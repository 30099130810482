import React from 'react'
import PropTypes from 'prop-types'
import Message from '../Message/Message'
import withStyles from 'react-jss'
import jss from './Checkbox.styles'

const Checkbox = ({ label, name, errorMessage, onClick, onChange, classes, ...rest }) => {
  return (
    <div className={classes['group']}>
      <input type="checkbox" id={name} onChange={onChange} className={classes['input']} {...rest} />
      {label && (
        <label data-testid="checkbox-btn" htmlFor={name} className={classes['label']} onClick={onClick}>
          {label}
        </label>
      )}
      {errorMessage && (
        <div className={classes['errorMessage']}>
          <Message text={errorMessage} variant={'error'} showIcon={false} />{' '}
        </div>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  /**
   * Label to be shown with the checkbox
   */
  label: PropTypes.any,
  /*
   * Name for the element
   */
  name: PropTypes.string,

  /**
   *
   */
  onClick: PropTypes.func,
  /**
   *
   */
  onChange: PropTypes.func,

  /**
   * Error Message to be shown
   */
  errorMessage: PropTypes.string,
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    label: PropTypes.object,
    input: PropTypes.object,
    group: PropTypes.object,
    errorMessage: PropTypes.object
  })
}

Checkbox.defaultProps = {
  override: {}
}

const CheckboxStyled = withStyles(jss)(Checkbox)

CheckboxStyled.defaultProps = {
  override: {}
}

export { jss as CheckboxDefaultStyles }
export { Checkbox as CheckboxUnstyled }
export { CheckboxStyled as Checkbox }
