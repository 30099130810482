import React from 'react'
import PropTypes from 'prop-types'
import deprecated from 'prop-types-extra/lib/deprecated'
import objstr from 'obj-str'
import withStyles from 'react-jss'
import defaultJss from './Button.styles'
import oeJss from './Button-oe.styles'

const BaseButton = props => {
  const { classes, variant, icon, text, children, size, light, block, override, ...rest } = props

  const className = objstr({
    [classes[variant]]: true,
    [classes[size]]: 'normal' !== size,
    [classes['light']]: light === true
  })

  return (
    <button className={className} {...rest}>
      {text || children}
    </button>
  )
}

const DefaultButton = withStyles(defaultJss)(BaseButton)

const OeButton = withStyles(oeJss)(BaseButton)

const Button = ({ preset, ...props }) => {
  switch (preset) {
    case 'oe':
      return <OeButton {...props} />
    default:
      return <DefaultButton {...props} />
  }
}

Button.propTypes = {
  preset: PropTypes.oneOf(['default', 'oe']),
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'inverse',
    'alternative',
    'alternative-inverse',
    'link',
    'withIcon',
    'success',
    'error',
    'info',
    'info-secondary'
  ]),
  size: PropTypes.oneOf(['full', 'normal', 'small']),
  light: PropTypes.bool,
  btnStyle: deprecated(PropTypes.any, 'Use `variant` instead.'),
  small: deprecated(PropTypes.any, 'Use `theme` instead.'),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  text: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  /**
   * Shape of theme expected. Any additional classes will be ignored.t
   */
  override: PropTypes.shape({
    primary: PropTypes.object,
    'primary:hover': PropTypes.object,
    'primary:active': PropTypes.object,
    secondary: PropTypes.object,
    tertiary: PropTypes.object,
    inverse: PropTypes.object,
    alternative: PropTypes.object,
    link: PropTypes.object,
    withIcon: PropTypes.object,
    success: PropTypes.object,
    error: PropTypes.object,
    info: PropTypes.object,
    'info-secondary': PropTypes.object
  })
}

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  preset: 'default',
  size: 'normal',
  light: false,
  override: {}
}

export default Button
