import React, { useCallback } from 'react'
import styles from './AddStoreToUser.styles'
import withStyles from 'react-jss'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import { SearchInput } from 'components/common'
import _ from 'lodash'
import useMyAccount from 'components/MyAccount/useMyAccount'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import { SelectedItems, PermissionsConfirmationModal } from 'components/common'
import useStoreSelector from 'hooks/useStoreSelector'
import apis from 'api'
import { EVENT_EDIT_USER_INFORMATION } from 'constants/mixpanel.constants'
import { getInsightsRole } from 'utils/user.utils'

const getAvailableStoresForTheUser = (stores, user) => {
  const { roles: userRoles } = user
  stores = _.differenceBy(stores, userRoles, 'cardAcceptorIdCode')
  return stores
}

const generateMixpanelEvent = (currentStores, selectedStores) => {
  let storePermissionsChange = selectedStores.length - currentStores.length

  let mixPanelEvent = {
    userPermissions: getInsightsRole([...currentStores, ...selectedStores], 'cardAcceptorIdCode')
  }

  if (storePermissionsChange > 0) {
    mixPanelEvent.addStorePermission = storePermissionsChange
  } else if (storePermissionsChange < 0) {
    // Shouldn't happen
    mixPanelEvent.removeStorePermission = Math.abs(storePermissionsChange)
  }

  return mixPanelEvent
}

const AddStoreToUser = ({ classes, handleBack, user }) => {
  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice

  const { stores: allStores } = useMyAccount()

  const updateUserAction = useCallback(actionParams => dispatch(usersActions.updateUser(actionParams)), [
    dispatch,
    usersActions
  ])
  const availableStores = getAvailableStoresForTheUser(allStores, user)
  const { Mixpanel: mixpanel } = apis

  const {
    onSearchTextChange,
    searchText,
    selectedStores,
    unSelectedStores,
    deSelectStore,
    filteredStores,
    onSelectAllFiltered,
    onSelectAll,
    onSelectStore,
    isAddSearched,
    isAddAll,
    isGlobalViewConfirmation,
    closeConfirmationModal
  } = useStoreSelector({
    availableStores: availableStores,
    handleStoreSelection: () => {}
  })

  const onChangeUserToGlobalViewer = async () => {
    const globalViewer = {
      role: 'INSIGHTS_VIEWER'
    }
    const updatedUser = { ...user, roles: [globalViewer] }

    mixpanel.track(EVENT_EDIT_USER_INFORMATION, {
      userPermissions: getInsightsRole(updatedUser.roles, 'cardAcceptorIdCode')
    })

    await updateUserAction(updatedUser)
    handleBack(updatedUser)
  }

  const addUsersToStore = async () => {
    const storesSelected = selectedStores.map(item => {
      const { cardAcceptorIdCode } = item
      return {
        role: 'INSIGHTS_VIEWER',
        cardAcceptorIdCode
      }
    })
    const currentRoles = user.roles
    const updatedUser = { ...user, roles: [...currentRoles, ...storesSelected] }
    mixpanel.track(EVENT_EDIT_USER_INFORMATION, generateMixpanelEvent(currentRoles, storesSelected))
    await updateUserAction(updatedUser)

    handleBack(updatedUser)
  }

  const text = 'Are you sure you want to add all stores? This will set the user up as a Global Viewer.'

  return (
    <div className={classes['container']}>
      <div className={classes['backAction']}>
        <Button onClick={handleBack} variant="secondary">
          Cancel
        </Button>
      </div>

      <div className={classes['header']}>
        <Text variant="h1"> Add Stores</Text>
        <Checkbox
          label={<Text variant="body">Add all remaining stores ({unSelectedStores.length})</Text>}
          checked={isAddAll}
          onClick={() => {
            onSelectAll()
          }}
          onChange={e => {
            e.preventDefault()
          }}
        />
      </div>

      <div className={classes['search']}>
        <SearchInput onChange={onSearchTextChange} placeholder={'Search Stores by Name'} text={searchText} />
      </div>

      {!_.isEmpty(selectedStores) && (
        <div className={classes['selectedStores']}>
          <SelectedItems
            {...{
              list: selectedStores,
              onClick: deSelectStore,
              keyExtractor: item => {
                return item.displayName
              },
              title: 'SELECTED STORES',
              isSelected: true
            }}
          />
          <div className={classes['addButtonContainer']}>
            <Button onClick={addUsersToStore}> Add Stores</Button>
          </div>
        </div>
      )}

      <div className={classes['addSearchResult']}>
        {searchText && (
          <Checkbox
            label={<Text variant="body">{'Add all search results'}</Text>}
            checked={isAddSearched}
            onChange={() => {}}
            onClick={onSelectAllFiltered}
            disabled={filteredStores.length === 0}
          />
        )}
      </div>

      <div className={classes['searchResult']}>
        <SelectedItems
          {...{
            list: filteredStores,
            onClick: onSelectStore,
            keyExtractor: item => {
              return item.displayName
            },
            isSelected: false
          }}
        />
      </div>

      {isGlobalViewConfirmation && (
        <PermissionsConfirmationModal
          {...{
            onAccept: onChangeUserToGlobalViewer,
            onClose: closeConfirmationModal,
            text
          }}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(AddStoreToUser)
