import FormikInput from 'components/FormikWrappers/FormikInput'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import Message from 'components/Message/Message'
import React from 'react'
import withStyles from 'react-jss'
import styles from './PersonalInformationForm.styles'
import Container from './PersonalInformationFormContainer'
import FieldSection from 'components/common/FieldSection'

const PasswordSection = ({ classes, showPasswordChange }) => {
  return (
    <div className={classes['passwordSection']}>
      <div className={'input'}>
        <FormikInput name="password" disabled={true} type="password" />
      </div>
      <div className={'link'}>
        <Button variant="link" color={colors.cyan} onClick={showPasswordChange}>
          Change Password
        </Button>
      </div>
    </div>
  )
}

const PersonalInformationForm = props => {
  const {
    handleSubmit,
    isSubmitting,
    classes,
    values,
    errors,
    showPasswordChange,
    isFormDisabled,
    setFieldValue
  } = props
  const { serverValidation: errorCode } = errors
  const isButtonDisabled = isSubmitting
  const { updated } = values

  const onPasswordChange = () => {
    setFieldValue('updated', false)
    showPasswordChange()
  }

  return (
    <form onSubmit={handleSubmit} name="PersonalInformationForm" className={classes['form']}>
      <FieldSection title={'First Name'} classes={classes} alignCenter>
        <FormikInput name="firstName" disabled={isFormDisabled} />
      </FieldSection>
      <FieldSection title={'Last Name'} classes={classes} alignCenter>
        <FormikInput name="lastName" disabled={isFormDisabled} />
      </FieldSection>
      <FieldSection title={'Email'} classes={classes} alignCenter>
        <FormikInput name="email" disabled={true} />
      </FieldSection>
      <FieldSection title={'Permissions'} classes={classes} alignCenter>
        <FormikInput name="permission" disabled={true} />
      </FieldSection>
      {!isFormDisabled && (
        <FieldSection title={'Password'} classes={classes} alignCenter>
          <PasswordSection classes={classes} showPasswordChange={onPasswordChange} />
        </FieldSection>
      )}

      {!isFormDisabled && (
        <div className={classes['primaryAction']}>
          <Button variant="primary" type="submit" disabled={isButtonDisabled}>
            {isSubmitting ? 'Saving Changes' : 'Save Changes'}
          </Button>
        </div>
      )}

      {!isFormDisabled && (
        <div className={classes['message']}>
          {updated && <Message text="Your details have been updated." variant="success" showIcon={false} />}
          {errorCode && (
            <Message text="Sorry, there was an error. Please try again later." variant="error" showIcon={false} />
          )}
        </div>
      )}
    </form>
  )
}

export default withStyles(styles)(Container(PersonalInformationForm))
