const styles = {
  legend: {
    display: 'flex',
    marginBottom: '0.5rem'
  },
  colors: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    padding: [0, '1rem', 0, '0.5rem']
  },
  color: {
    height: '0.75rem',
    width: '0.75rem',
    margin: '1px'
  }
}

export default styles
