import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_DOWN, MOBILE_UP, LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  cardsUsed: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  graphs: {
    display: 'flex',
    flexDirection: 'column'
  },
  currentDataGraph: {
    marginTop: 10,
    height: '90px',
    width: '240px',
    textAlign: 'center',
    [MOBILE_UP()]: {
      width: '470px'
    },
    [LAPTOP_UP()]: {
      width: '640px'
    },
    [SCREEN_UP()]: {
      width: '540px'
    },
    [LARGE_SCREEN_UP()]: {
      width: '880px'
    },
    '& h5': {
      paddingBottom: '3.5rem'
    }
  },
  pastDataGraph: {
    composes: '$currentDataGraph',
    marginTop: '-45px',
    height: '50px',
    '& h5': {
      paddingBottom: 0
    },
    [MOBILE_DOWN()]: {
      '& h5': {
        marginBottom: '-0.5rem'
      }
    }
  },
  isPastDataGraphEmpty: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'flex-end'
  },
  isCurrentDataGraphEmpty: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    margin: 'auto',
    marginTop: 0
  },
  legendsContainer: {
    display: 'flex',
    margin: ['0.5rem', 'auto'],
    flexWrap: 'wrap',
    padding: [0, '1rem']
  },
  noTransactionMessage: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    height: 120
  }
}

export default styles
