import * as colors from 'config/colors'
import { standardFontFamily, weightRegular } from 'config/jss-vars'

const styles = {
  input: {
    verticalAlign: 'middle',
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    backgroundColor: colors.white,
    borderRadius: '3px',
    border: `1px solid ${colors.line1}`,
    color: colors.greyDark,
    padding: '0.625rem',
    fontSize: '1rem',
    width: '100%',
    height: '2.4em',
    paddingLeft: props => {
      return props.icon ? '2.5em' : '0.5em'
    },
    '&:hover': {
      border: `1px solid ${colors.cyan}`,
      extend: ({ override }) => ({
        ...override['input:hover']
      })
    },
    '&:active': {
      border: `1px solid ${colors.cyan}`,
      extend: ({ override }) => ({
        ...override['input:active']
      })
    },
    '&:focus': {
      outline: 0,
      border: `1px solid ${colors.cyan}`,
      extend: ({ override }) => ({
        ...override['input:focus']
      })
    },
    '&::placeholder': {
      fontSize: '0.9rem',
      extend: ({ override }) => ({
        ...override['input::placeholder']
      })
    },
    '&:disabled, &[readonly]': {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.8,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      extend: ({ override }) => ({
        ...override['input:disabled']
      }),
      color: colors.textPlaceholder
    },
    extend: ({ override }) => ({
      ...override['input']
    })
  },
  icon: {
    position: 'absolute',
    padding: '0.5rem'
  },
  'input-error': {
    composes: '$input',
    color: colors.error,
    borderColor: colors.error,
    extend: ({ override }) => ({
      ...override['input-error']
    }),
    '&:hover': {
      borderColor: colors.error,
      extend: ({ override }) => ({
        ...override['input-error:hover']
      })
    },
    '&:active': {
      borderColor: colors.error,
      color: colors.greyDark,
      extend: ({ override }) => ({
        ...override['input-error:active']
      })
    },
    '&:focus': {
      borderColor: colors.error,
      color: colors.greyDark,
      extend: ({ override }) => ({
        ...override['input-error:focus']
      })
    }
  },
  clear: {
    position: 'absolute',
    padding: 1,
    right: '15px',
    margin: '8px 0 0',
    width: '1.25rem',
    height: '1.25rem',
    cursor: 'pointer',
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
      extend: ({ override }) => ({
        ...override['clear:hover']
      })
    },
    extend: ({ override }) => ({
      ...override['clear']
    })
  },
  help: {
    position: 'absolute',
    margin: '-28px 0 0',
    padding: 1,
    right: '5px',
    width: '1.25rem',
    height: '1.25rem',
    '& svg': {
      width: '1rem',
      extend: ({ override }) => ({
        ...override['help svg']
      })
    },
    extend: ({ override }) => ({
      ...override['help']
    })
  },
  'error-message': {
    margin: '1.25rem 0 0 0',
    '& p': {
      textAlign: 'center',
      extend: ({ override }) => ({
        ...override['error-message p']
      })
    },
    extend: ({ override }) => ({
      ...override['error-message']
    })
  },
  group: {
    marginBottom: '1rem',
    position: 'relative',
    extend: ({ override }) => ({
      ...override['group']
    })
  }
}

export default styles
