import _ from 'lodash'
import { getReturnCustomersAgg, getCardData, getSalesRevenueAgg } from './performance.api'

/**
 * Get Sales Revenue and Return Customer Data by Store.
 * @param {*} params
 */

export const getAllStoresInformation = async params => {
  const { current, previous, isCompare } = params

  if (!isCompare) {
    return getCurrentStoreInformation(current)
  }

  return getAllStoreWithComparison(current, previous)
}

/**
 * Get return customers propotion for all stores (Business Performance)
 */
export const getAllReturnCustomers = async params => {
  const { current, previous, isCompare } = params

  if (!isCompare) {
    const currentReturnCustomers = await getReturnCustomersAgg({ ...current, aggregate: true })
    return {
      current: _.head(currentReturnCustomers)
    }
  }

  const [currentReturnCustomers, previousReturnCustomers] = await Promise.all([
    getReturnCustomersAgg({ ...current, aggregate: true }),
    getReturnCustomersAgg({ ...previous, aggregate: true })
  ])

  return {
    current: _.head(currentReturnCustomers),
    previous: _.head(previousReturnCustomers)
  }
}

/**
 * Gets counts of cards used by all Stores (Business Performance)
 */
export const getAllCardsUsed = async params => {
  const { current: currentParams, previous: previousParams, isCompare } = params
  let [current, previous] = []

  if (isCompare) {
    const result = await Promise.all([getCardData(currentParams), getCardData(previousParams)])
    current = _.head(result)
    previous = _.last(result)
  } else {
    current = await getCardData(currentParams)
  }

  return {
    current: _.get(current, 'all.intervals[0].groups'),
    previous: _.get(previous, 'all.intervals[0].groups')
  }
}

const getCurrentStoreInformation = async currenParams => {
  const [currentTransactionsAgg, currentReturnCustomersAgg] = await Promise.all([
    getSalesRevenueAgg(currenParams),
    getReturnCustomersAgg(currenParams)
  ])

  return {
    current: {
      transactionsAgg: currentTransactionsAgg,
      returnCustomersAgg: currentReturnCustomersAgg
    }
  }
}

const getAllStoreWithComparison = async (currentParams, previousParams) => {
  const [
    currentTransactionsAgg,
    currentReturnCustomersAgg,
    prevTransactionsAgg,
    prevReturnCustomersAgg
  ] = await Promise.all([
    getSalesRevenueAgg(currentParams),
    getReturnCustomersAgg(currentParams),
    getSalesRevenueAgg(previousParams),
    getReturnCustomersAgg(previousParams)
  ])

  return {
    current: {
      transactionsAgg: currentTransactionsAgg,
      returnCustomersAgg: currentReturnCustomersAgg
    },
    previous: {
      transactionsAgg: prevTransactionsAgg,
      returnCustomersAgg: prevReturnCustomersAgg
    }
  }
}
