import { white } from 'config/colors'

const styles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.2)',
    minHeight: '100vh',
    overflowY: 'auto',
    zIndex: 1001
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    padding: '0',
    backgroundColor: white,
    border: 0
  }
}

export default styles
