import { teal, white } from '../../config/colors'

const styles = {
  'tool-tip-text': {
    visibility: 'hidden',
    width: '120px',
    'background-color': teal,
    color: white,
    'text-align': 'center',
    padding: '5px',
    'border-radius': '6px',
    position: 'absolute',
    'z-index': 1,
    'font-size': '0.865rem',
    'margin-left': '-110px',
    'margin-top': '-50px'
  },
  'tool-tip': {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer'
  },
  show: {
    visibility: 'visible'
  }
}

export default styles
