import axios from './axiosDefaults'
import handleError from 'utils/error.utils'
import { populateDisplayName } from 'utils/stores.utils'

const GET_MERCHANT_ACCESS = 'identity/merchantaccess/'
const UPDATE_PERSONAL_INFO = 'identity/user/me/'
const UPDATE_PASSWORD = `${UPDATE_PERSONAL_INFO}password/`

export const updatePersonalInformation = async data => {
  try {
    return await axios.put(UPDATE_PERSONAL_INFO, data)
  } catch (error) {
    handleError(error)
  }
}

export const getUserInformation = async () => {
  try {
    return await axios.get(UPDATE_PERSONAL_INFO)
  } catch (error) {
    handleError(error)
  }
}

export const updatePassword = async data => {
  try {
    return await axios.put(UPDATE_PASSWORD, data)
  } catch (error) {
    handleError(error)
  }
}

export const getMerchantAccess = async () => {
  try {
    let response = await axios.get(GET_MERCHANT_ACCESS)

    // Set display name which is a UI only property used throughout the app
    return response.map(item => {
      return populateDisplayName(item)
    })
  } catch (error) {
    handleError(error)
  }
}
