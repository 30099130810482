export const EVENT_NAME_SIGNUP_ATTEMPT = 'SignUpAttempt'
export const EVENT_NAME_SIGNUP_ATTEMPT_FAILURE = 'SignUpAttemptFailure'
export const EVENT_NAME_SIGNUP_VERIFY = 'SignUpVerify'
export const EVENT_NAME_SIGNUP_VERIFY_FAILURE = 'SignUpVerificationFailure'
export const EVENT_NAME_LOGIN = 'LogIn'
export const EVENT_DASHBOARD_QUERY = 'DashboardQuery'
export const EVENT_ONBOARDING_TUTORIAL = 'OnboardingTutorial'
export const EVENT_VIEWEDPAGE = 'ViewedPage'
export const EVENT_SWITCH_STORE_VIEW = 'SwitchStoreView'
export const EVENT_SWITCH_APP = 'SwitchApp'
export const EVENT_EDIT_PERSONAL_INFORMATION = 'EditPersonalInformation'
export const EVENT_EDIT_PERSONAL_INFORMATION_FAILURE = 'EditPersonalInformationFailure'
export const EVENT_DELETE_USER = 'DeleteUser'
export const EVENT_INVITE_USER = 'InviteUser'
export const EVENT_INVITE_USER_FAILURE = 'InviteUserFailure'

export const EVENT_INVITE_USER_VERIFY = 'InviteUserVerify'
export const EVENT_INVITE_USER_VERIFY_FAILURE = 'InviteUserVerificationFailure'
export const EVENT_RESEND_INVITE = 'ResendInvite'
export const EVENT_EDIT_USER_INFORMATION = 'EditUserInformation'

export const EVENT_VIEW_TRANSACTION = 'ViewTransaction'

export const EVENT_DOWNLOAD_REPORT = 'DownloadReport'
export const DOWNLOAD_REPORT_EXPORT_TYPE_PRINT = 'Print'
export const DOWNLOAD_REPORT_EXPORT_TYPE_CSV = 'CSV'

export const EVENT_SETTLEMENT_SEARCH = 'SettlementSearch'
export const EVENT_TRANSACTION_SEARCH = 'TransactionSearch'

export const EVENT_EDIT_STORE_INFORMATION = 'EditStoreInformation'
export const EVENT_DELETE_STORE = 'DeleteStore'

export const EVENT_ADD_MERCHANT = 'AddMerchant'
export const EVENT_ADD_MERCHANT_FAILURE = 'AddMerchantFailure'
