import React, { useState, useRef, useEffect } from 'react'
import styles from './UserSettings.styles'
import withStyles from 'react-jss'
import { usersSlice } from 'reduxSlices'
import { useSelector } from 'react-redux'
import UserList from './UserList'
import _ from 'lodash'
import UserLegends from './UserLegends'
import AddStoreToUser from './AddStoreToUser'
import { doFuzzySearch } from 'utils/filters.utils'
import { InviteUserRoute } from 'Routes'
import Button from 'components/Button/Button'

import { SearchBar } from 'components/common'

const UserSettings = ({ routerProps, classes }) => {
  const { selectors: usersSelectors } = usersSlice
  const users = useSelector(usersSelectors.getAllUsers)
  const [filteredUsers, setFilteredUsers] = useState([])
  const isLoading = useSelector(usersSelectors.isLoading)
  const [showAddUserScreen, setShowAddUserScreen] = useState(false)
  const ref = useRef(0)
  const [text, setText] = useState('')
  const [currentUser, setCurrentUser] = useState()
  const { history } = routerProps

  const onSearch = text => {
    setText(text)
  }

  const onClick = () => {
    history.push(InviteUserRoute.path)
  }

  const onShowAddScreen = user => {
    setCurrentUser(user)
    ref.current = window.scrollY
    setShowAddUserScreen(true)
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }

  const onShowUserList = () => {
    setShowAddUserScreen(false)
    setTimeout(() => {
      window.scrollTo({
        top: ref.current,
        behavior: 'auto'
      })
    })
  }

  useEffect(() => {
    const filteredUsers = doFuzzySearch(users, text, {
      keys: ['firstName', 'lastName', 'email', 'role', 'state', 'name']
    })
    setFilteredUsers(filteredUsers)
  }, [users, text])

  let infoText = `There are ${users.length} users set up for this Organisation`
  const placeholder = 'Search Users by name, email, status, role.'

  return (
    <div className={classes['container']}>
      <div style={{ display: showAddUserScreen ? 'none' : 'block' }}>
        <SearchBar onSearch={_.debounce(onSearch, 500)} text={infoText} placeholder={placeholder} />

        <div className={classes['actionBtn']}>
          <Button variant="secondary" onClick={onClick} width={'150px'}>
            Invite User
          </Button>
        </div>

        <UserList list={filteredUsers} isLoading={isLoading} searchText={text} handleAddStore={onShowAddScreen} />
      </div>

      {showAddUserScreen && (
        <div>
          <AddStoreToUser handleBack={onShowUserList} user={currentUser} />
        </div>
      )}

      <UserLegends />
    </div>
  )
}

export default withStyles(styles)(UserSettings)
