import moment from 'moment'

export const formatDateByRange = (value, range) => {
  let formatterString
  let nextHourTime = moment(value).add(1, 'hours')
  let formattedValue
  switch (range) {
    case '1d':
      formatterString = 'ddd Do, h a'
      formattedValue = `${moment(value).format(formatterString)} - ${nextHourTime.format('h a')}`
      break

    default:
      formatterString = 'MMM Do, YYYY'
      formattedValue = moment(value).format(formatterString)
      break
  }

  return formattedValue
}
