import { standardFontFamily, weightRegular } from 'config/jss-vars'
import { line1, greyLight } from 'config/colors'

export const buttonSize = '40px'

const baseButton = {
  float: 'left',
  border: `1px solid ${line1}`,
  cursor: 'pointer',
  width: buttonSize,
  height: buttonSize
}
const styles = {
  paginatorContainer: {
    width: '180px',
    height: buttonSize,
    margin: '0.5rem',
    boxShadow: '0 1px 2px 0 rgba(90, 60, 159, 0.2)',
    borderRadius: ['20px'],
    extend: ({ override }) => ({
      ...override['paginatorContainer']
    })
  },
  prevButton: {
    ...baseButton,
    borderRadius: ['20px', '0px', '0px', '20px'],
    paddingLeft: '2px'
  },
  nextButton: {
    ...baseButton,
    borderRadius: ['0px', '20px', '20px', '0px'],
    paddingRight: '2px',
    extend: ({ override }) => ({
      ...override['nextButton']
    })
  },
  indicator: {
    float: 'left',
    border: `1px solid ${line1}`,
    width: '100px',
    textAlign: 'center',
    height: buttonSize,
    paddingTop: 10,
    fontWeight: weightRegular,
    fontSize: '1rem',
    fontFamily: standardFontFamily,
    color: greyLight,
    extend: ({ override }) => ({
      ...override['indicator']
    })
  },

  disabled: {
    opacity: '0.5',
    cursor: 'not-allowed',
    extend: ({ override }) => ({
      ...override['disabled']
    })
  }
}

export default styles
