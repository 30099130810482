import React from 'react'
import withStyles from 'react-jss'
import styles from '../Group.styles'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import Radio from 'components/Radio/Radio'
import PropTypes from 'prop-types'
import { CardTypeArray, CreditCards } from './CardType.contants'

const CardTypeSelector = ({ classes, cardType, cards, handleCardTypeChange, handleCardChange }) => {
  return (
    <div className={classes['group']}>
      <div className={classes['radioButtons']} data-hj-suppress>
        {CardTypeArray.map(type => {
          const checked = cardType === type
          return (
            <div className={classes['radioItem']} key={type}>
              <Radio
                label={type}
                checked={checked}
                onChange={() => {
                  handleCardTypeChange(type)
                }}
              />
            </div>
          )
        })}
      </div>
      {CardTypeArray[2] === cardType && (
        <div className={classes['checkBoxes']} data-hj-suppress>
          {CreditCards.map(card => {
            const checked = cards.indexOf(card) > -1
            return (
              <div className={classes['checkBoxItem']} key={card}>
                <Checkbox
                  label={card}
                  checked={checked}
                  onClick={() => {
                    handleCardChange(card)
                  }}
                  onChange={e => {
                    e.preventDefault()
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

CardTypeSelector.propTypes = {
  classes: PropTypes.object,
  cardType: PropTypes.string,
  cards: PropTypes.array,
  handleCardTypeChange: PropTypes.func,
  handleCardChange: PropTypes.func
}

export default withStyles(styles)(CardTypeSelector)
