import React from 'react'
import withStyles from 'react-jss'
import styles from './SummaryLinks.styles'
import PropTypes from 'prop-types'
import { HeaderLinks } from 'components/common'

const title = 'Settlement'

const links = [
  {
    value: 'merchant',
    title: 'Merchant Summary'
  },
  {
    value: 'terminal',
    title: 'Terminal Summary'
  }
]

const SummaryLinks = ({ activeLink, action }) => {
  const headerLinkProps = {
    title,
    links,
    activeLink,
    action
  }

  return <HeaderLinks {...headerLinkProps} />
}

SummaryLinks.propTypes = {
  activeLink: PropTypes.string,
  action: PropTypes.func
}

export default withStyles(styles)(SummaryLinks)
