import React from 'react'
import PropTypes from 'prop-types'
import { getColorGradient } from 'utils/common.utils'
import { getCardName } from 'utils/filters.utils'

const CardType = ({ classes, baseColor, cardIndex, cardsCount, cardType }) => {
  const background = getColorGradient(baseColor, cardIndex, cardsCount)
  return (
    <div>
      <div
        className={classes['icon']}
        style={{
          background
        }}
      />
      {getCardName(cardType)}
    </div>
  )
}

CardType.propTypes = {
  classes: PropTypes.object.isRequired,
  baseColor: PropTypes.string.isRequired,
  cardIndex: PropTypes.number.isRequired,
  cardsCount: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired
}

export default CardType
