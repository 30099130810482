import React from 'react'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'

const HeaderItem = ({ children }) => {
  return (
    <Text variant="h5" color={colors.greyDark}>
      {children}
    </Text>
  )
}

const ListHeader = ({ classes }) => {
  return (
    <div className={classes['header']} id="storeListTop">
      <div className={classes['preferredName']}>
        <HeaderItem>Preferred Name</HeaderItem>
      </div>
      <div className={classes['businessName']}>
        <HeaderItem>Business Name</HeaderItem>
      </div>
      <div className={classes['merchantNumber']}>
        <HeaderItem>Merchant No.</HeaderItem>
      </div>
      <div className={classes['users']}>
        <HeaderItem>Users</HeaderItem>
      </div>
    </div>
  )
}

export default ListHeader
