import React from 'react'
import Text from 'components/Text/Text'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_CURRENT_PERIOD_COLOR } from 'constants/colors.contants'
import CurrentDataLabel from './CurrentDataLabel'

const CurrentPeriodGraph = ({ classes, isCurrentGraphEmpty, currentGraphData }) => {
  return (
    <div className={classes['currentDataGraph']}>
      {!isCurrentGraphEmpty ? (
        <HorizontalBarChart
          {...{
            height: 90,
            barSize: 40,
            color: GRAPH_CURRENT_PERIOD_COLOR,
            data: currentGraphData,
            labelPosition: 'top',
            Label: CurrentDataLabel
          }}
        />
      ) : (
        <Text variant="h5">There are no transactions for the selected time period.</Text>
      )}
    </div>
  )
}

export default CurrentPeriodGraph
