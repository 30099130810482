import { accountSlice } from 'reduxSlices'
import store from '../configureStore'

export const startLoader = config => {
  const { method } = config
  if (method !== 'get') {
    store.dispatch(accountSlice.actions.startLoading())
  }
}

export const stopLoader = response => {
  const {
    config: { method }
  } = response
  if (method !== 'get') {
    store.dispatch(accountSlice.actions.endLoading())
  }
}
