import React from 'react'

export const AccordionContext = React.createContext()

export function useAccordionContext() {
  const context = React.useContext(AccordionContext)
  if (!context) {
    throw new Error(`Accordion compound components cannot be rendered outside the Accordion component`)
  }
  return context
}
