export const DEFERED_BASE_COLOR = '#b45cb0'
export const CREDIT_TO_BANK_BASE_COLOR = '#00a8ea'
export const GRAPH_CURRENT_PERIOD_COLOR = '#ff9103'
export const GRAPH_REVENUE_COLOR = '#ff7794'
export const GRAPH_REVENUE_HIGHLIGHT_COLOR = '#ff99af'
export const GRAPH_SALES_COLOR = '#cc61dc'
export const GRAPH_SALES_AREA_COLOR = '#e1c3e8'
export const GRAPH_CURRENT_PERIOD_CARDS_COLOR = '#28c8fa'
export const GRAPH_PREVIOUS_PERIOD_COLOR = '#9491a0'
export const GRAPH_PREVIOUS_PERIOD_HIGHLIGHT_COLOR = '#bdbbc4'
export const GRAPH_PREVIOUS_PERIOD_AREA_COLOR = '#ecedfb'
