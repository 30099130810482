import React from 'react'
import numeral from 'numeral'
import Label from 'components/common/HorizontalBarChart/Label'

const CurrentDataLabel = ({ x, y, width, stroke, value, index }) => {
  const displayValue = value ? numeral(value / 100).format('0%') : ''

  return (
    <Label
      {...{
        x,
        y,
        width,
        stroke,
        value: displayValue,
        index,
        align: 'ends',
        mobileFontSize: 14,
        vAlign: 'top'
      }}
    />
  )
}

export default CurrentDataLabel
