import React, { useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { AccordionContext } from './AccordionContext'
import ItemHeader from './Accordion.ItemHeader'
import ItemPanel from './Accordion.ItemPanel'
import Item from './Accordion.Item'
import jss from './Accordion.styles'
import { useAccordionContext } from './AccordionContext'

function Accordion({ classes, children, initialOpenItem }) {
  const [openItem, setOpenItem] = useState(initialOpenItem)
  const contextValue = React.useMemo(() => ({ openItem, classes, setOpenItem }), [openItem, classes])

  return (
    <AccordionContext.Provider value={contextValue}>
      <div>{children}</div>
    </AccordionContext.Provider>
  )
}

Accordion.Item = Item
Accordion.ItemPanel = ItemPanel
Accordion.ItemHeader = ItemHeader
Accordion.AccordionContext = AccordionContext
Accordion.useAccordionContext = useAccordionContext

Accordion.defaultProps = {
  override: {}
}

Accordion.propTypes = {
  initialOpenItem: PropTypes.string,
  override: PropTypes.shape({
    'accordion-item': PropTypes.object,
    'accordion-item-header': PropTypes.object,
    'accordion-item-header-actions': PropTypes.object,
    'accordion-item-panel': PropTypes.object
  })
}

export default withStyles(jss)(Accordion)
