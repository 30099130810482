import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LAPTOP_UP, TABLET_DOWN } = mediaQueryHelper

const styles = {
  selectors: {
    display: 'flex',
    flexDirection: 'column',
    [LAPTOP_UP()]: {
      flexDirection: 'row'
    }
  },
  timeRange: {
    flex: [1, 1, 'auto'],
    padding: [0, 0, '1rem', 0],
    [LAPTOP_UP()]: {
      padding: [0, '2em', 0, 0]
    }
  },
  timePicker: {
    flex: [1, 1, 'auto'],
    alignItems: 'flex-end',
    '& > div': {
      marginLeft: 'auto',
      width: '270px',
      minWidth: '230px',

      [TABLET_DOWN()]: {
        width: '100%'
      }
    }
  },
  highlight: {
    composes: '$timePicker',
    '& > div': {
      borderBottom: `1.5px solid ${colors.teal}`
    }
  },
  errorMessage: {
    marginTop: '1rem'
  }
}

export default styles
