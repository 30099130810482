import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingGraph = () => {
  return (
    <div style={{ width: '120px', height: '120px', margin: 'auto' }}>
      <ContentLoader height={120} width={120} speed={2} primaryColor="#d9d9d9" secondaryColor="#ecebeb">
        <circle cx="60" cy="62" r="59" />
      </ContentLoader>
    </div>
  )
}

export default LoadingGraph
