import React, { useState } from 'react'
import styles from './StoreList.styles'
import withStyles from 'react-jss'
import StoreListLoader from './StoreListLoader'
import Accordion from 'components/Accordion/Accordion'
import Paginator from 'components/Paginator/Paginator'
import StoreItem from './StoreItem'
import ListHeader from './ListHeader'
import { getPaginatedItems } from 'utils/filters.utils'

const PER_PAGE = 50

const StoreList = ({ classes, list, isLoading, handleAddUserToStore }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const result = getPaginatedItems(list, currentPage, PER_PAGE)
  const { paginatorPage, paginatorTotalPages, data: storesToDisplay } = result

  const changeToPage = pageNo => {
    setCurrentPage(pageNo)
    document.getElementById('storeListTop').scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <div className={classes['storeList']}>
      <div className={classes['listHeader']}>
        <ListHeader classes={classes} />
      </div>
      <div className={classes['list']}>
        {storesToDisplay.length === 0 && isLoading && <StoreListLoader />}

        {storesToDisplay.length > 0 && (
          <Accordion>
            {storesToDisplay.map(store => {
              return (
                <StoreItem store={store} key={store.cardAcceptorIdCode} handleAddUserToStore={handleAddUserToStore} />
              )
            })}
          </Accordion>
        )}
      </div>

      {paginatorTotalPages > 1 && (
        <div className={classes['pagination']}>
          <Paginator
            onNextClick={() => changeToPage(paginatorPage + 1)}
            onPreviousClick={() => changeToPage(paginatorPage - 1)}
            currentPage={paginatorPage}
            totalPages={paginatorTotalPages}
          />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(StoreList)
