import React from 'react'
import ContentLoader from 'react-content-loader'
import { isCustomMobileWidth, isCustomTabletWidth, isCustomLaptopWidth } from '../CardLoader.utils'
import { cyan } from 'config/colors'
let h1 = isCustomMobileWidth() ? 50 : 75
h1 = isCustomTabletWidth() ? 85 : h1
h1 = isCustomLaptopWidth() ? 53 : h1

let y = isCustomMobileWidth() ? 5 : 16
y = isCustomTabletWidth() ? 18 : y
y = isCustomLaptopWidth() ? 10 : y

let x = isCustomMobileWidth() ? 100 : 125
x = isCustomTabletWidth() ? 100 : x
x = isCustomLaptopWidth() ? 100 : x

let h2 = isCustomMobileWidth() ? 40 : 50
h2 = isCustomTabletWidth() ? 55 : h2
h2 = isCustomLaptopWidth() ? 38 : h2

const StoreSalesSummaryCardLoader = () => {
  return (
    <ContentLoader height={h1} width={400} speed={2} primaryColor={cyan} secondaryColor={cyan}>
      <rect x={x} y={y} rx="5" ry="5" width="200" height={h2} />
    </ContentLoader>
  )
}

export default StoreSalesSummaryCardLoader
