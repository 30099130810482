import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  storeList: {
    paddingTop: '1rem'
  },
  list: {
    paddingTop: '0.5rem'
  },
  listHeader: {
    width: 'calc(100% - 2.2rem)',
    paddingLeft: '1rem'
  },
  header: {
    display: 'none',
    width: '100%',
    alignItems: 'center',
    padding: [0, '2rem', 0, '1rem'],
    [MOBILE_UP()]: {
      display: 'flex'
    }
  },
  openHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  preferredName: {
    flex: [2, 1, '0%'],
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '0.5rem',
    wordBreak: 'break-word'
  },
  businessName: {
    composes: '$preferredName'
  },
  merchantNumber: {
    composes: '$preferredName'
  },
  users: {
    flex: [1, 1, 'auto'],
    composes: '$preferredName',
    wordBreak: 'break-word'
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > .line1': {
      display: 'flex',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${colors.line2}`,
      padding: [0, 0, '0.5rem', 0],
      margin: [0, '0.5rem', '1rem', 0]
    },
    '& > .line2': {
      display: 'flex',
      '& > :first-child': {
        borderRight: `1px solid ${colors.line2}`,
        marginRight: '1rem'
      }
    },
    [MOBILE_UP()]: {
      display: 'none'
    }
  },
  pagination: {
    margin: '2rem auto 0',
    width: '100%',
    '& >div': {
      margin: 'auto'
    }
  }
}

export default styles
