import React from 'react'
import withStyles from 'react-jss'
import { formatNumber, getDifferencePercentage } from 'utils/number.utils'
import styles from './StoreSalesCard.styles'
import { ChartToolTip, ComposedChart, DashboardCard, CustomDot } from 'components/common'
import { GRAPH_SALES_COLOR } from 'constants/colors.contants'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import useTopStores from 'reduxSlices/dashboardSlice/hooks/useTopStores'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'
import StoreCardGraphLayout from 'components/StorePerformance/StoreCardGraphLayout/StoreCardGraphLayout'
import StoreSalesCardLoader from './StoreSalesCardLoader'
import { getMinMaxSalesValue } from 'utils/salesRevenue.utils'
import { withRouter } from 'react-router-dom'
import { generateTransactionPageUrl } from 'components/TransactionFilter/TransactionFilter.helper'
import { generateRankedCommentary, generateRankedText, getAverageSalesText } from '../StorePerformance.helper'
import { formatDateByRange } from 'components/common/ChartToolTip/ChartToolTip.utils'

const SalesChart = ({ data, isComparePeriod, isLoading, isCompareStore, handleClick, minValue, maxValue, range }) => {
  if (isLoading) {
    return <StoreSalesCardLoader {...{ isCompareStore }} />
  }
  const dateFormatter = value => {
    return formatDateByRange(value, range)
  }

  return (
    <ComposedChart
      {...{
        showYAxis: true,
        data: data,
        isCompare: isComparePeriod,
        showTooltip: true,
        minValue,
        maxValue,
        CustomTooltip: (
          <ChartToolTip
            {...{
              primaryColor: GRAPH_SALES_COLOR,
              isCompare: isComparePeriod,
              valueFormatter: value => formatNumber(value, false, false),
              dateFormatter
            }}
          />
        ),
        CustomDot: <CustomDot />,
        handleClick
      }}
    />
  )
}

const StoreSalesCard = ({ classes, history }) => {
  const { primaryStore, secondaryStore, isComparePeriod } = useSalesRevenueAgg()
  const { stores } = useTopStores()
  const { filter } = useFilters()
  const { comparedStore, store, range } = filter

  const { data: primaryData, isLoading: isPrimaryLoading } = primaryStore
  const { data: secondaryData, isLoading: isSecondaryLoading } = secondaryStore

  let minValue = 0
  let maxValue = 4
  let minMaxValue = {}
  if (!isPrimaryLoading && !isSecondaryLoading) {
    minMaxValue = getMinMaxSalesValue(!!comparedStore, isComparePeriod, primaryData, secondaryData)
    minValue = minMaxValue.minValue
    maxValue = minMaxValue.maxValue
  }

  const {
    currentRevenueTotal: primaryStoreCurrentRevenueTotal,
    currentSalesTotal: primaryStoreCurrentSalesTotal,
    previousSalesTotal: primaryStorePreviousSalesTotal
  } = primaryStore
  const { currentSalesTotal: compareStoreCurrentSalesTotal } = secondaryStore
  const differencePercentage = getDifferencePercentage(primaryStoreCurrentSalesTotal, primaryStorePreviousSalesTotal)
  const commentary = generateRankedCommentary(primaryData, range, differencePercentage, 'sales')
  const rankText = generateRankedText(stores, store, 'sales')
  const averageSalesText = getAverageSalesText(primaryStoreCurrentRevenueTotal, primaryStoreCurrentSalesTotal)

  const handleClick = (selectedData, period, isPrimaryStore) => {
    const storeToBeUsed = isPrimaryStore ? store : comparedStore
    const { currentDateTime, oldDateTime } = selectedData
    const timeFrom = 'current' === period ? currentDateTime : oldDateTime
    const url = generateTransactionPageUrl(storeToBeUsed, range, timeFrom)
    history.push(url)
  }

  const PrimaryChart = (
    <SalesChart
      {...{
        data: primaryData,
        isComparePeriod,
        isLoading: isPrimaryLoading && isSecondaryLoading,
        isCompareStore: !!comparedStore,
        minValue,
        maxValue,
        range,
        handleClick: (...params) => {
          handleClick(...params, true)
        }
      }}
    />
  )
  const SecondaryChart = (
    <SalesChart
      {...{
        data: secondaryData,
        isComparePeriod,
        isLoading: isPrimaryLoading && isSecondaryLoading,
        isCompareStore: !!comparedStore,
        minValue,
        maxValue,
        range,
        handleClick: (...params) => handleClick(...params, false)
      }}
    />
  )

  return (
    <DashboardCard
      {...{
        title: 'Sales Total',
        iconName: 'sales',
        height: '300px',
        isResizeable: false,
        showHeaderBorder: true
      }}
    >
      <div className={classes['cardContent']}>
        <div className={classes['currentStore']}>
          <StoreCardGraphLayout
            {...{
              Chart: PrimaryChart,
              total: formatNumber(primaryStoreCurrentSalesTotal, false, false),
              previousPeriodTotal: isComparePeriod
                ? formatNumber(primaryStorePreviousSalesTotal, false, false)
                : undefined,
              totalColor: GRAPH_SALES_COLOR,
              commentary,
              averageSalesText,
              storeName: store ? store.displayName : '',
              rankText,
              percentageChange: differencePercentage,
              isCompareStore: !!comparedStore,
              isLoading: isPrimaryLoading,
              graphBias: true
            }}
          />
        </div>

        {!!comparedStore && (
          <div className={classes['comparedStore']}>
            <StoreCardGraphLayout
              {...{
                Chart: SecondaryChart,
                total: formatNumber(compareStoreCurrentSalesTotal, false, false),
                totalColor: GRAPH_SALES_COLOR,
                storeName: comparedStore ? comparedStore.displayName : '',
                isCompareStore: !!comparedStore,
                graphBias: true
              }}
            />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

export default React.memo(withRouter(withStyles(styles)(StoreSalesCard)))
