import { useSelector, shallowEqual } from 'react-redux'
import { dashboardSlice, storeSlice } from 'reduxSlices'

function useReturnCustomers() {
  const { selectors } = dashboardSlice
  const { selectors: storeSelectors } = storeSlice

  const allReturnCustomers = useSelector(selectors.getAllReturnCustomers, shallowEqual)
  const isComparePeriod = useSelector(selectors.isComparePeriod)

  const primaryStoreRepeatCustomers = useSelector(storeSelectors.getPrimaryReturnCustomers)
  const secondaryStoreRepeatCustomers = useSelector(storeSelectors.getSecondaryReturnCustomers)

  return {
    allReturnCustomers,
    isComparePeriod,
    primaryStore: primaryStoreRepeatCustomers,
    secondaryStore: secondaryStoreRepeatCustomers
  }
}

export default useReturnCustomers
