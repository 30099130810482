import base from '../SettlementResult.styles'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { up, BREAK_POINT_LARGE } = mediaQueryHelper

const styles = {
  ...base,
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
                        'table'
                        'graph'
                        `,
    margin: [0, '1em'],
    padding: ['1.5em', 0, '2.5em'],
    borderTop: `1px solid ${colors.line1}`,
    borderBottom: `1px solid ${colors.line1}`,
    '& .title': {
      gridArea: 'title',
      display: 'none'
    },
    '& .table': {
      gridArea: 'table',
      msGridColumn: 1,
      msGridRow: 1
    },
    '& .graph': {
      gridArea: 'graph',
      padding: ['1em', 0, 0, 0],
      margin: [0, 'auto'],
      msGridColumn: 1,
      msGridRow: 2
    },
    [up(BREAK_POINT_LARGE)]: {
      msGridColumns: 'minmax(min-content, 1fr) 230px',
      gridTemplateColumns: 'auto 230px',
      msGridRows: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
                          'table graph'
                          `,
      margin: [0, '1.5em'],
      '& .graph': {
        msGridColumn: 2,
        msGridRow: 1
      }
    }
  }
}

export default styles
