import React, { useState } from 'react'
import container, { hasDataToExport, printSettlement } from './SettlementExportContainer'
import { isDesktop } from 'utils/common.utils'
import { ExportDropDown } from 'components/common'
import MultiStoreDownloadModal from 'components/Modal/MultiStoreDownloadModal'
import apis from 'api'
import {
  EVENT_DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_EXPORT_TYPE_CSV,
  DOWNLOAD_REPORT_EXPORT_TYPE_PRINT
} from 'constants/mixpanel.constants'
import { downloadCSV } from 'utils/file.utils'

const OPTIONS_PRINT_CSV_DOWNLOAD = [
  { value: 'print', label: 'Print' },
  { value: 'csv', label: 'Download CSV' }
]
const OPTIONS_CSV_DOWNLOAD = [{ value: 'csv', label: 'Download CSV' }]

const SETTLEMENT_PAGE_NAME = 'Settlement'

const getProps = props => {
  if (props.requestParams) {
    return props.requestParams
  }

  if (!props.requestParams) {
    return props
  }
}

const SettlementExport = props => {
  const { filter, actions, terminalSummary, merchantSummary, isLoading, enablePrint, removePrintMenu } = getProps(props)
  const [modalOpen, setModalOpen] = useState(false)
  const { Mixpanel: mixpanel } = apis
  const options = removePrintMenu ? OPTIONS_CSV_DOWNLOAD : OPTIONS_PRINT_CSV_DOWNLOAD

  const proceedHandler = async () => {
    try {
      setModalOpen(false)
      actions.startLoading()
      mixpanel.track(EVENT_DOWNLOAD_REPORT, {
        pageName: SETTLEMENT_PAGE_NAME,
        exportType: DOWNLOAD_REPORT_EXPORT_TYPE_CSV
      })
      const csv = await apis.getSettlementExportData(filter)

      if (csv !== 504) {
        const { settlementDateFrom, settlementDateTo } = filter
        const csvFilter = {
          from: settlementDateFrom,
          to: settlementDateTo
        }
        downloadCSV(csv, 'merchant-settlement-transactions', csvFilter)
      } else {
        actions.set504(true)
      }
    } finally {
      setTimeout(() => actions.endLoading(), 1000)
    }
  }

  const cancelHandler = () => {
    setModalOpen(false)
  }

  const onSelect = async option => {
    try {
      if (option.value === 'print') {
        actions.startLoading()
        mixpanel.track(EVENT_DOWNLOAD_REPORT, {
          pageName: SETTLEMENT_PAGE_NAME,
          exportType: DOWNLOAD_REPORT_EXPORT_TYPE_PRINT
        })
        await enablePrint(true)
        await printSettlement(filter)
      } else if (option.value === 'csv') {
        setModalOpen(true)
      }
    } finally {
      await enablePrint(false)
      setTimeout(() => actions.endLoading(), 1000)
    }
  }

  const isEnabled = !isLoading && hasDataToExport(terminalSummary, merchantSummary, filter, isLoading)

  return (
    <>
      <MultiStoreDownloadModal modalOpen={modalOpen} proceedHandler={proceedHandler} cancelHandler={cancelHandler} />
      <ExportDropDown {...{ isLoading, isEnabled, options: options, onSelect }} />
    </>
  )
}
export default container(SettlementExport)
