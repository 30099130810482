import * as colors from 'config/colors'
import { standardFontFamily, weightSemiBold } from 'config/jss-vars'

const header = {
  width: '100%',
  zIndex: 999
}

export const mobileStyles = {
  header: {
    ...header,
    height: '50px',
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    display: 'flex',
    flex: [1, 1, 'auto'],
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    fontSize: '1.15rem',
    lineHeight: '1.15rem',
    fontFamily: standardFontFamily,
    fontWeight: weightSemiBold
  },
  logo: {
    display: 'flex',
    flex: '0 1 auto',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  popovers: {
    display: 'flex',
    paddingRight: '2.125rem',
    position: 'relative'
  },
  navigateBack: {
    position: 'absolute',
    top: '8px',
    left: '10px',
    width: '30px',
    height: '30px'
  },
  chevronWithText: {
    display: 'flex',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    paddingTop: '4px'
  },
  backText: {
    color: colors.cyan,
    left: '15px',
    position: 'absolute'
  },
  menuButton: {
    width: '100%',
    position: 'absolute'
  },
  content: {
    extend: ({ override }) => ({
      width: '100%',
      height: '100%',
      ...override['content']
    })
  }
}

export const lightMobileStyles = {
  ...mobileStyles,
  header: {
    ...mobileStyles.header,
    backgroundColor: colors.white,
    boxShadow: `0 1px 2px 0 ${colors.line1}`
  },
  title: {
    ...mobileStyles.title,
    color: colors.heading
  },
  backText: {
    ...mobileStyles.backText,
    color: colors.cyan
  }
}

export const darkMobileStyles = {
  ...mobileStyles,
  header: {
    ...mobileStyles.header,
    backgroundColor: colors.cyan,
    boxShadow: `0 1px 2px 0 ${colors.menuBackground}`
  },
  title: {
    ...mobileStyles.title,
    color: colors.white
  },
  backText: {
    ...mobileStyles.backText,
    color: colors.white
  }
}
