import React from 'react'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import styles from './Legends.styles'
import PropTypes from 'prop-types'

const Legends = ({ classes, colors, title }) => {
  return (
    <div className={classes['legend']}>
      <div className={classes['colors']}>
        {colors.map(color => {
          if (!color) {
            return null
          }
          return <div className={classes['color']} key={color} style={{ background: color }}></div>
        })}
      </div>
      <div className={classes['title']}>
        <Text variant="label">{title}</Text>
      </div>
    </div>
  )
}

Legends.prototype = {
  colors: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(styles)(Legends)
