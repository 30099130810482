/**
 * Colors
 */
export const black_0 = '#fff'
export const white = black_0
export const orangeDark = '#ff6546'
export const greyLight = '#9491a0'
export const greyMedium = '#808080'
export const greyDark = '#605b71'
export const textPlaceholder = '#a8a5b4'
export const background1 = '#ffffff'
export const background2 = '#fafafb'
export const background3 = '#f6f7f8'
export const accentGrey = '#667882'
export const redLight = '#fcf8f3'
export const accentDark = '#17a5b3'
export const accentLight = '#42cad7'
export const brandDark = '#292540'
export const orangeLight = '#f7822a'
export const brandLight = '#36c6f9'
export const menuBackground = '#2a2441'
export const pageBackground = '#f7f8f9'
export const error = '#FE6464'
export const errorLight = '#ff1c00'
export const errorBackground = '#fae7e7'
export const warning = '#856404'
export const success = '#5cce8f'
export const successLight = '#1fd26f'
export const active = '#D1EAEA'
export const backgroundActive = '#ecedfb'
export const backgroundPressed = '#ecedfb'
export const warningBackground = '#b45cb0'
export const infoIcon = '#b45cb0'
export const infoHover = '#cb59c6'
export const positiveAlert = '#5cce8f'
export const positiveAlertHover = '#64e09b'
export const chartPrimaryColor = '#00a8ea'
export const line1 = '#cac8cf'
export const line2 = '#dfdee3'
export const highlightWarning = '#fff6f5'
export const textSecondary = '#787387'

//Worldline
export const cyan = '#46BEAA'
export const teal = '#2D8C8C'
export const boxshadowcyan = '#67e09c'
export const black = '#000000'
export const lightBlue = '#41B4D2'
export const yellow = '#41B4D2'
export const icon = '#62A9A9'
export const highlight = '#E4F6F3'
export const boxshadow = '#7DCEA0'
export const heading = '#000000'
