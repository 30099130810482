import axios from './axiosDefaults'
import loginHelpers from 'utils/loginHelpers'
import { getConfig } from 'utils/config.utils'
import handleError from 'utils/error.utils'

const PATH = 'bearer/'

export const fetchAccessToken = async () => {
  const { consumerKey, consumerSecret } = getConfig()

  try {
    return await axios.post(PATH, 'grant_type=client_credentials', {
      headers: loginHelpers.getBearerHeaders(consumerKey, consumerSecret)
    })
  } catch (error) {
    handleError(error)
  }
}
