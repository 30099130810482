import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './DashboardSummaryCard.styles'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import { isMobileWidth } from 'utils/common.utils'

const iconDimension = isMobileWidth() ? '1em' : '1.5em'

const iconProps = {
  width: iconDimension,
  height: iconDimension
}

const Header = ({ classes, title = 'Title', iconName = 'customer' }) => {
  return (
    <div className={classes['header']}>
      <div className={classes['title']}>
        <div className={classes['titleText']}>
          {iconName && <Icon {...{ ...iconProps, name: iconName, color: '#ffffff' }} />}
          <Text variant="h5"> {title} </Text>
        </div>
      </div>
    </div>
  )
}

const DashboardSummaryCard = ({ children, classes, title, iconName, isHeaderVisible = true }) => {
  return (
    <div className={classes['card']}>
      {isHeaderVisible && <Header {...{ classes, title, iconName }} />}
      <div className={classes['cardContent']}>{children}</div>
    </div>
  )
}

DashboardSummaryCard.prototype = {
  children: PropTypes.any,
  showHeaderBorder: PropTypes.bool
}

export default withStyles(styles)(DashboardSummaryCard)
