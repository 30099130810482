import React from 'react'
import PropTypes from 'prop-types'
import objstr from 'obj-str'
import withStyles from 'react-jss'
import styles from './ToggleGroup.styles'

const ToggleGroup = ({ classes, modes, onSelect, active, disabled }) => {
  return (
    <div className={classes['toggleGroup']}>
      {modes.map(mode => {
        const { label, value } = mode
        const isActive = active === value
        const className = objstr({
          [classes['toogleButton']]: true,
          [classes['active']]: isActive,
          [classes['disabled']]: disabled
        })

        return (
          <div
            onClick={() => {
              if (isActive || disabled) {
                return
              }
              onSelect(value)
            }}
            key={value}
            className={className}
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}

ToggleGroup.propTypes = {
  override: PropTypes.shape({
    toggleGroup: PropTypes.object,
    toggleButton: PropTypes.object,
    active: PropTypes.object,
    disabled: PropTypes.object
  }),
  modes: PropTypes.array,
  onSelect: PropTypes.func,
  active: PropTypes.string
}

ToggleGroup.defaultProps = {
  override: {}
}

export default withStyles(styles)(ToggleGroup)
