import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFileName, print } from 'utils/file.utils'
import { merchantPrintStyles } from 'styles/print.styles'
import { settlementSlice, accountSlice } from 'reduxSlices'

export const hasDataToExport = (terminalSummary, merchantSummary, filter) => {
  const { summaryType, enableExportCSV } = filter
  if (enableExportCSV) {
    return true
  }

  if ('merchant' === summaryType) {
    if (!merchantSummary) {
      return false
    }
    const { creditToBank, deferred } = merchantSummary
    const {
      totals: { totalCount: cobTotalCount }
    } = creditToBank
    const {
      totals: { totalCount: deftotalCount }
    } = deferred

    if (cobTotalCount > 0 || deftotalCount > 0) {
      return true
    }
  } else if ('terminal' === summaryType) {
    if (!terminalSummary) {
      return false
    }
    const { settlementTerminalIds } = terminalSummary

    if (settlementTerminalIds.length > 0) {
      return true
    }
  }

  return false
}

export const printSettlement = async filter => {
  const { summaryType } = filter
  let fileName = getFileName('Terminal-summary', filter)
  let id = 'printableTerminalSummary'
  if (summaryType === 'merchant') {
    fileName = getFileName('Merchant-summary', filter)
    id = 'printableMerchantSummary'
  }

  await print(fileName, id, merchantPrintStyles)
}

const settlementFilterState = state => ({
  terminalSummary: settlementSlice.selectors.getTerminalSummary(state),
  merchantSummary: settlementSlice.selectors.getMerchantSummary(state),
  filter: settlementSlice.selectors.getFilter(state),
  isLoading: settlementSlice.selectors.isLoading(state)
})

const mapStateToProps = state => ({
  ...settlementFilterState(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...accountSlice.actions
    },
    dispatch
  )
})

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component)
