import React from 'react'
import FormikInput from 'components/FormikWrappers/FormikInput'
import Button from 'components/Button/Button'
import Message from 'components/Message/Message'
import Container from './UpdatePasswordFormContainer'
import withStyles from 'react-jss'
import styles from './UpdatePasswordForm.styles'
import { PasswordConditions } from 'components'
import FieldSection from 'components/common/FieldSection'

const UpdatePasswordForm = props => {
  const { handleSubmit, isSubmitting, errors, touched, classes, cancelPasswordChange } = props
  const { newPassword: passwordErrors, serverValidation: errorCode } = errors
  const { newPassword: passwordTouched } = touched
  const isButtonDisabled = isSubmitting

  return (
    <form onSubmit={handleSubmit} name="UpdatePasswordForm">
      <FieldSection title={'Current Password'} noBorder>
        <FormikInput name="currentPassword" type="password" autoComplete="Current Password" />
      </FieldSection>

      <FieldSection title={'New Password'} noBorder>
        <>
          <FormikInput name="newPassword" type="password" autoComplete="New Password" />
          <PasswordConditions isError={passwordErrors && passwordTouched} />
        </>
      </FieldSection>

      <FieldSection title={'Confirm Password'}>
        <FormikInput name="confirmPassword" type="password" autoComplete="Confirm Password" />
      </FieldSection>

      <div className={classes['message']}>
        {errorCode && (
          <Message text="Sorry, there was an error. Please try again later." variant="error" showIcon={false} />
        )}
      </div>

      <div className={classes['actions']}>
        <div className={classes['actionItem1']}>
          <Button variant="primary" type="submit" disabled={isButtonDisabled}>
            {isSubmitting ? 'Updating Password' : 'Update Password'}
          </Button>
        </div>
        <div className={classes['actionItem2']}>
          <Button variant="secondary" onClick={cancelPasswordChange}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  )
}
export default withStyles(styles)(Container(UpdatePasswordForm))
