import React from 'react'
import PropTypes from 'prop-types'

const TableRow = ({ classes, card, type, count, purchase, cashout, total }) => {
  return (
    <tr data-hj-suppress>
      <td className={classes['colCard']}>{card} </td>
      <td className={classes['colType']}> {type}</td>
      <td className={classes['colCount']}>{count}</td>
      <td className={classes['colPurchase']}>{purchase}</td>
      <td className={classes['colCashout']}>{cashout}</td>
      <td className={classes['colTotal']}>{total}</td>
    </tr>
  )
}

TableRow.propTypes = {
  card: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  purchase: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  cashout: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired
}

export default TableRow
