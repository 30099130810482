import React, { useState } from 'react'
import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import { createUseStyles } from 'react-jss'
import styles from './MerchantSummary.styles'
import PropTypes from 'prop-types'
import LoadingSettlementTable from '../LoadingIndicators/LoadingTable'
import SettlmentExport from '../SettlementExport/SettlementExport'
import SettlementDate from 'components/common/SettlementDate/SettlementDate'
import TableHeader from '../SettlementTable/TableHeader'
import TableRow from '../SettlementTable/TableRow'
import CardTypeRow from '../SettlementTable/CardTypeRow'
import CardType from '../CardType/CardType'
import CardGraph from '../CardGraph/CardGraph'
import { hasDataToExport } from '../SettlementExport/SettlementExportContainer'
import { getMerchantSummaryChartData } from '../Summary.helper'
import PrintableMerchantSummary from './PrintableMerchantSummary'
import { getCurrency } from 'utils/number.utils'
import { formatDate } from 'utils/filters.utils'
import { SERVER_DATE_FORMAT } from 'constants/general.constants'
import { CREDIT_TO_BANK_BASE_COLOR, DEFERED_BASE_COLOR } from 'constants/colors.contants'

const useStyles = createUseStyles(styles)

const SettlementTable = ({ data, type, classes, baseColor, isLoading }) => {
  if (isLoading) return <LoadingSettlementTable {...{ classes }} />

  const { cardTypes, totals } = data
  const { tranTypes: totalTranTypes, totalTransactionAmount, totalCount, totalFinAmount, totalAdditionalAmt } = totals

  return (
    <table className={classes['table']}>
      <TableHeader {...{ classes }} />
      <tbody>
        {cardTypes.map((item, cardIndex) => {
          const { length: cardsCount } = cardTypes
          const { cardType, totalTransactionAmount, tranTypes } = item
          return tranTypes.map((item, index) => {
            const length = tranTypes.length
            const typeField = <CardType {...{ classes, baseColor, cardIndex, cardsCount, cardType }} />

            return (
              <CardTypeRow
                key={cardType + index}
                {...{
                  index,
                  length,
                  item,
                  typeField,
                  totalTransactionAmount,
                  classes,
                  uniqueKey: cardType + index
                }}
              />
            )
          })
        })}
        {totalTranTypes.length > 0 &&
          totalTranTypes.map((item, index) => {
            const { length } = totalTranTypes
            const typeField = 'All cards'
            return (
              <CardTypeRow
                key={typeField + index}
                {...{
                  index,
                  length,
                  item,
                  typeField,
                  totalTransactionAmount,
                  classes,
                  uniqueKey: typeField + index
                }}
              />
            )
          })}
      </tbody>
      <tfoot>
        <TableRow
          {...{
            classes,
            card: (
              <Text variant="h5" color={colors.heading}>
                {type} Total
              </Text>
            ),
            type: '',
            count: totalCount,
            purchase: getCurrency(totalFinAmount),
            cashout: getCurrency(totalAdditionalAmt),
            total: getCurrency(totalTransactionAmount)
          }}
        />
      </tfoot>
    </table>
  )
}

export const CreditToBank = ({ data, classes, baseColor, isLoading }) => {
  const type = 'Credit to Bank'
  return <SettlementTable {...{ data, type, classes, baseColor, isLoading }} />
}

export const Deferred = ({ data, classes, baseColor, isLoading }) => {
  const type = 'Deferred'
  return <SettlementTable {...{ data, type, classes, baseColor, isLoading }} />
}

export const Content = ({ classes, filter, data, isLoading }) => {
  const { creditToBank, deferred } = data || {}
  const { cardAcceptorIdCodes, displayName, settlementDateFrom, settlementDateTo } = filter || {}

  const noResults = !isLoading && !hasDataToExport(undefined, data, filter)

  const ctbGraphData = isLoading ? [] : getMerchantSummaryChartData(creditToBank)
  const deferredGraphData = isLoading ? [] : getMerchantSummaryChartData(deferred)

  return (
    <>
      <div className={classes['title']}>
        <div className={'name'} data-hj-suppress>
          <Text variant="h2" color={colors.greyDark}>
            {displayName}
          </Text>
        </div>
        <div className={'date'}>
          <SettlementDate
            {...{
              from: formatDate(settlementDateFrom, SERVER_DATE_FORMAT),
              to: formatDate(settlementDateTo, SERVER_DATE_FORMAT)
            }}
          />
        </div>
        <div className={'number'}>
          <Text variant="h5" color={colors.greyDark}>
            Merchant No.{' '}
          </Text>{' '}
          {cardAcceptorIdCodes}
        </div>
      </div>

      {noResults ? (
        <div className={classes['emptyMessageContainer']}>
          <Text variant="h4">No merchant summary to display.</Text>
        </div>
      ) : (
        <MerchantSummarySection {...{ classes, creditToBank, isLoading, ctbGraphData, deferred, deferredGraphData }} />
      )}
    </>
  )
}

const MerchantSummarySection = ({ classes, creditToBank, isLoading, ctbGraphData, deferred, deferredGraphData }) => {
  return (
    <>
      <div className={classes['section']}>
        <div className={'title'}>
          <Text variant="h4" color={colors.greyDark}>
            Credit to bank{' '}
          </Text>
        </div>
        <div className={'table'}>
          <CreditToBank {...{ data: creditToBank, classes, baseColor: CREDIT_TO_BANK_BASE_COLOR, isLoading }} />
        </div>
        <div className={'graph'}>
          <CardGraph {...{ data: ctbGraphData, isLoading, baseColor: CREDIT_TO_BANK_BASE_COLOR }} />
        </div>
      </div>
      <div className={classes['section']}>
        <div className={'title'}>
          <Text variant="h4" color={colors.greyDark}>
            Deferred
          </Text>
        </div>
        <div className={'table'}>
          <Deferred {...{ data: deferred, classes, baseColor: DEFERED_BASE_COLOR, isLoading }} />
        </div>
        <div className={'graph'}>
          <CardGraph {...{ data: deferredGraphData, isLoading, baseColor: DEFERED_BASE_COLOR }} />
        </div>
      </div>
      <div className={classes['tabletGraph']}>
        <div className={'graph'}>
          <div>
            <Text variant="h5"> Credit to bank</Text>
          </div>
          <CardGraph {...{ data: ctbGraphData, isLoading, baseColor: CREDIT_TO_BANK_BASE_COLOR }} />
        </div>
        <div className={'graph'}>
          <div>
            <Text variant="h5">Deferred </Text>
          </div>
          <CardGraph {...{ data: deferredGraphData, isLoading, baseColor: DEFERED_BASE_COLOR }} />
        </div>
      </div>
    </>
  )
}

const MerchantSummary = ({ filter, data, isLoading }) => {
  const classes = useStyles()
  const [renderPrint, setRenderPrint] = useState(false)

  const enablePrint = isRender => {
    setRenderPrint(isRender)
  }

  return (
    <>
      <div className={classes['wrapper']}>
        <div className={classes['header']}>
          <div className={'title'}>
            <Text variant="h3" color={colors.greyDark}>
              Merchant Summary
            </Text>
          </div>
          <div className={'export'}>
            <SettlmentExport {...{ enablePrint }} />
          </div>
        </div>
        <div className={classes['content']}>
          <Content {...{ classes, filter, data, isLoading }} />
        </div>
      </div>
      {renderPrint && <PrintableMerchantSummary {...{ filter, data, isLoading }} />}
    </>
  )
}

MerchantSummary.prototype = {
  filter: PropTypes.object,
  data: PropTypes.object,
  isLoading: PropTypes.bool
}

MerchantSummary.defaulProps = {
  isLoading: false,
  data: {
    creditToBank: undefined,
    deferred: undefined
  }
}

export default MerchantSummary
