import React from 'react'
import PropTypes from 'prop-types'

const TableHeader = ({ classes }) => {
  return (
    <thead>
      <tr>
        <th className={classes['colCard']}>Card Type</th>
        <th className={classes['colType']}>Type</th>
        <th className={classes['colCount']}>Count</th>
        <th className={classes['colPurchase']}>Purchase</th>
        <th className={classes['colCashout']}>Cash Out</th>
        <th className={classes['colTotal']}>Total</th>
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default TableHeader
