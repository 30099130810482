import React from 'react'
import { map } from 'lodash'
import { TerminalSummaryTable } from './TerminalSummary'
import PropTypes from 'prop-types'
import { formatDateForPrint } from 'utils/filters.utils'
import { CREDIT_TO_BANK_BASE_COLOR } from 'constants/colors.contants'

const PrintableTerminalSummary = ({ filter, data }) => {
  const { settlementTerminalIds } = data || {}
  const { displayName, settlementDateFrom, settlementDateTo } = filter || {}
  return (
    <div style={{ display: 'none' }}>
      <div id="printableTerminalSummary">
        <h2>Terminal Summary</h2>
        {map(settlementTerminalIds, terminal => {
          const { terminalId } = terminal
          return (
            <div key={terminalId} className="group">
              <div className="header">
                <div className="title" data-hj-suppress>
                  <b>{displayName} </b>{' '}
                </div>
                <div className="title">
                  {formatDateForPrint(settlementDateFrom)} - {formatDateForPrint(settlementDateTo)}{' '}
                </div>
                <div className="title">
                  <b>Terminal Id.</b> {terminalId}{' '}
                </div>
              </div>
              <div className={['section']}>
                <TerminalSummaryTable {...{ data: terminal, classes: {}, baseColor: CREDIT_TO_BANK_BASE_COLOR }} />
              </div>
              <br />
            </div>
          )
        })}
      </div>
    </div>
  )
}

PrintableTerminalSummary.prototype = {
  filter: PropTypes.object,
  data: PropTypes.object
}

export default PrintableTerminalSummary
