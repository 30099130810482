const styles = {
  modalContent: {
    marginTop: '1rem'
  },
  wrapper: {
    display: 'flex',
    width: '125px',
    cursor: 'pointer'
  },
  text: {
    flex: [1, 1, 'auto'],
    fontSize: '1.2rem',
    padding: ['0.25rem', 0, 0, '0.25rem']
  }
}

export default styles
