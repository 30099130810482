import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  form: {
    // padding: '1.5rem 0.75rem'
  },
  primaryAction: {
    display: 'flex',
    margin: ['2.5rem', 0],
    justifyContent: 'center',
    [MOBILE_UP()]: {
      justifyContent: 'flex-end'
    }
  },

  button: {
    width: '250px',
    [mediaQueryHelper.TABLET_DOWN()]: {
      width: '100%'
    }
  },
  updateBtn: {
    flex: [1, 1, 'auto']
  },
  message: {
    margin: '1rem 0',
    textAlign: 'center'
  },
  passwordSection: {
    display: 'flex',
    flexDirection: 'row',

    '& .input': {
      flex: [1, 1, 'auto']
    },
    '& .link': {
      padding: ['0.5rem', 0, '0.5rem', '1rem']
    }
  }
}

export default styles
