import React from 'react'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER } from 'constants/general.constants'
import Avatar from 'components/common/Avatar'
import _ from 'lodash'
import { getInitials } from 'utils/user.utils'

const getDisplayStoreName = stores => {
  if (stores.length > 1) {
    return 'Multiple'
  }
  if (stores[0] != null) {
    return _.head(stores).displayName
  }

  return 'N/A'
}

const AccordionHeader = ({ classes, user, isOpen }) => {
  const { email, name, state, stores, role } = user
  const initials = getInitials(user)
  const storeName = [ROLE_OWNER, ROLE_GLOBAL_VIEWER].includes(role) ? 'All' : getDisplayStoreName(stores)

  if (isOpen) {
    return (
      <div className={classes['openHeader']} data-hj-suppress>
        <div className={classes['avatarName']}>
          <Avatar title={initials} height={'2.5rem'} width={'2.5rem'} fontSize={'1rem'} role={role} />
          <div>
            <Text variant="body" color={colors.greyDark}>
              {name}
            </Text>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* Mobile Header */}
      <div className={classes['mobileHeader']} data-hj-suppress>
        <div className={'line1'}>
          <div className={classes['avatarName']}>
            <Avatar title={initials} height={'2.5rem'} width={'2.5rem'} fontSize={'1rem'} role={role} />
            <div>
              <Text variant="body">{name}</Text>
            </div>
          </div>
        </div>
        <div className={'line2'}>
          <div className={classes['role']}>
            <Text variant="body" color={colors.greyDark}>
              {role}
            </Text>
          </div>
          <div className={classes['store']}>
            <Text variant="body" color={colors.greyDark}>
              {storeName}
            </Text>
          </div>
        </div>
      </div>

      {/* Desktop Header */}
      <div className={classes['header']} data-hj-suppress>
        <div className={classes['avatar']}>
          <Avatar title={initials} height={'2.5rem'} width={'2.5rem'} fontSize={'1rem'} role={role} />
        </div>
        <div className={classes['name']}>
          <Text variant="body" color={colors.greyDark}>
            {name}
          </Text>
        </div>
        <div className={classes['email']}>
          <Text variant="body" color={colors.greyDark}>
            {email}
          </Text>
        </div>
        <div className={classes['store']}>
          <Text variant="body" color={colors.greyDark}>
            {storeName}
          </Text>
        </div>
        <div className={classes['status']}>
          <Text variant="body" color={colors.greyDark}>
            {state}
          </Text>
        </div>
        <div className={classes['role']}>
          <Text variant="body" color={colors.greyDark}>
            {role}
          </Text>
        </div>
      </div>
    </>
  )
}

export default AccordionHeader
