const styles = {
  callToAction: {
    padding: ['1.5rem', '1rem', '2rem', '1rem'],
    display: 'flex',
    '& >button': {
      margin: 'auto'
    }
  }
}

export default styles
