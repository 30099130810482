const styles = {
  container: {
    textAlign: 'center'
  },

  title: {
    '& > hr': {
      marginTop: '1rem',
      marginBottom: '1rem'
    }
  },

  body: {
    '& > *': {
      marginTop: '1rem'
    }
  },

  footer: {
    '& > *': {
      marginTop: '1.75rem'
    }
  }
}

export default styles
