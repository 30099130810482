import React from 'react'
import withStyles from 'react-jss'
import styles from './StoreCustomersCard.styles'
import { DashboardCard } from 'components/common'
import { GRAPH_REVENUE_COLOR } from 'constants/colors.contants'
import StoreCardGraphLayout from 'components/StorePerformance/StoreCardGraphLayout/StoreCardGraphLayout'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'
import useReturnCustomers from 'reduxSlices/dashboardSlice/hooks/useReturnCustomers'
import StoreCustomerChart from './StoreCustomersChart'
import { generateStoreCustomerCommentary } from '../StorePerformance.helper'

import _ from 'lodash'

const StoreCustomersCard = ({ classes }) => {
  const { filter } = useFilters()
  const { primaryStore, secondaryStore } = useReturnCustomers()

  const { isComparePeriod, comparedStore, store } = filter

  const { data: pData, isLoading: isPrimaryLoading } = primaryStore

  const { data: sData, isLoading: isSecondaryLoading } = secondaryStore

  const currentRepeatCustomerAverage = _.isEmpty(pData) ? 0 : pData.current.repeatCustomerAverage
  const currentNewCustomerAverage = _.isEmpty(pData) ? 0 : pData.current.newCustomerAverage
  const repeatCustomerChange = _.isEmpty(pData)
    ? 0
    : pData.current.repeatCustomerPercentage - pData.previous.repeatCustomerPercentage
  const newCustomerChange = _.isEmpty(pData)
    ? 0
    : pData.current.newCustomerPercentage - pData.previous.newCustomerPercentage

  const commentary =
    _.isEmpty(pData) || pData.isCurrentGraphEmpty
      ? 'There are no transactions for this period.'
      : generateStoreCustomerCommentary(
          repeatCustomerChange,
          newCustomerChange,
          currentRepeatCustomerAverage,
          currentNewCustomerAverage
        )

  const PrimaryStoreChart = (
    <StoreCustomerChart {...{ classes, data: pData, isComparePeriod, isLoading: isPrimaryLoading }} />
  )
  const SecondaryStoreChart = !!comparedStore ? (
    <StoreCustomerChart {...{ classes, data: sData, isComparePeriod, isLoading: isSecondaryLoading }} />
  ) : null

  return (
    <DashboardCard
      {...{
        title: 'Customer Loyalty',
        iconName: 'customer',
        isResizeable: false,
        showHeaderBorder: true
      }}
    >
      <div className={classes['cardContent']}>
        <div className={classes['currentStore']}>
          <StoreCardGraphLayout
            {...{
              Chart: PrimaryStoreChart,
              totalColor: GRAPH_REVENUE_COLOR,
              commentary,
              storeName: store ? store.displayName : '',
              isCompareStore: !!comparedStore,
              isLoading: isPrimaryLoading,
              graphHeight: 'auto'
            }}
          />
        </div>

        {!!comparedStore && (
          <div className={classes['comparedStore']}>
            <StoreCardGraphLayout
              {...{
                Chart: SecondaryStoreChart,
                storeName: comparedStore ? comparedStore.displayName : '',
                isCompareStore: !!comparedStore,
                graphHeight: 'auto'
              }}
            />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

StoreCustomersCard.prototype = {}

export default withStyles(styles)(StoreCustomersCard)
