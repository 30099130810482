import React from 'react'
import Divider from 'components/Divider/Divider'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import FormikInput from 'components/FormikWrappers/FormikInput'
import FormikCheckbox from 'components/FormikWrappers/FormikCheckbox'
import SSORegistrationErrorMessage from './SSORegistrationErrorMessage/SSORegistrationErrorMessage'
import Container from './SSORegistrationFormContainer'
import withStyles from 'react-jss'
import styles from './SSORegistrationForm.styles'
import { LinkTC } from 'components/common'

const Subtitle = () => {
  return (
    <Text variant="h5">
      Sign up to Insights and view all the transactional and settlement information for your stores.
    </Text>
  )
}

const SSORegistrationForm = props => {
  const { handleSubmit, isSubmitting, errors, goToLogin, classes } = props

  const { serverValidation: errorCode } = errors

  const allowSubmit = !isSubmitting

  return (
    <form onSubmit={handleSubmit} name="SSORegistrationForm" data-testid="SSORegisterForm">
      <div className={classes['container']}>
        <div className={classes['item']}>
          <Text variant="h1">Registration</Text>
        </div>
        <div className={classes['item']}>
          <Divider />
        </div>
        <div className={classes['item']}>
          <Subtitle />
        </div>

        <div className={classes['form']}>
          <FormikInput name="firstName" label={'First Name'} disabled={true} />
          <FormikInput name="lastName" label={'Last Name'} disabled={true} />
          <FormikInput name="email" label={'Email'} disabled={true} />
          <FormikInput
            name="merchantId"
            label={'Merchant Number*'}
            note="You can find this on the second page of your Worldline statement/invoice"
          />

          <div className={classes['conditions']}>
            <FormikCheckbox name="tcAgreement" label={<LinkTC />} />
          </div>

          {errorCode && <SSORegistrationErrorMessage {...{ goToLogin, errorCode }} />}
          <Button size="full" type="submit" disabled={!allowSubmit}>
            Register
          </Button>
        </div>

        <div className={classes['item']}>
          <Divider />
        </div>
      </div>
    </form>
  )
}

export default withStyles(styles)(Container(SSORegistrationForm))
