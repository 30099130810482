import React from 'react'
import Menu from 'components/Menu/Menu'
import Icon from 'components/Icon/Icon'
import Logo from 'components/Logo/Logo'
import {
  DashboardRoute,
  TransactionRoute,
  SettlementRoute,
  AccountSettingsMyAccountRoute,
  ContactUsRoute,
  InviteUserRoute,
  AddStoreRoute,
  AccountRoute,
  AccountSettingsUsersRoute,
  AccountSettingsStoresRoute
} from 'Routes'
import useMyAccount from 'components/MyAccount/useMyAccount'
import { SERVER_ROLE_OWNER } from 'constants/general.constants'
import { goBack } from 'utils/navigation.utils'
import { logout as myLogout } from 'utils/logout.utils'
import { getConfig } from 'utils/config.utils'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_SWITCH_APP } from 'constants/mixpanel.constants'

const { worldviewAppBaseUrl } = getConfig()

const Header = ({ activeLink, history, showBack, onNavigateBeforeClick }) => {
  const { Mixpanel: mixpanel } = apis
  const { user } = useMyAccount()
  const { role } = user
  const isOwner = SERVER_ROLE_OWNER === role

  const spaLogout = e => {
    e && e.preventDefault()

    //Letting the menu close before destoying the page. The popover has a transition if 350 ms
    setTimeout(() => {
      myLogout()
    }, 400)
  }

  const openInsightsPoductLink = () => {
    history.push(DashboardRoute.path)
  }

  const navLinks = [
    {
      name: 'Dashboard',
      icon: <Icon name="dashboard" width={'0.85em'} />,
      path: DashboardRoute.path,
      onClick: () => {
        history.push(DashboardRoute.path)
      }
    },
    {
      name: 'Transactions',
      icon: <Icon name="transaction" width={'0.85em'} />,
      path: TransactionRoute.path,
      onClick: () => {
        history.push(TransactionRoute.path)
      }
    },
    {
      name: 'Settlement',
      icon: <Icon name="settlement" width={'0.9em'} />,
      path: SettlementRoute.path,
      onClick: () => {
        history.push(SettlementRoute.path)
      }
    }
  ]

  const appsLinks = [
    {
      name: 'Worldview',
      onClick: () => {
        const url = worldviewAppBaseUrl
        window.open(url)
        const mixpanelSwitchAppData = mixpanelData.switchAppData({ path: AccountRoute.path, url })
        mixpanel.track(EVENT_SWITCH_APP, mixpanelSwitchAppData)
      }
    },
    {
      name: 'Worldline Website',
      onClick: () => {
        window.open('https://www.paymark.co.nz/')
        const mixpanelSwitchAppData = mixpanelData.switchAppData({
          path: AccountRoute.path,
          url: 'https://www.paymark.co.nz/'
        })
        mixpanel.track(EVENT_SWITCH_APP, mixpanelSwitchAppData)
      }
    }
  ]

  const menuItems = [
    {
      name: 'Account',
      icon: <Icon name="account" width={'1em'} />,
      path: AccountSettingsMyAccountRoute.path,
      onClick: () => {
        history.push(AccountSettingsMyAccountRoute.path)
      },
      subPaths: [AccountSettingsUsersRoute.path, AccountSettingsStoresRoute.path]
    },
    {
      name: 'Support Centre',
      icon: <Icon name="support-centre" width={'0.90em'} />,
      onClick: () => {
        window.open('https://www.paymark.co.nz/support/paymark-insights', '_blank')
      }
    },
    {
      name: 'Log out',
      icon: <Icon name="logout" width={'0.85em'} />,
      onClick: spaLogout
    }
  ]

  const secondaryMenuItems = [
    {
      name: 'Invite User',
      icon: <Icon name="customer" width={'0.85em'} />,
      path: InviteUserRoute.path,
      onClick: () => {
        history.push(InviteUserRoute.path)
      }
    },
    {
      name: 'Add New Store',
      icon: <Icon name="store" width={'0.85em'} />,
      path: AddStoreRoute.path,
      onClick: () => {
        history.push(AddStoreRoute.path)
      }
    }
  ]

  // Used to filter title only
  const nestedRoutes = [
    {
      name: 'Contact Us',
      path: ContactUsRoute.path
    }
  ]

  let activeRoute = [...navLinks, ...appsLinks, ...menuItems, ...nestedRoutes, ...secondaryMenuItems].filter(
    ({ path, subPaths }) => {
      if (path === activeLink) {
        return true
      }
      if (subPaths && subPaths.includes(activeLink)) {
        return true
      }

      return false
    }
  )

  activeRoute = activeRoute[0] ? activeRoute[0] : { name: '' }

  return (
    <Menu
      links={navLinks}
      appsLinks={isOwner ? appsLinks : null}
      activeLink={activeRoute.name}
      logo={<Logo name="insights" height={'9em'} />}
      mobileLogo={<Logo name="paymark-mobile" />}
      menuItems={menuItems}
      onLogoClick={openInsightsPoductLink}
      secondaryMenuItems={isOwner ? secondaryMenuItems : null}
      secondaryMenuIconName="plus-circle"
      showBack={showBack}
      onNavigateBeforeClick={onNavigateBeforeClick}
      timeout={500}
      showSwitchApp={false}
    />
  )
}

const PrivateHeader = props => {
  const { userName, routerProps, showBack } = props
  const { history } = routerProps

  const getActiveLink = () => {
    const {
      routerProps: { match }
    } = props
    const { path } = match
    return path
  }

  const onBack = () => {
    goBack(routerProps, AccountSettingsUsersRoute.path)
  }

  return <Header {...{ userName, activeLink: getActiveLink(), history, showBack, onNavigateBeforeClick: onBack }} />
}

export default PrivateHeader
