import React from 'react'
import jss from './TimePicker.styles'
import withStyles from 'react-jss'
import TextMask from 'react-text-mask'
import getMaskProps from '../Input/MaskHelper'
import Icon from '../Icon/Icon'
import moment from 'moment'
import PropTypes from 'prop-types'
import * as colors from 'config/colors'
export const UI_TIME_FORMAT = 'hh:mm a'

const TimePicker = props => {
  const { classes, onChange, startTime, endTime } = props

  const parseString = str => {
    const value = moment(str, UI_TIME_FORMAT)
    return value.format(UI_TIME_FORMAT)
  }

  const addTime = (str, change) => {
    let value = moment(str, UI_TIME_FORMAT)
    value = value.add(change, 'minutes')
    return value.format(UI_TIME_FORMAT)
  }

  const handleChange = e => {
    const {
      target: { value, name }
    } = e
    e.preventDefault()
    onChange({ [name]: value })
  }

  const handleBlur = e => {
    const {
      target: { value, name }
    } = e
    e.preventDefault()
    let correctedValue = parseString(value)
    onChange({ [name]: correctedValue })
  }

  const handleKeyDown = e => {
    const {
      target: { value, name },
      key
    } = e
    const CHANGES = {
      ArrowUp: +1,
      ArrowDown: -1
    }
    const changeDirection = CHANGES[key] || 0

    if (changeDirection === 0) {
      return
    }
    e.preventDefault()

    let newValue = addTime(value, changeDirection)
    onChange({ [name]: newValue })
  }

  const maskProps = {
    ...getMaskProps('time')
  }
  return (
    <div className={classes['timePicker']}>
      <div className={classes['startTime']}>
        <Icon className={classes['timeIcon']} name="time" color={colors.icon} width={'1.2em'} height={'1.2em'} />
        <TextMask
          {...maskProps}
          name="startTime"
          className={classes['startTimeInput']}
          onChange={handleChange}
          onBlur={handleBlur}
          value={startTime}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className={classes['endTime']}>
        <Icon className={classes['timeIcon']} name="time" color={colors.icon} width={'1.2em'} height={'1.2em'} />

        <TextMask
          {...maskProps}
          name="endTime"
          className={classes['endTimeInput']}
          onChange={handleChange}
          onBlur={handleBlur}
          value={endTime}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  )
}

TimePicker.propTypes = {
  override: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string
}

TimePicker.defaultProps = {
  override: {}
}

export default withStyles(jss)(TimePicker)
