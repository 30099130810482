import * as colors from 'config/colors'
import { standardFontFamily, weightRegular, weightSemiBold } from 'config/jss-vars'
import { MOBILE_UP, LAPTOP_UP } from 'config/mediaQueryHelper'
import { line1 } from 'config/colors'

const borderLine = `1px solid ${colors.line2}`
const icon = {
  padding: '0',
  margin: '0 2px 0 10px',
  [MOBILE_UP()]: {
    margin: '0 2px 0 14px'
  }
}

const baseButton = {
  float: 'left',
  border: `1px solid ${line1}`,
  cursor: 'pointer',
  width: '42px',
  height: '2.79rem',
  [LAPTOP_UP()]: {
    height: '2.44rem'
  }
}

const styles = {
  datePicker: {
    '& *.SingleDatePicker__block': {
      float: 'left'
    },
    '& *.DateInput_input__focused': {
      borderBottom: `1px solid ${colors.teal} !important`
    },
    '& *.DateInput_input:hover': {
      borderBottom: `1px solid ${colors.teal}`
    },
    '& *.DateInput_input': {
      fontFamily: standardFontFamily,
      fontWeight: weightRegular,
      color: colors.greyMedium,
      fontSize: '16px',
      lineHeight: 1.75,
      padding: '5px 7px 3px 7px',
      borderBottom: `1px solid transparent`,
      transition: 'border-bottom-color 0.3s ease-in'
    },
    '& *.DateRangePickerInput__withBorder': {
      border: `1px solid ${colors.line1}`
    },
    '& *.DateInput': {
      width: 'calc(50% - 30px)',
      [MOBILE_UP()]: {
        width: 'calc(50% - 34px)'
      }
    },
    '& *.CalendarDay__default': {
      fontWeight: weightSemiBold,
      color: colors.cyan
    },
    '& *.CalendarDay__blocked_out_of_range': {
      opacity: 0.5
    },
    '& *.CalendarDay__blocked_out_of_range:hover': {
      opacity: 0.5,
      backgroundColor: `${colors.white} !important`,
      border: borderLine,
      color: colors.line2
    },
    '& *.CalendarDay__selected': {
      border: borderLine,
      color: colors.white,
      backgroundColor: colors.teal
    },
    '& *.CalendarDay__selected_span': {
      border: borderLine,
      color: colors.teal,
      backgroundColor: colors.highlight
    },
    '& *.CalendarDay__hovered_span': {
      border: `1px solid ${colors.line2}`,
      color: colors.teal,
      backgroundColor: colors.highlight
    },
    '& *.CalendarDay__default:hover': {
      border: borderLine,
      color: colors.teal,
      backgroundColor: colors.highlight
    },
    '& *.CalendarDay__default:active': {
      border: borderLine,
      color: colors.teal,
      backgroundColor: colors.active
    },
    '& *.DateInput_fangStroke': {
      stroke: `${colors.teal}`
    },
    '& *.DayPickerKeyboardShortcuts_buttonReset': {
      display: 'none'
    },
    '& *.DayPickerNavigation_button': {
      padding: 0,
      '& >svg': {
        fill: colors.teal
      }
    },
    '& *.DayPickerNavigation_button:hover': {
      background: colors.highlight,
      '& >svg': {
        fill: colors.teal
      }
    },
    '& *.DayPickerNavigation_button:active': {
      background: colors.active,
      outlineColor: colors.active,
      '& >svg': {
        fill: colors.teal
      }
    },
    '& *.CalendarMonth_caption': {
      color: colors.greyDark,
      fontSize: '18px'
    },
    '& *.DayPicker_weekHeader_li': {
      color: colors.teal,
      fontWeight: weightSemiBold,
      letterSpacing: '0.38px',
      height: '20px'
    },
    '& *.DayPicker__withBorder': {
      boxShadow: `0 2px 6px ${colors.teal}, 0 0 0 1px ${colors.teal}`
    },
    '& *.DateRangePickerInput_calendarIcon': icon,
    '& *.DateRangePickerInput_arrow': icon,
    extend: ({ override }) => ({
      ...override['datePicker']
    })
  },
  singleDatePicker: {
    background: 'transparent',
    minWidth: '230px',
    '& *.DateInput': {
      width: 'calc(100% - 40px)'
    },
    '& *.SingleDatePickerInput__block': {
      width: '145px'
    },
    '& *.SingleDatePickerInput_calendarIcon': {
      margin: [0]
    }
  },
  prevButton: {
    ...baseButton,
    borderRadius: ['20px', '0px', '0px', '20px'],
    paddingLeft: '2px',
    extend: ({ override }) => ({
      ...override['prevButton']
    })
  },
  nextButton: {
    ...baseButton,
    borderRadius: ['0px', '20px', '20px', '0px'],
    paddingRight: '2px',
    extend: ({ override }) => ({
      ...override['nextButton']
    })
  },
  disabled: {
    opacity: '0.5',
    cursor: 'not-allowed',
    extend: ({ override }) => ({
      ...override['disabled']
    })
  }
}

export default styles
