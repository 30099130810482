import _ from 'lodash'
import { formatPercentage, toOrdinal, getCurrency } from 'utils/number.utils'
import moment from 'moment'

const generateChangeCustomerCommentary = (repeatCustomerChange, newCustomerChange) => {
  let repeatCustomerChangeAdverb = ''

  if (repeatCustomerChange > 0) {
    repeatCustomerChangeAdverb = 'up'
  } else if (repeatCustomerChange < 0) {
    repeatCustomerChangeAdverb = 'down'
  }

  const repeatCustomerChangeCommentary = `returning customers are ${repeatCustomerChangeAdverb} ${formatPercentage(
    Math.abs(repeatCustomerChange) / 100
  )}`

  let newCustomerChangeAdverb = ''
  if (newCustomerChange > 0) {
    newCustomerChangeAdverb = 'up'
  } else if (newCustomerChange < 0) {
    newCustomerChangeAdverb = 'down'
  }

  const newCustomerChangeCommentary = `new customers are ${newCustomerChangeAdverb} ${formatPercentage(
    Math.abs(newCustomerChange) / 100
  )}`

  return !!repeatCustomerChangeAdverb
    ? `Overall, ${repeatCustomerChangeCommentary} and ${newCustomerChangeCommentary} compared to the previous period.`
    : ''
}

const formatDateByRange = (value, range) => {
  let formatterString
  let nextHourTime = moment(value).add('hours', 1)
  let formattedValue
  switch (range) {
    case '1d':
      formatterString = 'ha'
      formattedValue = `${moment(value).format(formatterString)} - ${nextHourTime.format(formatterString)}`
      break

    default:
      formatterString = 'MMM Do'
      formattedValue = moment(value).format(formatterString)
      break
  }
  return formattedValue
}

const generateAverageCustomerCommentary = (currentRepeatCustomerAverage, currentNewCustomerAverage) => {
  // Only show commentary if amount/sales is more than 0
  let averageCommentaryList = []
  if (currentRepeatCustomerAverage > 0)
    averageCommentaryList.push(`${getCurrency(currentRepeatCustomerAverage)} for returning customers`)
  if (currentNewCustomerAverage > 0)
    averageCommentaryList.push(`${getCurrency(currentNewCustomerAverage)} for new customers`)

  return `Your average sale was ${averageCommentaryList.join(' and ')}.`
}

export const generateRankedCommentary = (primaryData, range, differencePercentage, metric) => {
  const periodPreposition = range === '1d' ? 'between' : 'on'

  let key = ''
  let metricDescription = ''
  let percentageChangeDescription = ''
  switch (metric) {
    case 'revenue':
      metricDescription = 'Your revenue was'
      percentageChangeDescription = 'revenue is'
      key = 'currentRevenue'
      break
    case 'sales':
      metricDescription = 'The sales were'
      percentageChangeDescription = 'sales are'
      key = 'currentSales'
      break
    default:
  }

  //filter out periods with no sales
  let sortedPrimaryData = _.chain([...primaryData])
    .filter(period => {
      return period.currentSales !== 0
    })
    .orderBy([key], ['desc'])
    .value()

  // earliest highest/ lowest should be selected
  let commentary = 'There are no transactions for this period'

  if (sortedPrimaryData.length > 0) {
    const highestPeriod = _.first(sortedPrimaryData).currentDateTime

    // Got to get first instance of lowest
    const lowestAmount = _.last(sortedPrimaryData)[key]
    const lowestPeriod = _.find(sortedPrimaryData, function(datum) {
      return datum[key] === lowestAmount
    }).currentDateTime

    const percentageCommentary = generatePercentageChangeCommentary(differencePercentage, percentageChangeDescription)

    commentary =
      `${metricDescription} highest ${periodPreposition} ${formatDateByRange(highestPeriod, range)}` +
      (highestPeriod !== lowestPeriod
        ? ` and lowest ${periodPreposition} ${formatDateByRange(lowestPeriod, range)}`
        : '') +
      '. ' +
      percentageCommentary
  }
  return commentary
}

export const getAverageSalesText = (totalRevenue, totalSales) => {
  if (!totalRevenue || !totalSales) return ''
  let averageSales = totalSales === 0 ? 0 : totalRevenue / totalSales
  averageSales = getCurrency(averageSales)
  return `Your average sales value is ${averageSales}.`
}

export const generateRankedText = (stores, selectedStore, metric) => {
  const storeRankBySales =
    _.chain(stores)
      .orderBy([metric], ['desc'])
      .findIndex(item => {
        return item.cardAcceptorIdCode === selectedStore.cardAcceptorIdCode
      }) + 1

  return stores.length > 1 ? `Ranked ${toOrdinal(storeRankBySales)} out of ${stores.length} stores` : ''
}

export const generateStoreCustomerCommentary = (
  repeatCustomerChange,
  newCustomerChange,
  currentRepeatCustomerAverage,
  currentNewCustomerAverage
) => {
  const averageCommentary = generateAverageCustomerCommentary(currentRepeatCustomerAverage, currentNewCustomerAverage)

  const changeCommentary = generateChangeCustomerCommentary(repeatCustomerChange, newCustomerChange)

  return `${averageCommentary} ${changeCommentary}`
}

export const generatePercentageChangeCommentary = (differencePercentage, description) => {
  let percentageCommentary = ''
  if (differencePercentage > 0) {
    percentageCommentary = `Overall, ${description} up ${formatPercentage(
      Math.abs(differencePercentage)
    )} compared to the previous period.`
  } else if (differencePercentage < 0) {
    percentageCommentary = `Overall, ${description} down ${formatPercentage(
      Math.abs(differencePercentage)
    )} compared to the previous period.`
  }

  return percentageCommentary
}
