import React from 'react'
import withStyles from 'react-jss'
import styles from './ConfirmationDialog.styles'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'

const ModalContent = ({ classes, onClose, onAccept, children }) => {
  return (
    <div className={classes['modalContent']}>
      <div className={classes['body']}>{children}</div>
      <div className={classes['actionBtns']}>
        <Button variant="secondary" onClick={onClose} width={'150px'}>
          No
        </Button>
        <Button variant="primary" onClick={onAccept} width={'150px'}>
          Yes
        </Button>
      </div>
    </div>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

const ConfirmationDialog = ({ children, onAccept, onClose, callbackParams }) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} size={'small'} data-testid="modal-confirmation-dialog">
        <StyledModalContent
          {...{
            onClose,
            onAccept: () => {
              onAccept(callbackParams)
            }
          }}
        >
          {children}
        </StyledModalContent>
      </Modal>
    </>
  )
}

export default ConfirmationDialog
