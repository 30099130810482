import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, MOBILE_DOWN } = mediaQueryHelper

const styles = {
  form: {},
  errorMessage: {
    textAlign: 'center',
    margin: ['1.5rem', 0]
  },
  submitButton: {
    padding: ['1.5rem', 0],
    borderTop: `1px solid ${colors.line2}`,
    borderBottom: `1px solid ${colors.line1}`,
    display: 'flex',
    justifyContent: 'center'
  },
  permissionRadios: {
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
      marginRight: '2rem'
    },
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  storeAccess: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: ['1rem', 0],
    flexDirection: 'column',
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center'
  },
  subtitle: {
    textAlign: 'center',
    margin: ['1.5rem', 0, '1rem', 0],
    wordBreak: 'break-word'
  },
  note: {
    textAlign: 'center',
    wordBreak: 'break-word'
  },
  showMore: {
    display: 'flex',
    '& >div': {
      marginLeft: '0.5rem'
    },
    [MOBILE_DOWN()]: {
      justifyContent: 'center',
      paddingTop: '1rem'
    }
  },
  globalStores: {
    marginBottom: '1.5rem'
  },
  search: {
    marginBottom: '1rem'
  },
  addSearchResult: {
    marginTop: '1rem'
  }
}

export default styles
