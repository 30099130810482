import React from 'react'
import ContentLoader from 'react-content-loader'
import { FieldSection } from 'components/common'
import { isMobileWidth, isTabletWidth } from 'utils/common.utils'

const StoreBodyLoader = ({ classes }) => {
  const SearchHeader = ({ classes }) => {
    const TITLE = 'SET UP USERS TO VIEW THIS STORE'

    return (
      <div className={classes['headerTitle']}>
        <h5>{TITLE}</h5>
      </div>
    )
  }

  const height = isMobileWidth() ? 30 : isTabletWidth() ? 15 : 7.5

  return (
    <div className={classes['storeBody']}>
      <FieldSection title={'Business Name'} classes={classes}>
        <ContentLoader height={height} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="5" ry="5" width="200" height={height} />
        </ContentLoader>
      </FieldSection>
      <FieldSection title={'Preferred Name'} classes={classes}>
        <ContentLoader height={height} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="5" ry="5" width="200" height={height} />
        </ContentLoader>
      </FieldSection>
      <FieldSection title={'Merchant Number'} classes={classes}>
        <ContentLoader height={height} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <rect x="0" y="0" rx="5" ry="5" width="200" height={height} />
        </ContentLoader>
      </FieldSection>
      <FieldSection title={'Users'} classes={classes}></FieldSection>

      <SearchHeader classes={classes} />
    </div>
  )
}

export default StoreBodyLoader
