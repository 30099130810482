import * as Yup from 'yup'
import { MERCHANT_ID_REGEX } from 'utils/validation.utils'
import api from 'api'
import FormikForm from 'components/FormikWrappers/FormikForm'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_ADD_MERCHANT, EVENT_ADD_MERCHANT_FAILURE } from 'constants/mixpanel.constants'

const getErrorMessage = errorCode => {
  let errorMessage = ''
  switch (errorCode) {
    case 'invalid_merchant':
    case 'no_fee':
    case 'aggregate':
      errorMessage =
        'Sorry, Insights is not available for that Merchant Number. Please contact your Worldline Account Manager or Help Desk on 0800 729 627.'
      break
    case 'already_active':
      errorMessage =
        'This Merchant has already been added to your account. Try adding a different Merchant or alternatively please call 0800 729 627 for assistance.'
      break
    case 'incorrect_email_mid':
      errorMessage =
        'Sorry, you cannot access Insights for that Merchant Number. Please try another Merchant Number or phone Help Desk on 0800 729 627 to get access.'
      break

    default:
      errorMessage = 'Sorry, there was an error. Please try again later.'
      break
  }

  return errorMessage
}

const validationSchema = Yup.object().shape({
  cardAcceptorIdCode: Yup.string()
    .required('Merchant Number is required.')
    .matches(MERCHANT_ID_REGEX, 'Enter a valid merchant number.'),
  tcAgreement: Yup.bool().oneOf(
    [true],
    'You need to accept the Terms & Conditions and Privacy Policy before proceeding.'
  )
})

const handleSubmit = async (values, params) => {
  const { setSubmitting, setErrors, setFieldValue } = params
  const { cardAcceptorIdCode } = values
  const mixpanelAddMerchantData = mixpanelData.addMerchantData(values)
  const { Mixpanel: mixpanel } = api

  try {
    await api.signUpStore(cardAcceptorIdCode)
    setFieldValue('updated', true)

    mixpanel.identify(values.email)
    mixpanel.peopleSet(mixpanelAddMerchantData)
    mixpanel.track(EVENT_ADD_MERCHANT, mixpanelAddMerchantData)
  } catch (error) {
    const { error: errorCode } = error
    setSubmitting(false)
    setErrors({
      serverValidation: getErrorMessage(errorCode)
    })
    const mixpanelAddMerchantFailureData = mixpanelData.addMerchantFailureData(values, errorCode)
    mixpanel.track(EVENT_ADD_MERCHANT_FAILURE, mixpanelAddMerchantFailureData)
  }
}

const mapPropsToValues = () => {
  return { cardAcceptorIdCode: '', tcAgreement: false, updated: false }
}

export const formikObject = {
  displayName: 'AddNewStoreForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
