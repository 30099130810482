import React from 'react'
import ContentLoader from 'react-content-loader'
import { isMobileWidth } from 'utils/common.utils'

const height = 150
const barHeight = 75

const totalWidth = isMobileWidth() ? 500 : 1000
const y = isMobileWidth() ? 60 : 30

const start = 0.15 * totalWidth
const width = 0.7 * totalWidth

const CardsUsedLoader = () => {
  return (
    <ContentLoader height={height} width={totalWidth} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x={start} y={y} rx="5" ry="5" width={width} height={barHeight} />
    </ContentLoader>
  )
}

export default CardsUsedLoader
