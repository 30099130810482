import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import api from 'api'
import { parseRepeatCustomerData } from 'reduxSlices/dashboardSlice/repeatCustomerAgg.helper'
import { dashboardSlice } from '../index'
import { parseCardsUsed } from 'reduxSlices/dashboardSlice/cardAgg.helper'
import { parseData } from 'reduxSlices/dashboardSlice/transactionAggregate.helper'
import { createCustomSelector } from 'utils/common.utils'

const initialState = {
  returnCustomers: {
    primary: {
      isLoading: true,
      data: {}
    },
    secondary: {
      isLoading: true,
      data: {}
    }
  },
  cardsUsed: {
    primary: {
      isLoading: true,
      data: {}
    },
    secondary: {
      isLoading: true,
      data: {}
    }
  },
  salesRevenueAgg: {
    primary: {
      isLoading: true,
      data: [],
      currentSalesTotal: 0,
      currentRevenueTotal: 0,
      previousSalesTotal: 0,
      previousRevenueTotal: 0
    },
    secondary: {
      isLoading: true,
      data: [],
      currentSalesTotal: 0,
      currentRevenueTotal: 0,
      previousSalesTotal: 0,
      previousRevenueTotal: 0
    }
  }
}

const getPrimaryStoreCardsUsed = createCustomSelector('store.cardsUsed.primary')
const getSecondaryStoreCardsUsed = createCustomSelector('store.cardsUsed.secondary')

const getPrimaryStoreSalesRevenueAgg = createCustomSelector('store.salesRevenueAgg.primary')
const getSecondaryStoreSalesRevenueAgg = createCustomSelector('store.salesRevenueAgg.secondary')

const getPrimaryReturnCustomers = createCustomSelector('store.returnCustomers.primary')
const getSecondaryReturnCustomers = createCustomSelector('store.returnCustomers.secondary')

const selectors = {
  getPrimaryStoreCardsUsed,
  getSecondaryStoreCardsUsed,
  getPrimaryStoreSalesRevenueAgg,
  getSecondaryStoreSalesRevenueAgg,
  getPrimaryReturnCustomers,
  getSecondaryReturnCustomers
}

const getStoreTypeKey = isSecondaryStore => {
  return isSecondaryStore ? 'secondary' : 'primary'
}

const fetchReturnCustomersReducer = (state, action) => {
  const { payload } = action
  const { isSecondaryStore, showLoading = true } = payload

  const { returnCustomers } = state
  const key = getStoreTypeKey(isSecondaryStore)

  const value = returnCustomers[key]
  const updatedValue = { ...value, isLoading: showLoading }

  return {
    ...state,
    returnCustomers: {
      ...returnCustomers,
      [key]: { ...updatedValue }
    }
  }
}

const fetchCardsUsedReducer = (state, action) => {
  const { payload } = action
  const { isSecondaryStore, showLoading = true } = payload

  const { cardsUsed } = state
  const key = getStoreTypeKey(isSecondaryStore)

  const value = cardsUsed[key]
  const updatedValue = { ...value, isLoading: showLoading }

  return {
    ...state,
    cardsUsed: {
      ...cardsUsed,
      [key]: { ...updatedValue }
    }
  }
}

const fetchSalesRevenueReducer = (state, action) => {
  const { payload } = action
  const { isSecondaryStore, showLoading = true } = payload
  const { salesRevenueAgg } = state
  const key = getStoreTypeKey(isSecondaryStore)

  const value = salesRevenueAgg[key]
  const updatedValue = { ...value, isLoading: showLoading }
  return {
    ...state,
    salesRevenueAgg: {
      ...salesRevenueAgg,
      [key]: { ...updatedValue }
    }
  }
}

const reducers = {
  fetchReturnCustomersPrimaryStore: fetchReturnCustomersReducer,
  fetchReturnCustomersSecondaryStore: fetchReturnCustomersReducer,
  setReturnCustomers: (state, action) => {
    const { payload } = action
    const { isSecondaryStore, ...data } = payload
    const { returnCustomers } = state
    const key = getStoreTypeKey(isSecondaryStore)
    const updatedValue = { data, isLoading: false }

    return {
      ...state,
      returnCustomers: {
        ...returnCustomers,
        [key]: { ...updatedValue }
      }
    }
  },
  fetchCardsUsedReducerPrimaryStore: fetchCardsUsedReducer,
  fetchCardsUsedReducerSecondaryStore: fetchCardsUsedReducer,
  setCardsUsed: (state, action) => {
    const { payload } = action
    const { isSecondaryStore, ...data } = payload
    const { cardsUsed } = state
    const key = getStoreTypeKey(isSecondaryStore)
    const updatedValue = { data, isLoading: false }

    return {
      ...state,
      cardsUsed: {
        ...cardsUsed,
        [key]: { ...updatedValue }
      }
    }
  },
  fetchSalesRevenueReducerPrimaryStore: fetchSalesRevenueReducer,
  fetchSalesRevenueReducerSecondaryStore: fetchSalesRevenueReducer,

  setSalesRevenue: (state, action) => {
    const { payload } = action
    const { isSecondaryStore, data, cardAcceptorIdCode } = payload
    const { salesRevenueAgg } = state
    const key = getStoreTypeKey(isSecondaryStore)
    const updatedValue = { ...data, isLoading: false, cardAcceptorIdCode }

    return {
      ...state,
      salesRevenueAgg: {
        ...salesRevenueAgg,
        [key]: { ...updatedValue }
      }
    }
  }
}

const storeSlice = createSlice({
  slice: 'store',
  initialState: initialState,
  reducers
})

function* fetchReturnCustomers(action) {
  const { payload } = action
  const { isSecondaryStore, cardAcceptorIdCode } = payload
  const filter = yield select(dashboardSlice.selectors.getFilter)
  const { isComparePeriod } = filter

  try {
    const result = yield call(api.getReturnCustomersAggByMerchant, payload)
    const parsedResult = parseRepeatCustomerData(result, isComparePeriod)

    yield put(storeSlice.actions.setReturnCustomers({ isSecondaryStore, ...parsedResult, cardAcceptorIdCode }))
  } catch (e) {
    console.error(e)
  }
}

function* fetchCardsUsed(action) {
  const { payload } = action
  const { isSecondaryStore, cardAcceptorIdCode } = payload

  try {
    const result = yield call(api.getCardsUsedAggByMerchant, payload)

    const parsedResult = parseCardsUsed(result)

    yield put(storeSlice.actions.setCardsUsed({ isSecondaryStore, ...parsedResult, cardAcceptorIdCode }))
  } catch (e) {
    console.error(e)
  }
}

function* fetchSalesRevenue(action) {
  const { payload } = action
  const { isSecondaryStore, cardAcceptorIdCode } = payload

  try {
    const result = yield call(api.getTransactionAggByMerchant, payload)
    const filter = yield select(dashboardSlice.selectors.getFilter)
    const parsedResult = parseData({ data: result, filter })

    yield put(storeSlice.actions.setSalesRevenue({ isSecondaryStore, data: parsedResult, cardAcceptorIdCode }))
  } catch (e) {
    console.error(e)
  }
}

function* storeSagas() {
  yield takeLatest(storeSlice.actions.fetchReturnCustomersPrimaryStore.toString(), fetchReturnCustomers)
  yield takeLatest(storeSlice.actions.fetchReturnCustomersSecondaryStore.toString(), fetchReturnCustomers)

  yield takeLatest(storeSlice.actions.fetchCardsUsedReducerPrimaryStore.toString(), fetchCardsUsed)
  yield takeLatest(storeSlice.actions.fetchCardsUsedReducerSecondaryStore.toString(), fetchCardsUsed)

  yield takeLatest(storeSlice.actions.fetchSalesRevenueReducerPrimaryStore.toString(), fetchSalesRevenue)
  yield takeLatest(storeSlice.actions.fetchSalesRevenueReducerSecondaryStore.toString(), fetchSalesRevenue)
}

export default {
  ...storeSlice,
  selectors,
  sagas: storeSagas
}
