const loginHelpers = (function() {
  const StorageType = {
    SESSION: 'SESSION',
    PERMANENT: 'PERMANENT'
  }

  const getToken = storageType => {
    let auth = {}

    if (storageType === StorageType.PERMANENT) {
      auth = window.localStorage.getItem('auth')
    } else if (storageType === StorageType.SESSION) {
      auth = window.sessionStorage.getItem('auth')
    }

    if (auth) {
      return JSON.parse(auth).access_token
    }

    return ''
  }

  const getHeaders = ({ accessToken }) => {
    return {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/vnd.paymark_api+json',
      'Content-Type': 'application/vnd.paymark_api+json'
    }
  }

  const base64 = (key, secret) => {
    // Nothing?
    if (!key || !secret) {
      return ''
    }

    // Return base64 encoded
    return window.btoa(`${key}:${secret}`)
  }

  const getBearerHeaders = (anonAuthKey, anonAuthSecret) => {
    const auth = base64(anonAuthKey, anonAuthSecret)

    return {
      Authorization: `Basic ${auth}`,
      Accept: 'application/vnd.paymark_api+json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  return { StorageType, getToken, getHeaders, getBearerHeaders, base64 }
})()

export default loginHelpers
