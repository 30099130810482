import FormikForm from 'components/FormikWrappers/FormikForm'
import { constructUrl, getParamsFromUrl, constructParams } from './TransactionFilter.helper'
import { validationSchema } from './TransactionFilter.validation'
import React, { Component } from 'react'
import {
  computeState,
  DATE_RANGE_CHANGE,
  DATE_CHANGE,
  STORE_SELECT,
  SHOW_SELECTOR,
  HIDE_SELECTOR,
  TOGGLE_SHOW_CUSTOM_SETTINGS,
  TIME_RANGE_CHANGE,
  TIME_CHANGE,
  CARD_TYPE_CHANGE,
  CARD_CHANGE,
  TRANSACTION_CATEGORY_CHANGE,
  TRANSACTION_TYPE_CHANGE,
  STATUS_CHANGE,
  INITIAL_STATE
} from './TransactionFilter.helper'
import { toggleFlagWithTimeout, selectUnSelectElem } from '../../utils/filters.utils'
import mixpanelData from 'utils/mixpanelObjects.utils'
import apis from 'api'
import { EVENT_TRANSACTION_SEARCH } from 'constants/mixpanel.constants'

const submit = (values, params) => {
  const { props, setSubmitting, setValues } = params
  const { history, actions } = props
  setValues({ ...values, showCustomSettings: false })

  const search = constructUrl(values)
  actions.setSelectedTransaction(null)

  //Force reload data when the params are same
  if (decodeURI(window.location.search) === '?' + decodeURI(search)) {
    const params = getParamsFromUrl(search)
    const actionParams = constructParams(params)
    actions.fetchTransactions(actionParams)
  } else {
    history.push({
      search
    })
    const params = getParamsFromUrl(search)
    const actionParams = constructParams(params)
    actions.fetchTransactions(actionParams)
  }
  setSubmitting(false)
}

const handleSubmit = (values, params) => {
  const { Mixpanel: mixpanel } = apis

  mixpanel.track(EVENT_TRANSACTION_SEARCH, mixpanelData.transactionSearchData(values))

  submit(values, params)
}

const mapPropsToValues = props => {
  const { search, merchants } = props

  const params = getParamsFromUrl(search)
  params.page = 1

  //Setting the base store if there is only one merchant
  if (merchants && merchants.length === 1) {
    params.store = merchants[0]
    params.isSingleStore = true
  }
  return params
}

const formikObject = {
  displayName: 'TransactionFilterForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
}

const withFormik = Component => {
  return FormikForm(Component, formikObject)
}

function withContainer(WrappedComponent) {
  return withFormik(
    class extends Component {
      UNSAFE_componentWillMount() {
        const { submitForm, values } = this.props
        if (values.store) {
          submitForm()
        }
      }
      updateFormik = formUpdates => {
        const { values, setValues } = this.props
        const updatedValues = { ...values, ...formUpdates }
        setValues(updatedValues)
      }

      handleShowSelector = () => {
        const stateChange = computeState({ actions: SHOW_SELECTOR })
        this.updateFormik(stateChange)
      }

      handleSelectStore = store => {
        let stateChange = null
        const { actions } = this.props
        if (store !== undefined) {
          actions.hideResult()
          stateChange = computeState({ actions: STORE_SELECT, props: { store } })
          //Updating form state in the next cycle
          setTimeout(() => {
            this.updateFormik(stateChange)
          })

          stateChange = computeState({ actions: STORE_SELECT, props: { store } })
          this.updateFormik(stateChange)
        } else {
          stateChange = computeState({ actions: HIDE_SELECTOR })
          this.updateFormik(stateChange)
        }
      }

      handleDateRangeChange = dateRange => {
        const stateChange = computeState({ actions: DATE_RANGE_CHANGE, props: { dateRange } })
        this.updateFormik(stateChange)
        this.highlight()
      }

      highlight = () => {
        toggleFlagWithTimeout(this.updateFormik)
      }

      handleDateChange = async ({ startDate, endDate }) => {
        const { validateForm } = this.props
        const stateChange = computeState({ actions: DATE_CHANGE, props: { startDate, endDate } })
        this.updateFormik(stateChange)
        await validateForm(stateChange)
      }

      handleToggleCustomSettings = async showCustomSettings => {
        const stateChange = computeState({ actions: TOGGLE_SHOW_CUSTOM_SETTINGS, props: { showCustomSettings } })
        this.updateFormik(stateChange)
      }

      getStoreError = ({ submitCount, errors }) => {
        if (submitCount > 0) {
          return errors['store']
        }
        return false
      }

      getDateError = errors => {
        const error = errors['startDate'] || errors['endDate']
        return error
      }

      getTimeError = errors => {
        const error = errors['startTime'] || errors['endTime']
        return error
      }

      getError = ({ errors, key }) => {
        return errors[key]
      }

      isDisabled = ({
        storeError,
        dateError,
        timeError,
        cardError,
        transactionTypeError,
        purchaseAmountError,
        last4DigitsError,
        terminalIdError,
        isSubmitting,
        isLoading,
        showStoreSelector
      }) => {
        const isDisabled =
          isLoading ||
          isSubmitting ||
          storeError ||
          dateError ||
          showStoreSelector ||
          timeError ||
          cardError ||
          transactionTypeError ||
          purchaseAmountError ||
          last4DigitsError ||
          terminalIdError

        return isDisabled
      }

      handleTimeRangeChange = timeRange => {
        const stateChange = computeState({ actions: TIME_RANGE_CHANGE, props: { timeRange } })
        if (!stateChange.startTime && !stateChange.endTime) {
          this.updateFormik({ timeRange })
          return
        }
        this.updateFormik(stateChange)
        this.highlight('hightlightTime')
      }

      handleTimeChange = async ({ startTime, endTime }) => {
        const { values, validateForm } = this.props
        const { startTime: dStartTime, endTime: dEndTime } = values
        startTime = startTime !== undefined ? startTime : dStartTime
        endTime = endTime !== undefined ? endTime : dEndTime
        const stateChange = computeState({ actions: TIME_CHANGE, props: { startTime, endTime } })
        this.updateFormik(stateChange)
        await validateForm(stateChange)
      }

      handleCardTypeChange = cardType => {
        const stateChange = computeState({ actions: CARD_TYPE_CHANGE, props: { cardType } })
        this.updateFormik(stateChange)
      }

      handleCardChange = card => {
        const { values } = this.props
        let { cards } = values

        selectUnSelectElem(cards, card)
        const stateChange = computeState({ actions: CARD_CHANGE, props: { cards } })
        this.updateFormik(stateChange)
      }

      handleCategoryChange = category => {
        const stateChange = computeState({ actions: TRANSACTION_CATEGORY_CHANGE, props: { category } })
        this.updateFormik(stateChange)
      }

      handleTransactionTypeChange = transactionType => {
        const { values } = this.props
        let { transactionTypes } = values
        selectUnSelectElem(transactionTypes, transactionType)
        const stateChange = computeState({ actions: TRANSACTION_TYPE_CHANGE, props: { transactionTypes } })
        this.updateFormik(stateChange)
      }

      handleStatusChange = status => {
        const stateChange = computeState({ actions: STATUS_CHANGE, props: { status } })
        this.updateFormik(stateChange)
      }

      highlight = key => {
        toggleFlagWithTimeout(this.updateFormik, key)
      }

      handleReset = () => {
        const { history } = this.props
        this.updateFormik(INITIAL_STATE)
        history.push({})
      }

      render() {
        const { errors, submitCount, isSubmitting, isLoading, showStoreSelector } = this.props

        const storeError = this.getStoreError({ submitCount, errors })
        const dateError = this.getDateError(errors)
        const timeError = this.getTimeError(errors)
        const cardError = this.getError({ errors, key: 'cards' })
        const transactionTypeError = this.getError({ errors, key: 'transactionTypes' })
        const purchaseAmountError = this.getError({ errors, key: 'purchaseAmount' })
        const last4DigitsError = this.getError({ errors, key: 'last4Digits' })
        const terminalIdError = this.getError({ errors, key: 'terminalId' })

        const isSubmitDisabled = this.isDisabled({
          storeError,
          dateError,
          timeError,
          cardError,
          transactionTypeError,
          purchaseAmountError,
          last4DigitsError,
          terminalIdError,
          isSubmitting,
          isLoading,
          showStoreSelector
        })

        return (
          <WrappedComponent
            {...{
              ...this.props,
              isSubmitDisabled,
              storeError,
              dateError,
              timeError,
              cardError,
              transactionTypeError,
              purchaseAmountError,
              last4DigitsError,
              terminalIdError,
              handleToggleCustomSettings: this.handleToggleCustomSettings,
              handleStoreTypeSelect: this.handleStoreTypeSelect,
              handleSelectStore: this.handleSelectStore,
              handleShowSelector: this.handleShowSelector,
              handleDateRangeChange: this.handleDateRangeChange,
              handleDateChange: this.handleDateChange,
              handleTimeRangeChange: this.handleTimeRangeChange,
              handleTimeChange: this.handleTimeChange,
              handleCardTypeChange: this.handleCardTypeChange,
              handleCardChange: this.handleCardChange,
              handleCategoryChange: this.handleCategoryChange,
              handleTransactionTypeChange: this.handleTransactionTypeChange,
              handleStatusChange: this.handleStatusChange,
              handleReset: this.handleReset
            }}
          />
        )
      }
    }
  )
}

export default withContainer
