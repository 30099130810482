import spaLogin from 'utils/spaLogin'
import apis from 'api'

const handleInsightsPreLogoutTasks = () => {
  //reset mixpanel
  const { Mixpanel: mixpanel } = apis
  mixpanel.reset()
}

export const logout = (handlePreLogoutTasks = handleInsightsPreLogoutTasks) => {
  spaLogin.logout(handlePreLogoutTasks)
}
