import React from 'react'
import withStyles from 'react-jss'
import styles from '../Group.styles'
import Radio from 'components/Radio/Radio'
import PropTypes from 'prop-types'

export const Statuses = [
  { label: 'All Status', value: -1 },
  { label: 'Approved', value: 1 },
  { label: 'Declined', value: 0 }
]

export const getStatusText = value => {
  const selectedStatus = Statuses.filter(status => {
    return status.value === value
  })[0]

  return selectedStatus ? selectedStatus.label : null
}

const StatusSelector = ({ classes, status, handleStatusChange }) => {
  return (
    <div className={classes['group']}>
      <div className={classes['radioButtons']} data-hj-suppress>
        {Statuses.map(item => {
          const checked = item.value === status
          return (
            <div className={classes['radioItem']} key={item.label}>
              <Radio
                label={item.label}
                checked={checked}
                onChange={() => {
                  handleStatusChange(item.value)
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

StatusSelector.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.number,
  handleStatusChange: PropTypes.func
}
export default withStyles(styles)(StatusSelector)
