import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import jss from './Divider.styles'

const Divider = ({ classes, color, height }) => {
  let className = classes['divider']

  const params = {
    className,
    color,
    height
  }
  return <hr {...params} />
}

Divider.propTypes = {
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    divider: PropTypes.object
  }),
  color: PropTypes.string,
  height: PropTypes.string
}

Divider.defaultProps = {
  override: {}
}

export default withStyles(jss)(Divider)
