import React from 'react'
import PropTypes from 'prop-types'
import deprecated from 'prop-types-extra/lib/deprecated'
import Icon from '../Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import jss from './Message.styles'

const iconNames = {
  error: { icon: 'exclamation-circle', iconColor: colors.error },
  success: { icon: 'check-circle', iconColor: colors.success },
  warning: { icon: 'exclamation-circle', iconColor: colors.warning }
}

const Message = props => {
  const { variant, text, showIcon, classes, override, ...rest } = props

  const className = `${classes[variant]}`

  return (
    <div className={className} {...rest}>
      {showIcon && <Icon name={iconNames[variant].icon} color={iconNames[variant].iconColor} />}
      {React.isValidElement(text) ? text : <p>{text}</p>}
    </div>
  )
}

Message.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.oneOf(['error', 'success', 'warning']),
  type: deprecated(PropTypes.any, 'Use `variant` instead.'),
  showIcon: PropTypes.bool,
  align: PropTypes.string,
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    message: PropTypes.object,
    success: PropTypes.object,
    'success-icon': PropTypes.object,
    error: PropTypes.object,
    'error-icon': PropTypes.object,
    warning: PropTypes.object,
    'warning-icon': PropTypes.object
  })
}

Message.defaultProps = {
  text: 'Success',
  variant: 'success',
  showIcon: true,
  align: 'center',
  override: {}
}

export default withStyles(jss)(Message)
