export const CARD_TYPE_EFTPOS = 'Eftpos'
export const CARD_TYPE_CREDIT = 'Credit'
export const CARD_TYPE_EFTPOS_CREDIT = 'Eftpos & Credit'
export const CARD_TYPE_OTHER = 'Other'
export const CARD_TYPE_ALL = 'All Cards'

export const CreditCardMapping = [
  {
    label: 'Diners',
    value: 'DINERS'
  },
  {
    label: 'Visa',
    value: 'VISA'
  },
  {
    label: 'MasterCard',
    value: 'MCARD'
  },
  {
    label: 'American Express',
    value: 'AMEX'
  },
  {
    label: 'Union Pay',
    value: 'UPICRD,CUPCRD'
  },
  {
    label: 'JCB',
    value: 'JCB'
  }
]

export const CreditCards = CreditCardMapping.map(({ label }) => label)
export const CardTypeArray = [
  CARD_TYPE_ALL,
  CARD_TYPE_EFTPOS,
  CARD_TYPE_CREDIT,
  CARD_TYPE_EFTPOS_CREDIT,
  CARD_TYPE_OTHER
]
