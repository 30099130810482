import * as colors from 'config/colors'
import { standardFontFamily, weightRegular } from 'config/jss-vars'
import { LAPTOP_UP, LARGE_SCREEN_UP } from 'config/mediaQueryHelper'
const styles = {
  container: {
    backgroundColor: colors.background2,
    padding: '3.5rem 1rem',
    position: 'relative',
    borderRadius: '3px',
    maxHeight: '500px',
    border: `1px solid ${colors.teal}`,
    extend: ({ override }) => ({
      ...override['container']
    })
  },
  searchIcon: {
    position: 'absolute',
    padding: '0.5rem',
    extend: ({ override }) => ({
      ...override['searchIcon']
    })
  },
  closeIcon: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    '& :hover': {
      opacity: 0.8
    },
    [LAPTOP_UP()]: {
      right: '0.75rem'
    },
    extend: ({ override }) => ({
      ...override['closeIcon']
    })
  },
  input: {
    width: '100%',
    color: colors.greyDark,
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    fontSize: '1rem',
    border: `1px solid ${colors.line1}`,
    height: '2.5rem',
    backgroundColor: colors.background1,
    borderRadius: '3px',
    padding: '0.5rem 0.5rem 0.5rem 2.5rem',
    '::placeholder': {
      color: colors.textPlaceholder
    },
    '&:hover': {
      border: `1px solid ${colors.cyan}`
    },
    '&:active': {
      border: `1px solid ${colors.teal}`
    },
    '&:focus': {
      outline: 'none',
      border: `1px solid ${colors.cyan}`
    },
    extend: ({ override }) => ({
      ...override['input']
    })
  },
  errorMessage: {
    padding: '1em 0.5em',
    textAlign: 'center',
    width: '100%',
    [LAPTOP_UP()]: {
      padding: '1.5em 2em'
    }
  },
  result: {
    width: '100%',
    marginTop: '-1rem',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '200px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '.69rem'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '.31rem',
      backgroundColor: colors.teal
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 .69rem rgb(216, 216, 216)'
    },

    [LAPTOP_UP()]: {
      padding: '0 30px'
    },
    extend: ({ override }) => ({
      ...override['result']
    })
  },
  resultNoScroll: {
    width: '100%',
    marginTop: '-1rem',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '260px',
    overflowY: 'hidden',

    [LAPTOP_UP()]: {
      padding: '0 30px'
    },
    extend: ({ override }) => ({
      ...override['resultNoScroll']
    })
  },
  showAllContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: ['2rem', 0, '1rem', 0],
    fontSize: '.875rem',
    paddingBottom: '1rem',

    '& p': {
      paddingRight: '2.5rem'
    }
  },
  item: {
    boxSizing: 'border-box',
    minHeight: '2.5rem',
    padding: '0.625rem 1rem',
    width: '100%',
    fontSize: '1rem',
    lineHeight: 1.3,
    color: colors.cyan,
    cursor: 'pointer',
    outline: 0,
    '&:focus': {
      background: colors.highlight,
      color: colors.teal
    },
    '&:hover': {
      background: colors.highlight,
      color: colors.teal
    },
    '&:active': {
      background: colors.active,
      color: colors.teal
    },
    flex: '1 0 100%',
    [LAPTOP_UP()]: {
      flex: '1 0 50%',
      maxWidth: '50%'
    },
    [LARGE_SCREEN_UP()]: {
      flex: '1 0 33%',
      maxWidth: '33%'
    },
    extend: ({ override }) => ({
      ...override['item']
    })
  },
  innerInput: {
    [LAPTOP_UP()]: {
      padding: '0 30px'
    }
  }
}

export default styles
