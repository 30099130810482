import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import withStyles from 'react-jss'
import CardGraph from '../CardGraph/CardGraph'
import CardType from '../CardType/CardType'
import LoadingSettlementTable from '../LoadingIndicators/LoadingTable'
import SettlmentExport from '../SettlementExport/SettlementExport'
import CardTypeRow from '../SettlementTable/CardTypeRow'
import TableHeader from '../SettlementTable/TableHeader'
import TableRow from '../SettlementTable/TableRow'
import { getTerminalSummaryChartData } from '../Summary.helper'
import PrintableTerminalSummary from './PrintableTerminalSummary'
import styles from './TerminalSummary.styles'
import { SettlementDate } from 'components/common'
import { SERVER_DATE_FORMAT } from 'constants/general.constants'
import { formatDate } from 'utils/filters.utils'
import { CREDIT_TO_BANK_BASE_COLOR } from 'constants/colors.contants'
import { getCurrency } from 'utils/number.utils'

const LoadingTerminalSummary = ({ displayName, settlementDateFrom, settlementDateTo, classes, isLoading }) => {
  return map([1, 2], index => {
    return (
      <div key={index}>
        <div className={classes['title']}>
          <div className={'name'} data-hj-suppress>
            <Text variant="h2">{displayName}</Text>
          </div>
          <div className={'date'}>
            <SettlementDate
              {...{
                from: formatDate(settlementDateFrom, SERVER_DATE_FORMAT),
                to: formatDate(settlementDateTo, SERVER_DATE_FORMAT)
              }}
            />
          </div>
          <div className={'number'}>
            <Text variant="h5" color={colors.greyDark}>
              Terminal No.{' '}
            </Text>
          </div>
        </div>
        <div className={classes['section']}>
          <div className={'table'}>
            <LoadingSettlementTable {...{ classes }} />
          </div>
          <div className={'graph'}>
            <CardGraph {...{ isLoading }} />
          </div>
        </div>
      </div>
    )
  })
}

export const TerminalSummaryTable = ({ data, classes, baseColor }) => {
  const { cardTypes, totalPurchaseAmount, totalCount, totalAdditionalAmt } = data

  return (
    <table className={classes['table']}>
      <TableHeader {...{ classes }} />
      <tbody>
        {cardTypes.map((item, cardIndex) => {
          const { length: cardsCount } = cardTypes
          const { cardType, totalTransactionAmount, tranTypes } = item

          return tranTypes.map((item, index) => {
            const length = tranTypes.length
            const typeField = <CardType {...{ classes, baseColor, cardIndex, cardsCount, cardType }} />

            return (
              <CardTypeRow
                key={cardType + index}
                {...{
                  index,
                  length,
                  item,
                  typeField,
                  totalTransactionAmount,
                  classes,
                  uniqueKey: cardType + index
                }}
              />
            )
          })
        })}
      </tbody>
      <tfoot>
        <TableRow
          {...{
            classes,
            card: (
              <Text variant="h5" color={colors.greyDark}>
                Terminal Total
              </Text>
            ),
            type: '',
            count: totalCount,
            purchase: getCurrency(totalPurchaseAmount),
            cashout: getCurrency(totalAdditionalAmt),
            total: getCurrency(totalPurchaseAmount + totalAdditionalAmt)
          }}
        />
      </tfoot>
    </table>
  )
}

const Content = ({
  isLoading,
  settlementTerminalIds,
  settlementDateFrom,
  settlementDateTo,
  displayName,
  classes,
  renderPrint
}) => {
  if (isLoading)
    return <LoadingTerminalSummary {...{ isLoading, classes, settlementDateFrom, settlementDateTo, displayName }} />

  const emptyResult = settlementTerminalIds.length === 0

  if (!isLoading && emptyResult) {
    return (
      <div className={classes['group']}>
        <TerminalTitleSection {...{ classes, displayName, settlementDateFrom, settlementDateTo }} />
        <div className={classes['emptyMessageContainer']}>
          <Text variant="h4">No terminal summary to display.</Text>
        </div>
      </div>
    )
  }

  return map(settlementTerminalIds, terminal => {
    const { terminalId } = terminal
    const chartData = isLoading ? null : getTerminalSummaryChartData(terminal)

    return (
      <div key={terminalId} className={classes['group']}>
        <TerminalTitleSection {...{ classes, displayName, settlementDateFrom, settlementDateTo, terminalId }} />
        <div className={classes['section']}>
          <div className={'table'}>
            <TerminalSummaryTable {...{ data: terminal, classes, baseColor: CREDIT_TO_BANK_BASE_COLOR }} />
          </div>
          <div className={'graph'}>
            <CardGraph {...{ data: chartData, isLoading, baseColor: CREDIT_TO_BANK_BASE_COLOR }} />
          </div>
        </div>
      </div>
    )
  })
}

const TerminalTitleSection = ({ classes, displayName, settlementDateFrom, settlementDateTo, terminalId }) => {
  return (
    <div className={classes['title']}>
      <div className={'name'} data-hj-suppress>
        <Text variant="h2" color={colors.greyDark}>
          {displayName}
        </Text>
      </div>
      <div className={'date'}>
        <SettlementDate
          {...{
            from: formatDate(settlementDateFrom, SERVER_DATE_FORMAT),
            to: formatDate(settlementDateTo, SERVER_DATE_FORMAT)
          }}
        />
      </div>
      {terminalId != null ? (
        <div className={'number'}>
          <Text variant="h5" color={colors.greyDark}>
            Terminal No.{' '}
          </Text>{' '}
          {terminalId}
        </div>
      ) : (
        <div className={'number'}></div>
      )}
    </div>
  )
}

export const TerminalSummary = ({ classes, filter, data, isLoading }) => {
  const { settlementTerminalIds } = data || {}
  const { displayName, settlementDateFrom, settlementDateTo } = filter || {}

  const [renderPrint, setRenderPrint] = useState(false)

  const enablePrint = isRender => {
    setRenderPrint(isRender)
  }

  return (
    <>
      {renderPrint && <PrintableTerminalSummary {...{ filter, data }} />}
      <div className={classes['wrapper']}>
        <div className={classes['header']}>
          <div className={'title'}>
            <Text variant="h3" color={colors.greyDark}>
              Terminal Summary
            </Text>
          </div>
          <div className={'export'}>
            <SettlmentExport {...{ enablePrint }} />
          </div>
        </div>

        <div className={classes['content']}>
          <Content
            {...{ isLoading, displayName, settlementDateFrom, settlementDateTo, settlementTerminalIds, classes }}
          />
        </div>
      </div>
    </>
  )
}

TerminalSummary.prototype = {
  filter: PropTypes.object,
  data: PropTypes.object,
  isLoading: PropTypes.bool
}

TerminalSummary.defaulProps = {
  isLoading: false,
  data: {
    creditToBank: undefined,
    deferred: undefined
  }
}

export default withStyles(styles)(TerminalSummary)
