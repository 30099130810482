import * as colors from 'config/colors'
import { weightSemiBold, standardFontFamily } from 'config/jss-vars'
import { MOBILE_DOWN, MOBILE_UP } from 'config/mediaQueryHelper'

const menuItem = {
  [MOBILE_UP()]: {
    width: '165px'
  },
  [MOBILE_DOWN()]: {
    width: '150px'
  },
  height: '40px',
  padding: '0.875rem',
  fontFamily: standardFontFamily,
  fontWeight: weightSemiBold,
  fontSize: '0.875rem',
  color: colors.cyan,
  cursor: 'pointer',
  backgroundColor: colors.white,
  '&:hover': {
    color: colors.teal,
    backgroundColor: colors.highlight
  },
  '&:focus': {
    color: colors.teal,
    backgroundColor: colors.highlight
  },
  '&:active': {
    background: colors.active,
    color: colors.teal
  },
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    paddingRight: '0.5rem'
  }
}

export const popoverMenuLibStyles = {
  arrowColor: colors.teal,
  arrowSize: 5,
  arrowStyle: { opacity: 1, zIndex: 999 },
  padding: 2
}

export const styles = {
  popoverContent: {
    border: `1px solid ${colors.teal}`,
    borderRadius: '2px',
    backgroundColor: colors.white,
    extend: ({ override }) => ({
      ...override['popoverContent']
    })
  },
  menuList: {
    'list-style': 'none',
    '& :first-child': {
      borderRadius: ['2px', '2px', 0, 0]
    },
    '& :last-child': {
      borderRadius: [0, 0, '2px', '2px']
    },
    extend: ({ override }) => ({
      ...override['menuList']
    })
  },
  menuItem: {
    ...menuItem,
    extend: ({ override }) => ({
      ...override['menuItem']
    })
  },
  activeMenuItem: {
    ...menuItem,
    color: colors.teal,
    backgroundColor: colors.highlight,
    cursor: 'none',
    extend: ({ override }) => ({
      ...override['activeMenuItem']
    })
  },
  popoverMenuContainer: {
    overflow: 'visible !important',
    '& > div > div:first-child': {
      borderBottomColor: 'white !important',
      top: '1.5px !important',
      zIndex: '999999 !important'
    },
    '& > div > div': {
      padding: '0 !important'
    },
    extend: ({ override }) => ({
      ...override['popoverMenuContainer']
    })
  }
}
