import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as variables from 'config/jss-vars'
import * as colors from 'config/colors'
const { MOBILE_DOWN, LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  wrapper: {
    display: 'flex',
    borderBottom: `1px solid ${colors.line2}`,
    boxShadow: '0px 1px 0px 0px rgba(201, 200, 200, 0.5)',
    [LAPTOP_UP()]: {
      paddingTop: ({ hasPaddingTop }) => (hasPaddingTop ? '2em' : '0')
    },
    paddingTop: ({ hasPaddingTop }) => (hasPaddingTop ? '1em' : '0')
  },
  link: {
    textAlign: 'center',
    fontWeight: variables.weightSemiBold,
    fontSize: '1rem',
    paddingBottom: '0.75rem',
    color: colors.cyan,
    cursor: 'pointer',
    borderBottom: '4px solid transparent',
    transition: 'border-bottom-color 0.6s ease',
    paddingTop: '0.2rem',
    margin: [0, '1rem'],
    minWidth: '2.5rem',
    '&:hover': {
      color: colors.teal
    },
    [MOBILE_DOWN()]: {
      flex: [1, 1, 'auto'],
      width: 'auto',
      fontSize: '1rem'
    }
  },
  active: {
    borderBottom: `4px solid ${colors.cyan} !important`
  },
  title: {
    padding: [0, '1rem', '0.75rem', '1em'],
    display: 'none',
    [LAPTOP_UP()]: {
      display: 'block',
      padding: [0, '1rem', '0.75rem', '1.5rem']
    },
    [SCREEN_UP()]: {
      padding: [0, '1rem', '0.75rem', '2rem']
    },
    [LARGE_SCREEN_UP()]: {
      padding: [0, '1rem', '0.75rem', '3rem']
    }
  },
  actionBtn: {
    [MOBILE_DOWN()]: {
      display: 'none'
    },
    margin: ['-0.8rem', 0, 'auto', 'auto'],
    padding: [0, '2rem']
  }
}

export default styles
