// eslint-disable-next-line
const PASSWORD_ALPHABETS_8_MIN = RegExp(/(?=.*?[a-z])(?=.*?[A-Z]).{8,}$/)
const PASSWORD_NUMBER = RegExp(/(?=.*?[0-9])/)
const PASSWORD_SPECIAL_CHARACTER = RegExp(/(?=.*?[#?!@$%^&*-])/)

export const isPasswordValid = value => {
  const has8Alphabets = PASSWORD_ALPHABETS_8_MIN.test(value)

  if (!has8Alphabets) {
    return false
  }

  const hasNumbers = PASSWORD_NUMBER.test(value)
  const hasSpecialCharacters = PASSWORD_SPECIAL_CHARACTER.test(value)

  if (!hasNumbers && !hasSpecialCharacters) {
    return false
  }

  return true
}
