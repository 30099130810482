import React from 'react'
import { getLegendsArray, getCardsMap } from 'components/BusinessPerformance/CardsUsed/CardsUsed.utils'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_CURRENT_PERIOD_CARDS_COLOR, GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import Key from './Key'
import NoTransactionsMessage from 'components/BusinessPerformance/CardsUsed/NoTransactionsMessage'
import CurrentDataLabel from 'components/BusinessPerformance/CardsUsed/CurrentDataLabel'
import PastDataLabel from 'components/BusinessPerformance/CardsUsed/PastDataLabel'
import Text from 'components/Text/Text'
import objstr from 'obj-str'
import _ from 'lodash'
import CardsUsedLoader from 'components/BusinessPerformance/CardsUsed/CardsUsedLoader'

const Chart = ({ classes, current, previous, isComparePeriod, isLoading }) => {
  const currentGraphData = getCardsMap(current)
  const previousGraphData = getCardsMap(previous)
  const legends = getLegendsArray(currentGraphData, previousGraphData, isComparePeriod)

  const isCurrentEmpty = _.isEmpty(current)
  const isPreviousEmpty = _.isEmpty(previous)
  const isNoTransactions = isComparePeriod ? isCurrentEmpty && isPreviousEmpty : isCurrentEmpty

  if (isLoading) {
    return <CardsUsedLoader />
  }

  const currentGraphClassName = objstr({
    [classes['currentDataGraph']]: true,
    [classes['currentDataEmptyMessage']]: isCurrentEmpty
  })

  const previousGraphClassName = objstr({
    [classes['pastDataGraph']]: true,
    [classes['previousDataEmptyMessage']]: isPreviousEmpty
  })

  if (isNoTransactions) {
    return (
      <div className={classes['noTransactionMessage ']}>
        <NoTransactionsMessage {...{ classes }} />
      </div>
    )
  }

  return (
    <div className={classes['graphWrapper']} data-hj-suppress>
      <div className={currentGraphClassName}>
        {!isCurrentEmpty ? (
          <HorizontalBarChart
            {...{
              barSize: 40,
              color: GRAPH_CURRENT_PERIOD_CARDS_COLOR,
              data: currentGraphData,
              labelPosition: 'top',
              Label: CurrentDataLabel
            }}
          />
        ) : (
          <Text variant="h5" color={GRAPH_CURRENT_PERIOD_CARDS_COLOR}>
            There are no transactions for the selected time period.
          </Text>
        )}
      </div>

      {!!isComparePeriod && (
        <div className={previousGraphClassName}>
          {!isPreviousEmpty ? (
            <HorizontalBarChart
              {...{
                barSize: 15,
                color: GRAPH_PREVIOUS_PERIOD_COLOR,
                data: previousGraphData,
                labelPosition: 'bottom',
                Label: PastDataLabel
              }}
            />
          ) : (
            <Text variant="h5" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
              There are no transactions for the previous time period.
            </Text>
          )}
        </div>
      )}
      <Key {...{ className: classes['legendsContainer'], legends: legends }} />
    </div>
  )
}

export default React.memo(Chart)
