import React from 'react'
import styles from './RoleInfo.styles'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import moment from 'moment'
import { UI_DATE_FORMAT_FULL } from 'constants/general.constants'
import { getInsightsRole } from 'utils/user.utils'

const RoleInfo = ({ classes, user }) => {
  const { roles, creationTime } = user
  const role = getInsightsRole(roles)
  const createdOnTime = moment(creationTime).format(UI_DATE_FORMAT_FULL)

  return (
    <div className={classes['roleInfo']}>
      <div className={classes['role']}>
        <Text variant="h2">{role}</Text>
      </div>
      <div className={classes['createdOn']}>
        <Text variant="note">Created on {createdOnTime}</Text>
      </div>
    </div>
  )
}

RoleInfo.prototype = {
  user: PropTypes.object.isRequired
}

export default withStyles(styles)(RoleInfo)
