import * as colors from 'config/colors'
import { TABLET_DOWN } from 'config/mediaQueryHelper'

const logo = {
  display: 'flex',
  flex: '0 1 auto',
  alignItems: 'center',
  cursor: 'pointer'
}

const styles = {
  header: {
    backgroundColor: colors.white,
    boxShadow: `${colors.line1} 0px 1px 2px 0px`,
    position: 'relative',
    display: 'flex',
    height: '70px',
    [TABLET_DOWN()]: {
      height: '50px'
    },
    extend: ({ override }) => ({
      ...override['header']
    })
  },
  'logo-desktop': {
    ...logo,
    padding: '0.875rem 0 0.875rem 1.25rem',
    [TABLET_DOWN()]: {
      padding: 0,
      display: 'none'
    },
    extend: ({ override }) => ({
      ...override['logo-desktop']
    })
  },
  'logo-mobile': {
    ...logo,
    padding: '0 1.25rem 0 0.625rem',
    display: 'none',
    [TABLET_DOWN()]: {
      display: 'flex'
    },
    extend: ({ override }) => ({
      ...override['logo-mobile']
    })
  },

  nav: {
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.25rem',
    [TABLET_DOWN()]: {
      padding: '1.25rem 0 0.875rem 0'
    },
    extend: ({ override }) => ({
      ...override['nav']
    })
  }
}

export default styles
