import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import pick from 'lodash/pick'
import { isPasswordValid } from 'utils/password.utils'

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Old password is required.'),
  newPassword: Yup.string()
    .required('Password is required.')
    .test('newPassword', 'Enter a valid password.', function(value) {
      return isPasswordValid(value)
    }),
  confirmPassword: Yup.string()
    .required('Please confirm your new password.')
    .test('passwords-match', 'Passwords entered do not match. Please try again.', function(value) {
      return this.parent.newPassword === value
    })
    .test(
      'passwords-match',
      'Sorry, invalid Password. Please ensure it conforms to the rules specified above.',
      function(value) {
        return isPasswordValid(value)
      }
    )
})

const getUpdatePasswordPayload = data => {
  return pick(data, ['currentPassword', 'newPassword'])
}

const handleSubmit = async (values, params) => {
  const { setSubmitting, setErrors, setFieldValue } = params
  const { props } = params
  const { updatePassword } = props
  const updateValues = getUpdatePasswordPayload(values)

  try {
    setFieldValue('updated', false)
    await updatePassword(updateValues)
    setSubmitting(false)
    setFieldValue('updated', true)
  } catch (error) {
    const { error: errorCode } = error
    setSubmitting(false)
    setErrors({
      serverValidation: errorCode
    })
  }
}

const mapPropsToValues = () => {
  return { currentPassword: '', newPassword: '', confirmPassword: '', updated: false }
}

const formikObject = {
  displayName: 'UpdatePasswordForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
