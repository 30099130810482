import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as UserIcon } from './svgs/user.svg'
import { ReactComponent as UserCircleIcon } from './svgs/user-circle.svg'
import { ReactComponent as Cog } from './svgs/cog.svg'
import { ReactComponent as PaymarkIcon } from './svgs/paymark-logo.svg'
import { ReactComponent as InsightsIcon } from './svgs/insights-logo.svg'
import { ReactComponent as InsightsBetaIcon } from './svgs/insights-beta-logo.svg'
import { ReactComponent as ExclamationCircle } from './svgs/exclamation-circle.svg'
import { ReactComponent as CheckCircle } from './svgs/check-circle.svg'
import { ReactComponent as CrossCircle } from './svgs/cross-circle.svg'
import { ReactComponent as HelpIcon } from './svgs/help.svg'
import { ReactComponent as DeleteSolid } from './svgs/delete-solid.svg'
import { ReactComponent as Clear } from './svgs/clear.svg'
import { ReactComponent as ClearFilled } from './svgs/clear-filled.svg'
import { ReactComponent as PaymarkIconMobile } from './svgs/paymark-logo-mobile.svg'
import { ReactComponent as PaymarkBetaIconMobile } from './svgs/paymark-beta-logo-mobile.svg'
import { ReactComponent as AccountIcon } from './svgs/account.svg'
import { ReactComponent as AccountLightIcon } from './svgs/account-light.svg'
import { ReactComponent as LogoutIcon } from './svgs/logout.svg'
import { ReactComponent as DashboardIcon } from './svgs/dashboard.svg'
import { ReactComponent as SettlementIcon } from './svgs/settlement.svg'
import { ReactComponent as MenuIcon } from './svgs/hamburger.svg'
import { ReactComponent as TransactionIcon } from './svgs/transactions.svg'
import { ReactComponent as SearchIcon } from './svgs/search.svg'
import { ReactComponent as CalendarIcon } from './svgs/calendar.svg'
import { ReactComponent as CalendarEndIcon } from './svgs/calendar_end.svg'
import { ReactComponent as Cross } from './svgs/cross.svg'
import { ReactComponent as Arrow } from './svgs/arrow.svg'
import { ReactComponent as ArrowLeftIcon } from './svgs/arrowLeft.svg'
import { ReactComponent as ArrowRightIcon } from './svgs/arrowRight.svg'
import { ReactComponent as ArrowDownIcon } from './svgs/arrowDown.svg'
import { ReactComponent as ArrowUpIcon } from './svgs/arrowUp.svg'
import { ReactComponent as ArrowDownRegular } from './svgs/arrowDownRegular.svg'
import { ReactComponent as ArrowUpRegular } from './svgs/arrowUpRegular.svg'
import { ReactComponent as NavigateBefore } from './svgs/navigateBefore.svg'
import { ReactComponent as NavigateNext } from './svgs/navigateNext.svg'
import { ReactComponent as ExportIcon } from './svgs/export.svg'
import { ReactComponent as Time } from './svgs/time.svg'
import { ReactComponent as Done } from './svgs/done.svg'
import { ReactComponent as Info } from './svgs/info.svg'
import { ReactComponent as Card } from './svgs/card.svg'
import { ReactComponent as Contract } from './svgs/contract.svg'
import { ReactComponent as Customer } from './svgs/customer.svg'
import { ReactComponent as Decrease } from './svgs/decrease.svg'
import { ReactComponent as Expand } from './svgs/expand.svg'
import { ReactComponent as Grid } from './svgs/grid.svg'
import { ReactComponent as Increase } from './svgs/increase.svg'
import { ReactComponent as Plus } from './svgs/plus.svg'
import { ReactComponent as PlusCircle } from './svgs/plus-circle.svg'
import { ReactComponent as Return } from './svgs/return.svg'
import { ReactComponent as Revenue } from './svgs/revenue.svg'
import { ReactComponent as Sales } from './svgs/sales.svg'
import { ReactComponent as Store } from './svgs/store.svg'
import { ReactComponent as SwitchIcon } from './svgs/switchIcon.svg'
import { ReactComponent as BankLogoAsb } from './svgs/logo-bank-asb.svg'
import { ReactComponent as BankLogoCoop } from './svgs/logo-bank-co-op.svg'
import { ReactComponent as BankLogoWestpac } from './svgs/logo-bank-westpac.svg'
import { ReactComponent as BankLogoHeartland } from './svgs/logo-bank-heartland.svg'
import { ReactComponent as BankLogoBnz } from './svgs/logo-bank-bnz.svg'
import { ReactComponent as HappyIcon } from './svgs/happy.svg'
import { ReactComponent as NotHappyIcon } from './svgs/not-happy.svg'
import { ReactComponent as MyPaymark } from './svgs/mypaymark.svg'
import { ReactComponent as OE } from './svgs/oe.svg'
import { ReactComponent as Switch } from './svgs/switch.svg'
import { ReactComponent as SwitchHover } from './svgs/switch-hover.svg'
import { ReactComponent as SwitchColor } from './svgs/switch-color.svg'
import { ReactComponent as SwitchColorHover } from './svgs/switch-color-hover.svg'
import { ReactComponent as Apps } from './svgs/apps.svg'
import { ReactComponent as Tick } from './svgs/tick.svg'
import { ReactComponent as Warning } from './svgs/warning.svg'
import { ReactComponent as SupportCentre } from './svgs/support-centre.svg'
import { ReactComponent as More } from './svgs/more.svg'
import { ReactComponent as Worldview } from './svgs/worldview.svg'

import withStyles from 'react-jss'
import jss from './Icon.styles'

const renderSvg = (name, props) => {
  switch (name) {
    case 'arrow':
      return <Arrow alt="User" {...props} />
    case 'cross':
      return <Cross alt="User" {...props} />
    case 'grid':
      return <Grid alt="User" {...props} />
    case 'worldview':
      return <Worldview alt="User" {...props} />
    case 'user':
      return <UserIcon alt="User" {...props} />
    case 'user-circle':
      return <UserCircleIcon alt="User Circle" {...props} />
    case 'paymark':
      return <PaymarkIcon alt="Paymark" {...props} />
    case 'paymark-mobile':
      return <PaymarkIconMobile alt="Paymark" {...props} />
    case 'paymark-mobile-beta':
      return <PaymarkBetaIconMobile alt="Paymark Beta" {...props} />
    case 'insights':
      return <InsightsIcon alt="Insights" {...props} />
    case 'insights-beta':
      return <InsightsBetaIcon alt="Insights Beta" {...props} />
    case 'exclamation-circle':
      return <ExclamationCircle alt="ExclamationCircle" {...props} />
    case 'check-circle':
      return <CheckCircle alt="CheckCircle" {...props} />
    case 'cross-circle':
      return <CrossCircle alt="CrossCircle" {...props} />
    case 'help':
      return <HelpIcon alt="Help" {...props} />
    case 'delete':
      return <DeleteSolid alt="Delete" {...props} />
    case 'clear':
      return <Clear alt="Clear" {...props} />
    case 'clear-filled':
      return <ClearFilled alt="Clear" {...props} />
    case 'account':
      return <AccountIcon alt="Account" {...props} />
    case 'account-light':
      return <AccountLightIcon alt="Account Light" {...props} />
    case 'logout':
      return <LogoutIcon alt="Logout" {...props} />
    case 'dashboard':
      return <DashboardIcon alt="Dashboard" {...props} />
    case 'settlement':
      return <SettlementIcon alt="Settlement" {...props} />
    case 'menu':
      return <MenuIcon alt="Menu" {...props} />
    case 'transaction':
      return <TransactionIcon alt="Transaction" {...props} />
    case 'search':
      return <SearchIcon alt="Search" {...props} />
    case 'calendar':
      return <CalendarIcon alt="Calendar" {...props} />
    case 'calendarEnd':
      return <CalendarEndIcon alt="Calendar" {...props} />
    case 'arrowLeft':
      return <ArrowLeftIcon alt="Left Arrow" {...props} />
    case 'arrowRight':
      return <ArrowRightIcon alt="Right Arrow" {...props} />
    case 'arrowDown':
      return <ArrowDownIcon alt="Down Arrow" {...props} />
    case 'arrowUp':
      return <ArrowUpIcon alt="Up Arrow" {...props} />
    case 'arrowDownRegular':
      return <ArrowDownRegular alt="Down Arrow" {...props} />
    case 'arrowUpRegular':
      return <ArrowUpRegular alt="Up Arrow" {...props} />
    case 'navigateBefore':
      return <NavigateBefore alt="Navigate Before" {...props} />
    case 'navigateNext':
      return <NavigateNext alt="Navigate Next" {...props} />
    case 'export':
      return <ExportIcon alt="Export" {...props} />
    case 'cog':
      return <Cog alt="Cog" {...props} />
    case 'time':
      return <Time alt="Time" {...props} />
    case 'done':
      return <Done alt="Done" {...props} />
    case 'info':
      return <Info alt="Info" {...props} />
    case 'card':
      return <Card alt="Card" {...props} />
    case 'contract':
      return <Contract alt="Contract" {...props} />
    case 'expand':
      return <Expand alt="Expand" {...props} />
    case 'customer':
      return <Customer alt="Customer" {...props} />
    case 'decrease':
      return <Decrease alt="Decrease" {...props} />
    case 'increase':
      return <Increase alt="Increase" {...props} />
    case 'plus':
      return <Plus alt="Plus" {...props} />
    case 'plus-circle':
      return <PlusCircle alt="Plus" {...props} />
    case 'return':
      return <Return alt="Return" {...props} />
    case 'revenue':
      return <Revenue alt="Revenue" {...props} />
    case 'sales':
      return <Sales alt="Sales" {...props} />
    case 'store':
      return <Store alt="Store" {...props} />
    case 'switchIcon':
      return <SwitchIcon alt="Switch Icon" {...props} />
    case 'bank-logo-asb':
      return <BankLogoAsb alt="ASB Bank" {...props} />
    case 'bank-logo-coop':
      return <BankLogoCoop alt="The Co-operative Bank" {...props} />
    case 'bank-logo-westpac':
      return <BankLogoWestpac alt="Westpac Bank" {...props} />
    case 'bank-logo-heartland':
      return <BankLogoHeartland alt="Heartland Bank" {...props} />
    case 'bank-logo-bnz':
      return <BankLogoBnz alt="BNZ Bank" {...props} />
    case 'happy':
      return <HappyIcon alt="Happy Icon" {...props} />
    case 'not-happy':
      return <NotHappyIcon alt="Not Happy Icon" {...props} />
    case 'mypaymark':
      return <MyPaymark alt="MyPaymark" {...props} />
    case 'oe':
      return <OE alt="OE" {...props} />
    case 'switch':
      return <Switch alt="Switch" {...props} />
    case 'switch-hover':
      return <SwitchHover alt="Switch Hover" {...props} />
    case 'switch-color':
      return <SwitchColor alt="SwitchColor" {...props} />
    case 'switch-color-hover':
      return <SwitchColorHover alt="Switch Color Hover" {...props} />
    case 'apps':
      return <Apps alt="Apps" {...props} />
    case 'tick':
      return <Tick alt="Tick" {...props} />
    case 'warning':
      return <Warning alt="Warning" {...props} />
    case 'support-centre':
      return <SupportCentre alt="Support Centre" {...props} />
    case 'more':
      return <More alt="More" {...props} />
    default:
      return null
  }
}

/**
 * A simple icon component
 */
const Icon = ({ name, classes, width, height, color, className = '', ...rest }) => {
  const svgProps = {}

  if (width) {
    svgProps.width = width
    svgProps.height = '100%'
  }

  if (height) {
    svgProps.height = height
  }

  return (
    <div className={`${classes['icon']} ${className}`} color={color} {...rest}>
      {renderSvg(name, svgProps)}
    </div>
  )
}

export const Icons = [
  'user',
  'user-circle',
  'paymark',
  'paymark-mobile',
  'paymark-mobile-beta',
  'insights',
  'insights-beta',
  'exclamation-circle',
  'check-circle',
  'help',
  'cross-circle',
  'delete',
  'clear',
  'account',
  'account-light',
  'logout',
  'dashboard',
  'settlement',
  'menu',
  'transaction',
  'search',
  'clear-filled',
  'calendar',
  'calendarEnd',
  'arrowLeft',
  'arrowRight',
  'arrowDown',
  'arrowUp',
  'arrowUpRegular',
  'arrowDownRegular',
  'navigateBefore',
  'navigateNext',
  'export',
  'cog',
  'time',
  'done',
  'info',
  'card',
  'contract',
  'expand',
  'customer',
  'decrease',
  'increase',
  'plus',
  'plus-circle',
  'return',
  'revenue',
  'sales',
  'store',
  'switchIcon',
  'bank-logo-asb',
  'bank-logo-coop',
  'bank-logo-westpac',
  'bank-logo-heartland',
  'happy',
  'not-happy',
  'mypaymark',
  'oe',
  'switch',
  'switch-hover',
  'switch-color',
  'switch-color-hover',
  'tick',
  'warning',
  'support-centre',
  'more'
]

Icon.propTypes = {
  /**
   * Type of icon
   */
  name: PropTypes.oneOf([...Icons]).isRequired,

  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
}

export default withStyles(jss)(Icon)
