import React, { Component } from 'react'
import TerminalSummary from './TerminalSummary/TerminalSummary'
import MerchantSummary from './MerchantSummary/MerchantSummary'
import { getParamsFromUrl, constructParams, dispatchDataActions, isFilterValid } from './Summary.helper'

class SettlementResult extends Component {
  componentWillReceiveProps(nextProps) {
    const { search: oldProps } = this.props
    const { search: newProps } = nextProps
    // Detect param change
    if (oldProps !== newProps) {
      this.getData(newProps)
      return false
    }
  }

  getData(newProps) {
    const { actions } = this.props
    const params = getParamsFromUrl(newProps)
    const isValid = isFilterValid(params)
    if (!isValid) {
      actions.resetSummary()
      return
    }
    const actionParams = constructParams(params)

    dispatchDataActions(actions, actionParams)
  }

  render() {
    const { props } = this
    const { filter, merchantSummary, terminalSummary, isLoading, isShowResult } = props
    if (!filter || !isShowResult) return null
    const { summaryType } = filter
    const isMerchant = 'merchant' === summaryType
    const isTerminal = 'terminal' === summaryType

    if (isMerchant) {
      return <MerchantSummary {...{ data: merchantSummary, filter, isLoading }} />
    }

    if (isTerminal) {
      return <TerminalSummary {...{ data: terminalSummary, filter, isLoading }} />
    }

    return null
  }
}

export default SettlementResult
