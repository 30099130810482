import React from 'react'
import PropTypes from 'prop-types'
import Fieldset from 'components/Fieldset/Fieldset'
import Card from 'components/Card/Card'
import withStyles from 'react-jss'
import styles from './Container.styles'
import objstr from 'obj-str'

const Container = ({ children, title, classes, isFullHeight, withContainerPaddingTop, ...props }) => {
  const className = objstr({ [classes['container']]: true, [classes['fullHeight']]: isFullHeight })

  return (
    <div className={className}>
      <Card padding={false}>
        <div className={classes.containerContent}>
          {title && (
            <Fieldset>
              <legend>{title}</legend>
              {children}
            </Fieldset>
          )}

          {!title && children}
        </div>
      </Card>
    </div>
  )
}

Container.prototype = {
  title: PropTypes.string,
  isFullHeight: PropTypes.bool,
  withContainerPaddingTop: PropTypes.bool
}

Container.defaultProps = {
  isFullHeight: false,
  withContainerPaddingTop: true
}

export default withStyles(styles)(Container)
