import * as mediaQueryHelper from 'config/mediaQueryHelper'

const { MOBILE_UP, LAPTOP_UP } = mediaQueryHelper

const styles = {
  tabletLayout: {
    display: 'flex',
    flexDirection: 'row'
  },
  desktopLayout: {
    display: 'flex',
    flexDirection: 'column'
  },
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    [LAPTOP_UP()]: {
      flexDirection: 'row'
    }
  },
  infoContainer: {
    color: 'white',
    whiteSpace: 'pre-line',
    [MOBILE_UP()]: {
      padding: [0, '1rem', 0, 0]
    },
    '& h2': {
      fontSize: '20px',
      [LAPTOP_UP()]: {
        fontSize: '25px'
      }
    },
    '& p': {
      fontSize: '12px',
      [LAPTOP_UP()]: {
        fontSize: '16px'
      }
    }
  },
  animationContainer: {
    [MOBILE_UP()]: {
      padding: [0, '2.5rem', 0, '1rem']
    },
    [LAPTOP_UP()]: {
      padding: [0, 0, 0, '1rem']
    }
  },
  buttonContainer: {
    textAlign: 'center',
    padding: [0, 0, '1rem', 0],
    [MOBILE_UP()]: {
      textAlign: 'left'
    },
    [LAPTOP_UP()]: {
      textAlign: 'center',
      padding: [0, 0, '2rem', 0]
    }
  },
  paginationContainer: {
    textAlign: 'center',
    [MOBILE_UP()]: {
      textAlign: 'left'
    },
    [LAPTOP_UP()]: {
      textAlign: 'center'
    }
  }
}

export default styles
