const styles = {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['2rem', '0.5rem', '0.5rem', '0.5rem'],
    alignItems: 'center'
  },
  actionBtns: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
    width: '310px',
    '& > button': {
      width: '140px'
    }
  },
  body: {
    maxWidth: '100%'
  }
}

export default styles
