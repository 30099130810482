import React, { useState, useEffect } from 'react'
import withStyles from 'react-jss'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import Lottie from 'react-lottie'
import styles from './TutorialModal.styles'
import { PaginationDots } from 'components/common'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { setTutorialCompletedInLocalStorage } from 'utils/localStorage.utils'
import { tutorial, animationData } from 'assets/tutorial/tutorial'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_ONBOARDING_TUTORIAL } from 'constants/mixpanel.constants'

const { white } = colors
const { BREAK_POINT_SMALL, BREAK_POINT_MEDIUM } = mediaQueryHelper

const modalStyles = {
  body: {
    padding: '1rem 0 0 0'
  },
  small: {
    width: '280px',
    backgroundColor: '#2CA48C',
    padding: '1.5rem'
  },
  medium: {
    width: '540px',
    backgroundColor: '#2CA48C',
    padding: '1rem 1rem 1.5rem 1.5rem'
  },
  large: {
    width: '630px',
    backgroundColor: '#2CA48C',
    padding: '1.5rem 1.5rem 2rem 1.5rem'
  }
}

const getModalSize = width => {
  if (width >= BREAK_POINT_MEDIUM) {
    return 'large'
  } else if (width >= BREAK_POINT_SMALL) {
    return 'medium'
  } else {
    return 'small'
  }
}

const ModalContent = ({ classes, children }) => {
  return (
    <div className={classes['modalContent']}>
      <div className={classes['body']}>{children}</div>
    </div>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

const TutorialInfo = ({ classes, tutorialPage }) => {
  return (
    <div className={classes['infoContainer']}>
      <h2>{tutorial[tutorialPage].header}</h2>
      <p>{tutorial[tutorialPage].body}</p>
    </div>
  )
}

const StyledTutorialInfo = withStyles(styles)(TutorialInfo)

const Animation = ({ classes, tutorialPage, modalSize }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData[tutorialPage],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const size = modalSize === 'large' ? 265 : 200

  return (
    <div className={classes['animationContainer']}>
      <Lottie options={defaultOptions} height={size} width={size} />
    </div>
  )
}

const StyledAnimation = withStyles(styles)(Animation)

const TutorialNav = ({ classes, nextPage, tutorialEnded, tutorialPage, tutorialLength, setTutorialPage }) => {
  return (
    <>
      <div className={classes['buttonContainer']}>
        <Button variant="primary" onClick={nextPage}>
          {tutorialEnded ? 'Get Started' : 'Next'}
        </Button>
      </div>
      <div className={classes['paginationContainer']}>
        <PaginationDots pageNumber={tutorialPage} numberOfPages={tutorialLength} setActivePage={setTutorialPage} />
      </div>
    </>
  )
}

const StyledTutorialNav = withStyles(styles)(TutorialNav)

const TutorialModal = ({ classes, setModalCompleted }) => {
  const [tutorialVisible, setTutorialVisible] = useState(false)
  const [tutorialPage, setTutorialPage] = useState(0)
  const { width } = useWindowDimensions()
  const modalSize = getModalSize(width)

  const tutorialLength = tutorial.length
  const tutorialEnded = tutorialPage > tutorialLength - 2

  useEffect(() => {
    if (!tutorialEnded) {
      setTutorialVisible(true)
      sendTutorialMixpanelTracking(tutorialPage + 1)
    }
  }, [tutorialPage, tutorialEnded])

  const sendTutorialMixpanelTracking = params => {
    const mixpanelTutorialData = mixpanelData.onboardingTutorialData(params)
    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_ONBOARDING_TUTORIAL, mixpanelTutorialData)
  }

  const closeModal = () => {
    if (!tutorialEnded) {
      sendTutorialMixpanelTracking('cancelled')
    }
    setTutorialVisible(false)
    setTutorialCompletedInLocalStorage()
    setModalCompleted(true)
  }

  const nextPage = () => {
    if (tutorialEnded) {
      sendTutorialMixpanelTracking('completed')
      closeModal()
    }
    setTutorialPage(tutorialPage + 1)
  }

  const tabletLayout = (
    <div className={classes['tabletLayout']}>
      <div className={classes['tutorialContainer']}>
        <StyledTutorialInfo {...{ tutorialPage }} />
        <StyledTutorialNav {...{ nextPage, tutorialEnded, tutorialPage, tutorialLength, setTutorialPage }} />
      </div>
      <StyledAnimation {...{ tutorialPage, modalSize }} />
    </div>
  )

  const desktopLayout = (
    <div className={classes['desktopLayout']}>
      <div className={classes['tutorialContainer']}>
        <StyledTutorialInfo {...{ tutorialPage }} />
        <StyledAnimation {...{ tutorialPage, modalSize }} />
      </div>
      <StyledTutorialNav {...{ nextPage, tutorialEnded, tutorialPage, tutorialLength, setTutorialPage }} />
    </div>
  )

  return (
    <>
      {tutorialVisible && (
        <Modal
          isOpen={true}
          onClose={closeModal}
          size={modalSize}
          closeIconColor={white}
          override={modalStyles}
          shouldCloseOnOverlayClick={false}
        >
          <StyledModalContent>{modalSize === 'medium' ? tabletLayout : desktopLayout}</StyledModalContent>
        </Modal>
      )}
    </>
  )
}

export default withStyles(styles)(TutorialModal)
