export const baseStyles = `
@page {
  margin: 0; /* this affects the margin in the printer settings */
  size: auto; /* auto is the initial value */
}

html,
body,
div,
span,
p,
dl,
dt,
dd,
ol,
ul,
li,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin: 0.5cm;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-right: 10;
}

th,
td {
  font-weight: normal;
  text-align: left;
  border-bottom: 1px solid #e8e4de;
  padding: 5px;
  vertical-align: middle;
}

th {
  padding: 5px;
  text-transform: uppercase;
  background-color: white;
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  white-space:nowrap;
}

td.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

tfoot td {
  font-weight: bold !important;
}

.filterHeader {
  width: 100%;
}
.label {
  width: 200px;
  height: 18px;
}

.header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #cac8cf;
}
.title {
  flex: 1;
  padding-right: 20px;
  display: flex;
}

.group {
  page-break-inside: avoid;
  padding: 15px 0 0 0;
}
h5 {
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 100%;
}
h4 {
  margin: 0 !important;
}

.noPrint {
  display: none;
}
`

export const storePrintStyles =
  baseStyles +
  `th .iconHeader {
    float: left;
    padding-right: 5px;
  }
  
  .storesValue * div, .storesValue div{
    display: inline-block;
  }
  
  .storesPositiveChangeText { 
    color: #5cce8f;
    margin-left: 4px;
  }
  
  .storesNegativeChangeText {
    color: #ff0900;
    margin-left: 4px;
  }

  .print-store-col {
    word-break: break-all;
  }

  .no-print {
    display:none
  }

  .no-right-pad {
    padding-right: 0;
    text-align: right;
  }

  .no-right-pad > div {
    display: inline-block;
  }

  .no-left-pad {
    padding-left: 0;
    padding-right: 20px;
  }

  .strict-width {
    width: 1px;
  }
  `

export const merchantPrintStyles =
  baseStyles +
  `
@page {
  size: auto; /* auto is the initial value */
}

tr td:first-child {
  vertical-align: top;
}
tr td:last-child {
  vertical-align: bottom;
}
`
