import { LAPTOP_UP } from 'config/mediaQueryHelper'

const styles = {
  links: {
    padding: 0,
    width: '100%',
    listStyle: 'none',
    margin: '1.25rem 0 0.75rem 1rem',
    borderBottom: `0.25rem solid transparent`,
    transition: 'border-bottom-color 0.5s ease',
    [LAPTOP_UP()]: {
      margin: '1.5rem 0 0.75rem 0'
    },
    '& > li': {
      position: 'relative',
      display: 'inline-block',
      '& >a': {
        position: 'relative',
        padding: '0 1rem'
      },
      '& >button': {
        position: 'relative',
        padding: '0 1rem'
      },
      '& >span': {
        position: 'relative',
        padding: '0 1rem'
      }
    },
    extend: ({ override }) => ({
      ...override['links']
    })
  },

  'is-active': {
    '& >a': {
      cursor: 'default',
      '&:hover': {
        color: params => params.color
      }
    },
    '& >span': {
      cursor: 'default',
      '&:hover': {
        color: params => params.color
      }
    },
    '& >button': {
      position: 'relative',
      padding: '0 1rem'
    },
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      top: '1.8rem',
      left: '0.3125rem',
      right: '0.3125rem',
      borderBottom: params => `0.25rem solid ${params.color}`,
      [LAPTOP_UP()]: {
        top: '2.4rem'
      }
    },
    extend: ({ override }) => ({
      ...override['is-active']
    })
  },

  'button-link': {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    extend: ({ override }) => ({
      ...override['button-link']
    })
  }
}

export default styles
