import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { storeSlice, dashboardSlice, accountSlice } from 'reduxSlices'
import { getConfig } from 'utils/config.utils'
import moment from 'moment'
import _ from 'lodash'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_DASHBOARD_QUERY } from 'constants/mixpanel.constants'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'

const getStoreParams = (isComparePeriod, startDate, endDate, previousStartPeriod, previousEndPeriod, rangeInterval) => {
  return {
    isCompare: isComparePeriod,
    isSecondaryStore: false,
    current: {
      transactionTimeFrom: startDate,
      transactionTimeTo: endDate,
      interval: rangeInterval
    },
    previous: {
      transactionTimeFrom: previousStartPeriod,
      transactionTimeTo: previousEndPeriod,
      interval: rangeInterval
    }
  }
}

const sendStorePerfMixpanelTracking = params => {
  const mixpanelStorePerfData = mixpanelData.storeDashboardQueryData(params)
  const { Mixpanel: mixpanel } = apis
  mixpanel.track(EVENT_DASHBOARD_QUERY, mixpanelStorePerfData)
}

const usePrimaryStorePerformanceActions = () => {
  const { selectors: dashboardSelectors, actions: dashboardActions } = dashboardSlice
  const { actions: storeActions } = storeSlice
  const { selectors: accountSelectors } = accountSlice
  const dispatch = useDispatch()

  const filter = useSelector(dashboardSelectors.getFilter)
  const merchants = useSelector(accountSelectors.getMerchantAccess)
  const isSingleStore = merchants ? merchants.length === 1 : false

  const {
    isComparePeriod,
    startDate,
    endDate,
    previousStartPeriod,
    previousEndPeriod,
    interval,
    store,
    comparedStore,
    range
  } = filter

  const cardAcceptorIdCode = store && store.cardAcceptorIdCode
  const comparedStoreCardAcceptorIdCode = comparedStore && comparedStore.cardAcceptorIdCode

  const fetchReturnCustomersPrimaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchReturnCustomersPrimaryStore({ ...params, isCompare: true })),
    [dispatch, storeActions]
  )
  const fetchReturnCustomersSecondaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchReturnCustomersSecondaryStore(params)),
    [dispatch, storeActions]
  )

  const fetchCardsUsedPrimaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchCardsUsedReducerPrimaryStore(params)),
    [dispatch, storeActions]
  )
  const fetchCardsUsedSecondaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchCardsUsedReducerSecondaryStore(params)),
    [dispatch, storeActions]
  )

  const fetchTransactionAggPrimaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchSalesRevenueReducerPrimaryStore({ ...params, isCompare: true })),
    [dispatch, storeActions]
  )
  const fetchTransactionAggSecondaryStoreAction = useCallback(
    params => dispatch(storeActions.fetchSalesRevenueReducerSecondaryStore(params)),
    [dispatch, storeActions]
  )
  const fetchAllStoresAction = useCallback(actionParams => dispatch(dashboardActions.fetchAllStores(actionParams)), [
    dispatch,
    dashboardActions
  ])

  const fetchSalesRevenueAction = useCallback(params => dispatch(storeActions.fetchSalesRevenue(params)), [
    dispatch,
    storeActions
  ])

  useEffect(() => {
    let actionParams = getStoreParams(
      isComparePeriod,
      startDate,
      endDate,
      previousStartPeriod,
      previousEndPeriod,
      interval
    )

    const { dataReloadInterval } = getConfig()
    const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)
    const reloadTime = dataReloadInterval * 1000

    const getPrimaryStoreData = () => {
      const params = { ...actionParams, cardAcceptorIdCode, isSecondaryStore: false }
      fetchReturnCustomersPrimaryStoreAction(params)
      if (cardsUsedFeatureFlag) {
        fetchCardsUsedPrimaryStoreAction(params)
      }

      fetchTransactionAggPrimaryStoreAction(params)

      // Fetch all store for ranks only when there are more than one store
      if (!isSingleStore) {
        fetchAllStoresAction(params)
      }
    }

    const getSecondaryStoreData = () => {
      const params = {
        ...actionParams,
        cardAcceptorIdCode: comparedStoreCardAcceptorIdCode,
        isComparePeriod: false,
        isSecondaryStore: true
      }

      fetchReturnCustomersSecondaryStoreAction(params)
      if (cardsUsedFeatureFlag) {
        fetchCardsUsedSecondaryStoreAction(params)
      }
      fetchTransactionAggSecondaryStoreAction(params)
    }

    // Primary Store First Load
    getPrimaryStoreData()
    //ComparedStore
    if (comparedStore) {
      getSecondaryStoreData()
    }

    const mixpanelParams = {
      ...actionParams,
      range: range,
      merchantId: cardAcceptorIdCode,
      comparedMerchantId: comparedStoreCardAcceptorIdCode,
      isCompareStore: !_.isEmpty(comparedStoreCardAcceptorIdCode)
    }
    sendStorePerfMixpanelTracking(mixpanelParams)

    // Enable when end date is today or later
    const isAutoReloadDisabled = moment(actionParams.current.transactionTimeTo).isBefore(moment())
    if (!isAutoReloadDisabled) {
      const timer = setInterval(() => {
        actionParams.showLoading = false
        // Primary Store Timer reload
        getPrimaryStoreData()
        // Secondary Store timer reload
        if (comparedStore) {
          getSecondaryStoreData()
        }
      }, reloadTime)

      return () => clearInterval(timer)
    }
  }, [
    startDate,
    endDate,
    previousStartPeriod,
    previousEndPeriod,
    interval,
    cardAcceptorIdCode,
    isComparePeriod,
    fetchSalesRevenueAction,
    storeActions,
    comparedStore,
    comparedStoreCardAcceptorIdCode,
    fetchReturnCustomersPrimaryStoreAction,
    fetchReturnCustomersSecondaryStoreAction,
    fetchCardsUsedPrimaryStoreAction,
    fetchCardsUsedSecondaryStoreAction,
    fetchTransactionAggPrimaryStoreAction,
    fetchTransactionAggSecondaryStoreAction,
    fetchAllStoresAction,
    isSingleStore,
    range
  ])

  return {
    isSingleStore
  }
}

export default usePrimaryStorePerformanceActions
