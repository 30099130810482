import React from 'react'
import withStyles from 'react-jss'
import styles from './ComparePreviousPeriodButton.styles'
import { CompareButton } from 'components/common'

const Label = ({ range = '1d', classes }) => {
  let comparePeriod = 'week'
  switch (range) {
    case '1w':
      comparePeriod = 'week'
      break
    case '1m':
      comparePeriod = 'month'
      break
    case '3m':
      comparePeriod = '3 months'
      break

    default:
      comparePeriod = 'week'
      break
  }

  return (
    <>
      <div>Compare to the</div>
      <div className={classes['periodText']}>previous {comparePeriod}</div>
    </>
  )
}

const ComparePreviousPeriodButton = ({ classes, onChange, isSelected, range, isLoading }) => {
  return <CompareButton {...{ classes, onChange, isSelected, isDisabled: isLoading, Label, range }} />
}

export default withStyles(styles)(ComparePreviousPeriodButton)
