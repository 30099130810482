import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { getParamsFromUrl, fetchSummaryData } from '../SettlementResult/Summary.helper'
import moment from 'moment'
import { getMomentDatesFromRange, isDateRangeValid } from '../../utils/filters.utils'
import queryString from 'query-string'
import apis from 'api'
import { EVENT_SETTLEMENT_SEARCH } from 'constants/mixpanel.constants'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { storeAdapter } from '../TransactionFilter/TransactionFilter.helper'
export const SUMMARY_TYPE = 'summaryType'
export const DATE_RANGE_CHANGE = 'dateRangeChange'
export const DATE_CHANGE = 'dateChange'
export const STORE_SELECT = 'storeSelect'
export const SHOW_SELECTOR = 'showSelector'
export const HIDE_SELECTOR = 'hideSelector'

const FILTER_TYPE = 'type'
const FILTER_START_DATE = 'from'
const FILTER_END_DATE = 'to'
const FILTER_RANGE = 'range'
const FILTER_STORE_CODE = 'code'
const FILTER_STORE_NAME = 'name'
const DATE_FORMAT = 'YYYY-MM-DD'
const yesterday = moment().subtract(1, 'days')

export const computeState = ({ props = {}, actions }) => {
  switch (actions) {
    case SUMMARY_TYPE:
      return {
        summaryType: props.type
      }
    case DATE_CHANGE:
      const { startDate, endDate } = props
      return {
        startDate,
        endDate,
        dateRange: 'custom'
      }
    case SHOW_SELECTOR:
      return {
        showStoreSelector: true
      }
    case HIDE_SELECTOR:
      return {
        showStoreSelector: false
      }
    case STORE_SELECT:
      const { store } = props
      return {
        store,
        showStoreSelector: false
      }
    case DATE_RANGE_CHANGE:
      const {
        dateRange: { value }
      } = props

      const { to, from } = getMomentDatesFromRange(value)
      return { startDate: from, endDate: to, dateRange: value }

    default:
      return {}
  }
}

const validationSchema = Yup.object().shape({
  summaryType: Yup.string().required('Summary Type is required.'),
  store: Yup.string()
    .required('Store must be selected.')
    .nullable(),
  startDate: Yup.object()
    .required('Start date is required.')
    .nullable(),
  endDate: Yup.object()
    .required('End Date is required.')
    .test('dateRange', 'Date range must not exceed 7 days. ', function(endDate) {
      const { startDate } = this.parent
      return isDateRangeValid(startDate, endDate)
    })
    .nullable()
})

export const pushSummaryTypeToHistory = (values, history) => {
  const search = generateUrl(values)
  history.push({
    search
  })
}

const generateUrl = values => {
  let { summaryType, endDate, startDate, store, dateRange } = values
  const adaptedStore = storeAdapter(store)
  const { value, label } = adaptedStore[0]

  endDate = endDate.format(DATE_FORMAT)
  startDate = startDate.format(DATE_FORMAT)

  let filterObject = {
    [FILTER_TYPE]: summaryType,
    [FILTER_STORE_CODE]: value,
    [FILTER_STORE_NAME]: label,
    [FILTER_RANGE]: dateRange
  }
  if (dateRange === 'custom') {
    filterObject = { ...filterObject, [FILTER_START_DATE]: startDate, [FILTER_END_DATE]: endDate }
  }
  const searchUrl = queryString.stringify(filterObject)
  return searchUrl
}

const submit = (values, params) => {
  const { props, setSubmitting } = params
  const { history, actions } = props

  const search = generateUrl(values)
  //Force reload data when the params are same
  if (decodeURI(window.location.search) === '?' + decodeURI(search)) {
    fetchSummaryData(search, actions)
  } else {
    history.push({
      search
    })
  }
  setSubmitting(false)
}

const handleSubmit = (values, params) => {
  const { Mixpanel: mixpanel } = apis

  mixpanel.track(EVENT_SETTLEMENT_SEARCH, mixpanelData.settlementSearchData(values))

  submit(values, params)
}

const mapPropsToValues = props => {
  const { search, merchants, selectedStores, summaryType } = props
  const stores = selectedStores?.length > 0 ? selectedStores : ''
  let base = {
    summaryType: summaryType || 'merchant',
    store: stores,
    dateRange: 'yesterday',
    startDate: yesterday,
    endDate: yesterday,
    showStoreSelector: false,
    highlight: false,
    isSingleStore: false
  }

  //Setting the base store if there is only one merchant
  if (merchants && merchants.length === 1) {
    base.store = merchants[0]
    base.isSingleStore = true
  }

  const params = getParamsFromUrl(search)

  if (params) {
    base = {
      ...base,
      dateRange: params.dateRange,
      endDate: params.endDate,
      startDate: params.startDate,
      summaryType: params.summaryType
    }
  }

  return base
}

const formikObject = {
  displayName: 'SettlementVerificationForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
