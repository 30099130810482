import React from 'react'
import withStyles from 'react-jss'
import Avatar from 'components/common/Avatar'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER, ROLE_VIEWER } from 'constants/general.constants'
import styles from './UserLegends.styles'
import Text from 'components/Text/Text'

const Legends = [
  {
    role: ROLE_OWNER,
    title: 'Owner'
  },
  {
    role: ROLE_GLOBAL_VIEWER,
    title: 'Global Viewer'
  },
  {
    role: ROLE_VIEWER,
    title: 'Viewer'
  }
]

const UserLegends = ({ classes }) => {
  return (
    <div className={classes['container']}>
      {Legends.map((legend, index) => {
        const { role, title } = legend
        return (
          <div className={classes['legend']} key={index}>
            <Avatar role={role} width={'1rem'} height={'1rem'} />
            <div className={classes['title']}>
              <Text variant="note"> {title}</Text>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(UserLegends)
