import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { MERCHANT_ID_REGEX } from '../../utils/validation.utils'
import queryString from 'query-string'

const validationSchema = Yup.object().shape({
  merchantId: Yup.string()
    .matches(MERCHANT_ID_REGEX, 'Enter a valid merchant number.')
    .required('Merchant Number is required.'),
  tcAgreement: Yup.bool().oneOf(
    [true],
    'You need to accept the Terms & Conditions and Privacy Policy before proceeding.'
  )
})

const handleSubmit = async (values, params) => {
  const { setSubmitting, setErrors } = params
  const { props } = params
  const { onSuccess, registerUser } = props

  try {
    await registerUser(values)
    setSubmitting(false)
    onSuccess()
  } catch (error) {
    setSubmitting(false)

    const { error: errorCode = 'bad_request' } = error
    setErrors({
      serverValidation: errorCode
    })
  }
}

const mapPropsToValues = props => {
  const {
    query: { merchantId }
  } = queryString.parseUrl(window.location.href)
  const {
    user: { firstName = '', lastName = '', email = '' }
  } = props
  return { firstName, lastName, email, merchantId, tcAgreement: false }
}

const formikObject = {
  displayName: 'SSORegisterUserForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
