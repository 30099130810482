import React from 'react'
import * as colors from 'config/colors'
import { ComposedChart, Line, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts'
import {
  GRAPH_SALES_COLOR,
  GRAPH_SALES_AREA_COLOR,
  GRAPH_PREVIOUS_PERIOD_COLOR,
  GRAPH_PREVIOUS_PERIOD_AREA_COLOR
} from 'constants/colors.contants'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import _ from 'lodash'
import { MAX_VIEWABLE_CHART_DATA_POINTS, DEFAULT_BAR_SALES_MAX_VALUE } from 'constants/general.constants'

const CustomComposedChart = ({
  data,
  isCompare,
  showTooltip = false,
  minTickGap = 2,
  CustomTooltip,
  showYAxis = false,
  CustomDot = false,
  handleClick = _.noop,
  minValue = 0,
  maxValue = DEFAULT_BAR_SALES_MAX_VALUE
}) => {
  const strokeWidth = showTooltip ? '4' : '2'
  const interval = data.length > MAX_VIEWABLE_CHART_DATA_POINTS ? 2 : 0

  return (
    <ResponsiveContainer>
      <ComposedChart data={data} margin={{ right: 20, top: 20 }}>
        {isCompare && <Area type="linear" dataKey="oldSales" fillOpacity={1} fill={GRAPH_PREVIOUS_PERIOD_AREA_COLOR} />}
        <Area type="linear" dataKey="currentSales" fillOpacity={1} fill={GRAPH_SALES_AREA_COLOR} />
        {isCompare && (
          <Line
            type="linear"
            dot={CustomDot}
            dataKey="oldSales"
            stroke={GRAPH_PREVIOUS_PERIOD_COLOR}
            strokeWidth={strokeWidth}
            activeDot={{ onClick: data => handleClick(data.payload, 'old') }}
          />
        )}
        <Line
          type="linear"
          dot={CustomDot}
          dataKey="currentSales"
          stroke={GRAPH_SALES_COLOR}
          strokeWidth={strokeWidth}
          activeDot={{ onClick: data => handleClick(data.payload, 'current') }}
        />
        {showTooltip && (
          <Tooltip {...{ content: CustomTooltip, cursor: false, isAnimationActive: false, position: { y: 0 } }} />
        )}

        <XAxis
          dataKey="currentTime"
          interval={showTooltip ? interval : 'preserveStartEnd'}
          minTickGap={minTickGap}
          tick={{ fill: colors.greyLight, fontSize: '10' }}
          stroke={colors.greyLight}
          tickLine={false}
          scale="point"
        />

        {showYAxis && (
          <YAxis
            domain={[minValue, maxValue]}
            tick={{ fill: colors.greyLight, fontSize: '10' }}
            stroke={null}
            tickLine={false}
            tickFormatter={value => {
              const displayCount = value < 1000 ? value : `${numeral(value).format('0a')}`
              return displayCount
            }}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

CustomComposedChart.prototype = {
  isCompare: PropTypes.bool,
  showTooltip: PropTypes.bool,
  CustomTooltip: PropTypes.element,
  data: PropTypes.object
}

export default React.memo(CustomComposedChart)
