import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { LARGE_SCREEN_UP, MOBILE_UP } = mediaQueryHelper

const styles = {
  container: {
    padding: ['1rem'],
    [LARGE_SCREEN_UP()]: {
      padding: ['2rem']
    }
  },
  actionBtn: {
    [MOBILE_UP()]: {
      display: 'none'
    },
    display: 'flex',
    justifyContent: 'center',
    padding: ['1.5em', 0, 0]
  },
  infoMessage: {
    marginTop: '20px'
  }
}

export default styles
