import { condensedFontFamily } from 'config/jss-vars'

import { accentDark, accentLight, brandDark, orangeLight, orangeDark, brandLight } from 'config/colors'

const styles = {
  base: {
    cursor: 'pointer',
    fontFamily: condensedFontFamily,
    fontSize: 15,
    textDecoration: 'none',
    textTransform: 'uppercase'
  },
  button: {
    composes: '$base',
    borderRadius: 100,
    color: '#fff',
    display: 'inline-block',
    minWidth: 80,
    outline: 'none',
    padding: '10px 30px',

    '&:disabled': {
      cursor: 'default',
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  primary: {
    composes: '$button',
    backgroundColor: brandLight,
    extend: ({ override }) => ({
      ...override.primary
    })
  },
  secondary: {
    composes: '$button',
    '&, &:focus': {
      background: `linear-gradient(287deg, ${accentLight}, ${accentDark})`,
      border: 0,
      '@media (min-width: 800px)': {
        '&:hover, &:active': {
          background: accentLight
        }
      }
    },
    extend: ({ override }) => ({
      ...override.secondary
    })
  },
  tertiary: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: 'transparent',
      border: `solid 2px ${accentDark}`,
      color: accentDark,
      '@media (min-width: 800px)': {
        '&:hover': {
          backgroundColor: accentLight,
          color: '#fff'
        }
      },
      '&:active': {
        filter: 'brightness(75%)'
      }
    },
    extend: ({ override }) => ({
      ...override.tertiary
    })
  },
  inverse: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: 'transparent',
      border: `solid 2px ${brandDark}`,
      color: brandDark,
      '@media (min-width: 800px)': {
        '&:hover': {
          backgroundColor: brandDark,
          color: '#fff'
        }
      },
      '&:active': {
        filter: 'brightness(75%)'
      }
    },
    extend: ({ override }) => ({
      ...override.inverse
    })
  },
  alternative: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: orangeLight,
      backgroundImage: 'linear-gradient(270deg, #ff6546 0, #f7822a 110%)',
      border: 0,
      color: '#fff',
      '&:after': {
        backgroundColor: 'transparent',
        opacity: 0
      },
      '&:hover': {
        backgroundColor: orangeLight,
        color: '#fff',
        '&:after': {
          backgroundColor: orangeDark,
          opacity: 1
        }
      }
    },
    extend: ({ override }) => ({
      ...override.alternative
    })
  },
  'alternative-inverse': {
    composes: 'button$',
    backgroundColor: 'transparent',
    border: `2px solid ${orangeLight}`,
    color: orangeLight,
    transition: 'color 0.2s ease-in-out',
    '&:active': {
      filter: 'brightness(75%)'
    },
    '&:hover': {
      border: `2px solid ${orangeDark}`,
      color: orangeDark
    },
    extend: ({ override }) => ({
      ...override['alternative-inverse']
    })
  },
  link: {
    composes: '$base',
    border: 0,
    color: '#f7822a',
    cursor: 'pointer',
    display: 'inline',
    padding: 0,
    backgroundColor: 'transparent',
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5,
      pointerEvents: 'none'
    },
    extend: ({ override }) => ({
      ...override['link']
    })
  },
  full: {
    width: '100%',
    extend: ({ override }) => ({
      ...override['full']
    })
  },
  normal: {
    minWidth: 120,
    extend: ({ override }) => ({
      ...override['normal']
    })
  }
}

export default styles
