import _ from 'lodash'
import {
  SERVER_ROLE_OWNER,
  SERVER_ROLE_VIEWER,
  ROLE_OWNER,
  ROLE_GLOBAL_VIEWER,
  ROLE_VIEWER,
  ROLE_NON_INSIGHTS_USER
} from 'constants/general.constants'

export const getInsightsRole = (roles, key = 'merchantId') => {
  if (roles) {
    let insightsRole = ROLE_VIEWER
    for (let roleElem of roles) {
      const { merchantId, role, cardAcceptorIdCode } = roleElem

      const id = key === 'merchantId' ? merchantId : cardAcceptorIdCode

      // Giving Owner the priority
      if (role === SERVER_ROLE_OWNER) {
        insightsRole = ROLE_OWNER
        break
      } else if (role === SERVER_ROLE_VIEWER && id === undefined) {
        insightsRole = ROLE_GLOBAL_VIEWER
      }
    }

    return insightsRole
  }

  return ROLE_NON_INSIGHTS_USER
}

export const isInsightsOwner = roles => {
  return ROLE_OWNER === getInsightsRole(roles)
}

const getFirstChar = name => {
  return _.slice(name, 0, 1)
}

export const getInitials = user => {
  const { firstName, lastName } = user

  return `${firstName && getFirstChar(firstName)}${lastName && getFirstChar(lastName)}`
}
