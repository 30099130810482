import React from 'react'
import PropTypes from 'prop-types'
import jss from './Fieldset.styles'
import withStyles from 'react-jss'

const Fieldset = ({ children, classes }) => {
  return <fieldset className={classes['fieldset']}>{children}</fieldset>
}

Fieldset.propTypes = {
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    fieldset: PropTypes.object
  }),
  children: PropTypes.any.isRequired
}

Fieldset.defaultProps = {
  override: {}
}

export default withStyles(jss)(Fieldset)
