import emailMask from 'text-mask-addons/dist/emailMask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
  includeThousandsSeparator: true
})

const getMaskProps = type => {
  switch (type) {
    case 'email':
      return {
        mask: emailMask
      }
    case 'phone':
      return {
        mask: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      }
    case 'time':
      return {
        mask: [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /[ap]/, /[m]/]
      }
    case 'time24':
      return {
        mask: [/[0-0]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
      }
    case 'currency':
      return {
        mask: currencyMask
      }

    default:
      return undefined
  }
}

export default getMaskProps
