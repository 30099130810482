import {
  SERVER_ROLE_OWNER,
  SERVER_ROLE_VIEWER,
  ROLE_OWNER,
  ROLE_GLOBAL_VIEWER,
  ROLE_VIEWER
} from 'constants/general.constants'

export const getUpdatePayload = data => {
  const { firstName, lastName, email, role, stores } = data

  let roles = []

  switch (role) {
    case ROLE_OWNER:
      roles = [
        {
          role: SERVER_ROLE_OWNER
        }
      ]
      break

    case ROLE_GLOBAL_VIEWER:
      roles = [
        {
          role: SERVER_ROLE_VIEWER
        }
      ]
      break

    case ROLE_VIEWER:
      roles = stores.map(store => {
        const { cardAcceptorIdCode } = store
        return {
          role: SERVER_ROLE_VIEWER,
          cardAcceptorIdCode
        }
      })
      break

    default:
      break
  }

  return {
    firstName,
    lastName,
    email,
    roles
  }
}

export const getStoreAccessMessage = role => {
  let message = ''
  const OWNER_MESSAGE = 'has access to all stores under an organisation'
  const GLOBAL_VIEWER_MESSAGE = 'has read-only access to all stores under an organisation'
  switch (role) {
    case ROLE_OWNER:
      message = `Owner ${OWNER_MESSAGE}`.toUpperCase()
      break
    case ROLE_GLOBAL_VIEWER:
      message = `Global Viewer ${GLOBAL_VIEWER_MESSAGE}`.toUpperCase()
      break
    default:
      break
  }

  return message
}
