import React from 'react'
import { ImprovementPercentage } from 'components/common'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import { GRAPH_CURRENT_PERIOD_COLOR, GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'

const CustomersLabel = ({
  classes,
  label,
  iconName,
  customersCurrentPercentage,
  currentPreviousPercentage,
  isCompare
}) => {
  const changePercentage = customersCurrentPercentage - currentPreviousPercentage
  return (
    <>
      <div className={classes['label']} data-hj-suppress>
        <Icon name={iconName} width={'1em'} height={'1em'} />
        <Text variant="h4">{label} </Text>
      </div>
      <div className={classes['value']} data-hj-suppress>
        <div className={classes['currencyValue']}>
          <ImprovementPercentage
            value={customersCurrentPercentage}
            variant={'h2'}
            iconSize={null}
            positiveColor={GRAPH_CURRENT_PERIOD_COLOR}
          />
          {isCompare && (
            <ImprovementPercentage
              value={currentPreviousPercentage}
              variant={'h3'}
              iconSize={null}
              positiveColor={GRAPH_PREVIOUS_PERIOD_COLOR}
            />
          )}
        </div>
        {isCompare && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage value={changePercentage} />
          </div>
        )}
      </div>
    </>
  )
}

export default CustomersLabel
