import React, { useState, useEffect, useCallback } from 'react'
import styles from './StoreBody.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import { NotificationModal, FieldSection, PermissionsInfoModal, ConfirmationDialog } from 'components/common'
import { getSingleStore } from 'api/stores.api'
import StoreBodyLoader from './StoreBodyLoader'
import StoreDeleteModal from '../StoreDeleteModal'
import { ROLE_OWNER } from 'constants/general.constants'
import useLoggedInUser from 'hooks/useLoggedInUser'
import StoreForm from '../StoreForm'
import UserCollectionSearch from '../UserCollectionSearch'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { storesSlice, usersSlice } from 'reduxSlices'
import apis from 'api'
import { EVENT_DELETE_USER, EVENT_EDIT_STORE_INFORMATION } from 'constants/mixpanel.constants'

const addUserButtonStyles = {
  secondary: {
    padding: '0.625rem 1rem'
  }
}

const preferredNameStyles = {
  h5: {
    marginTop: '0.5rem'
  }
}

const SearchHeader = ({ classes }) => {
  const TITLE = 'SET UP USERS TO VIEW THIS STORE'

  return (
    <div className={classes['headerTitle']}>
      <h5>{TITLE}</h5>
    </div>
  )
}

const StoreBody = ({ cardAcceptorIdCode, classes, handleAddUserToStore }) => {
  const { actions: storesActions } = storesSlice
  const { selectors: usersSelectors } = usersSlice
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [removeUserConfirmationVisible, setRemoveUserConfirmationVisible] = useState(false)
  const [deleteUserConfirmationVisible, setDeleteUserConfirmationVisible] = useState(false)
  const [messageDialogVisible, setMessageDialogVisible] = useState(false)
  const [userToBeRemoved, setUserToBeRemoved] = useState(null)
  const [store, setStore] = useState({})
  const [isLoading, setLoading] = useState(true)
  const { role } = useLoggedInUser()
  const dispatch = useDispatch()
  const usersUpdated = useSelector(usersSelectors.usersUpdated)

  const { actions: usersActions } = usersSlice
  const updateUserAction = useCallback(actionParams => dispatch(usersActions.updateUser(actionParams)), [
    dispatch,
    usersActions
  ])
  const deleteUserAction = useCallback(actionParams => dispatch(usersActions.deleteUser(actionParams)), [
    dispatch,
    usersActions
  ])
  const onInfoModalClose = () => {
    setInfoModalVisible(false)
  }

  const handleUpdateStore = useCallback(
    store => {
      const { Mixpanel: mixpanel } = apis

      mixpanel.track(EVENT_EDIT_STORE_INFORMATION, {
        preferredNameUsed: !!store.preferredName && store.tradingName !== store.preferredName
      })

      dispatch(storesActions.updateStore(store))
    },
    [dispatch, storesActions]
  )

  const onRemoveModalClose = () => {
    setRemoveUserConfirmationVisible(false)
  }

  const onRemoveButtonClick = user => {
    setUserToBeRemoved(user)
    setRemoveUserConfirmationVisible(true)
  }

  const showMessageDialog = () => {
    setRemoveUserConfirmationVisible(false)
    setDeleteUserConfirmationVisible(false)
    setMessageDialogVisible(true)
  }

  const onRemoveAccept = async () => {
    const { Mixpanel: mixpanel } = apis

    const usersByEmail = await apis.getUsersByEmail(userToBeRemoved.email)
    if (_.isEmpty(usersByEmail)) {
      showMessageDialog()
      return
    }
    const updatedUserByEmail = usersByEmail[0]
    if (!_.isEmpty(updatedUserByEmail.roles) && updatedUserByEmail.roles.length === 1) {
      // Only 1 store left so show another confirmation dialog
      const filteredUserByCaic = _.filter(
        updatedUserByEmail.roles,
        role => role.cardAcceptorIdCode === cardAcceptorIdCode
      )
      if (_.isEmpty(filteredUserByCaic)) {
        showMessageDialog()
      } else {
        setDeleteUserConfirmationVisible(true)
        setRemoveUserConfirmationVisible(false)
      }
    } else {
      mixpanel.track(EVENT_EDIT_STORE_INFORMATION, { userRemoved: 1 })

      const removeResults = _.remove(updatedUserByEmail.roles, role => role.cardAcceptorIdCode === cardAcceptorIdCode)
      if (_.isEmpty(removeResults)) {
        showMessageDialog()
      } else {
        await updateUserAction(updatedUserByEmail)
        setRemoveUserConfirmationVisible(false)
      }
    }
  }

  const onDeleteUserModalClose = () => {
    setDeleteUserConfirmationVisible(false)
  }

  const onDeleteUserAccept = async () => {
    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_EDIT_STORE_INFORMATION, { userRemoved: 1 })
    mixpanel.track(EVENT_DELETE_USER)

    await deleteUserAction(userToBeRemoved.id)
    setDeleteUserConfirmationVisible(false)
  }

  const onMessageAcceptClose = () => {
    setMessageDialogVisible(false)
  }

  const callApi = async cardAcceptorIdCode => {
    setLoading(true)
    const result = await getSingleStore(cardAcceptorIdCode)
    setStore({ ...result, displayName: result.preferredName || result.tradingName })
    setLoading(false)
  }

  useEffect(() => {
    callApi(cardAcceptorIdCode)
  }, [cardAcceptorIdCode])

  useEffect(() => {
    if (usersUpdated) {
      callApi(cardAcceptorIdCode)
    }
  }, [cardAcceptorIdCode, usersUpdated])

  const { tradingName, displayName, _users } = store

  const sortedUsers = _.orderBy(_users, ['firstName', 'lastName'], ['asc'])

  return (
    <>
      {isLoading && <StoreBodyLoader classes={classes} />}

      {!isLoading && (
        <div className={classes['storeBody']} data-hj-suppress>
          <FieldSection title={'Business Name'} classes={classes}>
            <Text variant="body" color={colors.greyDark}>
              {tradingName}
            </Text>
          </FieldSection>
          <FieldSection title={'Preferred Name'} classes={classes} textOverride={preferredNameStyles}>
            {role === ROLE_OWNER && <StoreForm store={store} onStoreUpdate={handleUpdateStore} />}
            {role !== ROLE_OWNER && (
              <Text variant="body" color={colors.greyDark}>
                {displayName}
              </Text>
            )}
          </FieldSection>
          <FieldSection title={'Merchant Number'} classes={classes}>
            <Text variant="body" color={colors.greyDark}>
              {cardAcceptorIdCode}
            </Text>
          </FieldSection>
          <FieldSection title={'Users'} classes={classes}></FieldSection>

          <SearchHeader classes={classes} />
          <UserCollectionSearch list={sortedUsers} onRemove={onRemoveButtonClick} />

          {role === ROLE_OWNER && (
            <div className={classes['addUserButton']} onClick={() => handleAddUserToStore(store)}>
              <Button variant="secondary" override={addUserButtonStyles}>
                Add User
              </Button>
            </div>
          )}

          {role === ROLE_OWNER && <StoreDeleteModal merchantId={cardAcceptorIdCode} />}

          {removeUserConfirmationVisible && (
            <ConfirmationDialog
              {...{
                onAccept: onRemoveAccept,
                onClose: onRemoveModalClose
              }}
            >
              <div data-hj-suppress>
                <Text variant="body">
                  Are you sure you want to remove store's access to {userToBeRemoved.firstName}{' '}
                  {userToBeRemoved.lastName}?
                </Text>
              </div>
            </ConfirmationDialog>
          )}

          {deleteUserConfirmationVisible && (
            <ConfirmationDialog
              {...{
                onAccept: onDeleteUserAccept,
                onClose: onDeleteUserModalClose
              }}
            >
              <div data-hj-suppress>
                <Text variant="body">
                  This store is the last store this viewer has access to, removing the access will delete this user.
                  User will need to be re-invited if access is required again.
                </Text>
                <Text variant="body">
                  Are you sure you want to remove store's access to {userToBeRemoved.firstName}{' '}
                  {userToBeRemoved.lastName}?
                </Text>
              </div>
            </ConfirmationDialog>
          )}

          {messageDialogVisible && (
            <NotificationModal
              {...{
                onClose: onMessageAcceptClose
              }}
            >
              <div>
                <Text variant="body">Something went wrong. Please refresh this page and try again.</Text>
              </div>
            </NotificationModal>
          )}

          {infoModalVisible && <PermissionsInfoModal {...{ onClose: onInfoModalClose }} />}
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(StoreBody)
