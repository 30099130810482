import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { LAPTOP_UP } = mediaQueryHelper

const styles = {
  content: {
    width: '100%',
    height: '100px',
    maxWidth: '400px',
    margin: 'auto'
  },
  expandedContent: {
    width: '100%',
    height: '250px',
    padding: ['0.25rem'],
    margin: 'auto'
  },
  graph: {
    margin: ['0.5rem', '1rem', 0, '1rem'],
    [LAPTOP_UP]: {
      margin: ['0.5rem', '2rem', 0, '2rem']
    }
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: ({ isExpanded }) => (isExpanded ? '85px' : '50px')
  },
  currencyValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  changeValue: {
    marginLeft: '0.5rem'
  }
}

export default styles
