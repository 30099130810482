import React from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import { ImprovementPercentage } from 'components/common'
import { getCurrency } from 'utils/number.utils'
import { isDesktop } from 'utils/common.utils'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'

const SalesLabel = ({ classes, label, currentAverageSale, previousAverageSale, isCompare }) => {
  const changePercentage =
    previousAverageSale > 0 ? (currentAverageSale - previousAverageSale) / previousAverageSale : 'n/a'

  return (
    <>
      <div className={classes['label']} data-hj-suppress>
        <Icon name="sales" width={'1em'} height={'1em'} />
        <Text variant="h4"> {label} </Text>
      </div>
      <div className={classes['value']} data-hj-suppress>
        <div className={classes['currencyValue']}>
          <Text variant="h4">{getCurrency(currentAverageSale, !isDesktop, true)}</Text>
          {isCompare && (
            <Text variant="h5" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
              {getCurrency(previousAverageSale, !isDesktop, true)}
            </Text>
          )}
        </div>
        {isCompare && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage value={changePercentage} />{' '}
          </div>
        )}
      </div>
    </>
  )
}

export default SalesLabel
