const styles = {
  group: {
    extend: ({ override }) => ({
      ...override['group']
    })
  },
  'error-message': {
    margin: '1.25rem 0 0 0',
    '& >p': {
      textAlign: 'center'
    },
    extend: ({ override }) => ({
      ...override['error-message']
    })
  },
  selectedValue: {
    display: 'flex',
    '& >div': {
      paddingRight: '0.5rem'
    },
    extend: ({ override }) => ({
      ...override['selectedValue']
    })
  }
}

export default styles
