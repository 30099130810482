import * as FileSaver from 'file-saver'
import moment from 'moment'
import { baseStyles } from '../styles/print.styles'

export const downloadCSV = (csvContent, baseFileName, query) => {
  const blob = new Blob([csvContent], { type: 'text/csv' })
  let fileName = getFileName(baseFileName, query)
  fileName = `${fileName}.csv`
  FileSaver.saveAs(blob, fileName)
}

export const getFileName = (baseFileName, searchQuery) => {
  const timeFormat = 'DDMMMYYYY'

  if (searchQuery) {
    const timeFrom = moment(searchQuery['from']).format(timeFormat)
    const timeTo = moment(searchQuery['to']).format(timeFormat)

    return `${baseFileName}-${timeFrom}-to-${timeTo}`
  }
  return baseFileName
}

export const print = (reportTitle, value, printStyles = baseStyles) => {
  if (document.getElementById(value)) {
    let popupWin
    const printContents = document.getElementById(value).innerHTML
    const template = buildPrintTemplate(printContents, reportTitle, printStyles)
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto')
    popupWin.document.open()
    popupWin.document.write(template)
    popupWin.document.close()
  }
}

export const buildPrintTemplate = (printContents, reportTitle, printStyles) => {
  return `
    <html>
      <head>
        <title>${reportTitle}</title>
        <style>${printStyles}</style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>
  `
}
