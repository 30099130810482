import React from 'react'
import withStyles from 'react-jss'
import styles from './InviteUserForm.styles'
import { SearchInput } from 'components/common'
import _ from 'lodash'
import useMyAccount from 'components/MyAccount/useMyAccount'
import { SelectedItems, PermissionsConfirmationModal } from 'components/common'
import Text from 'components/Text/Text'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import useStoreSelector from 'hooks/useStoreSelector'

const StoreSelector = ({ classes, onChangeUserToGlobalViewer, handleStoreSelection }) => {
  const { stores: allStores } = useMyAccount()

  const {
    onSearchTextChange,
    searchText,
    selectedStores,
    deSelectStore,
    filteredStores,
    onSelectAllFiltered,
    onSelectAll,
    onSelectStore,
    isAddSearched,
    isAddAll,
    isGlobalViewConfirmation,
    closeConfirmationModal
  } = useStoreSelector({
    availableStores: allStores,
    handleStoreSelection
  })

  const text = 'Are you sure you want to add all stores? This will set the user up as a Global Viewer.'

  return (
    <div className={classes['selector']}>
      <div className={classes['storeAccess']}>
        <div className={'message'}>
          <Text variant="h5">SET UP PERMISSIONS TO VIEW STORES</Text>
        </div>
        <div className={'link'}>
          <Checkbox
            label={<Text variant="body">Add all stores</Text>}
            checked={isAddAll}
            onClick={() => {
              onSelectAll()
            }}
            onChange={e => {
              e.preventDefault()
            }}
          />
        </div>
      </div>

      <div className={classes['search']}>
        <SearchInput onChange={onSearchTextChange} placeholder={'Search Stores by Name'} text={searchText} />
      </div>

      {!_.isEmpty(selectedStores) && (
        <div className={classes['selectedStores']}>
          <SelectedItems
            {...{
              list: selectedStores,
              onClick: deSelectStore,
              keyExtractor: item => {
                return item.displayName
              },
              title: 'SELECTED STORES',
              isSelected: true
            }}
          />
        </div>
      )}

      {searchText && (
        <div className={classes['addSearchResult']}>
          <Checkbox
            label={<Text variant="body">{'Add all search results'}</Text>}
            checked={isAddSearched}
            onChange={() => {}}
            onClick={onSelectAllFiltered}
            disabled={filteredStores.length === 0}
          />
        </div>
      )}

      <div className={classes['searchResult']}>
        <SelectedItems
          {...{
            list: filteredStores,
            onClick: onSelectStore,
            keyExtractor: item => {
              return item.displayName
            },
            isSelected: false
          }}
        />
      </div>

      {isGlobalViewConfirmation && (
        <PermissionsConfirmationModal
          {...{
            onAccept: onChangeUserToGlobalViewer,
            onClose: closeConfirmationModal,
            text
          }}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(StoreSelector)
