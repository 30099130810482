import React from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import { ImprovementPercentage } from 'components/common'

const ReturnCustomers = ({ classes, value, isGrowthVisible }) => {
  return (
    <div className={classes['customerLabel']}>
      <div className={classes['label']}>
        <Text variant="h5"> Return </Text>
        <Text variant="h5">Customers</Text>
        {isGrowthVisible && <ImprovementPercentage {...{ classes, value: value / 100 }} />}
      </div>
      <div className={classes['icon']}>
        <Icon name={'return'} />
      </div>
    </div>
  )
}

export default ReturnCustomers
