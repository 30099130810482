import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, up, BREAK_POINT_LARGE, BREAK_POINT_MEDIUM, MOBILE_DOWN } = mediaQueryHelper

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'grid',
    msGridColumns: 'minmax(min-content, 1fr)',
    gridTemplateColumns: 'auto',
    padding: ['1.5rem', '1em', '1em'],
    [up(BREAK_POINT_MEDIUM)]: {
      msGridColumns: 'minmax(min-content, 1fr) 250px',
      gridTemplateColumns: 'auto 250px',
      padding: ['1.5rem', '2em', '1em']
    },
    color: colors.greyDark,
    borderBottom: `1px solid ${colors.line1}`,
    borderTop: `1px solid ${colors.cyan}`,
    '& .export': {
      display: 'inline-block',
      textAlign: 'center',
      [up(BREAK_POINT_MEDIUM)]: {
        float: 'right',
        textAlign: 'right',
        display: 'block',
        msGridColumn: 2,
        msGridRow: 1
      }
    },
    '& .export > DIV': {
      [MOBILE_DOWN()]: {
        float: 'none',
        display: 'inline-block'
      }
    },
    '& .title': {
      margin: ['auto', 0],
      textAlign: 'center',
      msGridColumn: 1,
      msGridRow: 1,
      [up(BREAK_POINT_MEDIUM)]: {
        textAlign: 'right'
      },
      [up(BREAK_POINT_LARGE)]: {
        textAlign: 'left'
      }
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.cyan}`
  },
  title: {
    padding: ['1.5rem', '1em', '1em'],
    display: 'grid',
    gridTemplateColumns: '1fr',
    msGridRows: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    color: colors.greyDark,
    gridTemplateAreas: `
                        'name' 
                        'date'
                        'number'
                        `,
    '& .name': {
      gridArea: 'name',
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 1
    },
    '& .date': {
      gridArea: 'date',
      padding: ['0.5em'],
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 2
    },
    '& .number': {
      gridArea: 'number',
      padding: [0, '0.5em'],
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 3
    },
    [MOBILE_UP()]: {
      msGridColumns: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      msGridRows: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gridTemplateAreas: `
                          'name name name name' 
                          'date date number number'`,
      '& .name': {
        msGridColumnSpan: 4
      },
      '& .date': {
        float: 'right',
        margin: 'auto 0',
        msGridColumnSpan: 2
      },
      '& .number': {
        float: 'left',
        margin: 'auto 0',
        msGridColumn: 3,
        msGridRow: 2,
        msGridColumnSpan: 2
      },
      width: ({ selectedTransaction }) => (selectedTransaction ? '420px' : '100%')
    },
    [up(BREAK_POINT_LARGE)]: {
      padding: ['1.5em', '2em', '0.5em'],
      gridTemplateAreas: `'name date number'`,
      msGridColumns: 'minmax(min-content, 1fr) 300px 225px',
      gridTemplateColumns: 'auto 300px 225px',
      msGridRows: '1fr',
      gridTemplateRows: '1fr',
      width: ({ selectedTransaction }) => (selectedTransaction ? '960px' : '100%'),
      '& .name': {
        margin: '0',
        msGridColumn: 1,
        msGridRow: 1,
        msGridColumnSpan: 1
      },
      '& .number': {
        textAlign: 'left',
        paddingLeft: '1.5em',
        msGridColumn: 2,
        msGridRow: 1,
        msGridColumnSpan: 1
      },
      '& .date': {
        margin: 'auto 0',
        msGridColumn: 3,
        msGridRow: 1,
        msGridColumnSpan: 1
      }
    }
  },
  section: {
    padding: [0, 0, '1em'],
    display: 'flex',
    [up(BREAK_POINT_LARGE)]: {
      padding: [0, '2em', '1em']
    }
  },
  tableSection: {
    flex: 'auto'
  },
  detailsSection: {
    width: 0,
    [MOBILE_UP()]: {
      width: '330px'
    },
    [up(BREAK_POINT_LARGE)]: {
      width: '370px'
    }
  },

  emptyMessageContainer: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
}

export default styles
