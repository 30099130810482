import { MOBILE_DOWN } from 'config/mediaQueryHelper'
import { white, heading, teal } from 'config/colors'
import { weightSemiBold } from 'config/jss-vars'

const container = {
  [MOBILE_DOWN()]: {
    width: 'calc(98vw)'
  },
  maxWidth: '98vw',
  maxHeight: '98vh',
  overflowY: 'auto',
  padding: '1.5rem',
  backgroundColor: white,
  borderRadius: '0.25rem',
  border: `1px solid ${teal}`,
  extend: ({ override }) => ({
    ...override['container']
  })
}
const styles = {
  container,
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1rem',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.5'
    },
    extend: ({ override }) => ({
      ...override['closeIcon']
    })
  },

  title: {
    color: heading,
    fontSize: '1rem',
    fontWeight: weightSemiBold,
    '& hr': {
      margin: '0.5rem 0 0 0 '
    },
    extend: ({ override }) => {
      return {
        ...override['title']
      }
    }
  },

  body: {
    padding: '0.5rem 0 0 0 ',
    width: 'auto',
    extend: ({ override }) => ({
      ...override['body']
    })
  },

  small: {
    width: '340px',
    ...container,
    extend: ({ override }) => ({
      ...override['small']
    })
  },

  medium: {
    width: '720px',
    ...container,
    extend: ({ override }) => ({
      ...override['medium']
    })
  },

  large: {
    width: '1080px',
    ...container,
    extend: ({ override }) => ({
      ...override['large']
    })
  },

  custom: {
    ...container,
    extend: ({ override }) => ({
      ...override['custom']
    })
  }
}

export default styles
