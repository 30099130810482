import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'
import { logout } from 'utils/logout.utils'

const LoginLink = () => {
  const handleGoToLogin = () => {
    logout()
  }

  return (
    <>
      <div>
        <Text variant="h5">Already have an account?</Text>
      </div>
      <div>
        <Button variant="link" onClick={handleGoToLogin}>
          Login to Paymark Insights
        </Button>
      </div>
    </>
  )
}

LoginLink.propTypes = {
  goToLogin: PropTypes.func
}

export default LoginLink
