import { configureStore, getDefaultMiddleware } from 'redux-starter-kit'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import {
  accountSlice,
  settlementSlice,
  transactionSlice,
  dashboardSlice,
  storeSlice,
  storesSlice,
  usersSlice
} from './reduxSlices'

const reducer = {
  [accountSlice.slice]: accountSlice.reducer,
  [settlementSlice.slice]: settlementSlice.reducer,
  [transactionSlice.slice]: transactionSlice.reducer,
  [dashboardSlice.slice]: dashboardSlice.reducer,
  [storeSlice.slice]: storeSlice.reducer,
  [storesSlice.slice]: storesSlice.reducer,
  [usersSlice.slice]: usersSlice.reducer
}

function* rootSaga() {
  yield all([
    accountSlice.sagas(),
    settlementSlice.sagas(),
    transactionSlice.sagas(),
    dashboardSlice.sagas(),
    storeSlice.sagas(),
    storesSlice.sagas(),
    usersSlice.sagas()
  ])
}

const sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware(), sagaMiddleware]

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
  enhancers: []
})

sagaMiddleware.run(rootSaga)

export default store
