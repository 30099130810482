import * as colors from 'config/colors'
import { standardFontFamily, weightRegular } from 'config/jss-vars'

const styles = {
  input: {
    verticalAlign: 'middle',
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    backgroundColor: colors.white,
    borderColor: colors.teal,
    borderStyle: 'none none solid',
    borderWidth: 1,
    color: colors.greyDark,
    padding: '0.625rem',
    fontSize: '1rem',
    borderRadius: 0,
    width: '100%',
    height: 35,
    '&:focus': {
      background: 'rgba(236, 237, 251, 0.5)',
      outline: 0,
      extend: ({ override }) => ({
        ...override['input:focus']
      })
    },
    '&::placeholder': {
      fontSize: '0.9rem',
      extend: ({ override }) => ({
        ...override['input::placeholder']
      })
    },
    '&:disabled, &[readonly]': {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.8,
      borderBottom: '2px dotted rgba(0, 0, 0, 0.3)',
      extend: ({ override }) => ({
        ...override['input:disabled']
      })
    },
    extend: ({ override }) => ({
      ...override['input']
    })
  },
  'input-error': {
    composes: '$input',
    color: colors.error,
    borderColor: colors.error,
    extend: ({ override }) => ({
      ...override['input-error']
    })
  },
  clear: {
    position: 'absolute',
    padding: 1,
    right: 8,
    width: '1.25rem',
    height: '1.25rem',
    cursor: 'pointer',
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
      extend: ({ override }) => ({
        ...override['clear:hover']
      })
    },
    extend: ({ override }) => ({
      ...override['clear']
    })
  },
  help: {
    position: 'absolute',
    margin: '-35px 0 0',
    padding: 1,
    right: '-15px',
    width: '1.25rem',
    height: '1.25rem',
    '& svg': {
      width: '1rem',
      extend: ({ override }) => ({
        ...override['help svg']
      })
    },
    extend: ({ override }) => ({
      ...override['help']
    })
  },
  'error-message': {
    margin: '1.25rem 0 0 0',
    '& p': {
      textAlign: 'center',
      extend: ({ override }) => ({
        ...override['error-message p']
      })
    },
    extend: ({ override }) => ({
      ...override['error-message']
    })
  },
  group: {
    marginBottom: '1.5625rem',
    position: 'relative',
    extend: ({ override }) => ({
      ...override['group']
    })
  }
}

export default styles
