import React from 'react'
import ContentLoader from 'react-content-loader'

const UserListLoader = () => {
  return (
    <ContentLoader height={250} width={1000} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="20" rx="5" ry="5" width="1000" height="40" />
      <rect x="0" y="80" rx="5" ry="5" width="1000" height="40" />
      <rect x="0" y="140" rx="5" ry="5" width="1000" height="40" />
      <rect x="0" y="200" rx="5" ry="5" width="1000" height="40" />
    </ContentLoader>
  )
}

export default UserListLoader
