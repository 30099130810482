import * as mediaQueryHelper from 'config/mediaQueryHelper'
const styles = {
  containerContent: {
    [mediaQueryHelper.LAPTOP_UP()]: {
      paddingTop: ({ withContainerPaddingTop }) => (withContainerPaddingTop ? '2em' : '0'),
      paddingBottom: '2em'
    },
    paddingTop: ({ withContainerPaddingTop }) => (withContainerPaddingTop ? '1em' : '0'),
    paddingBottom: '1em'
  },
  container: {
    maxWidth: '1360px',
    margin: 'auto'
  },
  fullHeight: {
    minHeight: 'calc(100vh - 120px)',
    [mediaQueryHelper.TABLET_DOWN()]: {
      minHeight: 'calc(100vh - 160px)'
    }
  }
}

export default styles
