import React, { useState } from 'react'
import styles from './StoresTable.styles'
import withStyles from 'react-jss'
import Paginator from 'components/Paginator/Paginator'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import ContentLoader from 'react-content-loader'
import { getPaginatedItems } from 'utils/filters.utils'
import { getCurrency, formatPercentage } from 'utils/number.utils'
import { ImprovementPercentage, ImprovementPercentageIcon } from 'components/common'
import { NA } from 'constants/general.constants'

const PER_PAGE = 10

const LoadingIndicator = () => {
  const random = Math.random() * (1 - 0.5) + 0.5

  return (
    <ContentLoader height={40} width={980} speed={2} primaryColor="#d9d9d9" secondaryColor="#ecebeb">
      <rect x="5" y="13" rx="6" ry="6" width={50 * random} height="16" />
      <rect x="100" y="13" rx="6" ry="6" width={200 * random} height="16" />
      <rect x="520" y="13" rx="6" ry="6" width={120 * random} height="16" />
      <rect x="750" y="13" rx="6" ry="6" width={100 * random} height="16" />
      <rect x="900" y="13" rx="6" ry="6" width={70 * random} height="16" />
    </ContentLoader>
  )
}

const LoadingTable = ({ classes }) => {
  const array = Array(3).fill('')
  return (
    <>
      <table className={classes['table']}>
        <TableHeader {...{ classes }} />
      </table>
      {array.map((data, index) => {
        return <LoadingIndicator key={index} />
      })}
    </>
  )
}

const ColumnTitle = ({ name, icon, classes }) => {
  return (
    <div className={classes['columnTitle']}>
      <div className="iconHeader">
        <Icon name={icon} width={'1em'} height={'1em'} />
      </div>
      <div className={classes['columnName']}> {name}</div>
    </div>
  )
}

const TableHeader = ({ classes }) => {
  return (
    <thead>
      <tr>
        <th className={classes['tableHeader']} />
        <th className={classes['tableHeader']} />
        <th className={classes['tableHeader']} colSpan={3}>
          <ColumnTitle {...{ name: 'Revenue', icon: 'revenue', classes }} />
        </th>
        <th className={classes['tableHeader']} colSpan={3}>
          <ColumnTitle {...{ name: 'Sales', icon: 'sales', classes }} />
        </th>
        <th className={classes['tableHeader']} colSpan={3}>
          <ColumnTitle {...{ name: 'Return', icon: 'customer', classes }} />
        </th>
      </tr>
    </thead>
  )
}

const ColumnData = ({ classes, value, changePercentage, isCompare, columnName }) => {
  if (changePercentage !== 'n/a') {
    changePercentage = changePercentage / 100
  }

  return (
    <>
      <td className={`${classes[columnName]} strict-width`}>
        <div className={`${classes['columnData']} print-col-numbers`}>
          <div className={`${classes['value']} print-spacing`}>
            <Text variant="body">{value}</Text>
          </div>

          {isCompare && (
            <div className={`${classes['iconMobile']} no-print`}>
              {changePercentage !== 'n/a' ? (
                <ImprovementPercentage {...{ value: changePercentage }} />
              ) : (
                changePercentage
              )}
            </div>
          )}
        </div>
      </td>
      <td className={`${classes['comparisonCol']} no-right-pad strict-width`}>
        {isCompare && (
          <div className={`${classes['icon']}`}>
            {changePercentage !== 'n/a' ? (
              <ImprovementPercentage {...{ value: changePercentage, showIcon: false }} />
            ) : (
              changePercentage
            )}
          </div>
        )}
      </td>
      <td className={`${classes['percentageComparisonCol']} no-left-pad strict-width`}>
        {isCompare && changePercentage !== 'n/a' && changePercentage !== 0 && (
          <div className={`${classes['icon']}`}>
            <ImprovementPercentageIcon {...{ value: changePercentage }} />
          </div>
        )}
      </td>
    </>
  )
}

const TopStoresTable = ({
  classes,
  stores,
  isLoading = false,
  isCompare = false,
  onSelect,
  hasPagination = false,
  isError = false
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const { page, totalPages, data: storesToDisplay = stores } = hasPagination
    ? getPaginatedItems(stores, currentPage, PER_PAGE)
    : {}

  const changeToPage = pageNo => {
    setCurrentPage(pageNo)
  }

  if (isLoading) {
    return <LoadingTable {...{ classes }} />
  }

  const onClick = cardAcceptorIdCode => {
    if (onSelect) {
      onSelect(cardAcceptorIdCode)
    }
  }

  return (
    <div className={classes['tableContainer']}>
      <table className={classes['table']} id="transactionTable">
        <TableHeader {...{ classes }} />
        <tbody>
          {isError && (
            <tr className={classes['errorMessage']}>
              <td colSpan={11}>
                <Text variant="body"> There was an problem getting top stores, please try again.</Text>
              </td>
            </tr>
          )}

          {storesToDisplay.map((item, index) => {
            let {
              displayName,
              cardAcceptorIdCode,
              revenue,
              sales,
              returnCustomers,
              revenueChangePercentage,
              salesChangePercentage,
              returnCustomersChangePercentage
            } = item
            const revenueValueFormatted = getCurrency(revenue)
            const returnCustomersFormatted = formatPercentage(returnCustomers / 100)

            if (returnCustomersChangePercentage === NA) {
              returnCustomersChangePercentage = returnCustomers !== 0 ? returnCustomers : 0
            }

            if (revenueChangePercentage === NA) {
              revenueChangePercentage = revenue === 0 ? 0 : revenueChangePercentage
            }

            if (salesChangePercentage === NA) {
              salesChangePercentage = sales === 0 ? 0 : salesChangePercentage
            }

            return (
              <tr key={index} className={classes['storeTableRow']} data-hj-suppress>
                <td className={classes['serialNoCol']}>
                  <Text variant="body">{hasPagination ? index + 1 + PER_PAGE * (currentPage - 1) : index + 1}</Text>
                </td>
                <td
                  className={`${classes['storeCol']} print-store-col`}
                  onClick={() => onClick({ cardAcceptorIdCode, displayName })}
                >
                  <Text variant="h5" color={colors.cyan}>
                    {displayName}
                  </Text>
                </td>

                <ColumnData
                  {...{
                    value: revenueValueFormatted,
                    changePercentage: revenueChangePercentage,
                    classes,
                    isCompare,
                    columnName: 'revenueCol'
                  }}
                />

                <ColumnData
                  {...{
                    value: sales,
                    changePercentage: salesChangePercentage,
                    classes,
                    isCompare,
                    columnName: 'salesCol'
                  }}
                />

                <ColumnData
                  {...{
                    value: returnCustomersFormatted,
                    changePercentage: returnCustomersChangePercentage,
                    classes,
                    isCompare,
                    columnName: 'returnCustomersCol'
                  }}
                />
              </tr>
            )
          })}
        </tbody>
      </table>

      {totalPages > 1 && hasPagination && (
        <div className={classes['pagination']}>
          <Paginator
            onNextClick={() => changeToPage(page + 1)}
            onPreviousClick={() => changeToPage(page - 1)}
            currentPage={page}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  )
}

export default React.memo(withStyles(styles)(TopStoresTable))
