const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    padding: ['1rem'],
    '& > div': {
      padding: [0, '0.5rem']
    },
    margin: '0'
  }
}

export default styles
