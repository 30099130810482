import React from 'react'
import spaLogin from 'utils/spaLogin'
import Divider from 'components/Divider/Divider'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import styles from './SSORegistrationSuccess.styles'
import withStyles from 'react-jss'

const SSORegistrationSuccess = params => {
  const { classes } = params

  const goToDashboard = () => {
    spaLogin.redirectToLogin({ fromLogout: false })
  }

  return (
    <div className={classes['container']}>
      <div className={classes['item']}>
        <Text className="register" variant="h1">
          Account created
        </Text>
      </div>
      <div className={classes['item']}>
        <Divider />
      </div>

      <div className={classes['successIcon']}>
        <Icon name="check-circle" color={colors.success} width={'3.5em'} />
      </div>

      <div className={classes['item']}>
        <Text variant="h5">Your account has been created</Text>
      </div>
      <div className={classes['item']}>
        <Text variant="note">You can now login with your email and password</Text>
      </div>
      <div className={classes['divider']}>
        <Divider />
      </div>

      <div className={classes['item']}>
        <Button variant="link" onClick={goToDashboard}>
          Go to Dashboard
        </Button>
      </div>
      <div className={classes['divider']}>
        <Divider />
      </div>
    </div>
  )
}

export default withStyles(styles)(SSORegistrationSuccess)
