import React from 'react'
import ContentLoader from 'react-content-loader'
import { isMobileWidth, isTabletWidth } from 'utils/common.utils'

let y1 = isMobileWidth() ? 30 : 1
y1 = isTabletWidth() ? 20 : y1
let y2 = isMobileWidth() ? 30 : 30
y2 = isTabletWidth() ? 20 : y2

const width = isTabletWidth() ? 300 : 288

const x = isMobileWidth() ? 80 : 60

const h = isMobileWidth() ? 200 : 50

const StoreRevenueCardLoader = ({ isCompareStore }) => {
  return (
    <ContentLoader height={125} width={400} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      {isCompareStore ? (
        <rect x={x} y={y1} rx="5" ry="5" width={width} height={h} />
      ) : (
        <rect x={x} y={y2} rx="5" ry="5" width={width} height={h} />
      )}
    </ContentLoader>
  )
}

export default StoreRevenueCardLoader
