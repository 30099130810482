import * as colors from 'config/colors'

const checkboxSize = '16px'
const checkboxBackgroundColor = colors.white
const checkboxBorderColour = colors.teal
const checkboxBorderRadius = '2px'
const checkboxCheckColour = colors.teal

const styles = {
  label: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '1.75rem',
    '&:hover': {
      cursor: 'pointer'
    },
    '&::before': {
      position: 'absolute',
      content: '""',
      display: 'inline-block',
      height: checkboxSize,
      width: checkboxSize,
      left: '0px',
      backgroundColor: checkboxBackgroundColor,
      border: `1px solid ${checkboxBorderColour}`,
      borderRadius: checkboxBorderRadius
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      display: 'inline-block',
      transform: 'rotate(-45deg)',
      borderBottom: `2px solid ${colors.white}`,
      borderLeft: `2px solid ${colors.white}`,
      height: ' 6px',
      left: '3px',
      top: '4px',
      width: ' 11px'
    },
    extend: ({ override }) => ({
      ...override['label']
    })
  },
  input: {
    opacity: 0,
    height: 0,
    width: 0,
    padding: 0,
    margin: 0,
    /* Hide the checkmark by default */
    '& > label::after': {
      content: 'none'
    },
    /* Unhide on the checked state */
    '&:checked + label::before': {
      borderColor: checkboxCheckColour,
      backgroundColor: checkboxCheckColour
    },
    /* Unhide on the checked state */
    '&:checked + label::after': {
      content: '""'
    },
    /* Adding focus styles on the outer-box of the fake checkbox */
    '&:focus + label::before': {
      outline: 'rgb(59, 153, 252) auto 5px'
    },
    extend: ({ override }) => ({
      ...override['input']
    })
  },
  group: {
    marginBottom: '0.5rem',
    textAlign: 'left',
    extend: ({ override }) => ({
      ...override['group']
    })
  },
  errorMessage: {
    margin: '1.25rem 0 0 0',
    '& > p': {
      textAlign: 'center'
    },
    extend: ({ override }) => ({
      ...override['errorMessage']
    })
  }
}

export default styles
