const URL_PARAM_NAME = 'url'
const TUTORIAL_MODAL_VIEWED = 'isTutorialCompleted'
const IE_COMPATABILITY_WARNING_VIEWED = 'ieCompatabilityWarningViewed'
const APPSWITCHER_MODAL_VIEWED = 'appSwitcherModalViewed'

export const addUrlInLocalStorage = value => {
  // Not storing url which have the access_token
  if (value.indexOf('access_token') > -1 || value.indexOf('error') > -1) {
    return
  }
  window.localStorage.setItem(URL_PARAM_NAME, value)
}

export const getUrlFromLocalStorage = () => {
  return window.localStorage.getItem(URL_PARAM_NAME)
}

export const removeUrlFromLocalStorage = () => {
  window.localStorage.removeItem(URL_PARAM_NAME)
}

export const setTutorialCompletedInLocalStorage = value => {
  window.localStorage.setItem(TUTORIAL_MODAL_VIEWED, 'true')
}

export const getTutorialCompletedFromLocalStorage = value => {
  return window.localStorage.getItem(TUTORIAL_MODAL_VIEWED) || false
}

export const setIECompatabilityWarningViewed = value => {
  window.localStorage.setItem(IE_COMPATABILITY_WARNING_VIEWED, 'true')
}

export const getIECompatabilityWarningViewed = () => {
  return window.localStorage.getItem(IE_COMPATABILITY_WARNING_VIEWED)
}

export const getAppSwitcherModalViewedInLocalStorage = () => {
  return window.localStorage.getItem(APPSWITCHER_MODAL_VIEWED) || false
}

export const setAppSwitcherModalViewedInLocalStorage = value => {
  window.localStorage.setItem(APPSWITCHER_MODAL_VIEWED, 'true')
}
