import { getMomentDatesFromRange, getTimeFromRange, getDateTime } from '../../utils/filters.utils'
import moment from 'moment'
import queryString from 'query-string'
import { TransactionRoute } from 'Routes'

import {
  CreditCards,
  CARD_TYPE_CREDIT,
  CARD_TYPE_EFTPOS_CREDIT,
  CARD_TYPE_EFTPOS,
  CreditCardMapping,
  CARD_TYPE_ALL,
  CARD_TYPE_OTHER
} from './CardTypeSelector/CardType.contants'

const FILTER_STORE_CODE = 'cardAcceptorIdCode'
const FILTER_STORE_NAME = 'name'
const FILTER_DATE_RANGE = 'dateRange'
const FILTER_TIME_RANGE = 'timeRange'
const FILTER_SUFFIX = 'suffix'
const FILTER_TERMINAL_ID = 'terminalId'
const FILTER_STATUS_APPROVED = 'approved'
const FILTER_CARD = 'cardLogos'
const FILTER_TRANSACTION_TYPES = 'tranTypes'
const FILTER_PURCHASE_AMOUNT = 'purchaseAmount'
const FILTER_TRANSACTION_FROM_TIME = 'transactionTimeFrom'
const FILTER_TRANSACTION_TO_TIME = 'transactionTimeTo'
const FILTER_PAGE = 'page'
const FILTER_LIMIT = 'limit'

export const DATE_RANGE_CHANGE = 'dateRangeChange'
export const DATE_CHANGE = 'dateChange'
export const STORE_SELECT = 'storeSelect'
export const SHOW_SELECTOR = 'showSelector'
export const HIDE_SELECTOR = 'hideSelector'
export const TOGGLE_SHOW_CUSTOM_SETTINGS = 'togleCustomSettings'
export const TIME_RANGE_CHANGE = 'timeRangeChange'
export const TIME_CHANGE = 'timeChange'
export const CARD_TYPE_CHANGE = 'cardTypeChange'
export const CARD_CHANGE = 'cardChange'
export const TRANSACTION_CATEGORY_CHANGE = 'transactionCategoryChange'
export const TRANSACTION_TYPE_CHANGE = 'transactionTypeChange'
export const STATUS_CHANGE = 'statusChange'

const UI_TIME_FORMAT = 'hh:mm a'
const today = moment()

export const INITIAL_STATE = {
  store: '',
  dateRange: 'today',
  startDate: today,
  endDate: today,
  showStoreSelector: false,
  highlight: false,
  isSingleStore: false,
  showCustomSettings: false,
  timeRange: 'full',
  startTime: '12:00 am',
  endTime: '11:59 pm',
  hightlightTime: false,
  purchaseAmount: '',
  suffix: '',
  terminalId: '',
  cardType: 'All Cards',
  cards: [],
  transactionCategory: 'All Types',
  transactionTypes: [],
  status: -1,
  page: 1,
  limit: 100
}

export const computeState = ({ props = {}, actions }) => {
  switch (actions) {
    case DATE_CHANGE:
      const { startDate, endDate } = props
      return {
        startDate,
        endDate,
        dateRange: 'custom'
      }
    case SHOW_SELECTOR:
      return {
        showStoreSelector: true
      }
    case HIDE_SELECTOR:
      return {
        showStoreSelector: false
      }
    case STORE_SELECT:
      const { store } = props
      return {
        store,
        showStoreSelector: false
      }
    case DATE_RANGE_CHANGE:
      const {
        dateRange: { value }
      } = props

      const { to, from } = getMomentDatesFromRange(value)
      return { startDate: from, endDate: to, dateRange: value }
    case TOGGLE_SHOW_CUSTOM_SETTINGS:
      const { showCustomSettings } = props
      return { showCustomSettings }
    case TIME_RANGE_CHANGE:
      const { timeRange } = props
      const { to: endTime, from: startTime } = getTimeFromRange(timeRange.value)
      return { startTime, endTime, timeRange: timeRange.value }
    case TIME_CHANGE:
      const { startTime: tcStartTime, endTime: tcEndTime } = props

      return {
        startTime: tcStartTime,
        endTime: tcEndTime,
        timeRange: 'custom'
      }

    case CARD_TYPE_CHANGE:
      const { cardType } = props
      let selectedCards = []

      if (cardType === CARD_TYPE_CREDIT) {
        selectedCards = [...CreditCards]
      } else if (cardType === CARD_TYPE_EFTPOS_CREDIT) {
        selectedCards = [CARD_TYPE_EFTPOS, ...CreditCards]
      } else if (cardType === CARD_TYPE_ALL) {
        selectedCards = []
      } else {
        selectedCards = [cardType]
      }

      return {
        cardType,
        cards: selectedCards
      }
    case CARD_CHANGE:
      const { cards } = props

      return {
        cards,
        cardType: 'Credit'
      }
    case TRANSACTION_CATEGORY_CHANGE:
      const { category } = props
      let selectedTypes = []

      return {
        transactionCategory: category,
        transactionTypes: selectedTypes
      }

    case TRANSACTION_TYPE_CHANGE:
      const { transactionTypes } = props

      return {
        transactionCategory: 'Individual Types',
        transactionTypes
      }

    case STATUS_CHANGE:
      const { status } = props
      return {
        status
      }

    default:
      return {}
  }
}

export const getParamsFromUrl = search => {
  const qParams = queryString.parse(search)
  const {
    cardAcceptorIdCode,
    name,
    dateRange,
    timeRange,
    suffix = '',
    terminalId = '',
    approved,
    cardLogos,
    cardType,
    purchaseAmount = '',
    transTypes,
    transactionTimeFrom,
    transactionTimeTo,
    transactionCategory,
    page
  } = qParams

  let params = { ...INITIAL_STATE }
  if (!name) {
    return params
  }

  params.store = { cardAcceptorIdCode, displayName: name }

  if (terminalId) {
    params.terminalId = terminalId
  }

  if (purchaseAmount) {
    params.purchaseAmount = purchaseAmount
  }

  if (approved > -1) {
    params.status = parseInt(approved)
  }

  if (suffix) {
    params.suffix = suffix
  }

  if (dateRange === 'custom') {
    params.dateRange = dateRange
    params.startDate = moment(transactionTimeFrom)
    params.endDate = moment(transactionTimeTo)
  } else if (dateRange) {
    const dates = getMomentDatesFromRange(dateRange)
    params.startDate = dates.from
    params.endDate = dates.to
    params.dateRange = dateRange
  }

  if (timeRange === 'custom') {
    //TODO calculate custom date
    params.timeRange = timeRange
    params.startTime = moment(transactionTimeFrom).format(UI_TIME_FORMAT)
    params.endTime = moment(transactionTimeTo).format(UI_TIME_FORMAT)
  } else if (timeRange) {
    const time = getTimeFromRange(timeRange)
    params.startTime = time.from
    params.endTime = time.to
    params.timeRange = timeRange
  }

  if (cardType) {
    params.cards = cardLogos && cardLogos.split(',')
    params.cardType = cardType
  }

  if (transactionCategory) {
    if (transTypes) {
      const result = (params.transactionTypes = transTypes
        .toString()
        .split(',')
        .map(value => parseInt(value)))
      params.transactionTypes = result
    }

    params.transactionCategory = transactionCategory
  }

  if (page) {
    params.page = page
  }

  return params
}

const getTransactionTime = (date, time) => {
  const transactionTime = getDateTime(date, time)
  return transactionTime.toISOString()
}

export const storeAdapter = store => {
  if (!store) {
    return
  }
  if (!Array.isArray(store)) {
    const adaptedStore = [{ value: store.cardAcceptorIdCode, label: store.displayName }]
    return adaptedStore
  }
  return store
}

export const constructParams = values => {
  const {
    store,
    dateRange,
    timeRange,
    endDate,
    endTime,
    startDate,
    startTime,
    suffix,
    terminalId,
    status,
    purchaseAmount,
    cards,
    cardType,
    transactionTypes,
    transactionCategory,
    page,
    limit
  } = values

  let storeCode,
    storeName,
    suffixVal,
    cardsVal,
    tranTypesVal,
    statusVal,
    purchaseAmountVal,
    transFromTime,
    transToTime,
    dateRangeVal,
    timeRangeVal,
    terminalIdVal = undefined,
    pageVal,
    limitVal

  if (purchaseAmount) {
    let numberValue = purchaseAmount.replace('$', '').replace(/,/g, '')
    purchaseAmountVal = parseFloat(numberValue)
  }

  const adaptedStore = storeAdapter(store)
  storeCode = adaptedStore.map(store => store.value).join(',')
  storeName = adaptedStore[0].label

  transToTime = getTransactionTime(endDate, endTime)
  transFromTime = getTransactionTime(startDate, startTime)

  if (dateRange !== 'today') {
    dateRangeVal = dateRange
  }

  if (timeRange !== 'full') {
    timeRangeVal = timeRange
  }

  if (suffix) {
    suffixVal = suffix
  }

  if (terminalId) {
    terminalIdVal = terminalId
  }

  if (status > -1) {
    statusVal = status
  }

  if (cards && cards.length > 0) {
    cardsVal = [...cards].join(',')
  }

  if (transactionTypes && transactionTypes.length > 0) {
    tranTypesVal = [...transactionTypes].join(',')
  }

  pageVal = page
  limitVal = limit

  const param = {
    [FILTER_STORE_CODE]: storeCode,
    [FILTER_STORE_NAME]: storeName,
    [FILTER_DATE_RANGE]: dateRangeVal,
    [FILTER_TIME_RANGE]: timeRangeVal,
    [FILTER_SUFFIX]: suffixVal,
    [FILTER_TERMINAL_ID]: terminalIdVal,
    [FILTER_TRANSACTION_FROM_TIME]: transFromTime,
    [FILTER_TRANSACTION_TO_TIME]: transToTime,
    [FILTER_STATUS_APPROVED]: statusVal,
    [FILTER_PURCHASE_AMOUNT]: purchaseAmountVal,
    [FILTER_CARD]: cardsVal,
    [FILTER_TRANSACTION_TYPES]: tranTypesVal,
    [FILTER_PAGE]: pageVal,
    [FILTER_LIMIT]: limitVal,
    cardType, //Just for the UI not passed to Service
    transactionCategory //Just for the UI not passed to Service
  }

  return param
}

export const constructUrl = values => {
  const params = constructParams(values)
  const searchUrl = queryString.stringify(params)
  return searchUrl
}

export const isFilterValid = values => {
  const { store } = values || {}
  const isValuesPresent = store

  if (!isValuesPresent) {
    return false
  }

  return true
}

export const getCardValue = pLabel => {
  if (pLabel === CARD_TYPE_EFTPOS) {
    return CARD_TYPE_EFTPOS.toUpperCase()
  }

  if (pLabel === CARD_TYPE_OTHER) {
    return CARD_TYPE_OTHER.toUpperCase()
  }

  const filtered = CreditCardMapping.filter(({ label }) => label === pLabel).map(result => {
    return result.value
  })

  const filteredValue = filtered ? filtered[0] : undefined

  return filteredValue
}

export const getCreditCardLabel = pValue => {
  if (pValue === CARD_TYPE_EFTPOS.toUpperCase()) {
    return CARD_TYPE_EFTPOS
  }

  if (pValue === CARD_TYPE_OTHER.toUpperCase()) {
    return CARD_TYPE_OTHER
  }

  const filtered = CreditCardMapping.filter(({ value }) => value === pValue).map(result => {
    return result.label
  })

  const filteredLabel = filtered ? filtered[0] : undefined

  return filteredLabel
}

export const generateTransactionPageUrl = (store, range, timeFrom) => {
  const { cardAcceptorIdCode: code, displayName } = store
  const cardAcceptorIdCode = code
  const name = displayName

  const cardType = 'All Cards'
  const dateRange = 'custom'
  const transactionCategory = 'All Types'
  const timeTo =
    range === '1d'
      ? moment(timeFrom)
          .endOf('hour')
          .startOf('minute')
      : moment(timeFrom)
          .endOf('day')
          .startOf('minute')

  const params = {
    cardAcceptorIdCode: cardAcceptorIdCode,
    name,
    cardType,
    dateRange,
    transactionCategory,
    transactionTimeFrom: moment(timeFrom).toISOString(),
    transactionTimeTo: timeTo.toISOString()
  }

  if (range === '1d') {
    params.timeRange = 'custom'
  }

  const searchUrl = queryString.stringify(params)

  return `${TransactionRoute.path}/?${searchUrl}`
}
