import _ from 'lodash'
import { getReturnCustomersAgg, getCardData } from './performance.api'
import { getSalesRevenueAgg } from './allStoresSalesRevenuePerformance.api'

export const getReturnCustomersAggByMerchant = async params => {
  const { current, previous, isCompare, cardAcceptorIdCode } = params
  let [currentReturnCustomers, previousReturnCustomers] = []

  if (isCompare) {
    const result = await Promise.all([
      getReturnCustomersAgg({ ...current, aggregate: false, cardAcceptorIdCode }),
      getReturnCustomersAgg({ ...previous, aggregate: false, cardAcceptorIdCode })
    ])
    currentReturnCustomers = result[0]
    previousReturnCustomers = result[1]
  } else {
    const result = await getReturnCustomersAgg({ ...current, aggregate: false, cardAcceptorIdCode })
    currentReturnCustomers = result
  }

  return {
    current: _.head(currentReturnCustomers),
    previous: _.head(previousReturnCustomers)
  }
}

export const getCardsUsedAggByMerchant = async params => {
  const { current, previous, isCompare, cardAcceptorIdCode } = params
  let [currentReturnCustomers, previousReturnCustomers] = []

  if (isCompare) {
    const result = await Promise.all([
      getCardData({ ...current, cardAcceptorIdCodes: cardAcceptorIdCode }),
      getCardData({ ...previous, cardAcceptorIdCodes: cardAcceptorIdCode })
    ])
    currentReturnCustomers = result[0]
    previousReturnCustomers = result[1]
  } else {
    const result = await getCardData({ ...current, cardAcceptorIdCodes: cardAcceptorIdCode })
    currentReturnCustomers = result
  }

  return {
    current: _.get(currentReturnCustomers, 'all.intervals[0].groups'),
    previous: _.get(previousReturnCustomers, 'all.intervals[0].groups')
  }
}

export const getTransactionAggByMerchant = async params => {
  const { current, previous, isCompare, cardAcceptorIdCode } = params
  let [currentTransAgg, previousTransAgg] = []

  if (isCompare) {
    const result = await Promise.all([
      getSalesRevenueAgg({ ...current, cardAcceptorIdCode, aggregate: false }),
      getSalesRevenueAgg({ ...previous, cardAcceptorIdCode, aggregate: false })
    ])
    currentTransAgg = result[0]
    previousTransAgg = result[1]
  } else {
    const result = await getSalesRevenueAgg({ ...current, cardAcceptorIdCode, aggregate: false })
    currentTransAgg = result
    previousTransAgg = []
  }

  return {
    currentTransactionsAgg: currentTransAgg,
    previousTransactionsAgg: previousTransAgg
  }
}
