import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  radioItem: {
    paddingRight: '3rem'
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: ['0.5rem', 0, 0, '0.5rem']
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  checkBoxes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: `1px solid ${colors.line2}`,
    paddingTop: '1.5rem'
  },
  checkBoxItem: {
    width: '240px',
    padding: [0, '1rem', '1rem', 0],
    [MOBILE_UP()]: {
      padding: [0, '1rem', '0.5rem', 0]
    }
  }
}

export default styles
