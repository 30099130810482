import React from 'react'
import numeral from 'numeral'
import Label from 'components/common/HorizontalBarChart/Label'

const PreviousDataLabel = ({ x, y, width, height, stroke, value, total, index }) => {
  const displayValue = numeral(value / 100).format('0%')

  return (
    <Label
      {...{
        x,
        y,
        width,
        stroke,
        value: displayValue,
        total,
        index,
        align: 'ends',
        mobileFontSize: 12,
        vAlign: 'bottom'
      }}
    />
  )
}

export default PreviousDataLabel
