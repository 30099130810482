import React from 'react'
import PropTypes from 'prop-types'
import FormikInput from 'components/FormikWrappers/FormikInput'

import Text from 'components/Text/Text'
import Divider from 'components/Divider/Divider'
import Button from 'components/Button/Button'
import Container from './SetPasswordContainer'
import VerifyAccountErrorMessage from './VerifyAccountErrorMessage/VerifyAccountErrorMessage'
import { PasswordConditions } from '..'
import withStyles from 'react-jss'
import styles from './SetPassword.styles'
import { LoginLink } from 'components/common'

const SetPasswordForm = props => {
  const { handleSubmit, errors, goToLogin, isSubmitting, touched, classes, title } = props
  const { password: passwordErrors, serverValidation: errorCode } = errors
  const { password: passwordTouched } = touched
  const allowSubmit = !isSubmitting

  return (
    <form onSubmit={handleSubmit} name="SetPasswordForm">
      <div className={classes['container']}>
        <div className={classes['title']}>
          <Text variant="h1">{title}</Text>
          <Divider />
        </div>

        <div className={classes['body']}>
          <Text variant="h5">Set-up your password</Text>

          <p>
            <Text variant="note">
              Great, we successfully validated your account. The last step is to set-up your password.
            </Text>
          </p>
          <FormikInput name="password" label={'Password*'} type="password" autoComplete="new-password" autoFocus>
            <PasswordConditions isError={passwordErrors && passwordTouched} />
          </FormikInput>
          <FormikInput name="confirmPassword" label={'Confirm Password*'} type="password" autoComplete="new-password" />
          {errorCode && <VerifyAccountErrorMessage {...{ goToLogin, errorCode }} />}
          <Button size="full" type="submit" disabled={!allowSubmit}>
            Create Password
          </Button>
        </div>

        <div className={classes['footer']}>
          <p>
            <LoginLink goToLogin={goToLogin} />
          </p>
          <Divider />
        </div>
      </div>
    </form>
  )
}

SetPasswordForm.propTypes = {
  verifyAccount: PropTypes.func.isRequired
}

export default withStyles(styles)(Container(SetPasswordForm))
