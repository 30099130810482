import { connect } from 'react-redux'
import { accountSlice, transactionSlice } from '../../../reduxSlices'
import { bindActionCreators } from 'redux'
import { print, getFileName } from '../../../utils/file.utils'
import _ from 'lodash'
import { constructParams, getParamsFromUrl, isFilterValid } from '../../TransactionFilter/TransactionFilter.helper'
import { getTransactionsCSV } from '../../../api/transaction.api'

export const hasDataToExport = transactions => {
  return !_.isEmpty(transactions)
}

export const printTransaction = async search => {
  const { filter } = getParams(search)
  const fileName = getFileName('Transactions-summary', filter)
  await print(fileName, 'printableTransactions')
}

export const getParams = search => {
  const searchParams = getParamsFromUrl(search)
  const isValid = isFilterValid(searchParams)
  if (!isValid) {
    return
  }
  const params = constructParams(searchParams)
  const { transactionTimeFrom, transactionTimeTo } = params
  const filter = {
    from: transactionTimeFrom,
    to: transactionTimeTo
  }

  return {
    filter,
    params
  }
}

export const downloadTransactionsCsv = async search => {
  const { params } = getParams(search)

  const csv = await getTransactionsCSV(params)

  return csv
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...accountSlice.actions,
      ...transactionSlice.actions
    },
    dispatch
  )
})

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component)
