import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './LoaderUI.styles'
import LoadingIcon from './loading.gif'
import CircularProgress from '@mui/material/CircularProgress'
import { cyan } from 'config/colors'

const Loader = ({ logo, classes }) => {
  return (
    <div>
      <div className={classes['background']} />
      <div className={classes['icon']}>
        {' '}
        <CircularProgress style={{ color: cyan }} size={60} />{' '}
      </div>
    </div>
  )
}

Loader.propTypes = {
  /** Icon to be shown on the loader. Defaults to Paymark icon.*/
  logo: PropTypes.any
}

Loader.defaultProps = {
  logo: <img src={LoadingIcon} alt="loading..." />
}

export default withStyles(styles)(Loader)
