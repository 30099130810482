import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import withStyles from 'react-jss'
import styles from './styles/base.styles'
import 'normalize.css'
import './hotjar_override.css'

const StyledApp = withStyles(styles)(App)

const AppContent = (
  <BrowserRouter>
    <StyledApp />
  </BrowserRouter>
)

ReactDOM.render(AppContent, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
