import Text from 'components/Text/Text'
import objstr from 'obj-str'
import React from 'react'
import withStyles from 'react-jss'
import AllStoresCommentary from './AllStoresCommentary/AllStoresCommentary'
import styles from './BusinessPerformance.styles'
import TopStoresCard from './TopStoresCard/TopStoresCard'
import CardsUsed from './CardsUsed/CardsUsed'
import FilterSection from 'components/FilterSection/FilterSection'
import CustomersCard from 'components/BusinessPerformance/CustomersCard/CustomersCard'
import RevenueCard from 'components/BusinessPerformance/RevenueCard/RevenueCard'
import SalesCard from 'components/BusinessPerformance/SalesCard/SalesCard'
import TopStoresCommentary from './TopStoresCard/TopStoreCommentary'
import useBusinessPerformanceActions from './useBusinessPerformanceActions'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'

const BusinessPerformance = ({ classes }) => {
  const { expandedCard } = useBusinessPerformanceActions()

  const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)

  const isRevenueCardExpanded = expandedCard === 'revenue'
  const isSalesCardExpanded = expandedCard === 'sales'

  const layout = objstr({
    [classes['graphs']]: true,
    [classes['expandedCardsGrid']]: isRevenueCardExpanded,
    [classes['expandedCardsGridSales']]: isSalesCardExpanded
  })

  return (
    <div className={classes['container']}>
      <div className={classes['filterSection']}>
        <FilterSection comparePeriod />
      </div>
      <div className={classes['allStoresSection']}>
        <div className={classes['commentarySection']}>
          <div className={classes['title']}>
            <Text variant="h2">All Stores</Text>
          </div>
          <div className={`tablet`}>
            <AllStoresCommentary />
          </div>
        </div>
        <div className={layout}>
          {!isSalesCardExpanded && (
            <div className={'revenueGraph'}>
              <RevenueCard />
            </div>
          )}
          {!isRevenueCardExpanded && (
            <div className={'salesGraph'}>
              <SalesCard />
            </div>
          )}
          {!isRevenueCardExpanded && !isSalesCardExpanded && (
            <div className={'customersGraph'}>
              <CustomersCard />
            </div>
          )}

          {cardsUsedFeatureFlag && (
            <div className={'cardsGraph'}>
              <CardsUsed />
            </div>
          )}

          <div className={'mobileAllStoresCommentary'}>
            <AllStoresCommentary />
          </div>
        </div>
      </div>
      <div className={classes['topStoresSection']}>
        <div className={classes['commentarySection']}>
          <div className={classes['title']}>
            <Text variant="h2">Top Stores</Text>
          </div>
          <div className={`tablet`}>
            <TopStoresCommentary />
          </div>
        </div>
        <div className={classes['topStoresTable']}>
          <TopStoresCard />
        </div>

        <div className={`${classes['mobileTopStoresCommentary']}`}>
          <TopStoresCommentary />
        </div>
      </div>
    </div>
  )
}

BusinessPerformance.prototype = {}

export default withStyles(styles)(BusinessPerformance)
