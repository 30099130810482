import React from 'react'
import { CommentarySection } from 'components/common'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import styles from './StoreCardGraphLayout.styles'
import StoreCardTotalTitle from './StoreCardTotalTitle'
import StoreCardRankSection from './StoreCardRankSection'

const StoreCardGraphLayout = ({
  classes,
  Chart,
  totalColor,
  total,
  previousPeriodTotal,
  commentary = '',
  averageSalesText = '',
  storeName,
  rankText,
  percentageChange,
  isCompareStore,
  isLoading
}) => {
  return (
    <div className={classes['section']}>
      <div className={classes['commentary']} data-hj-suppress>
        {isCompareStore && <div className={classes['name']}>{storeName && <Text variant="h3">{storeName}</Text>}</div>}

        <StoreCardTotalTitle
          totalColor={totalColor}
          total={total}
          previousPeriodTotal={previousPeriodTotal}
          percentageChange={percentageChange}
          isLoading={isLoading}
        />

        {!isCompareStore && (isLoading || commentary) && (
          <div className={classes['text']}>
            <CommentarySection {...{ isLoading }}>{commentary}</CommentarySection>
            <CommentarySection {...{ isLoading }}>{averageSalesText}</CommentarySection>
          </div>
        )}

        <StoreCardRankSection isLoading={isLoading} isCompareStore={isCompareStore} rankText={rankText} />
      </div>

      <div className={classes['graph']} data-hj-suppress>
        {Chart}
      </div>

      {isCompareStore && (
        <div className={classes['nameMobile']} data-hj-suppress>
          {storeName && <Text variant="h3">{storeName}</Text>}
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(StoreCardGraphLayout)
