import React from 'react'
import styles from './PermissionsInfo.styles'
import withStyles from 'react-jss'
import Avatar from 'components/common/Avatar'
import Text from 'components/Text/Text'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER, ROLE_VIEWER } from 'constants/general.constants'

const PermissionsInfo = ({ classes }) => {
  const roles = [
    {
      name: ROLE_OWNER,
      title: 'Owner',
      description:
        'Full access to everything. Can add/remove stores and viewers. Can grant permissions to view stores. Can add other owners.'
    },
    {
      name: ROLE_GLOBAL_VIEWER,
      title: 'Global Viewer',
      description: 'Can see all stores for an organisation.'
    },
    {
      name: ROLE_VIEWER,
      title: 'Viewer',
      description: 'Can see one or more stores depending on the permissions set up by the Owner.'
    }
  ]

  return (
    <div>
      <Text variant="body">
        In Insights, users have three roles depending on the permissions that have been set up.
      </Text>
      <div className={classes['roleList']}>
        {roles.map((role, index) => {
          const { name, title, description } = role

          return (
            <div key={index} className={classes['roleItem']}>
              <div className={classes['roleIcon']}>
                <Avatar role={name} width={'1rem'} height={'1rem'} />
              </div>
              <div className={classes['roleInfo']}>
                <Text variant="h5">{title}</Text>
                <Text variant="body">{description}</Text>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(PermissionsInfo)
