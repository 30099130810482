import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { accountSlice, dashboardSlice } from 'reduxSlices'
import { getMomentDate } from 'utils/filters.utils'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_SWITCH_STORE_VIEW } from 'constants/mixpanel.constants'
import { getFeatureFlag, FEATURE_FLAG_MONTH_COMPARISON } from 'utils/config.utils'
import _ from 'lodash'

//Redux state and connector
const filterState = state => {
  const merchants = accountSlice.selectors.getMerchantAccess(state)
  const isSingleMerchant = merchants ? merchants.length === 1 : false

  return {
    filter: dashboardSlice.selectors.getFilter(state),
    merchants,
    isSingleMerchant
  }
}

const mapStateToProps = state => ({
  ...filterState(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...dashboardSlice.actions
    },
    dispatch
  )
})

//Business Logic and action handlers for filter
export const getFilterDates = (period, date) => {
  let startDate, previousStartPeriod, previousEndPeriod, endDate, rangeInterval
  const DAY_INTERVAL = 'day'
  const HOUR_INTERVAL = 'hour'

  const baseDate = moment(date)

  switch (period) {
    case '1w':
      startDate = moment(baseDate).startOf('isoWeek')
      previousStartPeriod = moment(baseDate)
        .subtract(1, 'weeks')
        .startOf('isoWeek')
      endDate = moment(baseDate).endOf('isoWeek')
      previousEndPeriod = moment(baseDate)
        .subtract(1, 'week')
        .endOf('isoWeek')
      rangeInterval = DAY_INTERVAL
      break
    case '1m':
      startDate = moment(baseDate).startOf('month')
      endDate = moment(baseDate).endOf('month')
      previousStartPeriod = moment(baseDate)
        .subtract(1, 'months')
        .startOf('month')
      previousEndPeriod = moment(baseDate)
        .subtract(1, 'months')
        .endOf('month')
      rangeInterval = DAY_INTERVAL
      break
    case '3m':
      startDate = moment(baseDate)
        .subtract(2, 'months')
        .startOf('month')
      endDate = moment(baseDate).endOf('month')
      previousStartPeriod = moment(baseDate)
        .subtract(5, 'months')
        .startOf('month')
      previousEndPeriod = moment(baseDate)
        .subtract(3, 'months')
        .endOf('month')
      rangeInterval = DAY_INTERVAL
      break
    default:
      startDate = moment(baseDate).startOf('day')
      endDate = moment(baseDate).endOf('day')
      previousStartPeriod = moment(baseDate)
        .subtract(1, 'weeks')
        .startOf('day')
      previousEndPeriod = moment(baseDate)
        .subtract(1, 'weeks')
        .endOf('day')
      rangeInterval = HOUR_INTERVAL
      break
  }

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    date: moment(date)
      .endOf('day')
      .toISOString(),
    previousStartPeriod: previousStartPeriod.toISOString(),
    previousEndPeriod: previousEndPeriod.toISOString(),
    interval: rangeInterval
  }
}

const monthComparison = getFeatureFlag(FEATURE_FLAG_MONTH_COMPARISON)

export const modes = [
  {
    value: '1d',
    label: 'Day'
  },
  {
    value: '1w',
    label: 'Week'
  },
  ...(monthComparison
    ? [
        {
          value: '1m',
          label: 'Month'
        }
      ]
    : [])

  // {
  //   value: '3m',
  //   label: '3 M'
  // }
]

const filterMerchants = (merchants, selected) => {
  if (!merchants) return merchants
  return merchants.filter(({ cardAcceptorIdCode }) => {
    if (selected) {
      return cardAcceptorIdCode !== selected.cardAcceptorIdCode
    }
    return true
  })
}

const withRedux = Component => connect(mapStateToProps, mapDispatchToProps)(Component)

export default function withContainer(WrappedComponent) {
  return withRedux(
    // Filter Container
    ({ filter, actions, isLoading, merchants, comparePeriod = false, compareStore = false, isSingleMerchant }) => {
      const { date, range, isComparePeriod, isCompareStore, store, comparedStore } = filter
      const [previousSelectedStore, setPreviousSelectedStore] = useState(store)
      const [previousComparedStore, setPreviousComparedStore] = useState(null)
      const datePickerDate = getMomentDate(date)
      compareStore = compareStore && !isSingleMerchant
      const [isStoreSelectorActive, setIsStoreSelectorActive] = useState(false)

      useEffect(() => {
        setFilter({
          isCompareStore: false,
          comparedStore: null
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const setDate = date => {
        if (date) {
          const filter = getFilterDates(range, date)
          setFilter({ ...filter })
        }
      }

      const setRange = range => {
        const filter = getFilterDates(range, date)

        setFilter({
          range,
          ...filter
        })
      }

      const onSetComparePeriod = isComparePeriod => {
        setFilter({
          isComparePeriod,
          isCompareStore: isComparePeriod ? false : isCompareStore,
          comparedStore: null
        })
      }

      const onSetCompareStore = isCompareStore => {
        setFilter({
          isCompareStore,
          isComparePeriod: isCompareStore ? false : isComparePeriod,
          comparedStore: null
        })
        isCompareStore && handleShowSelector()
      }

      const handleShowSelector = () => {
        setIsStoreSelectorActive(true)
      }

      const prepareMixpanelParams = (previousStore, newStore) => {
        const mixpanelData = {}
        if (previousStore) mixpanelData['CurrentUserMerchantID'] = previousStore.cardAcceptorIdCode
        if (!_.isEmpty(newStore)) {
          mixpanelData['NewUserMerchantID'] = newStore.cardAcceptorIdCode
        } else {
          // don't send to mixpanel if there is no new id
          return {}
        }
        return mixpanelData
      }

      const getDisplayedStore = previousStore => {
        const { store: displayedStore } = filter
        return previousStore ? previousStore : displayedStore
      }

      const handleSelectStore = store => {
        const key = isCompareStore ? 'comparedStore' : 'store'
        if (previousSelectedStore !== store && !isCompareStore) {
          setPreviousSelectedStore(store)
          sendSelectStoresMixpanelData(prepareMixpanelParams(getDisplayedStore(previousSelectedStore), store))
        }
        if (previousComparedStore !== store && isCompareStore) {
          setPreviousComparedStore(store)
          sendSelectStoresMixpanelData(prepareMixpanelParams(getDisplayedStore(previousComparedStore), store))
        }

        if (store === undefined) {
          setFilter({
            storeTouched: true,
            isCompareStore: false
          })
          setIsStoreSelectorActive(false)
          return
        }
        setFilter({
          [key]: store,
          storeTouched: true
        })
        setIsStoreSelectorActive(false)
      }

      const sendSelectStoresMixpanelData = params => {
        if (_.isEmpty(params)) return
        const topStoresMixpaneldata = mixpanelData.switchStoreViewData(params)
        const { Mixpanel: mixpanel } = apis
        mixpanel.track(EVENT_SWITCH_STORE_VIEW, topStoresMixpaneldata)
      }

      const setFilter = filter => {
        actions.setFilter(filter)
      }

      const helpText = isCompareStore ? 'Select another store' : 'search stores'
      const placeholder = isCompareStore ? 'Find and select another store' : 'search stores by name'

      merchants = filterMerchants(merchants, store)

      const searchStoreProps = {
        selectedStore: store,
        allStores: merchants,
        searchKey: 'displayName',
        uniqueKey: 'cardAcceptorIdCode',
        isActive: isStoreSelectorActive,
        activateSearch: handleShowSelector,
        onSelect: handleSelectStore,
        helpText,
        placeholder,
        shortPlaceholder: helpText
      }

      // Logic for Date picker
      let changeInterval = 'days'
      if (range === '1w') {
        changeInterval = 'weeks'
      } else if (range === '1m') {
        changeInterval = 'months'
      }

      return (
        <WrappedComponent
          {...{
            isStoreSelectorActive,
            isLoading,
            datePickerDate,
            setDate,
            setRange,
            range,
            compareStore,
            searchStoreProps,
            comparePeriod,
            onSetComparePeriod,
            isComparePeriod,
            onSetCompareStore,
            isCompareStore,
            changeInterval,
            comparedStore
          }}
        />
      )
    }
  )
}
