import React from 'react'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import _ from 'lodash'
import styles from './SearchInput.styles'
import withStyles from 'react-jss'

const SearchInput = ({ classes, onChange = _.noop, text, placeholder }) => {
  return (
    <div className={classes['searchBox']}>
      <Icon className={classes['searchIcon']} name="search" color={colors.icon} />
      <input placeholder={placeholder} className={classes['input']} type="text" value={text} onChange={onChange} />
    </div>
  )
}

export default withStyles(styles)(SearchInput)
