import * as colors from 'config/colors'
import * as variables from 'config/jss-vars'
const { standardFontFamily, weightRegular } = variables

const styles = {
  searchIcon: {
    position: 'absolute',
    padding: '0.5rem'
  },
  input: {
    width: '100%',
    color: colors.greyDark,
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    fontSize: '1rem',
    border: `1px solid ${colors.line1}`,
    height: '2.5rem',
    backgroundColor: colors.background1,
    borderRadius: '3px',
    padding: '0.5rem 0.5rem 0.5rem 2.5rem',
    '::placeholder': {
      color: colors.textPlaceholder
    },
    '&:hover': {
      border: `1px solid ${colors.cyan}`
    },
    '&:active': {
      border: `1px solid ${colors.teal}`
    },
    '&:focus': {
      outline: 'none',
      border: `1px solid ${colors.cyan}`
    }
  }
}

export default styles
