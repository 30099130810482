import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import container from '../PrivateRoute/routeContainer'
import { setPublicToken } from 'utils/token.utils'
import { isAccessTokenPresent } from 'utils/auth.utils'
import { DashboardRoute } from 'Routes'

class PublicRoute extends Component {
  UNSAFE_componentWillMount() {
    setPublicToken()
  }

  render() {
    const { component: Component, banner, actions } = this.props
    const userData = { banner }
    const userActions = actions

    // Avoid opening the un authorized pages when they are logged in
    if (isAccessTokenPresent()) {
      return <Redirect to={DashboardRoute.path} />
    }
    return <Route exact render={routerProps => <Component {...{ userData, userActions, routerProps }} />} />
  }
}

export default container(PublicRoute)
