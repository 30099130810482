import React from 'react'
import withStyles from 'react-jss'
import styles from './StorePerformanceHeader.styles'
import Text from 'components/Text/Text'
import { useSelector } from 'react-redux'
import { dashboardSlice } from 'reduxSlices'
import { SettlementDate } from 'components/common'
import moment from 'moment'
import { UI_DATE_FORMAT } from 'constants/general.constants'

const StorePerformanceHeader = ({ classes }) => {
  const { selectors: dashboardSelectors } = dashboardSlice
  const filter = useSelector(dashboardSelectors.getFilter)
  const { store, comparedStore } = filter

  const { startDate, endDate } = filter
  const from = moment(startDate).format(UI_DATE_FORMAT)
  const to = moment(endDate).format(UI_DATE_FORMAT)

  return (
    <div className={classes['headerContainer']}>
      {store && (
        <div className={classes['storeName']} data-hj-suppress>
          <Text variant="h2"> {store.displayName}</Text>
          {comparedStore && <Text variant="h2">vs</Text>}
          {comparedStore && <Text variant="h2">{comparedStore.displayName}</Text>}
        </div>
      )}

      <div className={classes['selectedDates']}>
        <SettlementDate {...{ from, to }} />
      </div>
    </div>
  )
}

export default withStyles(styles)(StorePerformanceHeader)
