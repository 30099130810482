import { DashboardCard } from 'components/common'
import React from 'react'
import withStyles from 'react-jss'
import StoresTable from '../StoresTable/StoresTable'
import styles from './TopStoresCard.styles'
import TopStoresModal from './TopStoresModal/TopStoresModal'
import useTopStores from 'reduxSlices/dashboardSlice/hooks/useTopStores'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_SWITCH_STORE_VIEW } from 'constants/mixpanel.constants'

const TopStoresCard = ({ classes }) => {
  const { stores, isCompare, isLoading, isError, selectStoreAction, topStores, isShowAllStores } = useTopStores()

  const onSelect = store => {
    sendTopStoresMixpanelData({ NewUserMerchantID: store.cardAcceptorIdCode })

    window.scrollTo({
      top: 0
    })
    selectStoreAction(store)
  }

  const sendTopStoresMixpanelData = params => {
    const topStoresMixpaneldata = mixpanelData.switchStoreViewData(params)
    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_SWITCH_STORE_VIEW, topStoresMixpaneldata)
  }

  return (
    <DashboardCard
      {...{
        isHeaderVisible: false
      }}
    >
      <StoresTable {...{ stores: topStores, isCompare, onSelect, isLoading, isError }} />

      {isShowAllStores && (
        <div className={classes['callToAction']}>
          <TopStoresModal {...{ stores: stores, isCompare, onSelect }} />
        </div>
      )}
    </DashboardCard>
  )
}

export default withStyles(styles)(TopStoresCard)
