import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import objstr from 'obj-str'
import jss from './Card.styles'

const Card = ({ classes, children, highlight, padding }) => {
  const className = objstr({
    [classes['card']]: true,
    [classes['highlight']]: highlight,
    [classes['no-highlight']]: !highlight,
    [classes['no-padding']]: !padding
  })

  return <div className={className}>{children}</div>
}

Card.propTypes = {
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    card: PropTypes.object,
    'no-highlight': PropTypes.object,
    highlight: PropTypes.object,
    'no-padding': PropTypes.object
  }),
  children: PropTypes.element.isRequired,
  highlight: PropTypes.bool,
  padding: PropTypes.bool
}

Card.defaultProps = {
  padding: true,
  highlight: false,
  override: {}
}

export default withStyles(jss)(Card)
