import React from 'react'
import { getData } from '../Summary.helper'
import PropTypes from 'prop-types'
import { getCurrency } from 'utils/number.utils'

const CardTypeRow = ({ index, length, item, typeField, classes, totalTransactionAmount, uniqueKey }) => {
  return (
    <tr key={uniqueKey} data-hj-suppress>
      {index === 0 && (
        <td rowSpan={length} className={classes['colCard']}>
          {typeField}
        </td>
      )}
      <td className={classes['colType']}>{getData(item, 'tranType')}</td>
      <td className={classes['colCount']}>{getData(item, 'count')}</td>
      <td className={classes['colPurchase']}>{getData(item, 'totalFinAmount')}</td>
      <td className={classes['colCashout']}>{getData(item, 'totalAdditionalAmt')}</td>
      {index === 0 && (
        <td className={classes['colTotal']} rowSpan={length}>
          {getCurrency(totalTransactionAmount)}{' '}
        </td>
      )}
    </tr>
  )
}

CardTypeRow.propTypes = {
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  typeField: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  totalTransactionAmount: PropTypes.number.isRequired,
  uniqueKey: PropTypes.string.isRequired
}

export default CardTypeRow
