import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo/Logo'
import withStyles from 'react-jss'
import jss from './PublicHeader.styles.js'

const PublicHeader = ({ logo, mobileLogo, headerLinks, classes, onLogoClick }) => {
  return (
    <header className={classes['header']}>
      <div className={classes['logo-desktop']} onClick={onLogoClick}>
        {React.cloneElement(logo, { ...{ height: '42px' } })}
      </div>
      <div className={classes['logo-mobile']} onClick={onLogoClick}>
        {React.cloneElement(mobileLogo, { ...{ width: '35px' } })}
      </div>
      {headerLinks && <div className={classes['nav']}> {headerLinks}</div>}
    </header>
  )
}

PublicHeader.propTypes = {
  /**
   * Desktop Logo. Defaults to Paymark Logo. Pass in you app specific logo here.
   */
  logo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),
  mobileLogo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),
  /**
   * Should be a Header Links component.
   */
  headerLinks: PropTypes.element,
  onLogoClick: PropTypes.func,
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    header: PropTypes.object,
    'logo-desktop': PropTypes.object,
    'logo-mobile': PropTypes.object,
    nav: PropTypes.object
  })
}

PublicHeader.defaultProps = {
  logo: <Logo name="paymark" />,
  mobileLogo: <Logo name="paymark-mobile" />,
  override: {}
}

export default withStyles(jss)(PublicHeader)
