import axios from './axiosDefaults'
import handleError from 'utils/error.utils'

const SIGN_UP_PATH = 'insights/signup/'
const VERIFY_ACCOUNT_PATH = `${SIGN_UP_PATH}verify`
const VERIFY_INVITE_PATH = 'insights/user/verify'

export const signUp = async data => {
  const { firstName, lastName, email, merchantId: cardAcceptorIdCode } = data
  const postData = { firstName, lastName, email, cardAcceptorIdCode }

  try {
    return await axios.post(SIGN_UP_PATH, postData)
  } catch (error) {
    handleError(error)
  }
}

export const verifyAccount = async data => {
  const { password, user: userId, code: otpCode } = data
  const postData = { password, userId, otpCode }

  try {
    return await axios.post(VERIFY_ACCOUNT_PATH, postData)
  } catch (error) {
    handleError(error)
  }
}

export const verifyInvite = async data => {
  const { password, user: userId, code: otpCode } = data
  const postData = { password, userId, otpCode }

  try {
    return await axios.post(VERIFY_INVITE_PATH, postData)
  } catch (error) {
    handleError(error)
  }
}
