import React from 'react'
import Select from 'components/Select/Select'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import ContentLoader from 'react-content-loader'
import styles from './ExportDropDown.styles'

const Loader = () => {
  return (
    <ContentLoader height={40} width={200} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="2" rx="5" ry="5" width="200" height="40" />
    </ContentLoader>
  )
}

const icon = <Icon name="export" width={'1em'} height={'1em'} color={colors.icon} />

const DEFAULT_SELECTED_VALUE = { value: 'export', label: 'Export' }

const ExportDropDown = ({ classes, isLoading, isEnabled, options, onSelect }) => {
  return (
    <div className={classes['exportContainer']}>
      <div id="myMm" style={{ height: '1mm' }} />
      {isLoading && <Loader />}
      {isEnabled && (
        <Select
          options={options}
          onChange={onSelect}
          value={DEFAULT_SELECTED_VALUE}
          icon={icon}
          isDisabled={!isEnabled}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(ExportDropDown)
