import qs from 'qs'
import LoginHelpers from './loginHelpers'

const spaLogin = (function() {
  /**
   * Environment specific settings
   */

  const PATH_USER_DETAIL = 'identity/user/me'
  const PATH_USER_AUTHORIZATION = 'identity/authorisation'
  const PATH_USER_LOGOUT = 'bearer/revoke'

  let APP_CONFIG = {}

  const fetchAsync = async ({ url, params }) => {
    const response = await fetch(url, params)
    if (!response.ok) {
      throw response
    }

    let data = await response.json()
    return data
  }

  const fetchUserDetail = async () => {
    const url = `${getConfig().apiUrl}/${PATH_USER_DETAIL}`

    const headerParams = { accessToken: LoginHelpers.getToken(LoginHelpers.StorageType.PERMANENT) }
    const headers = LoginHelpers.getHeaders(headerParams)

    const params = { headers }

    try {
      const data = await fetchAsync({ url, params })
      return data
    } catch (error) {
      if (error.status !== 200) {
        logout()
      }
    }
  }

  const randomString = length => {
    var text = ''
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  const getHashParams = () => qs.parse(window.location.hash.replace('#', ''))

  const redirectToLogin = ({ fromLogout }) => {
    const { clientId, redirectUrl, apiUrl } = APP_CONFIG

    if (!clientId || !redirectUrl) {
      throw new Error('Required [clientId, redirectUrl] params not found app config')
    }

    const state = randomString(6)

    const queryString = qs.stringify({
      response_type: 'token',
      client_id: clientId,
      redirect_uri: redirectUrl,
      state: state,
      action: fromLogout ? 'logout' : undefined
    })

    const url = `${apiUrl}/${PATH_USER_AUTHORIZATION}/?${queryString}`

    window.localStorage.setItem('state', state)
    window.location.replace(url)
  }

  const saveLogin = (params, successCallback) => {
    window.history.pushState({}, '', window.location.href.split('#')[0])
    window.localStorage.setItem('auth', JSON.stringify(params))
    confirmToken(successCallback)
  }

  const confirmToken = async successCallback => {
    const data = await fetchUserDetail()

    if (successCallback) {
      successCallback(data)
    }
  }

  const handleLogoutUi = handlePreLogoutTasks => {
    handlePreLogoutTasks()
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('state')
    document.getElementById('root').innerHTML = ''
    const param = { fromLogout: true }
    redirectToLogin(param)
  }

  const startLoginCheck = successCallback => {
    const { apiUseMocks } = APP_CONFIG

    if (apiUseMocks) {
      confirmToken(successCallback)
      return
    }

    const loginParams = getHashParams()

    if (loginParams.access_token && window.localStorage.getItem('state') === loginParams.state) {
      // If we have an access token in the URL, then we were redirected here from login. Save the login details.
      saveLogin(loginParams, successCallback)
    } else if (window.localStorage.getItem('auth') && JSON.parse(window.localStorage.getItem('auth')).access_token) {
      // If we have a token in localStorage. Confirm token is still authorised.
      confirmToken(successCallback)
    } else {
      // No tokens found. Redirect them to the login page
      const param = { fromLogout: false }
      redirectToLogin(param)
    }
  }

  const defaultPreLogoutTasksHandler = () => {
    // placeholder
  }

  const logout = async (handlePreLogoutTasks = defaultPreLogoutTasksHandler) => {
    const appConfig = getConfig()
    const { apiUrl, consumerKey, consumerSecret } = appConfig
    const url = `${apiUrl}/${PATH_USER_LOGOUT}`
    const headers = LoginHelpers.getBearerHeaders(consumerKey, consumerSecret)
    const token = LoginHelpers.getToken(LoginHelpers.StorageType.PERMANENT)

    const params = {
      headers,
      method: 'POST',
      body: `token=${token}&token_type_hint=access_token`
    }

    try {
      const data = await fetchAsync({ url, params })
      handleLogoutUi(handlePreLogoutTasks)
      return data
    } catch (error) {
      handleLogoutUi(handlePreLogoutTasks)
    }
  }

  const getConfig = () => {
    return APP_CONFIG
  }

  const validateParams = params => {
    const { apiUrl, consumerSecret } = params
    if (!apiUrl || !consumerSecret) {
      throw new Error('Required [apiUrl, consumerSecret] params not found')
    }
  }

  const setConfig = config => {
    // Check if  apiUrl is present or throw error
    validateParams(config)
    APP_CONFIG = config
  }

  return {
    redirectToLogin,
    startLoginCheck,
    setConfig,
    logout
  }
})()

export default spaLogin
