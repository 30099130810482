import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { isPasswordValid } from '../../utils/password.utils'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .test('passwords', 'Enter a valid password.', function(value) {
      return isPasswordValid(value)
    }),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .test('passwords-match', 'Passwords entered do not match. Please try again.', function(value) {
      return this.parent.password === value
    })
    .test(
      'passwords-match',
      'Sorry, invalid Password. Please ensure it conforms to the rules specified above.',
      function(value) {
        return isPasswordValid(value)
      }
    )
})

const handleSubmit = async (values, params) => {
  const { setSubmitting, setErrors } = params
  const { props } = params
  const { onSuccess, verifyAccount, urlParams } = props
  try {
    const postValues = { ...values, ...urlParams }
    await verifyAccount(postValues)
    setSubmitting(false)
    onSuccess()
  } catch (error) {
    const { error: errorCode } = error
    setSubmitting(false)
    setErrors({
      serverValidation: errorCode
    })
  }
}

const mapPropsToValues = () => {
  return { password: '', confirmPassword: '' }
}

const formikObject = {
  displayName: 'VerifyAccountForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
