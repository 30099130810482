import React from 'react'
import PropTypes from 'prop-types'
import jss from './Banner.styles'
import withStyles from 'react-jss'
import Icon from '../Icon/Icon'
import { white } from 'config/colors'
import Text from '../Text/Text'

const Banner = props => {
  const { classes, message, isVisible, onClose } = props

  if (!isVisible) {
    return null
  }

  return (
    <div className={classes['box']}>
      <div className={classes['content']}>
        <Text variant="body" color={white}>
          {message}
        </Text>
      </div>
      <div className={classes['icon']} onClick={onClose}>
        <Icon name="clear" color={white} width={'1.5rem'} height={'1.5rem'} />
      </div>
    </div>
  )
}

Banner.prototype = {
  message: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func
}

Banner.defaultProps = {
  isVisible: false
}

export default withStyles(jss)(Banner)
