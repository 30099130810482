import React, { useState } from 'react'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import styles from './TopStoresModal.styles'
import { print } from 'utils/file.utils'
import { storePrintStyles } from 'styles/print.styles'
import { isDesktop } from 'utils/common.utils'
import PrintableTopStores from 'components/BusinessPerformance/TopStoresCard/PrintableTopStores'
import StoresTable from 'components/BusinessPerformance/StoresTable/StoresTable'

const exportIcon = <Icon name="export" height={'1em'} width={'1em'} color={colors.icon} />

const printStores = async () => {
  await print('Top-stores', 'printableTopStores', storePrintStyles)
}

const ModalContent = ({ closeModal, actions, classes, stores, isCompare, onSelect }) => {
  const [renderPrint, setRenderPrint] = useState(false)

  const enablePrint = isRender => {
    setRenderPrint(isRender)
  }

  const onClick = async () => {
    try {
      await enablePrint(true)
      await printStores()
    } finally {
      await enablePrint(false)
    }
  }

  return (
    <>
      {renderPrint && <PrintableTopStores {...{ classes: {}, stores, isCompare }} />}
      <div className={classes['modalContent']}>
        <div className={classes['header']}>
          <div className={classes['title']}>Top Stores</div>

          {isDesktop && (
            <div className={classes['exportButton']} onClick={onClick}>
              <div className={classes['exportIcon']}>{exportIcon}</div>
              <Text variant="body" color={colors.teal} className={classes['exportText']}>
                Export
              </Text>
            </div>
          )}
        </div>
        <div>
          <StoresTable
            {...{
              stores: stores,
              isCompare,
              onSelect,
              isLoading: false,
              hasPagination: true
            }}
          />
        </div>
      </div>
    </>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

const TopStoresModal = ({ stores, isCompare, onSelect }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const toggleModal = isModalOpen => {
    setModalOpen(isModalOpen)
  }

  const openModal = () => {
    toggleModal(true)
  }

  const closeModal = () => {
    toggleModal(false)
  }

  return (
    <>
      <Button onClick={openModal} variant="secondary">
        View All Stores
      </Button>

      <Modal isOpen={isModalOpen} onClose={closeModal} size={'large'}>
        <StyledModalContent closeModal={closeModal} stores={stores} isCompare={isCompare} onSelect={onSelect} />
      </Modal>
    </>
  )
}

export default TopStoresModal
