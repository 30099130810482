import React from 'react'
import PropTypes from 'prop-types'
import objstr from 'obj-str'
import withStyles from 'react-jss'
import styles, { buttonSize } from './Paginator.styles'
import Icon from '../Icon/Icon'

const Paginator = ({ classes, onNextClick, onPreviousClick, currentPage, totalPages }) => {
  const isFirstPage = () => {
    return 1 === currentPage
  }

  const isLastPage = () => {
    return totalPages === currentPage
  }

  const prevClassName = objstr({
    [classes['prevButton']]: true,
    [classes['disabled']]: isFirstPage()
  })

  const nextBtnClassName = objstr({
    [classes['nextButton']]: true,
    [classes['disabled']]: isLastPage()
  })

  const iconProps = {
    width: buttonSize,
    height: buttonSize
  }

  const onPreviousKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (isFirstPage()) {
        return
      }
      onPreviousClick()
    }
  }

  const onNextKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (isLastPage()) {
        return
      }
      onNextClick()
    }
  }

  return (
    <div className={classes['paginatorContainer']}>
      <div
        className={prevClassName}
        onClick={() => {
          if (isFirstPage()) {
            return
          }
          onPreviousClick()
        }}
        onKeyDown={onPreviousKeyDown}
        data-testid="previousBtn"
        tabIndex={0}
      >
        <Icon name="arrowLeft" {...iconProps} />
      </div>
      <div className={classes['indicator']}>
        <span>
          {currentPage} of {totalPages}
        </span>
      </div>
      <div
        className={nextBtnClassName}
        onClick={() => {
          if (isLastPage()) {
            return
          }
          onNextClick()
        }}
        onKeyDown={onNextKeyDown}
        data-testid="nextBtn"
        tabIndex={0}
      >
        <Icon name="arrowRight" {...iconProps} />
      </div>
    </div>
  )
}

Paginator.propTypes = {
  override: PropTypes.shape({
    paginatorContainer: PropTypes.object,
    prevButton: PropTypes.object,
    nextButton: PropTypes.object,
    indicator: PropTypes.object,
    disabled: PropTypes.object
  }),

  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number
}

Paginator.defaultProps = {
  override: {}
}

export default withStyles(styles)(Paginator)
