import { connect } from 'react-redux'
import { accountSlice, transactionSlice } from '../../reduxSlices'
import { bindActionCreators } from 'redux'

const transactionFilterstate = state => ({
  transactions: transactionSlice.selectors.getTransactions(state),
  totalResults: transactionSlice.selectors.getTotalResults(state),
  totalPages: transactionSlice.selectors.getTotalPages(state),
  page: transactionSlice.selectors.getPage(state),
  isLoading: transactionSlice.selectors.isLoading(state),
  isShowResult: transactionSlice.selectors.isShowResult(state),
  filter: transactionSlice.selectors.getFilter(state),
  is504: transactionSlice.selectors.is504(state),
  merchants: accountSlice.selectors.getMerchantAccess(state),
  selectedTransaction: transactionSlice.selectors.getSelectedTransaction(state)
})

const mapStateToProps = state => ({
  ...transactionFilterstate(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...transactionSlice.actions
    },
    dispatch
  )
})

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component)
