import * as Yup from 'yup'
import { NAME_REGEX } from 'utils/validation.utils'
import { getUpdatePayload } from 'components/InviteUserForm/InviteUserForm.helper'
import _ from 'lodash'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { ROLE_VIEWER } from 'constants/general.constants'
import apis from 'api'
import { EVENT_INVITE_USER, EVENT_INVITE_USER_FAILURE } from 'constants/mixpanel.constants'

import {
  ERROR_USER_ACTIVE,
  ERROR_USER_WAITING_VERIFICATION,
  ERROR_USER_EMAIL_EXPIRED,
  ERROR_UNKNOWN_STATE,
  ERROR_NO_ACCESS,
  ERROR_SERVER
} from 'constants/errorCodes.constants'
import mixpanelData from 'utils/mixpanelObjects.utils'

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.')
    .matches(NAME_REGEX, 'Enter a valid first name.'),
  lastName: Yup.string()
    .required('Last name is required.')
    .matches(NAME_REGEX, 'Enter a valid last name.'),
  email: Yup.string()
    .email('Enter a valid email address.')
    .required('Email is required.'),
  stores: Yup.array().test('store-selected', 'At least one store should be selected when inviting a Viewer.', function(
    value
  ) {
    const { role } = this.parent
    if (ROLE_VIEWER === role) {
      return value.length > 0
    }

    return true
  })
})

const handleSubmit = async (values, params) => {
  const { Mixpanel: mixpanel } = apis
  const { setFieldValue } = params
  const userPayload = getUpdatePayload(values)

  try {
    const canInvite = await isValidInvitedUserState(userPayload, params)
    if (canInvite) {
      await apis.inviteUser(userPayload)
      setFieldValue('updated', true)
    }
  } catch (error) {
    let serverError = ERROR_SERVER
    if (error.error === 'viewer_belongs_to_different_org') {
      serverError = ERROR_NO_ACCESS
      mixpanel.track(EVENT_INVITE_USER_FAILURE, mixpanelData.inviteUserFailureData(userPayload, ERROR_NO_ACCESS))
    }
    setErrorFlags(params, { error: serverError })
  }
}

const isValidInvitedUserState = async (userPayload, params) => {
  const { Mixpanel: mixpanel } = apis
  try {
    const invitedUser = await apis.getUsersByEmail(userPayload.email)
    if (!_.isEmpty(invitedUser)) {
      // user exists, we need to tell the inviter
      const { state } = invitedUser[0]
      let errorCode = { error: '' }
      switch (state) {
        case 'Active':
          errorCode.error = ERROR_USER_ACTIVE
          mixpanel.track(EVENT_INVITE_USER, mixpanelData.inviteUserData(userPayload))
          mixpanel.track(EVENT_INVITE_USER_FAILURE, mixpanelData.inviteUserFailureData(userPayload, ERROR_USER_ACTIVE))
          break
        case 'Waiting email verification':
          errorCode.error = ERROR_USER_WAITING_VERIFICATION
          break
        case 'Email expired':
          errorCode.error = ERROR_USER_EMAIL_EXPIRED
          break
        default:
          errorCode.error = ERROR_UNKNOWN_STATE
          mixpanel.track(EVENT_INVITE_USER, mixpanelData.inviteUserData(userPayload))
          mixpanel.track(
            EVENT_INVITE_USER_FAILURE,
            mixpanelData.inviteUserFailureData(userPayload, ERROR_UNKNOWN_STATE)
          )
          break
      }
      setErrorFlags(params, errorCode)
      return false
    }
    mixpanel.track(EVENT_INVITE_USER, mixpanelData.inviteUserData(userPayload))
    return true
  } catch (error) {
    setErrorFlags(params, { error: ERROR_SERVER })
    return false
  }
}

const setErrorFlags = (params, error) => {
  const { setSubmitting, setErrors } = params
  const { error: errorCode } = error
  setSubmitting(false)
  setErrors({
    serverValidation: errorCode
  })
}

const mapPropsToValues = () => {
  return { firstName: '', lastName: '', role: 'Viewer', email: '', updated: false, stores: [] }
}

export const formikObject = {
  displayName: 'InviteUserForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
