export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export const UI_DATE_FORMAT = 'DD MMM YY'
export const UI_DATE_FORMAT_FULL = 'DD/MM/YYYY'
export const UI_DATE_FORMAT_PRINT = 'DD/MM/YY'
export const UI_TIME_FORMAT = 'h:mm a'
export const UI_TIME_SECONDS_FORMAT = 'h:mm:ss a'
export const HEADER_CSV = 'application/vnd.paymark_api+csv'
export const DATA_AVAILABLE_FROM_DATE = '01/06/2016'
export const OTHERS_KEY = 'OTHERS'
export const OTHER_CARD_LIMIT_PERCENTAGE = 5
export const DATE_AM_PM_FORMAT = 'MM/DD/YYYY hh:mm A'
export const MAX_VIEWABLE_CHART_DATA_POINTS = 15
export const DEFAULT_BAR_SALES_MAX_VALUE = 4
export const ROLE_OWNER = 'Owner'
export const ROLE_VIEWER = 'Viewer'
export const ROLE_GLOBAL_VIEWER = 'Global Viewer'
export const ROLE_NON_INSIGHTS_USER = 'Non Insights User'
export const SERVER_ROLE_OWNER = 'INSIGHTS_OWNER'
export const SERVER_ROLE_VIEWER = 'INSIGHTS_VIEWER'
export const NA = 'n/a'
export const TERMS_CONDITIONS_LINK = 'https://www.paymark.co.nz/support/our-fine-print/'
export const PRIVACY_POLICY_LINK = 'https://www.paymark.co.nz/support/our-fine-print/'
export const CALLBACK_LINK = 'https://www.paymark.co.nz/products/insights'
export const INSIGHTS_COST = 20
export const USER_STATE_ACTIVE = 'Active'
export const USER_STATE_WAITING_EMAIL = 'Waiting email verification'
export const USER_STATE_EMAIL_EXPIRED = 'Email expired'
