import { useSelector } from 'react-redux'
import { dashboardSlice } from 'reduxSlices'

function useFilters() {
  const { selectors: dashboardSelectors } = dashboardSlice

  const filter = useSelector(dashboardSelectors.getFilter)

  return {
    filter
  }
}

export default useFilters
