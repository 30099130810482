import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import PropTypes from 'prop-types'
import React from 'react'
import withStyles from 'react-jss'
import { getCardName } from 'utils/filters.utils'
import styles from './CardLogo.styles'
import logoAmex from './pngs/amex.png'
import logoDiners from './pngs/diners.jpg'
import logoEftpos from './pngs/eftpos.png'
import logoJCB from './pngs/jcb.png'
import logoMaster from './pngs/mastercard.png'
import logoUnionPay from './pngs/unionpay.png'
import logoVisa from './pngs/visa.png'

const CardLogo = ({ name, classes }) => {
  switch (name) {
    case 'DINERS':
      return <img src={logoDiners} alt="DINERS" className={classes['diners']} />
    case 'VISA':
      return <img src={logoVisa} alt="VISA" className={classes['visa']} />
    case 'MCARD':
      return <img src={logoMaster} alt="MCARD" className={classes['mcard']} />
    case 'AMEX':
      return <img src={logoAmex} alt="AMEX" className={classes['amex']} />
    case 'JCB':
      return <img src={logoJCB} alt="JCB" className={classes['jcb']} />
    case 'EFTPOS':
      return <img src={logoEftpos} alt="EFTPOS" className={classes['eftpos']} />
    case 'UPICRD':
      return <img src={logoUnionPay} alt="UNION PAY" className={classes['upi']} />
    case 'UPIDBT':
      return <img src={logoUnionPay} alt="UNION PAY" className={classes['upi']} />
    default:
      return (
        <div className={classes['cardName']}>
          <Text variant="h1" color={colors.greyDark}>
            {getCardName(name)}
          </Text>{' '}
        </div>
      )
  }
}

CardLogo.prototype = {
  name: PropTypes.string.isRequired
}

export default withStyles(styles)(CardLogo)
