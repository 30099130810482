const styles = {
  diners: {
    height: '3.5em'
  },
  visa: {
    height: '2em'
  },
  mcard: {
    height: '3.5em'
  },
  amex: { height: '5em', marginTop: '-1.2em' },
  jcb: {
    composes: '$mcard'
  },
  upi: {
    composes: '$mcard'
  },
  eftpos: {
    height: '5em',
    marginTop: '-1.4em'
  },
  cardName: {
    marginTop: '0.2em'
  }
}

export default styles
