import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    '& >div': {
      padding: ['1rem', '1rem'],
      [LAPTOP_UP()]: {
        padding: ['1rem', '1.5rem', 0, '1.5rem']
      },
      [SCREEN_UP()]: {
        padding: ['1rem', '2rem', 0, '2rem']
      },
      [LARGE_SCREEN_UP()]: {
        padding: ['1rem', '3rem', 0, '3rem']
      }
    }
  },
  filterSection: {
    composes: '$content',
    borderBottom: `1px solid ${colors.line1}`
  },
  storeSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.5rem',
    [MOBILE_UP()]: {
      padding: ['1.5rem', 0]
    },
    [LAPTOP_UP()]: {
      padding: ['1.5rem', 0, '0.5rem', 0]
    }
  },
  storeHeader: {
    padding: ['1rem', '1rem', 0, '1rem'],
    [LAPTOP_UP()]: {
      padding: ['1rem', '1.5rem']
    },
    [SCREEN_UP()]: {
      padding: ['1rem', '2rem']
    },
    [LARGE_SCREEN_UP()]: {
      padding: ['1rem', '3rem']
    }
  },
  graphs: {
    padding: [0, '1rem'],
    flex: [1, 1, 'auto'],
    borderBottom: 0,
    display: 'grid',
    gridTemplateColumns: '1fr',
    msGridRows: `auto 1em auto 1em auto 1em auto 1em auto 1em auto`,
    gridTemplateRows: 'auto auto auto auto auto auto',
    gridGap: '1em',
    gridTemplateAreas: `
                  'revenue'
                  'sales'
                  'revenueGraph'
                  'salesGraph'
                  'customersGraph'
                  'cardsGraph'
    `,
    [MOBILE_UP()]: {
      msGridColumns: `1fr 1em 1fr`,
      gridTemplateColumns: '1fr 1fr',
      msGridRows: `1fr 1em auto 1em auto 1em auto 1em auto`,
      gridTemplateRows: '1fr auto auto auto auto',
      gridTemplateAreas: `
                    'revenue sales'
                    'revenueGraph revenueGraph'
                    'salesGraph salesGraph'
                    'customersGraph customersGraph'
                    'cardsGraph cardsGraph'
      `
    },
    [LAPTOP_UP()]: {
      padding: [0, '1.5rem']
    },
    [SCREEN_UP()]: {
      padding: ['1rem', '2rem']
    },
    [LARGE_SCREEN_UP()]: {
      padding: ['1rem', '3rem']
    },
    '& .revenue': {
      gridArea: 'revenue',
      minWidth: '250px', //For Scalling of grah on tablets, when the graph is minimized
      margin: ['1rem', 0, 0, 0],
      msGridColumn: 1,
      msGridRow: 1,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        margin: [0]
      }
    },
    '& .sales': {
      gridArea: 'sales',
      minWidth: '250px',
      margin: ['-1rem', 0, 0, 0],
      msGridColumn: 1,
      msGridRow: 3,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        margin: [0, 0, 0, '-1rem'],
        msGridColumn: 3,
        msGridRow: 1
      }
    },
    '& .revenueGraph': {
      gridArea: 'revenueGraph',
      msGridColumn: 1,
      msGridRow: 5,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        msGridRow: 3,
        msGridColumnSpan: 3
      }
    },
    '& .salesGraph': {
      gridArea: 'salesGraph',
      msGridColumn: 1,
      msGridRow: 7,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        msGridRow: 5,
        msGridColumnSpan: 3
      }
    },
    '& .cardsGraph': {
      gridArea: 'cardsGraph',
      msGridColumn: 1,
      msGridRow: 11,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        msGridRow: 9,
        msGridColumnSpan: 3
      }
    },
    '& .customersGraph': {
      gridArea: 'customersGraph',
      msGridColumn: 1,
      msGridRow: 9,
      msGridColumnSpan: 1,
      [MOBILE_UP()]: {
        msGridRow: 7,
        msGridColumnSpan: 3
      }
    }
  }
}

export default styles
