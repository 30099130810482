import * as colors from 'config/colors'

const styles = {
  fieldset: {
    border: 'none',
    padding: 0,
    margin: 0,
    height: '100%',
    fontSize: '1.125em',
    color: colors.heading,
    '& legend': {
      height: '2.5rem',
      fontWeight: 500,
      marginBottom: 0,
      padding: ' 0.625rem',
      borderBottom: `1px solid ${colors.line2}`,
      width: '100%'
    },
    extend: ({ override }) => ({
      ...override.fieldset
    })
  }
}

export default styles
