import moment from 'moment'

const handleTotaltxnaggReqParams = params => {
  if (
    params.interval === 'hour' &&
    moment(params.transactionTimeTo).diff(moment(params.transactionTimeFrom), 'hours', true) >= 24
  ) {
    params.interval = 'day'
  }
  if ('cardAcceptorIdCode' in params) {
    params.cardAcceptorIdCodes = params.cardAcceptorIdCode
    delete params.cardAcceptorIdCode
  }
  return { ...params }
}

const handleRepeatcustaggReqParams = params => {
  if (params.interval === 'hour') {
    params.interval = 'day'
  }
  if ('cardAcceptorIdCode' in params) {
    params.cardAcceptorIdCodes = params.cardAcceptorIdCode
    delete params.cardAcceptorIdCode
  }
  return { ...params }
}

const transformTotaltxnaggResponse = (interval, result) => {
  let response = {
    interval,
    aggregateTransactions: []
  }
  if ('all' in result) {
    result.all.intervals.forEach(m => {
      response.aggregateTransactions.push({
        periodStart: m.interval,
        transactionCount: m.groups[0].count,
        transactionValue: m.groups[0].value
      })
    })
  } else if ('aggregates' in result) {
    result.aggregates.caics.forEach(n => {
      n.intervals.forEach(m => {
        response.aggregateTransactions.push({
          cardAcceptorIdCode: n.caic,
          periodStart: m.interval,
          transactionCount: m.groups[0].count,
          transactionValue: m.groups[0].value
        })
      })
    })
  }
  return response
}

const transformRepeatcustaggResponse = (interval, result) => {
  let response = {
    interval,
    aggregateTransactions: []
  }
  if ('all' in result) {
    result.all.intervals.forEach(m => {
      const repeatGroup = m.groups.find(g => g.group === 'repeat')
      const newGroup = m.groups.find(g => g.group === 'new')
      response.aggregateTransactions.push({
        periodStart: m.interval,
        repeatCustomerCount: repeatGroup.count,
        repeatCustomerValue: repeatGroup.value,
        newCustomerCount: newGroup.count,
        newCustomerValue: newGroup.value
      })
    })
  } else if ('aggregates' in result) {
    result.aggregates.caics.forEach(n => {
      n.intervals.forEach(m => {
        const repeatGroup = m.groups.find(g => g.group === 'repeat')
        const newGroup = m.groups.find(g => g.group === 'new')
        response.aggregateTransactions.push({
          cardAcceptorIdCode: n.caic,
          periodStart: m.interval,
          repeatCustomerCount: repeatGroup.count,
          repeatCustomerValue: repeatGroup.value,
          newCustomerCount: newGroup.count,
          newCustomerValue: newGroup.value
        })
      })
    })
  }
  return response
}

export {
  handleTotaltxnaggReqParams,
  handleRepeatcustaggReqParams,
  transformTotaltxnaggResponse,
  transformRepeatcustaggResponse
}
