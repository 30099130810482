import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LARGE_SCREEN_UP, MOBILE_UP } = mediaQueryHelper

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1rem', 0, 0, 0],
    [MOBILE_UP()]: {
      flexDirection: 'row',
      padding: ['0.85rem', 0, 0, 0]
    },
    [LARGE_SCREEN_UP()]: {
      padding: ['0.85rem', 0, 0, 0]
    },
    borderBottom: ({ noBorder }) => !noBorder && `1px solid ${colors.line2}`
  },
  label: {
    flexBasis: '25px',
    display: 'inline',
    [MOBILE_UP()]: {
      flexBasis: ({ labelWidth }) => labelWidth || '90px',
      marginTop: ({ alignCenter }) => (alignCenter ? '0.5rem' : 0)
    },
    marginRight: '1rem',
    '& * .icon': {
      cursor: 'pointer'
    }
  },
  content: {
    flex: [1, 1, 'auto']
  },
  infoIcon: {
    marginLeft: '0.5rem',
    marginBottom: '0.3125rem',
    display: 'inline-flex',
    verticalAlign: 'text-top'
  }
}

export default styles
