import React from 'react'
import styles from './UserDelete.styles'
import withStyles from 'react-jss'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import { ConfirmationDialog } from 'components/common'
import Avatar from 'components/common/Avatar'
import { getInitials } from 'utils/user.utils'

const UserDeleteConfirmation = ({ classes, user, onAccept, onClose }) => {
  const initial = getInitials(user)
  const { role, name } = user

  return (
    <ConfirmationDialog
      {...{
        onAccept,
        onClose,
        callbackParams: { user }
      }}
    >
      <div className={classes['body']}>
        <Text variant="body">
          Are you sure you want to delete this user? Once deleted they will need to be added again.
        </Text>

        <div className={classes['userIcon']} data-hj-suppress>
          <Avatar title={initial} width={'2.5rem'} height={'2.5rem'} fontSize={'1rem'} role={role} />
          <Text variant={'body'} color={colors.greyDark}>
            {name}
          </Text>
        </div>
      </div>
    </ConfirmationDialog>
  )
}

export default withStyles(styles)(UserDeleteConfirmation)
