import moment from 'moment'
import { startCase, lowerCase } from 'lodash'
import {
  SERVER_DATE_FORMAT,
  UI_DATE_FORMAT,
  UI_DATE_FORMAT_PRINT,
  UI_TIME_FORMAT,
  UI_DATE_FORMAT_FULL,
  DATA_AVAILABLE_FROM_DATE
} from '../constants/general.constants'
import _ from 'lodash'
import Fuse from 'fuse.js'

const lastAvailableDataDate = moment(DATA_AVAILABLE_FROM_DATE, UI_DATE_FORMAT_FULL)

export const getMomentDatesFromRange = range => {
  const today = moment()
  const yesterday = moment().subtract(1, 'days')
  if (range === 'yesterday') {
    return { from: yesterday, to: yesterday }
  } else if (range === 'today') {
    return { from: today, to: today }
  } else if (range === 'thisWeek') {
    const lastWeek = moment().subtract(6, 'days')
    const today = moment()
    return { from: lastWeek, to: today }
  } else if (range === 'thisMonth') {
    const lastMonth = moment().startOf('month')
    const today = moment()
    return { from: lastMonth, to: today }
  }
  return { from: yesterday, to: yesterday }
}

export const selectUnSelectElem = (array, elem) => {
  if (array.indexOf(elem) > -1) {
    _.remove(array, function(el) {
      return el === elem
    })
  } else {
    array.push(elem)
  }
}

export const getTimeFromRange = range => {
  if (range === 'full') {
    return { from: '12:00 am', to: '11:59 pm' }
  } else if (range === 'morning') {
    return { from: '06:00 am', to: '11:59 am' }
  } else if (range === 'afternoon') {
    return { from: '12:00 pm', to: '05:59 pm' }
  } else if (range === 'evening') {
    return { from: '06:00 pm', to: '11:59 pm' }
  }

  return {}
}

export const getCardName = value => {
  return startCase(lowerCase(value))
}

export const formatDate = (dateString, fromFormat) => {
  let date = null
  if (fromFormat) {
    date = moment(dateString, fromFormat)
  } else {
    date = moment(dateString)
  }

  return date.format(UI_DATE_FORMAT)
}

export const formatDateForPrint = date => {
  return moment(date, SERVER_DATE_FORMAT).format(UI_DATE_FORMAT_PRINT)
}

export const formatDateTimeForPrint = date => {
  return moment(date).format(`${UI_DATE_FORMAT_PRINT} ${UI_TIME_FORMAT}`)
}

export const isDateRangeValid = (startDate, endDate, maxDays = 6) => {
  let isValid = true
  if (startDate && endDate) {
    const days = endDate.diff(startDate, 'days')
    if (days > maxDays) {
      isValid = false
    }
  }

  return isValid
}

export const getDateTime = (date, time) => {
  const format = `${SERVER_DATE_FORMAT} ${UI_TIME_FORMAT}`
  let dateTime = date.format(SERVER_DATE_FORMAT) + ' ' + time
  return moment(dateTime, format)
}

export const isTimeValid = (startDate, endDate, startTime, endTime) => {
  try {
    let start = getDateTime(startDate, startTime)
    let end = getDateTime(endDate, endTime)
    return end.isAfter(start)
  } catch (e) {}

  return true
}

export const toggleFlagWithTimeout = (method, key = 'highlight') => {
  setTimeout(() => {
    method({ [key]: true })
    setTimeout(() => {
      method({ [key]: false })
    }, 700)
  })
}

export const getPaginatedItems = (items, aPage, perPage = 100) => {
  const page = aPage || 1,
    offset = (page - 1) * perPage,
    paginatedItems = _.take(_.drop(items, offset), perPage)
  return {
    paginatorPage: page,
    perPage: perPage,
    total: items.length,
    paginatorTotalPages: Math.ceil(items.length / perPage),
    data: paginatedItems
  }
}

export const isDayBeforeTomorrow = day => {
  day = day.startOf('day')
  const now = moment().endOf('day')
  return now.diff(day) < 1
}

export const isDateOutsideRange = day => {
  day = day.startOf('day')
  const isDateSelectionAllowed = !day.isAfter(lastAvailableDataDate) || isDayBeforeTomorrow(day)
  return isDateSelectionAllowed
}

export const getMomentDate = date => moment(date, SERVER_DATE_FORMAT)

export const doFuzzySearch = (list, searchText, customOptions) => {
  if (_.isEmpty(searchText)) {
    return list
  }
  const DEFAULT_OPTIONS = {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3
  }

  const options = { ...DEFAULT_OPTIONS, ...customOptions }

  var fuse = new Fuse(list, options)
  var result = fuse.search(searchText)

  return result
}
