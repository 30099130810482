import { TABLET_DOWN } from 'config/mediaQueryHelper'
import { pageBackground } from 'config/colors'

const styles = {
  body: {
    backgroundColor: pageBackground,
    padding: '3.25rem 1.25rem 1.25rem',
    [TABLET_DOWN()]: {
      padding: '0.865rem 0.625rem 0.625rem'
    },
    extend: ({ override }) => ({
      ...override['body']
    })
  },
  tile: {
    maxWidth: '450px',
    margin: ' 0 auto',
    extend: ({ override }) => ({
      ...override['tile']
    })
  },
  form: {
    margin: 'auto',
    padding: '2rem 0',
    textAlign: 'center',
    maxWidth: '350px',
    extend: ({ override }) => ({
      ...override['form']
    })
  }
}

export default styles
