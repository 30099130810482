import _ from 'lodash'

export const parseRepeatCustomerData = ({ current, previous }, isComparePeriod) => {
  const DEFAULT = {
    newCustomerCount: 0,
    newCustomerValue: 0,
    periodStart: '0',
    repeatCustomerCount: 0,
    repeatCustomerValue: 0
  }

  const isCurrentGraphEmpty = _.isEmpty(current)
  const isPreviousGraphEmpty = _.isEmpty(previous)

  let currentData = isCurrentGraphEmpty ? DEFAULT : current
  let oldPeriodData = isPreviousGraphEmpty ? DEFAULT : previous

  let returnCustomersGrowth = 0,
    newCustomersGrowth = 0

  let currentGraphData = [],
    previousGraphData = []

  currentGraphData = computeGraphData(currentData, isCurrentGraphEmpty)
  previousGraphData = computeGraphData(oldPeriodData, isPreviousGraphEmpty)

  if (isComparePeriod) {
    returnCustomersGrowth = currentGraphData['repeatCustomerPercentage'] - previousGraphData['repeatCustomerPercentage']
    newCustomersGrowth = currentGraphData['newCustomerPercentage'] - previousGraphData['newCustomerPercentage']
  }

  const isNoTransactions = isCurrentGraphEmpty && isPreviousGraphEmpty

  return {
    isNoTransactions,
    isCurrentGraphEmpty,
    isPreviousGraphEmpty,
    current: currentGraphData,
    previous: previousGraphData,
    returnCustomersGrowth,
    newCustomersGrowth
  }
}

export const getReturnPercentage = (newCustomerCount, repeatCustomerCount) => {
  const total = repeatCustomerCount + newCustomerCount

  if (total === 0) {
    return 0
  }

  if (repeatCustomerCount < 1 && newCustomerCount > 0) {
    return 0
  }

  const returnCustomers = Math.round((repeatCustomerCount / total) * 100)
  return returnCustomers
}

const computeGraphData = (
  { newCustomerCount = 0, repeatCustomerCount = 0, newCustomerValue, repeatCustomerValue },
  isEmpty
) => {
  if (isEmpty) {
    return {
      repeatCustomerPercentage: 0,
      newCustomerPercentage: 0,
      newCustomerValue,
      newCustomerAverage: 0,
      repeatCustomerValue,
      repeatCustomerAverage: 0
    }
  }

  const returnCustomers = getReturnPercentage(newCustomerCount, repeatCustomerCount)
  const repeatCustomerAverage = Math.round((repeatCustomerValue / repeatCustomerCount) * 100) / 100
  const repeatCustomerPercentage = returnCustomers
  const newCustomerAverage = Math.round((newCustomerValue / newCustomerCount) * 100) / 100
  const newCustomerPercentage = 100 - repeatCustomerPercentage

  return {
    repeatCustomerPercentage,
    newCustomerPercentage,
    newCustomerValue,
    newCustomerAverage,
    repeatCustomerValue,
    repeatCustomerAverage
  }
}
