import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { up, BREAK_POINT_LARGE, BREAK_POINT_MEDIUM } = mediaQueryHelper

const styles = {
  myAccount: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1rem', '1rem'],

    [up(BREAK_POINT_LARGE)]: {
      flexDirection: 'row',
      padding: ['3.5rem', '5rem']
    }
  },
  roleSection: {
    [mediaQueryHelper.LAPTOP_UP()]: {
      flexBasis: '150px'
    }
  },
  formSection: {
    flex: [3, 1, 'auto'],
    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: [0, '1rem']
    },
    [up(BREAK_POINT_LARGE)]: {
      padding: [0, '3rem']
    }
  },
  counts: {
    display: 'flex',
    padding: ['2rem', 0, 0, 0],
    margin: ['1rem', 0],
    width: '100%',

    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: [0],
      margin: [0],
      flex: [1, 1, 'auto']
    }
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: ['1rem', 0],
    [up(BREAK_POINT_MEDIUM)]: {
      flexDirection: 'row'
    },
    [up(BREAK_POINT_LARGE)]: {
      flexDirection: 'column',
      padding: [0, '3rem']
    }
  },
  role: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [up(BREAK_POINT_MEDIUM)]: {
      flexDirection: 'row',
      flex: [2, 1, 'auto']
    },
    [up(BREAK_POINT_LARGE)]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& > div': {
      margin: 'auto'
    }
  },
  avatar: {
    flex: [1, 1, 'auto']
  },
  roleInfo: {
    flex: [1, 1, 'auto']
  }
}

export default styles
