import React, { useEffect, useState } from 'react'
import styles from './TransactionTable.styles'
import withStyles from 'react-jss'
import moment from 'moment'
import {
  UI_TIME_SECONDS_FORMAT,
  UI_DATE_FORMAT_FULL,
  UI_DATE_FORMAT_PRINT,
  UI_TIME_FORMAT
} from 'constants/general.constants'
import { TransactionTypes } from '../../TransactionFilter/TransactionTypeSelector/TransactionTypeSelector'
// commented out this no longer used PaginationNavigator component and uninstall @mui/styles lib for DAT-25132
//import PaginationNavigator from 'components/PaginationNavigator/PaginationNavigator'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
import Message from 'components/Message/Message'
import Paginator from 'components/Paginator/Paginator'
import Icon from 'components/Icon/Icon'
import ContentLoader from 'react-content-loader'
import MediaQuery from 'react-responsive'
import { Statuses } from '../../TransactionFilter/StatusSelector/StatusSelector'
import isEqual from 'lodash/isEqual'
import { getPaginatedItems } from 'utils/filters.utils'
import { getCurrency, formatNumber } from 'utils/number.utils'
import { getFeatureFlag, FEATURE_FLAG_SERVER_SIDE_PAGINATION, getConfig } from 'utils/config.utils'

const serversidePagination = getFeatureFlag(FEATURE_FLAG_SERVER_SIDE_PAGINATION)

const { BREAK_POINT_MEDIUM, max, min } = mediaQueryHelper

const PER_PAGE = 100
const { maxTransactions } = getConfig()
const formattedMaxTransactions = formatNumber(maxTransactions + 1, false, false)

const StatusIcon = ({ status }) => {
  if (Statuses[1].label === status) {
    return <Icon name="done" color={colors.icon} width={'1.25em'} />
  }

  return <Icon name="clear" color={colors.icon} width={'1.25em'} />
}

const getContentLoader = random => {
  return (
    <>
      <ContentLoader height={40} width={980} speed={2} primaryColor="#d9d9d9" secondaryColor="#ecebeb">
        <MediaQuery query={`(${max(BREAK_POINT_MEDIUM)})`}>
          <rect x="0" y="13" rx="6" ry="6" width={300 * random} height="16" />
          <rect x="350" y="13" rx="6" ry="6" width={200 * random} height="16" />
          <rect x="600" y="13" rx="6" ry="6" width={200 * random} height="16" />
          <rect x="850" y="13" rx="6" ry="6" width={100 * random} height="16" />
        </MediaQuery>
        <MediaQuery query={`(${min(BREAK_POINT_MEDIUM)})`}>
          <rect x="0" y="13" rx="6" ry="6" width={100 * random} height="12" />
          <rect x="130" y="13" rx="6" ry="6" width={150 * random} height="12" />
          <rect x="300" y="13" rx="6" ry="6" width={75 * random} height="12" />
          <rect x="430" y="13" rx="6" ry="6" width={30 * random} height="12" />
          <rect x="500" y="13" rx="6" ry="6" width={50 * random} height="12" />
          <rect x="580" y="13" rx="6" ry="6" width={50 * random} height="12" />
          <rect x="660" y="13" rx="6" ry="6" width={50 * random} height="12" />
          <rect x="770" y="13" rx="6" ry="6" width={35 * random} height="12" />
          <rect x="860" y="13" rx="6" ry="6" width={35 * random} height="12" />
          <rect x="920" y="13" rx="6" ry="6" width={40 * random} height="12" />
        </MediaQuery>
      </ContentLoader>
    </>
  )
}

const LoadingTable = ({ classes }) => {
  const random1 = Math.random() * (1 - 0.7) + 0.7
  const random2 = Math.random() * (1 - 0.7) + 0.7
  return (
    <>
      <table className={classes['table']}>
        <TableHeader {...{ classes }} />
      </table>
      {getContentLoader(random1)}
      {getContentLoader(random2)}
      {getContentLoader(random1)}
    </>
  )
}

const TableHeader = ({ classes, selectedTransaction }) => {
  return (
    <thead>
      <tr>
        {!selectedTransaction && <th className={classes['terminalIdCol']}>Terminal ID</th>}
        <th className={classes['transactionDateCol']}>Transaction Date</th>
        <th className={classes['settlementCol']}>Settlement</th>
        {!selectedTransaction && <th className={classes['last4Col']}>Last 4</th>}
        {!selectedTransaction && <th className={classes['transactionNoCol']}>TXN #</th>}
        {!selectedTransaction && <th className={classes['cardCol']}>Card</th>}
        {!selectedTransaction && <th className={classes['typeCol']}>Type</th>}
        <th className={classes['purchaseCol']}>Purchase</th>
        {!selectedTransaction && <th className={classes['cashCol']}>Cash</th>}
        <th className={classes['statusCol']}>Status</th>
      </tr>
    </thead>
  )
}

const TransactionTime = ({ selectedTransaction, transactionTime }) => {
  const parsedTansactionTime = moment(transactionTime)
  const formattedTransactionTime = `${parsedTansactionTime.format(
    UI_DATE_FORMAT_FULL
  )} at ${parsedTansactionTime.format(UI_TIME_SECONDS_FORMAT)}`

  const formattedTransactionTimeShort = `${parsedTansactionTime.format(
    UI_DATE_FORMAT_PRINT
  )} ${parsedTansactionTime.format(UI_TIME_FORMAT)}`

  return (
    <>
      <span className="mobile">{formattedTransactionTimeShort} </span>
      <span className="desktop noPrint">
        {selectedTransaction ? formattedTransactionTimeShort : formattedTransactionTime}{' '}
      </span>
    </>
  )
}

const SettlementDate = ({ selectedTransaction, settlementDate }) => {
  const parsedSettlementDate = moment(settlementDate)
  const formattedSettlementDateShort = parsedSettlementDate.format(UI_DATE_FORMAT_PRINT)
  const formattedSettlementDate = parsedSettlementDate.format(UI_DATE_FORMAT_FULL)

  return (
    <>
      <span className="mobile">{formattedSettlementDateShort} </span>
      <span className="desktop noPrint">
        {selectedTransaction ? formattedSettlementDateShort : formattedSettlementDate}{' '}
      </span>
    </>
  )
}

const Status = ({ status }) => {
  return (
    <>
      <span className="mobile noPrint">
        <StatusIcon {...{ status }} />
      </span>
      <span className="desktop">{status} </span>{' '}
    </>
  )
}

const MaxRecordsShownMessage = ({ classes }) => {
  return (
    <div className={classes['errorMessage']}>
      <Message
        text={`Search is limited to the first ${formattedMaxTransactions} transactions. Please refine your search or use the export option which will include all transactions.`}
        variant={'error'}
        showIcon={false}
      />
    </div>
  )
}

const TransactionTable = ({
  transactions = [],
  totalResults,
  totalPages,
  page,
  search,
  fromCount = (page - 1) * PER_PAGE + 1,
  toCount = page === totalPages ? totalResults : fromCount + (PER_PAGE - 1),
  isLoading,
  classes,
  selectedTransaction,
  onSelectTransaction,
  history
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const result = !serversidePagination ? getPaginatedItems(transactions, currentPage, PER_PAGE) : { data: transactions }
  const { paginatorPage, paginatorTotalPages, data: transactionsToDisplay } = result
  const isMaxTransactionsShown = transactions.length > maxTransactions

  // Reset page no to 1 when transactions are updated.
  useEffect(() => {
    setCurrentPage(1)
  }, [transactions])

  useEffect(() => {
    if (!selectedTransaction) {
      return
    }

    const isSelectTransactionDisplayed = transactionsToDisplay.filter(transaction => {
      const { position, ...transactionData } = selectedTransaction
      return isEqual(transaction, transactionData)
    })
    if (isSelectTransactionDisplayed.length === 0) {
      onSelectTransaction(null)
    }
  })

  if (isLoading) return <LoadingTable {...{ classes }} />

  const changeToPage = pageNo => {
    setCurrentPage(pageNo)
    document.getElementById('transactionSummaryTop').scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const onRowSelect = (e, transaction) => {
    let offset = -130 // For desktop and tablet
    if (window.screen.width < 448) {
      offset = 330 //For mobile shown as a modal
    }
    const position = e.pageY - document.getElementById('transactionTable').offsetTop + offset
    onSelectTransaction({ ...transaction, position })
  }

  return (
    <div className={classes['tableContainer']}>
      {isMaxTransactionsShown && !serversidePagination && <MaxRecordsShownMessage {...{ classes }} />}
      <table className={classes['table']} id="transactionTable">
        <TableHeader {...{ classes, selectedTransaction }} />
        {transactions.length > 0 && (
          <tbody>
            {transactionsToDisplay.map((transaction, index) => {
              const {
                terminalId,
                cardLogo,
                cashoutAmount,
                purchaseAmount,
                settlementDate,
                status,
                suffix,
                tranType,
                transactionNumber,
                transactionTime
              } = transaction

              const formattedTransactionType = TransactionTypes.filter(type => {
                return type.value === tranType
              })[0] || { label: '' }

              const { position, ...transactionData } = selectedTransaction || {}

              const hightlightClass = isEqual(transaction, transactionData) ? classes['highlight'] : ''

              return (
                <tr key={index} onClick={e => onRowSelect(e, transaction)} className={hightlightClass} data-hj-suppress>
                  {!selectedTransaction && <td className={classes['terminalIdCol']}>{terminalId}</td>}
                  <td className={classes['transactionDateCol']}>
                    <TransactionTime {...{ selectedTransaction, transactionTime }} />
                  </td>
                  <td className={classes['settlementCol']}>
                    <SettlementDate {...{ selectedTransaction, settlementDate }} />
                  </td>
                  {!selectedTransaction && <td className={classes['last4Col']}>{suffix}</td>}
                  {!selectedTransaction && <td className={classes['transactionNoCol']}>{transactionNumber}</td>}
                  {!selectedTransaction && <td className={classes['cardCol']}>{cardLogo}</td>}
                  {!selectedTransaction && (
                    <td className={classes['typeCol']}>
                      {purchaseAmount > 0 && cashoutAmount > 0 ? 'P&C' : formattedTransactionType.label}
                    </td>
                  )}
                  <td className={classes['purchaseCol']}>{getCurrency(purchaseAmount)}</td>
                  {!selectedTransaction && <td className={classes['cashCol']}>{getCurrency(cashoutAmount)}</td>}
                  <td className={classes['statusCol']}>
                    <Status {...{ status }} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      {paginatorTotalPages > 1 && !serversidePagination && (
        <div className={classes['pagination']}>
          <Paginator
            onNextClick={() => changeToPage(paginatorPage + 1)}
            onPreviousClick={() => changeToPage(paginatorPage - 1)}
            currentPage={paginatorPage}
            totalPages={paginatorTotalPages}
          />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(TransactionTable)
