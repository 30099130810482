import React from 'react'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import styles from './StoreRevenueSummaryCard.styles'
import { white } from 'config/colors'
import { getCurrency, getTotal, getDifferencePercentage } from 'utils/number.utils'
import { ImprovementPercentage, DashboardSummaryCard } from 'components/common'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import StoreRevenueSummaryCardLoader from './StoreRevenueSummaryCardLoader'
import _ from 'lodash'

const dashboardCommonParams = {
  title: 'Revenue',
  iconName: 'revenue',
  height: '100px'
}

const StoreRevenueSummaryCard = ({ classes }) => {
  const { primaryStore, isComparePeriod } = useSalesRevenueAgg()
  const { data: primaryData, isLoading: isPrimaryLoading } = primaryStore

  if (isPrimaryLoading) {
    return (
      <DashboardSummaryCard
        {...{
          ...dashboardCommonParams
        }}
      >
        <StoreRevenueSummaryCardLoader />
      </DashboardSummaryCard>
    )
  }

  const currentTotal = _.isEmpty(primaryData) ? 0 : getTotal(primaryData, 'currentRevenue')
  let oldTotal = isComparePeriod ? (_.isEmpty(primaryData) ? 0 : getTotal(primaryData, 'oldRevenue')) : 0
  let difference = isComparePeriod ? (_.isEmpty(primaryData) ? 0 : getDifferencePercentage(currentTotal, oldTotal)) : 0

  return (
    <DashboardSummaryCard
      {...{
        ...dashboardCommonParams
      }}
    >
      <div className={classes['total']}>
        <div className={classes['currentPeriod']} data-hj-suppress>
          <Text variant="h1" color={white}>
            {getCurrency(currentTotal, false, false)}
          </Text>
        </div>
        {isComparePeriod && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage
              paramColor={white}
              positiveColor={white}
              negativeColor={white}
              {...{ value: difference, variant: 'h2', iconSize: '1.15em', iconClass: 'iconClass' }}
            />
          </div>
        )}
      </div>
    </DashboardSummaryCard>
  )
}

export default withStyles(styles)(StoreRevenueSummaryCard)
