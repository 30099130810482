import { signUp, verifyAccount, verifyInvite } from './registration.api'
import { updatePersonalInformation, getUserInformation, updatePassword, getMerchantAccess } from './user.api'
import { getMerchantSettlements, getTerminalSettlements, getSettlementExportData } from './settlement.api'
import { getTransactions } from './transaction.api'
import { fetchAccessToken } from './bearer'
import { getAllStoresInformation, getAllReturnCustomers, getAllCardsUsed } from 'api/allStoresPerformance.api'
import { getAllStoresSalesRevenueInformation } from 'api/allStoresSalesRevenuePerformance.api'
import {
  getReturnCustomersAggByMerchant,
  getTransactionAggByMerchant,
  getCardsUsedAggByMerchant
} from './storePerformance.api'
import { getAllUsersByOrg, deleteUser, updateUser, inviteUser, getUsersByEmail } from './users.api'
import { getAllStores, signUpStore, updateStore } from './stores.api'
import Mixpanel from 'api/mixpanel.api'

const apis = {
  signUp,
  verifyAccount,
  verifyInvite,
  updatePersonalInformation,
  deleteUser,
  getUserInformation,
  updatePassword,
  getMerchantAccess,
  fetchAccessToken,
  getMerchantSettlements,
  getTerminalSettlements,
  getSettlementExportData,
  getTransactions,
  getAllStoresInformation,
  getAllReturnCustomers,
  getAllCardsUsed,
  getAllStores,
  getAllStoresSalesRevenueInformation,
  getReturnCustomersAggByMerchant,
  getTransactionAggByMerchant,
  getCardsUsedAggByMerchant,
  getAllUsersByOrg,
  inviteUser,
  getUsersByEmail,
  updateStore,
  updateUser,
  signUpStore,
  Mixpanel
}

export default apis
