import React, { useState, useEffect } from 'react'
import Accordion from 'components/Accordion/Accordion'
import StoreHeader from './StoreHeader'
import StoreBody from './StoreBody'
import styles from './StoreList.styles'
import withStyles from 'react-jss'

const StoreItem = ({ key, store, classes, handleAddUserToStore }) => {
  const [currentStore, setStore] = useState(store)

  useEffect(() => {
    setStore(store)
  }, [store])

  const handleStoreUpdate = updatedStore => {
    setStore(updatedStore)
  }

  return (
    <Accordion.Item key={key} data-testid={`accordion-item-${currentStore.cardAcceptorIdCode}`}>
      <Accordion.ItemHeader>
        <StoreHeader store={currentStore} classes={classes} />
      </Accordion.ItemHeader>
      <Accordion.ItemPanel>
        <StoreBody
          cardAcceptorIdCode={currentStore.cardAcceptorIdCode}
          onStoreUpdate={handleStoreUpdate}
          handleAddUserToStore={handleAddUserToStore}
        />
      </Accordion.ItemPanel>
    </Accordion.Item>
  )
}

export default withStyles(styles)(StoreItem)
