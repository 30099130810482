import axios from './axiosCustomErrorHandling'
import {
  handleTotaltxnaggReqParams,
  handleRepeatcustaggReqParams,
  transformTotaltxnaggResponse,
  transformRepeatcustaggResponse
} from 'utils/aggregateWrapper.utils'

const PATH_ALL_STORES = 'data/totaltxnagg/'
const PATH_RETURN_CUSTOMERS = 'data/repeatcustagg/'
const PATH_CARDS_USED = 'merchant/transaction/aggregate'

export const getReturnCustomersAgg = async ({
  transactionTimeFrom,
  transactionTimeTo,
  aggregate = false,
  cardAcceptorIdCode
}) => {
  const serviceParams = {
    transactionTimeFrom,
    transactionTimeTo,
    interval: 'all',
    aggregate,
    cardAcceptorIdCode
  }
  const response = await axios.get(PATH_RETURN_CUSTOMERS, { params: handleRepeatcustaggReqParams(serviceParams) })
  return transformRepeatcustaggResponse(serviceParams.interval, response).aggregateTransactions
}

export const getCardData = async params => {
  const { aggregate = true } = params
  const baseParams = {
    interval: 'all',
    groupBy: 'cardtype',
    aggregate
  }

  const serviceParams = { ...params, ...baseParams }

  return await axios.get(PATH_CARDS_USED, { params: serviceParams })
}

export const getSalesRevenueAgg = async params => {
  const { transactionTimeFrom, transactionTimeTo } = params
  const serviceParams = {
    transactionTimeFrom,
    transactionTimeTo,
    interval: 'all'
  }
  const response = await axios.get(PATH_ALL_STORES, { params: handleTotaltxnaggReqParams(serviceParams) })
  return transformTotaltxnaggResponse(serviceParams.interval, response).aggregateTransactions
}
