import { useRef, useState } from 'react'
import { doFuzzySearch } from 'utils/filters.utils'
import _ from 'lodash'

const useUserSelector = ({ availableUsers, handleUserSelection }) => {
  const previousState = useRef([])
  const [isAddAll, setAddAll] = useState(false)
  const [isAddSearched, setAddSearched] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([])

  const onSelectUsers = users => {
    setSelectedUsers(users)
    handleUserSelection(users)
  }

  const onSearchTextChange = e => {
    const { value } = e.target
    setSearchText(value)
    setAddSearched(false)
  }

  const onSelectUser = user => {
    const newSelectedUsers = [...selectedUsers, user]
    onSelectUsers(newSelectedUsers)
  }

  const onSelectAll = () => {
    if (!isAddAll) {
      previousState.current = [...selectedUsers]
      setAddAll(true)
      const newSelectedUsers = [...selectedUsers, ...unSelectedUsers]
      onSelectUsers(newSelectedUsers)
      setSearchText('')
    } else {
      onSelectUsers(previousState.current)
      setAddAll(false)
    }
  }

  const onSelectAllFiltered = () => {
    if (!isAddSearched) {
      previousState.current = [...selectedUsers]
      const newSelectedUsers = [...selectedUsers, ...filteredUsers]
      onSelectUsers(newSelectedUsers)
      setAddSearched(true)
    } else {
      setAddSearched(false)
      onSelectUsers(previousState.current)
    }
  }

  const deSelectUser = user => {
    const filteredUsers = _.differenceBy(selectedUsers, [user], 'email')
    onSelectUsers(filteredUsers)
    setAddAll(false)
    setAddSearched(false)
  }

  const unSelectedUsers = _.differenceBy(availableUsers, selectedUsers, 'email')
  const filteredUsers = doFuzzySearch(unSelectedUsers, searchText, { keys: ['name'] })

  return {
    onSearchTextChange,
    onSelectAllFiltered,
    onSelectAll,
    onSelectUser,
    deSelectUser,
    searchText,
    selectedUsers,
    filteredUsers,
    isAddSearched,
    unSelectedUsers,
    isAddAll
  }
}

export default useUserSelector
