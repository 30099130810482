import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardSlice, accountSlice } from 'reduxSlices'
import { getConfig } from 'utils/config.utils'
import moment from 'moment'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_DASHBOARD_QUERY } from 'constants/mixpanel.constants'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'

const getParams = (isComparePeriod, startDate, endDate, previousStartPeriod, previousEndPeriod, rangeInterval) => {
  return {
    isCompare: isComparePeriod,
    current: {
      transactionTimeFrom: startDate,
      transactionTimeTo: endDate,
      interval: rangeInterval
    },
    previous: {
      transactionTimeFrom: previousStartPeriod,
      transactionTimeTo: previousEndPeriod,
      interval: rangeInterval
    }
  }
}

function useBusinessPerformanceActions() {
  const { actions: dashboardActions, selectors: dashboardSelectors } = dashboardSlice
  const dispatch = useDispatch()
  const { selectors: accountSelectors } = accountSlice

  // Actions
  const setViewAction = useCallback(view => dispatch(dashboardActions.setView(view)), [dispatch, dashboardActions])
  const fetchAllStoresAction = useCallback(actionParams => dispatch(dashboardActions.fetchAllStores(actionParams)), [
    dispatch,
    dashboardActions
  ])
  const fetchAllReturnCustomersAction = useCallback(
    actionParams => dispatch(dashboardActions.fetchAllReturnCustomers(actionParams)),
    [dispatch, dashboardActions]
  )

  const fetchAllCardsUsedactions = useCallback(
    actionParams => dispatch(dashboardActions.fetchAllCardsUsed(actionParams)),
    [dispatch, dashboardActions]
  )
  const fetchAllSalesRevenueAggAction = useCallback(
    actionParams => dispatch(dashboardActions.fetchAllSalesRevenueAgg(actionParams)),
    [dispatch, dashboardActions]
  )

  const fetchStoreRepeatCustomers = useCallback(
    (actionParams, isSecondary = false) =>
      dispatch(dashboardActions.fetchStoreRepeatCustomers({ params: actionParams, isSecondary })),
    [dispatch, dashboardActions]
  )

  const sendBusinessPerfMixpanelTracking = params => {
    const mixpanelBusinessPerfData = mixpanelData.businessDashboardQueryData(params)
    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_DASHBOARD_QUERY, mixpanelBusinessPerfData)
  }

  // Data
  const merchants = useSelector(accountSelectors.getMerchantAccess)
  const isShowBusinessPerformance = merchants ? merchants.length > 1 : false
  const view = useSelector(dashboardSelectors.getView)
  const filter = useSelector(dashboardSelectors.getFilter)
  const expandedCard = useSelector(dashboardSelectors.getExpandedCard)

  const { isComparePeriod, startDate, endDate, previousStartPeriod, previousEndPeriod, interval, range } = filter

  useEffect(() => {
    let actionParams = getParams(isComparePeriod, startDate, endDate, previousStartPeriod, previousEndPeriod, interval)

    const { dataReloadInterval } = getConfig()
    const reloadTime = dataReloadInterval * 1000
    const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)

    const getData = () => {
      fetchAllStoresAction(actionParams)
      fetchAllReturnCustomersAction(actionParams)
      if (cardsUsedFeatureFlag) {
        fetchAllCardsUsedactions(actionParams)
      }

      fetchAllSalesRevenueAggAction(actionParams)
    }

    getData()

    const mixpanelParams = { ...actionParams, range: range }
    sendBusinessPerfMixpanelTracking(mixpanelParams)

    const isAutoReloadDisabled = moment(actionParams.current.transactionTimeTo).isBefore(moment())

    if (!isAutoReloadDisabled) {
      const timer = setInterval(() => {
        actionParams.showLoading = false
        getData()
      }, reloadTime)

      return () => clearInterval(timer)
    }
  }, [
    range,
    isComparePeriod,
    startDate,
    endDate,
    previousStartPeriod,
    previousEndPeriod,
    interval,
    fetchAllStoresAction,
    fetchAllReturnCustomersAction,
    fetchAllCardsUsedactions,
    fetchAllSalesRevenueAggAction,
    view,
    fetchStoreRepeatCustomers
  ])

  return {
    view,
    filter,
    expandedCard,
    isShowBusinessPerformance,
    merchants,
    setViewAction
  }
}

export default useBusinessPerformanceActions
