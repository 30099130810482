import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP, LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    paddingBottom: '0 !important',
    [MOBILE_UP()]: {
      justifyContent: 'flex-start'
    }
  },
  components: {
    margin: [0, '0.75rem', '1rem', 0],
    [LARGE_SCREEN_UP()]: {
      margin: [0, '1.25rem', '1rem', 0]
    }
  },
  datePicker: {
    composes: '$components'
  },
  dateRange: {
    composes: '$components',
    display: 'flex',
    flex: [1, 1, 'auto'],
    minWidth: '180px',
    maxWidth: '285px',
    height: '36px',
    [MOBILE_UP()]: {
      minWidth: '200px'
    },
    [LAPTOP_UP()]: {
      width: '210px',
      height: '40px'
    },
    [LARGE_SCREEN_UP()]: {
      width: '240px'
    }
  },
  searchStoreActive: {
    width: '100%',
    paddingBottom: '0.75rem'
  },
  searchStore: {
    composes: '$components',
    flex: [1, 1, 'auto'],
    [LAPTOP_UP()]: {
      maxWidth: '457px'
    },
    [SCREEN_UP()]: {
      maxWidth: '154px'
    },
    [LARGE_SCREEN_UP()]: {
      maxWidth: '385px'
    }
  },
  compareSection: {
    display: 'flex'
  },
  compareToPreviousPeriod: {
    composes: '$components'
  },
  compareToAnotherStore: {
    composes: '$components'
  },
  errorMessage: {
    paddingBottom: '1rem',
    textAlign: 'center',
    width: '100%'
  }
}

export default styles
