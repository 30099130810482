import React from 'react'
import Text from 'components/Text/Text'

const NoTransactionsMessage = ({ classes }) => {
  return (
    <div className={classes['noTransactionMessage']}>
      <div>
        <Text variant="h5">There are no transactions for this time period.</Text>
      </div>
      <div>
        <Text variant="note"> Please select a different range.</Text>
      </div>
    </div>
  )
}

export default NoTransactionsMessage
