import * as colors from 'config/colors'

const styles = {
  divider: {
    height: params => params.height || '1px',
    margin: 0,
    border: 'none',
    flexShrink: 0,
    backgroundColor: params => params.color || colors.line2,
    extend: ({ override }) => ({
      ...override.divider
    })
  }
}

export default styles
