import _ from 'lodash'
import { DEFAULT_BAR_SALES_MAX_VALUE } from 'constants/general.constants'

const getMinMax = (isCompareStore, isCompare, primaryData, secondaryData, currentKey, oldKey) => {
  if (_.isEmpty(primaryData)) {
    return { maxValue: DEFAULT_BAR_SALES_MAX_VALUE, minValue: 0 }
  }
  let maxCurrentValue = maxByKey(primaryData, currentKey)
  let minCurrentValue = minByKey(primaryData, currentKey)
  let maxOldValue = isCompare ? maxByKey(primaryData, oldKey) : maxCurrentValue
  let minOldValue = isCompare ? minByKey(primaryData, oldKey) : minCurrentValue
  if (!_.isEmpty(secondaryData) && !isCompare && isCompareStore) {
    const maxSecondaryCurrentValue = maxByKey(secondaryData, currentKey)
    const maxSecondaryOldValue = maxByKey(secondaryData, oldKey)
    maxCurrentValue = maxSecondaryCurrentValue > maxCurrentValue ? maxSecondaryCurrentValue : maxCurrentValue
    maxOldValue = maxSecondaryOldValue > maxOldValue ? maxSecondaryOldValue : maxOldValue

    const minSecondaryCurrentValue = minByKey(secondaryData, currentKey)
    const minSecondaryOldValue = minByKey(secondaryData, oldKey)
    minCurrentValue = minSecondaryCurrentValue < minCurrentValue ? minSecondaryCurrentValue : minCurrentValue
    minOldValue = minSecondaryOldValue < minOldValue ? minSecondaryOldValue : minOldValue
  }

  let maxValue = maxCurrentValue > maxOldValue ? maxCurrentValue : maxOldValue
  let minValue = minCurrentValue < minOldValue ? minCurrentValue : minOldValue
  maxValue = maxValue > Math.abs(minValue) ? maxValue : Math.abs(minValue)
  minValue = minValue >= 0 ? minValue : addGap(-Math.floor(Math.abs(maxValue)))
  maxValue = addGap(Math.ceil(maxValue))
  return { maxValue: maxValue, minValue: minValue }
}

const addGap = value => {
  let newValue = Math.ceil(Math.abs(value) * 1.01)
  return value < 0 ? -newValue : newValue
}

const maxByKey = (value, key) => {
  return _.maxBy(value, key)[key]
}

const minByKey = (value, key) => {
  return _.minBy(value, key)[key]
}

export const getMinMaxSalesValue = (isCompareStore = false, isCompare = false, primaryData, secondaryData) => {
  return getMinMax(isCompareStore, isCompare, primaryData, secondaryData, 'currentSales', 'oldSales')
}

export const getMinMaxRevenueValue = (isCompareStore = false, isCompare = false, primaryData, secondaryData) => {
  return getMinMax(isCompareStore, isCompare, primaryData, secondaryData, 'currentRevenue', 'oldRevenue')
}
