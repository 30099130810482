import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { accountSlice, settlementSlice } from '../../reduxSlices'

const appState = state => {
  return {
    user: accountSlice.selectors.getUser(state),
    banner: accountSlice.selectors.getBanner(state),
    merchants: accountSlice.selectors.getMerchantAccess(state),
    isMerchantsLoading: accountSlice.selectors.isMerchantsLoading(state)
  }
}

// Passes all the appState store to container.
const mapStateToProps = state => ({
  ...appState(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...accountSlice.actions,
      resetSettlementSummary: settlementSlice.actions.resetSettlementSummary
    },
    dispatch
  )
})

export default Component =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
