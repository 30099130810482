import { standardFontFamily, weightRegular } from 'config/jss-vars'
import * as colors from 'config/colors'
const styles = {
  message: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.9375rem 0 0.625rem',
    padding: '0.8125rem 1.25rem',
    fontSize: ' 0.875rem',
    lineHeight: '1.28571',
    borderRadius: '2px',
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    '& + &': {
      marginTop: '20px'
    },
    '& * svg': {
      height: '34px',
      width: '35px',
      marginRight: '0.9375rem'
    },
    '& >p': {
      flex: '1 1 auto',
      display: 'inline-block',
      margin: 0
    },
    extend: ({ override }) => ({
      ...override['message']
    })
  },
  success: {
    composes: '$message',
    color: '#292540',
    'background-color': '#effbff',
    extend: ({ override }) => ({
      ...override['success']
    })
  },
  error: {
    composes: '$message',
    color: colors.error,
    backgroundColor: colors.highlightWarning,
    extend: ({ override }) => ({
      ...override['error']
    })
  },
  warning: {
    composes: '$message',
    color: colors.warning,
    'background-color': '#fff3cd',
    extend: ({ override }) => ({
      ...override['warning']
    })
  }
}

export default styles
