import { useSelector } from 'react-redux'
import { dashboardSlice, storeSlice } from 'reduxSlices'
import _ from 'lodash'

function useCardsUsed() {
  const { selectors: dashboardSelectors } = dashboardSlice
  const { selectors: storeSelectors } = storeSlice

  const allCardsUsed = useSelector(dashboardSelectors.getAllCardsUsed)
  const isComparePeriod = useSelector(dashboardSelectors.isComparePeriod)
  const topCardUsed = _.get(allCardsUsed, 'data.current[0]')
  const isCompareStore = useSelector(dashboardSelectors.isCompareStore)

  const primaryStoreCardsUsed = useSelector(storeSelectors.getPrimaryStoreCardsUsed)
  const secondaryStoreCardsUsed = useSelector(storeSelectors.getSecondaryStoreCardsUsed)

  return {
    allCardsUsed,
    isComparePeriod,
    isCompareStore,
    topCardUsed,
    primaryStore: primaryStoreCardsUsed,
    secondaryStore: secondaryStoreCardsUsed
  }
}

export default useCardsUsed
