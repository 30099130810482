import { DashboardRoute } from 'Routes'

export const goBack = (routerProps, defaultBackRoute = DashboardRoute.path) => {
  const { history } = routerProps
  const { goBack, length } = history
  if (length <= 2) {
    return history.push(defaultBackRoute)
  }
  return goBack()
}
