import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
import tableStyles from 'styles/Table.styles'
const { MOBILE_UP, MOBILE_DOWN } = mediaQueryHelper

const styles = {
  table: {
    ...tableStyles,
    marginTop: '0.5rem',
    '& * td': {
      borderBottom: `1px solid ${colors.line2} !important`,
      verticalAlign: 'middle',
      fontSize: '1.125em'
    }
  },
  tableHeader: {
    textAlign: 'center',
    [MOBILE_DOWN()]: { textAlign: 'right' }
  },
  serialNoCol: {
    textAlign: 'center',
    width: '5px',
    verticalAlign: 'top',
    padding: ['0.2em'],
    [MOBILE_UP()]: {
      minWidth: '40px',
      padding: ['0.5em']
    }
  },
  storeCol: {
    verticalAlign: 'top',
    textAlign: 'left',
    color: colors.teal,
    cursor: 'pointer',
    width: 'auto',
    wordBreak: 'break-word',
    padding: ['0.2em'],
    [MOBILE_UP()]: {
      padding: ['0.5em']
    }
  },
  revenueCol: {
    textAlign: 'right',
    width: '60px',
    padding: ['0.2em'],
    [MOBILE_UP()]: {
      textAlign: 'center',
      padding: ['0.5em', '0em', '0.5em', '1.125em']
    }
  },
  salesCol: {
    composes: '$revenueCol',
    width: '1px'
  },
  returnCustomersCol: {
    composes: '$revenueCol',
    width: '30px'
  },
  comparisonCol: {
    width: '1px',
    [MOBILE_DOWN()]: {
      borderWidth: '0',
      width: '0',
      maxWidth: '0',
      padding: '0',
      visibility: 'hidden'
    }
  },
  percentageComparisonCol: {
    width: '1px',
    padding: '0 1.125em 0 0',
    [MOBILE_DOWN()]: {
      borderWidth: '0',
      width: '0',
      maxWidth: '0',
      padding: '0',
      visibility: 'hidden'
    },
    '& > div': {
      padding: '0',
      marginBottom: '0.5413em',
      marginTop: '0.3438em', // Line height compensation >_<
      fontSize: '1rem'
    }
  },
  columnTitle: {
    display: 'inline-block',
    paddingRight: '0.5rem',
    '& > div': {
      float: 'right'
    }
  },
  columnName: {
    padding: ['0.125rem', '0.5rem'],
    display: 'none',
    [MOBILE_UP()]: {
      display: 'block'
    }
  },

  columnData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-end',
    [MOBILE_UP()]: {
      paddingLeft: '0.5rem'
    },
    '&>svg': {
      paddingTop: '0.25rem'
    }
  },
  iconMobile: {
    composes: '$icon',
    [MOBILE_UP()]: {
      paddingLeft: '0.5rem',
      display: 'none'
    }
  },
  filler: {
    [MOBILE_UP()]: {
      padding: '0.4em'
    }
  },
  text: {
    margin: '0 auto'
  },
  value: {},
  pagination: {
    margin: ['2rem', 'auto'],
    width: '100%',
    '& >div': {
      margin: 'auto'
    }
  },
  errorMessage: {
    textAlign: 'center'
  }
}

export default styles
