import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts'
import { getColorGradient } from 'utils/common.utils'

const HorizontalBarChart = ({ barSize, color, data, labelPosition, Label }) => {
  const dataStore = [{ name: 'CardsUsed', ...data }]
  let total = 0
  const { name, ...graphData } = dataStore[0]
  const keys = Object.keys(graphData)
  for (const [, value] of Object.entries(graphData)) {
    total += value
  }
  const length = keys.length

  // Workaround to get the custom label working with animated bar chart
  //https://github.com/recharts/recharts/issues/929
  const [state, setstate] = useState(true)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setstate(false)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <ResponsiveContainer>
      <BarChart data={dataStore} layout="vertical" margin={{ top: 0, right: 20, left: 20, bottom: 0 }}>
        <XAxis type="number" hide={true} />
        <YAxis dataKey="name" type="category" hide={true} />
        {keys.map((key, index) => {
          const backgroundColor = getColorGradient(color, index, length)
          return (
            <Bar
              dataKey={key}
              stackId="a"
              fill={backgroundColor}
              key={index}
              barSize={barSize}
              isAnimationActive={state}
            >
              {Label && (
                <LabelList
                  dataKey={key}
                  position={labelPosition}
                  content={props => {
                    return <Label {...{ ...props, total, index, stroke: color }} />
                  }}
                />
              )}
            </Bar>
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

HorizontalBarChart.prototype = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  barSize: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  Label: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default React.memo(HorizontalBarChart)
