import React from 'react'
import withStyles from 'react-jss'
import FilterSection from '../FilterSection/FilterSection'
import styles from './StorePerformance.styles'
import StorePerformanceHeader from './StorePerformanceHeader/StorePerformanceHeader'
import StoreRevenueSummaryCard from './StoreRevenueSummaryCard/StoreRevenueSummaryCard'
import StoreSalesSummaryCard from './StoreSalesSummaryCard/StoreSalesSummaryCard'
import StoreRevenueCard from './StoreRevenueCard/StoreRevenueCard'
import StoreSalesCard from './StoreSalesCard/StoreSalesCard'
import StoreCustomersCard from './StoreCustomersCard/StoreCustomersCard'
import StoreCardsUsedCard from './StoreCardsUsedCard/StoreCardsUsedCard'
import usePrimaryStorePerformanceActions from './useStorePerformanceActions'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'

const StorePerformance = ({ classes }) => {
  const { isSingleStore } = usePrimaryStorePerformanceActions()
  const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)

  return (
    <div className={classes['container']}>
      <div className={classes['filterSection']}>
        <FilterSection comparePeriod compareStore />
      </div>
      <div className={classes['storeSection']}>
        <div className={classes['storeHeader']}>
          <StorePerformanceHeader />
        </div>
        <div className={classes['graphs']}>
          {isSingleStore && (
            <div className={'revenue'}>
              <StoreRevenueSummaryCard />
            </div>
          )}
          {isSingleStore && (
            <div className={'sales'}>
              <StoreSalesSummaryCard />
            </div>
          )}
          <div className={'revenueGraph'}>
            <StoreRevenueCard />
          </div>
          <div className={'salesGraph'}>
            <StoreSalesCard />
          </div>
          <div className={'customersGraph'}>
            <StoreCustomersCard />
          </div>
          {cardsUsedFeatureFlag && (
            <div className={'cardsGraph'}>
              <StoreCardsUsedCard />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(withStyles(styles)(StorePerformance))
