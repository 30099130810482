import React, { useState, useCallback } from 'react'
import styles from './MyAccount.styles'
import withStyles from 'react-jss'
import Avatar from '../common/Avatar'
import RoleInfo from './RoleInfo'
import useMyAccount from './useMyAccount'
import PersonalInformationForm from './PersonalInformationForm'
import UpdatePasswordForm from './UpdatePasswordForm'
import AccountStats from './AccountStats'
import api from 'api'
import { accountSlice, usersSlice } from 'reduxSlices'
import { useDispatch, useSelector } from 'react-redux'
import Message from 'components/Message/Message'
import { ROLE_OWNER } from 'constants/general.constants'
import { getInitials, getInsightsRole } from 'utils/user.utils'

const MyAccount = ({ classes }) => {
  const { user, stores } = useMyAccount()
  const initials = getInitials(user)
  const { actions: accountActions } = accountSlice
  const { selectors: usersSelectors, actions: usersActions } = usersSlice

  const { roles } = user
  const insightsRole = getInsightsRole(roles)
  const users = useSelector(usersSelectors.getAllUsers)
  const [passworChangedEnabled, setPassworChangedEnabled] = useState(false)
  const [passwordChangeSuccess, setPasswordChange] = useState(false)

  const dispatch = useDispatch()
  const setUerAction = useCallback(latestUserInfo => dispatch(accountActions.setUser(latestUserInfo)), [
    dispatch,
    accountActions
  ])

  const fetchAllUsersActions = useCallback(() => dispatch(usersActions.fetchAllUsers()), [dispatch, usersActions])

  const handleUpdateUserInfo = async values => {
    const updatedUser = { ...user, ...values }
    const updatedUserResponse = await api.updatePersonalInformation(updatedUser)

    const latestUserInfo = await api.getUserInformation()
    setUerAction(latestUserInfo)

    dispatch(fetchAllUsersActions)

    return updatedUserResponse
  }

  const showPasswordChange = () => {
    setPassworChangedEnabled(true)
  }

  const personalInformationParams = {
    user,
    updateAccount: handleUpdateUserInfo,
    showPasswordChange,
    isFormDisabled: passworChangedEnabled
  }

  const updatePasswordParams = {
    updatePassword: async values => {
      await api.updatePassword(values)
      setPasswordChange(true)
      setPassworChangedEnabled(false)
      setTimeout(() => setPasswordChange(false), 10000)
    },
    cancelPasswordChange: () => {
      setPassworChangedEnabled(false)
    }
  }

  return (
    <div className={classes['myAccount']}>
      <div className={classes['roleSection']}>
        <div className={classes['wrapper']}>
          <div className={classes['role']}>
            <div className={classes['avatar']} data-hj-suppress>
              <Avatar title={initials} role={insightsRole} />
            </div>
            <div className={classes['roleInfo']} data-hj-suppress>
              <RoleInfo user={user} />
            </div>
          </div>
          <div className={classes['counts']}>
            <AccountStats users={users} stores={stores} isOwner={insightsRole === ROLE_OWNER} />
          </div>
        </div>
      </div>
      <div className={classes['formSection']}>
        <PersonalInformationForm {...personalInformationParams} />
        {passworChangedEnabled && <UpdatePasswordForm {...updatePasswordParams} />}

        <div>
          {passwordChangeSuccess && (
            <Message text="Your password has been updated." variant="success" showIcon={false} />
          )}
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(MyAccount)
