import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import oeJSS from './Label-oe.styles'
import defaultJSS from './Label.styles'
import Text from '../Text/Text'

/**
 * Label component
 */
const BaseLabel = ({ classes, htmlFor, label, note, errorMessage }) => {
  const labelClassName = errorMessage ? classes[' label-error'] : classes['label']

  return (
    <div className={labelClassName}>
      <label htmlFor={htmlFor}>{label}</label>
      {note && <Text variant="title-note">{note}</Text>}
    </div>
  )
}

const DefaultLabel = withStyles(defaultJSS)(BaseLabel)

const OeLabel = withStyles(oeJSS)(BaseLabel)

const Label = ({ preset, ...props }) => {
  switch (preset) {
    case 'oe':
      return <OeLabel {...props} />
    default:
      return <DefaultLabel {...props} />
  }
}

Label.propTypes = {
  preset: PropTypes.oneOf(['default', 'oe']),
  override: PropTypes.shape({
    'label-error': PropTypes.object
  }),
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  note: PropTypes.string,
  errorMessage: PropTypes.string
}

Label.defaultProps = {
  errorMessage: undefined,
  override: {},
  preset: 'default'
}

export default Label
