const styles = {
  title: {
    '& > hr': {
      margin: '1rem 0 2rem 0'
    }
  },
  body: {
    '& > h5': {
      marginTop: '1.25rem'
    }
  },
  note: {
    marginTop: '1.25rem'
  },
  footerItem: {
    margin: '1.25rem 0'
  }
}

export default styles
