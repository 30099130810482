import * as mediaQueryHelper from 'config/mediaQueryHelper'
import Text from 'components/Text/Text'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'
import withStyles from 'react-jss'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import { Cell, Pie, PieChart } from 'recharts'
import CardDetailsPopoverContent from './CardDetailsPopoverContent/CardDetailsPopoverContent'
import styles, { popoverMenuStyles } from './CardsPieChart.styles'
import { getColorGradient } from 'utils/common.utils'
import { formatNumber } from 'utils/number.utils'

const { BREAK_POINT_LARGE } = mediaQueryHelper

const CardsPieChart = ({ baseColor, data, classes }) => {
  const [showPopover, setShowPopover] = useState(null)

  const handleOpenPopover = (e, entry) => {
    e.preventDefault()
    setShowPopover(entry)
  }

  const scrollListner = e => {
    handleOpenPopover(e, null)
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', scrollListner)
    return () => {
      window.removeEventListener('scroll', scrollListner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { cards, totalCount } = data
  const position = window.innerWidth < BREAK_POINT_LARGE ? 'top' : 'left'
  const pieHeight = 160
  const displayCount = totalCount < 1000 ? totalCount : `${formatNumber(totalCount, true)}`

  const pieOptions = {
    data: cards,
    dataKey: 'value',
    cx: 75,
    cy: 75,
    innerRadius: 50,
    outerRadius: 75,
    fill: baseColor,
    paddingAngle: 1
  }

  const PopoverContent = ({ position, targetRect, popoverRect }) => {
    const { arrowColor, arrowSize, arrowStyle, padding } = popoverMenuStyles
    const arrowcontinerProps = { position, arrowColor, arrowSize, arrowStyle, padding }
    return (
      <ArrowContainer position={position} targetRect={targetRect} popoverRect={popoverRect} {...arrowcontinerProps}>
        <CardDetailsPopoverContent
          data={showPopover}
          classes={classes}
          onClose={e => {
            handleOpenPopover(e, null)
          }}
        />
      </ArrowContainer>
    )
  }
  return (
    <Popover
      isOpen={showPopover !== null}
      position={position} // preferred position
      content={PopoverContent}
    >
      <div
        className={classes['content']}
        onMouseLeave={e => {
          handleOpenPopover(e, null)
        }}
      >
        <PieChart width={pieHeight} height={pieHeight}>
          <Pie {...pieOptions}>
            {cards.map((entry, index) => {
              const backgroundColor = getColorGradient(baseColor, index, cards.length)
              return (
                <Cell
                  fill={backgroundColor}
                  key={index}
                  onMouseEnter={e => {
                    handleOpenPopover(e, entry)
                  }}
                  onClick={e => {
                    handleOpenPopover(e, entry)
                  }}
                />
              )
            })}
          </Pie>
        </PieChart>
        <div className={classes['totalCountContainer']}>
          <div className={classes['totalCount']}>
            <Text variant="h2">{displayCount}</Text>
          </div>
          <div className={classes['label']}>
            <Text variant="label">{'Count'}</Text>
          </div>
        </div>
      </div>
    </Popover>
  )
}

CardsPieChart.prototype = {
  baseColor: PropTypes.string.isRequired,
  data: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(styles)(CardsPieChart)
