import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import merge from 'lodash/merge'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import Message from '../Message/Message'
import Icon from '../Icon/Icon'
import * as colors from 'config/colors'
import styles from './Select.styles'
import reactSelectStyles from './ReactSelect.styles'
import Label from '../Label/Label'

/**
 * Wrapper Select component on react-select.
 */
const Select = ({
  options,
  defaultValue,
  value,
  isMulti,
  placeholder,
  errorMessage,
  async,
  asyncOptions,
  defaultOptions,
  onChange,
  onBlur,
  isClearable,
  isDisabled,
  isSearchable,
  isLoading,
  label,
  id,
  children,
  classes,
  icon,
  reactSelectOverrride
}) => {
  const defaultProps = {
    className: classes['react-select-container'],
    classNamePrefix: 'react-select',
    defaultOptions,
    isMulti,
    placeholder,
    onChange,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    value,
    onBlur,
    isMenuOpen: true
  }

  const SingleValue = ({ children, innerProps }) => (
    <div {...innerProps}>
      <div className={classes['selectedValue']}>
        {icon} <span classes={classes['selectedText']}>{children} </span>
      </div>
    </div>
  )

  const DropdownIndicator = ({ innerProps, selectProps }) => {
    const name = selectProps.menuIsOpen ? 'arrowUp' : 'arrowDown'
    return (
      <div {...innerProps}>
        <Icon name={name} width={'1.8em'} color={colors.icon} />
      </div>
    )
  }

  const reactSelectProps = {
    ...defaultProps,
    options,
    placeholder,
    onChange,
    defaultValue,
    components: { SingleValue, DropdownIndicator }
  }

  const asyncSelectProps = {
    ...defaultProps,
    cacheOptions: true,
    loadOptions: asyncOptions
  }

  const mergedReactSelectStyles = merge(reactSelectStyles, reactSelectOverrride)

  return (
    <div className={classes['group']}>
      {label && <Label htmlFor={id} label={label} />}
      {async ? (
        <AsyncSelect styles={mergedReactSelectStyles} {...asyncSelectProps} />
      ) : (
        <ReactSelect styles={mergedReactSelectStyles} {...reactSelectProps} />
      )}
      {errorMessage && (
        <div className={classes['error-message']}>
          <Message text={errorMessage} variant={'error'} showIcon={false} />{' '}
        </div>
      )}
      {children}
    </div>
  )
}

Select.propTypes = {
  /**
   * Pass the label to be shown
   */
  label: PropTypes.string,

  id: PropTypes.string,
  overrride: PropTypes.shape({
    group: PropTypes.object,
    'error-message': PropTypes.object,
    selectedValue: PropTypes.object
  }),
  /**
   * Shape of override expected. Any additional classes will be ignored.
   *  Refer https://react-select.com/styles#style-object for the component structure.
   */
  reactSelectOverrride: PropTypes.shape({
    clearIndicator: PropTypes.func,
    container: PropTypes.func,
    control: PropTypes.func,
    dropdownIndicator: PropTypes.func,
    group: PropTypes.func,
    groupHeading: PropTypes.func,
    indicatorsContainer: PropTypes.func,
    indicatorSeparator: PropTypes.func,
    input: PropTypes.func,
    loadingIndicator: PropTypes.func,
    loadingMessage: PropTypes.func,
    menu: PropTypes.func,
    menuList: PropTypes.func,
    menuPortal: PropTypes.func,
    multiValue: PropTypes.func,
    multiValueLabel: PropTypes.func,
    multiValueRemove: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    option: PropTypes.func,
    placeholder: PropTypes.func,
    singleValue: PropTypes.func,
    valueContainer: PropTypes.func
  }),

  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),

  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Initial value of the control
   */
  defaultValue: PropTypes.object || PropTypes.array,
  //value: PropTypes.object || PropTypes.array,
  /**
   * Allow the user to select multiple values
   */
  isMulti: PropTypes.bool,
  /**
   * Change handler
   */
  onChange: PropTypes.func,
  /**
   * on Blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Disabled Property
   */
  isDisabled: PropTypes.bool,

  isSearchable: PropTypes.bool,

  isLoading: PropTypes.bool,

  isClearable: PropTypes.bool,
  /**
   * Error Message to be displayed
   */
  errorMessage: PropTypes.string,
  /**
   *
   */
  children: PropTypes.element,

  /**
   * Async mode with Promises
   */
  async: PropTypes.bool,

  /**
   * Only use when async is passed as true. The function should return a promise.
   */
  asyncOptions: PropTypes.func,

  icon: PropTypes.any
}

Select.defaultProps = {
  async: false,
  isDisabled: false,
  isSearchable: false,
  override: {},
  reactSelectOverrride: {},
  isClearable: false
}

Select.displayName = 'Select'

export default withStyles(styles)(Select)
