import React from 'react'
import Text from 'components/Text/Text'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_CURRENT_PERIOD_CARDS_COLOR } from 'constants/colors.contants'
import CurrentDataLabel from './CurrentDataLabel'
import objstr from 'obj-str'

const CurrentPeriodGraph = ({ classes, isEmpty, data }) => {
  const className = objstr({
    [classes['currentDataGraph']]: true,
    [classes['isCurrentDataGraphEmpty']]: isEmpty
  })

  return (
    <div className={className}>
      {!isEmpty ? (
        <HorizontalBarChart
          {...{
            barSize: 40,
            color: GRAPH_CURRENT_PERIOD_CARDS_COLOR,
            data,
            labelPosition: 'top',
            Label: CurrentDataLabel
          }}
        />
      ) : (
        <Text variant="h5">There are no transactions for the selected time period.</Text>
      )}
    </div>
  )
}

export default CurrentPeriodGraph
