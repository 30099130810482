const styles = {
  modalHeader: {
    alignItems: 'center',
    marginBottom: '1em',
    display: 'flex',
    '& > h2': {
      marginBottom: 0
    }
  },
  helpIcon: {
    marginRight: '0.5em'
  }
}

export default styles
