import React from 'react'
import { connect, getIn } from 'formik'
import Radio from 'components/Radio/Radio'

/**
 * Formik wrapper on the Radio component. Refer Radio component for props.
 */
const FormikRadio = props => {
  const { name, id, formik, label, ...rest } = props
  const { handleChange, handleBlur } = formik
  const value = getIn(formik.values, name)

  const radioProps = {
    name,
    id: id,
    label,
    value: id,
    onChange: handleChange,
    onBlur: handleBlur,
    checked: id === value,
    ...rest
  }

  return <Radio {...radioProps} />
}

export default connect(FormikRadio)
