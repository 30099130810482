import { line1 } from 'config/colors'

const styles = {
  'accordion-item': {
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0
    },
    extend: ({ override }) => ({
      ...override['accordion-item']
    })
  },
  'accordion-item-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderBottom: {
      width: '1px',
      style: 'solid',
      color: line1
    },
    '& > h5': {
      marginBottom: '0'
    },
    extend: ({ override }) => ({
      ...override['accordion-item-header']
    })
  },
  'accordion-item-header-actions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button': {
      height: 'auto'
    },
    extend: ({ override }) => ({
      ...override['accordion-item-header-actions']
    })
  },
  'accordion-item-panel': {
    padding: '1rem',
    cursor: 'default',
    extend: ({ override }) => ({
      ...override['accordion-item-panel']
    })
  },
  card: {
    background: 'rgb(250, 250, 251)',
    padding: '0',
    cursor: 'pointer',
    '& + &': {
      borderBottom: 'none'
    }
  },
  'card-footer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 10
    }
  }
}

export default styles
