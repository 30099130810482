import React from 'react'
import withStyles from 'react-jss'
import styles from './CustomersCard.styles'
import ReturnCustomers from './ReturnCustomers'
import NewCustomers from './NewCustomers'
import { DashboardCard } from 'components/common'
import useReturnCustomers from 'reduxSlices/dashboardSlice/hooks/useReturnCustomers'
import CustomerCardLoader from './CustomerCardLoader'
import NoTransactionsMessage from 'components/BusinessPerformance/CustomersCard/NoTransactionsMessage'
import CurrentPeriodGraph from './CurrentPeriodGraph'
import PreviousPeriodGraph from './PreviousPeriodGraph'

const CARDS_USED = {
  title: 'Customer Loyalty',
  iconName: 'customer',
  height: '165px',
  isResizeable: false
}

const getPercentages = data => {
  const { repeatCustomerPercentage, newCustomerPercentage } = data

  return {
    repeatCustomerPercentage,
    newCustomerPercentage
  }
}

const CustomersCard = ({ classes }) => {
  const { allReturnCustomers, isComparePeriod } = useReturnCustomers()
  const { data, isLoading } = allReturnCustomers

  if (isLoading) {
    return (
      <DashboardCard {...CARDS_USED}>
        <CustomerCardLoader />
      </DashboardCard>
    )
  }

  const {
    isNoTransactions,
    returnCustomersGrowth,
    isCurrentGraphEmpty,
    current,
    isPreviousGraphEmpty,
    previous,
    newCustomersGrowth
  } = data

  const currentGraphData = getPercentages(current)
  const previousGraphData = getPercentages(previous)

  const isGrowthVisible = isComparePeriod && (!isPreviousGraphEmpty && !isCurrentGraphEmpty)
  return (
    <DashboardCard {...CARDS_USED}>
      <div className={classes['customersCard']} data-hj-suppress>
        <div className={classes['content']}>
          {!isNoTransactions ? (
            <>
              <div className={classes['newCustomers']}>
                <ReturnCustomers {...{ classes, value: returnCustomersGrowth, isGrowthVisible }} />
              </div>

              <div className={classes['graphs']}>
                <CurrentPeriodGraph {...{ classes, isCurrentGraphEmpty, currentGraphData: currentGraphData }} />
                {isComparePeriod && (
                  <PreviousPeriodGraph {...{ classes, isPreviousGraphEmpty, previousGraphData: previousGraphData }} />
                )}
              </div>

              <div className={classes['newCustomers']}>
                <NewCustomers {...{ classes, value: newCustomersGrowth, isGrowthVisible }} />
              </div>
            </>
          ) : (
            <NoTransactionsMessage {...{ classes }} />
          )}
        </div>
      </div>
    </DashboardCard>
  )
}

export default React.memo(withStyles(styles)(CustomersCard))
