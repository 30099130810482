const transactionTypes = [
  {
    title: 'Purchase',
    description: 'A payment for goods or services.'
  },
  {
    title: 'Refund',
    description: 'A return of money paid when goods or services are returned.'
  },
  {
    title: 'Pre-Auth',
    description:
      'Reservation of a portion of the cardholder’s funds, holding this balance as unavailable until either the transaction is cleared or completed.'
  },
  {
    title: 'Completion',
    description:
      'Completion of a pre-authorisation, when the amount for the goods or service actually used are charged to the customer.'
  },
  {
    title: 'Payment',
    description:
      'A transaction that enables the cardholder to make a deposit without purchase. This is only available on terminals that have a ‘Payment’ button. Only certain cards are permitted, and it cannot be performed via Contactless or offline.'
  },
  {
    title: 'Enquiry',
    description:
      'A transaction that requests available funds of the account specified by the cardholder. This is only available on terminals that have ‘balance enquiry’ button, and cannot be performed via Contactless or offline.'
  },
  {
    title: 'Payment/Refund Reversal',
    description:
      'Reversal of a pending payment or refund when the transaction fail to finalise i.e. customer removes card before a transaction is completed.'
  },
  {
    title: 'Other',
    description:
      'Unclassified – Contact Worldline for details if any transaction is shown with unclassified transaction type that is not listed above.'
  }
]

export default transactionTypes
