import React from 'react'
import withStyles from 'react-jss'
import styles from './AllStoresCommentary.styles'
import {
  GRAPH_REVENUE_COLOR,
  GRAPH_SALES_COLOR,
  GRAPH_CURRENT_PERIOD_COLOR,
  GRAPH_CURRENT_PERIOD_CARDS_COLOR,
  GRAPH_PREVIOUS_PERIOD_COLOR
} from 'constants/colors.contants'
import { CommentarySection, Legends } from 'components/common'
import useTopStores from 'reduxSlices/dashboardSlice/hooks/useTopStores'
import { getCurrency, formatNumber, formatPercentage } from 'utils/number.utils'
import useReturnCustomers from 'reduxSlices/dashboardSlice/hooks/useReturnCustomers'
import useCardsUsed from 'reduxSlices/dashboardSlice/hooks/useCardsUsed'
import _ from 'lodash'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'

const getRevenueText = (topStore, totalRevenue, totalSales) => {
  if (!totalSales) return 'There are no transactions for this time period.'

  const revenue = getCurrency(totalRevenue, false, false)

  const topStoreName = topStore && topStore.displayName

  const revenueText = `Your revenue is ${revenue}.`
  const topStoreText = topStoreName ? ` Your top performing store by revenue is ${topStoreName}.` : ''

  return `${revenueText}${topStoreText}`
}

const getSalesText = (totalSales, topStoreBySales) => {
  if (!totalSales) return ''

  const sales = formatNumber(totalSales, false, false)

  const topStoreBySalesName = topStoreBySales && topStoreBySales.displayName

  const salesText = `Sales total is ${sales}.`
  const topSalesText = topStoreBySalesName ? ` Your top performing store by sales is ${topStoreBySalesName}.` : ''

  return `${salesText}${topSalesText}`
}

const getAverageSalesText = (totalRevenue, totalSales) => {
  if (!totalSales || !totalRevenue) return ''
  let averageSales = totalRevenue === 0 ? 0 : totalSales / totalRevenue
  averageSales = getCurrency(averageSales)
  return `Your average sales value is ${averageSales}.`
}

const getRepeatCustomersText = (allReturnCustomers, topStoreByRepeatCustomers) => {
  const returnCustomer = _.get(allReturnCustomers, 'data.current.repeatCustomerPercentage') || 0
  const newCustomers = _.get(allReturnCustomers, 'data.current.newCustomerPercentage') || 0

  if (!returnCustomer && !newCustomers) {
    return
  }

  const topStoreNameByRepeatCustomers = topStoreByRepeatCustomers && topStoreByRepeatCustomers.displayName
  const newCustomerText =
    newCustomers > 0 ? `${returnCustomer > 0 ? ' vs' : ''} ${formatPercentage(newCustomers / 100)} new customers` : ''
  const returnCustomerText = returnCustomer > 0 ? `${formatPercentage(returnCustomer / 100)} returning customers` : ''

  const repeatCustomer = `Your business has ${returnCustomerText}${newCustomerText}.`
  const topRepeatCustomerText = topStoreNameByRepeatCustomers
    ? ` ${topStoreNameByRepeatCustomers} has the highest number of returning customers of all your stores.`
    : ''

  return `${repeatCustomer}${topRepeatCustomerText}`
}

const getCardsUsedText = topCardUsed => {
  if (_.isEmpty(topCardUsed)) return

  const { group, percentage } = topCardUsed
  const formattedPercentage = formatPercentage(percentage / 100)

  return `${group} is ${formattedPercentage} of all card transactions for your business.`
}

const Commentry = React.memo(({ revenueText, salesText, averageSalesText, repeatCustomersText, cardsUsedText }) => {
  return (
    <>
      <CommentarySection> {revenueText} </CommentarySection>
      <CommentarySection> {salesText} </CommentarySection>
      <CommentarySection> {averageSalesText} </CommentarySection>
      {repeatCustomersText && <CommentarySection> {repeatCustomersText} </CommentarySection>}
      {cardsUsedText && <CommentarySection> {cardsUsedText} </CommentarySection>}
    </>
  )
})

const AllStoresCommentary = ({ classes }) => {
  const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)
  const { topStore, totalRevenue, totalSales, topStoreBySales, topStoreByRepeatCustomers, isLoading } = useTopStores()
  const { topCardUsed, isComparePeriod } = useCardsUsed()
  const { allReturnCustomers } = useReturnCustomers()

  let revenueText,
    salesText,
    averageSalesText,
    repeatCustomersText,
    cardsUsedText = ''
  const isShowData = !isLoading && totalSales

  if (isShowData) {
    revenueText = getRevenueText(topStore, totalRevenue, totalSales)
    salesText = getSalesText(totalSales, topStoreBySales)
    averageSalesText = getAverageSalesText(totalSales, totalRevenue)
    repeatCustomersText = getRepeatCustomersText(allReturnCustomers, topStoreByRepeatCustomers)
    cardsUsedText = cardsUsedFeatureFlag ? getCardsUsedText(topCardUsed) : ''
  } else {
    revenueText = 'There are no transactions for this time period.'
  }

  return (
    <div className={classes['content']}>
      <div className={classes['commentary']}>
        {!isLoading ? (
          <Commentry {...{ revenueText, salesText, averageSalesText, repeatCustomersText, cardsUsedText }} />
        ) : (
          <>
            <CommentarySection isLoading />
            <CommentarySection isLoading />
          </>
        )}
      </div>

      {isComparePeriod && (
        <div className={classes['legends']}>
          <Legends
            {...{
              title: 'Selected time period',
              colors: [
                GRAPH_REVENUE_COLOR,
                GRAPH_SALES_COLOR,
                GRAPH_CURRENT_PERIOD_COLOR,
                GRAPH_CURRENT_PERIOD_CARDS_COLOR
              ]
            }}
          />
          <Legends
            {...{
              title: 'Previous time period',
              colors: [GRAPH_PREVIOUS_PERIOD_COLOR]
            }}
          />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(AllStoresCommentary)
