import * as colors from 'config/colors'
import { standardFontFamily, weightRegular } from 'config/jss-vars'

const styles = {
  timePicker: {
    display: 'flex'
  },
  startTime: {
    flex: 1,
    display: 'flex'
  },
  endTime: {
    flex: 1,
    display: 'flex'
  },
  timeIcon: {
    position: 'absolute',
    margin: '10px 2px 0 14px'
  },
  input: {
    width: '100%',
    color: colors.greyDark,
    fontFamily: standardFontFamily,
    fontWeight: weightRegular,
    fontSize: '1rem',
    border: `1px solid ${colors.line1}`,
    height: '2.5rem',
    backgroundColor: colors.background1,
    padding: '0.5rem 0.5rem 0.5rem 2.5rem',
    transition: 'border-bottom-color 0.3s',
    '::placeholder': {
      color: colors.textPlaceholder
    },
    '&:hover': {
      borderBottom: `1px solid ${colors.teal}`
    },
    '&:active': {
      borderBottom: `1px solid ${colors.teal}`
    },
    '&:focus': {
      outline: 'none',
      borderBottom: `1px solid ${colors.teal}`
    }
  },
  startTimeInput: {
    composes: '$input',
    borderRadius: ['3px', 0, 0, '3px'],
    borderRight: 0
  },
  endTimeInput: {
    composes: '$input',
    borderRadius: [0, '3px', '3px', 0],
    borderLeft: 0
  }
}

export default styles
