import { warningBackground } from 'config/colors'
import { LAPTOP_UP } from 'config/mediaQueryHelper'

const styles = {
  box: {
    display: 'flex',
    padding: '15px',
    backgroundColor: warningBackground,
    [LAPTOP_UP()]: {
      padding: '16px 25px'
    }
  },
  content: {
    flex: 1,
    '& >p': {
      fontSize: '14px !important'
    }
  },
  icon: {
    flexBasis: '1.5em',
    cursor: 'pointer',
    '& :hover': {
      opacity: 0.8
    }
  }
}

export default styles
