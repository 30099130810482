import React from 'react'
import { ImprovementPercentage } from 'components/common'
import { isTabletWidth } from 'utils/common.utils'
import Text from 'components/Text/Text'
import ContentLoader from 'react-content-loader'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import withStyles from 'react-jss'
import styles from './StoreCardGraphLayout.styles'

const LoadingIndicator = () => {
  const width = isTabletWidth() ? 600 : 200

  return (
    <ContentLoader height={90} width={width} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="3" ry="3" width={0.35 * width} height="10" />
      <rect x={0.4 * width} y="0" rx="3" ry="3" width={0.5 * width} height="10" />
      <rect x="0" y="20" rx="3" ry="3" width={0.65 * width} height="10" />
      <rect x={0.7 * width} y="20" rx="3" ry="3" width={0.65 * width} height="10" />
      <rect x="0" y="40" rx="3" ry="3" width={0.45 * width} height="10" />
      <rect x={0.6 * width} y="40" rx="3" ry="3" width={0.3 * width} height="10" />
      <rect x="0" y="60" rx="3" ry="3" width={0.15 * width} height="10" />
    </ContentLoader>
  )
}

const StoreCardTotalTitle = ({ classes, totalColor, total, previousPeriodTotal, percentageChange, isLoading }) => {
  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <div className={classes['totals']} data-hj-suppress>
      <div className={classes['currencyValue']}>
        {total && (
          <Text variant="h1" color={totalColor}>
            {total}
          </Text>
        )}
        {previousPeriodTotal !== undefined && (
          <Text variant="h2" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
            {previousPeriodTotal}
          </Text>
        )}
      </div>
      <div className={classes['changeValue']}>
        {previousPeriodTotal !== undefined && <ImprovementPercentage value={percentageChange} />}
      </div>
    </div>
  )
}

export default withStyles(styles)(StoreCardTotalTitle)
