import { useSelector, useDispatch } from 'react-redux'
import { dashboardSlice, storeSlice } from 'reduxSlices'
import { useCallback } from 'react'

const useSalesRevenueAgg = () => {
  const { actions, selectors: businessSelectors } = dashboardSlice
  const { selectors: storeSelectors } = storeSlice

  const dispatch = useDispatch()

  // Business Selectors
  const allSalesRevenueAgg = useSelector(businessSelectors.getAllSalesRevenueAgg)
  const isComparePeriod = useSelector(businessSelectors.isComparePeriod)
  const getExpandedCard = useSelector(businessSelectors.getExpandedCard)

  // Store Selectors
  const primaryStoreSalesRevenueAgg = useSelector(storeSelectors.getPrimaryStoreSalesRevenueAgg)
  const secondaryStoreSalesRevenueAgg = useSelector(storeSelectors.getSecondaryStoreSalesRevenueAgg)

  // Business Actions
  const expandCardAction = useCallback(card => dispatch(actions.expandCard(card)), [dispatch, actions])
  const compressCardsAction = useCallback(() => dispatch(actions.compressCards()), [dispatch, actions])

  return {
    allSalesRevenueAgg,
    isComparePeriod,
    expandCardAction,
    compressCardsAction,
    getExpandedCard,
    primaryStore: primaryStoreSalesRevenueAgg,
    secondaryStore: secondaryStoreSalesRevenueAgg
  }
}

export default useSalesRevenueAgg
