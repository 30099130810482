import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './configureStore'
import Routes from './Routes'
import { getConfig } from 'utils/config.utils'
import { hotjar } from 'utils/hotjar.utils'

const HOT_JAR_VERSION = 6
const { hotJarToken } = getConfig()
hotjar(hotJarToken, HOT_JAR_VERSION)
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    )
  }
}

export default App
