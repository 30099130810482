import { connect } from 'react-redux'
import { accountSlice, settlementSlice } from '../../reduxSlices'
import { bindActionCreators } from 'redux'

const settlementFilterState = state => ({
  merchants: accountSlice.selectors.getMerchantAccess(state),
  terminalSummary: settlementSlice.selectors.getTerminalSummary(state),
  merchantSummary: settlementSlice.selectors.getMerchantSummary(state),
  filter: settlementSlice.selectors.getFilter(state),
  is504: settlementSlice.selectors.is504(state),
  isLoading: settlementSlice.selectors.isLoading(state),
  isShowResult: settlementSlice.selectors.isShowResult(state)
})

const mapStateToProps = state => ({
  ...settlementFilterState(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...settlementSlice.actions
    },
    dispatch
  )
})

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component)
