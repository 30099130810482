import React, { useState } from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import Modal from 'components/Modal/Modal'
import withStyles from 'react-jss'
import styles from './StoreDeleteModal.styles'
import * as colors from 'config/colors'
import apis from 'api'
import { EVENT_DELETE_STORE } from 'constants/mixpanel.constants'

const ModalContent = ({ classes }) => {
  return (
    <div className={classes['modalContent']}>
      <Text variant="body" color={colors.greyDark}>
        To delete this store, please send us a note at support@paymark.co.nz or give us a call on
        <br />
        0800 729 627
      </Text>
    </div>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

const StoreDeleteModal = ({ merchantId, classes }) => {
  const { Mixpanel: mixpanel } = apis

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    mixpanel.track(EVENT_DELETE_STORE, { UserMerchantID: merchantId })

    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={classes['wrapper']} onClick={openModal}>
        <Icon name="clear-filled" />
        <div className={classes['text']}>
          <Text color={colors.cyan} variant="body">
            Delete Store
          </Text>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} size={'small'}>
        <StyledModalContent closeModal={closeModal} />
      </Modal>
    </>
  )
}

export default withStyles(styles)(StoreDeleteModal)
