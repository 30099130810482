import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
import * as variables from 'config/jss-vars'
const { LARGE_SCREEN_UP, LAPTOP_UP, MOBILE_UP } = mediaQueryHelper

const customSettingLabel = {
  display: 'flex',
  height: '2rem',
  margin: 'auto',
  cursor: 'pointer',
  justifyContent: 'center',
  '& .label': {
    marginTop: '11px',
    fontSize: '1rem',
    lineHeight: '1',
    color: colors.cyan,
    fontWeight: variables.weightMedium,
    width: '180px'
  },
  [MOBILE_UP()]: {
    justifyContent: 'flex-start'
  }
}

const customSettings = {
  background: colors.background2,
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${colors.cyan}`,
  borderTop: `1px solid ${colors.cyan}`,
  transition: 'all 1s ease',
  padding: '1rem',
  [LAPTOP_UP()]: {
    padding: ['1.5rem', '2rem', '3rem']
  }
}

const section = {
  display: 'flex',
  flexDirection: 'column',
  padding: ['1rem', 0],
  [MOBILE_UP()]: {
    flexDirection: 'row',
    padding: ['0.85rem', 0]
  }
}

const styles = {
  mainFilter: {
    display: 'flex',
    flexDirection: 'column',
    padding: [0, '1rem', '1rem'],
    [LAPTOP_UP()]: {
      padding: [0, '2rem']
    }
  },
  dateSection: {
    ...section,
    borderTop: `1px solid ${colors.line2}`
  },
  timePeriodSection: {
    ...section,
    borderBottom: `1px solid ${colors.line2}`
  },
  purchaseAmountSection: {
    composes: '$timePeriodSection',
    paddingBottom: '0'
  },
  last4DigitsSections: {
    composes: '$purchaseAmountSection'
  },
  terminalsSection: {
    composes: '$purchaseAmountSection'
  },
  cardTypeSection: {
    composes: '$purchaseAmountSection'
  },
  transactionTypeSection: {
    composes: '$purchaseAmountSection',
    display: 'flex',
    '& * .icon': {
      margin: [0, 0, 0, '10px'],
      [MOBILE_UP()]: {
        margin: [0, 0, 0, '10px']
      }
    }
  },
  statusSection: {
    composes: '$purchaseAmountSection',
    marginBottom: '1rem',
    [LARGE_SCREEN_UP()]: {
      marginBottom: 0
    }
  },
  purchaseAmount: {
    width: '100%',
    padding: '0 1rem 0 0',
    [MOBILE_UP()]: {
      '& * input ': {
        width: 'calc(50% - 10px)',
        minWidth: '250px'
      }
    }
  },
  last4Digits: {
    composes: '$purchaseAmount'
  },
  terminal: {
    composes: '$purchaseAmount'
  },
  storeSection: {
    ...section
  },
  label: {
    flexBasis: '25px',
    display: 'flex',
    [MOBILE_UP()]: {
      flexBasis: '140px',
      marginTop: '0.5rem'
    },
    [LARGE_SCREEN_UP()]: {
      flexBasis: '200px'
    },
    '& * .icon': {
      cursor: 'pointer'
    }
  },
  content: {
    flex: [1, 1, 'auto']
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    margin: [0, '1rem'],
    borderTop: `1px solid ${colors.line2}`,
    '& .customSettingControl': {
      flex: [1, 1, 'auto'],
      paddingBottom: '1.5rem',
      [MOBILE_UP()]: {
        paddingBottom: '0'
      },
      borderBottom: `1px solid ${colors.line2}`
    },
    '& .button': {
      width: '100%',
      flex: 'auto',
      padding: ['1rem', 0],
      [MOBILE_UP()]: {
        padding: [0]
      },
      textAlign: 'center',
      '& >span': {
        flex: 'auto',
        '& >button': {
          marginRight: '1rem'
        }
      }
    },

    [MOBILE_UP()]: {
      margin: [0, '1rem'],
      padding: ['1rem', '1rem', '1rem', 0],

      flexDirection: 'row',
      '& .customSettingControl': {
        border: 0
      },
      '& .button': {
        flex: 'auto',
        paddingTop: '0',
        textAlign: 'right',
        '& >button': {
          margin: 0
        }
      }
    },
    [LAPTOP_UP()]: {
      padding: ['1.5rem', 0, 0, 0],
      margin: [0, '2rem']
    }
  },
  hideControl: {
    flex: [1, 1, 'auto'],
    justifyContent: 'center !important',
    height: '40px',
    borderBottom: `1px solid ${colors.line2}`,
    paddingBottom: '1.5rem'
  },
  customSettingLabel: {
    ...customSettingLabel,
    paddingTop: '0.5rem',
    [MOBILE_UP()]: {
      paddingTop: '0rem'
    }
  },
  hidecustomSettingLabel: {
    ...customSettingLabel
  },
  hideCustomSettings: {
    ...customSettings,
    opacity: 0,
    overflow: 'hidden',
    height: 0,
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    borderTop: 'none',
    borderBottom: 'none'
  },
  showCustomSettings: {
    ...customSettings,
    height: 'auto'
  },
  errorMessage: {
    margin: ['1rem', 0],
    textAlign: 'center'
  },
  mulitSelectAnouncement: {
    paddingTop: '10px',
    paddingBottom: '0px',
    color: colors.lightBlue,
    fontSize: '18px'
  },
  messageErrorRow: {
    marginTop: '10px'
  }
}

export default styles
