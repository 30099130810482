import React from 'react'
import PropTypes from 'prop-types'
import jss from './Text.styles'
import objstr from 'obj-str'
import withStyles from 'react-jss'

class Text extends React.Component {
  getClassName = variantClassName => {
    const { classes, isError } = this.props

    const className = objstr({
      [classes[variantClassName]]: true,
      [classes['error']]: isError
    })
    return className
  }

  render() {
    const { variant, children } = this.props

    switch (variant) {
      case 'h1':
        return <h1 className={this.getClassName('h1')}>{children}</h1>
      case 'h2':
        return <h2 className={this.getClassName('h2')}>{children}</h2>
      case 'h3':
        return <h3 className={this.getClassName('h3')}>{children}</h3>
      case 'h4':
        return <h4 className={this.getClassName('h4')}>{children}</h4>
      case 'h5':
        return <h5 className={this.getClassName('h5')}>{children}</h5>
      case 'body':
        return <p className={this.getClassName('body-copy')}>{children}</p>
      case 'body-small':
        return <p className={this.getClassName('body-copy-sml')}>{children}</p>
      case 'title-small1':
        return <h6 className={this.getClassName('title-sml')}>{children}</h6>
      case 'title-sml-capital':
        return <h6 className={this.getClassName('title-sml-capital')}>{children}</h6>
      case 'title-note':
        return <h6 className={this.getClassName('title-note')}>{children}</h6>
      case 'note':
        return <span className={this.getClassName('note')}>{children}</span>
      case 'header-link':
        return <span className={this.getClassName('header-link')}>{children}</span>
      case 'label':
        return <span className={this.getClassName('label')}>{children}</span>
      default:
        return null
    }
  }
}

Text.propTypes = {
  /**
   * Shape of override expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    h1: PropTypes.object,
    h2: PropTypes.object,
    h3: PropTypes.object,
    h4: PropTypes.object,
    h5: PropTypes.object,
    body: PropTypes.object,
    'body-small': PropTypes.object,
    'title-small1': PropTypes.object,
    'title-sml-capital': PropTypes.object,
    'title-note': PropTypes.object,
    label: PropTypes.object,
    note: PropTypes.object,
    'header-link': PropTypes.object
  }),
  variant: PropTypes.PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body',
    'body-small',
    'title-small1',
    'title-sml-capital',
    'title-note',
    'label',
    'note',
    'header-link'
  ]).isRequired,
  children: PropTypes.any.isRequired,
  isError: PropTypes.bool,
  color: PropTypes.string
}

Text.defaultProps = {
  variant: 'body',
  isError: false,
  override: {}
}

export default withStyles(jss)(Text)
