import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LAPTOP_UP } = mediaQueryHelper

const styles = {
  alignMessage: {
    textAlign: 'center',
    margin: ['1rem', 0]
  },
  searchItem: {
    background: 'rgb(241, 241, 242)',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    padding: ['0.5rem'],
    flex: '1 0 100%',
    margin: [0, 0, '0.5rem', 0],
    [LAPTOP_UP()]: {
      flex: '1 0 50%',
      maxWidth: 'calc(50% - 4px)'
    }
  },
  text: {
    flex: [1, 1, 'auto'],
    color: colors.heading,
    fontSize: '1.2rem'
  },
  result: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '1rem',
    justifyContent: 'space-between'
  },
  showMore: {
    color: colors.heading,
    textAlign: 'center',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    '& >div': {
      marginLeft: '0.5rem'
    }
  }
}

export default styles
