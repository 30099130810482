import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo/Logo'
import MediaQuery from 'react-responsive'
import { BREAK_POINT_LARGE, min, max } from 'config/mediaQueryHelper'
import { LightDesktopMenu, DarkDesktopMenu } from './DesktopMenu/DesktopMenu'
import { LightMobileMenu, DarkMobileMenu } from './MobileMenu/MobileMenu'

const Menu = props => {
  return (
    <>
      <MediaQuery query={`(${max(BREAK_POINT_LARGE)})`}>
        {props.preset === 'dark' ? <DarkMobileMenu {...props} /> : <LightMobileMenu {...props} />}
      </MediaQuery>

      <MediaQuery query={`(${min(BREAK_POINT_LARGE)})`}>
        {props.preset === 'dark' ? <DarkDesktopMenu {...props} /> : <LightDesktopMenu {...props} />}
      </MediaQuery>
    </>
  )
}

Menu.propTypes = {
  /**
   * App Logo. Defaults to Paymark Logo. Pass in you app specific logo here.
   */
  logo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),
  mobileLogo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Action Handlers
   */
  onLogoClick: PropTypes.func,
  onNavigateBeforeClick: PropTypes.func,
  onSwitchAppClick: PropTypes.func,

  /**
   *  Header links
   */
  links: PropTypes.array,
  activeLink: PropTypes.string,

  /**
   *  Apps Links
   */
  appsLinks: PropTypes.array,

  /**
   * Custom Icon Menu Items
   */
  secondaryMenuItems: PropTypes.array,
  secondaryMenuIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Fixed Icon Menu Items
   */
  menuItems: PropTypes.array,
  applicationMenuItems: PropTypes.array,
  inviteMenuItems: PropTypes.array,
  notificationMenuItems: PropTypes.array,
  accountMenuItems: PropTypes.array,

  /**
   * Inner Content
   */
  content: PropTypes.any,

  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    header: PropTypes.object,
    logo: PropTypes.object,
    nav: PropTypes.object,
    title: PropTypes.object
  }),

  /**
   * Flag to show the back navigation button instead of the Paymark logo.
   */
  showBack: PropTypes.bool,

  /**
   * Flag to show the button which, when clicked, navigates from beta app to old app.
   */
  showSwitchApp: PropTypes.bool,

  /**
   * Flag for enabling the mouseEnter event
   */
  mouseEnter: PropTypes.bool,

  /**
   * Timeout in ms used for the mouseEnter event
   */
  timeout: PropTypes.number,

  /**
   * Preset is used to specify an app-specific style preset
   */
  preset: PropTypes.oneOf(['light', 'dark'])
}

Menu.defaultProps = {
  preset: 'light',
  override: {}
}

export default Menu
