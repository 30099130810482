import { weightMedium, standardFontFamily, weightSemiBold, weightRegular } from 'config/jss-vars'

import { cyan, heading, greyDark, greyLight, error } from 'config/colors'

const styles = {
  base: {
    fontFamily: standardFontFamily,
    color: params => params.color || heading,
    margin: '0',
    marginBottom: '0.3125em',
    display: 'inline-block'
  },
  h1: {
    composes: '$base',
    fontSize: '1.75rem',
    color: params => params.color || heading,
    fontWeight: weightSemiBold,
    extend: ({ override }) => ({
      ...override.h1
    })
  },
  h2: {
    composes: '$base',
    fontSize: '1.5rem',
    color: params => params.color || heading,
    fontWeight: weightMedium,
    extend: ({ override }) => ({
      ...override.h2
    })
  },
  h3: {
    composes: '$base',
    fontSize: '1.25rem',
    color: params => params.color || heading,
    fontWeight: weightSemiBold,
    extend: ({ override }) => ({
      ...override.h3
    })
  },
  h4: {
    composes: '$base',
    fontSize: '1.125rem',
    lineHeight: '1.5',
    color: params => params.color || heading,
    fontWeight: weightSemiBold,
    extend: ({ override }) => ({
      ...override.h4
    })
  },
  h5: {
    composes: '$base',
    fontSize: '1rem',
    lineHeight: '1.25',
    color: params => params.color || heading,
    fontWeight: weightSemiBold,
    extend: ({ override }) => ({
      ...override.h5
    })
  },
  'body-copy': {
    composes: '$base',
    fontSize: '1rem',
    textAlign: 'left',
    color: params => params.color || greyDark,
    lineHeight: '1.25',
    fontWeight: weightRegular,
    extend: ({ override }) => ({
      ...override['body-copy']
    })
  },

  'body-copy-sml': {
    composes: '$base',
    fontSize: '0.875rem',
    color: params => params.color || greyDark,
    lineHeight: '1.125',
    fontWeight: weightRegular,
    extend: ({ override }) => ({
      ...override['body-copy-sml']
    })
  },
  'title-sml': {
    composes: '$base',
    fontSize: '0.75rem',
    color: params => params.color || greyDark,
    letterSpacing: '0.015625em',
    fontWeight: weightMedium,
    lineHeight: '1.125',
    extend: ({ override }) => ({
      ...override['title-sml']
    })
  },
  'title-sml-capital': {
    composes: '$base',
    fontSize: '0.75rem',
    color: params => params.color || greyLight,
    letterSpacing: '0.0625em',
    textTransform: 'uppercase',
    fontWeight: weightMedium,
    lineHeight: '1.125',
    extend: ({ override }) => ({
      ...override['title-sml-capital']
    })
  },
  label: {
    composes: '$base',
    fontSize: '0.875rem',
    color: params => params.color || greyLight,
    letterSpacing: '0.0125em',
    fontWeight: weightRegular,
    lineHeight: '1.125',
    display: 'inline',
    extend: ({ override }) => ({
      ...override['label']
    })
  },
  'title-note': {
    composes: '$base',
    color: params => params.color || greyLight,
    fontSize: '0.75rem',
    letterSpacing: '0.5px',
    fontStyle: 'italic',
    fontWeight: weightRegular,
    extend: ({ override }) => ({
      ...override['title-note']
    })
  },
  note: {
    composes: '$base',
    fontSize: '0.875rem',
    color: params => params.color || greyLight,
    letterSpacing: '0.0125rem',
    lineHeight: '1.25',
    fontWeight: weightRegular,
    display: 'inline',
    extend: ({ override }) => ({
      ...override['note']
    })
  },
  link: {
    composes: '$base',
    fontSize: '1rem',
    color: params => params.color || cyan,
    lineHeight: '1',
    letterSpacing: '0',
    fontWeight: weightSemiBold,
    extend: ({ override }) => ({
      ...override['link']
    })
  },
  'header-link': {
    composes: '$base',
    cursor: 'pointer',
    transition: 'color 0.25s ease-in-out',
    fontSize: '1rem',
    lineHeight: '1',
    color: params => params.color || heading,
    fontWeight: weightSemiBold,
    '&:hover': {
      color: params => params.hoverColor || greyDark
    },
    extend: ({ override }) => ({
      ...override['header-link']
    })
  },
  error: {
    color: error + ' !important',
    extend: ({ override }) => ({
      ...override['error']
    })
  }
}

export default styles
