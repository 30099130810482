import React, { useState } from 'react'
import styles from './PermissionsConfirmationModal.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import { ConfirmationDialog, PermissionsInfo } from 'components/common'

const PermissionsConfirmationModal = ({ onAccept, onClose, callbackParams, classes, text }) => {
  const [isExpand, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!isExpand)
  }

  return (
    <ConfirmationDialog
      {...{
        onAccept: onAccept,
        onClose: onClose,
        callbackParams: callbackParams
      }}
    >
      <div>
        <Text variant="body">{text}</Text>
      </div>

      <div className={classes['showMore']} onClick={handleClick}>
        <Text variant="header-link" color={colors.cyan}>
          Learn more first
        </Text>
        <div>
          <Icon width={'0.8rem'} height={'0.8rem'} name={isExpand ? 'arrowUpRegular' : 'arrowDownRegular'} />
        </div>
      </div>

      <div className={classes['expandedContent']}>{isExpand && <PermissionsInfo />}</div>
    </ConfirmationDialog>
  )
}

export default withStyles(styles)(PermissionsConfirmationModal)
