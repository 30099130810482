import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, LAPTOP_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.line1}`,
    borderRadius: 4,
    '&:hover': {
      color: colors.teal,
      backgroundColor: colors.highlight
    },
    width: '130px',
    height: '36px',
    [MOBILE_UP()]: {
      width: '105px'
    },
    [LAPTOP_UP()]: {
      width: '120px',
      height: '40px'
    },
    [LARGE_SCREEN_UP()]: {
      width: '150px'
    }
  }
}

export default styles
