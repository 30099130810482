import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import api from 'api'
import { createCustomSelector } from 'utils/common.utils'
import accountSlice from 'reduxSlices/accountSlice'

const initialState = {
  list: [],
  isLoading: false
}

const getAllStores = createCustomSelector('stores.list')
const isLoading = createCustomSelector('stores.isLoading')

const selectors = {
  getAllStores,
  isLoading
}

const reducers = {
  fetchAllStores: state => {
    return {
      ...state,
      isLoading: true
    }
  },
  setAllStores: (state, action) => {
    const { payload } = action
    return {
      ...state,
      isLoading: false,
      list: payload
    }
  },
  updateStore: state => {
    return {
      ...state
    }
  }
}

const storesSlice = createSlice({
  slice: 'stores',
  initialState: initialState,
  reducers
})

function* updateStore(action) {
  let { payload } = action

  yield call(api.updateStore, payload)

  // Fetch the latest stores list and have the global loader.

  yield put(accountSlice.actions.startLoading())

  yield fetchAllStores()

  // Also have to re-fetch merchant access
  let merchantAccess = yield call(api.getMerchantAccess)
  yield put(accountSlice.actions.setMerchantAccess(merchantAccess))

  yield put(accountSlice.actions.endLoading())
}

function* fetchAllStores() {
  let storesList = yield call(api.getAllStores)

  storesList = _.orderBy(storesList, [store => store.displayName.toLowerCase()], ['asc'])

  yield put(storesSlice.actions.setAllStores(storesList))
}

function* storesSagas() {
  yield takeLatest(storesSlice.actions.fetchAllStores.toString(), fetchAllStores)
  yield takeLatest(storesSlice.actions.updateStore.toString(), updateStore)
}

export default {
  ...storesSlice,
  selectors,
  sagas: storesSagas
}
