import * as colors from 'config/colors'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '800px',
    margin: 'auto'
  },
  backAction: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: ['2rem', 0, '1rem', 0]
  },
  addButtonContainer: {
    borderTop: `1px solid ${colors.line1}`,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  selectedUsers: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    background: colors.highlight
  },
  search: {
    marginBottom: '1rem'
  }
}

export default styles
