import * as colors from 'config/colors'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { up, BREAK_POINT_LARGE } = mediaQueryHelper

export const popoverMenuStyles = {
  arrowColor: colors.cyan,
  arrowSize: 8,
  arrowStyle: { opacity: 0.8 },
  padding: 2
}

const styles = {
  body: {
    border: `1px solid ${colors.cyan}`,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    height: '160px',
    width: '310px',
    padding: '1.625em',
    position: 'relative',
    transition: 'all 1s ease',
    borderRadius: '4px',
    [up(BREAK_POINT_LARGE)]: {
      height: '190px',
      width: '320px'
    }
  },
  content: {
    position: 'relative',
    width: '160px',
    height: '160px',
    margin: 'auto'
  },
  totalCountContainer: {
    position: 'absolute',
    left: '30px',
    textAlign: 'center',
    width: '100px',
    top: 'calc(50% - 18px)',
    borderRadius: 20,
    '& * h2': {
      marginBottom: 0
    }
  },
  card: {
    textAlign: 'center',
    margin: 'auto',
    minHeight: '50px',
    paddingBottom: '5px'
  },
  details: {
    display: 'flex',
    marginTop: '0.5em'
  },
  count: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column'
  },
  amount: {
    width: '60%'
  },
  value: {
    padding: ['0.3em', 0, 0, '0.1em'],
    '& >h2': {
      fontSize: '1.6em !important'
    }
  },
  label: {
    fontWeight: 500
  },
  closeIcon: {
    position: 'absolute',
    top: '1em',
    right: '1em',
    cursor: 'pointer',
    '& :hover': {
      opacity: 0.8
    }
  }
}

export default styles
