import React, { useState, useEffect } from 'react'
import FormikInput from 'components/FormikWrappers/FormikInput'
import FormikRadio from 'components/FormikWrappers/FormikRadio'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import Message from 'components/Message/Message'
import withStyles from 'react-jss'
import styles from './InviteUserForm.styles'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER, ROLE_VIEWER } from 'constants/general.constants'
import FieldSection from 'components/common/FieldSection'
import { SelectedItems, ConfirmationDialog } from 'components/common'
import useMyAccount from 'components/MyAccount/useMyAccount'
import Container from './InviteUserContainer'
import { getStoreAccessMessage, getUpdatePayload } from './InviteUserForm.helper'
import InviteSuccessModal from './InviteSuccessModal'
import StoreSelector from './StoreSelector'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import { PermissionsInfoModal } from 'components/common'
import apis from 'api'

import {
  ERROR_USER_ACTIVE,
  ERROR_USER_WAITING_VERIFICATION,
  ERROR_USER_EMAIL_EXPIRED,
  ERROR_UNKNOWN_STATE,
  ERROR_NO_ACCESS,
  ERROR_SERVER
} from 'constants/errorCodes.constants'
import { EVENT_RESEND_INVITE } from 'constants/mixpanel.constants'

const InfoIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <Icon name="info" width={'1.25em'} height={'1.25em'} />
    </div>
  )
}

const InviteUserForm = props => {
  const {
    handleSubmit,
    isSubmitting,
    classes,
    values,
    errors,
    onBack,
    setFieldValue,
    submitCount,
    setSubmitting,
    setErrors
  } = props
  const { serverValidation: errorCode } = errors
  const { updated, role } = values

  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [isAllStoresVisible, setStoresVisible] = useState(false)
  const { stores: allStores } = useMyAccount()
  const isButtonDisabled = isSubmitting
  const [isPermissionInfoVisible, setPermissionInfoVisible] = useState(false)

  const handleStoreSelection = stores => {
    setFieldValue('stores', [...stores])
  }

  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice

  useEffect(() => {
    if (updated) {
      setConfirmationVisible(true)
      dispatch(usersActions.fetchAllUsers())
    }
  }, [updated, dispatch, usersActions])

  const onToggle = () => {
    setStoresVisible(!isAllStoresVisible)
  }

  const onModalClose = () => {
    setConfirmationVisible(false)
    onBack()
  }

  const onChangeUserToGlobalViewer = () => {
    setFieldValue('role', ROLE_GLOBAL_VIEWER)
  }

  const togglePermissionsInfoModal = () => {
    setPermissionInfoVisible(!isPermissionInfoVisible)
  }

  const handleResendInvite = async () => {
    const { Mixpanel: mixpanel } = apis
    const userPayload = getUpdatePayload(values)

    try {
      setErrors({ serverValidation: {} })

      mixpanel.track(EVENT_RESEND_INVITE)

      await apis.inviteUser(userPayload)
      setFieldValue('updated', true)
    } catch (error) {
      const { error: errorCode } = error
      setSubmitting(false)
      setErrors({
        serverValidation: errorCode
      })
    }
  }

  const onCloseHandleResendInvite = () => {
    setErrors({ serverValidation: {} })
  }

  let message = getStoreAccessMessage(role)

  return (
    <>
      <form onSubmit={handleSubmit} name="InviteUserForm" className={classes['form']}>
        <FieldSection title={'First Name'} labelWidth={'150px'} alignCenter>
          <FormikInput name="firstName" />
        </FieldSection>

        <FieldSection title={'Last Name'} labelWidth={'150px'} alignCenter>
          <FormikInput name="lastName" />
        </FieldSection>

        <FieldSection title={'Email Address'} labelWidth={'150px'} alignCenter>
          <FormikInput name="email" />
        </FieldSection>

        <FieldSection
          title={'Permissions'}
          labelWidth={'150px'}
          noBorder
          infoIcon={<InfoIcon onClick={togglePermissionsInfoModal} />}
        >
          <div className={classes['permissionRadios']}>
            <FormikRadio name="role" id={ROLE_VIEWER} label={ROLE_VIEWER} />
            <FormikRadio name="role" id={ROLE_GLOBAL_VIEWER} label={ROLE_GLOBAL_VIEWER} />
            <FormikRadio name="role" id={ROLE_OWNER} label={ROLE_OWNER} />
          </div>
        </FieldSection>

        {ROLE_VIEWER !== role && (
          <div className={classes['storeAccess']}>
            <div className={'message'}>
              <Text variant="h5" override={{ h5: { fontSize: '0.9rem' } }}>
                {' '}
                {message}
              </Text>
            </div>

            <div className={'link'}>
              <div className={classes['showMore']} onClick={onToggle}>
                <Text variant="header-link" color={colors.cyan}>
                  {isAllStoresVisible ? `Hide all ${allStores.length} stores` : `See all ${allStores.length} stores`}
                </Text>
                <div>
                  <Icon
                    width={'1rem'}
                    height={'1rem'}
                    name={isAllStoresVisible ? 'arrowUpRegular' : 'arrowDownRegular'}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {//Showing all stores for Gloabl viewer and Owner
        ROLE_VIEWER !== role && isAllStoresVisible && (
          <div className={classes['globalStores']}>
            <SelectedItems
              {...{
                list: allStores,
                keyExtractor: item => {
                  return item.displayName
                },
                title: `${role.toUpperCase()}'S STORES`,
                isSelected: true
              }}
            />
          </div>
        )}

        {ROLE_VIEWER === role && (
          <>
            <StoreSelector
              {...{
                classes,
                onChangeUserToGlobalViewer,
                handleStoreSelection
              }}
            />

            <div className={classes['errorMessage']}>
              {submitCount > 0 && errors.stores && <Message showIcon={false} variant="error" text={errors.stores} />}
            </div>
          </>
        )}

        <div className={classes['submitButton']}>
          <Button disabled={isButtonDisabled} variant="primary" type="submit">
            Invite User
          </Button>
        </div>

        {(errorCode === ERROR_SERVER || errorCode === ERROR_UNKNOWN_STATE) && (
          <div className={classes['errorMessage']}>
            <Message showIcon={false} variant="error" text="Sorry, there was an error. Please try again later." />
          </div>
        )}

        {errorCode === ERROR_NO_ACCESS && (
          <div className={classes['errorMessage']}>
            <Message
              showIcon={false}
              variant="error"
              text="The email is already in use, please use a different email address."
            />
          </div>
        )}

        {errorCode === ERROR_USER_ACTIVE && (
          <div className={classes['errorMessage']}>
            <Message showIcon={false} variant="error" text="This staff member already exists on this account." />
          </div>
        )}

        {(errorCode === ERROR_USER_WAITING_VERIFICATION || errorCode === ERROR_USER_EMAIL_EXPIRED) && (
          <ConfirmationDialog
            {...{
              onAccept: handleResendInvite,
              onClose: onCloseHandleResendInvite
            }}
          >
            <div>
              {errorCode === ERROR_USER_WAITING_VERIFICATION && (
                <Text variant="body">
                  This staff member has already been invited. Would you like to resend the email invitation?
                </Text>
              )}
              {errorCode === ERROR_USER_EMAIL_EXPIRED && (
                <Text variant="body">
                  This staff member's invitation has expired. Would you like to resend the email invitation?
                </Text>
              )}
            </div>
          </ConfirmationDialog>
        )}

        {confirmationVisible && (
          <InviteSuccessModal
            {...{
              onClose: onModalClose,
              user: values
            }}
          />
        )}

        {isPermissionInfoVisible && <PermissionsInfoModal {...{ onClose: togglePermissionsInfoModal }} />}
      </form>
    </>
  )
}

export default withStyles(styles)(Container(InviteUserForm))
