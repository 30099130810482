import React, { Suspense, useState } from 'react'
import Banner from 'components/Banner/Banner'
import withStyles from 'react-jss'
import styles from './PrivateLayout.styles'
import { PrivateHeader, Loader } from 'components/common'
import { getTutorialCompletedFromLocalStorage, getAppSwitcherModalViewedInLocalStorage } from 'utils/localStorage.utils'
import useMyAccount from 'components/MyAccount/useMyAccount'
import { SERVER_ROLE_OWNER } from 'constants/general.constants'

const TutorialModal = React.lazy(() => import('components/common/TutorialModal/TutorialModal'))
const AppSwitcherModal = React.lazy(() => import('components/common/AppSwitcherModal/AppSwitcherModal'))

const getDisplayModal = (
  appSwitcherModalCompleted,
  tutorialModalCompleted,
  setAppSwitcherModalCompleted,
  setModalCompleted,
  isOwner
) => {
  if (!tutorialModalCompleted) {
    return <TutorialModal setModalCompleted={setModalCompleted} />
  }
  //TODO - Will need to be uncommented the when app switcher is working properly - DXT-19404
  /* if (!appSwitcherModalCompleted && isOwner) {
         return <AppSwitcherModal setAppSwitcherModalViewed={setAppSwitcherModalCompleted} />
       } */
}

const PrivateLayout = ({ Component, classes, ...props }) => {
  const { user } = useMyAccount()
  const { role } = user
  const isOwner = SERVER_ROLE_OWNER === role

  const [appSwitcherModalCompleted, setsetAppSwitcherModalCompleted] = useState(
    getAppSwitcherModalViewedInLocalStorage()
  )
  const [modalCompleted, setModalCompleted] = useState(false)

  const dismissBanner = () => {
    const { userActions } = props
    userActions.dismissBanner()
  }

  const {
    userData: {
      banner: { isVisible, message }
    }
  } = props

  const tutorialModalCompleted = getTutorialCompletedFromLocalStorage()
  const displayModel = tutorialModalCompleted === false //|| appSwitcherModalCompleted == false - DXT-19404

  const modal = getDisplayModal(
    appSwitcherModalCompleted,
    tutorialModalCompleted,
    setsetAppSwitcherModalCompleted,
    setModalCompleted,
    isOwner
  )
  return (
    <main className={classes.wrapper}>
      <section className={classes.header}>
        <section className={classes.banner}>
          <Banner message={message} isVisible={isVisible} onClose={dismissBanner} />
        </section>

        <PrivateHeader {...props} />
      </section>

      <section className={classes.section}>
        <Component {...{ ...props }} />
      </section>

      {displayModel && <Suspense>{modal}</Suspense>}

      <Loader />
    </main>
  )
}

const StyledPrivateLayout = withStyles(styles)(PrivateLayout)

const withPrivateLayoutHOC = Component => {
  return props => <StyledPrivateLayout Component={Component} {...props} />
}
export default withPrivateLayoutHOC
