import React from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import styles from './ImprovementPercentage.styles'
import { formatPercentage } from 'utils/number.utils'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import objstr from 'obj-str'

const ImprovementPercentage = ({
  paramColor,
  classes,
  value,
  iconClass,
  variant = 'body',
  iconSize = '0.8em',
  positiveColor = colors.success,
  negativeColor = colors.error,
  showIcon = true
}) => {
  let params = {}

  if (value < 0) {
    params = {
      name: 'decrease',
      color: paramColor || negativeColor
    }
  } else if (value === 0 || isNaN(value)) {
    params = {
      name: null,
      color: paramColor || GRAPH_PREVIOUS_PERIOD_COLOR
    }
  } else {
    params = {
      name: 'increase',
      color: paramColor || positiveColor
    }
  }

  const className = objstr({ [classes['iconClass']]: iconClass })

  return (
    <div className={classes['change']} data-hj-suppress>
      <div className={`${classes['value']} print-spacing`}>
        <Text
          {...{
            color: params.color,
            variant
          }}
        >
          {isNaN(value) ? value : formatPercentage(value)}
        </Text>
      </div>
      {iconSize && params.name && showIcon && (
        <Icon
          className={`${className}`}
          {...{
            ...params,
            width: iconSize,
            height: iconSize
          }}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(ImprovementPercentage)
