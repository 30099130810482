import React from 'react'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'

const HeadingElement = ({ text, className }) => {
  return (
    <div className={className}>
      <Text variant="body" color={colors.greyDark}>
        {text}
      </Text>
    </div>
  )
}

const StoreHeader = ({ classes, store, isOpen }) => {
  const { displayName, tradingName, cardAcceptorIdCode, _userCount } = store

  if (isOpen) {
    return (
      <div className={classes['openHeader']} data-hj-suppress>
        <Text variant="body" color={colors.greyDark}>
          {displayName}
        </Text>
      </div>
    )
  }

  return (
    <>
      {/* Mobile Header */}
      <div className={classes['mobileHeader']} data-hj-suppress>
        <div className={'line1'}>
          <HeadingElement className={classes['preferredName']} text={displayName} />
        </div>
        <div className={'line2'}>
          <HeadingElement className={classes['businessName']} text={tradingName} />
          <HeadingElement className={classes['userCount']} text={`${_userCount} Users`} />
        </div>
      </div>

      {/* Desktop Header */}
      <div className={classes['header']} data-hj-suppress>
        <HeadingElement className={classes['preferredName']} text={displayName} />
        <HeadingElement className={classes['businessName']} text={tradingName} />
        <HeadingElement className={classes['merchantNumber']} text={cardAcceptorIdCode} />
        <HeadingElement className={classes['users']} text={`${_userCount} Users`} />
      </div>
    </>
  )
}

export default StoreHeader
