import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { NAME_REGEX } from 'utils/validation.utils'
import pick from 'lodash/pick'
import apis from 'api'
import { getInsightsRole } from 'utils/user.utils'
import { EVENT_EDIT_PERSONAL_INFORMATION, EVENT_EDIT_PERSONAL_INFORMATION_FAILURE } from 'constants/mixpanel.constants'

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.')
    .matches(NAME_REGEX, 'Enter a valid first name.'),

  lastName: Yup.string()
    .required('Last name is required.')
    .matches(NAME_REGEX, 'Enter a valid last name.')
})

const getUpdatePayload = data => {
  return pick(data, ['firstName', 'lastName'])
}

const handleSubmit = async (values, params) => {
  const { Mixpanel: mixpanel } = apis
  const { setSubmitting, setErrors, setFieldValue } = params
  const { props } = params
  const { updateAccount } = props
  const updateValues = getUpdatePayload(values)

  try {
    setFieldValue('updated', false)

    mixpanel.track(EVENT_EDIT_PERSONAL_INFORMATION)
    await updateAccount(updateValues)
    setSubmitting(false)
    setFieldValue('updated', true)
  } catch (error) {
    const { error: errorCode } = error

    mixpanel.track(EVENT_EDIT_PERSONAL_INFORMATION_FAILURE, { EditPersonalInformationError: errorCode })

    setSubmitting(false)
    setErrors({
      serverValidation: errorCode
    })
  }
}

const mapPropsToValues = props => {
  const {
    user: { firstName = '', lastName = '', email = '', roles }
  } = props
  const permission = getInsightsRole(roles)
  return { firstName, lastName, email, updated: false, permission: permission, password: 'Password' }
}

const formikObject = {
  displayName: 'PersonalInformationForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
