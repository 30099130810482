import React from 'react'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import styles from './ImprovementPercentage.styles'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import objstr from 'obj-str'

const ImprovementPercentageIcon = ({
  classes,
  value,
  iconClass,

  iconSize = '0.8em',
  positiveColor = colors.success,
  negativeColor = colors.error
}) => {
  let params = {}

  if (value < 0) {
    params = {
      name: 'decrease',
      color: negativeColor
    }
  } else if (value === 0 || isNaN(value)) {
    params = {
      name: null,
      color: GRAPH_PREVIOUS_PERIOD_COLOR
    }
  } else {
    params = {
      name: 'increase',
      color: positiveColor
    }
  }

  const className = objstr({ [classes['iconClass']]: iconClass })

  return (
    <>
      {iconSize && params.name && (
        <Icon
          className={`${className}`}
          {...{
            ...params,
            width: iconSize,
            height: iconSize
          }}
        />
      )}
    </>
  )
}

export default withStyles(styles)(ImprovementPercentageIcon)
