import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  date: {
    display: 'flex',

    [MOBILE_UP()]: {
      float: 'right'
    }
  }
}

export default styles
