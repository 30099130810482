import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { up, BREAK_POINT_LARGE } = mediaQueryHelper

const isDesktop = up(BREAK_POINT_LARGE)

const styles = {
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexDirection: 'column',

    [isDesktop]: {
      flexDirection: 'row'
    }
  },
  storeName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [isDesktop]: {
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'left',
      alignItems: 'left',
      '& >h2': {
        marginRight: '1rem'
      }
    }
  },
  selectedDates: {
    display: 'flex',
    justifyContent: 'center',
    [isDesktop]: {
      justifyContent: 'right',
      marginTop: '0.5rem'
    }
  }
}

export default styles
