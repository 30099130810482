import React from 'react'
import Text from 'components/Text/Text'
import { isTabletWidth } from 'utils/common.utils'
import ContentLoader from 'react-content-loader'
import withStyles from 'react-jss'
import styles from './StoreCardGraphLayout.styles'

const LoadingIndicator = () => {
  const width = isTabletWidth() ? 200 : 600
  return (
    <ContentLoader height={20} width={600} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="3" ry="3" width={width} height="20" />
    </ContentLoader>
  )
}

const StoreCardRankSection = ({ classes, rankText, isCompareStore, isLoading }) => {
  if (!isCompareStore) {
    return (
      <div className={classes['rank']} data-hj-suppress>
        {!isLoading && <Text variant="h5"> {rankText}</Text>}
        {isLoading && <LoadingIndicator />}
      </div>
    )
  }
  return null
}

export default withStyles(styles)(StoreCardRankSection)
