import React from 'react'
import { CommentarySection } from 'components/common'
import useTopStores from 'reduxSlices/dashboardSlice/hooks/useTopStores'

const TopStoresCommentary = () => {
  const { topStore, bottomStore, isLoading } = useTopStores()

  if (isLoading) {
    return <CommentarySection isLoading={true} />
  }
  let text = 'There are no transactions for this time period.'
  let bottomStoreText = null

  if (topStore) {
    text = `${topStore.displayName} is the top performing store.`
    bottomStoreText = `${bottomStore.displayName} has the lowest performance of all your stores.`
  }

  return (
    <>
      <CommentarySection {...{ isLoading }}>{text} </CommentarySection>
      {bottomStoreText && <CommentarySection>{bottomStoreText} </CommentarySection>}
    </>
  )
}

export default TopStoresCommentary
