import React from 'react'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import styles from './StoreSalesSummaryCard.styles'
import { DashboardSummaryCard, ImprovementPercentage } from 'components/common'
import { getTotal, getDifferencePercentage, formatNumber } from 'utils/number.utils'
import { white } from 'config/colors'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import StoreSalesSummaryCardLoader from './StoreSalesSummaryCardLoader'
import _ from 'lodash'

const dashboardCommonParams = {
  title: 'Sales',
  iconName: 'sales',
  height: '100px'
}

const valueFormatter = value => formatNumber(value, false, false)

const StoreSalesSummaryCard = ({ classes }) => {
  const { primaryStore, isComparePeriod } = useSalesRevenueAgg()
  const { data: primaryData, isLoading: isPrimaryLoading } = primaryStore

  if (isPrimaryLoading) {
    return (
      <DashboardSummaryCard
        {...{
          ...dashboardCommonParams
        }}
      >
        <StoreSalesSummaryCardLoader />
      </DashboardSummaryCard>
    )
  }

  const currentTotal = _.isEmpty(primaryData) ? 0 : getTotal(primaryData, 'currentSales')
  let oldTotal = isComparePeriod ? (_.isEmpty(primaryData) ? 0 : getTotal(primaryData, 'oldSales')) : 0
  let difference = isComparePeriod ? (_.isEmpty(primaryData) ? 0 : getDifferencePercentage(currentTotal, oldTotal)) : 0

  return (
    <DashboardSummaryCard
      {...{
        ...dashboardCommonParams
      }}
    >
      <div className={classes['total']}>
        <div className={classes['currentPeriod']}>
          <Text variant="h1" color={white}>
            {valueFormatter(currentTotal)}
          </Text>
        </div>
        {isComparePeriod && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage
              paramColor={white}
              positiveColor={white}
              negativeColor={white}
              {...{ value: difference, variant: 'h2', iconSize: '1.15em', iconClass: 'iconClass' }}
            />
          </div>
        )}
      </div>
    </DashboardSummaryCard>
  )
}

export default withStyles(styles)(StoreSalesSummaryCard)
