import React from 'react'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import styles from './RevenueCard.styles'
import { GRAPH_PREVIOUS_PERIOD_COLOR, GRAPH_REVENUE_COLOR } from 'constants/colors.contants'
import { getCurrency, getTotal, getDifferencePercentage } from 'utils/number.utils'
import { ChartToolTip, ImprovementPercentage, DashboardCard, BarChart } from 'components/common'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import { getMinMaxRevenueValue } from 'utils/salesRevenue.utils'
import RevenueCardLoader from './RevenueCardLoader'
import _ from 'lodash'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'
import { formatDateByRange } from 'components/common/ChartToolTip/ChartToolTip.utils'

const dashboardCommonParams = {
  title: 'Revenue Total',
  iconName: 'revenue',
  height: '230px'
}

const valueFormatter = value => {
  return getCurrency(value)
}

const RevenueCard = ({ classes }) => {
  const {
    allSalesRevenueAgg,
    isComparePeriod,
    getExpandedCard,
    expandCardAction,
    compressCardsAction
  } = useSalesRevenueAgg()
  const {
    filter: { range }
  } = useFilters()
  const { data: parsedData, isLoading } = allSalesRevenueAgg
  const dataKey = 'revenue'
  const isExpanded = getExpandedCard === dataKey

  if (isLoading) {
    return (
      <DashboardCard
        {...{
          ...dashboardCommonParams,
          isExpanded
        }}
      >
        <RevenueCardLoader />
      </DashboardCard>
    )
  }

  const { maxValue, minValue } = getMinMaxRevenueValue(isComparePeriod, parsedData, null)

  const currentTotal = _.isEmpty(parsedData) ? 0 : getTotal(parsedData, 'currentRevenue')
  let oldTotal = isComparePeriod ? (_.isEmpty(parsedData) ? 0 : getTotal(parsedData, 'oldRevenue')) : 0
  let difference = isComparePeriod ? (_.isEmpty(parsedData) ? 0 : getDifferencePercentage(currentTotal, oldTotal)) : 0

  const onResize = isExpand => {
    if (isExpand) {
      return expandCardAction(dataKey)
    }
    compressCardsAction()
  }

  const dateFormatter = value => {
    return formatDateByRange(value, range)
  }

  return (
    <DashboardCard
      {...{
        ...dashboardCommonParams,
        onResize,
        isExpanded
      }}
    >
      <div className={classes['graph']} data-hj-suppress>
        {isExpanded ? (
          <div className={classes['expandedContent']}>
            <BarChart
              {...{
                showYAxis: true,
                data: parsedData,
                isCompare: isComparePeriod,
                showTooltip: true,
                minValue,
                maxValue,
                CustomTooltip: (
                  <ChartToolTip {...{ isCompare: isComparePeriod, valueFormatter, dataKey, dateFormatter }} />
                )
              }}
            />
          </div>
        ) : (
          <div className={classes['content']}>
            <BarChart
              {...{
                data: parsedData,
                isCompare: isComparePeriod,
                minTickGap: 200,
                minValue,
                maxValue
              }}
            />
          </div>
        )}
      </div>

      <div className={classes['total']} data-hj-suppress>
        <div className={classes['currencyValue']}>
          <Text variant="h1" color={GRAPH_REVENUE_COLOR}>
            {getCurrency(currentTotal, false, false)}
          </Text>
          {isComparePeriod && (
            <Text variant="h2" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
              {getCurrency(oldTotal, false, false)}
            </Text>
          )}
        </div>
        {isComparePeriod && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage {...{ value: difference }} />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

export default withStyles(styles)(RevenueCard)
