import * as colors from 'config/colors'

const styles = {
  showMore: {
    color: colors.heading,
    textAlign: 'center',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    padding: ['1rem', 0],
    '& >div': {
      marginLeft: '0.5rem'
    },
    borderTop: `1px solid ${colors.line2}`
  },
  expandedContent: {
    borderBottom: `1px solid ${colors.line2}`
  }
}

export default styles
