import React, { Component } from 'react'
import withStyles from 'react-jss'
import styles from './TimerPeriodSelector.styles'
import TimePicker from 'components/TimePicker/TimePicker'
import * as colors from 'config/colors'
import Icon from 'components/Icon/Icon'
import Message from 'components/Message/Message'
import Select from 'components/Select/Select'
import PropTypes from 'prop-types'
import objstr from 'obj-str'

export const OPTIONS = [
  { value: 'full', label: 'Full day' },
  { value: 'morning', label: 'Morning' },
  { value: 'afternoon', label: 'Afternoon' },
  { value: 'evening', label: 'Evening' },
  { value: 'custom', label: 'Custom' }
]

class TimePeriodSelector extends Component {
  state = {}

  render() {
    const {
      classes,
      timeRange,
      handleTimeRangeChange,
      handleTimeChange,
      endTime,
      startTime,
      timeError,
      hightlight
    } = this.props

    const value = OPTIONS.filter(({ value }) => {
      if (value === timeRange) {
        return true
      }
      return false
    })[0]

    const className = objstr({ [classes['highlight']]: hightlight })

    return (
      <div>
        <div className={classes['selectors']}>
          <div className={classes['timeRange']}>
            <Select
              options={OPTIONS}
              placeholder={'Select an option'}
              onChange={handleTimeRangeChange}
              value={value}
              icon={<Icon name="time" width={'1.2em'} height={'1.2em'} color={colors.icon} />}
            />
          </div>
          <div className={`${classes['timePicker']} ${className}}`}>
            <TimePicker startTime={startTime} endTime={endTime} onChange={handleTimeChange} />
          </div>
        </div>
        {timeError && (
          <div className={classes['errorMessage']}>
            <Message variant="error" showIcon={false} text={timeError} />{' '}
          </div>
        )}
      </div>
    )
  }
}

TimePeriodSelector.propTypes = {
  classes: PropTypes.object,
  timeRange: PropTypes.string,
  handleTimeRangeChange: PropTypes.func,
  handleTimeChange: PropTypes.func,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  timeError: PropTypes.any,
  hightlight: PropTypes.bool
}

TimePeriodSelector.propTypes = {}

export default withStyles(styles)(TimePeriodSelector)
