import axios from './axiosDefaults'
import handleError from 'utils/error.utils'
import { HEADER_CSV } from 'constants/general.constants'

const BASE_PATH = 'merchant/settlement/'

const getCreditToBank = async params => {
  const { cardAcceptorIdCodes, settlementDateFrom, settlementDateTo } = params
  const ctbParams = {
    type: 'total',
    settlementType: 'Credit to Bank',
    cardAcceptorIdCodes,
    settlementDateFrom,
    settlementDateTo
  }
  return await axios.get(BASE_PATH, { params: ctbParams })
}

const getDeferred = async params => {
  const { cardAcceptorIdCodes, settlementDateFrom, settlementDateTo } = params
  const defParams = {
    type: 'total',
    settlementType: 'Deferred',
    cardAcceptorIdCodes,
    settlementDateFrom,
    settlementDateTo
  }

  return await axios.get(BASE_PATH, { params: defParams })
}

export const getMerchantSettlements = async params => {
  const qParams = {
    ...params
  }

  try {
    const result = await Promise.all([getCreditToBank(qParams), getDeferred(qParams)])

    if (result[0] === 504 || result[1 === 504]) {
      return 504
    }

    const creditToBank = result[0].settlementCaids[0]
    const deferred = result[1].settlementCaids[0]

    return { creditToBank, deferred }
  } catch (error) {
    throw new Error(error)
  }
}

export const getTerminalSettlements = async params => {
  const { cardAcceptorIdCodes, settlementDateFrom, settlementDateTo } = params
  const terminalParams = {
    type: 'terminal',
    cardAcceptorIdCode: cardAcceptorIdCodes,
    settlementDateFrom,
    settlementDateTo
  }

  try {
    return await axios.get(BASE_PATH, { params: terminalParams })
  } catch (error) {
    // handleError(error)
  }
}

export const getSettlementExportData = async params => {
  const { cardAcceptorIdCodes, settlementDateFrom, settlementDateTo } = params

  const headers = { ...axios.defaults.headers }
  headers['Accept'] = HEADER_CSV

  const exportParams = {
    cardAcceptorIdCodes: cardAcceptorIdCodes,
    settlementDateFrom,
    settlementDateTo
  }

  try {
    const csv = await axios.get(BASE_PATH, {
      headers,
      params: exportParams
    })

    return csv
  } catch (error) {
    handleError(error)
  }
}
