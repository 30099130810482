import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { up, BREAK_POINT_MEDIUM, BREAK_POINT_LARGE } = mediaQueryHelper
const styles = {
  roleInfo: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: ['1.5rem', 0],

    [up(BREAK_POINT_MEDIUM)]: {},
    [up(BREAK_POINT_LARGE)]: {
      textAlign: 'center',
      margin: ['1.5rem', 0],
      borderBottom: `1px solid ${colors.line2}`,
      borderTop: `1px solid ${colors.line2}`
    }
  },
  role: {},
  createdOn: {
    [mediaQueryHelper.LAPTOP_UP()]: {
      width: '10rem'
    }
  }
}

export default styles
