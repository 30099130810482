import axios from './axiosCustomErrorHandling'
import { handleTotaltxnaggReqParams, transformTotaltxnaggResponse } from 'utils/aggregateWrapper.utils'

const PATH_ALL_STORES = 'data/totaltxnagg/'

export const getAllStoresSalesRevenueInformation = async params => {
  const { current, previous, isCompare } = params

  if (!isCompare) {
    return getCurrentSalesRevenueInformation(current)
  }

  return getAllSalesRevenueWithComparison(current, previous)
}

export const getSalesRevenueAgg = async params => {
  const { interval, transactionTimeFrom, transactionTimeTo, aggregate = true, cardAcceptorIdCode } = params
  const serviceParams = {
    transactionTimeFrom,
    transactionTimeTo,
    interval,
    aggregate,
    cardAcceptorIdCode
  }
  const response = await axios.get(PATH_ALL_STORES, { params: handleTotaltxnaggReqParams(serviceParams) })
  return transformTotaltxnaggResponse(serviceParams.interval, response)
}

const getCurrentSalesRevenueInformation = async currenParams => {
  const curTransactionsAgg = await getSalesRevenueAgg(currenParams)

  return {
    currentTransactionsAgg: curTransactionsAgg
  }
}

const getAllSalesRevenueWithComparison = async (currenParams, previousParams) => {
  const [currentTransactionsAgg, prevTransactionsAgg] = await Promise.all([
    getSalesRevenueAgg(currenParams),
    getSalesRevenueAgg(previousParams)
  ])

  return {
    currentTransactionsAgg: currentTransactionsAgg,
    previousTransactionsAgg: prevTransactionsAgg
  }
}
