export const BREAK_POINT_EXTRA_SMALL = 320
export const BREAK_POINT_SMALL = 568
export const BREAK_POINT_MEDIUM = 768
export const BREAK_POINT_LARGE = 1024
export const BREAK_POINT_EXTRA_LARGE = 1440

export const min = size => {
  return `min-width: ${size}px`
}

export const max = size => {
  return `max-width: ${size - 1}px`
}

export const down = size => {
  return `@media (${max(size)})`
}

export const up = size => {
  return `@media (${min(size)})`
}

export const between = (lowerLimit, upperLimit) => {
  return `@media (${min(lowerLimit)}) and (${max(upperLimit)})`
}

export const TABLET_DOWN = () => down(BREAK_POINT_MEDIUM)
export const MOBILE_DOWN = () => down(BREAK_POINT_SMALL)

export const TABLET_ONLY = () => between(BREAK_POINT_SMALL, BREAK_POINT_MEDIUM)

export const MOBILE_UP = () => up(BREAK_POINT_SMALL)
export const LAPTOP_UP = () => up(BREAK_POINT_MEDIUM)
export const SCREEN_UP = () => up(BREAK_POINT_LARGE)
export const LARGE_SCREEN_UP = () => up(BREAK_POINT_EXTRA_LARGE)
