import * as Yup from 'yup'
import FormikForm from 'components/FormikWrappers/FormikForm'
import { NAME_REGEX } from 'utils/validation.utils'

const validationSchema = Yup.object().shape({
  preferredName: Yup.string().matches(NAME_REGEX, `Sorry, special characters (<>%$!"*) are not supported`)
})

const handleSubmit = async (values, params) => {
  const { setSubmitting, setErrors, setFieldValue } = params
  const { props } = params
  const { store, onStoreUpdate } = props

  const preferredName = values.preferredName

  let updatedStore = { ...store, preferredName }
  delete updatedStore.displayName // This is used in UI only

  try {
    setFieldValue('updated', false)

    onStoreUpdate(updatedStore)
    setSubmitting(false)
    setFieldValue('updated', true)
  } catch (error) {
    const { error: errorCode } = error
    setSubmitting(false)
    setErrors({
      serverValidation: errorCode
    })
  }
}

const mapPropsToValues = props => {
  const { store } = props

  const preferredName = store.preferredName || store.tradingName

  return { preferredName, updated: false }
}

const formikObject = {
  displayName: 'PreferredNameForm',
  mapPropsToValues,
  validationSchema,
  handleSubmit
}

const Container = Component => {
  return FormikForm(Component, formikObject)
}

export default Container
