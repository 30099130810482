import React, { useState } from 'react'
import container, {
  hasDataToExport,
  downloadTransactionsCsv,
  printTransaction,
  getParams
} from './TransactionExportContainer'
import { ExportDropDown } from 'components/common'
import MultiStoreDownloadModal from 'components/Modal/MultiStoreDownloadModal'
import apis from 'api'
import {
  EVENT_DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_EXPORT_TYPE_PRINT,
  DOWNLOAD_REPORT_EXPORT_TYPE_CSV
} from 'constants/mixpanel.constants'
import { downloadCSV } from 'utils/file.utils'

const OPTIONS_PRINT_CSV_DOWNLOAD = [
  { value: 'print', label: 'Print' },
  { value: 'csv', label: 'Download CSV' }
]
const OPTIONS_CSV_DOWNLOAD = [{ value: 'csv', label: 'Download CSV' }]
const TRANSACTIONS_PAGE_NAME = 'transactions'

const TransactionExport = ({
  isLoading,
  search,
  transactions,
  actions,
  enablePrintRender,
  removePrintMenu,
  enableExport
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { Mixpanel: mixpanel } = apis
  let options = removePrintMenu ? OPTIONS_CSV_DOWNLOAD : OPTIONS_PRINT_CSV_DOWNLOAD

  const proceedHandler = async () => {
    try {
      setModalOpen(false)
      actions.startLoading()
      mixpanel.track(EVENT_DOWNLOAD_REPORT, {
        pageName: TRANSACTIONS_PAGE_NAME,
        exportType: DOWNLOAD_REPORT_EXPORT_TYPE_CSV
      })
      const csv = await downloadTransactionsCsv(search)
      const { filter } = getParams(search)
      if (csv !== 504) {
        downloadCSV(csv, 'merchant-transactions', filter)
      } else {
        actions.set504(true)
      }
    } finally {
      setTimeout(() => actions.endLoading(), 1000)
    }
  }

  const cancelHandler = () => {
    setModalOpen(false)
  }

  const onSelect = async selection => {
    const { value } = selection
    try {
      if (value === 'print') {
        actions.startLoading()
        mixpanel.track(EVENT_DOWNLOAD_REPORT, {
          pageName: TRANSACTIONS_PAGE_NAME,
          exportType: DOWNLOAD_REPORT_EXPORT_TYPE_PRINT
        })
        await enablePrintRender(true)
        await printTransaction(search)
      } else if (value === 'csv') {
        setModalOpen(true)
      }
    } finally {
      await enablePrintRender(false)
      setTimeout(() => actions.endLoading(), 1000)
    }
  }

  const isEnabled = (!isLoading && hasDataToExport(transactions)) || enableExport
  return (
    <>
      <MultiStoreDownloadModal modalOpen={modalOpen} proceedHandler={proceedHandler} cancelHandler={cancelHandler} />
      <ExportDropDown {...{ isLoading, isEnabled, options: options, onSelect }} />
    </>
  )
}
export default container(TransactionExport)
