import { take } from 'lodash'
import React, { useEffect, useState } from 'react'
import withStyles from 'react-jss'
import styles from './TransactionFilter.styles'
import Message from 'components/Message/Message'
import FormikInput from 'components/FormikWrappers/FormikInput'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import container from './TransactionFilterContainer'
import DateSection from '../DateSection/DateSection'
import TimePeriodSelector from './TimePeriodSelector/TimePeriodSelector'
import CardTypeSelector from './CardTypeSelector/CardTypeSelector'
import TransactionTypeSelector from './TransactionTypeSelector/TransactionTypeSelector'
import StatusSelector from './StatusSelector/StatusSelector'
import TypeHelpModal from './TypeHelpModal/TypeHelpModal'
import { getFeatureFlag, FEATURE_FLAG_TRANSACTION_MONTH_SEARCH, getConfig } from 'utils/config.utils'
import Select from 'components/Select/Select'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import TransactionExport from 'components/TransactionResult/TransactionExport/TransactionExport'
import { getDateTime } from '../../utils/filters.utils'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { storeAdapter } from './TransactionFilter.helper'

const {
  features: { StoreMaxSelect: STORES_MAX_SELECT }
} = getConfig()

const monthSearch = getFeatureFlag(FEATURE_FLAG_TRANSACTION_MONTH_SEARCH)
export const OPTIONS = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'thisWeek', label: 'This Week' },
  ...(monthSearch ? [{ value: 'thisMonth', label: 'This Month' }] : []),
  { value: 'custom', label: 'Custom' }
]

const TransactionFilter = props => {
  const {
    classes,
    merchants,
    values,
    handleSubmit,
    isLoading,
    storeError,
    dateError,
    timeError,
    cardError,
    transactionTypeError,
    isSubmitDisabled,
    handleToggleCustomSettings,
    handleSelectStore,
    handleDateRangeChange,
    handleDateChange,
    handleTimeRangeChange,
    handleTimeChange,
    handleCardTypeChange,
    handleCardChange,
    handleCategoryChange,
    handleTransactionTypeChange,
    handleReset,
    handleStatusChange
  } = props

  const {
    showStoreSelector,
    store,
    dateRange,
    startDate,
    endDate,
    isSingleStore,
    showCustomSettings,
    timeRange,
    startTime,
    endTime,
    hightlightTime,
    highlight,
    cardType,
    cards,
    transactionCategory,
    transactionTypes,
    status,
    page,
    limit,
    suffix,
    terminalId,
    approved,
    purchaseAmount
  } = values
  const { width } = useWindowDimensions()
  const [allStoresSelected, setAllStoresSelected] = useState(false)
  const [selectedValue, setSelectedValues] = useState(null)
  const [maxSelectedValue, setMaxSelectedValues] = useState(false)
  const [multipleStoresSelected, setmultipleStoresSelected] = useState(isSubmitDisabled)
  const displayHeader = merchants.length > 1

  useEffect(() => {
    if (!store) {
      return
    }
    if (store?.length > 1) {
      setmultipleStoresSelected(true)
      return
    }

    if (store.length < 2 || isSubmitDisabled) {
      setmultipleStoresSelected(false)
      return
    }
  }, [store])

  const [options, setOptions] = useState(() => {
    return merchants.map(merchant => {
      return { value: merchant.cardAcceptorIdCode, label: merchant.displayName }
    })
  })

  useEffect(() => {
    if (selectedValue && selectedValue.length > STORES_MAX_SELECT) {
      setMaxSelectedValues(true)
    }
  }, [selectedValue])

  const resetAll = () => {
    setAllStoresSelected(false)
    setmultipleStoresSelected(false)
    setSelectedValues(null)
    handleSelectStore(null)
    handleReset()
  }
  let cardAcceptorIdCodes = ''
  const adaptedStore = storeAdapter(store)
  if (adaptedStore != null) {
    cardAcceptorIdCodes = adaptedStore
      .map(store => {
        if (store) {
          return store.value
        }
      })
      .join(',')
  }

  const transactionTimeFrom = getDateTime(startDate, startTime).toISOString()
  const transactionTimeTo = getDateTime(endDate, endTime).toISOString()
  const cardsString = cards ? cards.join(',') : ''
  const tranTypesString = transactionTypes ? transactionTypes.join(',') : ''
  const searchQueryString = `cardLogos=${cardsString}&cardAcceptorIdCode=${cardAcceptorIdCodes}&transTypes=${tranTypesString}&limit=${limit}&page=${page}&purchaseAmount=${purchaseAmount}&approved=${status}&terminalId=${terminalId}&suffix=${suffix}&cardType=${cardType}&dateRange=${dateRange}&name=multi&transactionCategory=${transactionCategory}&transactionTimeFrom=${transactionTimeFrom}&transactionTimeTo=${transactionTimeTo}&timeRange=${timeRange}`

  const onChange = (selected, allStoresSelected) => {
    if (allStoresSelected === true) {
      setSelectedValues(null)
      handleSelectStore(selected)
      return
    }

    if (!Array.isArray(selected)) {
      setMaxSelectedValues(false)
      setSelectedValues([selected])
      handleSelectStore([selected])
      return
    }

    if (selected && selected.length <= STORES_MAX_SELECT) {
      setMaxSelectedValues(false)
      setSelectedValues(selected)
      handleSelectStore(selected)
    }

    if (selected && selected.length > STORES_MAX_SELECT) {
      setMaxSelectedValues(true)
    }
  }

  const customSettingsClassName = showCustomSettings ? 'showCustomSettings' : 'hideCustomSettings'

  useEffect(() => {
    if (storeError && storeError.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  })

  const placeHolder = allStoresSelected ? 'All Stores Selected' : 'Search stores'

  return (
    <form onSubmit={handleSubmit} name="TransactionsFilter">
      <div className={classes['mainFilter']}>
        {' '}
        {displayHeader && (
          <div className={classes['mulitSelectAnouncement']}>
            {' '}
            {`Exporting to CSV is now available with up to ${STORES_MAX_SELECT} stores selected`}
          </div>
        )}
        {!isSingleStore && (
          <div className={classes['storeSection']}>
            <br></br>
            {!showStoreSelector && (
              <div className={classes['label']}>
                <Text variant="h5">Store Select</Text>
              </div>
            )}

            {/* Store section */}
            <div className={classes['content']}>
              <Select
                value={take(selectedValue, STORES_MAX_SELECT)}
                isDisabled={allStoresSelected}
                onChange={onChange}
                options={options}
                isMulti
                isMultiMaxed={maxSelectedValue}
                isSearchable={true}
                placeholder={placeHolder}
                isClearable={true}
                errorMessage={storeError}
              />
              {maxSelectedValue && (
                <div className={classes['messageErrorRow']}>
                  <Message variant="error" text={`Only ${STORES_MAX_SELECT} stores can be selected`} showIcon={false} />
                </div>
              )}
              <br />
              {options.length <= STORES_MAX_SELECT && (
                <Checkbox
                  label="Select All Stores"
                  checked={allStoresSelected}
                  onChange={() => {}}
                  onClick={() => {
                    if (allStoresSelected) {
                      setAllStoresSelected(false)
                      onChange(null)
                      return
                    }

                    setAllStoresSelected(true)
                    onChange(options, true)
                  }}
                />
              )}

              {selectedValue && selectedValue.length > 1 && (
                <div>
                  <Text variant="note">
                    {' '}
                    You have chosen 2 or more stores, to view your report, please download by clicking on the "Export"
                    button.
                  </Text>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Date Section */}
        <div className={classes['dateSection']}>
          <div className={classes['label']}>
            <Text variant="h5">Date Range</Text>
          </div>
          <div className={classes['content']}>
            <DateSection
              {...{
                classes,
                dateRange,
                handleDateRangeChange,
                handleDateChange,
                startDate,
                endDate,
                dateError,
                highlight,
                options: OPTIONS
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes[customSettingsClassName]}>
        {showCustomSettings && (
          <div className={classes['hideControl']}>
            <div
              className={classes['hidecustomSettingLabel']}
              onClick={() => {
                handleToggleCustomSettings(false)
              }}
            >
              <Icon name="arrowUp" color={colors.icon} />
              <div className="label">Hide custom settings</div>
            </div>
          </div>
        )}
        {/* Custom Settings */}
        {showCustomSettings && (
          <>
            <div className={classes['timePeriodSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Time Period</Text>
              </div>
              <div className={classes['content']}>
                <TimePeriodSelector
                  {...{
                    classes,
                    timeRange,
                    startTime,
                    endTime,
                    handleTimeRangeChange,
                    handleTimeChange,
                    timeError,
                    hightlight: hightlightTime
                  }}
                />
              </div>
            </div>

            {/* Purchase Amount  */}
            <div className={classes['purchaseAmountSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Purchase Amount</Text>
              </div>
              <div className={classes['content']}>
                <div className={classes['purchaseAmount']}>
                  <FormikInput mask="currency" name="purchaseAmount" placeholder={'Enter the purchase amount'} />
                </div>
              </div>
            </div>

            {/* Last 4  digitis  */}
            <div className={classes['last4DigitsSections']}>
              <div className={classes['label']}>
                <Text variant="h5">Last 4 Digits</Text>
              </div>
              <div className={classes['content']}>
                <div className={classes['last4Digits']}>
                  <FormikInput
                    name="suffix"
                    customMaskProps={{ mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/] }}
                    placeholder={'Enter the last 4 digits of the card'}
                  />
                </div>
              </div>
            </div>

            {/* Terminal Id */}
            <div className={classes['terminalsSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Terminal</Text>
              </div>
              <div className={classes['content']}>
                <div className={classes['terminal']}>
                  <FormikInput name="terminalId" placeholder="Enter a Terminal Id" />
                </div>
              </div>
            </div>

            {/* Card Type Section */}
            <div className={classes['cardTypeSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Card Type</Text>
              </div>
              <div className={classes['content']}>
                <CardTypeSelector
                  cardType={cardType}
                  cards={cards}
                  handleCardTypeChange={handleCardTypeChange}
                  handleCardChange={handleCardChange}
                  error={cardError}
                />
                {cardError && (
                  <div className={classes['errorMessage']}>
                    <Message variant="error" showIcon={false} text={cardError} />{' '}
                  </div>
                )}
              </div>
            </div>

            {/* Transaction Type Section */}
            <div className={classes['transactionTypeSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Transaction Type</Text>
                <TypeHelpModal />
              </div>
              <div className={classes['content']}>
                <TransactionTypeSelector
                  transactionCategory={transactionCategory}
                  transactionTypes={transactionTypes}
                  handleCategoryChange={handleCategoryChange}
                  handleTransactionTypeChange={handleTransactionTypeChange}
                />
                {transactionTypeError && (
                  <div className={classes['errorMessage']}>
                    <Message variant="error" showIcon={false} text={transactionTypeError} />{' '}
                  </div>
                )}
              </div>
            </div>

            {/* Status Section */}
            <div className={classes['statusSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Status</Text>
              </div>
              <div className={classes['content']}>
                <StatusSelector status={status} handleStatusChange={handleStatusChange} />
              </div>
            </div>
          </>
        )}
      </div>{' '}
      <div className={classes['actions']} id="actions">
        {' '}
        {!showCustomSettings && (
          <div className={'customSettingControl'}>
            <div
              className={classes['customSettingLabel']}
              onClick={() => {
                handleToggleCustomSettings(true)
              }}
            >
              <Icon name="arrowDown" color={colors.icon} />
              <div className="label">Show custom settings</div>
            </div>
          </div>
        )}
        <div className={'button'}>
          {showCustomSettings && (
            <span className={classes['resetButton']}>
              <Button onClick={resetAll} variant="secondary">
                {isLoading ? 'Loading...' : 'Reset'}
              </Button>
            </span>
          )}
          {!multipleStoresSelected && (
            <Button variant="primary" type="submit">
              {isLoading ? 'Loading...' : 'View Transactions'}
            </Button>
          )}
        </div>
        {multipleStoresSelected && (
          <div className={'export'}>
            <TransactionExport
              {...{
                isLoading,
                search: searchQueryString,
                transactions: [],
                enablePrintRender: () => {},
                removePrintMenu: true,
                enableExport: true
              }}
            />{' '}
          </div>
        )}
      </div>{' '}
    </form>
  )
}

export default withStyles(styles)(container(TransactionFilter))
