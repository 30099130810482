import React from 'react'
import { formatDateForPrint } from 'utils/filters.utils'
import { PrintLabel } from 'components/common'

const PrintHeader = ({ filter }) => {
  const { displayName, settlementDateFrom, settlementDateTo, cardAcceptorIdCodes } = filter || {}
  const date = `${formatDateForPrint(settlementDateFrom)} - ${formatDateForPrint(settlementDateTo)}`
  return (
    <div className="filterHeader">
      <PrintLabel {...{ title: 'Trading Name', value: displayName }} />
      <PrintLabel {...{ title: 'Merchant Number', value: cardAcceptorIdCodes }} />
      <PrintLabel {...{ title: 'Date', value: date }} />
    </div>
  )
}

export default PrintHeader
