import { useSelector, useDispatch } from 'react-redux'
import { dashboardSlice } from 'reduxSlices'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'

const TOP_STORE_COUNT = 5

const isNoRevenue = store => {
  if (!store) return true
  const { revenue } = store
  return revenue < 1
}

const getTotals = stores => {
  let totalRevenue = 0,
    totalSales = 0

  stores.forEach(store => {
    const { revenue, sales } = store
    totalRevenue = totalRevenue + revenue
    totalSales = totalSales + sales
  })

  return {
    totalRevenue,
    totalSales
  }
}

const getTopStoreBySales = stores => {
  const storeBySales = _.orderBy(stores, ['sales'], ['desc'])
  const firstStore = _.head(storeBySales)
  const topStore = isNoRevenue(firstStore) ? null : firstStore
  return topStore
}

const getTopStoreByRepeatCustomer = stores => {
  const storeBySales = _.orderBy(stores, ['returnCustomers', 'currentRepeatCustomerCount'], ['desc', 'desc'])
  const firstStore = _.head(storeBySales)
  const topStore = isNoRevenue(firstStore) ? null : firstStore
  return topStore
}

const computeData = stores => {
  const topStores = _.take(stores, TOP_STORE_COUNT)
  const isShowAllStores = stores.length > TOP_STORE_COUNT

  const firstStore = _.head(stores)
  const topStore = isNoRevenue(firstStore) ? null : firstStore
  const bottomStore = _.last(stores)

  const totals = getTotals(stores)
  const totalRevenue = totals.totalRevenue
  const totalSales = totals.totalSales
  const topStoreBySales = getTopStoreBySales(topStores)
  const topStoreByRepeatCustomers = getTopStoreByRepeatCustomer(topStores)

  return {
    topStores,
    isShowAllStores,
    topStore,
    bottomStore,
    totalRevenue,
    totalSales,
    topStoreBySales,
    topStoreByRepeatCustomers
  }
}

const useTopStores = () => {
  const {
    actions,
    selectors: { getAllStoresData, getFilter }
  } = dashboardSlice

  //Data Selectors
  const allStores = useSelector(getAllStoresData)
  const filters = useSelector(getFilter)
  const dispatch = useDispatch()
  const { isComparePeriod: isCompare } = filters
  const { data: stores, isLoading, isError } = allStores

  //Action
  const selectStoreAction = useCallback(store => dispatch(actions.selectStore(store)), [dispatch, actions])

  const {
    topStores,
    isShowAllStores,
    topStore,
    bottomStore,
    totalRevenue,
    totalSales,
    topStoreBySales,
    topStoreByRepeatCustomers
  } = useMemo(() => computeData(stores), [stores])

  return {
    stores,
    isLoading,
    isCompare,
    isError,
    selectStoreAction,
    topStores,
    isShowAllStores,
    topStore,
    bottomStore,
    totalRevenue,
    totalSales,
    topStoreBySales,
    topStoreByRepeatCustomers
  }
}

export default useTopStores
