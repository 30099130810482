import React from 'react'

const CustomDot = ({ cx, cy, stroke, payload, value }) => {
  return (
    <svg x={cx - 4} y={cy - 4} width={40} height={40} fill={stroke} viewBox="0 0 50 50">
      <circle cx="6" cy="6" r="6" />
    </svg>
  )
}

export default CustomDot
