import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  periodText: {
    [MOBILE_UP()]: {
      width: '100px'
    }
  }
}

export default styles
