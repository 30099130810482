import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
import * as variables from 'config/jss-vars'
const styles = {
  summary: {
    display: 'flex',
    borderBottom: `1px solid ${colors.line2}`
  },
  link: {
    flexBasis: '200px',
    textAlign: 'center',
    fontWeight: variables.weightSemiBold,
    fontSize: '1rem',
    paddingBottom: '1rem',
    width: '200px',
    color: colors.cyan,
    cursor: 'pointer',
    borderBottom: '4px solid transparent',
    transition: 'border-bottom-color 0.6s ease',
    margin: [0, '0.25rem'],
    '&:hover': {
      color: colors.teal
    },
    [mediaQueryHelper.MOBILE_DOWN()]: {
      flex: [1, 1, '200px'],
      width: 'auto',
      fontSize: '1rem'
    }
  },
  active: {
    borderBottom: `4px solid ${colors.teal} !important`
  }
}

export default styles
