import React from 'react'
import { connect, getIn } from 'formik'
import Input from 'components/Input/Input'

/**
 * Formik wrapper on the Input component. Refer Input component for props.
 */
const FormikInput = props => {
  const { name, formik, showClear, ...rest } = props
  const { handleChange, handleBlur, setFieldValue } = formik
  const error = getIn(formik.errors, name)
  const touch = getIn(formik.touched, name)
  const value = getIn(formik.values, name)
  const errorMessage = touch && error

  const clearValue = () => {
    setFieldValue(name, '', false)
  }

  const inputProps = {
    errorMessage,
    name,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    onClear: showClear ? clearValue : undefined,
    ...rest
  }

  return <Input {...inputProps} />
}

export default connect(FormikInput)
