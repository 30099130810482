import React, { Component } from 'react'
import { TransactionFilter, TransactionResult } from '../../components'
import container from './TransactionContainer'
import styles from './Transaction.styles'
import withStyles from 'react-jss'
import { Container, withPrivateLayout, HeaderLinks } from 'components/common'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import * as colors from 'config/colors'

class Transactions extends Component {
  componentWillUnmount() {
    const { props } = this
    props.actions.resetTransactions()
  }

  setSelectedTransaction = transaction => {
    const { props } = this
    props.actions.setSelectedTransaction(transaction)
  }

  onClose = () => {
    this.props.actions.set504(false)
    this.props.actions.resetTransactions()
  }

  render() {
    const { props } = this
    const {
      classes,
      isLoading,
      is504,
      routerProps,
      actions,
      merchants,
      transactions,
      totalResults,
      totalPages,
      page,
      isShowResult,
      filter,
      selectedTransaction
    } = props

    const {
      location: { search },
      history
    } = routerProps

    const title = 'Transactions'
    const links = []

    return (
      <Container>
        <Modal
          override={{
            title: {
              color: colors.teal
            }
          }}
          title="Something went wrong"
          isOpen={is504}
          onClose={this.onClose}
        >
          <div className={classes['modalMain']}>
            <Text>
              Your search criteria took too long to respond, please reduce the number of stores and days or try again
              later
            </Text>
            <div className={classes['modalFooter']}>
              <Button onClick={this.onClose} variant="secondary">
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <div className={classes['filter']}>
          <HeaderLinks {...{ title, links }} />
          <TransactionFilter {...{ search, merchants, history, isLoading, actions }} />
        </div>
        {isShowResult && (
          <div className={classes['result']}>
            <TransactionResult
              {...{
                transactions,
                totalResults,
                totalPages,
                page,
                filter,
                history,
                isLoading,
                search,
                actions,
                isShowResult,
                selectedTransaction,
                setSelectedTransaction: this.setSelectedTransaction
              }}
            />
          </div>
        )}
      </Container>
    )
  }
}

export default withPrivateLayout(container(withStyles(styles)(Transactions)))
