import { createSlice } from 'redux-starter-kit'
import moment from 'moment'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import api from '../../api'
import { getFilterDates } from 'components/FilterSection/FilterSectionContainer'

import { getMerchantAccess } from 'reduxSlices/accountSlice'
import { parseData } from './transactionAggregate.helper'
import { parseAllStoresData } from 'reduxSlices/dashboardSlice/allStoresAgg.helper'
import { parseRepeatCustomerData } from 'reduxSlices/dashboardSlice/repeatCustomerAgg.helper'
import { parseCardsUsed } from 'reduxSlices/dashboardSlice/cardAgg.helper'
import { createCustomSelector } from 'utils/common.utils'

const endOfDay = moment()
  .endOf('day')
  .toISOString()

const defaultDates = getFilterDates('1d', endOfDay)

const initialState = {
  isLoading: false,
  view: 'business',
  filter: {
    range: '1d',
    isComparePeriod: true,
    isCompareStore: false,
    store: null,
    comparedStore: null,
    storeTouched: false,
    ...defaultDates
  },
  expandedCard: undefined,
  business_allStores: {
    isLoading: true,
    data: [],
    isError: false
  },
  business_returnCustomers: {
    isLoading: true,
    data: {}
  },
  business_cardsUsed: {
    isLoading: true,
    data: {}
  },
  business_allSalesRevenueAgg: {
    isLoading: true,
    data: [],
    currentSalesTotal: 0,
    currentRevenueTotal: 0,
    previousSalesTotal: 0,
    previousRevenueTotal: 0
  }
}

const getView = createCustomSelector('dashboard.view')
const getFilter = createCustomSelector('dashboard.filter')
const isLoading = createCustomSelector('dashboard.isLoading')
const getExpandedCard = createCustomSelector('dashboard.expandedCard')
const getAllStoresData = createCustomSelector('dashboard.business_allStores')
const getAllReturnCustomers = createCustomSelector('dashboard.business_returnCustomers')
const isComparePeriod = createCustomSelector('dashboard.filter.isComparePeriod')
const isCompareStore = createCustomSelector('dashboard.filter.isCompareStore')
const getAllCardsUsed = createCustomSelector('dashboard.business_cardsUsed')
const getAllSalesRevenueAgg = createCustomSelector('dashboard.business_allSalesRevenueAgg')

const selectors = {
  isLoading,
  isComparePeriod,
  isCompareStore,
  getView,
  getFilter,
  getExpandedCard,
  getAllStoresData,
  getAllReturnCustomers,
  getAllCardsUsed,
  getAllSalesRevenueAgg
}

const reducers = {
  setView: (state, action) => {
    return {
      ...state,
      view: action.payload
    }
  },
  setFilter: (state, action) => {
    const value = action.payload
    const updatedFilter = { ...state.filter, ...value }

    return {
      ...state,
      filter: updatedFilter
    }
  },
  startLoading: state => {
    return { ...state, isLoading: true }
  },
  endLoading: state => {
    return { ...state, isLoading: false }
  },
  expandCard: (state, action) => {
    const { payload } = action
    return { ...state, expandedCard: payload }
  },
  compressCards: state => {
    return { ...state, expandedCard: undefined }
  },
  fetchAllStores: (state, action) => {
    const {
      payload: { showLoading = true }
    } = action

    const { business_allStores } = state
    const business_allStoresupdated = { ...business_allStores, isLoading: showLoading }
    return { ...state, business_allStores: business_allStoresupdated }
  },
  setAllStoreData: (state, action) => {
    const { payload } = action
    return { ...state, business_allStores: { data: payload, isLoading: false, isError: false } }
  },
  setAllStoreError: state => {
    return { ...state, business_allStores: { data: [], isLoading: false, isError: true } }
  },
  selectStore: (state, action) => {
    const { payload } = action
    return { ...state, view: 'store', filter: { ...state.filter, store: payload } }
  },
  fetchAllReturnCustomers: (state, action) => {
    const {
      payload: { showLoading = true }
    } = action
    const { business_returnCustomers } = state
    const business_returnCustomersUpdated = { ...business_returnCustomers, isLoading: showLoading }
    return { ...state, business_returnCustomers: business_returnCustomersUpdated }
  },
  setAllStoreReturnCustomers: (state, action) => {
    const { payload } = action
    const business_returnCustomersUpdated = { data: payload, isLoading: false }
    return { ...state, business_returnCustomers: business_returnCustomersUpdated }
  },
  fetchAllCardsUsed: (state, action) => {
    const {
      payload: { showLoading = true }
    } = action
    const { business_cardsUsed } = state
    const business_cardsUsedUpdated = { ...business_cardsUsed, isLoading: showLoading }
    return { ...state, business_cardsUsed: business_cardsUsedUpdated }
  },
  setAllCardsUsed: (state, action) => {
    const { payload } = action
    const business_cardsUsed = { data: payload, isLoading: false }
    return { ...state, business_cardsUsed: business_cardsUsed }
  },
  setAllCardsUsedError: state => {
    return { ...state, business_cardsUsed: { data: {}, isLoading: false, isError: true } }
  },
  fetchAllSalesRevenueAgg: (state, action) => {
    const {
      payload: { showLoading = true }
    } = action
    const { business_allSalesRevenueAgg } = state
    const business_allSalesRevenueAggUpdated = { ...business_allSalesRevenueAgg, isLoading: showLoading }
    return { ...state, business_allSalesRevenueAgg: business_allSalesRevenueAggUpdated }
  },
  setAllSalesRevenueAgg: (state, action) => {
    const { payload } = action
    const business_allSalesRevenueAggUpdated = { ...payload, isLoading: false }
    return { ...state, business_allSalesRevenueAgg: business_allSalesRevenueAggUpdated }
  },
  fetchStoreRepeatCustomers: (state, action) => {
    const {
      payload: { showLoading = true }
    } = action
    const { payload } = action
    const primaryStore_returnCustomersUpdated = { data: payload, isLoading: showLoading }
    return { ...state, primaryStore_returnCustomers: primaryStore_returnCustomersUpdated }
  },
  setStoreRepeatCustomers: (state, action) => {
    const { payload } = action
    const primaryStore_returnCustomersUpdated = { data: payload, isLoading: false }
    return { ...state, primaryStore_returnCustomers: primaryStore_returnCustomersUpdated }
  }
}

const dashboardSlice = createSlice({
  slice: 'dashboard',
  initialState: initialState,
  reducers
})

function* fetchAllStores(action) {
  const { payload } = action
  const merchants = yield select(getMerchantAccess)
  const filter = yield select(getFilter)
  const view = yield select(getView)
  try {
    const result = yield call(api.getAllStoresInformation, payload)
    const parsedResult = parseAllStoresData(merchants, result)

    // If more than 1 stores, set the top store as the default selected store.
    if (parsedResult.length > 1 && !filter.storeTouched && view !== 'store') {
      const { displayName, cardAcceptorIdCode } = parsedResult[0]
      yield put(
        dashboardSlice.actions.setFilter({
          store: { displayName, cardAcceptorIdCode }
        })
      )
    }

    yield put(dashboardSlice.actions.setAllStoreData(parsedResult))
  } catch (e) {
    yield put(dashboardSlice.actions.setAllStoreError())
  }
}

function* fetchAllSalesRevenueAgg(action) {
  const { payload } = action
  try {
    const result = yield call(api.getAllStoresSalesRevenueInformation, payload)
    const filter = yield select(getFilter)
    const parsedResult = parseData({ data: result, filter })
    yield put(dashboardSlice.actions.setAllSalesRevenueAgg(parsedResult))
  } catch (e) {
    console.error(e)
  }
}

function* fetchAllReturnCustomers(action) {
  const { payload } = action
  try {
    const result = yield call(api.getAllReturnCustomers, payload)
    const filter = yield select(getFilter)
    const { isComparePeriod } = filter
    const parsedResult = parseRepeatCustomerData(result, isComparePeriod)
    yield put(dashboardSlice.actions.setAllStoreReturnCustomers(parsedResult))
  } catch (e) {
    console.error(e)
  }
}

function* fetchAllCardsUsed(action) {
  const { payload } = action
  try {
    const cardsUsed = yield call(api.getAllCardsUsed, payload)
    const parsedResult = parseCardsUsed(cardsUsed)

    yield put(dashboardSlice.actions.setAllCardsUsed(parsedResult))
  } catch (e) {
    yield put(dashboardSlice.actions.setAllCardsUsedError())
  }
}

function* dashboardSagas() {
  yield takeLatest(dashboardSlice.actions.fetchAllStores.toString(), fetchAllStores)
  yield takeLatest(dashboardSlice.actions.fetchAllReturnCustomers.toString(), fetchAllReturnCustomers)
  yield takeLatest(dashboardSlice.actions.fetchAllCardsUsed.toString(), fetchAllCardsUsed)
  yield takeLatest(dashboardSlice.actions.fetchAllSalesRevenueAgg.toString(), fetchAllSalesRevenueAgg)
}

export default {
  ...dashboardSlice,
  selectors,
  sagas: dashboardSagas
}
