import axios from './axiosDefaults'
import handleError from 'utils/error.utils'

const USERS_BY_ORGANIZATION = 'insights/user'

export const getAllUsersByOrg = async () => {
  try {
    return await axios.get(USERS_BY_ORGANIZATION)
  } catch (error) {
    handleError(error)
  }
}

export const getUsersByEmail = async email => {
  try {
    const encodedEmail = encodeURIComponent(email)
    const url = `${USERS_BY_ORGANIZATION}/?email=${encodedEmail}`
    return await axios.get(url)
  } catch (error) {
    handleError(error)
  }
}

export const sendEmailVerification = async user => {
  return inviteUser(user)
}

export const deleteUser = async userId => {
  const url = `${USERS_BY_ORGANIZATION}/${userId}`
  try {
    return await axios.delete(url)
  } catch (error) {
    handleError(error)
  }
}

export const updateUser = async updatedUser => {
  const { firstName, lastName, email, roles, id } = updatedUser

  const data = {
    id,
    firstName,
    lastName,
    email,
    roles
  }

  const url = `${USERS_BY_ORGANIZATION}/${id}`
  try {
    return await axios.put(url, data)
  } catch (error) {
    handleError(error)
  }
}

export const inviteUser = async user => {
  const { firstName, lastName, email, roles } = user

  const data = {
    firstName,
    lastName,
    email,
    roles
  }

  try {
    return await axios.post(USERS_BY_ORGANIZATION, data)
  } catch (error) {
    handleError(error)
  }
}
