import React, { Component } from 'react'
import withStyles from 'react-jss'
import styles from './SettlementFilter.styles'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'
import container, {
  computeState,
  SUMMARY_TYPE,
  DATE_RANGE_CHANGE,
  DATE_CHANGE,
  STORE_SELECT,
  SHOW_SELECTOR,
  HIDE_SELECTOR,
  pushSummaryTypeToHistory
} from './SettlementFilterContainer'
import DateSection from '../DateSection/DateSection'
import SummaryLinks from './SummaryLinks/SummaryLinks'
import isEmpty from 'lodash/isEmpty'
import { toggleFlagWithTimeout } from '../../utils/filters.utils'
import Select from 'components/Select/Select'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import { SERVER_DATE_FORMAT } from 'constants/general.constants'
import SettlementExport from 'components/SettlementResult/SettlementExport/SettlementExport'
import LoaderUI from 'components/LoaderCommonsUI/LoaderUI'
import { storeAdapter } from 'components/TransactionFilter/TransactionFilter.helper'
import { getConfig } from 'utils/config.utils'
import { take } from 'lodash'
import Message from 'components/Message/Message'

const {
  features: { StoreMaxSelect: STORES_MAX_SELECT }
} = getConfig()
const { BREAK_POINT_MEDIUM } = mediaQueryHelper
class SettlementFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allStoresSelected: false,
      selectedValue: null,
      maxSelectedValue: false,
      multipleStoresSelected: false,
      type: 'merchant',
      width: 0,
      height: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.getMultipleStoresSelected = this.getMultipleStoresSelected.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { submitForm, values } = this.props
    window.removeEventListener('resize', this.updateWindowDimensions)

    if (values.store) {
      submitForm()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values && this.props.values) {
      const prevStore = storeAdapter(prevProps.values.store)
      const currentStore = storeAdapter(this.props.values.store)

      const { hideResults } = this.props
      if (currentStore !== prevStore) {
        if (currentStore && currentStore.length > 1) {
          if (!hideResults) {
            this.props.setHideResults(true)
          }
        }
        if (currentStore && currentStore.length <= 1) {
          if (hideResults) {
            this.props.setHideResults(false)
          }
        }
      }

      const { summaryType: prevSummaryType } = prevProps
      const { summaryType } = this.props.values
      if (prevSummaryType != summaryType) {
        this.props.setSummaryType(summaryType)
      }
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  updateFormik = formUpdates => {
    const { values, setValues } = this.props
    const updatedValues = { ...values, ...formUpdates }
    setValues(updatedValues)
  }

  handleShowSelector = () => {
    const stateChange = computeState({ actions: SHOW_SELECTOR })
    this.updateFormik(stateChange)
  }

  handleSelectStore = store => {
    const { setSelectedStores } = this.props
    setSelectedStores(store)
    let stateChange = null
    const { actions } = this.props
    if (store !== undefined) {
      actions.hideResult()
      stateChange = computeState({ actions: STORE_SELECT, props: { store } })
      //Updating form state in the next cycle
      setTimeout(() => {
        this.updateFormik(stateChange)
      })
    } else {
      stateChange = computeState({ actions: HIDE_SELECTOR })
      this.updateFormik(stateChange)
    }
  }

  handleDateRangeChange = dateRange => {
    const stateChange = computeState({ actions: DATE_RANGE_CHANGE, props: { dateRange } })
    this.updateFormik(stateChange)
    this.highlight()
  }

  highlight = () => {
    toggleFlagWithTimeout(this.updateFormik)
  }

  handleDateChange = async ({ startDate, endDate }) => {
    const { validateForm } = this.props
    const stateChange = computeState({ actions: DATE_CHANGE, props: { startDate, endDate } })
    this.updateFormik(stateChange)
    await validateForm(stateChange)
  }

  getStoreError = ({ submitCount, errors }) => {
    if (submitCount > 0) {
      return errors['store']
    }
    return false
  }

  getDateError = ({ errors }) => {
    const error = errors['startDate'] || errors['endDate']
    return error
  }

  isDisabled = ({ storeError, dateError, isSubmitting, isLoading, showStoreSelector }) => {
    const isDisabled = isLoading || isSubmitting || storeError || dateError || showStoreSelector
    return isDisabled
  }

  handleCheckboxClick = options => {
    const { allStoresSelected } = this.state
    const { setSelectedStores } = this.props
    if (allStoresSelected) {
      this.setState({ allStoresSelected: false })
      this.onChange(null)
      return
    }

    this.setState({ allStoresSelected: true })
    this.onChange(options, true)
    setSelectedStores(options)
  }

  onChange = (selected, allStoresSelected) => {
    this.setState({ maxSelectedValue: false })

    if (allStoresSelected === true) {
      this.setState({ selectedValue: null })
      this.handleSelectStore(selected)
    }

    if (!Array.isArray(selected)) {
      this.setState({ selectedValue: [selected] })
      this.handleSelectStore([selected])
      return
    }

    if (selected.length <= STORES_MAX_SELECT) {
      this.setState({ maxSelectedValue: false })
      this.setState({ selectedValue: selected })
      this.handleSelectStore(selected)
    } else {
      this.setState({ maxSelectedValue: true })
    }
  }

  getMultipleStoresSelected = store => {
    if (!store) {
      return false
    }
    if (store.length > 1) {
      return true
    }

    if (store.length < 2) {
      return false
    }
  }

  render() {
    const { allStoresSelected, selectedValue, type } = this.state
    const {
      classes,
      merchants,
      values,
      errors,
      submitCount,
      handleSubmit,
      isSubmitting,
      isLoading,
      actions
    } = this.props
    const { showStoreSelector, store, summaryType, dateRange, startDate, endDate, highlight, isSingleStore } = values
    const storeError = this.getStoreError({ submitCount, errors })
    const dateError = this.getDateError({ errors })
    const isSubmitDisabled = this.isDisabled({ storeError, dateError, isSubmitting, isLoading, showStoreSelector })
    const multipleStoresSelected = this.getMultipleStoresSelected(store)

    const formattedStartDate = startDate.format(SERVER_DATE_FORMAT)
    const formattedEndDate = endDate.format(SERVER_DATE_FORMAT)
    const x = startDate.format(SERVER_DATE_FORMAT)
    let cardAcceptorIdCodes = ''
    const adaptedStore = storeAdapter(store)
    if (adaptedStore != null) {
      cardAcceptorIdCodes = adaptedStore
        .map(store => {
          if (store) {
            return store.value
          }
        })
        .join(',')
    }
    const filter = {
      cardAcceptorIdCodes: cardAcceptorIdCodes,
      displayName: 'multi',
      settlementDateFrom: formattedStartDate,
      settlementDateTo: formattedEndDate,
      summaryType: summaryType,
      enableExportCSV: true
    }

    const options = []
    merchants.forEach(merchant => {
      options.push({ value: merchant.cardAcceptorIdCode, label: merchant.displayName })
    })

    const handleSelectSummaryType = async type => {
      const { values, history, validateForm, setSummaryType } = this.props
      setSummaryType(type)
      this.setState({ type: type })

      const stateChange = computeState({
        actions: SUMMARY_TYPE,
        props: { type }
      })
      const { store = {} } = values

      if (!store) {
        return this.updateFormik(stateChange)
      }

      const params = { ...values, ...stateChange }
      const error = await validateForm(params)
      if (!isEmpty(error)) {
        return this.updateFormik(stateChange)
      }

      return pushSummaryTypeToHistory(params, history)
    }
    const placeHolder = allStoresSelected ? 'All Stores Selected' : 'Search stores'
    const displayMultiDownloadStoreHeader = merchants.length > 1

    return (
      <form onSubmit={handleSubmit} name="SettlementFilter">
        <div className={classes['container']}>
          {' '}
          {isLoading && <LoaderUI />}
          <div className={classes['section']}>
            <SummaryLinks {...{ classes, action: handleSelectSummaryType, activeLink: type }} />
          </div>
          <div className={classes['formInputs']}>
            {displayMultiDownloadStoreHeader && (
              <div className={classes['mulitSelectAnouncement']}>
                {' '}
                {`Exporting to CSV is now available with up to ${STORES_MAX_SELECT} stores selected`}
              </div>
            )}
            {!isSingleStore && (
              <div className={classes['storeSection']}>
                {!showStoreSelector && (
                  <div className={classes['label']}>
                    <Text variant="h5">Store Select</Text>
                  </div>
                )}
                <div className={classes['content']}>
                  <Select
                    value={take(selectedValue, STORES_MAX_SELECT)}
                    isDisabled={allStoresSelected}
                    onChange={this.onChange}
                    options={options}
                    isMulti
                    isSearchable={true}
                    placeholder={placeHolder}
                    isClearable={true}
                    errorMessage={storeError}
                  />
                  {this.state.maxSelectedValue && (
                    <div className={classes['messageErrorRow']}>
                      <Message
                        variant="error"
                        text={`Only ${STORES_MAX_SELECT} stores can be selected`}
                        showIcon={false}
                      />
                    </div>
                  )}
                  <br />
                  {options.length <= STORES_MAX_SELECT && (
                    <Checkbox
                      label="Select All Stores"
                      checked={allStoresSelected}
                      onClick={() => this.handleCheckboxClick(options)}
                      onChange={() => {}}
                    />
                  )}
                  {multipleStoresSelected && (
                    <div>
                      <Text variant="note">
                        {' '}
                        You have chosen 2 or more stores, to view your report, please download by clicking on the
                        "Export" button.
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={classes['dateSection']}>
              <div className={classes['label']}>
                <Text variant="h5">Date Range</Text>
              </div>
              <div className={classes['content']}>
                <DateSection
                  {...{
                    classes,
                    dateRange,
                    handleDateRangeChange: this.handleDateRangeChange,
                    handleDateChange: this.handleDateChange,
                    startDate,
                    endDate,
                    dateError,
                    highlight
                  }}
                />
              </div>
            </div>
            {!multipleStoresSelected && (
              <div className={`${classes['actions']}`}>
                <div className={'button'}>
                  <Button variant="primary" size="full" type="submit" disabled={isSubmitDisabled}>
                    {isLoading ? 'Loading...' : 'View Settlement'}
                  </Button>
                </div>
              </div>
            )}
            {multipleStoresSelected && (
              <div className={`${classes['csvdownload']}`}>
                <SettlementExport
                  requestParams={{
                    filter: filter,
                    terminalSummary: true,
                    actions: actions,
                    enablePrint: () => {},
                    terminalSummary: undefined,
                    merchantSummary: undefined,
                    removePrintMenu: true,
                    isLoading: isLoading
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </form>
    )
  }
}

export default withStyles(styles)(container(SettlementFilter))
