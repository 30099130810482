import React, { Component } from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import Modal from 'components/Modal/Modal'
import withStyles from 'react-jss'
import styles from './TypeHelpModal.styles'
import transactionTypes from './TransactionTypes.constants'

const ModalContent = ({ classes }) => {
  return (
    <div className={classes['modalContent']}>
      <div className={classes['header']}>
        <Icon name="info" width={'1.5em'} height={'1.5em'} className={classes['helpIcon']} />{' '}
        <Text variant="h3"> Transactions</Text>
      </div>
      <div className={classes['transctionTypes']}>
        {transactionTypes.map(({ title, description }) => {
          return (
            <div className={classes['type']} key={title}>
              <div className={classes['typeHeader']}>
                <Text variant="h4"> {title}</Text>
              </div>
              <div className={classes['typeDescription']}>
                <Text variant="body"> {description}</Text>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

class TypeHelpModal extends Component {
  state = {
    isModalOpen: false
  }

  toggleModal = isModalOpen => {
    this.setState({
      isModalOpen
    })
  }

  openModal = () => {
    this.toggleModal(true)
  }

  closeModal = () => {
    this.toggleModal(false)
  }

  render() {
    return (
      <>
        <div onClick={this.openModal}>
          <Icon name="info" width={'1.15em'} height={'1.15em'} className={'icon'} />
        </div>

        <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal} size={'large'}>
          <StyledModalContent closeModal={this.closeModal} />
        </Modal>
      </>
    )
  }
}

export default TypeHelpModal
