import * as colors from 'config/colors'
import { standardFontFamily, weightMedium } from 'config/jss-vars'

const outerWidth = '20px'
const radioDisabledColor = 'rgba(0,0,0,.26)'

const styles = {
  root: {
    position: 'relative',
    marginBottom: '1.5rem',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    extend: ({ override }) => ({
      ...override['root']
    })
  },
  input: {
    position: 'absolute',
    width: 0,
    height: 0,
    padding: 0,
    margin: 0,
    border: 0,
    opacity: 0,
    appearance: 'none',
    '&:focus + div': {
      boxShadow: `0 0 1px 1px ${colors.teal}`
    }
  },

  text: {
    whiteSpace: 'nowrap',
    position: 'relative',
    paddingLeft: '8px',
    cursor: 'pointer',
    lineHeight: '20px',
    display: 'inline-block',
    color: colors.greyDark,
    fontFamily: standardFontFamily,
    fontWeight: weightMedium,
    fontSize: '1rem',
    extend: ({ override }) => ({
      ...override['text']
    })
  },

  radio: {
    position: 'relative',
    display: 'inline-block',
    width: outerWidth,
    height: outerWidth,
    verticalAlign: 'top',
    borderRadius: '50%',
    cursor: 'pointer',
    border: `1px solid ${colors.teal}`,

    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      borderRadius: '50%',
      transition: 'transform .35s cubic-bezier(.4,0,.2,1)',
      transform: 'scale(0)',
      background: colors.teal,
      extend: ({ override }) => ({
        ...override['radio:before']
      })
    },
    extend: ({ override }) => ({
      ...override['radio']
    })
  },
  // https://github.com/cssinjs/jss/issues/796
  // We use modified styles like this until above issue is released
  // if we need to change nested selectors
  'root--checked': {
    '& $radio': {
      '&:before': {
        transform: 'scale(0.65)',
        extend: ({ override }) => ({
          ...override['root--checked $radio&:before']
        })
      },
      extend: ({ override }) => ({
        ...override['root--checked $radio']
      })
    },
    extend: ({ override }) => ({
      ...override['root--checked']
    })
  },
  'root--disabled': {
    '& $radio': {
      cursor: 'auto',
      borderColor: radioDisabledColor,
      '&:before': {
        position: 'absolute',
        backgroundColor: radioDisabledColor
      }
    },
    '& $text': {
      color: radioDisabledColor
    },
    extend: ({ override }) => ({
      ...override['root--disabled']
    })
  }
}

export default styles
