import { standardFontFamily, weightSemiBold, weightMedium } from 'config/jss-vars'

import {
  white,
  line1,
  accentDark,
  accentLight,
  brandDark,
  orangeLight,
  orangeDark,
  warningBackground,
  successLight,
  errorLight,
  infoIcon,
  infoHover,
  cyan,
  teal,
  highlight
} from 'config/colors'

const styles = {
  base: {
    cursor: 'pointer',
    fontFamily: standardFontFamily,
    fontSize: '1rem',
    lineHeight: '1rem',
    textAlign: 'center',
    display: 'inline-block',
    outline: 'none'
  },
  button: {
    composes: '$base',
    borderRadius: 20,
    padding: '0.625rem 2rem',
    letterSpacing: 0,
    height: 40,
    boxShadow: '0 1px 2px 0 rgba(90, 60, 159, 0.2)',
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  primary: {
    composes: '$button',
    background: cyan,
    border: {
      color: cyan,
      width: '1px',
      style: 'solid'
    },
    color: white,
    fontWeight: weightSemiBold,
    '&:hover': {
      background: teal,
      border: {
        color: teal,
        width: '1px',
        style: 'solid'
      },
      extend: ({ override }) => ({
        ...override['primary:hover']
      })
    },
    '&:active': {
      background: teal,
      extend: ({ override }) => ({
        ...override['primary:active']
      })
    },
    extend: ({ override }) => ({
      ...override.primary
    })
  },
  secondary: {
    composes: '$button',
    fontSize: '1rem',
    lineHeight: '1rem',
    background: white,
    color: cyan,
    border: {
      color: line1,
      width: '1px',
      style: 'solid'
    },
    fontWeight: weightMedium,
    '&:hover': {
      border: {
        color: teal,
        width: '1px',
        style: 'solid'
      },
      color: teal
    },
    '&:active': {
      background: highlight
    },
    extend: ({ override }) => ({
      ...override.secondary
    })
  },
  tertiary: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: 'transparent',
      border: {
        color: accentDark,
        width: '2px',
        style: 'solid'
      },
      color: accentDark,
      '@media (min-width: 800px)': {
        '&:hover': {
          backgroundColor: accentLight,
          color: '#fff'
        }
      },
      '&:active': {
        filter: 'brightness(75%)'
      }
    },
    extend: ({ override }) => ({
      ...override.tertiary
    })
  },
  inverse: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: 'transparent',
      border: {
        color: brandDark,
        width: '2px',
        style: 'solid'
      },
      color: brandDark,
      '@media (min-width: 800px)': {
        '&:hover': {
          backgroundColor: brandDark,
          color: '#fff'
        }
      },
      '&:active': {
        filter: 'brightness(75%)'
      }
    },
    extend: ({ override }) => ({
      ...override.inverse
    })
  },
  alternative: {
    composes: '$button',
    '&, &:focus': {
      backgroundColor: orangeLight,
      backgroundImage: 'linear-gradient(270deg, #ff6546 0, #f7822a 110%)',
      border: 0,
      color: '#fff',
      '&:after': {
        backgroundColor: 'transparent',
        opacity: 0
      },
      '&:hover': {
        backgroundColor: orangeLight,
        color: '#fff',
        '&:after': {
          backgroundColor: orangeDark,
          opacity: 1
        }
      }
    },
    extend: ({ override }) => ({
      ...override.alternative
    })
  },
  'alternative-inverse': {
    composes: '$button',
    backgroundColor: 'transparent',
    border: {
      color: orangeLight,
      width: '2px',
      style: 'solid'
    },
    color: orangeLight,
    transition: 'color 0.2s ease-in-out',
    '&:active': {
      filter: 'brightness(75%)'
    },
    '&:hover': {
      border: {
        color: orangeDark,
        width: '2px',
        style: 'solid'
      },
      color: orangeDark
    },
    extend: ({ override }) => ({
      ...override['alternative-inverse']
    })
  },
  link: {
    composes: '$base',
    border: 0,
    color: cyan,
    cursor: 'pointer',
    display: 'inline-block',
    padding: 0,
    backgroundColor: 'transparent',
    fontWeight: weightSemiBold,
    fontSize: '0.875rem',
    height: '0.875rem',
    lineHeight: '0.875rem',
    letterSpacing: 0.2,
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5,
      pointerEvents: 'none'
    },
    '&:hover': {
      color: teal
    },
    extend: ({ override }) => ({
      ...override['link']
    })
  },
  withIcon: {
    composes: '$button',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.4rem 0.5rem',
    fontWeight: weightSemiBold,
    '& >.buttonIcon': {
      marginRight: '.5rem'
    },
    '&:hover': {
      '& >.buttonIcon': {
        '& * path': {
          fill: '#fff'
        },
        '& * polygon': {
          fill: '#fff'
        },
        '& * rect': {
          fill: '#fff'
        },
        '& * [fill="none"]': {
          fill: `none !important`
        }
      }
    },
    '&, &:focus': {
      backgroundColor: 'transparent',
      border: {
        color: warningBackground,
        width: '2px',
        style: 'solid'
      },
      color: warningBackground,
      '&:hover': {
        backgroundColor: warningBackground,
        color: '#fff'
      },
      '&:active': {
        filter: 'brightness(75%)'
      }
    },
    extend: ({ override }) => ({
      ...override.withIcon
    })
  },
  full: {
    width: '100%',
    extend: ({ override }) => ({
      ...override['full']
    })
  },
  small: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `1.625rem`,
    borderRadius: '0.8125rem',
    padding: '0.22rem 0.5rem',
    fontSize: '0.75rem',
    '& >.buttonIcon': {
      marginRight: '0.4rem'
    },
    extend: ({ override }) => ({
      ...override['small']
    })
  },
  light: {
    borderRadius: 3,
    fontWeight: 300,
    extend: ({ override }) => ({
      ...override['light']
    })
  },
  success: {
    composes: '$button',
    background: successLight,
    border: {
      color: successLight,
      width: '1px',
      style: 'solid'
    },
    color: white,
    transition: 'color 0.2s ease-in-out',
    fontWeight: weightSemiBold,
    '&:hover': {
      filter: 'brightness(110%)',
      border: {
        filter: 'brightness(110%)',
        width: '1px',
        style: 'solid'
      }
    },
    '&:active': {
      filter: 'brightness(90%)'
    },
    extend: ({ override }) => ({
      ...override.success
    })
  },
  error: {
    composes: '$button',
    background: errorLight,
    border: {
      color: errorLight,
      width: '1px',
      style: 'solid'
    },
    color: white,
    transition: 'color 0.2s ease-in-out',
    fontWeight: weightSemiBold,
    '&:hover': {
      filter: 'brightness(110%)',
      border: {
        filter: 'brightness(110%)',
        width: '1px',
        style: 'solid'
      }
    },
    '&:active': {
      filter: 'brightness(90%)'
    },
    extend: ({ override }) => ({
      ...override.error
    })
  },
  info: {
    composes: '$primary',
    background: infoIcon,
    border: {
      color: infoIcon,
      width: '1px',
      style: 'solid'
    },
    '&:hover': {
      background: infoHover,
      border: {
        color: infoHover,
        width: '1px',
        style: 'solid'
      }
    },
    '&:active': {
      background: infoIcon
    },
    extend: ({ override }) => ({
      ...override.info
    })
  },
  'info-secondary': {
    composes: '$secondary',
    color: infoIcon,
    border: {
      color: infoIcon,
      width: '1px',
      style: 'solid'
    },
    '&:hover': {
      border: {
        color: infoHover,
        width: '1px',
        style: 'solid'
      },
      color: infoHover
    },
    extend: ({ override }) => ({
      ...override['info-secondary']
    })
  }
}

export default styles
