import React from 'react'
import TransactionTable from './TransactionTable'
import PropTypes from 'prop-types'
import { formatDateTimeForPrint } from 'utils/filters.utils'
import { PrintLabel } from 'components/common'
import { getCurrency } from 'utils/number.utils'
import { getStatusText } from 'components/TransactionFilter/StatusSelector/StatusSelector'

const PrintHeader = ({ filter }) => {
  const {
    cardAcceptorIdCode,
    transactionTimeFrom,
    transactionTimeTo,
    name,
    purchaseAmount,
    suffix,
    terminalId,
    approved
  } = filter || {}
  const time = `${formatDateTimeForPrint(transactionTimeFrom)} - ${formatDateTimeForPrint(transactionTimeTo)}`
  return (
    <div className="filterHeader">
      <PrintLabel {...{ title: 'Trading Name', value: name }} />
      <PrintLabel {...{ title: 'Merchant Number', value: cardAcceptorIdCode }} />
      <PrintLabel {...{ title: 'Time', value: time }} />
      <PrintLabel
        {...{ title: 'Purchase Amount', value: purchaseAmount != null ? getCurrency(purchaseAmount) : undefined }}
      />
      <PrintLabel {...{ title: 'Last 4 Digits', value: suffix }} />
      <PrintLabel {...{ title: 'Terminal', value: terminalId }} />
      <PrintLabel {...{ title: 'Status', value: approved > -1 ? getStatusText(approved) : undefined }} />
    </div>
  )
}

const PrintableTransactionTable = ({ filter, transactions }) => {
  return (
    <div style={{ display: 'none' }}>
      <div id="printableTransactions">
        <h2>Transaction Summary</h2>
        <PrintHeader {...{ filter }} />
        <br /> <br />
        <TransactionTable
          {...{
            transactions,
            isLoading: false,
            classes: {},
            onSelectTransaction: () => {},
            selectedTransaction: null,
            isPaginated: false
          }}
        />
      </div>
    </div>
  )
}

PrintableTransactionTable.prototype = {
  filter: PropTypes.object,
  transactions: PropTypes.array
}
export default PrintableTransactionTable
