import React from 'react'
import styles from './RemoveStoreAccess.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'

const RemoveStoreAccessConfirmation = ({ classes, user, displayName }) => {
  const { firstName } = user

  return (
    <div className={classes['body']} data-hj-suppress>
      <Text variant="body">
        Are you sure you want to delete this store for {firstName}? Once deleted, it will have to be added again.
      </Text>

      <div className={classes['storeName']} data-hj-suppress>
        <Text variant="body">{displayName}</Text>
      </div>
    </div>
  )
}

const StyledRemoveStoreAccessConfirmation = withStyles(styles)(RemoveStoreAccessConfirmation)

export default StyledRemoveStoreAccessConfirmation
