import _ from 'lodash'
import { getReturnPercentage } from './repeatCustomerAgg.helper'
import { NA } from 'constants/general.constants'

export const parseAllStoresData = (merchants, apiResponse) => {
  let merchantsDictionary = getMerchantDictionary(merchants)
  const { current, previous } = apiResponse

  const { transactionsAgg, returnCustomersAgg } = current
  const merchantDictWithTransactions = addTransactionAggData(merchantsDictionary, transactionsAgg)
  const merchantDictWithReturnCustomers = addReturnAggData(merchantDictWithTransactions, returnCustomersAgg)

  merchantsDictionary = merchantDictWithReturnCustomers

  if (previous) {
    const { transactionsAgg: prevTranscAgg, returnCustomersAgg: prevReturnCustAgg } = previous
    const merchantWithTransactionDiff = addTransactionDifference(merchantDictWithReturnCustomers, prevTranscAgg)
    const merchantsWithReturnCustDiff = addReturnCustomersDifference(merchantWithTransactionDiff, prevReturnCustAgg)
    merchantsDictionary = merchantsWithReturnCustDiff
  }

  const allStores = getSortedStores(merchantsDictionary)
  return allStores
}

const getMerchantDictionary = (merchants = []) => {
  const merchantsDictionary = _.reduce(
    merchants,
    (obj, param) => {
      const { cardAcceptorIdCode, displayName } = param
      obj[cardAcceptorIdCode] = {
        displayName,
        revenue: 0,
        sales: 0,
        returnCustomers: 0,
        cardAcceptorIdCode,
        salesChangePercentage: NA,
        revenueChangePercentage: NA,
        returnCustomersChangePercentage: NA
      }
      return obj
    },
    {}
  )

  return merchantsDictionary
}
const addTransactionAggData = (merchantsDictionary = {}, transactionAgg = []) => {
  if (_.isEmpty(transactionAgg)) {
    return merchantsDictionary
  }

  transactionAgg.forEach(transaction => {
    const { cardAcceptorIdCode, transactionCount, transactionValue } = transaction
    const merchant = merchantsDictionary[cardAcceptorIdCode]
    merchant['revenue'] = transactionValue
    merchant['sales'] = transactionCount
  })

  return { ...merchantsDictionary }
}

const addReturnAggData = (merchantsDictionary = {}, returnCustomerAgg = []) => {
  if (_.isEmpty(returnCustomerAgg)) {
    return merchantsDictionary
  }

  returnCustomerAgg.forEach(transaction => {
    const { cardAcceptorIdCode, newCustomerCount, repeatCustomerCount } = transaction
    const returnCustomers = getReturnPercentage(newCustomerCount, repeatCustomerCount)
    const merchant = merchantsDictionary[cardAcceptorIdCode]
    merchant['returnCustomers'] = returnCustomers
    merchant['curentNewCustomerCount'] = newCustomerCount
    merchant['currentRepeatCustomerCount'] = repeatCustomerCount
  })

  return { ...merchantsDictionary }
}
const getSortedStores = (merchantsDictionary = {}) => {
  const allStores = _.values(merchantsDictionary)
  return _.orderBy(allStores, ['revenue'], ['desc'])
}

const addTransactionDifference = (merchantsDictionary = {}, previousTransactionAgg = []) => {
  if (_.isEmpty(previousTransactionAgg)) {
    return merchantsDictionary
  }

  previousTransactionAgg.forEach(transaction => {
    const { cardAcceptorIdCode, transactionCount = 0, transactionValue = 0 } = transaction
    const merchant = merchantsDictionary[cardAcceptorIdCode]

    const currentTransactionValue = merchant['revenue']
    const currentTransactionCount = merchant['sales']

    merchant['revenueChangePercentage'] = Math.round(
      ((currentTransactionValue - transactionValue) / transactionValue) * 100
    )
    merchant['salesChangePercentage'] = Math.round(
      ((currentTransactionCount - transactionCount) / transactionCount) * 100
    )
  })

  return { ...merchantsDictionary }
}

export const addReturnCustomersDifference = (merchantsDictionary = {}, prevReturnCustAgg = []) => {
  if (_.isEmpty(prevReturnCustAgg)) {
    return merchantsDictionary
  }

  prevReturnCustAgg.forEach(transaction => {
    const { cardAcceptorIdCode, newCustomerCount, repeatCustomerCount } = transaction
    const merchant = merchantsDictionary[cardAcceptorIdCode]

    merchant['prevNewCustomerCount'] = newCustomerCount
    merchant['prevRepeatCustomerCount'] = repeatCustomerCount

    const returnCustomers = merchant['returnCustomers']
    const prevCustomerPercentage = getReturnPercentage(newCustomerCount, repeatCustomerCount)

    let percentageDiff = returnCustomers - prevCustomerPercentage
    merchant['returnCustomersChangePercentage'] = percentageDiff
  })

  return { ...merchantsDictionary }
}
