import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './PaginationDots.styles'

const PaginationDots = ({ classes, pageNumber, numberOfPages, setActivePage }) => {
  return (
    <>
      {[...Array(numberOfPages).keys()].map(page => {
        const dotClass = page === pageNumber ? 'dotActive' : 'dot'
        return (
          <span
            key={page}
            className={classes[dotClass]}
            onClick={() => page !== pageNumber && setActivePage(page)}
          ></span>
        )
      })}
    </>
  )
}

PaginationDots.propTypes = {
  pageNumber: PropTypes.number,
  numberOfPages: PropTypes.number,
  setActivePage: PropTypes.func
}

PaginationDots.defaultProps = {
  pageNumber: 0,
  numberOfPages: 0,
  setActivePage: {}
}

export default withStyles(styles)(PaginationDots)
