export const TranTypes = {
  0: 'Completion',
  1: 'Refund Reversal',
  2: 'Payment Reversal',
  3: 'Pre-auth',
  4: 'Refund',
  5: 'Purchase',
  6: 'Other',
  7: 'Enquiry',
  8: 'Payment',
  9: 'Preauth Reversal'
}

export const getTransactionType = type => {
  return TranTypes[type]
}
