import { Children, cloneElement } from 'react'

const RenderChildByType = ({ children, childType, ...rest }) => {
  if (Children.count(children) > 0) {
    return Children.map(children, child => {
      if (child.type === childType) {
        return cloneElement(child, {
          ...rest
        })
      }
      return null
    })
  }
  return null
}

export default RenderChildByType
