import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as variables from 'config/jss-vars'
import * as colors from 'config/colors'
const { MOBILE_UP, up, BREAK_POINT_LARGE } = mediaQueryHelper

const LARGE = up(BREAK_POINT_LARGE)
const styles = {
  table: {
    width: '100%',
    color: colors.greyDark,
    borderCollapse: 'collapse',
    border: `1px solid ${colors.line2} !important`,
    '& * tr': {
      border: `1px solid ${colors.line2} !important`,
      transition: 'all 0.2s ease background 0.6s ease'
    },
    '& * td': {
      border: `1px solid ${colors.line2} !important`,
      padding: ['0.25rem', '0.5rem'],
      height: '30px',
      cursor: 'pointer'
    },
    '& * th': {
      border: `1px solid ${colors.line2} !important`,
      padding: ['1rem', '0.5rem', '0.5rem']
    },
    '& >thead': {
      textAlign: 'left',
      fontSize: '0.75rem',
      letterSpacing: '0.03125rem',
      fontWeight: variables.weightSemiBold,
      lineHeight: '0.875rem',
      borderBottom: `1px solid ${colors.line2}`
    },
    '& >tbody': {
      textAlign: 'left',
      fontSize: '0.875rem',
      letterSpacing: '0.01875rem',
      lineHeight: '1rem',
      fontWeight: variables.weightRegular,
      '& >tr:hover': {
        boxShadow:
          'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)'
      }
    },
    tranition: 'all 1s ease'
  },
  terminalIdCol: {
    textAlign: 'left',
    display: 'none',
    [MOBILE_UP()]: {
      display: 'table-cell'
    }
  },
  transactionDateCol: {
    textAlign: 'left',
    '& > .mobile': {
      display: 'block',
      [MOBILE_UP()]: {
        display: 'none'
      }
    },
    '& > .desktop': {
      display: 'none',
      [MOBILE_UP()]: {
        display: 'block'
      }
    }
  },
  settlementCol: {
    composes: '$transactionDateCol'
  },
  last4Col: {
    textAlign: 'left',
    display: 'none',
    [MOBILE_UP()]: {
      display: 'table-cell'
    }
  },
  transactionNoCol: {
    textAlign: 'left',
    display: 'none',
    [LARGE]: {
      display: 'table-cell'
    }
  },
  cardCol: {
    textAlign: 'left',
    display: 'none',
    [MOBILE_UP()]: {
      display: 'table-cell'
    }
  },
  typeCol: {
    textAlign: 'left',
    display: 'none',
    [LARGE]: {
      display: 'table-cell'
    }
  },
  cashCol: {
    textAlign: 'right',
    display: 'none',
    [LARGE]: {
      display: 'table-cell'
    }
  },
  purchaseCol: {
    textAlign: 'right'
  },
  statusCol: {
    textAlign: 'left',
    '& > .mobile': {
      display: 'table-cell',
      [MOBILE_UP()]: {
        display: 'none'
      },
      paddingLeft: '50%'
    },
    '& > .desktop': {
      display: 'none',
      [MOBILE_UP()]: {
        display: 'table-cell'
      },
      '& >div': {
        paddingLeft: '10px'
      }
    }
  },
  pagination: {
    margin: '2rem auto 2rem',
    width: '100%',
    '& >div': {
      margin: 'auto'
    }
  },
  errorMessage: {
    textAlign: 'center',
    marginBottom: '1rem'
  },
  highlight: {
    background: colors.highlight
  }
}

export default styles
