import React, { useState, useEffect } from 'react'
import styles from './CollectionSearch.styles'
import withStyles from 'react-jss'
import _ from 'lodash'
import SearchInput from 'components/common/SearchInput'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Message from 'components/Message/Message'
import { doFuzzySearch } from 'utils/filters.utils'

const PLACEHOLDER = 'Search stores for this user by name.'
const VISIBLE_COUNT = 6

const CollectionSearch = ({
  classes,
  list,
  searchKey,
  onRemove,
  errorMessage = 'No matching record found',
  isDisabled = false
}) => {
  const [text, setText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [isExpand, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!isExpand)
  }

  useEffect(() => {
    setFilteredList(list)
  }, [list])

  const onSearch = e => {
    const { value } = e.target
    setText(value)
    const filteredList = doFuzzySearch(list, value, { keys: [searchKey] })
    setFilteredList(filteredList)
  }

  const displayList = isExpand ? filteredList : _.take(filteredList, VISIBLE_COUNT)
  const matchingText = `${text ? 'matching stores' : 'stores set up for this user'}`
  const isLastItem = list.length === 1

  const isShowRemoveBtn = !isDisabled && !isLastItem

  return (
    <div className={classes['searchWrapper']}>
      <div className={classes['searchBox']}>
        <SearchInput
          {...{
            placeholder: PLACEHOLDER,
            text,
            onChange: onSearch
          }}
        />
      </div>

      {filteredList.length === 0 && text && (
        <div className={classes['alignMessage']}>
          <Message variant="error" showIcon={false} text={errorMessage} />
        </div>
      )}

      <div className={classes['result']}>
        {displayList.map((item, index) => {
          return (
            <div key={index} className={classes['searchItem']}>
              <div className={classes['text']}>
                <Text color={colors.greyDark} variant="body">
                  {_.get(item, searchKey)}
                </Text>
              </div>

              {//Dont show the remove button if its the last store
              isShowRemoveBtn && (
                <div
                  onClick={() => {
                    onRemove(item)
                  }}
                >
                  <Icon name="clear-filled" />
                </div>
              )}
            </div>
          )
        })}
      </div>

      {filteredList.length > VISIBLE_COUNT && (
        <div className={classes['showMore']} onClick={handleClick}>
          <Text variant="header-link" color={colors.cyan}>
            {isExpand ? `Show less` : `Show all ${filteredList.length} ${matchingText}`}
          </Text>
          <div>
            <Icon width={'1rem'} height={'1rem'} name={isExpand ? 'arrowUpRegular' : 'arrowDownRegular'} />
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(CollectionSearch)
