import React from 'react'
import PublicLayout from 'components/PublicLayout/PublicLayout'
import Loader from 'components/common/Loader/Loader'
import { SSORegistrationRoute, VerifyAccountRoute, VerifyInviteRoute } from 'Routes'
import { logout } from 'utils/logout.utils'

const DEFAULT_LINKS = [
  {
    name: 'Login',
    onClick: e => {
      e.preventDefault()
      logout()
    }
  },
  {
    name: 'Register'
  }
]

const withPublicLayout = Component => {
  return props => {
    const {
      routerProps: { location = {} },
      showLinks = true
    } = props

    const { pathname } = location
    const isRegisterLink = [SSORegistrationRoute.path].includes(pathname)
    const isVerifyLink = [VerifyAccountRoute.path, VerifyInviteRoute.path].includes(pathname)

    const links = showLinks ? (isVerifyLink ? DEFAULT_LINKS.slice(0, 1) : DEFAULT_LINKS) : null
    const activeLink = links ? (isVerifyLink ? links[0].name : isRegisterLink ? links[1].name : links[0].name) : null

    return (
      <>
        <PublicLayout links={links} iconName="insights" activeLink={activeLink}>
          <Component {...props} />
        </PublicLayout>
        <Loader />
      </>
    )
  }
}

export default withPublicLayout
