import * as colors from 'config/colors'
import * as variables from 'config/jss-vars'
const styles = {
  container: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center'
  },
  proceedBtn: {
    marginRight: '40px'
  }
}

export default styles
