import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, BREAK_POINT_LARGE, up } = mediaQueryHelper

const styles = {
  transactionDetail: {
    background: colors.white,
    borderRadius: '4px',
    border: '1px solid rgb(223, 222, 227)',
    boxShadow: '0px 1px 2px 0px rgba(201, 200, 200, 0.5)',
    height: 'auto',
    padding: ['1.25rem', '0.75rem'],
    position: 'absolute',
    transition: 'all 0.5s ease',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '330px',
    [MOBILE_UP()]: {
      position: 'relative',
      left: 0,
      transform: 'none',
      width: 'auto',
      marginLeft: '0.25rem'
    },
    [up(BREAK_POINT_LARGE)]: {
      marginLeft: '1rem'
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    }
  },
  details: {
    borderTop: `1px solid ${colors.line1}`
  },
  detail: {
    display: 'flex',
    paddingTop: '0.25rem',
    borderBottom: `1px solid ${colors.line1}`,
    transition: 'all 0.5s ease'
  },
  title: {
    width: '130px'
  },
  value: {
    width: 'auto',
    paddingLeft: '5px'
  }
}

export default styles
