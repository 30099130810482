import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
import tableStyles from '../../styles/Table.styles'

const { TABLET_DOWN, MOBILE_UP, up, BREAK_POINT_LARGE, BREAK_POINT_MEDIUM, MOBILE_DOWN } = mediaQueryHelper

const styles = {
  table: tableStyles,
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'grid',
    msGridColumns: 'minmax(min-content, 1fr)',
    gridTemplateColumns: 'auto',
    padding: ['1.5rem', '1em', '1em'],
    [up(BREAK_POINT_LARGE)]: {
      msGridColumns: 'minmax(min-content, 1fr) 250px',
      gridTemplateColumns: 'auto 250px',
      padding: ['1.5rem', '2em', '1em']
    },
    color: colors.greyDark,
    borderBottom: `1px solid ${colors.line1}`,
    borderTop: `1px solid ${colors.cyan}`,
    '& .export': {
      display: 'inline-block',
      textAlign: 'center',
      [up(BREAK_POINT_MEDIUM)]: {
        float: 'right',
        textAlign: 'right',
        display: 'block',
        msGridColumn: 2,
        msGridRow: 1
      }
    },
    '& .export > DIV': {
      [MOBILE_DOWN()]: {
        float: 'none',
        display: 'inline-block'
      }
    },
    '& .title': {
      margin: ['auto', 0],
      textAlign: 'center',
      msGridColumn: 1,
      msGridRow: 1,
      [up(BREAK_POINT_LARGE)]: {
        textAlign: 'left'
      }
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.cyan}`
  },
  title: {
    padding: ['1.5rem', '1em', '1em'],
    display: 'grid',
    gridTemplateColumns: '1fr',
    msGridRows: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    color: colors.greyDark,
    gridTemplateAreas: `
                        'name' 
                        'date'
                        'number'
                        `,
    '& .name': {
      gridArea: 'name',
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 1,
      msGridColumnSpan: 1
    },
    '& .date': {
      gridArea: 'date',
      padding: ['0.5em'],
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 2,
      msGridColumnSpan: 1
    },
    '& .number': {
      gridArea: 'number',
      padding: [0, '0.5em'],
      margin: 'auto',
      msGridColumn: 1,
      msGridRow: 3,
      msGridColumnSpan: 1
    },
    [MOBILE_UP()]: {
      msGridColumns: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      msGridRows: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gridTemplateAreas: `
                          'name name name name' 
                          'date date number number'`,
      '& .name': {
        msGridColumnSpan: 4
      },
      '& .date': {
        float: 'right',
        margin: 'auto 0',
        msGridColumnSpan: 2
      },
      '& .number': {
        float: 'left',
        margin: 'auto 0',
        msGridColumn: 3,
        msGridRow: 2,
        msGridColumnSpan: 2
      }
    },
    [up(BREAK_POINT_LARGE)]: {
      padding: ['1.5em', '2em', '0.5em'],
      gridTemplateAreas: `'name date number'`,
      msGridColumns: 'minmax(min-content, 1fr) 300px 225px',
      gridTemplateColumns: 'auto 300px 225px',
      msGridRows: '1fr',
      gridTemplateRows: '1fr',
      '& .name': {
        margin: '0',
        msGridColumnSpan: 1
      },
      '& .number': {
        textAlign: 'left',
        paddingLeft: '1.5em',
        msGridColumn: 2,
        msGridRow: 1,
        msGridColumnSpan: 1
      },
      '& .date': {
        margin: 'auto 0',
        msGridColumn: 3,
        msGridRow: 1,
        msGridColumnSpan: 1
      }
    }
  },

  icon: {
    width: '10px',
    height: '10px',
    float: 'left',
    margin: ['7px', '10px', 0, 0]
  },
  colCard: {
    width: '20%',
    minWidth: '185px',
    verticalAlign: 'top',
    [TABLET_DOWN()]: {
      '@media screen': {
        width: '60%'
      }
    }
  },
  colType: {
    width: '20%',
    minWidth: '185px',
    [TABLET_DOWN()]: {
      display: 'none'
    }
  },
  colCount: {
    width: '10%',
    textAlign: 'right',
    [TABLET_DOWN()]: {
      display: 'none'
    }
  },
  colPurchase: {
    width: '15%',
    textAlign: 'right',
    [TABLET_DOWN()]: {
      display: 'none'
    }
  },
  colCashout: {
    width: '15%',
    textAlign: 'right',
    [TABLET_DOWN()]: {
      display: 'none'
    }
  },
  colTotal: {
    width: '20%',
    textAlign: 'right',
    verticalAlign: 'bottom',
    [TABLET_DOWN()]: {
      width: '40%'
    }
  },
  tabletGraph: {
    display: 'none',
    textAlign: 'center',
    margin: [0, 0, '2rem', 0],
    '& >div': {
      flex: [1, 1, 'auto'],
      margin: 'auto'
    },
    [up(BREAK_POINT_MEDIUM)]: {
      display: 'flex'
    },
    [up(BREAK_POINT_LARGE)]: {
      display: 'none'
    }
  },
  messageContainer: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.greyDark,
    fontSize: '1.25em'
  },
  emptyMessageContainer: {
    height: '4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '1.5em'
  }
}

export default styles
