import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, BREAK_POINT_LARGE, up } = mediaQueryHelper

const styles = {
  userList: {
    paddingTop: '1rem',
    paddingBottom: '2rem'
  },
  list: {
    paddingTop: '0.5rem'
  },
  listHeader: {
    width: 'calc(100% - 2.2rem)',
    paddingLeft: '1rem'
  },
  header: {
    display: 'none',
    width: '100%',
    alignItems: 'center',
    padding: [0, '2rem', 0, '1rem'],
    [MOBILE_UP()]: {
      display: 'flex'
    }
  },
  avatar: {
    flexBasis: '80px',
    marginBottom: '0.25rem'
  },
  name: {
    flex: [2, 1, '0%'],
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '0.5rem',
    wordBreak: 'break-word'
  },
  email: {
    flex: [3, 1, '0%'],
    composes: '$name',
    display: 'none',
    wordBreak: 'break-word',
    [up(BREAK_POINT_LARGE)]: {
      display: 'flex'
    }
  },
  store: {
    composes: '$name'
  },
  status: {
    flex: [2, 1, '0%'],
    composes: '$name',
    wordBreak: 'break-word'
  },
  role: {
    flex: [1, 1, '0%'],
    composes: '$name',
    wordBreak: 'break-word'
  },
  openHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  avatarName: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: '1rem'
    },
    '& > :first-child': {
      marginBottom: '0.25rem'
    }
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > .line1': {
      display: 'flex',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${colors.line2}`,
      padding: [0, 0, '0.5rem', 0],
      margin: [0, '0.5rem', '1rem', 0]
    },
    '& > .line2': {
      display: 'flex',
      '& > :first-child': {
        borderRight: `1px solid ${colors.line2}`,
        marginRight: '1rem'
      }
    },
    [MOBILE_UP()]: {
      display: 'none'
    }
  },
  errorMessage: {
    textAlign: 'center',
    margin: ['1rem', 0]
  }
}

export default styles
