import base from '../SettlementResult.styles'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, up, BREAK_POINT_LARGE, BREAK_POINT_MEDIUM } = mediaQueryHelper

const styles = {
  ...base,
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
                        'title' 
                        'table'
                        'graph'
                        `,
    margin: [0, '1em'],
    padding: ['1.5em', 0, '2.5em'],
    borderTop: `1px solid ${colors.line1}`,
    '& .title': {
      gridArea: 'title',
      msGridColumn: 1,
      msGridRow: 1
    },
    '& .table': {
      gridArea: 'table',
      msGridColumn: 1,
      msGridRow: 2
    },
    '& .graph': {
      gridArea: 'graph',
      padding: ['1em', 0, 0, 0],
      margin: [0, 'auto'],
      msGridColumn: 1,
      msGridRow: 3
    },
    [MOBILE_UP()]: {
      msGridColumns: 'minmax(min-content, 1fr) 200px',
      gridTemplateColumns: 'auto 200px',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `
                          'title title' 
                          'table graph'
                          `,
      '& .title': {
        msGridColumnSpan: 2
      },
      '& .graph': {
        msGridColumn: 2,
        msGridRow: 2
      },
      '@media print': {
        gridTemplateAreas: `
                          'title title' 
                          'table table'
                          `,
        '& .title': {
          msGridColumnSpan: 2
        },
        '& .table': {
          msGridColumnSpan: 2
        },
        '& .graph': {
          display: 'none'
        }
      }
    },
    [up(BREAK_POINT_MEDIUM)]: {
      msGridColumns: '1fr',
      gridTemplateColumns: '1fr',
      msGridRows: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
                          'title' 
                          'table'
                        `,
      '& .title': {
        msGridColumnSpan: 1
      },
      '& .table': {
        msGridColumnSpan: 1
      },
      '& .graph': {
        display: 'none'
      },
      margin: [0, '1em']
    },
    [up(BREAK_POINT_LARGE)]: {
      msGridColumns: 'minmax(min-content, 1fr) 230px',
      gridTemplateColumns: 'auto 230px',
      msGridRows: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
                          'title title' 
                          'table graph'
                          `,
      '& .title': {
        msGridColumnSpan: 2
      },
      '& .table': {
        msGridColumnSpan: 1
      },
      '& .graph': {
        display: 'grid'
      },
      margin: [0, '1.5em']
    }
  }
}

export default styles
