import * as colors from 'config/colors'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER } from 'constants/general.constants'

const owner_color = '#519D9E'
const globalViewer_color = '#4987A9'
const viewer_color = '#51B8D3'

const styles = {
  avatar: {
    width: ({ height }) => height || '6.5rem',
    display: 'flex',
    height: ({ height }) => height || '6.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    background: ({ role }) => {
      switch (role) {
        case ROLE_OWNER:
          return owner_color
        case ROLE_GLOBAL_VIEWER:
          return globalViewer_color
        default:
          return viewer_color
      }
    },
    borderRadius: '6rem'
  },
  title: {
    fontSize: ({ fontSize }) => fontSize || '2rem',
    color: colors.white
  }
}

export default styles
