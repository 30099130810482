import React, { useState } from 'react'
import styles from './SearchBar.styles'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import _ from 'lodash'
import SearchInput from 'components/common/SearchInput'

const SearchBar = ({ classes, onSearch = _.noop, text, placeholder }) => {
  const [search, setSearch] = useState('')

  const onChange = e => {
    const { value } = e.target
    setSearch(value)
    onSearch(value)
  }

  return (
    <div className={classes['searchWrapper']}>
      <div className={classes['searchBox']}>
        <SearchInput
          {...{
            placeholder,
            search,
            onChange
          }}
        />
      </div>
      {text && (
        <div className={classes['infoText']}>
          <Text variant="note"> {text}</Text>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(SearchBar)
