import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LAPTOP_UP } = mediaQueryHelper

const styles = {
  errorMessage: {
    marginTop: '1rem',
    textAlign: 'center'
  },
  selectors: {
    display: 'flex',
    flexDirection: 'column',
    [LAPTOP_UP()]: {
      flexDirection: 'row'
    }
  },
  dateSelector: {
    borderRadius: '3px',
    width: '100%',
    [LAPTOP_UP()]: {
      width: '268px',
      float: 'right'
    }
  },
  dateRange: {
    width: '100%',
    padding: [0, 0, '1rem', 0],

    [LAPTOP_UP()]: {
      width: '50%',
      padding: [0, '1rem', 0, 0]
    }
  },
  settlementFilter: {
    width: '100%',
    [LAPTOP_UP()]: {
      width: '50%'
    }
  },
  highlight: {
    borderBottom: `1.5px solid ${colors.teal}`
  }
}

export default styles
