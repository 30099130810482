import React from 'react'
import { CreditToBank, Deferred } from './MerchantSummary'
import PrintHeader from '../SettlementTable/PrintHeader'
import PropTypes from 'prop-types'
import { CREDIT_TO_BANK_BASE_COLOR, DEFERED_BASE_COLOR } from '../../../constants/colors.contants'

const PrintableMerchantSummary = ({ filter, data, isLoading }) => {
  const { creditToBank, deferred } = data || {}

  if (isLoading) {
    return null
  }

  return (
    <div style={{ display: 'none' }}>
      <div id="printableMerchantSummary">
        <h2>Merchant Summary</h2>
        <PrintHeader {...{ filter }} />
        <br /> <br />
        <h4>Credit to bank </h4>
        <CreditToBank {...{ data: creditToBank, classes: {}, baseColor: CREDIT_TO_BANK_BASE_COLOR, isLoading }} />
        <br />
        <h4>Deferred </h4>
        <Deferred {...{ data: deferred, classes: {}, baseColor: DEFERED_BASE_COLOR, isLoading }} />
      </div>
    </div>
  )
}

PrintableMerchantSummary.prototype = {
  filter: PropTypes.object,
  data: PropTypes.object,
  isLoading: PropTypes.bool
}

export default PrintableMerchantSummary
