import React from 'react'
import PropTypes from 'prop-types'
import objstr from 'obj-str'
import withStyles from 'react-jss'
import styles from './Radio.styles'

const RadioButton = props => {
  //NOTE override is used in styles.js, extracted from rest params so that its not appended on the element.
  const { override, disabled, id, label, name, onClick, value, onChange, onBlur, checked, classes, ...rest } = props

  const rootClassName = objstr({
    [classes['root']]: true,
    [classes['root--disabled']]: disabled,
    [classes['root--checked']]: checked
  })

  return (
    <label htmlFor={id} value={value} onClick={onClick} className={rootClassName}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        className={classes['input']}
        {...rest}
      />

      <div data-react-toolbox="radio" className={classes['radio']} />

      {label ? <span className={classes['text']}>{label}</span> : null}
    </label>
  )
}

RadioButton.propTypes = {
  /**
   * Label to be shown with the radio btn
   */
  label: PropTypes.any,
  /*
   * Name for the element
   */
  name: PropTypes.string,

  id: PropTypes.string,

  /**
   *
   */
  onClick: PropTypes.func,
  /**
   *
   */
  onChange: PropTypes.func,

  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    root: PropTypes.object,
    text: PropTypes.object,
    radio: PropTypes.object,
    'radio:before': PropTypes.object,
    'root--checked': PropTypes.object,
    'root--checked $radio': PropTypes.object,
    'root--checked $radio&:before': PropTypes.object,
    'root--disabled': PropTypes.object
  })
}

RadioButton.defaultProps = {
  override: {}
}

export default withStyles(styles)(RadioButton)
