import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { BREAK_POINT_LARGE, up, MOBILE_UP } = mediaQueryHelper

const isDesktop = up(BREAK_POINT_LARGE)

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    padding: ['0.5rem'],
    [isDesktop]: {
      padding: ['1.5rem', '1.5rem', 0, '1.5rem'],
      flexDirection: 'row'
    }
  },
  commentary: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: [0, '1.5rem', '0.5rem'],
    [MOBILE_UP()]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    [isDesktop]: {
      flexDirection: 'column',
      width: '220px',
      textAlign: 'left',
      padding: [0, '0.5rem'],
      justifyContent: 'start'
    }
  },
  graph: {
    height: ({ graphHeight = 160 }) => {
      return graphHeight
    },
    padding: ({ graphBias }) => (graphBias ? ['2rem', 0, 0, 0] : 0),
    marginLeft: ({ graphBias }) => (graphBias ? '-1.5rem' : 0),
    width: '100%',
    [up(BREAK_POINT_LARGE)]: {
      width: '90%',
      maxWidth: 'calc(100% - 200px)',
      height: ({ isCompareStore, graphHeight = 160, graphBias }) => {
        if (!graphBias) {
          return 'auto'
        }
        return isCompareStore ? graphHeight : graphHeight * 2
      }
    }
  },
  text: {
    [MOBILE_UP()]: {
      padding: [0, '1.5rem', '1.5rem', '1.5rem'],
      flex: [2, 1, 'auto'],
      textAlign: 'left'
    },
    [isDesktop]: {
      padding: 0
    }
  },
  rank: {
    [MOBILE_UP()]: {
      width: '100%',
      textAlign: 'left'
    }
  },
  totals: {
    marginBottom: '0.5rem',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'inherit',
    [MOBILE_UP()]: {
      justifyContent: 'start'
    }
  },
  currencyValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  changeValue: {
    marginLeft: '0.5rem'
  },
  name: {
    marginBottom: '1rem',
    textAlign: 'center',
    display: 'none',

    [isDesktop]: {
      textAlign: 'left',
      display: 'block'
    }
  },
  nameMobile: {
    display: 'block',
    textAlign: 'center',
    margin: ['0.5rem', 0, 0],
    [isDesktop]: {
      display: 'none'
    }
  }
}

export default styles
