import React from 'react'
import PropTypes from 'prop-types'
import LoadingGraph from '../LoadingIndicators/LoadingGraph'
import CardsPieChart from '../../CardsPieChart/CardsPieCharts'

const CardGraph = ({ isLoading, baseColor, data, classes }) => {
  if (isLoading) return <LoadingGraph />
  return <CardsPieChart {...{ baseColor, data, classes }} />
}

CardGraph.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  baseColor: PropTypes.string,
  data: PropTypes.any
}

export default CardGraph
