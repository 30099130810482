import * as colors from 'config/colors'
import { standardFontFamily, weightRegular } from 'config/jss-vars'

const styles = {
  multiValueLabel: (provided, state) => ({
    backgroundColor: colors.highlight,
    borderStyle: 'solid none solid solid',
    borderWidth: '1px',
    borderColor: colors.cyan
  }),
  multiValueRemove: (provided, state) => ({
    backgroundColor: colors.highlight,
    borderStyle: 'solid solid solid none',
    borderWidth: '1px',
    borderColor: colors.cyan,
    '&:hover': {
      backgroundColor: colors.teal,
      color: colors.white
    }
  }),
  control: (provided, state) => {
    let stateStyles = {}

    if (state.isFocused) {
      stateStyles = {
        ...stateStyles,
        boxShadow: `0 0 0 0 ${colors.boxshadowcyan}`
      }
    }

    if (state.menuIsOpen) {
      stateStyles = {
        ...stateStyles,
        border: `1px solid ${colors.cyan}`,
        borderRadius: `3px 3px 0px 0px`
      }
    }

    return {
      ...provided,
      border: `1px solid ${colors.line1}`,
      borderRadius: '3px',
      color: colors.greyDark,
      fontFamily: standardFontFamily,
      fontSize: '1rem',
      fontWeight: weightRegular,
      lineHeight: '1.7rem',
      '&:hover': {
        border: `1px solid ${colors.cyan}`,
        boxShadow: `0 2px 2px 0 ${colors.line2}`
      },
      '&:active': {
        border: `1px solid ${colors.teal}`,
        boxShadow: `0 2px 2px 0 ${colors.line2}`
      },
      ...stateStyles
    }
  },
  menu: provided => ({
    ...provided,
    border: `1px solid ${colors.cyan}`,
    borderTop: `0px`,
    marginTop: '-2px',
    boxShadow: `0 2px 2px 0 ${colors.line2}`,
    borderRadius: `0px 0px 3px 3px`
  }),
  option: (provided, state) => {
    let stateStyles = {}

    if (state.isSelected) {
      stateStyles = {
        background: colors.highlight
      }
    }

    return {
      ...provided,
      color: colors.teal,
      fontFamily: standardFontFamily,
      fontSize: '1rem',
      fontWeight: weightRegular,
      '&:hover': {
        color: colors.teal,
        background: colors.highlight
      },
      '&:active': {
        background: colors.active
      },
      ...stateStyles
    }
  },
  //Commented out as it stops the clear all selected (X) feature work on multi select for stores (causes a new problem)
  // Need to investigate where this is causing an issue for touch on tablet and phones on custom svg icons?
  /* valueContainer: provided => ({
    ...provided,
    // Important: Work around to allow for touch on tablet and phones on custom svg icons
    marginRight: '-40px'
  }), */
  singleValue: provided => ({
    ...provided,
    color: colors.greyDark
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.greyMedium
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colors.teal,
    padding: '0 10px'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  menuList: provided => ({
    ...provided,
    borderTop: `1px solid ${colors.line2}`
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    color: colors.teal,
    '&:hover': {
      color: colors.teal
    }
  })
}

export default styles
