import * as mediaQueryHelper from 'config/mediaQueryHelper'
import { getFeatureFlag, FEATURE_FLAG_CARDS_USED } from 'utils/config.utils'
const { up, BREAK_POINT_EXTRA_LARGE } = mediaQueryHelper
const isDesktopScreen = up(BREAK_POINT_EXTRA_LARGE)

const cardsUsedFeatureFlag = getFeatureFlag(FEATURE_FLAG_CARDS_USED)

const styles = {
  content: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    msGridRows: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
                            'legends'
                            'commentary'`,
    [isDesktopScreen]: {
      gridTemplateRows: cardsUsedFeatureFlag ? 'auto 200px' : 'auto auto',
      gridTemplateAreas: `
                                'commentary'
                                'legends'`
    }
  },
  commentary: {
    gridArea: 'commentary',
    msGridColumn: 1,
    msGridRow: 1,
    [isDesktopScreen]: {
      flexDirection: 'column',
      msGridRow: 2
    }
  },
  legends: {
    gridArea: 'legends',
    display: 'flex',
    padding: ['1rem', 0],
    msGridColumn: 1,
    msGridRow: 2,
    [isDesktopScreen]: {
      flexDirection: 'column',
      msGridRow: 1
    }
  }
}

export default styles
