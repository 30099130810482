import React from 'react'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { BREAK_POINT_MEDIUM } = mediaQueryHelper

const Label = ({ x, y, width, stroke, value, index, align = 'middle', mobileFontSize, vAlign = 'top' }) => {
  let position = x + width / 2
  let anchor = 'middle'

  if (align === 'ends') {
    anchor = index === 0 ? 'start' : 'end'
    position = index === 0 ? x : x + width
  }
  const screenWidth = window.innerWidth

  let fontSize = mobileFontSize
  if (screenWidth > BREAK_POINT_MEDIUM) {
    fontSize = mobileFontSize * 1.5
  }

  let vPosition = y - 5

  if (vAlign === 'bottom') {
    vPosition = y + 36
  }

  return (
    <text x={position} y={vPosition} dy={-4} fill={stroke} fontSize={fontSize} textAnchor={anchor}>
      {value}
    </text>
  )
}

export default Label
