import axios from '../api/axiosDefaults'
import axiosCustomErrorHandling from '../api/axiosCustomErrorHandling'
import loginHelpers from 'utils/loginHelpers'
import api from '../api'

const getPublicToken = async () => {
  const response = await api.fetchAccessToken()
  const { access_token } = response
  return access_token
}

export const setPublicToken = async () => {
  const publicToken = await getPublicToken()
  const header = loginHelpers.getHeaders({ accessToken: publicToken })
  axios.defaults.headers = header
  axiosCustomErrorHandling.defaults.headers = header
}

export const setPrivateToken = async () => {
  const auth = window.localStorage.getItem('auth') && JSON.parse(window.localStorage.getItem('auth'))
  const { access_token } = auth
  const header = loginHelpers.getHeaders({ accessToken: access_token })
  axios.defaults.headers = header
  axiosCustomErrorHandling.defaults.headers = header
}
