import { GRAPH_CURRENT_PERIOD_CARDS_COLOR, GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import { getColorGradient } from 'utils/common.utils'
import _ from 'lodash'

export const getLegendsArray = (currentData, oldPeriodData, isComparePeriod) => {
  const map = {}

  let index = 0
  const entries = Object.entries(currentData)
  for (const [key] of entries) {
    const color = getColorGradient(GRAPH_CURRENT_PERIOD_CARDS_COLOR, index, entries.length)
    map[key] = { currentColor: color }
    index++
  }

  if (isComparePeriod && oldPeriodData) {
    index = 0
    const newEntries = Object.entries(oldPeriodData)
    for (const [key] of newEntries) {
      const color = getColorGradient(GRAPH_PREVIOUS_PERIOD_COLOR, index, newEntries.length)
      if (map[key]) {
        map[key] = { ...map[key], oldColor: color }
      } else {
        map[key] = { oldColor: color }
      }
      index++
    }
  }

  return map
}

export const getCardsMap = sortedCards => {
  const cardsMap = _.chain(sortedCards)
    .keyBy('group')
    .mapValues('percentage')
    .value()
  return cardsMap
}
