import React from 'react'
import PropTypes from 'prop-types'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import { LightPopoverContainer, DarkPopoverContainer } from '../PopoverContainer/PopoverContainer'
import { lightMobileStyles, darkMobileStyles } from './MobileMenu.styles'
import Icon from 'components/Icon/Icon'
import BurgerMenu from '../../BurgerMenu/BurgerMenu'
import Logo from 'components/Logo/Logo'

const popoverMenuStyles = {
  popoverContent: {
    margin: '0 0 0 -120px'
  }
}

const MobileMenu = ({
  classes,
  mobileLogo,
  links,
  appsLinks,
  activeLink,
  onLogoClick,
  onNavigateBeforeClick,
  onSwitchAppClick,
  menuItems,
  secondaryMenuItems,
  secondaryMenuIconName,
  applicationMenuItems,
  inviteMenuItems,
  notificationMenuItems,
  content,
  showBack,
  showSwitchApp,
  preset,
  override
}) => {
  const burgerProps = {
    navLinks: links,
    actionLinks: menuItems,
    secondaryActionLinks: secondaryMenuItems,
    onSwitchAppClick,
    activeLink,
    showSwitchApp,
    preset,
    override
  }

  const popoverProps = {
    appsLinks,
    activeLink,
    secondaryMenuItems,
    secondaryMenuIconName,
    applicationMenuItems,
    inviteMenuItems,
    notificationMenuItems,
    preset,
    override: { ...popoverMenuStyles, ...override },
    popoverContainerPrefix: 'mobile-'
  }

  return (
    <header className={classes['header']}>
      {showBack && (
        <div className={classes['navigateBack']} onClick={onNavigateBeforeClick}>
          <div className={classes['chevronWithText']}>
            <div>
              <Icon name="navigateBefore" />
            </div>
            <div className={classes['backText']}>Back</div>
          </div>
        </div>
      )}

      {activeLink && <div className={classes['title']}>{activeLink}</div>}

      {content && <div className={classes['content']}>{content}</div>}

      {!showBack && (
        <div className={classes['popovers']}>
          {preset === 'dark' ? <DarkPopoverContainer {...popoverProps} /> : <LightPopoverContainer {...popoverProps} />}
        </div>
      )}

      {menuItems && (
        <div className={classes['menuButton']}>
          <BurgerMenu {...burgerProps} />
        </div>
      )}
    </header>
  )
}

export const LightMobileMenu = withStyles(lightMobileStyles)(MobileMenu)
export const DarkMobileMenu = withStyles(darkMobileStyles)(MobileMenu)

MobileMenu.propTypes = {
  /**
   * App Logo. Defaults to Paymark Logo. Pass in you app specific logo here.
   */
  mobileLogo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Action Handlers
   */
  onLogoClick: PropTypes.func,
  onNavigateBeforeClick: PropTypes.func,
  onSwitchAppClick: PropTypes.func,

  /**
   *  Header links
   */
  links: PropTypes.array,
  activeLink: PropTypes.string,

  /**
   *  Apps Links
   */
  appsLinks: PropTypes.array,

  /**
   * Custom Icon Menu Items
   */
  secondaryMenuItems: PropTypes.array,

  /**
   * Fixed Icon Menu Items
   */
  menuItems: PropTypes.array,
  applicationMenuItems: PropTypes.array,
  inviteMenuItems: PropTypes.array,
  notificationMenuItems: PropTypes.array,
  accountMenuItems: PropTypes.array,

  /**
   * Inner Content
   */
  content: PropTypes.any,

  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    header: PropTypes.object,
    logo: PropTypes.object,
    nav: PropTypes.object,
    title: PropTypes.object
  }),

  /**
   * Flag to show the back navigation button instead of the Paymark logo.
   */
  showBack: PropTypes.bool,

  /**
   * Flag to show the button which, when clicked, navigates from beta app to old app.
   */
  showSwitchApp: PropTypes.bool,

  /**
   * Preset is used to specify an app-specific style preset
   */
  preset: PropTypes.oneOf(['light', 'dark'])
}

MobileMenu.defaultProps = {
  mobileLogo: <Logo name="paymark-mobile" color={colors.brandDark} width="30px" />,
  override: {},
  showBack: false,
  showSwitchApp: false
}
