import React from 'react'
import withStyles from 'react-jss'
import styles from '../Group.styles'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import Radio from 'components/Radio/Radio'
import PropTypes from 'prop-types'

export const TransactionTypes = [
  {
    value: 0,
    label: 'Completion'
  },
  {
    value: 1,
    label: 'Refund Reversal'
  },
  {
    value: 2,
    label: 'Payment Reversal'
  },
  {
    value: 3,
    label: 'Pre-auth'
  },
  {
    value: 4,
    label: 'Refund'
  },
  {
    value: 5,
    label: 'Purchase'
  },
  {
    value: 6,
    label: 'Other'
  },
  {
    value: 7,
    label: 'Enquiry'
  },
  {
    value: 8,
    label: 'Payment'
  },
  {
    value: 9,
    label: 'Preauth Reversal'
  }
]

const TransactionRadios = ['All Types', 'Individual Types']

const TransactionTypeSelector = ({
  classes,
  transactionCategory,
  transactionTypes,
  handleCategoryChange,
  handleTransactionTypeChange
}) => {
  return (
    <div className={classes['group']}>
      <div className={classes['radioButtons']} data-hj-suppress>
        {TransactionRadios.map(type => {
          const checked = transactionCategory === type
          return (
            <div className={classes['radioItem']} key={type}>
              <Radio
                label={type}
                checked={checked}
                onChange={() => {
                  handleCategoryChange(type)
                }}
              />
            </div>
          )
        })}
      </div>
      {TransactionRadios[1] === transactionCategory && (
        <div className={classes['checkBoxes']} data-hj-suppress>
          {TransactionTypes.map(type => {
            const checked = transactionTypes.indexOf(type.value) > -1
            return (
              <div className={classes['checkBoxItem']} key={type.label}>
                <Checkbox
                  label={type.label}
                  checked={checked}
                  onClick={() => {
                    handleTransactionTypeChange(type.value)
                  }}
                  onChange={e => {
                    e.preventDefault()
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

TransactionTypeSelector.propTypes = {
  classes: PropTypes.object,
  transactionCategory: PropTypes.string,
  transactionTypes: PropTypes.array,
  handleCategoryChange: PropTypes.func,
  handleTransactionTypeChange: PropTypes.func
}
export default withStyles(styles)(TransactionTypeSelector)
