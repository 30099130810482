import React, { useState, useEffect } from 'react'
import styles from './UserCollectionSearch.styles'
import withStyles from 'react-jss'
import _ from 'lodash'
import SearchInput from 'components/common/SearchInput'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import Message from 'components/Message/Message'
import { doFuzzySearch } from 'utils/filters.utils'
import { getInitials } from 'utils/user.utils'
import Avatar from 'components/common/Avatar'
import useLoggedInUser from 'hooks/useLoggedInUser'
import { ROLE_OWNER } from 'constants/general.constants'

const PLACEHOLDER = 'Search users for this store by name.'
const VISIBLE_COUNT = 6

const UserCollectionSearch = ({ classes, list, errorMessage = 'No matching record found', onRemove }) => {
  const [text, setText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [isExpand, setExpanded] = useState(false)
  const { role } = useLoggedInUser()

  const handleClick = () => {
    setExpanded(!isExpand)
  }

  useEffect(() => {
    setFilteredList(list)
  }, [list])

  const onSearch = e => {
    const { value } = e.target
    setText(value)
    const filteredList = doFuzzySearch(list, value, { keys: ['firstName', 'lastName'] })
    setFilteredList(filteredList)
  }

  const UserItem = ({ user, classes, onRemove }) => {
    const name = `${user.firstName} ${user.lastName}`
    const initials = getInitials(user)
    const currentRole = user.role

    return (
      <div className={classes['searchItem']}>
        <div className={classes['text']}>
          <div className={classes['avatarName']}>
            <Avatar title={initials} height={'2rem'} width={'1.6rem'} fontSize={'1rem'} role={currentRole} />
          </div>
          <Text color={colors.greyDark} variant="body">
            {name}
          </Text>
        </div>
        {role === ROLE_OWNER && (
          <div
            onClick={() => {
              onRemove(user)
            }}
          >
            <Icon name="clear-filled" />
          </div>
        )}
      </div>
    )
  }

  const displayList = isExpand ? filteredList : _.take(filteredList, VISIBLE_COUNT)
  const matchingText = `${text ? 'matching users' : 'users set up for this store'}`

  return (
    <div className={classes['searchWrapper']}>
      <div className={classes['searchBox']}>
        <SearchInput
          {...{
            placeholder: PLACEHOLDER,
            text,
            onChange: onSearch
          }}
        />
      </div>

      {filteredList.length === 0 && text && (
        <div className={classes['alignMessage']}>
          <Message variant="error" showIcon={false} text={errorMessage} />
        </div>
      )}

      <div className={classes['result']}>
        {displayList.map((item, index) => {
          return <UserItem key={index} user={item} classes={classes} onRemove={onRemove} />
        })}
      </div>

      {filteredList.length > VISIBLE_COUNT && (
        <div className={classes['showMore']} onClick={handleClick}>
          <Text variant="header-link" color={colors.cyan}>
            {isExpand ? `Show less` : `Show all ${filteredList.length} ${matchingText}`}
          </Text>

          <div>
            <Icon width={'1rem'} height={'1rem'} name={isExpand ? 'arrowUpRegular' : 'arrowDownRegular'} />
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(UserCollectionSearch)
