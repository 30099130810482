import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LAPTOP_UP } = mediaQueryHelper

const styles = {
  selectedItems: {
    background: ({ isSelected }) => (isSelected ? colors.highlight : colors.white),
    padding: ['1rem'],
    justifyContent: 'space-between',
    '& > .title': {
      textAlign: 'center',
      marginBottom: '1rem'
    }
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >.store': {
      margin: [0, 0, '0.5rem', 0],
      flex: '1 0 100%',
      [LAPTOP_UP()]: {
        flex: '1 0 50%',
        maxWidth: 'calc(50% - 4px)'
      }
    }
  },
  displayAvatar: {
    '& div > label::before': {
      top: '0.5rem'
    },
    '& div > label::after': {
      top: '0.75rem'
    }
  },
  avatarName: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > div': {
      marginRight: '1rem'
    },
    '& > p': {
      paddingTop: '0.4rem',
      wordBreak: 'break-word'
    }
  },
  avatarCheckbox: {
    marginBottom: '1rem'
  },
  item: {
    boxSizing: 'border-box',
    minHeight: '2.5rem',
    padding: '0.625rem 1rem',
    width: '100%',
    fontSize: '1rem',
    lineHeight: 1.3,
    color: colors.cyan,
    cursor: ({ onClick }) => (onClick ? 'pointer' : 'default'),
    outline: 0,
    '&:focus': {
      background: colors.highlight,
      color: colors.teal
    },
    '&:hover': {
      background: ({ onClick }) => (onClick ? colors.highlight : ''),
      color: ({ onClick }) => (onClick ? colors.teal : '')
    },
    '&:active': {
      background: colors.active,
      color: colors.teal
    },
    flex: '1 0 100%',
    [LAPTOP_UP()]: {
      flex: '1 0 50%',
      maxWidth: '50%'
    }
  }
}

export default styles
