import React from 'react'
import Message from 'components/Message/Message'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './SSORegistrationErrorMessage.styles'

const transformServerErrorMessage = ({ errorCode, goToLogin }) => {
  switch (errorCode) {
    //Access not allowed
    case 'incorrect_email_mid':
      return 'Sorry, you cannot access Insights for that Merchant Number. Please use an email that is associated with that Merchant Number or, if you believe your information is correct, phone Helpdesk on 0800 729 627 to get access. We are open 24/7.'

    //Account already exists
    case 'already_active':
    case 'login_required':
      return (
        <p>
          Sorry, this email address cannot be used. Please send us a note at support@paymark.co.nz or give us a call on
          0800 729 627.
        </p>
      )

    //Not available
    case 'no_fee':
    case 'aggregate':
    case 'premium_bnz':
      return 'Sorry, Insights is not available for that Merchant Number. Please contact your Worldline Account Manager or Help Desk on 0800 729 627.'

    //Invalid merchant\
    case 'not_active':
    case 'invalid_merchant':
      return 'Sorry, Insights is not available for that Merchant Number. Please contact Help Desk on 0800 729 627.'

    //Already Active
    case 'org_already_active':
      return 'This business is already registered for Insights.  Please contact your Insights Administrator to be given access.'

    //Invalid request
    case 'bad_request':
      return 'Sorry, there was an error. Please try again later.'
    default:
      return 'Sorry, there was an error. Please try again later.'
  }
}

const SSORegistrationErrorMessage = ({ errorCode, goToLogin, classes }) => {
  const message = transformServerErrorMessage({ errorCode, goToLogin })
  return (
    <div className={classes['error']}>
      <Message text={message} variant={'error'} showIcon={false} />{' '}
    </div>
  )
}

SSORegistrationErrorMessage.propTypes = {
  goToLogin: PropTypes.func,
  errorCode: PropTypes.string
}

export default withStyles(styles)(SSORegistrationErrorMessage)
