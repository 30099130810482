import React from 'react';
import { CompareButton } from 'components/common';

const Label = () => {
  return (
    <>
      <div>Compare to</div>
      <div>another store</div>
    </>
  )
}

const CompareStoreButton = ({ classes, onChange, isSelected, range, isLoading }) => {
  return <CompareButton {...{ classes, onChange, isSelected, range, disabled: isLoading, Label }} />
}

export default CompareStoreButton
