import numeral from 'numeral'
import _ from 'lodash'

export const getCurrency = (iValue, isRounded = false, isShowDecimals = true) => {
  const currency = numeral(iValue)
  return isRounded ? currency.format('$0.0a') : currency.format(isShowDecimals ? '$0,0.00' : '$0,0')
}

export const formatNumber = (iValue = 0, isRounded = false, isShowDecimals = true) => {
  const number = numeral(iValue)
  return isRounded ? number.format('0,0.0a') : number.format(isShowDecimals ? '0,0.0' : '0,0')
}

export const getTotal = (data, key) => {
  return data
    .map(item => parseFloat(item[key]))
    .reduce((total, amount) => {
      return total + amount
    })
}

export const getMaxValue = (data, key) => {
  return _.maxBy(data, item => _.toNumber(item[key]))
}

export const getDifferencePercentage = (first, second) => {
  first = parseInt(first)
  second = parseInt(second)

  if (second <= 0) {
    return 'n/a'
  }

  return (first - second) / second
}

export const formatPercentage = value => {
  return numeral(value).format('0%')
}

export const toOrdinal = value => {
  return numeral(value).format('0o')
}
