import Text from 'components/Text/Text'
import React from 'react'
import ContentLoader from 'react-content-loader'
import withStyles from 'react-jss'
import styles from './CommentarySection.styles'
import { isTabletWidth } from 'utils/common.utils'

const LoadingIndicator = () => {
  const width = isTabletWidth() ? 600 : 200

  if (isTabletWidth()) {
    return (
      <ContentLoader height={40} width={width} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="0" y="0" rx="3" ry="3" width={0.35 * width} height="10" />
        <rect x={0.4 * width} y="0" rx="3" ry="3" width={0.5 * width} height="10" />
        <rect x="0" y="20" rx="3" ry="3" width={0.65 * width} height="10" />
      </ContentLoader>
    )
  }

  return (
    <ContentLoader height={90} width={width} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="3" ry="3" width={0.35 * width} height="10" />
      <rect x={0.4 * width} y="0" rx="3" ry="3" width={0.5 * width} height="10" />
      <rect x="0" y="20" rx="3" ry="3" width={0.65 * width} height="10" />
      <rect x={0.7 * width} y="20" rx="3" ry="3" width={0.65 * width} height="10" />
      <rect x="0" y="40" rx="3" ry="3" width={0.45 * width} height="10" />
      <rect x={0.6 * width} y="40" rx="3" ry="3" width={0.3 * width} height="10" />
      <rect x="0" y="60" rx="3" ry="3" width={0.15 * width} height="10" />
    </ContentLoader>
  )
}

const CommentarySection = ({ classes, children, isLoading }) => {
  if (isLoading) {
    return <LoadingIndicator />
  }
  return (
    <div className={classes['text']} data-hj-suppress>
      <Text variant="body">{children}</Text>
    </div>
  )
}

export default withStyles(styles)(CommentarySection)
