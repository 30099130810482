import React from 'react'
import ContentLoader from 'react-content-loader'
import { isMobileWidth, isTabletWidth } from 'utils/common.utils'

const StoreListLoader = () => {
  const maxHeight = isMobileWidth() ? 920 : isTabletWidth() ? 460 : 230
  const height = isMobileWidth() ? 160 : isTabletWidth() ? 80 : 40
  const space = isMobileWidth() ? 240 : isTabletWidth() ? 120 : 60

  return (
    <ContentLoader height={maxHeight} width={1000} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="5" ry="5" width="1000" height={height} />
      <rect x="0" y={space} rx="5" ry="5" width="1000" height={height} />
      <rect x="0" y={space * 2} rx="5" ry="5" width="1000" height={height} />
      <rect x="0" y={space * 3} rx="5" ry="5" width="1000" height={height} />
    </ContentLoader>
  )
}

export default StoreListLoader
