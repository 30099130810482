import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import ModalBody from './ModalBody'
import jss from './Modal.styles'

class Modal extends React.Component {
  componentDidMount() {
    ReactModal.setAppElement('#root')
  }
  render() {
    const {
      children,
      title,
      onClose,
      size,
      showCloseIcon,
      isOpen,
      shouldCloseOnOverlayClick,
      override,
      closeIconColor
    } = this.props

    const dataTestId = this.props['data-testid']

    const reactModalProps = {
      isOpen,
      shouldCloseOnOverlayClick,
      onRequestClose: onClose,
      contentLabel: title || 'Modal'
    }

    return (
      <ReactModal style={jss} {...reactModalProps}>
        <ModalBody {...{ title, onClose, size, showCloseIcon, override, dataTestId, closeIconColor }}>
          {' '}
          {children}{' '}
        </ModalBody>
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  ...ReactModal.propTypes,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'custom']),
  title: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  closeIconColor: PropTypes.string,
  /**
   * Shape of override expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    closeIcon: PropTypes.object,
    title: PropTypes.object,
    body: PropTypes.object,
    small: PropTypes.object,
    medium: PropTypes.object,
    large: PropTypes.object,
    custom: PropTypes.object
  })
}

Modal.defaultProps = {
  ...ReactModal.defaultProps,
  size: 'small',
  onClose: undefined,
  title: undefined,
  showCloseIcon: true,
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  override: {}
}

export default Modal
