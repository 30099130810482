import axios from './axiosDefaults'
import { getCardValue } from 'components/TransactionFilter/TransactionFilter.helper'
import handleError from 'utils/error.utils'
import { HEADER_CSV } from 'constants/general.constants'
import moment from 'moment'

const BASE_PATH = 'merchant/transaction/'

const getCardLogos = cardLogosString => {
  if (cardLogosString) {
    const cardLogos = cardLogosString.split(',')

    const cards = cardLogos.map(card => {
      return getCardValue(card)
    })

    return cards.join(',')
  }

  return undefined
}

const getTransactionParams = params => {
  const {
    cardAcceptorIdCode,
    approved,
    purchaseAmount,
    suffix,
    terminalId,
    transactionTimeFrom,
    transactionTimeTo,
    cardLogos,
    tranTypes,
    page,
    limit
  } = params

  const transactionParams = {
    cardAcceptorIdCodes: cardAcceptorIdCode,
    suffix,
    terminalId,
    approved,
    purchaseAmount,
    transactionTimeFrom,
    transactionTimeTo: moment(transactionTimeTo)
      .endOf('minute')
      .toISOString(),
    cardLogos: getCardLogos(cardLogos),
    tranTypes,
    page,
    limit
  }

  return transactionParams
}

export const getTransactions = async params => {
  const transactionParams = getTransactionParams(params)
  try {
    return await axios.get(BASE_PATH, { params: transactionParams })
  } catch (error) {
    handleError(error)
  }
}

export const getTransactionsCSV = async params => {
  const transactionParams = getTransactionParams(params)
  const headers = { ...axios.defaults.headers }
  headers['Accept'] = HEADER_CSV

  try {
    const csv = await axios.get(BASE_PATH, {
      headers,
      params: transactionParams
    })

    return csv
  } catch (error) {
    handleError(error)
  }
}
