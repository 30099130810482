import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../api'
import { createCustomSelector } from 'utils/common.utils'

const initialState = {
  error: false,
  isLoading: false,
  is504: false,
  filter: undefined,
  transactions: undefined,
  totalResults: undefined,
  totalPages: undefined,
  page: undefined,
  isShowResult: false,
  selectedTransaction: null
}

const isLoading = createCustomSelector('transaction.isLoading')
const is504 = createCustomSelector('transaction.is504')
const getFilter = createCustomSelector('transaction.filter')
const isShowResult = createCustomSelector('transaction.isShowResult')
const getTransactions = createCustomSelector('transaction.transactions')
const getTotalResults = createCustomSelector('transaction.totalResults')
const getTotalPages = createCustomSelector('transaction.totalPages')
const getPage = createCustomSelector('transaction.page')
const getSelectedTransaction = createCustomSelector('transaction.selectedTransaction')

const selectors = {
  isLoading,
  is504,
  getFilter,
  isShowResult,
  getTransactions,
  getTotalResults,
  getTotalPages,
  getPage,
  getSelectedTransaction
}

const reducers = {
  startLoading: state => {
    return { ...state, isLoading: true }
  },
  set504: (state, action) => {
    return { ...state, is504: action.payload }
  },
  endLoading: state => {
    return { ...state, isLoading: false }
  },
  fetchTransactions: (state, action) => {
    const { payload } = action
    return { ...state, filter: payload, isLoading: true, isShowResult: true }
  },
  setTransactions: (state, action) => {
    const { payload } = action
    const transactions = payload ? payload.transactions : []
    const totalResults = payload ? payload.totalResults : []
    const totalPages = payload ? payload.totalPages : []
    const page = payload ? payload.page : []
    return {
      ...state,
      transactions,
      totalResults,
      totalPages,
      page,
      isLoading: false,
      isShowResult: true
    }
  },
  resetTransactions: state => {
    return { ...state, filter: undefined, isLoading: false, isShowResult: false }
  },
  hideResult: state => {
    return { ...state, isShowResult: false }
  },
  setSelectedTransaction: (state, action) => {
    return { ...state, selectedTransaction: action.payload }
  }
}

const transactionSlice = createSlice({
  slice: 'transaction',
  initialState: initialState,
  reducers
})

function* fetchTransactions(action) {
  const { payload } = action

  const transactions = yield call(api.getTransactions, payload)

  if (transactions === 504) {
    yield put(transactionSlice.actions.set504(true))
  } else {
    yield put(transactionSlice.actions.setTransactions(transactions))
  }
}

function* transactionSagas() {
  yield takeLatest(transactionSlice.actions.fetchTransactions.toString(), fetchTransactions)
}

export default {
  ...transactionSlice,
  selectors,
  sagas: transactionSagas
}
