import * as colors from 'config/colors'

const { white, line1 } = colors

const styles = {
  dot: {
    height: '1rem',
    width: '1rem',
    margin: [0, '0.5rem'],
    backgroundColor: line1,
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer'
  },
  dotActive: {
    composes: '$dot',
    backgroundColor: white
  }
}

export default styles
