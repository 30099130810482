import React, { useState, useCallback } from 'react'
import styles from './UserBody.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import UserPermissions from '../UserPermissions'
import UserStatus from '../UserStatus'
import CollectionSearch from '../../CollectionSearch'
import UserDelete from '../UserDelete'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import { ConfirmationDialog, FieldSection, PermissionsInfoModal } from 'components/common'
import StyledRemoveStoreAccessConfirmation from '../RemoveStoreAccess'
import useMyAccount from 'components/MyAccount/useMyAccount'
import { ROLE_VIEWER, USER_STATE_WAITING_EMAIL, USER_STATE_EMAIL_EXPIRED } from 'constants/general.constants'
import apis from 'api'
import { EVENT_EDIT_USER_INFORMATION } from 'constants/mixpanel.constants'
import { getInsightsRole } from 'utils/user.utils'

const addStoreButtonStyles = {
  secondary: {
    padding: '0.625rem 1rem'
  }
}

const SearchHeader = ({ classes, role }) => {
  const title = ROLE_VIEWER === role ? 'SET UP PERMISSION TO VIEW STORES' : `${role}s can see all stores`.toUpperCase()

  return (
    <div className={classes['headerTitle']}>
      <h5>{title.toUpperCase()}</h5>
    </div>
  )
}

const UserBody = ({ classes, user, isSelf, handleAddStore, isLoading }) => {
  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice
  const updateUserAction = useCallback(actionParams => dispatch(usersActions.updateUser(actionParams)), [
    dispatch,
    usersActions
  ])
  const { Mixpanel: mixpanel } = apis

  const { email, role, stores, state } = user

  const sortedStores = _.orderBy(stores, ['displayName'], ['asc'])

  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [storeToBeRemoved, setStoreToBeRemoved] = useState(null)

  const { stores: allStores } = useMyAccount()

  const isAllStoresSelected = allStores.length === sortedStores.length
  const isUserActive = state === 'Active'

  const onAccept = async () => {
    setConfirmationVisible(false)
    setStoreToBeRemoved(null)

    const updatedRoles = _.differenceBy(user.roles, [storeToBeRemoved], 'cardAcceptorIdCode')
    const updatedUser = { ...user, roles: updatedRoles }

    mixpanel.track(EVENT_EDIT_USER_INFORMATION, {
      userPermissions: getInsightsRole(updatedRoles, 'cardAcceptorIdCode'),
      removeStorePermission: 1
    })

    updateUserAction(updatedUser)
  }

  const onClose = () => {
    setConfirmationVisible(false)
  }

  const onInfoModalClose = () => {
    setInfoModalVisible(false)
  }

  const onIconClick = () => {
    setInfoModalVisible(true)
  }

  const onRemove = store => {
    setStoreToBeRemoved(store)
    setConfirmationVisible(true)
  }

  const InfoIcon = () => {
    return (
      <div onClick={onIconClick}>
        <Icon name="info" width={'1.25em'} height={'1.25em'} />
      </div>
    )
  }

  return (
    <div className={classes['userBody']} data-hj-suppress>
      <FieldSection title={'Email'} classes={classes}>
        <Text variant="body" color={colors.greyDark}>
          {email}
        </Text>
      </FieldSection>

      <FieldSection title={'Status'} classes={classes}>
        <UserStatus user={user} />
      </FieldSection>

      <FieldSection title={'Permissions'} infoIcon={<InfoIcon />} classes={classes} noBorder>
        {isSelf || state === USER_STATE_WAITING_EMAIL || state === USER_STATE_EMAIL_EXPIRED ? (
          <Text variant="body" color={colors.greyDark}>
            {role}
          </Text>
        ) : (
          <UserPermissions role={role} user={user} isSelf={isSelf} handleAddStore={handleAddStore} />
        )}
      </FieldSection>

      <SearchHeader classes={classes} role={role} />

      {// The store search is only shown for the viewer.
      !isLoading && ROLE_VIEWER === role && (
        <>
          <CollectionSearch
            list={sortedStores}
            searchKey="displayName"
            errorMessage="Can't find the searched store."
            onRemove={onRemove}
            isDisabled={!isUserActive}
          />

          {!isAllStoresSelected && (
            <div className={classes['addStoreButton']}>
              <Button
                disabled={!isUserActive}
                variant="secondary"
                onClick={() => handleAddStore(user)}
                override={addStoreButtonStyles}
              >
                Add Store
              </Button>
            </div>
          )}
        </>
      )}

      {!isSelf && <UserDelete user={user} />}

      {confirmationVisible && (
        <ConfirmationDialog
          {...{
            onAccept,
            onClose,
            callbackParams: { user }
          }}
        >
          <StyledRemoveStoreAccessConfirmation user={user} displayName={storeToBeRemoved.displayName} />
        </ConfirmationDialog>
      )}

      {infoModalVisible && <PermissionsInfoModal {...{ onClose: onInfoModalClose }} />}
    </div>
  )
}

export default withStyles(styles)(UserBody)
