import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'

/**
 * Logo is a wrapper over the Icon component. To be used to display Product Logos
 */
const Logo = props => {
  return <Icon {...props} />
}

Logo.propTypes = {
  /**
   * Name of the Logo
   */
  name: PropTypes.PropTypes.oneOf([
    'paymark',
    'paymark-mobile',
    'paymark-mobile-beta',
    'insights',
    'insights-beta',
    'mypaymark',
    'oe'
  ]).isRequired,

  /**
   * Refer Icon theme for the shape.
   */
  iconTheme: PropTypes.shape({}),

  color: PropTypes.string
}

Logo.defaultProps = {
  name: 'paymark'
}

export default Logo
