import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { BREAK_POINT_LARGE, up } = mediaQueryHelper
const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '3rem',
    [mediaQueryHelper.LAPTOP_UP()]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'auto',
      borderLeft: `1px solid ${colors.line2}`,
      padding: ['1rem']
    },
    [up(BREAK_POINT_LARGE)]: {
      borderLeft: `0`,
      padding: [0],
      alignItems: 'center'
    }
  },
  users: {
    flex: [1, 1, 'auto'],
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderLeft: `1px solid ${colors.line2}`,
    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: [0, 0, '1.25rem', 0],
      borderLeft: 0
    }
  },
  stores: {
    flex: [1, 1, 'auto'],
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: [0, 0, '1.25rem', 0]
    }
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      padding: [0, '0.25rem']
    }
  }
}

export default styles
