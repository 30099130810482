import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { DashboardRoute, SSORegistrationRoute, UnsupportedBrowser } from 'Routes'
import { getIECompatabilityWarningViewed } from 'utils/localStorage.utils'

const checkIE = () => {
  return /MSIE|Trident/.test(window.navigator.userAgent)
}

const getAllowedRoute = props => {
  const { merchants, path, rest, componentProps, Component } = props

  if (merchants.length === 0 && path !== SSORegistrationRoute.path) {
    return <Redirect to={SSORegistrationRoute.path} />
  } else if (merchants.length > 0 && path === SSORegistrationRoute.path) {
    return <Redirect to={DashboardRoute.path} />
  }

  if (
    checkIE() &&
    path !== UnsupportedBrowser.path &&
    path !== SSORegistrationRoute.path &&
    getIECompatabilityWarningViewed() !== 'true'
  ) {
    return <Redirect to={UnsupportedBrowser.path} />
  }

  return <Route {...rest} render={routerProps => <Component key={path} {...{ ...componentProps, routerProps }} />} />
}

export default getAllowedRoute
