import React from 'react'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import styles from './PasswordConditions.styles'
import objstr from 'obj-str'

const PasswordConditions = ({ isError, classes }) => {
  const notes = [
    'Password needs to be at least 8 characters long.',
    'Use a mix of upper case and lower case characters.',
    'Include a number or a special character (@,!,$, ~,%,…).'
  ]

  const className = objstr({
    [classes['rules']]: true,
    [classes['error']]: isError
  })

  return (
    <div className={className}>
      <ul>
        {notes.map(note => {
          return (
            <li key={note}>
              <Text variant="note" isError={isError}>
                {note}
              </Text>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default withStyles(styles)(PasswordConditions)
