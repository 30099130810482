import { useSelector } from 'react-redux'
import { accountSlice } from 'reduxSlices'

const useMyAccount = () => {
  const { selectors: accountSelectors } = accountSlice

  const user = useSelector(accountSelectors.getUser)
  const stores = useSelector(accountSelectors.getMerchantAccess)

  return {
    user,
    stores
  }
}

export default useMyAccount
