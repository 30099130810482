import welcome from './lottie/welcome.json'
import businessDashboard from './lottie/businessDashboard.json'
import storePerformance from './lottie/storePerformance.json'
import comparePeriods from './lottie/comparePeriods.json'
import storeDashboard from './lottie/storeDashboard.json'
import accountSettings from './lottie/accountSettings.json'
// import weather from './lottie/weather.json'
import moreInformation from './lottie/moreInformation.json'

export const animationData = [
  welcome,
  businessDashboard,
  storePerformance,
  comparePeriods,
  storeDashboard,
  accountSettings,
  // weather,
  moreInformation
]

export const tutorial = [
  {
    header: `Welcome to the new Insights`,
    body: `Insights is a real-time performance dashboard that keeps you on top of your business and in front of your competition.
    
    Let’s get you started with a tour of some of the new features.`
  },
  {
    header: `Business Dashboard`,
    body: `A real-time overview of business performance.
    
    See the whole organisation: total sales, revenue, customer spend, customer loyalty, top stores and more.
    
    All the essential data you need, at a glance.`
  },
  {
    header: `Detailed store performance at a glance`,
    body: `We've set up the first store so we have on record for your business.
    
    To add more stores, simply use the + icon in the top right hand corner, and choose 'Add New Store'.`
  },
  {
    header: `Compare Periods`,
    body: `Navigate through business performance by day or week.
    
    Choose a starting date and a time period, and you’ll see performance compared to the same period previously.`
  },
  {
    header: `Store Dashboard`,
    body: `More than one store?
    
    Real-time transactions for a selected store, revenue, returning customers and more.
    
    Plus store comparison: choose any two stores and view their performance side by side.`
  },
  {
    header: `Account Settings`,
    body: `Control how your team accesses Insights.
    
    Full access for Owners, view selected stores for Viewers, and view all stores for Global Viewers.`
  },
  // {
  //   header: `Weather`,
  //   body: `How is the weather impacting your business?

  //   Match weather data to sales, so you can start to understand how the weather will affect your customers’ spending.`
  // },
  {
    header: `Need more information?`,
    body: `That’s a quick introduction to Insights.
    
    There’s plenty of extra information to help you navigate it in more detail: you can visit our site, contact our support team and read our FAQs.`
  }
]
