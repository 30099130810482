import { getLegendsArray, getCardsMap } from 'components/BusinessPerformance/CardsUsed/CardsUsed.utils'
import { DashboardCard, Legends } from 'components/common'
import React from 'react'
import withStyles from 'react-jss'
import styles from './CardsUsed.styles'
import useCardsUsed from 'reduxSlices/dashboardSlice/hooks/useCardsUsed'
import NoTransactionsMessage from './NoTransactionsMessage'
import CurrentPeriodGraph from './CurrentPeriodGraph'
import PreviousPeriodGraph from './PreviousPeriodGraph'
import CardsUsedLoader from './CardsUsedLoader'
import _ from 'lodash'

const CARD_PROPS = {
  title: 'Top Cards Used',
  iconName: 'card',
  height: '190px',
  isResizeable: false
}

const CardsUsed = ({ classes }) => {
  const { allCardsUsed, isComparePeriod } = useCardsUsed()
  const { data, isLoading } = allCardsUsed
  const { current, previous } = data

  if (isLoading) {
    return (
      <DashboardCard {...CARD_PROPS}>
        <CardsUsedLoader />
      </DashboardCard>
    )
  }

  const isCurrentEmpty = _.isEmpty(current)
  const isPreviousEmpty = _.isEmpty(previous)
  const isNoTransactions = isComparePeriod ? isCurrentEmpty && isPreviousEmpty : isCurrentEmpty

  if (isNoTransactions) {
    return (
      <DashboardCard {...CARD_PROPS}>
        <NoTransactionsMessage {...{ classes }} />
      </DashboardCard>
    )
  }

  const currentGraphData = getCardsMap(current)
  const previousGraphData = getCardsMap(previous)
  const legends = getLegendsArray(currentGraphData, previousGraphData, isComparePeriod)

  return (
    <DashboardCard {...CARD_PROPS}>
      <div className={classes['cardsUsed']} data-hj-suppress>
        <div className={classes['content']}>
          <div className={classes['graphs']}>
            <CurrentPeriodGraph {...{ classes, isEmpty: isCurrentEmpty, data: currentGraphData }} />

            {isComparePeriod && (
              <PreviousPeriodGraph {...{ classes, isEmpty: isPreviousEmpty, data: previousGraphData }} />
            )}
          </div>
        </div>

        {!isNoTransactions && (
          <div className={classes['legendsContainer']}>
            {Object.keys(legends).map((legendKey, index) => {
              const legend = legends[legendKey]
              const { currentColor, oldColor } = legend
              const colors = isComparePeriod ? [currentColor, oldColor] : [currentColor]
              return <Legends colors={colors} title={legendKey} key={index} />
            })}
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

export default React.memo(withStyles(styles)(CardsUsed))
