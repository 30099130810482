import React from 'react'
import withStyles from 'react-jss'
import styles from './StoreCardsUsedCard.styles'
import { DashboardCard } from 'components/common'
import { GRAPH_REVENUE_COLOR } from 'constants/colors.contants'
import StoreCardGraphLayout from 'components/StorePerformance/StoreCardGraphLayout/StoreCardGraphLayout'
import useCardsUsed from 'reduxSlices/dashboardSlice/hooks/useCardsUsed'
import _ from 'lodash'
import { formatPercentage } from 'utils/number.utils'
import StoreCardChart from './StoreCardChart'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'

const getPrimaryStoreCommentary = data => {
  let text = ''
  if (_.isEmpty(data)) return 'There are no transactions for the selected time period.'

  const topCard = _.head(data)
  if (!topCard) {
    return text
  }

  let { group, percentage } = topCard
  percentage = formatPercentage(percentage / 100, false, false)
  text = `${group} is ${percentage} of all your transactions.`

  return text
}

const StoreCardsUsedCard = ({ classes }) => {
  const { primaryStore, secondaryStore } = useCardsUsed()
  const { filter } = useFilters()

  const { isComparePeriod, comparedStore, store } = filter
  const { data: pData, isLoading: isPrimaryLoading } = primaryStore
  const { current: pCurrent, previous: pPrevious } = pData

  const { data: sData, isLoading: isSecondaryLoading } = secondaryStore
  const { current: sCurrent, previous: sPrevious } = sData

  const PrimaryChart = (
    <StoreCardChart
      {...{ classes, current: pCurrent, previous: pPrevious, isComparePeriod, isLoading: isPrimaryLoading }}
    />
  )
  const SecondaryCard = (
    <StoreCardChart
      {...{ classes, current: sCurrent, previous: sPrevious, isComparePeriod, isLoading: isSecondaryLoading }}
    />
  )

  const commentary = !comparedStore ? getPrimaryStoreCommentary(pCurrent) : ''

  return (
    <DashboardCard
      {...{
        title: 'Top Cards Used',
        iconName: 'card',
        isResizeable: false,
        showHeaderBorder: true
      }}
    >
      <div className={classes['cardContent']}>
        <div className={classes['currentStore']}>
          <StoreCardGraphLayout
            {...{
              Chart: PrimaryChart,
              totalColor: GRAPH_REVENUE_COLOR,
              commentary,
              storeName: store ? store.displayName : '',
              isCompareStore: !!comparedStore,
              graphHeight: 'auto',
              isLoading: isPrimaryLoading
            }}
          />
        </div>

        {!!comparedStore && (
          <div className={classes['comparedStore']}>
            <StoreCardGraphLayout
              {...{
                Chart: SecondaryCard,
                totalColor: GRAPH_REVENUE_COLOR,
                storeName: comparedStore ? comparedStore.displayName : '',
                isCompareStore: !!comparedStore,
                graphHeight: 'auto',
                isLoading: isSecondaryLoading
              }}
            />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

StoreCardsUsedCard.prototype = {}

export default withStyles(styles)(StoreCardsUsedCard)
