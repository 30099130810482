import React from 'react'
import PropTypes from 'prop-types'
import Card from '../Card/Card'
import PublicHeader from '../PublicHeader/PublicHeader'
import HeaderLinks from '../HeaderLinks/HeaderLinks'
import Logo from '../Logo/Logo'
import withStyles from 'react-jss'
import jss from './PublicLayout.styles.js'

const PublicLayout = ({
  links,
  activeLink,
  iconName,
  logo,
  mobileLogo,
  children,
  classes,
  showHeader,
  onLogoClick
}) => {
  const dLogo = iconName ? <Logo name={iconName} /> : logo
  const mLogo = mobileLogo ? mobileLogo : undefined

  const header = (
    <PublicHeader
      headerLinks={<HeaderLinks links={links} activeLink={activeLink} />}
      logo={dLogo}
      mobileLogo={mLogo}
      onLogoClick={onLogoClick}
    />
  )

  return (
    <main>
      {showHeader && header}
      <section className={classes['body']}>
        <div className={classes['tile']}>
          <Card>
            <div className={classes['form']}>{children}</div>
          </Card>
        </div>
      </section>
    </main>
  )
}

PublicLayout.propTypes = {
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    body: PropTypes.object,
    tile: PropTypes.object,
    form: PropTypes.object
  }),
  /**
   * Links to be shown on the the header
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      target: PropTypes.string,
      onClick: PropTypes.func
    })
  ),

  /**
   * Pass the name of the active link
   */
  activeLink: PropTypes.string,

  /**
   * Pass the name of logo to be used
   */
  iconName: PropTypes.string,

  /**
   * Pass if you want to use a custom icon
   */
  desktopLogo: PropTypes.any,

  /**
   * Pass if you want to use a custom mobile icon. Paymark icon used as default.
   */
  mobileLogo: PropTypes.any,

  children: PropTypes.any,

  showHeader: PropTypes.bool,

  onLogoClick: PropTypes.func
}

PublicLayout.defaultProps = {
  links: [],
  showHeader: true,
  onLogoClick: () => {},
  override: {}
}

export default withStyles(jss)(PublicLayout)
