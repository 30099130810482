import React from 'react'
import PropTypes from 'prop-types'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import { LightPopoverContainer, DarkPopoverContainer } from '../PopoverContainer/PopoverContainer'
import HeaderLinks from '../../HeaderLinks/HeaderLinks'
import { lightDesktopStyles, darkDesktopStyles } from './DesktopMenu.styles'
import Icon from '../../Icon/Icon'
import Button from '../../Button/Button'
import Logo from '../../Logo/Logo'

const popoverMenuStyles = {
  popoverContent: {
    margin: '0 0 0 -120px'
  }
}

const DesktopMenu = ({
  classes = {},
  logo,
  links,
  appsLinks,
  activeLink,
  onLogoClick,
  onSwitchAppClick,
  content,
  showSwitchApp,
  preset,
  ...props
}) => {
  return (
    <header className={classes['header']}>
      <div className={classes['wrapper']}>
        <div className={classes['logo']} onClick={onLogoClick}>
          {React.cloneElement(logo, { ...{ height: '42px' } })}
        </div>

        {links && (
          <div className={classes['nav']}>
            <HeaderLinks
              links={links}
              activeLink={activeLink}
              color={preset === 'dark' ? colors.white : colors.cyan}
              hoverColor={preset === 'dark' ? colors.highlight : colors.teal}
            />
          </div>
        )}

        {content && <div className={classes['content']}>{content}</div>}

        {preset === 'dark' ? (
          <DarkPopoverContainer
            {...props}
            activeLink={activeLink}
            appsLinks={appsLinks}
            preset={preset}
            override={popoverMenuStyles}
            popoverContainerPrefix={'desktop-'}
          />
        ) : (
          <LightPopoverContainer
            {...props}
            activeLink={activeLink}
            appsLinks={appsLinks}
            preset={preset}
            override={popoverMenuStyles}
            popoverContainerPrefix={'desktop-'}
          />
        )}
      </div>

      {showSwitchApp && (
        <div className={classes['switchAppButton']}>
          <Button variant="withIcon" onClick={onSwitchAppClick} width={'85px'} size={'small'}>
            <Icon
              className="buttonIcon"
              name="switchIcon"
              width={'1.3em'}
              height={'1.3em'}
              color={colors.warningBackground}
            />
            Old App
          </Button>
        </div>
      )}
    </header>
  )
}

export const LightDesktopMenu = withStyles(lightDesktopStyles)(DesktopMenu)
export const DarkDesktopMenu = withStyles(darkDesktopStyles)(DesktopMenu)

DesktopMenu.propTypes = {
  /**
   * App Logo. Defaults to Paymark Logo. Pass in you app specific logo here.
   */
  logo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),
  mobileLogo: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Action Handlers
   */
  onLogoClick: PropTypes.func,
  onSwitchAppClick: PropTypes.func,

  /**
   *  Header links
   */
  links: PropTypes.array,
  activeLink: PropTypes.string,

  /**
   *  Apps Links
   */
  appsLinks: PropTypes.array,

  /**
   * Custom Icon Menu Items
   */
  secondaryMenuItems: PropTypes.array,
  secondaryMenuIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Fixed Icon Menu Items
   */
  menuItems: PropTypes.array,
  applicationMenuItems: PropTypes.array,
  inviteMenuItems: PropTypes.array,
  notificationMenuItems: PropTypes.array,
  accountMenuItems: PropTypes.array,

  /**
   * Inner Content
   */
  content: PropTypes.any,

  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    header: PropTypes.object,
    logo: PropTypes.object,
    nav: PropTypes.object,
    title: PropTypes.object
  }),

  /**
   * Flag to show the button which, when clicked, navigates from beta app to old app.
   */
  showSwitchApp: PropTypes.bool,

  /**
   * Flag for enabling the mouseEnter event
   */
  mouseEnter: PropTypes.bool,

  /**
   * Timeout in ms used for the mouseEnter event
   */
  timeout: PropTypes.number,

  /**
   * Preset is used to specify an app-specific style preset
   */
  preset: PropTypes.oneOf(['light', 'dark'])
}

DesktopMenu.defaultProps = {
  logo: <Logo name="paymark" color={colors.brandDark} />,
  override: {},
  secondaryMenuIcon: <Icon name="plus-circle" color={colors.brandDark} />,
  showSwitchApp: false
}
