import * as variables from 'config/jss-vars'
import * as colors from 'config/colors'

const table = {
  width: '100%',
  color: colors.greyDark,
  borderCollapse: 'collapse',
  '& * tr': {
    borderBottom: `1px solid ${colors.line2} !important`
  },
  '& * td': {
    borderBottom: `1px solid ${colors.line2} !important`,
    padding: ['0.25em', '0.5em'],
    fontSize: '1.125em'
  },
  '& * th': {
    borderBottom: `1px solid ${colors.line1} !important`,
    padding: ['1em', '0.5em'],
    fontSize: '1em'
  },
  '& >thead': {
    textAlign: 'left',
    fontSize: '0.75em',
    letterSpacing: '0.03125em',
    fontWeight: variables.weightSemiBold,
    lineHeight: '0.875em',
    borderBottom: `1px solid ${colors.line1}`
  },
  '& >tbody': {
    textAlign: 'left',
    fontSize: '1em',
    letterSpacing: '0.01875em',
    lineHeight: '1.6875em',
    fontWeight: variables.weightRegular
  },
  '& >tfoot': {
    textAlign: 'left',
    fontWeight: variables.weightSemiBold,
    letterSpacing: '0.01875em',
    lineHeight: '1.6875em',
    color: colors.heading,
    fontSize: '1em'
  }
}

export default table
