import * as Yup from 'yup'
import { isDateRangeValid, isTimeValid } from '../../utils/filters.utils'
import { getCurrency } from '../../utils/number.utils'
import { getFeatureFlag, FEATURE_FLAG_TRANSACTION_MONTH_SEARCH } from 'utils/config.utils'

const monthSearch = getFeatureFlag(FEATURE_FLAG_TRANSACTION_MONTH_SEARCH)

const MAX_DAYS = monthSearch ? 32 : 7
const MAX_PURCHASE_AMOUNT = 999999.99 // 1 Million

export const validationSchema = Yup.object().shape({
  store: Yup.string()
    .required('Store must be selected.')
    .nullable(),
  startDate: Yup.object()
    .required('Start date is required.')
    .nullable(),
  endDate: Yup.object()
    .required('End Date is required.')
    .test('dateRange', `Date range must not exceed ${MAX_DAYS} days. `, function(endDate) {
      const { startDate } = this.parent
      return isDateRangeValid(startDate, endDate, MAX_DAYS - 1)
    })
    .nullable(),
  startTime: Yup.string()
    .required('Start Time is required.')
    .nullable(),
  endTime: Yup.string()
    .required('End Time is required.')
    .test('startTime', 'Please enter a valid start time', function(endTime) {
      const { startTime } = this.parent
      if ('Invalid date' === startTime) {
        return false
      }
      return true
    })
    .test('endTime', 'Please enter a valid end time', function(endTime) {
      if ('Invalid date' === endTime) {
        return false
      }

      return true
    })
    .test('timeRange', 'End time must be after start time ', function(endTime) {
      const { startDate, startTime, endDate } = this.parent

      const isValid = isTimeValid(startDate, endDate, startTime, endTime)
      return isValid
    })
    .nullable(),
  purchaseAmount: Yup.string()
    .test('timeRange', `Amount cannot be more than ${getCurrency(MAX_PURCHASE_AMOUNT)}`, function(purchaseAmount) {
      if (!purchaseAmount) {
        return true
      }
      let numberValue = purchaseAmount.replace('$', '').replace(/,/g, '')
      numberValue = parseFloat(numberValue)
      return numberValue <= MAX_PURCHASE_AMOUNT
    })
    .nullable(),
  suffix: Yup.string()
    .test('last4Digits', 'Suffix must be a 4 digit number', function(last4Digits) {
      if (last4Digits) {
        return /^\d{4}$/.test(last4Digits)
      }
      return true
    })
    .nullable(),
  terminalId: Yup.string()
    .test('terminalId', 'Terminal Id must be a number.', function(terminalId) {
      if (terminalId) {
        return !isNaN(terminalId)
      }
      return true
    })
    .test('terminalId', 'Please enter a valid Terminal ID number', function(terminalId) {
      if (terminalId) {
        return terminalId.length < 12
      }
      return true
    })
    .nullable(),
  cards: Yup.array().test('cards', 'Please select at least one Credit Card', function(cards) {
    const { cardType } = this.parent
    if (cardType === 'Credit') {
      return cards.length > 0
    }
    return true
  }),
  transactionTypes: Yup.array()
    .test('transactionTypes', 'Please select at least one Transaction Type', function(transactionTypes) {
      const { transactionCategory } = this.parent
      if (transactionCategory === 'Individual Types') {
        return transactionTypes.length > 0
      }
      return true
    })
    .nullable()
})
