import React from 'react'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'

const HeaderItem = ({ children }) => {
  return (
    <Text variant="h5" color={colors.greyDark}>
      {children}
    </Text>
  )
}

const ListHeader = ({ classes }) => {
  return (
    <div className={classes['header']}>
      <div className={classes['avatar']}> </div>
      <div className={classes['name']}>
        <HeaderItem>Name </HeaderItem>
      </div>
      <div className={classes['email']}>
        <HeaderItem>Email </HeaderItem>
      </div>
      <div className={classes['store']}>
        <HeaderItem>Store </HeaderItem>
      </div>
      <div className={classes['status']}>
        <HeaderItem>Status </HeaderItem>
      </div>
      <div className={classes['role']}>
        <HeaderItem>Role </HeaderItem>
      </div>
    </div>
  )
}

export default ListHeader
