import React, { useState, useCallback } from 'react'
import styles from './UserDelete.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import UserDeleteConfirmation from './UserDeleteConfirmationDialog'
import apis from 'api'
import { EVENT_DELETE_USER } from 'constants/mixpanel.constants'

const UserDelete = ({ classes, user }) => {
  const { Mixpanel: mixpanel } = apis
  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice

  const deleteUserAction = useCallback(id => dispatch(usersActions.deleteUser(id)), [dispatch, usersActions])

  const onAccept = async () => {
    setConfirmationVisible(false)

    mixpanel.track(EVENT_DELETE_USER)

    deleteUserAction(user.id)
  }

  const onClose = () => {
    setConfirmationVisible(false)
  }

  const handleDeleteUser = () => {
    setConfirmationVisible(true)
  }

  return (
    <>
      <div className={classes['wrapper']} onClick={handleDeleteUser}>
        <Icon name="clear-filled" />
        <div className={classes['text']}>
          <Text color={colors.cyan} variant="body">
            Delete User
          </Text>
        </div>
      </div>

      {confirmationVisible && (
        <UserDeleteConfirmation
          {...{
            user,
            onAccept,
            onClose
          }}
        />
      )}
    </>
  )
}

export default withStyles(styles)(UserDelete)
