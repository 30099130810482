import { connect } from 'react-redux'
import { accountSlice } from 'reduxSlices';

const loadingState = state => ({
  isLoading: accountSlice.selectors.isLoading(state)
})

const mapStateToProps = state => ({
  ...loadingState(state)
})

export default Component => connect(mapStateToProps)(Component)
