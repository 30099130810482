import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP, LAPTOP_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  total: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: ({ isExpanded }) => (isExpanded ? '85px' : '50px')
  },
  currentPeriod: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '& >h1': {
      fontSize: '2em',
      padding: '0.5rem 0',
      fontWeight: '500',
      letterSpacing: '0px',
      textAlign: 'right',
      margin: '0'
    },
    [MOBILE_UP()]: {
      '& >h1': {
        fontSize: '2.25em',
        padding: '1.25rem 0'
      }
    },
    [LAPTOP_UP()]: {
      '& >h1': {
        fontSize: '2.5em',
        padding: '1rem 0'
      }
    },
    [LARGE_SCREEN_UP()]: {
      '& >h1': {
        fontSize: '3.25em',
        padding: '1.2rem 0'
      }
    }
  },
  oldPeriod: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  changeValue: {
    marginLeft: '0.5rem',
    marginRight: 0,
    padding: '0.2rem 0',
    '& h2': {
      fontSize: '1.2em',
      padding: '0.6rem 0'
    },
    [MOBILE_UP()]: {
      '& h2': {
        fontSize: '1.25em',
        padding: '1.1rem 0'
      }
    },
    [LAPTOP_UP()]: {
      '& h2': {
        fontSize: '1.25em',
        padding: '1rem 0'
      }
    },
    [LARGE_SCREEN_UP()]: {
      '& h2': {
        fontSize: '1.7em',
        padding: '0.5rem 0'
      },
      padding: '1rem 0'
    }
  }
}

export default styles
