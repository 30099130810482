import React from 'react'
import { NotificationModal } from 'components/common'
import { PermissionsInfo } from 'components/common'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import withStyles from 'react-jss'
import styles from './PermissionsModal.styles'

const PermissionsInfoModal = ({ classes, onClose }) => {
  return (
    <NotificationModal
      {...{
        onClose,
        size: 'medium'
      }}
    >
      <div className={classes['modalHeader']}>
        <Icon name="info" width={'2em'} height={'2em'} className={classes['helpIcon']} />
        <Text variant="h2">Insights Permissions</Text>
      </div>

      <PermissionsInfo />
    </NotificationModal>
  )
}

export default withStyles(styles)(PermissionsInfoModal)
