import React from 'react'
import { connect, getIn } from 'formik'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'

/**
 * Formik wrapper on the Checkbox component. Refer checkbox component for props.
 */
const FormikCheckbox = props => {
  const { name, label, formik, ...rest } = props
  const { handleChange, handleBlur } = formik
  const error = getIn(formik.errors, name)
  const touch = getIn(formik.touched, name)
  const value = getIn(formik.values, name)
  const errorMessage = touch && error

  const checkboxProps = {
    checked: value || false,
    label,
    errorMessage,
    name,
    onChange: handleChange,
    onBlur: handleBlur,
    ...rest
  }

  return <Checkbox {...checkboxProps} />
}

export default connect(FormikCheckbox)
