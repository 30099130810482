const styles = {
  form: {
    display: 'flex',
    alignItems: 'stretch',
    '& > div': {
      margin: '0 1em 0.325em 0'
    }
  },
  submitButton: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > button': {
      marginTop: '0.7rem'
    }
  },
  errorMessage: {
    textAlign: 'center',
    margin: ['1rem', 0]
  }
}

export default styles
