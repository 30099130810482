import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import api from '../../api'
import { getUsersWithStoreInfo } from './users.helper'
import accountSlice from 'reduxSlices/accountSlice'
import { createCustomSelector } from 'utils/common.utils'

const initialState = {
  list: [],
  isLoading: false,
  usersUpdated: false,
  listByEmail: []
}

const getAllUsers = createCustomSelector('users.list')
const getAllUsersByEmail = createCustomSelector('users.listByEmail')
const isLoading = createCustomSelector('users.isLoading')
const usersUpdated = createCustomSelector('users.usersUpdated')

const selectors = {
  getAllUsersByEmail,
  getAllUsers,
  isLoading,
  usersUpdated
}

const reducers = {
  fetchAllUsers: state => {
    return {
      ...state,
      isLoading: true
    }
  },
  fetchUsersByEmail: state => {
    return {
      ...state,
      isLoading: true
    }
  },
  setAllUsers: (state, action) => {
    const { payload } = action
    return {
      ...state,
      isLoading: false,
      list: payload
    }
  },
  setUsersByEmail: (state, action) => {
    const { payload } = action
    return {
      ...state,
      isLoading: false,
      listByEmail: payload
    }
  },
  updateUser: state => {
    return {
      ...state
    }
  },
  updateUsers: state => {
    return {
      ...state
    }
  },
  deleteUser: state => {
    return {
      ...state
    }
  },
  setUsersUpdated: state => {
    return { ...state, usersUpdated: true }
  },
  unsetUsersUpdated: state => {
    return { ...state, usersUpdated: false }
  }
}

const usersSlice = createSlice({
  slice: 'users',
  initialState: initialState,
  reducers
})

function* fetchAllUsers() {
  const { selectors: accountSelectors } = accountSlice

  const usersList = yield call(api.getAllUsersByOrg)
  const merchants = yield select(accountSelectors.getMerchantAccess)
  const parsedUsers = getUsersWithStoreInfo(usersList, merchants)

  yield put(usersSlice.actions.setAllUsers(parsedUsers))
}

function* fetchUsersByEmail(action) {
  const { payload } = action
  const usersList = yield call(api.getUsersByEmail, payload)
  yield put(usersSlice.actions.setUsersByEmail(usersList))
}

function* updateUser(action) {
  const { payload } = action
  const { selectors: accountSelectors } = accountSlice
  yield call(api.updateUser, payload)

  // Fetch the latest user list and have the global loader.

  yield put(accountSlice.actions.startLoading())

  const usersList = yield call(api.getAllUsersByOrg)
  const merchants = yield select(accountSelectors.getMerchantAccess)
  const parsedUsers = getUsersWithStoreInfo(usersList, merchants)
  yield put(usersSlice.actions.setAllUsers(parsedUsers))

  yield put(usersSlice.actions.setUsersUpdated())
  yield put(accountSlice.actions.endLoading())
  yield put(usersSlice.actions.unsetUsersUpdated())
}

function* updateUsers(action) {
  const { payload } = action
  const { usersToBeUpdated } = payload
  const { selectors: accountSelectors } = accountSlice

  for (let userToBeUpdated of usersToBeUpdated) {
    yield call(api.updateUser, userToBeUpdated)
  }

  // Fetch the latest user list and have the global loader.

  yield put(accountSlice.actions.startLoading())

  const usersList = yield call(api.getAllUsersByOrg)
  const merchants = yield select(accountSelectors.getMerchantAccess)
  const parsedUsers = getUsersWithStoreInfo(usersList, merchants)
  yield put(usersSlice.actions.setAllUsers(parsedUsers))

  yield put(usersSlice.actions.setUsersUpdated())
  yield put(accountSlice.actions.endLoading())
  yield put(usersSlice.actions.unsetUsersUpdated())
}

function* deleteUser(action) {
  const { payload } = action
  const { selectors: accountSelectors } = accountSlice
  yield call(api.deleteUser, payload)

  // Fetch the latest user list and have the global loader.

  yield put(accountSlice.actions.startLoading())

  const usersList = yield call(api.getAllUsersByOrg)
  const merchants = yield select(accountSelectors.getMerchantAccess)
  const parsedUsers = getUsersWithStoreInfo(usersList, merchants)
  yield put(usersSlice.actions.setAllUsers(parsedUsers))

  yield put(usersSlice.actions.setUsersUpdated())
  yield put(accountSlice.actions.endLoading())
  yield put(usersSlice.actions.unsetUsersUpdated())
}

function* userSagas() {
  yield takeLatest(usersSlice.actions.fetchAllUsers.toString(), fetchAllUsers)
  yield takeLatest(usersSlice.actions.fetchUsersByEmail.toString(), fetchUsersByEmail)
  yield takeLatest(usersSlice.actions.updateUser.toString(), updateUser)
  yield takeLatest(usersSlice.actions.updateUsers.toString(), updateUsers)
  yield takeLatest(usersSlice.actions.deleteUser.toString(), deleteUser)
}

export default {
  ...usersSlice,
  selectors,
  sagas: userSagas
}
