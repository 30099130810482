import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateAreas: `
    "header"
    "section"
    `,
    msGridColumns: 'minmax(min-content, 1fr)',
    backgroundColor: colors.pageBackground
  },
  header: {
    top: 0,
    position: 'sticky',
    zIndex: 999,
    width: '100%',
    '& > header': {
      position: 'relative'
    }
  },
  section: {
    gridArea: 'section',
    msGridColumn: 1,
    msGridRow: 2,
    padding: '5px',
    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: '1rem'
    },
    [mediaQueryHelper.LARGE_SCREEN_UP()]: {
      padding: '2.5rem'
    }
  },
  banner: {
    width: '100%',
    zIndex: 999
  }
}

export default styles
