import React from 'react'
import { NotificationModal } from 'components/common'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import withStyles from 'react-jss'
import styles from './AddNewStoreForm.styles'

const StoreAddedSuccessModal = ({ classes, onClose }) => {
  return (
    <NotificationModal
      {...{
        onClose
      }}
    >
      <div>
        <div className={classes['successIcon']}>
          <Icon name="check-circle" color={colors.success} width={'3.5em'} />
        </div>

        <div className={classes['subtitle']}>
          <Text variant="h5">New store has been successfully signed up</Text>
        </div>
      </div>
    </NotificationModal>
  )
}

export default withStyles(styles)(StoreAddedSuccessModal)
