import * as colors from 'config/colors'
import * as variables from 'config/jss-vars'
const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    height: '2.5em'
  },
  exportButton: {
    border: `1px solid ${colors.line2}`,
    borderRadius: '0.2em',
    height: 'auto',
    padding: '0.4em 1.0em 0.4em 0.8em',
    display: 'flex',
    '& p': {
      margin: 0
    },
    cursor: 'pointer'
  },
  exportIcon: {
    margin: '0.2em 0 0 0',
    padding: '0 0.6em 0 0'
  },
  exportText: {
    margin: '0'
  },
  title: {
    'font-size': '1.75em',
    'font-weight': variables.weightSemiBold,
    margin: 'auto 0'
  }
}

export default styles
