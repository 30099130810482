import React, { Component } from 'react'
import styles from './SearchStore.styles'
import withStyles from 'react-jss'
import Fuse from 'fuse.js'
import Icon from '../Icon/Icon'
import Text from '../Text/Text'
import { Checkbox } from '../CheckboxComponent/Checkbox'
import * as colors from 'config/colors'
import objstr from 'obj-str'
import PropTypes from 'prop-types'
import Message from '../Message/Message'
import { BREAK_POINT_EXTRA_LARGE, BREAK_POINT_MEDIUM } from 'config/mediaQueryHelper'

const DEFAULT_PLACEHOLDER_MESSAGE = 'Find and select stores'
const DEFAULT_ERROR_MESSAGE = 'Sorry we could not find any stores that matched that search'

const checkboxOverride = {
  label: {
    position: 'absolute'
  }
}

const ErrorMessage = ({ errorMessage, classes }) => {
  return (
    <div className={classes['errorMessage']}>
      <Message text={errorMessage} variant="error" showIcon={false} align="center" />
    </div>
  )
}

const SearchResultHeader = ({
  classes,
  visibleStoresCount = 0,
  totalStoresCount = 0,
  isShowAll,
  onShowAll,
  showCheckbox
}) => {
  return (
    <div className={classes['showAllContainer']}>
      <Text variant="body">
        {' '}
        {visibleStoresCount} of {totalStoresCount} stores listed
      </Text>
      {showCheckbox && (
        <Checkbox
          label={<Text variant="body">Show all stores </Text>}
          checked={isShowAll}
          onClick={() => {
            onShowAll()
          }}
          onChange={e => {
            e.preventDefault()
          }}
          override={checkboxOverride}
        />
      )}
    </div>
  )
}

const SearchResultItem = ({ store, key, className, handleSelectStore, searchKey }) => {
  return (
    <div
      key={store[key]}
      className={className}
      tabIndex="0"
      onClick={() => {
        handleSelectStore(store)
      }}
      data-hj-suppress
    >
      {store[searchKey]}
    </div>
  )
}

const SearchResult = props => {
  const {
    classes,
    allStores,
    filteredStores,
    handleSelectStore,
    searchKey,
    uniqueKey,
    isShowAll,
    selectedStore,
    removeSelectedStore
  } = props
  const visibleStores = isShowAll ? removeSelectedStore(allStores, selectedStore) : filteredStores

  return isShowAll ? (
    <div className={classes['result']}>
      {visibleStores.map(store => {
        const className = objstr({
          [classes['item']]: true
        })
        const key = uniqueKey || searchKey
        return <SearchResultItem {...{ store, key, className, handleSelectStore, searchKey }} />
      })}
    </div>
  ) : (
    <div className={classes['resultNoScroll']} ref={props.inputRef}>
      {visibleStores.map(store => {
        const className = objstr({
          [classes['item']]: true
        })
        const key = uniqueKey || searchKey
        return <SearchResultItem {...{ store, key, className, handleSelectStore, searchKey }} />
      })}
    </div>
  )
}

class SearchStore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      isShowAll: false,
      filteredStores: undefined
    }
    this.fuseList = null
    this.setupFuseSearch(props.allStores)
  }

  reset = () => {
    this.setState({
      value: '',
      filteredStores: undefined
    })
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({
      value
    })
    this.setState({ isShowAll: false })
    if (value) {
      const result = this.fuseList.search(value)
      const { visibleStoresTemp } = this.retrieveItemsByWidth(result)
      this.setState({ filteredStores: visibleStoresTemp })
    } else {
      this.handleResize()
    }
  }

  setupFuseSearch = list => {
    const { searchKey, fuseOptions } = this.props
    fuseOptions.keys = [searchKey]
    const fuseList = new Fuse(list, fuseOptions)
    this.fuseList = fuseList
  }

  handleSelectStore = store => {
    const { onSelect } = this.props
    this.reset()
    onSelect(store)
  }

  handleClose = () => {
    this.reset()
    this.handleSelectStore()
  }

  componentDidUpdate() {
    if (this.props.allStores !== this.state.allStores) {
      const { allStores } = this.props
      this.setupFuseSearch(allStores)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  retrieveItemsByWidth(stores) {
    if (!stores) {
      return null
    }
    const allStoresLength = stores.length
    const { innerWidth } = window
    let visibleStoresTemp = [],
      showCheckboxTemp = true
    if (innerWidth >= BREAK_POINT_EXTRA_LARGE && allStoresLength > 15) {
      visibleStoresTemp = stores.slice(0, 15)
    } else if (innerWidth >= BREAK_POINT_MEDIUM && innerWidth < BREAK_POINT_EXTRA_LARGE && allStoresLength > 10) {
      visibleStoresTemp = stores.slice(0, 10)
    } else if (innerWidth < BREAK_POINT_MEDIUM && allStoresLength > 5) {
      visibleStoresTemp = stores.slice(0, 5)
    } else {
      visibleStoresTemp = stores
      showCheckboxTemp = false
    }
    return { visibleStoresTemp, showCheckboxTemp }
  }

  handleResize() {
    const { allStores, selectedStore } = this.props
    const allStoresSelectedRemoved = this.removeSelectedStore(allStores, selectedStore)

    const { visibleStoresTemp, showCheckboxTemp } = this.retrieveItemsByWidth(allStoresSelectedRemoved)
    this.setState({ filteredStores: visibleStoresTemp, value: '', showCheckboxState: showCheckboxTemp })
    return { visibleStoresTemp, showCheckboxTemp }
  }

  removeSelectedStore(merchants, selected) {
    if (!merchants) return merchants
    return merchants.filter(({ cardAcceptorIdCode }) => {
      if (selected) {
        return cardAcceptorIdCode !== selected.cardAcceptorIdCode
      }
      return true
    })
  }

  render() {
    const {
      classes,
      placeholder,
      errorMessage,
      searchKey,
      isActive,
      activateSearch,
      uniqueKey,
      shortPlaceholder,
      allStores,
      selectedStore
    } = this.props
    const { value, filteredStores, isShowAll, showCheckboxState } = this.state

    let visibleStores = [],
      showCheckbox = true
    if (!filteredStores) {
      const { visibleStoresTemp, showCheckboxTemp } = this.handleResize()
      visibleStores = visibleStoresTemp
      showCheckbox = showCheckboxTemp
    } else {
      visibleStores = filteredStores
      showCheckbox = showCheckboxState
    }

    const onShowAll = () => {
      const { isShowAll } = this.state
      const newIsShowAll = isShowAll ? false : true
      this.setState({
        isShowAll: newIsShowAll,
        value: ''
      })
      if (newIsShowAll) {
        this.setState({ filteredStores: this.removeSelectedStore(allStores, selectedStore) })
      } else {
        this.handleResize()
      }
    }

    const inputProps = {
      value,
      onChange: this.handleChange
    }

    const dataExists = filteredStores ? filteredStores.length > 0 : false
    const noResult = value.length > 0 && filteredStores.length === 0
    visibleStores = isShowAll ? this.removeSelectedStore(allStores, selectedStore) : visibleStores
    const visibleStoresCount = visibleStores.length

    if (!isActive) {
      return (
        <div className={classes['inputContainer']} onClick={activateSearch}>
          <Icon className={classes['searchIcon']} name="search" color={colors.icon} />
          <input
            placeholder={shortPlaceholder}
            className={classes['input']}
            type="text"
            onClick={activateSearch}
            onChange={activateSearch}
          />
        </div>
      )
    }

    return (
      <div className={classes['container']}>
        <div className={classes['closeIcon']} onClick={this.handleClose}>
          <Icon name="clear" width={'2em'} height={'2em'} color={colors.icon} />
        </div>
        <div className={`${classes['inputContainer']} ${classes['innerInput']}`}>
          <Icon className={classes['searchIcon']} name="search" color={colors.icon} />
          <input placeholder={placeholder} className={classes['input']} type="text" {...inputProps} autoFocus />
          <SearchResultHeader
            {...{
              classes,
              visibleStoresCount,
              isShowAll,
              onShowAll: onShowAll,
              totalStoresCount: allStores.length,
              showCheckbox
            }}
          />
        </div>

        {noResult && <ErrorMessage {...{ classes, errorMessage }} />}

        {dataExists && (
          <SearchResult
            {...{
              classes,
              allStores,
              filteredStores,
              handleSelectStore: this.handleSelectStore,
              searchKey,
              uniqueKey,
              isShowAll,
              selectedStore,
              removeSelectedStore: this.removeSelectedStore
            }}
          />
        )}
      </div>
    )
  }
}

SearchStore.propTypes = {
  allStores: PropTypes.array,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  shortPlaceholder: PropTypes.string,
  errorMessage: PropTypes.string,
  override: PropTypes.shape({
    popoverContent: PropTypes.object,
    menuList: PropTypes.object,
    menuItem: PropTypes.object,
    activeMenuItem: PropTypes.object
  }),
  /** Keys to be used in search. Don't pass this if  fuseOptions is supplied*/
  searchKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  /*Override the fuse search options*/
  fuseOptions: PropTypes.object,
  isActive: PropTypes.bool,
  activateSearch: PropTypes.func
}

SearchStore.defaultProps = {
  override: {},
  allStores: [],
  placeholder: DEFAULT_PLACEHOLDER_MESSAGE,
  errorMessage: DEFAULT_ERROR_MESSAGE,
  shortPlaceholder: DEFAULT_PLACEHOLDER_MESSAGE,
  searchKey: 'name',
  isActive: false,
  fuseOptions: {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name']
  }
}

export default withStyles(styles)(SearchStore)
