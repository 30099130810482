import { line2, background1, line1 } from 'config/colors'

const styles = {
  card: {
    backgroundColor: 'green',
    padding: '0 1rem',
    border: {
      color: line2,
      width: '1px',
      style: 'solid'
    },
    borderRadius: 4,
    width: '100%',
    height: '100%',
    boxShadow: {
      x: 0,
      y: '1px',
      blur: '2px',
      color: line2
    },
    '@media (max-width: 47.99rem)': {
      border: 0,
      padding: '0 0.9375rem',
      borderBottom: {
        width: '1px',
        style: 'solid',
        color: line1
      },
      borderRadius: 2,
      marginBottom: 0
    },
    extend: ({ override }) => ({
      ...override.card
    })
  },
  'no-highlight': {
    backgroundColor: background1,
    extend: ({ override }) => ({
      ...override['no-highlight']
    })
  },
  highlight: {
    backgroundColor: line2,
    extend: ({ override }) => ({
      ...override['highlight']
    })
  },
  'no-padding': {
    padding: 0,
    extend: ({ override }) => ({
      ...override['no-padding']
    })
  }
}

export default styles
