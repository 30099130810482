import React from 'react'
import withStyles from 'react-jss'
import styles from './NotificationModal.styles'
import Modal from 'components/Modal/Modal'

const ModalContent = ({ classes, onClose, children }) => {
  return (
    <div className={classes['modalContent']}>
      <div className={classes['body']}>{children}</div>
    </div>
  )
}

const StyledModalContent = withStyles(styles)(ModalContent)

const NotificationModal = ({ children, onClose, size = 'small' }) => {
  return (
    <>
      <Modal isOpen={true} onClose={onClose} size={size}>
        <StyledModalContent
          {...{
            onClose
          }}
        >
          {children}
        </StyledModalContent>
      </Modal>
    </>
  )
}

export default NotificationModal
