import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  permissions: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      marginRight: '2rem',
      marginBottom: '1rem',
      [MOBILE_UP()]: {
        marginBottom: '0 !important'
      }
    },
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  roleList: {
    flexDirection: 'column',
    display: 'flex',
    margin: '0.8em 0 1em 0'
  },
  roleInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  roleItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    display: 'flex',
    marginTop: '0.8em'
  },
  roleIcon: {
    margin: '0 1em 1em 0'
  }
}

export default styles
