import React from 'react'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import styles from './SalesCard.styles'
import { ChartToolTip, DashboardCard, ImprovementPercentage, CustomDot, ComposedChart } from 'components/common'
import { getTotal, getDifferencePercentage, formatNumber } from 'utils/number.utils'
import { GRAPH_SALES_COLOR, GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import { getMinMaxSalesValue } from 'utils/salesRevenue.utils'
import SalesCardLoader from './SalesCardLoader'
import _ from 'lodash'
import { formatDateByRange } from 'components/common/ChartToolTip/ChartToolTip.utils'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'

const dashboardCommonParams = {
  title: 'Sales Total',
  iconName: 'sales',
  height: '230px'
}

const valueFormatter = value => formatNumber(value, false, false)

const SalesCard = ({ classes }) => {
  const {
    allSalesRevenueAgg,
    isComparePeriod,
    getExpandedCard,
    expandCardAction,
    compressCardsAction
  } = useSalesRevenueAgg()
  const { data: parsedData, isLoading } = allSalesRevenueAgg
  const dataKey = 'sales'
  const isExpanded = getExpandedCard === dataKey
  const {
    filter: { range }
  } = useFilters()

  if (isLoading) {
    return (
      <DashboardCard
        {...{
          ...dashboardCommonParams,
          isExpanded
        }}
      >
        <SalesCardLoader />
      </DashboardCard>
    )
  }

  const { maxValue, minValue } = getMinMaxSalesValue(isComparePeriod, parsedData, null)

  const currentTotal = _.isEmpty(parsedData) ? 0 : getTotal(parsedData, 'currentSales')
  let oldTotal = isComparePeriod ? (_.isEmpty(parsedData) ? 0 : getTotal(parsedData, 'oldSales')) : 0
  let difference = isComparePeriod ? (_.isEmpty(parsedData) ? 0 : getDifferencePercentage(currentTotal, oldTotal)) : 0

  const onResize = isExpand => {
    if (isExpand) {
      return expandCardAction(dataKey)
    }
    compressCardsAction()
  }

  const dateFormatter = value => {
    return formatDateByRange(value, range)
  }

  return (
    <DashboardCard
      {...{
        ...dashboardCommonParams,
        onResize,
        isExpanded
      }}
    >
      <div className={classes['graph']} data-hj-suppress>
        {isExpanded ? (
          <div className={classes['expandedContent']}>
            <ComposedChart
              {...{
                showYAxis: true,
                data: parsedData,
                isCompare: isComparePeriod,
                showTooltip: true,
                minValue,
                maxValue,
                CustomTooltip: (
                  <ChartToolTip
                    {...{ isCompare: isComparePeriod, valueFormatter, primaryColor: GRAPH_SALES_COLOR, dateFormatter }}
                  />
                ),
                CustomDot: <CustomDot />
              }}
            />
          </div>
        ) : (
          <div className={classes['content']}>
            <ComposedChart
              {...{
                data: parsedData,
                isCompare: isComparePeriod,
                minTickGap: 200,
                minValue,
                maxValue
              }}
            />
          </div>
        )}
      </div>

      <div className={classes['total']} data-hj-suppress>
        <div className={classes['currencyValue']}>
          <Text variant="h1" color={GRAPH_SALES_COLOR}>
            {valueFormatter(currentTotal)}
          </Text>
          {isComparePeriod && (
            <Text variant="h2" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
              {valueFormatter(oldTotal)}
            </Text>
          )}
        </div>
        {isComparePeriod && (
          <div className={classes['changeValue']}>
            <ImprovementPercentage {...{ value: difference }} />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

export default withStyles(styles)(SalesCard)
