import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP, LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  customersCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
    height: '100%'
  },
  graphs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  currentDataGraph: {
    marginTop: 10,
    height: '90px',
    width: '180px',
    textAlign: 'center',
    [MOBILE_UP()]: {
      width: '280px'
    },
    [LAPTOP_UP()]: {
      width: '400px'
    },
    [SCREEN_UP()]: {
      width: '300px'
    },
    [LARGE_SCREEN_UP()]: {
      width: '500px'
    }
  },
  pastDataGraph: {
    composes: '$currentDataGraph',
    marginTop: '-42px',
    height: '50px',
    '& h5': {
      paddingTop: '1.5rem'
    }
  },
  errroMessage: {
    composes: '$currentDataGraph',
    textAlign: 'center',
    height: '50px'
  },
  newCustomers: { display: 'flex', flexDirection: 'column' },
  returnCustomers: { composes: '$newCustomers' },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%'
  },
  customerLabel: {
    display: 'flex',
    margin: 'auto',
    paddingTop: '0.75rem',
    alignItems: 'center'
  },
  label: {
    padding: [0, '1rem'],
    display: 'none',
    [MOBILE_UP()]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  noTransactionMessage: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    height: 90
  }
}

export default styles
