import * as mediaQueryHelper from 'config/mediaQueryHelper'

import * as colors from 'config/colors'
const { LAPTOP_UP } = mediaQueryHelper

const styles = {
  form: {},
  errorMessage: {
    textAlign: 'center',
    margin: ['1rem', 0]
  },
  submitButton: {
    padding: ['1.5rem', 0],
    borderTop: `1px solid ${colors.line2}`,
    borderBottom: `1px solid ${colors.line1}`,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem'
  },
  note: {
    marginBottom: '1rem'
  },
  message: {
    fontWeight: 500,
    flex: [1, 1, 'auto'],
    textAlign: 'center',
    paddingBottom: '1rem',

    '& .text': {
      width: '300px',
      margin: 'auto'
    },
    [LAPTOP_UP()]: {
      textAlign: 'left',
      paddingBottom: 0
    }
  },
  conditions: {
    marginBottom: '1.5rem'
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center'
  },
  subtitle: {
    textAlign: 'center',
    margin: ['1.5rem', 0, '1rem', 0]
  }
}

export default styles
