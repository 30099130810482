import React, { useState, useEffect, useCallback } from 'react'
import withStyles from 'react-jss'
import styles from './UserPermissions.styles'
import {
  ROLE_OWNER,
  ROLE_VIEWER,
  ROLE_GLOBAL_VIEWER,
  SERVER_ROLE_OWNER,
  SERVER_ROLE_VIEWER
} from 'constants/general.constants'
import Radio from 'components/Radio/Radio'
import { PermissionsConfirmationModal } from 'components/common'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import apis from 'api'
import { EVENT_EDIT_USER_INFORMATION } from 'constants/mixpanel.constants'
import { getInsightsRole } from 'utils/user.utils'

const UserPermissions = ({ role, user, handleAddStore, classes }) => {
  const [selectedPermission, setSelectedPermission] = useState('')
  const [visible, setVisible] = useState(false)
  const [newRole, setNewRole] = useState('')
  const text = `Are you sure you want to change from ${selectedPermission} to ${newRole}?`
  const { Mixpanel: mixpanel } = apis

  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice

  const updateUserAction = useCallback(actionParams => dispatch(usersActions.updateUser(actionParams)), [
    dispatch,
    usersActions
  ])

  useEffect(() => {
    setSelectedPermission(role)
  }, [role])

  const onPermissionChange = role => {
    setNewRole(role)
    setVisible(true)
  }

  const onAccept = async params => {
    const { newRole } = params

    setVisible(false)

    let newRoles = []
    let updatedUser = { ...user }

    switch (newRole) {
      case ROLE_GLOBAL_VIEWER:
        newRoles.push({ role: SERVER_ROLE_VIEWER })
        updatedUser.roles = newRoles

        mixpanel.track(EVENT_EDIT_USER_INFORMATION, {
          userPermissions: getInsightsRole(newRoles, 'cardAcceptorIdCode')
        })

        await updateUserAction(updatedUser)
        break
      case ROLE_OWNER:
        newRoles.push({ role: SERVER_ROLE_OWNER })
        updatedUser.roles = newRoles

        mixpanel.track(EVENT_EDIT_USER_INFORMATION, {
          userPermissions: getInsightsRole(newRoles, 'cardAcceptorIdCode')
        })

        await updateUserAction(updatedUser)
        break
      case ROLE_VIEWER:
        updatedUser.roles = []
        handleAddStore(updatedUser)
        break
      default:
    }
  }

  const onClose = () => {
    setVisible(false)
  }

  return (
    <div className={classes['permissions']}>
      {visible && (
        <PermissionsConfirmationModal
          {...{
            onAccept,
            onClose,
            callbackParams: { oldRole: role, newRole },
            text
          }}
        ></PermissionsConfirmationModal>
      )}

      {[ROLE_VIEWER, ROLE_GLOBAL_VIEWER, ROLE_OWNER].map(roleItem => {
        return (
          <Radio
            key={roleItem}
            label={roleItem}
            onChange={() => onPermissionChange(roleItem)}
            checked={roleItem === selectedPermission}
          />
        )
      })}
    </div>
  )
}

export default withStyles(styles)(UserPermissions)
