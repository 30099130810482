import 'react-dates/initialize'
import React from 'react'
import PropTypes from 'prop-types'
import jss from './DatePicker.styles'
import withStyles from 'react-jss'
import Icon from '../Icon/Icon'
import * as colors from 'config/colors'
import MediaQuery from 'react-responsive'
import { BREAK_POINT_MEDIUM, min, max } from 'config/mediaQueryHelper'
import * as ReactDates from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import objstr from 'obj-str'
import moment from 'moment'

const { DateRangePicker, SingleDatePicker } = ReactDates

const LeftArrow = () => {
  return (
    <div
      role="button"
      tabIndex="0"
      className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
      aria-label="Move backward to switch to the previous month."
    >
      <Icon name="arrowLeft" />
    </div>
  )
}

const RightArrow = () => {
  return (
    <div
      role="button"
      tabIndex="0"
      className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
      aria-label="Move forward to switch to the next month."
    >
      <Icon name="arrowRight" />
    </div>
  )
}

const ChangeDateBtn = ({ className, onClick, iconName }) => {
  return (
    <div className={className} onClick={onClick}>
      <Icon name={iconName} height={'40px'} width={'40px'} />
    </div>
  )
}

const SingleDate = ({ classes, isOutsideRange, date, onDateChange, datePickerProps, showControls, changeInterval }) => {
  const { disabled } = datePickerProps

  let previousDate = moment(date).subtract(1, changeInterval)
  let nextDate = moment(date).add(1, changeInterval)

  // To navigate to current day
  if (changeInterval !== 'days') {
    const isNextDateAfterToday = nextDate.isAfter(moment().endOf('day'))
    const isSelectedDateBeforeToday = moment(date).isBefore(moment().startOf('day'))

    if (isNextDateAfterToday && isSelectedDateBeforeToday) {
      nextDate = moment()
    }
  }

  const prevClassName = objstr({
    [classes['prevButton']]: true,
    [classes['disabled']]: disabled || isOutsideRange(previousDate)
  })

  const nextBtnClassName = objstr({
    [classes['nextButton']]: true,
    [classes['disabled']]: disabled || isOutsideRange(nextDate)
  })

  const singleDatePicker = objstr({
    [classes['datePicker']]: true,
    [classes['singleDatePicker']]: true
  })

  return (
    <div className={singleDatePicker}>
      {showControls && (
        <ChangeDateBtn
          {...{
            className: prevClassName,
            iconName: 'arrowLeft',
            onClick: () => {
              if (isOutsideRange(previousDate)) {
                return
              }
              onDateChange(previousDate)
            }
          }}
        />
      )}
      <SingleDatePicker {...{ ...datePickerProps, numberOfMonths: 1, date, onDateChange }} />

      {showControls && (
        <ChangeDateBtn
          {...{
            className: nextBtnClassName,
            iconName: 'arrowRight',
            onClick: () => {
              if (isOutsideRange(nextDate)) {
                return
              }
              onDateChange(nextDate)
            }
          }}
        />
      )}
    </div>
  )
}

const DateRange = ({ classes, datePickerProps, customArrowIcon }) => {
  return (
    <div className={classes['datePicker']}>
      <MediaQuery query={`(${max(BREAK_POINT_MEDIUM)})`}>
        <DateRangePicker {...{ ...datePickerProps, numberOfMonths: 1, customArrowIcon }} />
      </MediaQuery>
      <MediaQuery query={`(${min(BREAK_POINT_MEDIUM)})`}>
        <DateRangePicker {...{ ...datePickerProps, customArrowIcon }} />
      </MediaQuery>
    </div>
  )
}

/**
 *  {https://github.com/airbnb/react-dates}
 */
const DatePicker = props => {
  const {
    classes,
    displayFormat,
    override, // eslint-disable-line no-unused-vars
    isSingleDate,
    date,
    onDateChange,
    isOutsideRange,
    showControls,
    changeInterval,
    ...rest
  } = props
  const Calendar = () => <Icon name={'calendar'} width={'1em'} height={'1em'} color={colors.icon} />

  const datePickerProps = {
    hideKeyboardShortcutsPanel: false,
    customInputIcon: <Calendar />,
    displayFormat,
    navPrev: <LeftArrow {...{ classes }} />,
    navNext: <RightArrow {...{ classes }} />,
    showDefaultInputIcon: true,
    isOutsideRange,
    firstDayOfWeek: 1,
    ...rest
  }

  const customArrowIcon = <Icon name="calendarEnd" width={'1em'} height={'1em'} color={colors.icon} />

  if (isSingleDate) {
    return (
      <SingleDate {...{ classes, isOutsideRange, date, onDateChange, datePickerProps, showControls, changeInterval }} />
    )
  }

  return <DateRange {...{ classes, datePickerProps, customArrowIcon }} />
}

DatePicker.propTypes = {
  overrride: PropTypes.shape({
    datePicker: PropTypes.object
  }),
  displayFormat: PropTypes.string,
  isSingleDate: PropTypes.bool,
  controls: PropTypes.bool,
  changeInterval: PropTypes.oneOf(['days', 'weeks', 'months'])
}

DatePicker.defaultProps = {
  override: {},
  displayFormat: 'DD/MM/YY',
  isSingleDate: false,
  showControls: false,
  changeInterval: 'days'
}

export default withStyles(jss)(DatePicker)
