const styles = {
  'loading-page': {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    extend: ({ override }) => ({
      ...override['loading-page']
    }),
    '& > svg': {
      width: '15rem',
      height: 'auto'
    }
  }
}

export default styles
