import React from 'react'
import { Legends } from 'components/common'

const Key = ({ className, legends }) => {
  return (
    <div className={className}>
      {Object.keys(legends).map((legendKey, index) => {
        const legend = legends[legendKey]
        const { currentColor, oldColor } = legend
        const colors = [currentColor, oldColor]
        return <Legends colors={colors} title={legendKey} key={index} />
      })}
    </div>
  )
}

export default Key
