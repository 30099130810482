import React, { useState, Fragment, Children } from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import Card from '../Card/Card'
import jss from './Accordion.styles'
import { useAccordionContext } from './AccordionContext'
import RenderChildByType from 'utils/RenderChildByType'
import ItemHeader from './Accordion.ItemHeader'
import ItemPanel from './Accordion.ItemPanel'

const Item = ({ children, onClose, onOpen, id, ...rest }) => {
  const [itemIndex] = useState(() => id || uniqueId('AccordionItem-'))
  const { openItem, classes, setOpenItem } = useAccordionContext()

  const isOpen = itemIndex === openItem

  const toggleOpen = () => {
    if (isOpen) {
      setOpenItem(undefined)
      onClose()
    } else {
      setOpenItem(itemIndex)
      onOpen()
    }
  }

  if (Children.count(children) > 0) {
    return (
      <div className={classes['accordion-item']} {...rest}>
        <Card override={jss}>
          <Fragment>
            <RenderChildByType toggleOpen={toggleOpen} isOpen={isOpen} childType={ItemHeader}>
              {children}
            </RenderChildByType>
            <RenderChildByType toggleOpen={toggleOpen} isOpen={isOpen} childType={ItemPanel}>
              {children}
            </RenderChildByType>
          </Fragment>
        </Card>
      </div>
    )
  }
  return null
}

Item.defaultProps = {
  onClose: () => {},
  onOpen: () => {}
}

Item.propTypes = {
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  id: PropTypes.string
}

export default Item
