import React from 'react'
import withStyles from 'react-jss'
import styles from './StoreForm.styles'
import Container from './StoreFormContainer'
import Button from 'components/Button/Button'
import Message from 'components/Message/Message'
import FormikInput from 'components/FormikWrappers/FormikInput'
const StoreForm = props => {
  const { handleSubmit, classes, errors } = props
  const { serverValidation } = errors
  return (
    <form onSubmit={handleSubmit} name="StoreForm" className={classes['form']}>
      <FormikInput name="preferredName" maxLength="50" />
      <div className={classes['submitButton']}>
        <Button variant="link" type="submit">
          Save
        </Button>
      </div>
      {serverValidation && (
        <div className={classes['errorMessage']}>
          <Message showIcon={false} variant="error" text={serverValidation} />
        </div>
      )}
    </form>
  )
}

export default withStyles(styles)(Container(StoreForm))
