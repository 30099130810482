import React from 'react'
import styles from './TransactionDetails.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import moment from 'moment'
import { UI_DATE_FORMAT_FULL, UI_TIME_SECONDS_FORMAT } from '../../../constants/general.constants'
import { TransactionTypes } from '../../TransactionFilter/TransactionTypeSelector/TransactionTypeSelector'
import { getCurrency } from '../../../utils/number.utils'

const Detail = ({ title, value, classes }) => {
  return (
    <div className={classes['detail']}>
      <div className={classes['title']}>
        <Text variant="h5" color={colors.greyDark}>
          {title}{' '}
        </Text>
      </div>
      <div className={classes['value']} data-hj-suppress>
        <Text variant="body" color={colors.greyDark}>
          {value}{' '}
        </Text>
      </div>
    </div>
  )
}

const TransactionDetail = ({ selectedTransaction, deselectTransaction, classes }) => {
  const {
    terminalId,
    transactionTime,
    settlementDate,
    suffix,
    transactionNumber,
    cardLogo,
    tranType,
    cashoutAmount,
    status,
    purchaseAmount,
    transactionAmount,
    position
  } = selectedTransaction

  const parsedTansactionTime = moment(transactionTime)
  const formattedTransactionTime = `${parsedTansactionTime.format(
    UI_DATE_FORMAT_FULL
  )} at ${parsedTansactionTime.format(UI_TIME_SECONDS_FORMAT)}`

  const parsedSettlementDate = moment(settlementDate)
  const formattedSettlementDate = parsedSettlementDate.format(UI_DATE_FORMAT_FULL)

  const formattedTransactionType = TransactionTypes.filter(type => {
    return type.value === tranType
  })[0] || { label: '' }

  const details = [
    {
      title: 'Terminal ID',
      value: terminalId
    },
    {
      title: 'Transaction Date',
      value: formattedTransactionTime
    },
    {
      title: 'Settlement Date',
      value: formattedSettlementDate
    },
    {
      title: 'Last 4 Digits',
      value: suffix
    },
    {
      title: 'Txn #',
      value: transactionNumber
    },
    {
      title: 'Card Type',
      value: cardLogo
    },
    {
      title: 'Type',
      value: purchaseAmount > 0 && cashoutAmount > 0 ? 'P&C' : formattedTransactionType.label
    },
    {
      title: 'Txn Amount',
      value: getCurrency(transactionAmount)
    },
    {
      title: 'Purchase',
      value: getCurrency(purchaseAmount)
    },
    {
      title: 'Cash',
      value: getCurrency(cashoutAmount)
    },
    {
      title: 'Status',
      value: status
    }
  ]

  return (
    <div className={classes['transactionDetail']} style={{ top: position }}>
      <div className={classes['closeIcon']} onClick={deselectTransaction}>
        <Icon name="clear" width={'2em'} height={'2em'} color={colors.icon} />
      </div>
      <Text variant="h4" color={colors.greyDark}>
        Transaction Details
      </Text>
      <div className={classes['details']}>
        {details.map(({ title, value }) => {
          return <Detail {...{ title, value, classes }} key={title} />
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(TransactionDetail)
