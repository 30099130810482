import React, { useState } from 'react'
import withStyles from 'react-jss'
import styles from './FilterSection.styles'
import SearchStore from 'components/SearchStore/SearchStore'
import ToggleGroup from 'components/ToggleGroup/ToggleGroup'
import DatePicker from 'components/DatePicker/DatePicker'
import ComparePreviousPeriodButton from '../ComparePreviousPeriodButton/ComparePreviousPeriodButton'
import CompareStoreButton from '../CompareStoreButton/CompareStoreButton'
import withContainer, { modes } from './FilterSectionContainer'
import { isDateOutsideRange } from 'utils/filters.utils'
import { UI_DATE_FORMAT } from 'constants/general.constants'

const toggleGroupStyles = {
  toggleGroup: {
    display: 'flex',
    flex: '1 0 auto'
  },
  toggleButton: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  }
}

const FilterSection = ({
  classes,
  isStoreSelectorActive,
  isLoading,
  datePickerDate,
  setDate,
  setRange,
  range,
  compareStore,
  searchStoreProps,
  comparePeriod,
  onSetComparePeriod,
  isComparePeriod,
  onSetCompareStore,
  isCompareStore,
  changeInterval
}) => {
  const [focused, setFocused] = useState(null)

  return (
    <div className={classes['container']}>
      {!isStoreSelectorActive ? (
        <>
          {compareStore && (
            <div className={classes['searchStore']}>
              <SearchStore {...{ ...searchStoreProps }} />
            </div>
          )}
          <div className={classes['datePicker']}>
            <DatePicker
              disabled={isLoading}
              isSingleDate={true}
              showControls={true}
              date={datePickerDate}
              onDateChange={setDate}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              id="storedatePicker"
              block={true}
              isOutsideRange={isDateOutsideRange}
              displayFormat={UI_DATE_FORMAT}
              changeInterval={changeInterval}
            />
          </div>
          <div className={classes['dateRange']}>
            <ToggleGroup
              {...{
                modes,
                onSelect: setRange,
                active: range,
                disabled: isLoading,
                override: toggleGroupStyles
              }}
            />
          </div>
          <div className={classes['compareSection']}>
            {comparePeriod && (
              <div className={classes['compareToPreviousPeriod']}>
                <ComparePreviousPeriodButton
                  {...{ onChange: onSetComparePeriod, isSelected: isComparePeriod, range, isLoading }}
                />
              </div>
            )}
            {compareStore && (
              <div className={classes['compareToAnotherStore']}>
                <CompareStoreButton
                  {...{ onChange: onSetCompareStore, isSelected: isCompareStore, range, isLoading }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={classes['searchStoreActive']}>
          <SearchStore {...{ ...searchStoreProps }} />
        </div>
      )}
    </div>
  )
}

export default withContainer(withStyles(styles)(FilterSection))
