import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import Logo from '../Logo/Logo'
import jss from './LoadingPage.styles'

/**
 * Loading page. Shows the Paymark Icon by default.
 */
const LoadingPage = ({ classes, logo }) => {
  return <div className={classes['loading-page']}>{logo}</div>
}

LoadingPage.propTypes = {
  /** Icon to be shown on the loader. Defaults to Paymark icon.*/
  logo: PropTypes.any,
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    'loading-page': PropTypes.object
  })
}

LoadingPage.defaultProps = {
  logo: <Logo name="paymark" />,
  override: {}
}

export default withStyles(jss)(LoadingPage)
