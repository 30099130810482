import * as mediaQueryHelper from 'config/mediaQueryHelper'

const styles = {
  actions: {
    margin: '2.5rem 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [mediaQueryHelper.TABLET_DOWN()]: {
      flexDirection: 'column'
    }
  },
  actionItem1: {
    marginRight: '1rem',
    justifyContent: 'flex-end',
    display: 'flex',
    [mediaQueryHelper.TABLET_DOWN()]: {
      marginRight: 0,
      marginBottom: '1rem',
      flexBasis: 'auto',
      justifyContent: 'center'
    }
  },
  actionItem2: {
    justifyContent: 'flex-end',
    display: 'flex',
    [mediaQueryHelper.TABLET_DOWN()]: {
      justifyContent: 'center'
    }
  },
  message: {
    margin: '1rem 0',
    textAlign: 'center'
  }
}

export default styles
