import * as mediaQueryHelper from 'config/mediaQueryHelper'

const { MOBILE_UP } = mediaQueryHelper

const styles = {
  modalContent: {
    '& > div': {
      marginTop: '1rem'
    }
  },
  header: {
    display: 'flex',

    paddingLeft: '0.5rem'
  },
  helpIcon: {
    margin: ['-0.5em', '0.75em', 0, 0]
  },
  transctionTypes: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  type: {
    [MOBILE_UP()]: {
      flex: '0 50%'
    },
    padding: '0.5rem'
  }
}

export default styles
