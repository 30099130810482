import _ from 'lodash'
import { getInsightsRole } from 'utils/user.utils'

const getMerchantDictionary = (merchants = []) => {
  return _.reduce(
    merchants,
    (obj, param) => {
      const { cardAcceptorIdCode, displayName } = param
      obj[cardAcceptorIdCode] = { displayName }
      return obj
    },
    {}
  )
}

export const getUsersWithStoreInfo = (users = [], merchants = []) => {
  const merchantDictionary = getMerchantDictionary(merchants)

  users = users.map(user => {
    const { email, firstName, lastName, roles, state, id } = user
    const role = getInsightsRole(roles, 'cardAcceptorIdCode')

    let stores = roles.map(role => {
      const { cardAcceptorIdCode } = role
      if (cardAcceptorIdCode && merchantDictionary[cardAcceptorIdCode]) {
        return {
          cardAcceptorIdCode,
          displayName: merchantDictionary[cardAcceptorIdCode].displayName
        }
      }
      return null
    })

    const casedFirstName = _.startCase(_.toLower(firstName))
    const casedLastName = _.startCase(_.toLower(lastName))

    return {
      email,
      name: `${casedFirstName} ${casedLastName}`,
      firstName: casedFirstName,
      lastName: casedLastName,
      role,
      roles,
      state,
      stores,
      id
    }
  })

  return _.orderBy(users, ['name'], ['asc'])
}
