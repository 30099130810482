import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP, LAPTOP_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  change: {
    display: 'flex',
    '& >div:nth-child(1)': {
      lineHeight: '1.6875rem' // needed for alignment of percentages on graphs for some reason
    },
    '& >div:nth-child(2)': {
      fontSize: '1rem',
      marginLeft: '0.1rem',
      marginBottom: 'auto',
      marginTop: 'auto',
      paddingBottom: '0.3125rem'
    }
  },
  iconClass: {
    padding: '0.5rem 0',
    fontSize: '0.8em',
    [MOBILE_UP()]: {
      padding: '1rem 0',
      fontSize: '0.85em'
    },
    [LAPTOP_UP()]: {
      padding: '0.9rem 0',
      fontSize: '0.9em'
    },
    [LARGE_SCREEN_UP()]: {
      padding: '0.55rem 0',
      fontSize: '1.15em'
    }
  }
}

export default styles
