import { GRAPH_REVENUE_COLOR, GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'

const styles = {
  toolTip: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    border: '1px solid rgb(202, 200, 207)',
    boxShadow: '0px 1px 2px 0px rgb(168, 165, 180)',
    padding: '0.75rem',
    opacity: 0.9,
    minWidth: '130px',
    textAlign: 'center'
  },
  currentData: {
    flex: [1, 1, 'auto'],
    color: ({ primaryColor }) => primaryColor || GRAPH_REVENUE_COLOR,
    fontSize: '1rem',
    fontWeight: 500,
    paddingBottom: ({ isCompare }) => (isCompare ? '0.5rem' : 0)
  },
  comparedData: {
    paddingTop: '0.5rem',
    fontSize: '0.85rem',
    flex: [1, 1, 'auto'],
    color: GRAPH_PREVIOUS_PERIOD_COLOR,
    borderTop: `1px solid ${GRAPH_PREVIOUS_PERIOD_COLOR}`
  },
  value: {
    fontSize: '1em',
    paddingBottom: '0.25rem'
  },
  date: {
    fontSize: '0.85em'
  }
}

export default styles
