import React from 'react'
import withStyles from 'react-jss'
import styles from './ChartToolTip.styles'
import PropTypes from 'prop-types'
import _ from 'lodash'

const defaultFormatter = value => value

const ChartToolTip = ({
  active,
  payload,
  classes,
  isCompare,
  valueFormatter = defaultFormatter,
  dateFormatter = defaultFormatter,
  dataKey
}) => {
  if (active && !_.isEmpty(payload)) {
    const data = payload[0].payload
    const { currentSales, oldSales, currentRevenue, oldRevenue, oldDateTime, currentDateTime } = data
    const isRevenue = dataKey === 'revenue'
    const current = isRevenue ? currentRevenue : currentSales
    const old = isRevenue ? oldRevenue : oldSales
    return (
      <div className={classes['toolTip']}>
        {currentDateTime && (
          <div className={classes['currentData']}>
            <div className={classes['value']}>{valueFormatter(current)}</div>
            <div className={classes['date']}>{dateFormatter(currentDateTime)}</div>
          </div>
        )}
        {oldDateTime && isCompare && (
          <div className={classes['comparedData']}>
            <div className={classes['value']}>{valueFormatter(old)}</div>
            <div className={classes['date']}>{dateFormatter(oldDateTime)}</div>
          </div>
        )}
      </div>
    )
  }

  return null
}

export default withStyles(styles)(ChartToolTip)

ChartToolTip.prototype = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  isCompare: PropTypes.bool,
  valueFormatter: PropTypes.func,
  dateFormatter: PropTypes.func
}
