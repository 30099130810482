import React from 'react'
import Divider from 'components/Divider/Divider'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './AccountCreated.styles'

const AccountCreated = params => {
  const { goToLogin, classes } = params

  return (
    <div>
      <div className={classes['title']}>
        <Text className="register" variant="h1">
          Account Created
        </Text>
        <Divider />
      </div>

      <div className={classes['body']}>
        <Icon name="check-circle" color={colors.success} width={'3.5em'} />
        <Text variant="h5">Your account has been created</Text>
        <p>
          <Text variant="note">You can now login with your email and password.</Text>
        </p>
      </div>

      <div className={classes['footerItem']}>
        <Button variant="primary" onClick={goToLogin}>
          GO TO LOGIN
        </Button>
      </div>
      <div className={classes['footerItem']}>
        <Divider />
      </div>
    </div>
  )
}

AccountCreated.propTypes = {
  goToLogin: PropTypes.func
}

export default withStyles(styles)(AccountCreated)
