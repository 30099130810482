import React from 'react'
import * as variables from 'config/jss-vars'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'
import { TERMS_CONDITIONS_LINK, PRIVACY_POLICY_LINK } from 'constants/general.constants'

const LinkTc = ({ isError }) => {
  const linkOverride = { link: { fontWeight: variables.weightRegular } }

  return (
    <Text variant="label" isError={isError}>
      I agree to the{' '}
      <a href={TERMS_CONDITIONS_LINK} target="_blank" rel="noopener noreferrer">
        <Button override={linkOverride} variant="link">
          Terms &amp; Conditions
        </Button>
      </a>{' '}
      and{' '}
      <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
        <Button override={linkOverride} variant="link">
          Privacy Policy
        </Button>
      </a>
      .
    </Text>
  )
}

export default LinkTc
