import React from 'react'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import PropTypes from 'prop-types'
import { CardLogo } from 'components/common'
import { getCurrency } from 'utils/number.utils'

const CardDetailsPopoverContent = ({ data, classes, onClose }) => {
  if (data === null) {
    return null
  }
  const { name, value, totalTransactionAmount } = data

  return (
    <div className={classes['body']}>
      <div className={classes['closeIcon']} onClick={onClose}>
        <Icon name="clear" width={'2em'} height={'2em'} color={colors.icon} />
      </div>
      <div className={classes['card']} data-hj-suppress>
        <CardLogo name={name} />
      </div>
      <div className={classes['details']}>
        <div className={classes['count']}>
          <div className={classes['label']}>
            <Text variant="label" color={colors.greyDark}>
              Count
            </Text>
          </div>
          <div className={classes['value']} data-hj-suppress>
            <Text variant="h2" color={colors.greyDark}>
              {value}
            </Text>
          </div>
        </div>
        <div className={classes['amount']}>
          <div className={classes['label']} data-hj-suppress>
            <Text variant="label" color={colors.greyDark}>
              Total
            </Text>
          </div>
          <div className={classes['value']}>
            <Text variant="h2" color={colors.greyDark}>
              {getCurrency(totalTransactionAmount)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

CardDetailsPopoverContent.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CardDetailsPopoverContent
