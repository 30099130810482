import React from 'react'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import NoTransactionsMessage from 'components/BusinessPerformance/CardsUsed/NoTransactionsMessage'
import Text from 'components/Text/Text'
import { GRAPH_CURRENT_PERIOD_COLOR } from 'constants/colors.contants'
import Icon from 'components/Icon/Icon'
import CustomersLabel from './CustomersLabel'
import SalesLabel from './SalesLabel'
import CustomerCardLoader from 'components/BusinessPerformance/CustomersCard/CustomerCardLoader'

const CustomersBarchart = ({ data, barSize, color }) => {
  return (
    <HorizontalBarChart
      {...{
        barSize,
        data,
        isLoading: false,
        color
      }}
    />
  )
}

const getPercentages = data => {
  if (!data) {
    return {
      repeatCustomerPercentage: 0,
      newCustomerPercentage: 0
    }
  }

  const { repeatCustomerPercentage, newCustomerPercentage } = data

  return {
    repeatCustomerPercentage,
    newCustomerPercentage
  }
}

const StoreCustomerChart = ({ classes, isComparePeriod, data, isLoading }) => {
  const { isNoTransactions, isCurrentGraphEmpty, current, isPreviousGraphEmpty, previous } = data

  const currentGraphData = getPercentages(current)
  const previousGraphData = getPercentages(previous)
  const infoClassName = isComparePeriod ? classes['infoComparePeriod'] : classes['info']

  if (isLoading) {
    return <CustomerCardLoader />
  }

  return (
    <div className={classes['graphWrapper']}>
      {!isNoTransactions ? (
        <>
          <div className={classes['currentDataGraph']} data-hj-suppress>
            <div className={classes['customChart']}>
              <div className={'icon'}>
                <Icon name="return" />
              </div>
              <div className={'graph'}>
                {!isCurrentGraphEmpty ? (
                  <CustomersBarchart
                    {...{
                      classes,
                      data: currentGraphData,
                      barSize: 40,
                      color: GRAPH_CURRENT_PERIOD_COLOR
                    }}
                  />
                ) : (
                  <Text variant="h5" color={GRAPH_CURRENT_PERIOD_COLOR}>
                    There are no transactions for the selected time period.
                  </Text>
                )}
              </div>
              <div className={'icon'}>
                <Icon name="plus" />
              </div>
            </div>
          </div>
          {isComparePeriod && (
            <div className={classes['previousDataGraph']} data-hj-suppress>
              <div className={classes['customChart']}>
                <div className={'graph'}>
                  {!isPreviousGraphEmpty ? (
                    <CustomersBarchart
                      {...{
                        classes,
                        data: previousGraphData,
                        barSize: 15,
                        color: GRAPH_PREVIOUS_PERIOD_COLOR
                      }}
                    />
                  ) : (
                    <Text variant="h5" color={GRAPH_PREVIOUS_PERIOD_COLOR}>
                      There are no transactions for the previous time period.
                    </Text>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className={infoClassName}>
            <div className={classes['percentage']}>
              <div className={'return'}>
                <CustomersLabel
                  {...{
                    label: 'Return Customers',
                    classes,
                    iconName: 'return',
                    customersCurrentPercentage: current && current.repeatCustomerPercentage / 100,
                    currentPreviousPercentage: previous && previous.repeatCustomerPercentage / 100,
                    isCompare: isComparePeriod
                  }}
                />
              </div>
              <div className={'new'}>
                <CustomersLabel
                  {...{
                    label: 'New Customers',
                    classes,
                    iconName: 'plus',
                    customersCurrentPercentage: current && current.newCustomerPercentage / 100,
                    currentPreviousPercentage: previous && previous.newCustomerPercentage / 100,
                    isCompare: isComparePeriod
                  }}
                />
              </div>
            </div>
            <div className={classes['sales']}>
              <div className={'return'}>
                <SalesLabel
                  {...{
                    classes,
                    label: 'Average Sales',
                    currentAverageSale: current && current.repeatCustomerAverage,
                    previousAverageSale: previous && previous.repeatCustomerAverage,
                    isCompare: isComparePeriod
                  }}
                />
              </div>
              <div className={'new'}>
                <SalesLabel
                  {...{
                    classes,
                    label: 'Average Sales',
                    currentAverageSale: current && current.newCustomerAverage,
                    previousAverageSale: previous && previous.newCustomerAverage,
                    isCompare: isComparePeriod
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoTransactionsMessage {...{ classes }} />
      )}
    </div>
  )
}

export default React.memo(StoreCustomerChart)
