import { useRef, useState } from 'react'
import { doFuzzySearch } from 'utils/filters.utils'
import _ from 'lodash'

const useStoreSelector = ({ availableStores, handleStoreSelection }) => {
  const previousState = useRef([])
  const [isAddAll, setAddAll] = useState(false)
  const [isAddSearched, setAddSearched] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedStores, setSelectedStores] = useState([])
  const [isGlobalViewConfirmation, setGlobalViewConfirmation] = useState(false)

  const checkForGlobalViewer = (availableStores, newSelectedStores) => {
    //Show GLOBAL VIEWER if all stores are selected
    if (availableStores.length === newSelectedStores.length) {
      setGlobalViewConfirmation(true)
    }
  }

  const onSelectStores = stores => {
    setSelectedStores(stores)
    handleStoreSelection(stores)
  }

  const closeConfirmationModal = () => {
    setGlobalViewConfirmation(false)
  }

  const onSearchTextChange = e => {
    const { value } = e.target
    setSearchText(value)
    setAddSearched(false)
  }

  const onSelectStore = store => {
    const newSelectedStores = [...selectedStores, store]
    onSelectStores(newSelectedStores)

    checkForGlobalViewer(availableStores, newSelectedStores)
  }

  const onSelectAll = () => {
    if (!isAddAll) {
      previousState.current = [...selectedStores]
      setAddAll(true)
      const newSelectedStores = [...selectedStores, ...unSelectedStores]
      onSelectStores(newSelectedStores)
      setSearchText('')

      checkForGlobalViewer(availableStores, newSelectedStores)
    } else {
      onSelectStores(previousState.current)
      setAddAll(false)
    }
  }

  const onSelectAllFiltered = () => {
    if (!isAddSearched) {
      previousState.current = [...selectedStores]
      const newSelectedStores = [...selectedStores, ...filteredStores]
      onSelectStores(newSelectedStores)
      setAddSearched(true)

      checkForGlobalViewer(availableStores, newSelectedStores)
    } else {
      setAddSearched(false)
      onSelectStores(previousState.current)
    }
  }

  const deSelectStore = store => {
    const filteredStores = _.differenceBy(selectedStores, [store], 'cardAcceptorIdCode')
    onSelectStores(filteredStores)
    setAddAll(false)
    setAddSearched(false)
  }

  const unSelectedStores = _.differenceBy(availableStores, selectedStores, 'cardAcceptorIdCode')
  const filteredStores = doFuzzySearch(unSelectedStores, searchText, { keys: ['displayName'] })

  return {
    onSearchTextChange,
    onSelectAllFiltered,
    onSelectAll,
    onSelectStore,
    deSelectStore,
    searchText,
    selectedStores,
    unSelectedStores,
    filteredStores,
    isAddSearched,
    isAddAll,
    isGlobalViewConfirmation,
    closeConfirmationModal
  }
}

export default useStoreSelector
