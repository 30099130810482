import { accountSlice } from 'reduxSlices'
import { useSelector } from 'react-redux'
import { getInsightsRole } from 'utils/user.utils'

const useLoggedInUser = () => {
  const user = useSelector(accountSlice.selectors.getUser)
  const merchants = useSelector(accountSlice.selectors.getMerchantAccess)
  const poll = useSelector(accountSlice.selectors.getPoll)

  const role = getInsightsRole(user.roles)
  return { user, merchants, role, poll }
}

export default useLoggedInUser
