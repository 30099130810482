import _ from 'lodash'
import { getInsightsRole } from 'utils/user.utils'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER } from 'constants/general.constants'

// common
const getFirstNameData = data => ({ $first_name: data.firstName })
const getLastNameData = data => ({ $last_name: data.lastName })
const getEmailData = data => ({ $email: data?.email?.toLowerCase() })
const getUserMerchantIdData = data => ({ UserMerchantID: data.merchantId })
const getOrgIdData = data => ({ organisation_id: data.organisationId })
const getCreationTimeData = data => ({ $created: data.creationTime })
const getCreatedDateNow = () => ({ $created: new Date().toISOString() })

// business and store performance common data
const getPerformanceTypeData = data => ({ performanceType: data })
const getDateRangeStartData = data => ({ dateRangeStart: data.transactionTimeFrom })
const getDateRangeEndData = data => ({ dateRangeEnd: data.transactionTimeTo })
const getRangeTypeData = data => ({ rangeType: data.range })
const getComparePreviousPeriodData = data => ({ comparePreviousPeriod: data.isCompare })
const getCompareAnotherStoreData = data => ({ compareAnotherStore: data.isCompareStore })
const getComparisonMerchantIDData = data => ({ ComparisonMerchantID: data.comparedMerchantId })

const getRoleData = data => {
  const roles = data.roles
  if (_.isEmpty(roles)) return null
  return { role: getInsightsRole(roles) }
}

const userWithMerchantId = data => ({
  ...getFirstNameData(data),
  ...getLastNameData(data),
  ...getEmailData(data),
  ...getUserMerchantIdData(data)
})

const getSignupAttemptData = data => {
  if (_.isEmpty(data)) return null
  return userWithMerchantId(data)
}

const getVerifyData = data => {
  if (_.isEmpty(data)) return null
  return {
    ...getEmailData(data),
    ...getCreatedDateNow()
  }
}

const getVerifyErrorData = (data, error) => {
  if (_.isEmpty(data)) return null
  return {
    ...getEmailData(data),
    VerificationError: error
  }
}

const getSignupAttemptFailureData = (data, error) => {
  if (_.isEmpty(data)) return null
  const userData = userWithMerchantId(data)
  return {
    ...userData,
    RegistrationError: error
  }
}

const getLoginData = data => {
  if (_.isEmpty(data)) return null
  return {
    ...getEmailData(data),
    ...getOrgIdData(data),
    ...getCreationTimeData(data)
  }
}

const getMixpanelPeopleSetData = data => {
  if (_.isEmpty(data)) return null
  return {
    ...getFirstNameData(data),
    ...getLastNameData(data),
    ...getEmailData(data),
    ...getOrgIdData(data),
    ...getRoleData(data)
  }
}

export const getSwitchAppData = data => {
  return {
    PageUrl: data.path,
    switch: data.url
  }
}

const getMixpanelRegisterData = data => {
  if (_.isEmpty(data)) return null
  return {
    ...getOrgIdData(data)
  }
}

const getCommonPerformanceData = data => {
  const { current } = data
  return {
    ...getDateRangeStartData(current),
    ...getDateRangeEndData(current),
    ...getRangeTypeData(data),
    ...getComparePreviousPeriodData(data)
  }
}

const getBusinessDashboardQueryData = data => {
  if (_.isEmpty(data) || _.isEmpty(data.current)) return null
  // business won't have a current store
  // and another store to compare to
  return {
    ...getPerformanceTypeData('Business'),
    ...getCommonPerformanceData(data)
  }
}

const getStoreDashboardQueryData = data => {
  if (_.isEmpty(data) || _.isEmpty(data.current)) return null
  return {
    ...getPerformanceTypeData('Store'),
    ...getCommonPerformanceData(data),
    ...getUserMerchantIdData(data),
    ...getCompareAnotherStoreData(data),
    ...getComparisonMerchantIDData(data)
  }
}

const getTutorialData = data => {
  return {
    onboardingScreen: data
  }
}

const getViewedPageData = data => {
  return {
    PageUrl: data
  }
}

const getSwitchStoreViewData = data => {
  if (_.isEmpty(data)) return null
  return {
    CurrentUserMerchantID: data.CurrentUserMerchantID,
    NewUserMerchantID: data.NewUserMerchantID
  }
}

const getInviteUserData = data => {
  let role = getInsightsRole(data.roles, 'cardAcceptorIdCode')

  return {
    userPermissions: role,
    storesPermissions: role === ROLE_OWNER || role === ROLE_GLOBAL_VIEWER ? 0 : data.roles.length
  }
}

const getInviteUserFailureData = (data, error) => {
  return {
    ...getEmailData(data),
    InviteError: error
  }
}

const getSettlementSearchData = data => {
  return {
    SettlementView: data.summaryType,
    UserMerchantId: data.store.cardAcceptorIdCode,
    settlementDateFrom: data.startDate.format('YYYY-MM-DD'),
    settlementDateTo: data.endDate.format('YYYY-MM-DD')
  }
}

const getTransactionSearchData = data => {
  return {
    UserMerchantId: data.store.cardAcceptorIdCode,
    terminalId: data.terminalId,
    purchaseAmount: data.purchaseAmount,
    cardLogo: !data.cards || data.cards.length === 0 ? 'ALL' : data.cards.join(','),
    tranType: !data.transactionTypes || data.transactionTypes.length === 0 ? 'ALL' : data.transactionTypes.join(','),
    suffix: data.suffix,
    approved: data.status === -1 ? 'ALL' : data.status
  }
}

const getAddMerchantData = data => {
  if (_.isEmpty(data)) return null
  return {
    SiteAdded: data.cardAcceptorIdCode
  }
}

const getAddMerchantFailureData = (data, error) => {
  if (_.isEmpty(data)) return null
  return {
    SiteAdded: data.cardAcceptorIdCode,
    AddSiteError: error
  }
}

const mixpanelData = {
  signupAttemptData: data => getSignupAttemptData(data),
  signupAttemptFailureData: (data, error) => getSignupAttemptFailureData(data, error),
  loginData: data => getLoginData(data),
  mixpanelPeopleSetData: data => getMixpanelPeopleSetData(data),
  switchAppData: data => getSwitchAppData(data),
  mixpanelRegisterData: data => getMixpanelRegisterData(data),
  verifyData: data => getVerifyData(data),
  verifyErrorData: (data, error) => getVerifyErrorData(data, error),
  businessDashboardQueryData: data => getBusinessDashboardQueryData(data),
  storeDashboardQueryData: data => getStoreDashboardQueryData(data),
  onboardingTutorialData: data => getTutorialData(data),
  viewedPageData: data => getViewedPageData(data),
  switchStoreViewData: data => getSwitchStoreViewData(data),
  inviteUserData: data => getInviteUserData(data),
  inviteUserFailureData: (data, error) => getInviteUserFailureData(data, error),
  settlementSearchData: data => getSettlementSearchData(data),
  transactionSearchData: data => getTransactionSearchData(data),
  addMerchantData: data => getAddMerchantData(data),
  addMerchantFailureData: (data, error) => getAddMerchantFailureData(data, error)
}

export default mixpanelData
