import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from './components'
import Logo from './components/Logo/Logo'
import LoadingPage from './components/LoadingPage/LoadingPage'
import Version from './components/Version/Version'
import { isAccessTokenPresent } from './utils/auth.utils'
import Settlement from './pages/Settlement/Settlement'
import Transactions from './pages/Transactions/Transactions'
import { isInsightsOwner } from 'utils/user.utils'
import { accountSlice } from 'reduxSlices'
import { useSelector } from 'react-redux'

const InsightsLogo = () => <Logo name="insights" />

export const DashboardRoute = {
  path: '/',
  component: React.lazy(() => import('./pages/Dashboard/Dashboard'))
}

export const AccountSettingsMyAccountRoute = {
  path: '/settings/myAccount',
  component: React.lazy(() => import('./pages/AccountSettings'))
}
export const AccountSettingsUsersRoute = {
  path: '/settings/users',
  component: React.lazy(() => import('./pages/AccountSettings'))
}
export const AccountSettingsStoresRoute = {
  path: '/settings/stores',
  component: React.lazy(() => import('./pages/AccountSettings'))
}

export const AddStoreRoute = {
  path: '/settings/stores/add',
  component: React.lazy(() => import('./pages/AddNewStore/AddNewStore'))
}

export const InviteUserRoute = {
  path: '/settings/users/invite',
  component: React.lazy(() => import('./pages/InviteUser/InviteUser'))
}

export const ContactUsRoute = {
  path: '/contact',
  component: React.lazy(() => import('./pages/ContactUs/ContactUs'))
}

export const TransactionRoute = {
  path: '/transaction',
  component: Transactions
}

export const SettlementRoute = {
  path: '/settlement',
  component: Settlement
}

export const SSORegistrationRoute = {
  path: '/ssoregister',
  component: React.lazy(() => import('./pages/SSORegistration/SSORegistration'))
}

export const AccountRoute = {
  name: 'Account',
  path: '/account'
}

export const VerifyAccountRoute = {
  path: '/portal/verify',
  component: React.lazy(() => import('./pages/VerifyAccount/VerifyAccount'))
}

export const VerifyInviteRoute = {
  path: '/portal/invite/verify',
  component: React.lazy(() => import('./pages/VerifyInvite/VerifyInvite'))
}

export const PrivateNotFoundRoute = {
  component: React.lazy(() => import('./pages/NotFound/PrivateNotFound'))
}

export const PublicNotFoundRoute = {
  component: React.lazy(() => import('./pages/NotFound/PublicNotFound'))
}

export const ErrorPageRoute = {
  path: '/error',
  component: React.lazy(() => import('./pages/ErrorPage/PrivateErrorPage'))
}

export const UnsupportedBrowser = {
  path: '/unsupported-browser',
  component: React.lazy(() => import('./pages/UnsupportedBrowser/UnsupportedBrowser'))
}

const LazyComponent = Component => {
  return props => {
    return (
      <Suspense fallback={<LoadingPage logo={<InsightsLogo />} />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
const isLoggedIn = isAccessTokenPresent()

const Routes = () => {
  const { selectors: accountSelectors } = accountSlice
  const user = useSelector(accountSelectors.getUser)
  const roles = user ? user.roles : []
  const isOwner = isInsightsOwner(roles)

  return (
    <Switch>
      {/* Private Routes */}

      {/* Settings */}

      <PrivateRoute
        exact
        path={AccountSettingsMyAccountRoute.path}
        component={LazyComponent(AccountSettingsMyAccountRoute.component)}
      />
      <PrivateRoute
        exact
        path={AccountSettingsUsersRoute.path}
        component={LazyComponent(AccountSettingsUsersRoute.component)}
      />
      <PrivateRoute
        exact
        path={AccountSettingsStoresRoute.path}
        component={LazyComponent(AccountSettingsStoresRoute.component)}
      />
      {isOwner && (
        <PrivateRoute
          exact
          path={InviteUserRoute.path}
          showBack={true}
          component={LazyComponent(InviteUserRoute.component)}
        />
      )}
      {isOwner && (
        <PrivateRoute
          exact
          path={AddStoreRoute.path}
          showBack={true}
          component={LazyComponent(AddStoreRoute.component)}
        />
      )}

      <PrivateRoute exact path={DashboardRoute.path} component={LazyComponent(DashboardRoute.component)} />
      <PrivateRoute exact path={ContactUsRoute.path} component={LazyComponent(ContactUsRoute.component)} />
      <PrivateRoute exact path={TransactionRoute.path} component={TransactionRoute.component} />
      <PrivateRoute path={SettlementRoute.path} component={SettlementRoute.component} />

      <PrivateRoute exact path={SSORegistrationRoute.path} component={LazyComponent(SSORegistrationRoute.component)} />

      <PrivateRoute path={UnsupportedBrowser.path} component={LazyComponent(UnsupportedBrowser.component)} />

      {/* Public Routes */}
      <PublicRoute exact path={VerifyAccountRoute.path} component={LazyComponent(VerifyAccountRoute.component)} />
      <PublicRoute exact path={VerifyInviteRoute.path} component={LazyComponent(VerifyInviteRoute.component)} />

      <PrivateRoute exact path={ErrorPageRoute.path} component={LazyComponent(ErrorPageRoute.component)} />

      <Route exact path={'/portal/version'} component={() => <Version />} />

      {/* Default Route */}
      {isLoggedIn && <PrivateRoute component={LazyComponent(PrivateNotFoundRoute.component)} />}
      {!isLoggedIn && <PublicRoute component={LazyComponent(PublicNotFoundRoute.component)} />}
    </Switch>
  )
}

export default Routes
