import React, { Component } from 'react'
import withStyles from 'react-jss'
import styles from './DateSection.styles'
import Icon from 'components/Icon/Icon'
import Message from 'components/Message/Message'
import Select from 'components/Select/Select'
import * as colors from 'config/colors'
import SettlementDateFilter from './SettlementDateFilter'
import PropTypes from 'prop-types'

export const OPTIONS = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'thisWeek', label: 'This week' },
  { value: 'custom', label: 'Custom' }
]
class DateSection extends Component {
  state = {}

  render() {
    const {
      classes,
      dateRange,
      handleDateRangeChange,
      handleDateChange,
      endDate,
      startDate,
      dateError,
      highlight,
      options
    } = this.props

    const value = options.filter(({ value }) => {
      if (value === dateRange) {
        return true
      }
      return false
    })[0]

    return (
      <div>
        <div className={classes['selectors']}>
          <div className={classes['dateRange']}>
            <Select
              options={options}
              placeholder={'Select an option'}
              onChange={handleDateRangeChange}
              value={value}
              icon={
                <Icon
                  name="calendar"
                  className={classes['calendar']}
                  width={'1em'}
                  height={'1em'}
                  color={colors.icon}
                />
              }
            />
          </div>
          <div className={classes['settlementFilter']}>
            <SettlementDateFilter {...{ endDate, startDate, handleDateChange, classes, highlight }} />
          </div>
        </div>
        {dateError && (
          <div className={classes['errorMessage']}>
            <Message variant="error" showIcon={false} text={dateError} />{' '}
          </div>
        )}
      </div>
    )
  }
}

DateSection.propTypes = {
  dateRange: PropTypes.string,
  handleDateRangeChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  endDate: PropTypes.any,
  startDate: PropTypes.any,
  dateError: PropTypes.any,
  highlight: PropTypes.bool,
  options: PropTypes.array
}

DateSection.defaultProps = {
  options: OPTIONS
}

export default withStyles(styles)(DateSection)
