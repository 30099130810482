import Color from 'color'
import { createSelector } from 'redux-starter-kit'
import _ from 'lodash'

export const getColorGradient = (base, index, total) => {
  if (index === 0) return base

  const lighten = index / (total * 1.4)
  const cl = Color(base)
    .lighten(lighten)
    .hex()
  return cl
}

export const isDesktop = null === navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)

export const isMobileWidth = () => window.innerWidth < 500
export const isTabletWidth = () => window.innerWidth > 500 && window.innerWidth < 800

export const createCustomSelector = selectorString => {
  return createSelector(
    state => _.get(state, selectorString),
    data => data
  )
}
