import * as colors from 'config/colors'

const styles = {
  exportContainer: {
    width: '200px',
    textAlign: 'left',
    float: 'right',
    '& * .react-select__control': {
      background: colors.background2
    },
    '& * .react-select__menu': {
      background: colors.background2
    }
  }
}

export default styles
