import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { SCREEN_UP, MOBILE_UP } = mediaQueryHelper

const styles = {
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  currentStore: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    '& > div': {
      width: '100%'
    }
  },
  comparedStore: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    borderTop: `1px solid ${colors.line2}`,
    '& > div': {
      width: '100%'
    }
  },
  graphWrapper: {
    width: '100%',
    marginBottom: '1.5rem',
    margin: 'auto',
    [MOBILE_UP()]: {
      width: '95%'
    }
  },
  currentDataGraph: {
    height: '90px',
    marginBottom: '1rem'
  },
  pastDataGraph: {
    composes: '$currentDataGraph',
    marginTop: '-65px',
    marginBottom: '35px',
    height: '58px'
  },
  percentage: {
    display: 'flex',
    borderBottom: `1px solid ${colors.line1}`,
    '& > .return': {
      flex: [1, 1, 'auto'],
      borderRight: `1px solid ${colors.line1}`,
      padding: ['1rem', '0.5rem', '0.5rem', '0.5rem'],
      display: 'flex',
      'justify-content': 'space-between'
    },
    '& > .new': {
      flex: [1, 1, 'auto'],
      padding: ['1rem', '0.5rem', '0.5rem', '0.5rem'],
      display: 'flex',
      'justify-content': 'space-between'
    }
  },
  sales: {
    composes: '$percentage'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      paddingRight: '0.5rem'
    },
    '& >h4': {
      display: 'none',
      [MOBILE_UP()]: {
        display: 'block'
      }
    }
  },
  value: {
    display: 'flex',
    flexDirection: 'column',
    '& > .current': {
      display: 'flex',
      flexDirection: 'row',
      '& > div': {
        padding: [0, '0.25rem']
      }
    },
    '& >.previousPeriod': {
      display: 'flex',
      justifyContent: 'start',
      padding: ['0.25rem', 0, 0, '0.25rem']
    }
  },
  legendsContainer: {
    display: 'flex',
    margin: ['-1.4rem', 'auto', 0],
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: [0, '1rem']
  },
  noTransactionMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '110px',
    padding: ['1rem', 0, '2rem', 0],
    [SCREEN_UP()]: {
      marginRight: '200px'
    }
  },
  currentDataEmptyMessage: {
    textAlign: 'center',
    '& > h5': {
      marginTop: '1.5rem'
    }
  },
  previousDataEmptyMessage: {
    textAlign: 'center',
    '& > h5': {
      marginTop: '2rem'
    }
  }
}

export default styles
