import React from 'react'
import withStyles from 'react-jss'
import styles from './CompareButton.styles'
import Radio from 'components/Radio/Radio'
import * as colors from 'config/colors'

const CompareButton = ({ classes, onChange, isSelected, isDisabled, Label, ...labelProps }) => {
  const themedStyle = {
    root: {
      margin: 0,
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    },
    text: {
      fontSize: '0.75rem',
      color: colors.cyan,
      lineHeight: '0.9rem',
      textAlign: 'center',
      padding: 0
    },
    radio: {
      display: 'none'
    },
    'root--checked': {
      background: colors.active,
      color: colors.teal
    }
  }

  return (
    <div
      className={classes['group']}
      onClick={e => {
        if (isDisabled) {
          return
        }
        e.preventDefault()
        onChange(!isSelected)
      }}
    >
      <Radio
        label={<Label {...{ ...labelProps, classes }} />}
        onChange={() => {}}
        checked={isSelected}
        override={themedStyle}
        disabled={isDisabled}
      />
    </div>
  )
}

export default withStyles(styles)(CompareButton)
