import * as colors from 'config/colors'

const menuItem = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  paddingRight: '1.5rem'
}

const popoverContainerStyles = {
  popoverContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    justifyContent: 'flex-end'
  },
  appsLinksItems: {
    ...menuItem,
    '&:hover': {
      '& * g': {
        fill: ({ preset }) => (preset === 'dark' ? '#EAEAEA' : colors.teal)
      }
    }
  },
  secondaryMenuItems: {
    ...menuItem
  },
  applicationMenu: {
    ...menuItem,
    '&:hover': {
      '& * g': {
        fill: ({ preset }) => (preset === 'dark' ? '#EAEAEA' : colors.teal)
      }
    }
  },
  inviteMenu: {
    ...menuItem
  },
  notificationMenu: {
    ...menuItem
  },
  accountMenu: {
    paddingTop: '4px',
    ...menuItem
  }
}

export const lightPopoverContainerStyles = {
  ...popoverContainerStyles,
  accountMenu: {
    ...popoverContainerStyles.accountMenu,
    '&:hover': {
      '& * path': {
        fill: colors.teal
      }
    }
  },
  secondaryMenuItems: {
    ...popoverContainerStyles.secondaryMenuItems,
    '&:hover': {
      '& * path': {
        fill: colors.teal
      }
    }
  },
  appsLinksItems: {
    ...popoverContainerStyles.appsLinksItems,
    '&:hover': {
      '& * g': {
        fill: colors.teal
      }
    }
  }
}

export const darkPopoverContainerStyles = {
  ...popoverContainerStyles,
  accountMenu: {
    ...popoverContainerStyles.accountMenu,
    '&:hover': {
      '& * path': {
        fill: colors.white
      }
    }
  },
  secondaryMenuItems: {
    ...popoverContainerStyles.secondaryMenuItems,
    '&:hover': {
      '& * path': {
        fill: colors.white
      }
    }
  }
}
