import React from 'react'
import Text from 'components/Text/Text'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import PastDataLabel from './PastDataLabel'
import objstr from 'obj-str'

const PreviousPeriodGraph = ({ classes, isEmpty, data }) => {
  const className = objstr({
    [classes['pastDataGraph']]: true,
    [classes['isPastDataGraphEmpty']]: isEmpty
  })

  return (
    <div className={className}>
      {!isEmpty ? (
        <HorizontalBarChart
          {...{
            barSize: 15,
            color: GRAPH_PREVIOUS_PERIOD_COLOR,
            data,
            labelPosition: 'bottom',
            Label: PastDataLabel
          }}
        />
      ) : (
        <Text variant="h5">There are no transactions for the previous time period.</Text>
      )}
    </div>
  )
}

export default PreviousPeriodGraph
