import React from 'react'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import styles from './SelectedItems.styles'
import withStyles from 'react-jss'
import Avatar from 'components/common/Avatar'
import { getInitials } from 'utils/user.utils'
import _ from 'lodash'

const SelectedItems = ({
  classes,
  list,
  onClick,
  isSelected,
  title,
  displayKey = 'displayName',
  displayAvatar = false
}) => {
  const handleClick = onClick || _.noop

  const CheckboxGroupWithAvatar = ({ item }) => {
    const { name, role } = item
    const initial = getInitials(item)
    return (
      <div className={classes['avatarName']}>
        <div>
          <Avatar title={initial} height={'2rem'} width={'1.6rem'} fontSize={'1rem'} role={role} />
        </div>
        <Text variant="body">{name}</Text>
      </div>
    )
  }

  return (
    <div className={classes['selectedItems']} data-hj-suppress>
      {title && (
        <div className={'title'}>
          <Text variant="h5" color={colors.cyan}>
            {title}
          </Text>
        </div>
      )}

      <div className={classes['list']} data-hj-suppress>
        {list.map((item, index) => {
          const itemName = item[displayKey]
          if (isSelected && onClick !== undefined) {
            return (
              <div className={'store'} key={index}>
                {displayAvatar && (
                  <div className={classes['displayAvatar']}>
                    <Checkbox
                      label={<CheckboxGroupWithAvatar {...{ item: item }} />}
                      checked={true}
                      onClick={() => {
                        handleClick(item)
                      }}
                      onChange={_.noop}
                    ></Checkbox>
                  </div>
                )}
                {!displayAvatar && (
                  <Checkbox
                    label={<Text variant="body">{itemName}</Text>}
                    checked={true}
                    onClick={() => {
                      handleClick(item)
                    }}
                    onChange={_.noop}
                  />
                )}
              </div>
            )
          } else {
            return (
              <div className={classes['item']} onClick={() => handleClick(item)} key={index}>
                {displayAvatar && (
                  <div className={classes['avatarName']}>
                    <div>
                      <Avatar
                        title={getInitials(item)}
                        height={'2rem'}
                        width={'1.6rem'}
                        fontSize={'1rem'}
                        role={item.role}
                      />
                    </div>
                    <Text color={colors.greyDark} variant="body">
                      {itemName}
                    </Text>
                  </div>
                )}
                {!displayAvatar && (
                  <Text color={colors.greyDark} variant="body">
                    {itemName}
                  </Text>
                )}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default withStyles(styles)(SelectedItems)
