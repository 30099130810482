import moment from 'moment'
import queryString from 'query-string'
import { getTransactionType } from '../../constants/transactionTypes.constant'
import { getCurrency } from 'utils/number.utils'
import { getMomentDatesFromRange, isDateRangeValid } from 'utils/filters.utils'
import { SERVER_DATE_FORMAT } from 'constants/general.constants'

export const getData = (item, key) => {
  const value = item[key]
  if (['totalFinAmount', 'totalAdditionalAmt'].includes(key)) {
    return getCurrency(value)
  }
  if (['tranType'].includes(key)) {
    return getTransactionType(value)
  }
  return value
}

export const getSearchString = search => {
  return decodeURIComponent(search)
}

export const getParamsFromUrl = search => {
  const qParams = queryString.parse(search)
  const { code, name, range, type } = qParams
  let { from, to } = qParams

  if (!name) {
    return null
  }

  let params = {
    store: {
      cardAcceptorIdCode: code,
      displayName: name
    },
    dateRange: range,
    summaryType: type
  }

  if (range && !from && !to) {
    const dates = getMomentDatesFromRange(range)
    from = dates.from
    to = dates.to
  }

  if (from && to) {
    const dateParams = {
      startDate: moment(from, SERVER_DATE_FORMAT),
      endDate: moment(to, SERVER_DATE_FORMAT)
    }
    params = { ...params, ...dateParams }
  }

  return params
}

export const constructParams = values => {
  const { store, endDate, startDate, summaryType } = values
  const { cardAcceptorIdCode, displayName } = store
  const settlementDateTo = endDate.format(SERVER_DATE_FORMAT)
  const settlementDateFrom = startDate.format(SERVER_DATE_FORMAT)

  const params = {
    summaryType,
    cardAcceptorIdCodes: cardAcceptorIdCode,
    displayName,
    settlementDateFrom,
    settlementDateTo
  }

  return params
}

export const isFilterValid = values => {
  const { summaryType, store, startDate, endDate } = values || {}

  const isValuesPresent = summaryType && store && startDate && endDate

  if (!isValuesPresent) {
    return false
  }

  return isDateRangeValid(startDate, endDate)
}

export const dispatchDataActions = (actions, values) => {
  const { summaryType } = values

  if (summaryType === 'terminal') {
    actions.fetchTerminalSummary(values)
  } else if (summaryType === 'merchant') {
    actions.fetchMerchantSummary(values)
  }
}

export const fetchSummaryData = (newProps, actions) => {
  const params = getParamsFromUrl(newProps)
  const isValid = isFilterValid(params)
  if (!isValid) {
    actions.resetSummary()
    return
  }
  const actionParams = constructParams(params)

  dispatchDataActions(actions, actionParams)
}

export const getMerchantSummaryChartData = data => {
  const {
    cardTypes,
    totals: { totalCount }
  } = data
  let graphData = { totalCount, cards: [] }

  if (!cardTypes) return []

  cardTypes.forEach(card => {
    const { tranTypes, cardType, totalTransactionAmount } = card
    let totalCount = 0
    tranTypes.forEach(tranType => {
      const { count } = tranType
      totalCount = totalCount + count
    })
    const item = {
      value: totalCount,
      name: cardType,
      totalTransactionAmount
    }
    graphData.cards.push(item)
  })
  return graphData
}

export const getTerminalSummaryChartData = data => {
  const { cardTypes, totalCount } = data
  let graphData = { totalCount, cards: [] }

  if (!cardTypes) return []

  cardTypes.forEach(card => {
    const { tranTypes, cardType, totalTransactionAmount } = card
    let totalCount = 0
    tranTypes.forEach(tranType => {
      const { count } = tranType
      totalCount = totalCount + count
    })
    const item = {
      value: totalCount,
      name: cardType,
      totalTransactionAmount
    }
    graphData.cards.push(item)
  })
  return graphData
}
