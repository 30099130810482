import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { up, BREAK_POINT_LARGE, MOBILE_UP } = mediaQueryHelper

const isDesktop = up(BREAK_POINT_LARGE)

const styles = {
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    [isDesktop]: {
      paddingBottom: '1rem'
    }
  },
  currentStore: {
    flex: [1, 1, 'auto'],
    display: 'flex'
  },
  comparedStore: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    borderTop: `1px solid ${colors.line2}`
  },
  graphWrapper: {
    width: '100%',
    marginBottom: '1.5rem',
    margin: 'auto',
    [MOBILE_UP()]: {
      width: '95%'
    }
  },
  currentDataGraph: {
    marginTop: '-12px',
    height: '90px',
    marginBottom: '1rem',
    textAlign: 'center',
    '& * h5': {
      paddingTop: '1.5rem'
    }
  },
  previousDataGraph: {
    marginTop: '-58px',
    height: '50px',
    textAlign: 'center',
    '& * h5': {
      paddingTop: '1.5rem'
    }
  },
  info: {
    margin: [0, '1rem'],
    marginTop: '-2.5rem'
  },
  infoComparePeriod: {
    composes: '$info',
    marginTop: '-1rem'
  },
  percentage: {
    display: 'flex',
    borderBottom: `1px solid ${colors.line1}`,
    '& > .return': {
      flex: [1, 1, '0%'],
      borderRight: `1px solid ${colors.line1}`,
      padding: ['1rem', '0.5rem', '0.5rem', '0.5rem'],
      display: 'flex',
      'justify-content': 'space-between'
    },
    '& > .new': {
      flex: [1, 1, '0%'],
      padding: ['1rem', '0.5rem', '0.5rem', '0.5rem'],
      display: 'flex',
      'justify-content': 'space-between'
    }
  },
  sales: {
    composes: '$percentage'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    '& >div': {
      paddingRight: '0.5rem',
      paddingBottom: '0.5rem'
    },
    '& >h4': {
      display: 'none',
      [MOBILE_UP()]: {
        display: 'block'
      }
    }
  },
  value: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  currencyValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  changeValue: {
    marginLeft: '0.5rem'
  },
  customChart: {
    width: 'calc(100% - 3rem)',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& >.graph': {
      margin: 'auto',
      width: 'calc(100% - 3rem)',
      height: '100%'
    }
  },
  noTransactionMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '110px',
    padding: ['1rem', 0, '2rem', 0],
    [isDesktop]: {
      marginRight: '200px'
    }
  }
}

export default styles
