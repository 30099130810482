import * as colors from 'config/colors'

const styles = {
  text: {
    flex: [1, 1, 'auto'],
    fontSize: '1.2rem',
    padding: ['0.25rem', 0, 0, '0.25rem']
  },
  wrapper: {
    display: 'flex',
    width: '125px'
  },
  userIcon: {
    display: 'flex',
    padding: ['1rem'],
    borderBottom: `1px solid ${colors.line2}`,
    borderTop: `1px solid ${colors.line2}`,
    justifyContent: 'center',
    alignItems: 'center',
    '& >p': {
      margin: ['0.1rem', '0.25rem', 0, '0.5rem'],
      wordBreak: 'break-word'
    },
    margin: ['1rem', 0, 0, 0]
  }
}

export default styles
