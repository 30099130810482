import React, { useCallback, useState } from 'react'
import styles from './AddUserToStore.styles'
import withStyles from 'react-jss'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'
import { Checkbox } from 'components/CheckboxComponent/Checkbox'
import { SearchInput, ConfirmationDialog } from 'components/common'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import { SelectedItems } from 'components/common'
import UserLegends from '../../UserSettings/UserLegends'
import useUserSelector from 'hooks/useUserSelector'
import { getInsightsRole } from 'utils/user.utils'
import { ROLE_VIEWER, USER_STATE_ACTIVE } from 'constants/general.constants'
import apis from 'api'
import { EVENT_EDIT_STORE_INFORMATION } from 'constants/mixpanel.constants'

const getAvailableUsersForTheStore = (users, store) => {
  const { cardAcceptorIdCode } = store
  const filteredUsers = users.filter(user => {
    const { roles, state } = user
    const insightsRole = getInsightsRole(roles, 'cardAcceptorIdCode')
    const isViewer = insightsRole === ROLE_VIEWER
    const isActive = state === USER_STATE_ACTIVE
    const stores =
      isViewer &&
      roles.filter(role => {
        if (role.cardAcceptorIdCode && role.cardAcceptorIdCode === cardAcceptorIdCode) {
          return role
        }
        return null
      })
    const isAvailableUser = isViewer && isActive && (!stores || stores.length === 0)
    if (isAvailableUser) {
      return user
    }
    return null
  })
  return filteredUsers
}

const AddUserToStore = ({ classes, handleBack, store }) => {
  const dispatch = useDispatch()
  const { actions: usersActions, selectors: usersSelectors } = usersSlice
  const users = useSelector(usersSelectors.getAllUsers)
  const [isConfirmationDialogVisible, setConfirmationDialogVisible] = useState(false)

  const updateUsersAction = useCallback(actionParams => dispatch(usersActions.updateUsers(actionParams)), [
    dispatch,
    usersActions
  ])

  const availableUsers = getAvailableUsersForTheStore(users, store)

  const {
    onSearchTextChange,
    onSelectAllFiltered,
    onSelectAll,
    onSelectUser,
    deSelectUser,
    searchText,
    selectedUsers,
    filteredUsers,
    unSelectedUsers,
    isAddSearched,
    isAddAll
  } = useUserSelector({
    availableUsers: availableUsers,
    handleUserSelection: () => {}
  })

  const onAccept = async () => {
    const { Mixpanel: mixpanel } = apis
    const { cardAcceptorIdCode } = store
    const storesSelected = []
    storesSelected.push({
      role: 'INSIGHTS_VIEWER',
      cardAcceptorIdCode
    })
    const updatedUsers = []
    for (let selectedUser of selectedUsers) {
      const updatedUser = { ...selectedUser, roles: [...selectedUser.roles, ...storesSelected] }
      updatedUsers.push(updatedUser)
    }
    mixpanel.track(EVENT_EDIT_STORE_INFORMATION, { usersAdded: updatedUsers.length })
    await updateUsersAction({ usersToBeUpdated: updatedUsers })
    handleBack(store)
  }

  const onClose = () => {
    setConfirmationDialogVisible(false)
  }

  const onAddUserButtonClick = () => {
    setConfirmationDialogVisible(true)
  }

  return (
    <div className={classes['container']}>
      <div className={classes['backAction']}>
        <Button onClick={handleBack} variant="secondary">
          Cancel
        </Button>
      </div>

      <div className={classes['header']}>
        <Text variant="h1"> Add Users</Text>
        <Checkbox
          label={<Text variant="body">Add all remaining users ({unSelectedUsers.length})</Text>}
          checked={isAddAll}
          onClick={() => {
            onSelectAll()
          }}
          onChange={e => {
            e.preventDefault()
          }}
        />
      </div>

      <div className={classes['search']}>
        <SearchInput onChange={onSearchTextChange} placeholder={'Search a User by Name'} text={searchText} />
      </div>

      {!_.isEmpty(selectedUsers) && (
        <div className={classes['selectedUsers']} data-hj-suppress>
          <SelectedItems
            {...{
              list: selectedUsers,
              onClick: deSelectUser,
              keyExtractor: item => {
                return item.name
              },
              title: 'SELECTED USERS',
              isSelected: true,
              displayKey: 'name',
              displayAvatar: true
            }}
          />
          <div className={classes['addButtonContainer']}>
            <Button onClick={onAddUserButtonClick}> Add Users</Button>
          </div>
        </div>
      )}

      <div className={classes['addSearchResult']}>
        {searchText && (
          <Checkbox
            label={<Text variant="body">{'Add all search results'}</Text>}
            checked={isAddSearched}
            onChange={() => {}}
            onClick={onSelectAllFiltered}
            disabled={filteredUsers.length === 0}
          />
        )}
      </div>

      <div className={classes['searchResult']}>
        <SelectedItems
          {...{
            list: filteredUsers,
            onClick: onSelectUser,
            keyExtractor: item => {
              return item.name
            },
            isSelected: false,
            displayKey: 'name',
            displayAvatar: true
          }}
        />
      </div>

      {isConfirmationDialogVisible && (
        <ConfirmationDialog
          {...{
            onAccept,
            onClose,
            callbackParams: { store }
          }}
        >
          <div>
            <Text variant="body">Are you sure you want to add selected users to this store?</Text>
          </div>
        </ConfirmationDialog>
      )}

      <UserLegends />
    </div>
  )
}

export default withStyles(styles)(AddUserToStore)
