import React, { useState, useCallback } from 'react'
import Text from 'components/Text/Text'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import withStyles from 'react-jss'
import styles from './UserStatus.styles'
import { useDispatch } from 'react-redux'
import { usersSlice } from 'reduxSlices'
import { sendEmailVerification } from 'api/users.api'
import { ConfirmationDialog } from 'components/common'
import { USER_STATE_WAITING_EMAIL, USER_STATE_EMAIL_EXPIRED } from 'constants/general.constants'
import apis from 'api'
import { EVENT_RESEND_INVITE } from 'constants/mixpanel.constants'

const resendButtonStyles = {
  small: {
    padding: '0.22rem 1rem'
  }
}

const UserStatusConfirmationMessage = ({ user }) => {
  return (
    <div data-hj-suppress>
      <Text variant="body">
        {user.firstName} {user.lastName} will be invited via email. This invitation will be valid for 24 hours.
      </Text>
    </div>
  )
}

const UserPermissions = ({ user, classes }) => {
  const { state } = user
  const [invited, setInvited] = useState(false)
  const [isInviteBtnVisible, setInviteBtnVisible] = useState(false)
  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice
  const fetchAllUsers = useCallback(actionParams => dispatch(usersActions.fetchAllUsers()), [dispatch, usersActions])

  const onAccept = async () => {
    setInviteBtnVisible(false)

    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_RESEND_INVITE)

    await sendEmailVerification(user)
    setInvited(true)
    fetchAllUsers()
    setTimeout(() => setInvited(false), 10000)
  }

  const onClose = () => {
    setInviteBtnVisible(false)
  }

  const onResendInvitation = async () => {
    setInviteBtnVisible(true)
  }

  const isWaitingForEmailVerification = [USER_STATE_EMAIL_EXPIRED, USER_STATE_WAITING_EMAIL].includes(state)

  return (
    <>
      <div className={classes['userStatus']}>
        <Text variant="body" color={colors.greyDark}>
          {state}
        </Text>

        {isWaitingForEmailVerification && (
          <div className={classes['actionButton']}>
            {!invited && (
              <Button onClick={onResendInvitation} variant="secondary" size="small" override={resendButtonStyles}>
                Resend Invitation
              </Button>
            )}

            {invited && (
              <Text variant="h5" color={colors.success}>
                {' '}
                Email invitation sent
              </Text>
            )}
          </div>
        )}
      </div>

      {isInviteBtnVisible && (
        <ConfirmationDialog
          {...{
            onAccept,
            onClose,
            callbackParams: { user }
          }}
        >
          <UserStatusConfirmationMessage user={user} />
        </ConfirmationDialog>
      )}
    </>
  )
}

export default withStyles(styles)(UserPermissions)
