import React, { useState, useEffect } from 'react'
import * as variables from 'config/jss-vars'
import Message from 'components/Message/Message'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import FormikInput from 'components/FormikWrappers/FormikInput'
import FormikCheckbox from 'components/FormikWrappers/FormikCheckbox'
import withStyles from 'react-jss'
import styles from './AddNewStoreForm.styles'
import FieldSection from 'components/common/FieldSection'
import Container from './AddNewStoreContainer'
import StoreAddedSuccessModal from './AddNewStoreSuccessModal'
import { accountSlice } from 'reduxSlices'
import { useDispatch } from 'react-redux'
import { LinkTC } from 'components/common'

const AddNewStoreForm = props => {
  const { handleSubmit, isSubmitting, classes, values, errors, onBack } = props
  const { serverValidation } = errors
  const { updated } = values

  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const isButtonDisabled = isSubmitting

  const merchantNumberTextOverride = { h4: { fontWeight: variables.weightRegular } }

  const dispatch = useDispatch()
  const { actions: accountActions } = accountSlice

  useEffect(() => {
    if (updated) {
      setConfirmationVisible(true)
    }
  }, [updated])

  const onModalClose = () => {
    setConfirmationVisible(false)
    dispatch(accountActions.fetchMerchantAccess())
    onBack()
  }

  return (
    <form onSubmit={handleSubmit} name="AddNewStoreForm" className={classes['form']}>
      <FieldSection
        title={'Enter Merchant Number'}
        textVariant="h4"
        textOverride={merchantNumberTextOverride}
        labelWidth={'225px'}
        alignCenter
        noBorder
      >
        <FormikInput name="cardAcceptorIdCode" autoFocus />
        <div className={classes['note']}>
          <Text variant="note">Note: You can find this on the second page of your Worldline statement/invoice.</Text>
        </div>
      </FieldSection>
      {serverValidation && (
        <div className={classes['errorMessage']}>
          <Message showIcon={false} variant="error" text={serverValidation} />
        </div>
      )}

      <div className={classes['conditions']}>
        <FormikCheckbox name="tcAgreement" label={<LinkTC />} />
      </div>
      <div className={classes['submitButton']}>
        <Button disabled={isButtonDisabled} variant="primary" type="submit">
          Add New Store
        </Button>
      </div>

      {confirmationVisible && (
        <StoreAddedSuccessModal
          {...{
            onClose: onModalClose,
            user: values
          }}
        />
      )}
    </form>
  )
}

export default withStyles(styles)(Container(AddNewStoreForm))
