import * as colors from 'config/colors'

const styles = {
  result: {
    background: colors.background2,
    marginTop: '1.5rem'
  },
  modalMain: {
    marginTop: 20
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  }
}

export default styles
