import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import withStyles from 'react-jss'
import jss from './ToolTip.styles'

const ToolTip = ({ classes, text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={`${classes['tool-tip']}`}
    >
      {children}
      <span className={`${classes['tool-tip-text']} ${showTooltip && classes['show']}`}>{text}</span>
    </div>
  )
}

ToolTip.propTypes = {
  /**
   * Help text tbe displayed
   */
  text: PropTypes.string,
  /**
   *
   */
  children: PropTypes.element,
  /**
   * Shape of theme expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    'tool-tip': PropTypes.object,
    'tool-tip-text': PropTypes.object
  })
}

ToolTip.defaultProps = {
  children: <Icon name="help" />,
  override: {}
}

export default withStyles(jss)(ToolTip)
