import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import jss from './HeaderLinks.styles.js'
import * as colors from 'config/colors'

/**
 * Header Link component.
 */
const ActionLink = ({ name, target, onClick, classes, color, hoverColor }) => {
  const colorProps = {
    color,
    hoverColor
  }

  const link = (
    <Text variant="header-link" {...colorProps}>
      {name}
    </Text>
  )
  if (onClick) {
    return (
      <button onClick={onClick} className={classes['button-link']}>
        {link}
      </button>
    )
  }

  if (target) {
    return <a href={target}>{link}</a>
  }

  return <Text variant="header-link">{name}</Text>
}

const HeaderLinks = ({ links, activeLink, classes, color, hoverColor }) => {
  return (
    <ul className={classes['links']}>
      {map(links, link => {
        const { name, target, onClick } = link
        const isLinkActive = activeLink === name
        const className = isLinkActive ? classes['is-active'] : null

        const activeElement = isLinkActive ? (
          <Text variant="header-link" color={color}>
            {name}
          </Text>
        ) : (
          <ActionLink {...{ name, target, onClick, classes, color, hoverColor }} />
        )
        return (
          <li key={name} className={className}>
            {activeElement}
          </li>
        )
      })}
    </ul>
  )
}

HeaderLinks.propTypes = {
  /**
   * Pass an array with props name and target. Example [{name: '', target: 'www...'}]
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      target: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired,

  /**
   * Pass the name of the active link
   */
  activeLink: PropTypes.string,

  /**
   * Shape of override expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    links: PropTypes.object,
    'is-active': PropTypes.object
  }),

  color: PropTypes.string,

  hoverColor: PropTypes.string
}

HeaderLinks.defaultProps = {
  override: {},
  color: colors.cyan,
  hoverColor: colors.teal
}

export default withStyles(jss)(HeaderLinks)
