import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMenu from 'react-burger-menu'
import Divider from '../Divider/Divider'
import withStyles from 'react-jss'
import { jss, libStyles } from './BurgerMenu.styles'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import * as colors from 'config/colors'

const Links = ({ classes, links, closeMenu, activeLink }) => {
  if (!links) {
    return null
  }
  const doAction = action => {
    if (closeMenu) {
      closeMenu()
    }
    if (action) {
      action()
    }
  }

  return (
    <ul className={classes['list']}>
      {links.map(link => {
        const { name, onClick, icon } = link
        const listClassName = name === activeLink ? 'activeLink' : 'link'
        return (
          <li className={classes[listClassName]} onClick={() => doAction(onClick)} key={name}>
            <div className={classes['listIcon']}>{icon} </div>
            <div>{name}</div>
          </li>
        )
      })}
    </ul>
  )
}

const SwitchAppButton = ({ classes, onSwitchAppClick }) => {
  return (
    <div className={classes['switchAppButton']}>
      <Button variant="withIcon" onClick={onSwitchAppClick} width={'85px'} size={'small'}>
        <Icon
          className="buttonIcon"
          name="switchIcon"
          width={'1.3em'}
          height={'1.3em'}
          color={colors.warningBackground}
        />
        Old App
      </Button>
    </div>
  )
}

class BurgerMenu extends Component {
  state = {
    isOpen: false
  }

  openMenu = () => {
    this.setState({ isOpen: true })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
  }

  isMenuOpen = state => {
    // For outside click
    if (state.isOpen === false) {
      this.setState({
        isOpen: false
      })
    }
    return state.isOpen
  }

  render() {
    const {
      classes,
      navLinks,
      actionLinks,
      activeLink,
      secondaryActionLinks,
      showSwitchApp = false,
      onSwitchAppClick,
      preset
    } = this.props
    return (
      <div>
        <ReactMenu.slide
          onStateChange={this.isMenuOpen}
          right
          width={260}
          styles={libStyles}
          customCrossIcon={false}
          isOpen={this.state.isOpen}
          customBurgerIcon={false}
          menuClassName={classes['menuContent']}
          itemListClassName={classes['itemList']}
        >
          {showSwitchApp && <SwitchAppButton {...{ classes, onSwitchAppClick }} />}
          <Links {...{ classes, links: navLinks, activeLink, closeMenu: this.closeMenu }} />
          <Divider />
          <Links {...{ classes, links: secondaryActionLinks, activeLink, closeMenu: this.closeMenu }} />
          <Divider />
          <Links {...{ classes, links: actionLinks, activeLink, closeMenu: this.closeMenu }} />
        </ReactMenu.slide>
        <div onClick={this.openMenu} className={classes['menuIcon']}>
          <Icon
            name="menu"
            width={jss.listIcon.width}
            height={jss.listIcon.height}
            color={preset === 'dark' ? colors.white : undefined}
          />
        </div>
      </div>
    )
  }
}

BurgerMenu.propTypes = {
  /**
   *  Nav links
   */
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.any,
      onClick: PropTypes.func
    })
  ),
  /**
   *  Action links
   */
  actionLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.any,
      onClick: PropTypes.func
    })
  ),
  onSwitchAppClick: PropTypes.func,
  /**
   * Shape of override expected. Any additional classes will be ignored.
   */
  override: PropTypes.shape({
    navLinks: PropTypes.object,
    actionLinks: PropTypes.object,
    list: PropTypes.object,
    listIcon: PropTypes.object,
    link: PropTypes.object,
    menuIcon: PropTypes.object
  }),
  /**
   * Flag to show the button which, when clicked, navigates from beta app to old app.
   */
  showSwitchApp: PropTypes.bool,
  preset: PropTypes.oneOf(['light', 'dark'])
}

BurgerMenu.defaultProps = {
  override: {},
  showCloseIcon: false
}

export default withStyles(jss)(BurgerMenu)
