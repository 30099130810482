import _ from 'lodash'
import { OTHER_CARD_LIMIT_PERCENTAGE, OTHERS_KEY } from 'constants/general.constants'
const TOP_CARDS_NO = 4

export const parseCardsUsed = (data, isComparePeriod) => {
  const { current, previous } = data
  const isCurrentEmpty = _.isEmpty(current)
  const isPreviousEmpty = _.isEmpty(previous)
  const currenCardPercentage = !isCurrentEmpty ? getCardPercentage(current) : {}
  const previousCardPercentage = !isPreviousEmpty ? getCardPercentage(previous) : {}

  return {
    current: currenCardPercentage,
    previous: previousCardPercentage
  }
}

/**
 * Get the top 4 cards rest are clubbed into other. Also cards lower than 5 % are clubbed into others.
 * @param {*} cards
 */

const getCardPercentage = cards => {
  if (_.isEmpty(cards)) {
    return null
  }
  let total = cards.map(elem => elem.count).reduce((total, value) => total + value)

  const othersLimit = (OTHER_CARD_LIMIT_PERCENTAGE / 100) * total

  const totalCards = cards.length

  const majorCards = cards.filter(card => {
    const { count } = card
    return count >= othersLimit
  })

  const otherCards = cards.filter(card => {
    const { count } = card
    return count < othersLimit
  })

  let cardsPercentage = majorCards.map(card => {
    const { group, count, value } = card
    const percentage = Math.round((count / total) * 100)
    return {
      group,
      percentage,
      value
    }
  })

  let sortedCards = _.orderBy(cardsPercentage, ['percentage'], ['desc'])

  if (sortedCards.length > TOP_CARDS_NO) {
    // Top 4 cards
    sortedCards = _.take(sortedCards, TOP_CARDS_NO)
  }
  const majorCardShare = sortedCards
    .map(item => item.percentage)
    .reduce((total = 0, percentage) => {
      return total + percentage
    })

  if (totalCards > sortedCards.length) {
    sortedCards.push({ group: OTHERS_KEY, percentage: 100 - majorCardShare, meta: otherCards })
  }

  // Fix for rounding off problem when the totals dont round up to 100. This causes a issue with horizontal bar chart
  if (majorCards.length > 1) {
    const initial = _.initial(sortedCards)
    let last = _.last(sortedCards)
    const intialTotal = initial.map(elem => elem.percentage).reduce((total, val) => total + val)
    last = { ...last, percentage: 100 - intialTotal }
    initial.push(last)
    sortedCards = initial
  }

  return sortedCards
}
