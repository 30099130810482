import React from 'react'
import MediaQuery from 'react-responsive'
import ContentLoader from 'react-content-loader'
import * as mediaQueryHelper from 'config/mediaQueryHelper'
import TableHeader from '../SettlementTable/TableHeader'

const { BREAK_POINT_MEDIUM, max, min } = mediaQueryHelper

const getContentLoader = random => {
  return (
    <>
      <ContentLoader height={40} width={980} speed={2} primaryColor="#d9d9d9" secondaryColor="#ecebeb">
        <MediaQuery query={`(${max(BREAK_POINT_MEDIUM)})`}>
          <rect x="0" y="13" rx="6" ry="6" width={400 * random} height="16" />
          <rect x="780" y="13" rx="6" ry="6" width={200 * random} height="16" />
        </MediaQuery>
        <MediaQuery query={`(${min(BREAK_POINT_MEDIUM)})`}>
          <rect x="0" y="13" rx="6" ry="6" width={100 * random} height="12" />
          <rect x="200" y="13" rx="6" ry="6" width={100 * random} height="12" />
          <rect x="440" y="13" rx="6" ry="6" width={30 * random} height="12" />
          <rect x="570" y="13" rx="6" ry="6" width={50 * random} height="12" />
          <rect x="720" y="13" rx="6" ry="6" width={50 * random} height="12" />
          <rect x="880" y="13" rx="6" ry="6" width={100 * random} height="12" />
        </MediaQuery>
      </ContentLoader>
    </>
  )
}

const LoadingSettlementTable = ({ classes }) => {
  const random1 = Math.random() * (1 - 0.7) + 0.7
  const random2 = Math.random() * (1 - 0.7) + 0.7

  return (
    <div>
      <table className={classes['table']}>
        <TableHeader {...{ classes }} />
      </table>
      {getContentLoader(random1)}
      {getContentLoader(random2)}
      {getContentLoader(random1)}
    </div>
  )
}

export default LoadingSettlementTable
