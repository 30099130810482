import React, { useEffect } from 'react'
import styles from './AccountSettings.styles'
import withStyles from 'react-jss'
import { HeaderLinks, withPrivateLayout, Container } from 'components/common'
import { MyAccount, UserSettings, StoreSettings } from 'components'
import {
  AccountSettingsMyAccountRoute,
  AccountSettingsUsersRoute,
  AccountSettingsStoresRoute,
  InviteUserRoute,
  AddStoreRoute
} from 'Routes'
import { usersSlice } from 'reduxSlices'
import { useDispatch } from 'react-redux'
import { isInsightsOwner } from 'utils/user.utils'

export const ACCOUNT_TAB = {
  value: 'myAccount',
  title: 'My Account'
}

export const USERS_TAB = {
  value: 'users',
  title: 'Users'
}

export const STORES_TAB = {
  value: 'stores',
  title: 'Stores'
}

const AccountSettings = ({ routerProps, classes, userData }) => {
  const {
    location: { pathname },
    history
  } = routerProps

  const {
    user: { roles }
  } = userData

  const dispatch = useDispatch()
  const { actions: usersActions } = usersSlice

  const isOwner = isInsightsOwner(roles)
  let TABS = []

  let activeTabName = pathname.split('/settings/')[1] || TABS[0].title

  // Get all the users only when the user is a Owner
  useEffect(() => {
    if (isOwner) {
      dispatch(usersActions.fetchAllUsers())
    }
  }, [dispatch, isOwner, usersActions])

  const changeTab = value => {
    let url = ''
    switch (value) {
      case ACCOUNT_TAB.value:
        url = AccountSettingsMyAccountRoute.path
        break
      case USERS_TAB.value:
        url = AccountSettingsUsersRoute.path
        break
      case STORES_TAB.value:
        url = AccountSettingsStoresRoute.path
        break
      default:
        url = AccountSettingsMyAccountRoute.path
    }
    history.push(url)
  }

  if (isOwner) {
    TABS = [ACCOUNT_TAB, USERS_TAB, STORES_TAB]
  } else {
    TABS = [ACCOUNT_TAB, STORES_TAB]
    // Users Tab is not allowed for a user who is not a OWNER
    if (activeTabName === USERS_TAB.value) {
      changeTab(ACCOUNT_TAB.value)
      return null
    }
  }

  const headerLinkProps = {
    title: 'Account Settings',
    links: [...TABS],
    activeLink: activeTabName,
    action: changeTab,
    hasPaddingTop: true
  }

  const isMyAccountTab = ACCOUNT_TAB.value === activeTabName
  const isUsersTab = USERS_TAB.value === activeTabName
  const isStoresTab = STORES_TAB.value === activeTabName

  const buttonName = isUsersTab ? 'Invite User' : isStoresTab && isOwner ? 'Add New Store' : ''
  const pathName = isUsersTab ? InviteUserRoute.path : isStoresTab ? AddStoreRoute.path : ''

  return (
    <Container {...{ withContainerPaddingTop: false }}>
      <div className={classes['accountSettings']}>
        <HeaderLinks
          {...{
            ...headerLinkProps,
            button: buttonName,
            onClick: () => {
              history.push(pathName)
            }
          }}
        />
        <div>{isMyAccountTab && <MyAccount />}</div>
        <div>{isUsersTab && <UserSettings {...{ routerProps }} />}</div>
        <div>{isStoresTab && <StoreSettings {...{ routerProps }} />}</div>
      </div>
    </Container>
  )
}

export default withPrivateLayout(withStyles(styles)(AccountSettings))
