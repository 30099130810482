import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  permissions: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      marginRight: '2rem',
      marginBottom: '1rem',
      [MOBILE_UP()]: {
        marginBottom: '0 !important'
      }
    },
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  }
}

export default styles
