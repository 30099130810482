import React from 'react'
import withStyles from 'react-jss'
import styles from './HeaderLinks.styles'
import objstr from 'obj-str'
import PropTypes from 'prop-types'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'

const buttonOverride = {
  secondary: {
    padding: '0.625rem 1rem'
  }
}

const HeaderLinks = ({ classes, title, links, button, onClick, activeLink, action, hasPaddingTop }) => {
  return (
    <div className={classes['wrapper']}>
      {title && (
        <div className={classes['title']}>
          <Text variant="h2"> {title}</Text>
        </div>
      )}

      {links.map(link => {
        const { value, title } = link
        const linkClassName = objstr({ [classes['link']]: true, [classes['active']]: activeLink === value })

        return (
          <div key={value} className={linkClassName} onClick={() => action(value)}>
            {title}
          </div>
        )
      })}

      {button && (
        <div className={classes['actionBtn']}>
          <Button variant="secondary" onClick={onClick} override={buttonOverride}>
            {button}
          </Button>
        </div>
      )}
    </div>
  )
}

HeaderLinks.propTypes = {
  activeLink: PropTypes.string,
  action: PropTypes.func,
  links: PropTypes.array,
  hasPaddingTop: PropTypes.bool
}

HeaderLinks.defaultProps = {
  hasPaddingTop: false
}

export default withStyles(styles)(HeaderLinks)
