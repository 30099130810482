import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import { getConfig } from 'utils/config.utils'
import _ from 'lodash'

const { mixPanelToken } = getConfig()
const init = () => {
  try {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.init(mixPanelToken)
    }
  } catch (e) {}
}

init() // Initialise Mixpanel

const setTimeProperty = data => {
  if (data) {
    data['$time'] = moment().unix()
  }
}

const Mixpanel = {
  alias: identifier => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.alias(identifier)
    }
  },
  identify: identifier => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.identify(identifier?.toLowerCase())
    }
  },
  register: data => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.register(data)
    }
  },
  peopleSet: data => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.people.set(data)
    }
  },
  peopleDeleteUser: () => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.people.delete_user()
    }
  },
  reset: () => {
    if (!_.isEmpty(mixPanelToken)) {
      mixpanel.reset()
    }
  },
  track: (name, data) => {
    if (_.isEmpty(mixPanelToken)) {
      return
    }
    try {
      setTimeProperty(data)
      mixpanel.track(name, data)
    } catch (e) {}
  }
}

export default Mixpanel
