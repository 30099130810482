import * as colors from 'config/colors'
import { weightSemiBold, standardFontFamily } from 'config/jss-vars'
import { LAPTOP_UP } from 'config/mediaQueryHelper'
const border = `1px solid ${colors.line1}`
const styles = {
  toggleGroup: {
    float: 'left',
    border: border,
    borderRadius: '3px',
    background: colors.white,
    color: colors.cyan,
    backgroundColor: colors.white,
    fontWeight: weightSemiBold,
    fontFamily: standardFontFamily,
    '& > div': {
      borderRight: border
    },
    '& :last-child': {
      borderRight: 'none'
    },
    extend: ({ override }) => ({
      ...override['toggleGroup']
    })
  },
  toogleButton: {
    float: 'left',
    borderRight: `1px solid ${colors.line1}`,
    textAlign: 'center',
    padding: ['0.65rem', '0.45rem'],
    [LAPTOP_UP()]: {
      padding: '0.65rem'
    },
    '& > span': {
      margin: 'auto'
    },
    cursor: 'pointer',
    transition: 'color 0.25s ease-in-out',
    '&:hover': {
      color: colors.teal,
      backgroundColor: colors.highlight,
      borderBottom: border
    },
    '&:active': {
      background: colors.active,
      color: colors.teal,
      borderBottom: border
    },
    extend: ({ override }) => ({
      ...override['toggleButton']
    })
  },
  active: {
    color: colors.teal,
    backgroundColor: colors.highlight,
    cursor: 'default',
    borderBottom: border,
    extend: ({ override }) => ({
      ...override['active']
    })
  },
  disabled: {
    opacity: '0.5',
    cursor: 'default !important',
    extend: ({ override }) => ({
      ...override['disabled']
    })
  }
}

export default styles
