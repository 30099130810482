import Text from 'components/Text/Text'
import React from 'react'
import StoresTable from '../StoresTable/StoresTable'

const PrintableTopStores = ({ isCompare, stores, classes }) => {
  return (
    <>
      <div style={{ display: 'none' }}>
        <div id="printableTopStores">
          <div className={classes['header']}>
            <Text variant="h2">Top Stores</Text>
          </div>
          <StoresTable
            {...{
              stores: stores,
              isCompare,
              onSelect: () => {},
              isLoading: false,
              hasPagination: false
            }}
          />
        </div>
      </div>
    </>
  )
}

export default PrintableTopStores
