import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import * as colors from 'config/colors'
import PopoverMenu from '../../PopoverMenu/PopoverMenu'
import Icon from '../../Icon/Icon'
import Logo from '../../Logo/Logo'
import { lightPopoverContainerStyles, darkPopoverContainerStyles } from './PopoverContainer.styles'

const PopoverContainer = ({
  classes,
  activeLink,
  appsLinks,
  menuItems,
  secondaryMenuItems,
  secondaryMenuIconName,
  applicationMenuItems,
  inviteMenuItems,
  notificationMenuItems,
  accountMenuItems,
  mouseEnter,
  timeout,
  preset,
  override,
  popoverContainerPrefix
}) => {
  accountMenuItems = accountMenuItems || menuItems

  return (
    <div className={classes['popoverContainer']}>
      {appsLinks && (
        <div
          className={classes['appsLinksItems']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'appsLinksContainer'}
        >
          <PopoverMenu
            menuItems={appsLinks}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'appsLinksContainer'}
          >
            <Icon name="switch" width="20" height="20" color={preset === 'dark' ? colors.white : undefined} />
          </PopoverMenu>
        </div>
      )}

      {secondaryMenuItems && (
        <div
          className={classes['secondaryMenuItems']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'secondaryMenuContainer'}
        >
          <PopoverMenu
            menuItems={secondaryMenuItems}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={
              (popoverContainerPrefix ? popoverContainerPrefix : '') + 'secondaryMenuContainer'
            }
          >
            <Icon
              name={secondaryMenuIconName}
              width={'1.625em'}
              height={'1.625em'}
              color={preset === 'dark' ? colors.white : undefined}
            />
          </PopoverMenu>
        </div>
      )}

      {applicationMenuItems && (
        <div
          className={classes['applicationMenu']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'applicationMenuContainer'}
        >
          <PopoverMenu
            menuItems={applicationMenuItems}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={
              (popoverContainerPrefix ? popoverContainerPrefix : '') + 'applicationMenuContainer'
            }
          >
            <Icon name={preset === 'dark' ? 'switch' : 'switch-color'} width={'1.2em'} height={'1.2em'} />
          </PopoverMenu>
        </div>
      )}

      {inviteMenuItems && (
        <div
          className={classes['inviteMenu']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'inviteMenuContainer'}
        >
          <PopoverMenu
            menuItems={inviteMenuItems}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'inviteMenuContainer'}
          >
            <Icon
              name="plus-circle"
              width={'1.625em'}
              height={'1.625em'}
              color={preset === 'dark' ? colors.white : undefined}
            />
          </PopoverMenu>
        </div>
      )}

      {notificationMenuItems && (
        <div
          className={classes['notificationMenu']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'notificationMenuContainer'}
        >
          <PopoverMenu
            menuItems={notificationMenuItems}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={
              (popoverContainerPrefix ? popoverContainerPrefix : '') + 'notificationMenuContainer'
            }
          >
            <Icon
              name={'exclamation-circle'}
              width={'1.6em'}
              height={'1.6em'}
              color={preset === 'dark' ? colors.white : colors.icon}
            />
          </PopoverMenu>
        </div>
      )}

      {accountMenuItems && (
        <div
          className={classes['accountMenu']}
          id={(popoverContainerPrefix ? popoverContainerPrefix : '') + 'accountMenuContainer'}
        >
          <PopoverMenu
            menuItems={accountMenuItems}
            activeLink={activeLink}
            mouseEnter={mouseEnter}
            timeout={timeout}
            override={override}
            popoverContentDestinationId={
              (popoverContainerPrefix ? popoverContainerPrefix : '') + 'accountMenuContainer'
            }
          >
            <Icon name={preset === 'dark' ? 'account-light' : 'account'} width={'1.625em'} height={'1.625em'} />
          </PopoverMenu>
        </div>
      )}
    </div>
  )
}

export const LightPopoverContainer = withStyles(lightPopoverContainerStyles)(PopoverContainer)
export const DarkPopoverContainer = withStyles(darkPopoverContainerStyles)(PopoverContainer)

PopoverContainer.propTypes = {
  /**
   *  Header links
   */
  activeLink: PropTypes.string,

  /**
   * Apps Links
   */
  appsLinks: PropTypes.array,

  /**
   * Custom Icon Menu Items
   */
  secondaryMenuItems: PropTypes.array,
  secondaryMenuIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Logo)]),

  /**
   * Fixed Icon Menu Items
   */
  menuItems: PropTypes.array,
  applicationMenuItems: PropTypes.array,
  inviteMenuItems: PropTypes.array,
  notificationMenuItems: PropTypes.array,
  accountMenuItems: PropTypes.array,

  /**
   * Flag for enabling the mouseEnter event
   */
  mouseEnter: PropTypes.bool,

  /**
   * Timeout in ms used for the mouseEnter event
   */
  timeout: PropTypes.number,

  /**
   * Preset is used to specify an app-specific style preset
   */
  preset: PropTypes.oneOf(['light', 'dark']),

  /**
   * Prefix for uniquely specifying popover ids
   */

  popoverContainerPrefix: PropTypes.string
}

PopoverContainer.defaultProps = {
  logo: <Logo name="paymark" color={colors.brandDark} />,
  override: {},
  secondaryMenuIcon: <Icon name="plus-circle" color={colors.brandDark} />
}
