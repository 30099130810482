import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  card: {
    width: '100%',
    minHeight: params => params.height || '100%',
    background: colors.teal,
    margin: 'auto',
    borderRadius: 3,
    border: `1px solid rgb(223, 222, 227)`,
    boxShadow: '0px 2px 4px 0px rgb(223, 222, 227)',
    [LARGE_SCREEN_UP()]: {
      display: 'grid',
      msGridColumns: '1fr 3fr',
      gridTemplateColumns: '1fr 3fr'
    }
  },
  titleText: {
    margin: 'auto',
    display: 'flex',
    '& >div': {
      padding: [0, '0.5rem', '0.5rem']
    },
    '& h5': {
      fontSize: '1em',
      fontWeight: '600',
      color: '#ffffff'
    },
    [MOBILE_UP()]: {
      '& h5': {
        fontSize: '1.25em',
        fontWeight: '600',
        color: '#ffffff'
      }
    }
  },
  title: {
    display: 'flex'
  },
  header: {
    width: '100%',
    padding: ['1.5rem', '0.5rem', 0, 0],
    [LARGE_SCREEN_UP()]: {
      padding: ['1.5rem', 0, 0, '1.25rem'],
      msGridColumn: 1,
      msGridRow: 1
    },
    borderBottom: params => {
      if (params.showHeaderBorder) {
        return `1px solid ${colors.line2}`
      }
      return 0
    }
  },
  cardContent: {
    [LARGE_SCREEN_UP()]: {
      msGridColumn: 2,
      msGridRow: 1
    }
  },
  resizeIcon: {
    '& :hover': {
      cursor: 'pointer',
      opacity: 0.8
    },
    display: 'none',
    [MOBILE_UP()]: {
      display: 'block',
      padding: [0, '0.75rem', 0, 0]
    }
  }
}

export default styles
