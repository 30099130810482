import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LAPTOP_UP, SCREEN_UP, LARGE_SCREEN_UP } = mediaQueryHelper

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    '& >div': {
      padding: ['1rem', '1rem', 0, '1rem'],
      [LARGE_SCREEN_UP()]: {
        padding: ['1rem', '1.5rem', 0, '1.5rem']
      }
    }
  },

  filterSection: {
    composes: '$content',
    borderBottom: `1px solid ${colors.line1}`,
    [SCREEN_UP()]: {
      padding: [0, '1rem']
    },
    [LARGE_SCREEN_UP()]: {
      padding: [0, '1.5rem']
    }
  },
  allStoresSection: {
    composes: '$content',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.line1}`,
    [SCREEN_UP()]: {
      flexDirection: 'row',
      padding: [0, '1rem', '1rem', '1rem']
    },
    [LARGE_SCREEN_UP()]: {
      flexDirection: 'row',
      padding: [0, '1.5rem', '1rem', '1.5rem']
    }
  },
  commentarySection: {
    width: '100%',
    marginTop: '0.5rem',
    textAlign: 'center',
    [SCREEN_UP()]: {
      textAlign: 'left',
      width: '320px',
      marginTop: '1.5rem'
    },
    '& >.tablet': {
      paddingTop: '1rem',
      display: 'none',
      [SCREEN_UP()]: {
        height: '100%',
        display: 'block'
      }
    }
  },

  graphs: {
    flex: [1, 1, 'auto'],
    margin: [0],
    display: 'grid',
    gridTemplateColumns: '1fr',
    msGridRows: `auto 1em auto 1em auto 1em auto 1em auto`,
    gridTemplateRows: 'auto auto auto auto auto',
    gridGap: '1em',
    gridTemplateAreas: `
                  'revenueGraph'
                  'salesGraph'
                  'customersGraph'
                  'cardsGraph'
                  'mobileAllStoresCommentary'
              
    `,
    [LAPTOP_UP()]: {
      msGridColumns: `1fr 1em 1fr`,
      gridTemplateColumns: '1fr 1fr',
      msGridRows: `auto 1em auto 1em auto 1em auto`,
      gridTemplateRows: 'auto auto auto auto',
      gridTemplateAreas: `
                    'revenueGraph salesGraph'
                    'customersGraph customersGraph'
                    'cardsGraph cardsGraph'
                    'mobileAllStoresCommentary mobileAllStoresCommentary'
      `
    },
    [SCREEN_UP()]: {
      margin: ['1.5rem', 0],
      msGridColumns: `1fr 1em 1fr`,
      gridTemplateColumns: '1fr 1fr',
      msGridRows: `1fr 1em auto 1em auto`,
      gridTemplateRows: '1fr auto auto',
      gridTemplateAreas: `
                    'revenueGraph salesGraph'
                    'customersGraph customersGraph'
                    'cardsGraph cardsGraph'
      `
    },
    '& .revenueGraph': {
      gridArea: 'revenueGraph',
      minWidth: '250px', //For Scalling of grah on tablets, when the graph is minimized
      msGridColumn: 1,
      msGridRow: 1,
      msGridColumnSpan: 1
    },
    '& .salesGraph': {
      gridArea: 'salesGraph',
      minWidth: '250px',
      msGridColumn: 1,
      msGridRow: 3,
      msGridColumnSpan: 3,

      [LAPTOP_UP()]: {
        msGridColumn: 3,
        msGridRow: 1,
        msGridColumnSpan: 1
      },
      [SCREEN_UP()]: {
        msGridColumn: 3,
        msGridRow: 1,
        msGridColumnSpan: 1
      }
    },
    '& .cardsGraph': {
      gridArea: 'cardsGraph',
      msGridColumn: 1,
      msGridRow: 7,
      msGridColumnSpan: 1,

      [LAPTOP_UP()]: {
        msGridColumn: 1,
        msGridRow: 5,
        msGridColumnSpan: 3
      },
      [SCREEN_UP()]: {
        msGridColumn: 1,
        msGridRow: 5,
        msGridColumnSpan: 3
      }
    },
    '& .customersGraph': {
      gridArea: 'customersGraph',
      msGridColumn: 1,
      msGridRow: 5,
      msGridColumnSpan: 1,

      [LAPTOP_UP()]: {
        msGridColumn: 1,
        msGridRow: 3,
        msGridColumnSpan: 3
      },
      [SCREEN_UP()]: {
        msGridColumn: 1,
        msGridRow: 3,
        msGridColumnSpan: 3
      }
    },
    '& .mobileAllStoresCommentary': {
      gridArea: 'mobileAllStoresCommentary',
      msGridColumn: 1,
      msGridRow: 5,
      msGridColumnSpan: 1,

      [SCREEN_UP()]: {
        display: 'none'
      }
    }
  },
  expandedCardsGrid: {
    [LAPTOP_UP()]: {
      msGridColumns: '1fr',
      gridTemplateColumns: '1fr',
      msGridRows: 'auto 1em auto 1em auto',
      gridTemplateRows: 'auto auto auto',
      gridTemplateAreas: `
                          'revenueGraph revenueGraph'
                          'cardsGraph cardsGraph'
                          'mobileAllStoresCommentary mobileAllStoresCommentary'
                          `,
      '& .revenueGraph': {
        msGridColumnSpan: 3
      },
      '& .salesGraph': {
        display: 'none'
      }
    },
    [SCREEN_UP()]: {
      msGridColumns: '1fr 1em 1fr',
      gridTemplateColumns: '1fr 1fr',
      msGridRows: 'auto 1em auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
                          'revenueGraph revenueGraph'
                          'cardsGraph cardsGraph'
                          `
    }
  },
  expandedCardsGridSales: {
    [LAPTOP_UP()]: {
      msGridColumns: '1fr',
      gridTemplateColumns: '1fr',
      msGridRows: 'auto 1em auto 1em auto',
      gridTemplateRows: 'auto auto auto',
      gridTemplateAreas: `
                          'salesGraph salesGraph'
                          'cardsGraph cardsGraph'
                          'mobileAllStoresCommentary mobileAllStoresCommentary'
                          `,
      '& .revenueGraph': {
        display: 'none'
      },
      '& .salesGraph': {
        msGridColumn: 1,
        msGridColumnSpan: 3
      }
    },
    [SCREEN_UP()]: {
      msGridColumns: '1fr 1em 1fr',
      gridTemplateColumns: '1fr 1fr',
      msGridRows: 'auto 1em auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
                          'salesGraph salesGraph'
                          'cardsGraph cardsGraph'
                          `
    }
  },
  topStoresSection: {
    composes: '$content',
    display: 'flex',
    flexDirection: 'column',

    [SCREEN_UP()]: {
      flexDirection: 'row',
      padding: [0, '1rem']
    },
    [LARGE_SCREEN_UP()]: {
      padding: [0, '1.5rem']
    }
  },
  mobileTopStoresCommentary: {
    [SCREEN_UP()]: {
      display: 'none'
    }
  },
  topStoresTable: {
    flex: [1, 1, 'auto'],
    [SCREEN_UP()]: {
      margin: ['1.5rem', 0]
    }
  }
}

export default styles
