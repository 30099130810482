import React from 'react'
import numeral from 'numeral'
import Label from 'components/common/HorizontalBarChart/Label'
import { OTHER_CARD_LIMIT_PERCENTAGE } from 'constants/general.constants'
import { isMobileWidth } from 'utils/common.utils'

const CurrentDataLabel = ({ x, y, width, stroke, value, total, index }) => {
  const displayValue = numeral(value / 100).format('0%')
  if (value < OTHER_CARD_LIMIT_PERCENTAGE && isMobileWidth()) {
    return null
  }

  return (
    <Label
      {...{
        x,
        y,
        width,
        stroke,
        value: displayValue,
        total,
        index,
        align: 'middle',
        mobileFontSize: 14,
        vAlign: 'top'
      }}
    />
  )
}

export default CurrentDataLabel
