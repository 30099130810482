import React from 'react'
import withStyles from 'react-jss'
import styles from './StoreRevenueCard.styles'
import { DashboardCard, BarChart, ChartToolTip } from 'components/common'
import { getCurrency, getDifferencePercentage } from 'utils/number.utils'
import { GRAPH_REVENUE_COLOR } from 'constants/colors.contants'
import useSalesRevenueAgg from 'reduxSlices/dashboardSlice/hooks/useSalesRevenueAgg'
import useTopStores from 'reduxSlices/dashboardSlice/hooks/useTopStores'
import useFilters from 'reduxSlices/dashboardSlice/hooks/useFilters'
import StoreCardGraphLayout from 'components/StorePerformance/StoreCardGraphLayout/StoreCardGraphLayout'
import StoreRevenueCardLoader from './StoreRevenueCardLoader'
import { getMinMaxRevenueValue } from 'utils/salesRevenue.utils'
import { withRouter } from 'react-router-dom'
import { generateTransactionPageUrl } from 'components/TransactionFilter/TransactionFilter.helper'
import { generateRankedCommentary, generateRankedText } from '../StorePerformance.helper'
import { formatDateByRange } from 'components/common/ChartToolTip/ChartToolTip.utils'

const RevenueChart = ({ data, isComparePeriod, isLoading, isCompareStore, handleClick, minValue, maxValue, range }) => {
  if (isLoading) {
    return <StoreRevenueCardLoader {...{ isCompareStore }} />
  }
  const dateFormatter = value => {
    return formatDateByRange(value, range)
  }

  return (
    <BarChart
      {...{
        showYAxis: true,
        data: data,
        isCompare: isComparePeriod,
        showTooltip: true,
        handleClick,
        minValue,
        maxValue,
        isCompareStore,
        CustomTooltip: (
          <ChartToolTip
            {...{
              isCompare: isComparePeriod,
              valueFormatter: value => getCurrency(value),
              dataKey: 'revenue',
              dateFormatter
            }}
          />
        )
      }}
    />
  )
}

const StoreRevenueCard = ({ classes, history }) => {
  const { primaryStore, secondaryStore, isComparePeriod } = useSalesRevenueAgg()
  const { stores } = useTopStores()
  const { filter } = useFilters()
  const { comparedStore, store, range } = filter
  const { data: primaryData, isLoading: isPrimaryLoading } = primaryStore
  const { data: secondaryData, isLoading: isSecondaryLoading } = secondaryStore

  const { maxValue, minValue } = getMinMaxRevenueValue(!!comparedStore, isComparePeriod, primaryData, secondaryData)

  const handleClick = (selectedData, period, isPrimaryStore) => {
    const storeToBeUsed = isPrimaryStore ? store : comparedStore
    const { currentDateTime, oldDateTime } = selectedData
    const timeFrom = 'current' === period ? currentDateTime : oldDateTime

    const url = generateTransactionPageUrl(storeToBeUsed, range, timeFrom)

    history.push(url)
  }

  let PrimaryChart = (
    <RevenueChart
      {...{
        data: primaryData,
        isComparePeriod,
        isLoading: isPrimaryLoading,
        isCompareStore: !!comparedStore,
        minValue,
        maxValue,
        range,
        handleClick: (...params) => handleClick(...params, true)
      }}
    />
  )
  let SecondaryChart = (
    <RevenueChart
      {...{
        data: secondaryData,
        isComparePeriod,
        isLoading: isSecondaryLoading,
        isCompareStore: !!comparedStore,
        minValue,
        maxValue,
        range,
        handleClick: (...params) => handleClick(...params, false)
      }}
    />
  )

  const {
    currentRevenueTotal: primaryStoreCurrentRevenueTotal,
    previousRevenueTotal: primaryStorePreviousRevenueTotal
  } = primaryStore
  const { currentRevenueTotal: compareStoreCurrentRevenueTotal } = secondaryStore
  const differencePercentage = getDifferencePercentage(
    primaryStoreCurrentRevenueTotal,
    primaryStorePreviousRevenueTotal
  )

  const commentary = generateRankedCommentary(primaryData, range, differencePercentage, 'revenue')
  const rankText = generateRankedText(stores, store, 'revenue')

  return (
    <DashboardCard
      {...{
        title: 'Revenue Total',
        iconName: 'revenue',
        height: '300px',
        isResizeable: false,
        showHeaderBorder: true
      }}
    >
      <div className={classes['cardContent']}>
        <div className={classes['currentStore']}>
          <StoreCardGraphLayout
            {...{
              Chart: PrimaryChart,
              total: getCurrency(primaryStoreCurrentRevenueTotal, false, false),
              previousPeriodTotal: isComparePeriod
                ? getCurrency(isComparePeriod ? primaryStorePreviousRevenueTotal : 0, false, false)
                : undefined,
              totalColor: GRAPH_REVENUE_COLOR,
              commentary,
              storeName: store ? store.displayName : '',
              rankText: rankText,
              isCompareStore: !!comparedStore,
              isLoading: isPrimaryLoading,
              percentageChange: (isComparePeriod ? primaryStorePreviousRevenueTotal : 0) && differencePercentage,
              graphBias: true
            }}
          />
        </div>

        {!!comparedStore && (
          <div className={classes['comparedStore']}>
            <StoreCardGraphLayout
              {...{
                Chart: SecondaryChart,
                total: getCurrency(compareStoreCurrentRevenueTotal, false, false),
                totalColor: GRAPH_REVENUE_COLOR,
                storeName: comparedStore ? comparedStore.displayName : '',
                rankText: '',
                isCompareStore: !!comparedStore,
                graphBias: true
              }}
            />
          </div>
        )}
      </div>
    </DashboardCard>
  )
}

export default React.memo(withRouter(withStyles(styles)(StoreRevenueCard)))
