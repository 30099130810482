const styles = {
  icon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    lineHeight: 0,
    '& * path': {
      fill: params => params.color
    },
    '& * polygon': {
      fill: params => params.color
    },
    '& * rect': {
      fill: params => params.color
    },
    '& * [fill="none"]': {
      fill: `none !important`
    }
  }
}

export default styles
