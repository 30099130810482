import * as mediaQueryHelper from 'config/mediaQueryHelper'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  userStatus: {
    display: 'flex',
    flexDirection: 'column',
    [MOBILE_UP()]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  actionButton: {
    [MOBILE_UP()]: {
      marginLeft: '2rem'
    }
  }
}

export default styles
