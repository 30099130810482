import React, { useState } from 'react'
import * as colors from 'config/colors'
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import {
  GRAPH_PREVIOUS_PERIOD_COLOR,
  GRAPH_PREVIOUS_PERIOD_HIGHLIGHT_COLOR,
  GRAPH_REVENUE_COLOR,
  GRAPH_REVENUE_HIGHLIGHT_COLOR
} from 'constants/colors.contants'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import _ from 'lodash'
import { MAX_VIEWABLE_CHART_DATA_POINTS, DEFAULT_BAR_SALES_MAX_VALUE } from 'constants/general.constants'

const Barchart = ({
  data,
  isCompare,
  showTooltip = false,
  minTickGap = 0,
  CustomTooltip,
  showYAxis = false,
  handleClick = _.noop,
  minValue = 0,
  maxValue = DEFAULT_BAR_SALES_MAX_VALUE,
  isCompareStore = false
}) => {
  const [focusBar, setFocusBar] = useState(null)

  const newMinValue = isCompareStore ? minValue : 'auto'
  const interval = data.length > MAX_VIEWABLE_CHART_DATA_POINTS ? 2 : 0
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        barGap={0}
        onMouseMove={state => {
          if (state && state.isTooltipActive) {
            setFocusBar(state.activeTooltipIndex)
          } else {
            setFocusBar(null)
          }
        }}
      >
        {isCompare && (
          <Bar dataKey="oldRevenue" fill={GRAPH_PREVIOUS_PERIOD_COLOR} onClick={data => handleClick(data, 'old')}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                stroke={GRAPH_PREVIOUS_PERIOD_COLOR}
                fill={focusBar === index ? GRAPH_PREVIOUS_PERIOD_HIGHLIGHT_COLOR : GRAPH_PREVIOUS_PERIOD_COLOR}
              />
            ))}
          </Bar>
        )}
        {showTooltip && (
          <Tooltip {...{ content: CustomTooltip, cursor: false, isAnimationActive: false, position: { y: 0 } }} />
        )}
        <Bar dataKey="currentRevenue" fill={GRAPH_REVENUE_COLOR} onClick={data => handleClick(data, 'current')}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              stroke={GRAPH_REVENUE_COLOR}
              fill={focusBar === index ? GRAPH_REVENUE_HIGHLIGHT_COLOR : GRAPH_REVENUE_COLOR}
            />
          ))}
        </Bar>
        <ReferenceLine y={0} stroke={colors.greyLight} />
        <XAxis
          dataKey="currentTime"
          minTickGap={minTickGap}
          tick={{ fill: colors.greyLight, fontSize: '10' }}
          stroke={null}
          tickLine={false}
          interval={showTooltip ? interval : 'preserveStartEnd'}
        />

        {showYAxis && (
          <YAxis
            type="number"
            interval={0}
            domain={[newMinValue, maxValue]}
            tick={{ fill: colors.greyLight, fontSize: '10' }}
            stroke={null}
            tickLine={false}
            tickFormatter={value => {
              const displayCount =
                value < 1000 && value > -1000 ? `${numeral(value).format('$0')}` : `${numeral(value).format('$0a')}`
              return displayCount
            }}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

Barchart.prototype = {
  isCompare: PropTypes.bool,
  showTooltip: PropTypes.bool,
  CustomTooltip: PropTypes.element,
  data: PropTypes.object
}

export default React.memo(Barchart)
