import * as colors from 'config/colors'

const styles = {
  rules: {
    display: 'block',
    textAlign: 'left',
    margin: '1rem 0',
    color: colors.greyLight,
    '& > ul': {
      margin: '0 0 0.3125rem',
      padding: '0 0 0 0.9375rem',
      '& > li': {
        margin: '0 0 0.125rem',
        verticalAlign: 'text-top',
        '& > span': {
          fontSize: '0.8rem !important',
          display: 'inline !important'
        }
      }
    }
  },

  error: {
    color: colors.error
  }
}

export default styles
