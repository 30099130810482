import React from 'react'
import container from './LoaderContainer'
import LoaderUI from 'components/LoaderCommonsUI/LoaderUI'
import LoadingImage from './loading.gif'

const Loader = ({ isLoading }) => {
  return <> {isLoading && <LoaderUI logo={<img src={LoadingImage} alt="Loading..." />} />} </>
}

export default container(Loader)
