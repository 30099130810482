import base from 'styles/base.style.commonui'
import * as colors from 'config/colors'
import * as fontLoader from 'config/fontLoader'
import regular from 'assets/fonts/proximanova-regular-webfont.woff'
import regular2 from 'assets/fonts/proximanova-regular-webfont.woff'
import semibold from 'assets/fonts/proximanova-semibold-webfont.woff'
import semibold2 from 'assets/fonts/proximanova-semibold-webfont.woff'
import bold from 'assets/fonts/proximanova-bold-webfont.woff'
import bold2 from 'assets/fonts/proximanova-bold-webfont.woff'

const fontPaths = {
  regular: {
    woff: regular,
    woff2: regular2
  },
  semibold: {
    woff: semibold,
    woff2: semibold2
  },
  bold: {
    woff: bold,
    woff2: bold2
  }
}

const styles = {
  ...base,
  '@global': {
    body: {
      position: 'relative',
      backgroundColor: colors.background3
    },
    ...base['@global']
  },
  '@font-face': fontLoader.getFonts(fontPaths)
}

export default styles
