import React from 'react'
import styles from './AccountStats.styles'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'
import { STORES_TAB, USERS_TAB } from 'pages/AccountSettings/AccountSettings'
import { withRouter } from 'react-router-dom'
import { AccountSettingsStoresRoute, AccountSettingsUsersRoute } from 'Routes'

const Counts = ({ classes, count, icon, label, color }) => {
  return (
    <div className={classes['stat']}>
      <Icon name={icon} height={'1rem'} />
      {count} {label}
    </div>
  )
}

const AccountStats = ({ classes, users = [], stores = [], history, isOwner }) => {
  const userCount = users.length
  const storeCount = stores.length

  const changeTab = tabName => {
    if (tabName === 'users') {
      history.push(AccountSettingsUsersRoute.path)
    } else if (tabName === 'stores') {
      history.push(AccountSettingsStoresRoute.path)
    }
  }

  return (
    <div className={classes['wrapper']}>
      <div className={classes['stores']}>
        <Button
          variant="link"
          color={colors.cyan}
          onClick={() => {
            changeTab(STORES_TAB.value)
          }}
        >
          <Counts {...{ classes, count: storeCount, icon: 'store', label: 'Stores' }} />
        </Button>
      </div>

      {isOwner && (
        <div className={classes['users']}>
          <Button
            variant="link"
            color={colors.cyan}
            onClick={() => {
              changeTab(USERS_TAB.value)
            }}
          >
            <Counts {...{ classes, count: userCount, icon: 'user', label: 'Users' }} />
          </Button>
        </div>
      )}
    </div>
  )
}

AccountStats.prototype = {
  users: PropTypes.array.isRequired,
  stores: PropTypes.array.isRequired
}

export default withRouter(withStyles(styles)(AccountStats))
