import * as colors from 'config/colors'
import { SCREEN_UP, LARGE_SCREEN_UP } from 'config/mediaQueryHelper'

const logo = {
  display: 'flex',
  flex: '0 1 auto',
  alignItems: 'center',
  cursor: 'pointer'
}

const header = {
  width: '100%',
  zIndex: 999
}

const desktopStyles = {
  header: {
    ...header,
    height: '70px',
    display: 'flex',
    flexDirection: 'row'
  },
  wrapper: {
    display: 'flex',
    flex: '1',
    position: 'relative'
  },
  logo: {
    ...logo,
    padding: '0.875rem 0 0.875rem 1rem',
    [LARGE_SCREEN_UP()]: {
      padding: '0.875rem 0 0.875rem 2.5rem'
    }
  },
  nav: {
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    padding: '1.25rem 0 0.875rem 0',
    [SCREEN_UP()]: {
      padding: '0 1.25rem'
    }
  },
  switchAppButton: {
    display: 'flex',
    paddingTop: `1.3rem`,
    paddingRight: `1rem`,
    marginLeft: `auto`
  },
  title: {
    flex: 1
  },
  content: {
    extend: ({ override }) => ({
      width: '100%',
      ...override['content']
    })
  }
}

export const lightDesktopStyles = {
  ...desktopStyles,
  header: {
    ...desktopStyles.header,
    backgroundColor: colors.white,
    boxShadow: `0 1px 2px 0 ${colors.line1}`,
    extend: ({ override }) => ({
      ...override['header']
    })
  },
  logo: {
    ...desktopStyles.logo,
    extend: ({ override }) => ({
      ...override['logo']
    })
  },
  nav: {
    ...desktopStyles.nav,
    extend: ({ override }) => ({
      ...override['nav']
    })
  }
}

export const darkDesktopStyles = {
  ...desktopStyles,
  header: {
    ...desktopStyles.header,
    backgroundColor: colors.teal,
    boxShadow: `0 1px 2px 0 ${colors.menuBackground}`,
    extend: ({ override }) => ({
      ...override['header']
    })
  },
  logo: {
    ...desktopStyles.logo,
    extend: ({ override }) => ({
      ...override['logo']
    })
  },
  nav: {
    ...desktopStyles.nav,
    extend: ({ override }) => ({
      ...override['nav']
    })
  }
}
