import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { BREAK_POINT_LARGE, up } = mediaQueryHelper

const isDesktop = up(BREAK_POINT_LARGE)

const styles = {
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    [isDesktop]: {
      paddingBottom: '1rem'
    }
  },
  currentStore: {
    flex: [1, 1, 'auto'],
    display: 'flex'
  },
  comparedStore: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    borderTop: `1px solid ${colors.line2}`
  }
}

export default styles
