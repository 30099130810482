import React, { Component } from 'react'
import DatePicker from 'components/DatePicker/DatePicker'
import objstr from 'obj-str'
import './datepicker.css'
import { isDateOutsideRange } from '../../utils/filters.utils'
import { UI_DATE_FORMAT } from 'constants/general.constants'

class SettlementDateFilter extends Component {
  state = {
    focusedInput: null
  }

  selectDate = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      const { handleDateChange } = this.props
      handleDateChange({ startDate, endDate })
    }
  }

  render() {
    const { startDate, endDate, classes, highlight } = this.props
    const className = objstr({ [classes['dateSelector']]: true, [classes['highlight']]: highlight })

    return (
      <div className={className}>
        <DatePicker
          startDate={startDate}
          startDateId="settlemntStartDate"
          endDate={endDate}
          endDateId="settlemntEndDate"
          onDatesChange={this.selectDate}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          isOutsideRange={isDateOutsideRange}
          anchorDirection="right"
          displayFormat={UI_DATE_FORMAT}
          minimumNights={0}
        />
      </div>
    )
  }
}

export default SettlementDateFilter
