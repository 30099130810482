import React from 'react'
import withStyles from 'react-jss'
import styles from './SettlementDate.styles'
import Text from 'components/Text/Text'
import PropTypes from 'prop-types'

const DateRange = ({ from, to }) => {
  return (
    <div>
      <Text variant="h5">Date Range</Text>
      {` ${from} `}
      <Text variant="h5">to</Text>
      {` ${to}`}
    </div>
  )
}

const SingleDate = ({ date }) => {
  return (
    <div>
      <Text variant="h5">Date</Text>
      {` ${date}`}
    </div>
  )
}

const ConditionalDate = ({ from, to }) => {
  if (from !== to) {
    return <DateRange {...{ from, to }} />
  } else {
    return <SingleDate date={from} />
  }
}

const SettlementDate = ({ from, to, classes }) => {
  return (
    <div className={classes['date']} data-hj-suppress>
      <ConditionalDate {...{ from, to }} />
    </div>
  )
}

SingleDate.propTypes = {
  date: PropTypes.string.isRequired
}

DateRange.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

ConditionalDate.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

SettlementDate.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SettlementDate)
