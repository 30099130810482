import React from 'react'
import styles from './UserList.styles'
import withStyles from 'react-jss'
import Accordion from 'components/Accordion/Accordion'
import Message from 'components/Message/Message'
import ListHeader from './ListHeader'
import UserListLoader from './UserListLoader'
import AccordionHeader from './AccordionHeader'
import UserBody from './UserBody'
import { useSelector } from 'react-redux'
import { accountSlice } from 'reduxSlices'

const UserList = ({ classes, list, isLoading, searchText, handleAddStore }) => {
  const { selectors: accountSelector } = accountSlice
  const loggedInUser = useSelector(accountSelector.getUser)

  return (
    <div className={classes['userList']}>
      <div className={classes['listHeader']}>
        <ListHeader classes={classes} />
      </div>
      <div className={classes['list']}>
        {list.length === 0 && isLoading && <UserListLoader />}

        {list.length > 0 && (
          <Accordion>
            {list.map((user, index) => {
              const isSelf = loggedInUser.email === user.email

              return (
                <Accordion.Item key={index} data-testid={`accordion-item-${user.email}`}>
                  <Accordion.ItemHeader>
                    <AccordionHeader user={user} classes={classes} />
                  </Accordion.ItemHeader>
                  <Accordion.ItemPanel>
                    <UserBody user={user} isSelf={isSelf} handleAddStore={handleAddStore} isLoading={isLoading} />
                  </Accordion.ItemPanel>
                </Accordion.Item>
              )
            })}
          </Accordion>
        )}

        {!isLoading && list.length === 0 && searchText && (
          <div className={classes['errorMessage']}>
            <Message variant="error" showIcon={false} text={'No matching users found.'} />
          </div>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(UserList)
