import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  searchWrapper: {
    display: 'flex',
    paddingBottom: '2rem',
    borderBottom: `1px solid ${colors.line2}`,
    flexDirection: 'column',
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  },
  infoText: {
    padding: '0 2rem',
    width: '250px',
    textAlign: 'center',
    margin: '1rem auto 0 auto',
    [MOBILE_UP()]: {
      margin: 0,
      flexBasis: '250px'
    }
  },
  searchBox: {
    flex: [1, 1, 'auto'],
    [MOBILE_UP()]: {
      flexDirection: 'row'
    }
  }
}

export default styles
