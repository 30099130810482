const getFontFace = ({ path, fontWeight }) => {
  return {
    fontFamily: 'Proxima Nova',
    src: `url(${path.woff2}) format('woff2')`,
    fontStyle: 'normal',
    fontWeight,
    fallbacks: [{ src: `url(${path.woff}) format('woff')` }]
  }
}

export const getFonts = fontPaths => {
  return [
    getFontFace({ path: fontPaths['regular'], fontWeight: 400 }),
    getFontFace({ path: fontPaths['semibold'], fontWeight: 500 }),
    getFontFace({ path: fontPaths['semibold'], fontWeight: 500 }),
    getFontFace({ path: fontPaths['bold'], fontWeight: 700 })
  ]
}
