const styles = {
  container: {
    textAlign: 'center'
  },
  item: {
    marginBottom: '1.25rem'
  },
  divider: {
    margin: '2rem 0'
  },

  successIcon: {
    margin: '3rem 0 2rem'
  }
}

export default styles
