import React from 'react'
import PropTypes from 'prop-types'

const PrintLabel = ({ title, value }) => {
  if (!value) {
    return null
  }
  return (
    <div className="title">
      <label className="label"> {title}:</label> <b>{value} </b>
    </div>
  )
}

PrintLabel.prototype = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default PrintLabel
