import React from 'react'
import Text from 'components/Text/Text'
import { HorizontalBarChart } from 'components/common'
import { GRAPH_PREVIOUS_PERIOD_COLOR } from 'constants/colors.contants'
import PreviousDataLabel from './PreviousDataLabel'

const PreviousPeriodGraph = ({ classes, isPreviousGraphEmpty, previousGraphData }) => {
  return (
    <div className={classes['pastDataGraph']}>
      {!isPreviousGraphEmpty ? (
        <HorizontalBarChart
          {...{
            barSize: 15,
            color: GRAPH_PREVIOUS_PERIOD_COLOR,
            data: previousGraphData,
            labelPosition: 'bottom',
            Label: PreviousDataLabel
          }}
        />
      ) : (
        <Text variant="h5">There are no transactions for the previous time period.</Text>
      )}
    </div>
  )
}

export default PreviousPeriodGraph
