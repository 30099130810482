import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LARGE_SCREEN_UP, MOBILE_UP } = mediaQueryHelper

const styles = {
  storeBody: {
    padding: [0, '1rem']
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1rem', 0, '0.5rem', 0],
    [MOBILE_UP()]: {
      flexDirection: 'row',
      padding: ['0.85rem', 0, '0.5rem', 0],
      alignItems: 'stretch'
    },
    [LARGE_SCREEN_UP()]: {
      padding: ['0.85rem', 0, '0.5rem', 0]
    },
    borderBottom: ({ noBorder }) => {
      return noBorder ? 0 : `1px solid ${colors.line2}`
    },
    '& > .label': {
      flexBasis: '25px',
      display: 'flex',
      [MOBILE_UP()]: {
        flexBasis: '150px',
        marginTop: ({ alignCenter }) => (alignCenter ? '0.5rem' : 0)
      },
      marginRight: '1rem',
      '& * .icon': {
        cursor: 'pointer'
      }
    },
    '& > .content': {
      flex: [1, 1, 'auto']
    }
  },
  label: {
    display: 'flex',
    [MOBILE_UP()]: {
      flexBasis: ({ labelWidth }) => labelWidth || '160px',
      marginTop: ({ alignCenter }) => (alignCenter ? '0.5rem' : 0)
    }
  },
  headerTitle: {
    textAlign: 'left'
  },
  addUserButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${colors.line1}`,
    borderBottom: `1px solid ${colors.line1}`,
    padding: '1.25rem 0',
    margin: '1rem 0'
  }
}

export default styles
