import React from 'react'
import PackageJson from '../../../package.json'
import withStyles from 'react-jss'
import * as colors from 'config/colors'

const styles = {
  container: {
    margin: '1rem',
    textAlign: 'center',
    color: colors.cyan
  }
}

const Version = ({ classes }) => {
  return (
    <div className={classes.container}>
      <h5>Version: {PackageJson.version}</h5>
    </div>
  )
}

export default withStyles(styles)(Version)
