import appConfig from 'utils/appConfig'
import spaLogin from 'utils/spaLogin'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

const FEATURE_FLAG_CARDS_USED = 'CardsUsedDashboardFeatureEnabled'
const FEATURE_FLAG_MONTH_COMPARISON = 'MonthComparisonDashboardFeatureEnabled'
const FEATURE_FLAG_TRANSACTION_MONTH_SEARCH = 'MonthLimitTransactionSearchFeatureEnabled'
const FEATURE_FLAG_SERVER_SIDE_PAGINATION = 'ServerSidePaginationEnabled'

const ENVS = ['local', 'dev', 'lte', 'cte']

const setConfigs = () => {
  const { InsightsConfig } = window
  spaLogin.setConfig(InsightsConfig)
  appConfig.setConfig(InsightsConfig)
}

const getConfig = () => {
  return appConfig.getConfig()
}

const getFeatureFlag = feature => {
  const { features, environment } = getConfig()

  if (features && features[feature]) {
    return true
  }

  const testEnv = find(ENVS, env => env === environment)

  if (testEnv) {
    const featuresParams = getParameterByName('features')

    if (featuresParams) {
      const hasParamFeature = find(featuresParams.split(','), featuresParam => featuresParam === feature)

      if (hasParamFeature) {
        return true
      }
    }

    const featureCookies = getFeaturesFromCookies()

    if (!isEmpty(featureCookies)) {
      const hasCookieFeature = find(featureCookies, featureCookie => featureCookie === feature)

      if (hasCookieFeature) {
        return true
      }
    }
  }

  return false
}

const getFeaturesFromCookies = () => {
  const cookieName = 'features'
  const cookiesArray = document.cookie.split(';')

  let i
  let x
  let y

  for (i = 0; i < cookiesArray.length; i++) {
    x = cookiesArray[i].substr(0, cookiesArray[i].indexOf('='))
    y = cookiesArray[i].substr(cookiesArray[i].indexOf('=') + 1)
    x = x.replace(/^\s+|\s+$/g, '')

    if (x === cookieName) {
      return decodeURI(y).split(',')
    }
  }

  return null
}

const getParameterByName = name => {
  const url = window.location.href
  const cleanName = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) {
    return null
  }

  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export {
  setConfigs,
  getConfig,
  getFeatureFlag,
  FEATURE_FLAG_CARDS_USED,
  FEATURE_FLAG_MONTH_COMPARISON,
  FEATURE_FLAG_TRANSACTION_MONTH_SEARCH,
  FEATURE_FLAG_SERVER_SIDE_PAGINATION
}
