import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './DashboardCard.styles'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import Button from 'components/Button/Button'

const iconProps = {
  width: '1em',
  height: '1em'
}

const Header = ({ classes, title = 'Title', iconName = 'customer', isResizeable, isExpanded, onResize }) => {
  const resizeIcon = isExpanded ? 'contract' : 'expand'
  return (
    <div className={classes['header']}>
      <div className={classes['title']}>
        <div className={classes['titleText']}>
          <Text variant="h5"> {title} </Text>
          {iconName && <Icon {...{ ...iconProps, name: iconName }} />}
        </div>

        {isResizeable && (
          <div className={classes['resizeIcon']}>
            <Button variant="link" onClick={() => onResize(!isExpanded)}>
              <Icon {...{ ...iconProps, name: resizeIcon, color: colors.icon }} />{' '}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const DashboardCard = ({
  children,
  classes,
  title,
  iconName,
  isResizeable = true,
  isExpanded = false,
  onResize,
  isHeaderVisible = true
}) => {
  return (
    <div className={classes['card']}>
      {isHeaderVisible && <Header {...{ classes, title, iconName, isResizeable, isExpanded, onResize }} />}
      <div className={classes['cardContent']}>{children}</div>
    </div>
  )
}

DashboardCard.prototype = {
  children: PropTypes.any,
  showHeaderBorder: PropTypes.bool
}

export default withStyles(styles)(DashboardCard)
