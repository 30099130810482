import axios from 'axios'
import { setConfigs, getConfig } from 'utils/config.utils'
import { ErrorPageRoute } from 'Routes'
import { startLoader, stopLoader } from '../utils/api.utils'
import { logout } from 'utils/logout.utils'

setConfigs()

const HTTP_STATUS_UNAUTHORIZED = 401
const HTTP_SERVER_ERROR_START = 500
const HTTP_SERVER_TIMEOUT = 504
const HTTP_SERVER_ERROR_END = 511

const { apiUrl } = getConfig()

const baseURL = process.env.REACT_APP_USE_MOCK_API ? process.env.REACT_APP_MOCK_API_BASE_URL : apiUrl

const instance = axios.create({
  baseURL
})

instance.interceptors.request.use(
  function(config) {
    startLoader(config)
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

const isServerError = status => {
  if (status >= HTTP_SERVER_ERROR_START && status <= HTTP_SERVER_ERROR_END) {
    return true
  }

  return false
}

// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    stopLoader(response)
    return response.data
  },
  function(error) {
    const { response } = error
    const { status } = response

    stopLoader(response)

    if (status === HTTP_STATUS_UNAUTHORIZED) {
      logout()
    } else if (status === HTTP_SERVER_TIMEOUT) {
      return 504
    } else if (isServerError(status)) {
      window.location.href = ErrorPageRoute.path
    }

    return Promise.reject(error)
  }
)

export default instance
