import React, { useState, useRef, useEffect } from 'react'
import { storesSlice, usersSlice } from 'reduxSlices'
import { useSelector } from 'react-redux'
import StoreList from './StoreList'
import styles from './StoreSettings.styles'
import withStyles from 'react-jss'
import { useDispatch } from 'react-redux'
import { doFuzzySearch } from 'utils/filters.utils'
import _ from 'lodash'
import { SearchBar } from 'components/common'
import AddUserToStore from './AddUserToStore'
import useLoggedInUser from 'hooks/useLoggedInUser'
import { ROLE_OWNER, ROLE_GLOBAL_VIEWER } from 'constants/general.constants'
import { AddStoreRoute } from 'Routes'
import Button from 'components/Button/Button'
import { greyDark } from '../../config/colors'
import Text from '../Text/Text'
const StoreSettings = ({ routerProps, classes }) => {
  const [filteredStores, setFilteredStores] = useState([])
  const [text, setText] = useState('')

  const { actions: storesActions } = storesSlice
  const dispatch = useDispatch()

  const { selectors: storesSelectors } = storesSlice
  const { selectors: usersSelectors } = usersSlice
  const isLoading = useSelector(storesSelectors.isLoading)
  const stores = useSelector(storesSelectors.getAllStores)
  const { role } = useLoggedInUser()
  const [showAddStoreToUserScreen, setShowAddStoreToUserScreen] = useState(false)
  const ref = useRef(0)
  const [currentStore, setCurrentStore] = useState()
  const usersUpdated = useSelector(usersSelectors.usersUpdated)
  const { history } = routerProps

  const onSearch = text => {
    setText(text)
  }

  const onClick = () => {
    history.push(AddStoreRoute.path)
  }

  const onShowAddScreen = store => {
    setCurrentStore(store)
    ref.current = window.scrollY
    setShowAddStoreToUserScreen(true)
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }

  const onShowStoreList = () => {
    setShowAddStoreToUserScreen(false)
    setTimeout(() => {
      window.scrollTo({
        top: ref.current,
        behavior: 'auto'
      })
    })
  }

  useEffect(() => {
    dispatch(storesActions.fetchAllStores())
  }, [storesActions, dispatch])

  useEffect(() => {
    if (usersUpdated) {
      dispatch(storesActions.fetchAllStores())
    }
  }, [usersUpdated, storesActions, dispatch])

  useEffect(() => {
    const filteredStores = doFuzzySearch(stores, text, {
      keys: ['preferredName', 'tradingName', 'cardAcceptorIdCode']
    })
    setFilteredStores(filteredStores)
  }, [stores, text])

  const infoText =
    role === ROLE_OWNER || role === ROLE_GLOBAL_VIEWER
      ? `There are ${stores.length} stores set up for this Organisation`
      : undefined

  const placeholder = 'Search Stores by preferred name, business name, merchant number.'

  return (
    <div className={classes['container']}>
      <div style={{ display: showAddStoreToUserScreen ? 'none' : 'block' }}>
        <SearchBar onSearch={_.debounce(onSearch, 500)} text={infoText} placeholder={placeholder} />

        <div className={classes['actionBtn']}>
          <Button variant="secondary" onClick={onClick} width={'150px'}>
            Add New Store
          </Button>
        </div>

        <StoreList
          list={filteredStores}
          isLoading={isLoading}
          searchText={text}
          handleAddUserToStore={onShowAddScreen}
        />
      </div>

      <div className={classes['infoMessage']}>
        <Text color={greyDark}> In Insights, the stores you will see are those that you have manually added.</Text>
      </div>
      <div>
        <Text color={greyDark}>
          <span>
            {' '}
            In Worldview, the stores you see are those that we have identified as relating to your business' legal name.
          </span>
        </Text>
      </div>

      {showAddStoreToUserScreen && (
        <div>
          <AddUserToStore handleBack={onShowStoreList} store={currentStore} />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(StoreSettings)
