import React from 'react'
import { NotificationModal } from 'components/common'
import Text from 'components/Text/Text'
import Icon from 'components/Icon/Icon'
import * as colors from 'config/colors'
import withStyles from 'react-jss'
import styles from './InviteUserForm.styles'

const InviteSuccessModal = ({ classes, onClose, user }) => {
  const { firstName, lastName } = user
  return (
    <NotificationModal
      {...{
        onClose
      }}
    >
      <div data-hj-suppress>
        <div className={classes['successIcon']}>
          <Icon name="check-circle" color={colors.icon} width={'3.5em'} />
        </div>

        <div className={classes['subtitle']}>
          <Text variant="h5">{firstName} has been invited</Text>
        </div>
        <div className={classes['note']}>
          <Text variant="note">
            We have invited {firstName} {lastName} via email. This invitation will be valid for 24 hours.
          </Text>
        </div>
        <div className={classes['note']}>
          <Text variant="note">
            If your staff has not received an email from us, please ask them to check their junk folder.
          </Text>
        </div>
      </div>
    </NotificationModal>
  )
}

export default withStyles(styles)(InviteSuccessModal)
