import React, { Component } from 'react'
import spaLogin from 'utils/spaLogin'
import Logo from 'components/Logo/Logo'
import LoadingPage from 'components/LoadingPage/LoadingPage'
import container from './routeContainer'
import _ from 'lodash'
import { setPrivateToken } from 'utils/token.utils'
import getAllowedRoute from './RouteGuards'
import { addUrlInLocalStorage, getUrlFromLocalStorage, removeUrlFromLocalStorage } from 'utils/localStorage.utils'
import apis from 'api'
import mixpanelData from 'utils/mixpanelObjects.utils'
import { EVENT_NAME_LOGIN, EVENT_VIEWEDPAGE } from 'constants/mixpanel.constants'
import { DashboardRoute } from 'Routes'
const InsightsLogo = () => <Logo name="insights" />

class PrivateRoute extends Component {
  state = {
    isLoggedIn: false,
    path: undefined
  }

  UNSAFE_componentWillMount() {
    this.checkLogin()
  }

  onLogin = async user => {
    const { actions } = this.props
    actions.setUser(user)
    await setPrivateToken()
    await actions.fetchMerchantAccess()
    this.setState({ isLoggedIn: true })
  }

  checkLogin() {
    const isLogin = this.hasAccessTokenURL(window.location.href)
    const setIsLoggedIn = async user => {
      if (!_.isEmpty(user)) {
        this.onLogin(user)
      }

      const storedUrl = getUrlFromLocalStorage()
      removeUrlFromLocalStorage()
      const currentUrl = window.location.href
      if (storedUrl && currentUrl !== storedUrl) {
        window.location.href = storedUrl
      }
      this.sendMixpanelLoginData(user, isLogin)
    }
    addUrlInLocalStorage(window.location.href)
    spaLogin.startLoginCheck(setIsLoggedIn)
  }

  sendMixpanelLoginData(user, isLogin) {
    if (_.isEmpty(user)) {
      return
    }
    if (isLogin) {
      const { Mixpanel: mixpanel } = apis
      const mixpanelLoginData = mixpanelData.loginData(user)
      const mixpanelPeopleSetData = mixpanelData.mixpanelPeopleSetData(user)
      const mixpanelRegisterData = mixpanelData.mixpanelRegisterData(user)
      // Identify every login
      mixpanel.identify(user.email)
      mixpanel.peopleSet(mixpanelPeopleSetData)
      mixpanel.register(mixpanelRegisterData)
      // send Login mixpanel data upon redirect from account spa
      mixpanel.track(EVENT_NAME_LOGIN, mixpanelLoginData)
    }
  }

  hasAccessTokenURL(url) {
    return url.indexOf('access_token') > -1
  }

  sendRouteMixpanelData(params) {
    const routeMixpaneldata = mixpanelData.viewedPageData(params)
    const { Mixpanel: mixpanel } = apis
    mixpanel.track(EVENT_VIEWEDPAGE, routeMixpaneldata)
  }

  render() {
    const {
      component: Component,
      user,
      banner,
      actions,
      key,
      merchants = [],
      isMerchantsLoading,
      showBack = false,
      ...rest
    } = this.props

    const { isLoggedIn } = this.state
    const isMerchantsLoaded = !isMerchantsLoading

    const showLoadingPage = !isLoggedIn || !isMerchantsLoaded

    if (showLoadingPage) return <LoadingPage logo={<InsightsLogo />} />

    const userData = { user, banner }
    const userActions = actions
    const { path } = rest
    const componentProps = { userData, userActions, showBack }

    if (path !== this.state.path && path !== DashboardRoute.path) {
      // make sure that we are not sending to mixpanel twice
      // dashboard route is handled separately
      this.setState({ path: path })
      this.sendRouteMixpanelData(path)
    }

    return getAllowedRoute({ isLoggedIn, merchants, path, rest, componentProps, Component })
  }
}
export default container(PrivateRoute)
