import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import oeJSS from './Input-oe.styles'
import defaultJSS from './Input.styles'
import TextMask from 'react-text-mask'
import getMaskProps from './MaskHelper'
import Icon from '../Icon/Icon'
import ToolTip from '../ToolTip/ToolTip'
import Message from '../Message/Message'
import Label from '../Label/Label'

/**
 * Simple Input component
 */
const BaseInput = ({
  classes,
  value,
  onChange,
  name,
  onBlur,
  mask,
  label,
  note,
  errorMessage,
  onClear,
  children,
  toolTip,
  customMaskProps,
  clearIcon,
  helpIcon,
  icon,
  preset,
  override,
  ...restParams
}) => {
  const inputProps = {
    className: errorMessage ? classes['input-error'] : classes['input'],
    name,
    id: name, // Used for label
    value,
    onChange,
    onBlur,
    ...restParams
  }

  const maskProps = customMaskProps ? customMaskProps : getMaskProps(mask)
  return (
    <div className={classes['group']}>
      {label && <Label htmlFor={name} preset={preset} label={label} note={note} />}
      {icon && <div className={classes['icon']}> {icon} </div>}
      {maskProps ? <TextMask {...maskProps} {...inputProps} /> : <input type="text" {...inputProps} />}
      {!!value && onClear && (
        <span className={classes['clear']} onClick={onClear} data-testid="clearBtn">
          {clearIcon}
        </span>
      )}
      {toolTip && (
        <div className={classes['help']}>
          <ToolTip text={toolTip}>{helpIcon}</ToolTip>
        </div>
      )}
      <div />
      {errorMessage && (
        <div className={classes['error-message']}>
          <Message text={errorMessage} variant={'error'} showIcon={false} />
        </div>
      )}

      {children}
    </div>
  )
}

const DefaultInput = withStyles(defaultJSS)(BaseInput)

const OeInput = withStyles(oeJSS)(BaseInput)

const Input = props => {
  switch (props.preset) {
    case 'oe':
      return <OeInput {...props} />
    default:
      return <DefaultInput {...props} />
  }
}

Input.propTypes = {
  override: PropTypes.shape({
    input: PropTypes.object,
    'input:focus': PropTypes.object,
    'input::placeholder': PropTypes.object,
    'input:disabled': PropTypes.object,
    'input-error': PropTypes.object,
    clear: PropTypes.object,
    'clear:hover': PropTypes.object,
    help: PropTypes.object,
    'help svg': PropTypes.object,
    'error-message': PropTypes.object,
    'error-message p': PropTypes.object,
    'label-error': PropTypes.object,
    group: PropTypes.object
  }),

  clearIcon: PropTypes.element,
  helpIcon: PropTypes.element,

  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  /**
   * Pass the label to be shown
   */
  label: PropTypes.string,
  /**
   * Pass the note text to be shown along with the label
   */
  note: PropTypes.string,

  name: PropTypes.string,

  /**
   * Type of Mask. This will apply the default mask for the available type. You can use the customMaskProps to pass in Custom mask Syntax
   */
  mask: PropTypes.oneOf([false, 'email', 'phone', 'currency', 'time', 'time24']),

  /**
   * Custom mask format
   */
  customMaskProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

  /***
   *
   */
  errorMessage: PropTypes.string,

  /**
   * The clear button will be showed when a function handler is passed
   */
  onClear: PropTypes.func,

  /**
   * Use this when you want to display any additional element with the Input Eg: Help messages
   */
  children: PropTypes.element,

  /**
   * Tooltip
   */
  toolTip: PropTypes.string,

  preset: PropTypes.oneOf(['default', 'oe'])
}

Input.defaultProps = {
  mask: false,
  errorMessage: undefined,
  override: {},
  clearIcon: <Icon name="cross-circle" width="0.7em" height="0.7em" />,
  helpIcon: <Icon name="help" width="0.7em" height="0.7em" />,
  preset: 'default'
}

export default Input
