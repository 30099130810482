import React from 'react'
import withStyles from 'react-jss'
import objstr from 'obj-str'
import PropTypes from 'prop-types'
import Icon from 'components/Icon/Icon'
import Divider from '../Divider/Divider'
import styles from './ModalBody.styles'
import { icon } from 'config/colors'

const ModalBody = ({
  title,
  classes,
  children,
  showCloseIcon,
  onClose,
  size,
  override,
  dataTestId,
  closeIconColor
}) => {
  const container = objstr({
    [classes['small']]: 'small' === size,
    [classes['medium']]: 'medium' === size,
    [classes['large']]: 'large' === size,
    [classes['custom']]: 'custom' === size
  })

  return (
    <div className={container} data-testid={dataTestId}>
      <div className={classes.header}>
        {title && (
          <div className={classes.title}>
            {title}
            <Divider />
          </div>
        )}
        {showCloseIcon && (
          <div className={classes.closeIcon} onClick={onClose}>
            <Icon name="clear" width={'2em'} height={'2em'} color={closeIconColor || icon} />
          </div>
        )}
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  )
}

ModalBody.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'custom']),
  closeIconColor: PropTypes.string
}

ModalBody.defaultProps = {
  title: undefined,
  onClose: undefined,
  size: 'small'
}

export default withStyles(styles)(ModalBody)
