import axios from './axiosDefaults'
import handleError from 'utils/error.utils'
import { populateDisplayName } from 'utils/stores.utils'

const INSIGHTS_MERCHANT = `/insights/merchant/`

export const signUpStore = async cardAcceptorIdCode => {
  try {
    return await axios.post(INSIGHTS_MERCHANT, { cardAcceptorIdCode })
  } catch (error) {
    handleError(error)
  }
}

export const getAllStores = async () => {
  try {
    let response = await axios.get(INSIGHTS_MERCHANT)
    // Set display name which is a UI only property used throughout the app
    return response.map(item => {
      return populateDisplayName(item)
    })
  } catch (error) {
    handleError(error)
  }
}

export const getSingleStore = async cardAcceptorIdCode => {
  try {
    let response = await axios.get(INSIGHTS_MERCHANT + cardAcceptorIdCode, { params: { expand: '_users' } })
    // Set display name which is a UI only property used throughout the app
    return populateDisplayName(response)
  } catch (error) {
    handleError(error)
  }
}

export const updateStore = async store => {
  delete store._userCount
  delete store._users
  delete store.displayName
  delete store.storeSuffix

  try {
    let response = await axios.put(INSIGHTS_MERCHANT + store.cardAcceptorIdCode, { ...store })
    // Set display name which is a UI only property used throughout the app
    return populateDisplayName(response)
  } catch (error) {
    handleError(error)
  }
}
