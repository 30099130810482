import React, { useState, useEffect } from 'react'
import Modal from 'components/Modal/Modal'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import withStyles from 'react-jss'
import styles from './MultiStoreDownloadModal.styles'

const MultiStoreDownloadModal = ({ classes, modalOpen, proceedHandler, cancelHandler }) => {
  const [isModalOpen, setModalOpen] = useState(modalOpen)

  useEffect(() => {
    setModalOpen(modalOpen)
  }, [modalOpen])

  const toggleModal = isModalOpen => {
    setModalOpen(isModalOpen)
  }

  const closeModal = () => {
    cancelHandler()
    toggleModal(false)
  }

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={false}
        showCloseIcon={true}
        isOpen={isModalOpen}
        onClose={closeModal}
        size={'small'}
      >
        <br></br>
        <div>
          <Text>
            {' '}
            Two or more stores have been selected. It may take 3-5 minutes for the download to finish depending on the
            number of transactions. Thank you for your patience.
          </Text>
        </div>
        <br />
        <div className={classes['container']}>
          {' '}
          <Button style={{ marginRight: '1rem' }} variant="secondary" onClick={cancelHandler} width={'150px'}>
            Cancel
          </Button>
          <Button variant="primary" onClick={proceedHandler} width={'150px'}>
            Proceed
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default withStyles(styles)(MultiStoreDownloadModal)
