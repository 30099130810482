import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { LARGE_SCREEN_UP, LAPTOP_UP, MOBILE_UP } = mediaQueryHelper

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  dateSection: {
    display: 'flex',
    border: `1px solid ${colors.line2}`,
    borderLeft: 0,
    borderRight: 0,
    flexDirection: 'column',
    padding: ['1rem', 0],
    [MOBILE_UP()]: {
      flexDirection: 'row',
      padding: '0.85rem 0'
    }
  },
  storeSection: {
    display: 'flex',
    padding: ['1rem', 0],
    flexDirection: 'column',
    [MOBILE_UP()]: {
      padding: '0.85rem 0',
      flexDirection: 'row'
    }
  },
  label: {
    flexBasis: '25px',
    [MOBILE_UP()]: {
      flexBasis: '140px',
      marginTop: '0.5rem'
    },
    [LARGE_SCREEN_UP()]: {
      flexBasis: '200px'
    }
  },
  content: {
    flex: [1, 1, 'auto']
  },
  actions: {
    padding: ['1.5rem', 0, '1rem', '1rem'],
    margin: '0 auto',
    textAlign: 'center',
    '& .button': {
      width: '200px',
      display: 'inline-block'
    },
    [MOBILE_UP()]: {
      margin: '0',
      '& >button': {
        float: 'right'
      },
      '& .button': {
        float: 'right'
      }
    },
    [LAPTOP_UP()]: {
      padding: ['1.5rem', 0, '1rem', 0]
    }
  },
  formInputs: {
    [mediaQueryHelper.LAPTOP_UP()]: {
      padding: [0, '2em']
    },
    padding: [0, '1em']
  },
  mulitSelectAnouncement: {
    paddingTop: '10px',
    paddingBottom: '0px',
    color: colors.lightBlue,
    fontSize: '18px'
  },
  csvdownload: {
    marginTop: '20px'
  },
  messageErrorRow: {
    marginTop: '10px'
  }
}

export default styles
