import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../api'
import { createCustomSelector } from 'utils/common.utils'

const initialState = {
  merchant: undefined,
  terminal: undefined,
  error: false,
  isLoading: false,
  is504: false,
  filter: undefined,
  isShowResult: false
}

const getTerminalSummary = createCustomSelector('settlement.terminal')
const getMerchantSummary = createCustomSelector('settlement.merchant')
const isLoading = createCustomSelector('settlement.isLoading')
const is504 = createCustomSelector('settlement.is504')
const getFilter = createCustomSelector('settlement.filter')
const isShowResult = createCustomSelector('settlement.isShowResult')

const selectors = {
  getTerminalSummary,
  getMerchantSummary,
  isLoading,
  is504,
  getFilter,
  isShowResult
}

const reducers = {
  setMerchantSummary: (state, action) => {
    return {
      ...state,
      merchant: action.payload,
      isLoading: false,
      isShowResult: true
    }
  },
  fetchMerchantSummary: (state, action) => {
    const { payload } = action
    return { ...state, filter: payload, isLoading: true, isShowResult: true }
  },
  setTerminalSummary: (state, action) => {
    const { payload } = action
    return {
      ...state,
      terminal: payload,
      isLoading: false,
      isShowResult: true
    }
  },
  fetchTerminalSummary: (state, action) => {
    const { payload } = action
    return { ...state, filter: payload, isLoading: true, isShowResult: true }
  },
  startLoading: state => {
    return { ...state, isLoading: true }
  },
  set504: (state, action) => {
    return { ...state, is504: action.payload }
  },
  endLoading: state => {
    return { ...state, isLoading: false }
  },
  resetSummary: state => {
    return { ...state, filter: undefined, isLoading: false }
  },
  hideResult: state => {
    return { ...state, isShowResult: false }
  }
}

const settlementSlice = createSlice({
  slice: 'settlement',
  initialState: initialState,
  reducers
})

function* fetchMerchantSummary(action) {
  const { payload } = action
  const settlements = yield call(api.getMerchantSettlements, payload)

  if (settlements === 504) {
    yield put(settlementSlice.actions.set504(true))
  } else {
    yield put(settlementSlice.actions.setMerchantSummary(settlements))
  }
}

function* fetchTerminalSummary(action) {
  const { payload } = action
  const settlements = yield call(api.getTerminalSettlements, payload)

  if (settlements === 504) {
    yield put(settlementSlice.actions.set504(true))
  } else {
    yield put(settlementSlice.actions.setTerminalSummary(settlements))
  }
}

function* settlementSagas() {
  yield takeLatest(settlementSlice.actions.fetchMerchantSummary.toString(), fetchMerchantSummary)
  yield takeLatest(settlementSlice.actions.fetchTerminalSummary.toString(), fetchTerminalSummary)
}

export default {
  ...settlementSlice,
  selectors,
  sagas: settlementSagas
}
