import * as colors from 'config/colors'

const styles = {
  label: {
    textAlign: 'left',
    marginBottom: '0.25rem',
    '& >label': {
      fontSize: '0.75rem',
      color: colors.greyLight,
      letterSpacing: '0.0625rem',
      textTransform: 'uppercase',
      fontWeight: 500,
      lineHeight: 1,
      height: '1rem',
      marginRight: '0.5rem',
      '&:hover ': {
        cursor: 'pointer'
      }
    }
  },
  'label-error': {
    composes: '$label',
    color: colors.error,
    extend: ({ override }) => ({
      ...override['label-error']
    })
  }
}

export default styles
