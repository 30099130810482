import axios from 'axios'
import { stopLoader } from '../utils/api.utils'
import { setConfigs, getConfig } from 'utils/config.utils'
import { logout } from 'utils/logout.utils'

setConfigs()

const HTTP_STATUS_UNAUTHORIZED = 401

const { apiUrl } = getConfig()

const baseURL = process.env.REACT_APP_USE_MOCK_API ? process.env.REACT_APP_MOCK_API_BASE_URL : apiUrl

const instance = axios.create({
  baseURL
})
// Add a response interceptor
instance.interceptors.response.use(
  function(response) {
    stopLoader(response)
    return response.data
  },
  function(error) {
    const { response } = error
    const { status } = response

    stopLoader(response)

    if (status === HTTP_STATUS_UNAUTHORIZED) {
      logout()
    }

    return Promise.reject(error)
  }
)

export default instance
