import React, { isValidElement, Children, cloneElement } from 'react'
import { useAccordionContext } from './AccordionContext'

const ItemPanel = ({ children, isOpen, toggleOpen }) => {
  const { classes } = useAccordionContext()

  const renderChildren = () => {
    if (isValidElement(children)) {
      return Children.map(children, child => {
        return cloneElement(child, {
          toggleOpen
        })
      })
    }

    return React.createElement('div', {}, children)
  }

  if (isOpen) {
    return <div className={classes['accordion-item-panel']}>{renderChildren()}</div>
  }

  return null
}

export default ItemPanel
