import React, { Component } from 'react'
import { styles, popoverMenuLibStyles } from './PopoverMenu.styles'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import * as TinyPopover from 'react-tiny-popover'

class PopoverMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPopoverOpen: false
    }
    this.popoverRef = React.createRef()
    this.menuRef = React.createRef()
  }

  onAction = (isOpen, action) => {
    this.setState({
      isPopoverOpen: isOpen
    })

    if (action) {
      action()
    }
  }

  openWithDelay = (shouldOpen, isOpen, timeout) => {
    setTimeout(() => {
      shouldOpen && this.onAction(isOpen)
    }, timeout)
  }

  onKeyDown = (isOpen, event) => {
    if (event.key === 'Enter') {
      this.setState({
        isPopoverOpen: isOpen
      })
    } else if (event.key === 'ArrowDown') {
      this.setState(
        {
          isPopoverOpen: true
        },
        async () => (await this.state.isPopoverOpen) && this.menuRef.current && this.menuRef.current.focus()
      )
    }
  }

  scrollListner = () => {
    if (this.state.isPopoverOpen === false) {
      return
    }

    this.setState({
      isPopoverOpen: false
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListner)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListner)
  }

  render() {
    const {
      classes,
      menuItems,
      position,
      children,
      activeLink,
      mouseEnter,
      timeout,
      popoverContentDestinationId
    } = this.props
    const { isPopoverOpen } = this.state

    const body = (
      <div className={classes['popoverContent']}>
        <div className={classes['menuList']}>
          {menuItems.map((item, index) => {
            const { name, onClick, icon } = item
            const clazz = name === activeLink ? 'activeMenuItem' : 'menuItem'
            const action = () => {
              if (clazz === 'activeMenuItem') {
                this.setState({
                  isPopoverOpen: false
                })
                return
              }
              this.onAction(false, onClick)
            }

            const onKeyDown = event => {
              if (event.key === 'Enter') {
                action()
              } else if (event.key === 'ArrowDown' && document.activeElement.nextSibling) {
                document.activeElement.nextSibling.focus()
              } else if (event.key === 'ArrowUp') {
                if (document.activeElement.previousSibling) {
                  document.activeElement.previousSibling.focus()
                } else {
                  this.popoverRef.current && this.popoverRef.current.focus()
                  this.setState({ isPopoverOpen: false })
                }
              }
            }

            return (
              <div
                onClick={action}
                className={classes[clazz]}
                key={name}
                tabIndex={0}
                onKeyDown={onKeyDown}
                ref={index === 0 && this.menuRef}
              >
                {icon} {name}
              </div>
            )
          })}
        </div>
      </div>
    )

    const PopoverElem = TinyPopover.default
    const { ArrowContainer } = TinyPopover

    const PopoverContent = ({ position, targetRect, popoverRect }) => {
      const { arrowColor, arrowSize, arrowStyle, padding } = popoverMenuLibStyles
      const arrowcontinerProps = { position, arrowColor, arrowSize, arrowStyle, padding }
      return (
        <ArrowContainer position={position} targetRect={targetRect} popoverRect={popoverRect} {...arrowcontinerProps}>
          <ArrowContainer position={position} targetRect={targetRect} popoverRect={popoverRect} {...arrowcontinerProps}>
            {body}
          </ArrowContainer>
        </ArrowContainer>
      )
    }

    const calculatePopoverPosition = ({ targetRect, popoverRect, position, align, nudgedLeft, nudgedTop }) => {
      return { top: 50, left: 0 }
    }

    return (
      <PopoverElem
        isOpen={isPopoverOpen}
        position={position}
        contentDestination={popoverContentDestinationId ? document.getElementById(popoverContentDestinationId) : null}
        contentLocation={calculatePopoverPosition}
        onClickOutside={() => this.onAction(false)}
        content={PopoverContent}
        containerStyle={{ zIndex: 999 }}
        containerClassName={classes['popoverMenuContainer']}
      >
        <div
          onClick={() => this.onAction(!isPopoverOpen, this.popoverRef.current && this.popoverRef.current.blur())}
          onMouseEnter={() => this.openWithDelay(mouseEnter, true, timeout)}
          onKeyDown={e => this.onKeyDown(!isPopoverOpen, e)}
          tabIndex={0}
          ref={this.popoverRef}
        >
          {children}
        </div>
      </PopoverElem>
    )
  }
}

PopoverMenu.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  mouseEnter: PropTypes.bool,
  timeout: PropTypes.number,
  /**
   * Shape of theme expected. Any additional classes will be ignored.t
   */
  override: PropTypes.shape({
    popoverContent: PropTypes.object,
    menuList: PropTypes.object,
    menuItem: PropTypes.object,
    activeMenuItem: PropTypes.object,
    popoverMenuContainer: PropTypes.object
  })
}

PopoverMenu.defaultProps = {
  position: 'bottom',
  override: {},
  timeout: 0
}

export default withStyles(styles)(PopoverMenu)
