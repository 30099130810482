import React from 'react'
import withStyles from 'react-jss'
import styles from './FieldSection.styles'
import Text from 'components/Text/Text'

const FieldSection = ({ classes, title, textVariant, textOverride, infoIcon, children }) => {
  const textVariantValue = !textVariant ? 'h5' : textVariant
  const textOverrideValue = !textOverride ? {} : textOverride

  return (
    <div className={classes['section']}>
      <div className={classes['label']}>
        <Text override={textOverrideValue} variant={textVariantValue}>
          {title}
        </Text>
        <div className={classes['infoIcon']}>{infoIcon}</div>
      </div>

      <div className={classes['content']}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(FieldSection)
