import * as colors from 'config/colors'
import { standardFontFamily, weightSemiBold } from 'config/jss-vars'

const link = {
  padding: '0.75em',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: standardFontFamily,
  fontSize: '1rem',
  fontWeight: weightSemiBold,
  color: ({ preset }) => (preset === 'dark' ? colors.white : colors.cyan),
  '&:hover': {
    background: ({ preset }) => (preset === 'dark' ? colors.active : colors.highlight),
    color: colors.teal,
    '& * path, * polygon, * rect': {
      fill: colors.teal
    }
  },
  '&:active': {
    background: ({ preset }) => (preset === 'dark' ? colors.backgroundPressed : colors.active),
    color: colors.teal,
    '& * path, * polygon, * rect': {
      fill: colors.teal
    }
  }
}

export const jss = {
  list: {
    listStyle: 'none',
    padding: 0,
    extend: ({ override }) => ({
      ...override['list']
    })
  },
  link: {
    ...link,
    extend: ({ override }) => ({
      ...override['link']
    })
  },
  switchAppButton: {
    display: 'flex',
    paddingRight: `1rem`,
    marginTop: `-2.5rem`,
    justifyContent: 'flex-end'
  },
  activeLink: {
    ...link,
    background: ({ preset }) => (preset === 'dark' ? colors.active : colors.highlight),
    color: colors.teal,
    '& * path, * polygon, * rect': {
      fill: colors.teal
    }
  },
  listIcon: {
    paddingRight: '0.5rem',
    paddingTop: '1px',
    width: '18px',
    height: '18px',
    extend: ({ override }) => ({
      ...override['icon']
    })
  },
  menuIcon: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    extend: ({ override }) => ({
      ...override['menuIcon']
    })
  },
  menuContent: {
    background: colors.white,
    padding: 0,
    overflow: 'hidden'
  },
  itemList: {
    padding: 0,
    marginTop: ({ showSwitchApp }) => (showSwitchApp ? '3.5rem' : '1.5rem')
  }
}

export const libStyles = {
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(4, 81, 65, 0.8)'
  }
}
