import { createSlice } from 'redux-starter-kit'
import { call, put, takeLatest } from 'redux-saga/effects'
import api from '../api'
import { dashboardSlice } from 'reduxSlices'
import { createCustomSelector } from 'utils/common.utils'

const {
  InsightsConfig: {
    notificationBanner: { show, message }
  }
} = window

const initialState = {
  user: undefined,
  isAccountRegistered: false,
  isAccountVerified: false,
  banner: {
    isVisible: show,
    message: message
  },
  poll: {
    isVisible: false
  },
  merchantAccess: undefined,
  isLoading: false,
  isMerchantsLoading: true
}

const getUser = createCustomSelector('account.user')
const isAccountRegistered = createCustomSelector('account.isAccountRegistered')
const isAccountVerified = createCustomSelector('account.isAccountVerified')
const getBanner = createCustomSelector('account.banner')
const getPoll = createCustomSelector('account.poll')
export const getMerchantAccess = createCustomSelector('account.merchantAccess')
const isLoading = createCustomSelector('account.isLoading')
const isMerchantsLoading = createCustomSelector('account.isMerchantsLoading')

const selectors = {
  isAccountRegistered,
  isAccountVerified,
  getBanner,
  getUser,
  getPoll,
  getMerchantAccess,
  isLoading,
  isMerchantsLoading
}

const reducers = {
  accountRegistered: state => {
    return {
      ...state,
      isAccountRegistered: true
    }
  },
  accountVerified: state => {
    return {
      ...state,
      isAccountVerified: true
    }
  },
  updateBanner: (state, action) => {
    return {
      ...state,
      banner: action.payload
    }
  },
  setUser: (state, action) => {
    return {
      ...state,
      user: action.payload
    }
  },
  dismissBanner: state => {
    return {
      ...state,
      banner: { ...state.banner, isVisible: false }
    }
  },
  setMerchantAccess: (state, action) => {
    return {
      ...state,
      merchantAccess: action.payload,
      isMerchantsLoading: false
    }
  },
  fetchMerchantAccess: state => {
    return {
      ...state,
      isMerchantsLoading: true
    }
  },
  startLoading: state => {
    return { ...state, isLoading: true }
  },
  endLoading: state => {
    return { ...state, isLoading: false }
  }
}

const accountSlice = createSlice({
  slice: 'account',
  initialState: initialState,
  reducers
})

function* fetchMerchantAccess() {
  let merchantAccess = yield call(api.getMerchantAccess)
  if (merchantAccess.length === 1) {
    // Setting the first store
    yield put(dashboardSlice.actions.setView('store'))
    yield put(
      dashboardSlice.actions.setFilter({
        store: merchantAccess[0]
      })
    )
  }

  yield put(accountSlice.actions.setMerchantAccess(merchantAccess))
}

function* userSagas() {
  yield takeLatest(accountSlice.actions.fetchMerchantAccess.toString(), fetchMerchantAccess)
}

export default {
  ...accountSlice,
  selectors,
  sagas: userSagas
}
