import React, { isValidElement, Children, cloneElement } from 'react'
import Icon from '../Icon/Icon'
import { useAccordionContext } from './AccordionContext'
import objstr from 'obj-str'
import { icon } from 'config/colors'

const ArrowIcon = ({ isOpen }) => {
  const iconName = isOpen ? 'arrowUpRegular' : 'arrowDownRegular'

  return <Icon name={iconName} width="18px" height="10px" color={icon} />
}

const ItemHeader = ({ children, isOpen, toggleOpen }) => {
  const { classes } = useAccordionContext()

  const itemHeaderClassName = objstr({
    [classes['accordion-item-header']]: true,
    [classes['accordion-item-header--open']]: isOpen
  })

  const renderChildren = () => {
    if (isValidElement(children)) {
      return Children.map(children, child => {
        return cloneElement(child, {
          isOpen,
          toggleOpen
        })
      })
    }

    return React.createElement('div', {}, children)
  }

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleOpen()
    }
  }

  return (
    <div onClick={toggleOpen} className={itemHeaderClassName} tabIndex={0} onKeyDown={onKeyDown}>
      {renderChildren()}
      <div className={classes['accordion-item-header-actions']}>
        <ArrowIcon isOpen={isOpen} />
      </div>
    </div>
  )
}

export default ItemHeader
