import React from 'react'
import Message from 'components/Message/Message'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import styles from './VerifyAccountErrorMessage.styles'

const transformServerErrorMessage = ({ errorCode, goToLogin }) => {
  switch (errorCode) {
    //Access not allowed
    case 'invalidPassword':
      return 'Sorry, invalid Password. Please ensure it conforms to the rules specified above.'

    //Account already exists
    case 'incorrectEmailMid':
      return 'Sorry, you cannot access Insights for that Merchant Number. Please use an email that is associated with that Merchant Number or phone Help Desk on 0800 729 627 to get access.'

    //Not available
    case 'invalidRequest':
    case 'invalid_link':
    case 'not_active':
      return 'Your invitation link is invalid or expired. Please talk to your account owner(s) for a new invite!'

    default:
      return 'Sorry, there was an error. Please try again later.'
  }
}

const VerificationErrorMessage = ({ errorCode, goToLogin, classes }) => {
  const message = transformServerErrorMessage({ errorCode, goToLogin })
  return (
    <div className={classes['error']}>
      <Message text={message} variant={'error'} showIcon={false} />{' '}
    </div>
  )
}

VerificationErrorMessage.propTypes = {
  errorCode: PropTypes.string
}

export default withStyles(styles)(VerificationErrorMessage)
