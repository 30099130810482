let topMarginUtil = {}
let rightMarginUtil = {}
let bottomMarginUtil = {}
let leftMarginUtil = {}

let topPaddingUtil = {}
let rightPaddingUtil = {}
let bottomPaddingUtil = {}
let leftPaddingUtil = {}

const toRem = val => {
  return val + 'rem'
}

for (var count = 0; count < 6; count++) {
  let startingMargin = 0.625

  topPaddingUtil[`.pT${count}`] = {
    paddingTop: toRem(startingMargin * count)
  }
  rightPaddingUtil[`.pR${count}`] = {
    paddingRight: toRem(startingMargin * count)
  }
  bottomPaddingUtil[`.pB${count}`] = {
    paddingBottom: toRem(startingMargin * count)
  }
  leftPaddingUtil[`.pL${count}`] = {
    paddingLeft: toRem(startingMargin * count)
  }

  topMarginUtil[`.mT${count}`] = {
    marginTop: toRem(startingMargin * count)
  }
  rightMarginUtil[`.mR${count}`] = {
    marginRight: toRem(startingMargin * count)
  }
  bottomMarginUtil[`.mB${count}`] = {
    marginBottom: toRem(startingMargin * count)
  }
  leftMarginUtil[`.mL${count}`] = {
    marginLeft: toRem(startingMargin * count)
  }
}

const styles = {
  '@global': {
    ...topMarginUtil,
    ...rightMarginUtil,
    ...bottomMarginUtil,
    ...leftMarginUtil,
    ...topPaddingUtil,
    ...rightPaddingUtil,
    ...bottomPaddingUtil,
    ...leftPaddingUtil,
    '*, *:before, *:after': {
      boxSizing: 'border-box'
    },
    html: {
      boxSizing: 'border-box',
      fontSize: '14px',
      fontFamily: `'Proxima Nova', 'Helvetica Neue', 'Helvetica, Arial', 'sans-serif'`
    },
    '@media only screen and (min-width: 768px)': {
      html: {
        fontSize: '16px'
      }
    }
  }
}

export default styles
