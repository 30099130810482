import React from 'react'
import styles from './Avatar.styles'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'

const Avatar = ({ classes, title }) => {
  return (
    <div className={classes['avatar']}>
      <div className={classes['title']}>{title && title.toUpperCase()}</div>
    </div>
  )
}

Avatar.prototype = {
  title: PropTypes.string.isRequired
}

export default withStyles(styles)(Avatar)
