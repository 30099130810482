import _ from 'lodash'
import moment from 'moment'
import { DATE_AM_PM_FORMAT } from 'constants/general.constants'
import { toOrdinal } from 'utils/number.utils'
import { getTotal } from 'utils/number.utils'

const convertToShortAMPMTimeFormat = date => {
  if (!moment.isMoment(date)) {
    date = moment(date)
  }
  return date.format('ha')
}

const convertToAMPMTimeFormat = date => {
  if (!moment.isMoment(date)) {
    date = moment(date)
  }
  return date.format('hh:mm A')
}

const convertToAMPMTime = date => {
  return moment(date, 'hh:mm A')
}

const convertToDateParamFormat = date => {
  return date.format('YYYY-MM-DDThh:mm:ssZ')
}

const convertToDayNameFormat = (date, range, dayCounter) => {
  if (range === '1m') {
    return toOrdinal(dayCounter)
  }
  return date.format('ddd Do')
}

const convertToDayFormat = date => {
  return date.format('MM/DD/YYYY')
}

const convertAggTxnsToMap = (aggTxns = [], formatter = convertToShortAMPMTimeFormat) => {
  return _.chain(aggTxns)
    .keyBy(v => {
      return formatter(moment(v.periodStart))
    })
    .mapValues(v => {
      return {
        transactionValue: v.transactionValue,
        transactionCount: v.transactionCount
      }
    })
    .value()
}

const getDateWithMoreDays = (date1, date2) => {
  return date1.daysInMonth() > date2.daysInMonth() ? date1 : date2
}

const convertToDateParamFormatIfDateExists = (date, dayNumber) => {
  return date.date() === dayNumber ? convertToDateParamFormat(date) : null
}

const getTotalAgg = (data, key) => {
  return _.isEmpty(data) ? 0 : getTotal(data, key)
}

const generateAllTotals = (currentAggregateTransactions, previousAggregateTransactions) => {
  const currentSalesTotal = getTotalAgg(currentAggregateTransactions, 'transactionCount')
  const currentRevenueTotal = getTotalAgg(currentAggregateTransactions, 'transactionValue')
  const previousSalesTotal = getTotalAgg(previousAggregateTransactions, 'transactionCount')
  const previousRevenueTotal = getTotalAgg(previousAggregateTransactions, 'transactionValue')

  return {
    currentSalesTotal,
    currentRevenueTotal,
    previousSalesTotal,
    previousRevenueTotal
  }
}
const processDailyIntervalDataGrouping = (range, curMap, prevMap, curTimeFrom, prevTimeFrom, dayCounter) => {
  let incCurrentTimeFromFormatted = null
  let prevCurrentTimeFromFormatted = null
  if (range === '1m') {
    incCurrentTimeFromFormatted = convertToDateParamFormatIfDateExists(curTimeFrom, dayCounter)
    prevCurrentTimeFromFormatted = convertToDateParamFormatIfDateExists(prevTimeFrom, dayCounter)
  } else {
    incCurrentTimeFromFormatted = convertToDateParamFormat(curTimeFrom)
    prevCurrentTimeFromFormatted = convertToDateParamFormat(prevTimeFrom)
  }
  const revenueAgTransaction = {
    currentRevenue: 0,
    currentSales: 0,
    currentTime: curTimeFrom.isSame(moment(), 'day') ? 'Today' : convertToDayNameFormat(curTimeFrom, range, dayCounter),
    oldRevenue: 0,
    oldSales: 0,
    currentDateTime: incCurrentTimeFromFormatted ? curTimeFrom.valueOf() : null,
    oldDateTime: prevCurrentTimeFromFormatted ? prevTimeFrom.valueOf() : null
  }
  if (!_.isEmpty(curMap) && incCurrentTimeFromFormatted) {
    const curAggTxn = curMap[incCurrentTimeFromFormatted]
    revenueAgTransaction.currentRevenue = curAggTxn ? curAggTxn.transactionValue : 0
    revenueAgTransaction.currentSales = curAggTxn ? curAggTxn.transactionCount : 0
  }
  if (!_.isEmpty(prevMap) && prevCurrentTimeFromFormatted) {
    const prevAggTxn = prevMap[prevCurrentTimeFromFormatted]
    revenueAgTransaction.oldRevenue = prevAggTxn ? prevAggTxn.transactionValue : 0
    revenueAgTransaction.oldSales = prevAggTxn ? prevAggTxn.transactionCount : 0
  }
  return revenueAgTransaction
}

const convertToDailyTxnAgg = data => {
  const { filter, currentAggregateTransactions, previousAggregateTransactions } = extractAggregateTransactions(data)

  const { range, startDate, endDate, previousStartPeriod, isComparePeriod } = filter
  const currentTimeFrom = moment(startDate)
  const currentTimeTo = moment(endDate)
  const previousTimeFrom = moment(previousStartPeriod)

  let incCurrentTimeFrom = currentTimeFrom.startOf('hour')
  let incPreviousTimeFrom = previousTimeFrom.startOf('hour')

  const currentAggregateTransactionsMap = convertAggTxnsToMap(currentAggregateTransactions, convertToDateParamFormat)
  const previousAggregateTransactionsMap = convertAggTxnsToMap(previousAggregateTransactions, convertToDateParamFormat)

  const revenueAggTransactions = []

  if (range === '1m') {
    let maxDays = currentTimeTo.date()
    if (isComparePeriod) maxDays = getDateWithMoreDays(currentTimeFrom, previousTimeFrom).daysInMonth()
    for (let dayCounter = currentTimeFrom.date(); dayCounter <= maxDays; dayCounter++) {
      revenueAggTransactions.push(
        processDailyIntervalDataGrouping(
          range,
          currentAggregateTransactionsMap,
          previousAggregateTransactionsMap,
          incCurrentTimeFrom,
          incPreviousTimeFrom,
          dayCounter
        )
      )
      incCurrentTimeFrom = incCurrentTimeFrom.startOf('day').add(1, 'days')
      incPreviousTimeFrom = incPreviousTimeFrom.startOf('day').add(1, 'days')
    }
  } else {
    while (incCurrentTimeFrom.isSameOrBefore(currentTimeTo)) {
      revenueAggTransactions.push(
        processDailyIntervalDataGrouping(
          range,
          currentAggregateTransactionsMap,
          previousAggregateTransactionsMap,
          incCurrentTimeFrom,
          incPreviousTimeFrom
        )
      )
      incCurrentTimeFrom = incCurrentTimeFrom.startOf('day').add(1, 'days')
      incPreviousTimeFrom = incPreviousTimeFrom.startOf('day').add(1, 'days')
    }
  }
  return revenueAggTransactions
}

const convertToHourlyTxnAgg = data => {
  const { earliestPeriodStart, latestPeriodStart } = getDateTimeBoundariesHourInterval(data)
  if (!earliestPeriodStart || !latestPeriodStart) {
    return []
  }
  const { currentAggregateTransactions, previousAggregateTransactions } = extractAggregateTransactions(data)

  let incEarliestPeriodStart = convertToAMPMTime(earliestPeriodStart)
  let incLatestPeriodStart = convertToAMPMTime(latestPeriodStart)

  const currentAggregateTransactionsMap = convertAggTxnsToMap(currentAggregateTransactions)
  const previousAggregateTransactionsMap = convertAggTxnsToMap(previousAggregateTransactions)

  const revenueAggTransactions = []

  while (incEarliestPeriodStart.isSameOrBefore(incLatestPeriodStart)) {
    let nztTime = convertToShortAMPMTimeFormat(incEarliestPeriodStart)
    const previousDateTime = moment(incEarliestPeriodStart).subtract(7, 'days')
    const revenueAgTransaction = {
      currentRevenue: 0,
      currentSales: 0,
      currentTime: incEarliestPeriodStart.isSame(moment(), 'hour') ? 'Now' : nztTime,
      oldRevenue: 0,
      oldSales: 0,
      oldTime: nztTime,
      currentDateTime: incEarliestPeriodStart.valueOf(),
      oldDateTime: previousDateTime.valueOf()
    }
    if (!_.isEmpty(currentAggregateTransactionsMap)) {
      const curAggTxn = currentAggregateTransactionsMap[nztTime]
      revenueAgTransaction.currentRevenue = curAggTxn ? curAggTxn.transactionValue : 0
      revenueAgTransaction.currentSales = curAggTxn ? curAggTxn.transactionCount : 0
    }
    if (!_.isEmpty(previousAggregateTransactionsMap)) {
      const prevAggTxn = previousAggregateTransactionsMap[nztTime]
      revenueAgTransaction.oldRevenue = prevAggTxn ? prevAggTxn.transactionValue : 0
      revenueAgTransaction.oldSales = prevAggTxn ? prevAggTxn.transactionCount : 0
    }
    revenueAggTransactions.push(revenueAgTransaction)
    incEarliestPeriodStart = incEarliestPeriodStart.add(1, 'hours')
  }
  return revenueAggTransactions
}

const getDateTimeBoundariesHourInterval = data => {
  const { filter, currentAggregateTransactions, previousAggregateTransactions } = extractAggregateTransactions(data)

  let earliestPeriodStart, latestPeriodStart, currentDateFormat
  const eightAM = { hour: 8, minute: 0, second: 0, millisecond: 0 }
  const fivePM = { hour: 17, minute: 0, second: 0, millisecond: 0 }

  if (_.isEmpty(currentAggregateTransactions) && _.isEmpty(previousAggregateTransactions)) {
    earliestPeriodStart = moment(filter.startDate).set(eightAM)
    latestPeriodStart = moment(filter.startDate).set(fivePM)
  }

  if (currentAggregateTransactions && !_.isEmpty(currentAggregateTransactions)) {
    earliestPeriodStart = moment(_.head(currentAggregateTransactions).periodStart)
    latestPeriodStart = moment(_.last(currentAggregateTransactions).periodStart)
    currentDateFormat = convertToDayFormat(earliestPeriodStart)
  }

  if (previousAggregateTransactions && !_.isEmpty(previousAggregateTransactions)) {
    let prevEarliest = moment(_.head(previousAggregateTransactions).periodStart)
    let prevLatest = moment(_.last(previousAggregateTransactions).periodStart)

    currentDateFormat = !currentDateFormat ? convertToDayFormat(prevEarliest) : currentDateFormat

    if (earliestPeriodStart) {
      let currentDatePrevEarliest = moment(
        currentDateFormat + ' ' + convertToAMPMTimeFormat(prevEarliest),
        DATE_AM_PM_FORMAT
      )
      earliestPeriodStart = earliestPeriodStart.isAfter(currentDatePrevEarliest)
        ? currentDatePrevEarliest
        : earliestPeriodStart
    } else {
      earliestPeriodStart = prevEarliest
    }

    if (latestPeriodStart) {
      let currentDatePrevLatest = moment(
        currentDateFormat + ' ' + convertToAMPMTimeFormat(prevLatest),
        DATE_AM_PM_FORMAT
      )
      latestPeriodStart = latestPeriodStart.isBefore(currentDatePrevLatest) ? currentDatePrevLatest : latestPeriodStart
    } else {
      latestPeriodStart = prevLatest
    }
  }

  const baseEarliestPeriodStart = moment(earliestPeriodStart).set(eightAM)
  const baseLatestPeriodStart = moment(latestPeriodStart).set(fivePM)

  earliestPeriodStart = baseEarliestPeriodStart.isBefore(earliestPeriodStart)
    ? baseEarliestPeriodStart
    : earliestPeriodStart
  latestPeriodStart = baseLatestPeriodStart.isAfter(latestPeriodStart) ? baseLatestPeriodStart : latestPeriodStart

  return { earliestPeriodStart, latestPeriodStart }
}

const extractAggregateTransactions = data => {
  const { filter, currentTransactionsAgg, previousTransactionsAgg } = data
  const currentAggregateTransactions = _.get(currentTransactionsAgg, 'aggregateTransactions')
  const previousAggregateTransactions = _.get(previousTransactionsAgg, 'aggregateTransactions')
  return { filter, currentAggregateTransactions, previousAggregateTransactions }
}

const convertToTransactionAgg = data => {
  const { currentTransactionsAgg, previousTransactionsAgg } = data
  let interval
  if (currentTransactionsAgg) {
    interval = currentTransactionsAgg.interval
  } else if (previousTransactionsAgg) {
    interval = previousTransactionsAgg.interval
  } else {
    return []
  }

  // Generates a list of data points bases on the interval
  const transactionList = interval === 'hour' ? convertToHourlyTxnAgg(data) : convertToDailyTxnAgg(data)

  //Computes totals for periods
  const { currentAggregateTransactions, previousAggregateTransactions } = extractAggregateTransactions(data)
  const totals = generateAllTotals(currentAggregateTransactions, previousAggregateTransactions)

  return {
    data: transactionList,
    ...totals
  }
}

export const parseData = ({ data, filter }) => {
  const currentTransactionsAgg = _.get(data, 'currentTransactionsAgg')
  const previousTransactionsAgg = _.get(data, 'previousTransactionsAgg')
  return convertToTransactionAgg({ currentTransactionsAgg, previousTransactionsAgg, filter })
}
