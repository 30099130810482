const styles = {
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['2rem', '0.5rem', '0.5rem', '0.5rem'],
    alignItems: 'center'
  },
  body: {
    maxWidth: '100%'
  }
}

export default styles
