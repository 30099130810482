import React from 'react'
import ContentLoader from 'react-content-loader'
import { isMobileWidth } from 'utils/common.utils'

const height = 125
const barHeight = 75

const totalWidth = isMobileWidth() ? 500 : 1000
const y = isMobileWidth() ? 40 : 25

const start1 = 0.15 * totalWidth
const width1 = 0.085 * totalWidth
const start2 = 0.3 * totalWidth
const width2 = 0.4 * totalWidth
const start3 = 0.765 * totalWidth
const width3 = 0.085 * totalWidth

const CustomerCardLoader = () => {
  return (
    <ContentLoader height={height} width={totalWidth} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x={start1} y={y} rx="5" ry="5" width={width1} height={barHeight} />
      <rect x={start2} y={y} rx="5" ry="5" width={width2} height={barHeight} />
      <rect x={start3} y={y} rx="5" ry="5" width={width3} height={barHeight} />
    </ContentLoader>
  )
}

export default CustomerCardLoader
