import React from 'react'
import ContentLoader from 'react-content-loader'
import { isMobileWidth } from 'utils/common.utils'

const y1 = isMobileWidth() ? 30 : 16
const y2 = isMobileWidth() ? 100 : 80

const SalesCardLoader = () => {
  return (
    <ContentLoader height={125} width={400} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
      <rect x="60" y={y1} rx="5" ry="5" width="288" height="50" />
      <rect x="148" y={y2} rx="4" ry="4" width="100" height="27" />
    </ContentLoader>
  )
}

export default SalesCardLoader
