const styles = {
  background: {
    position: 'fixed',
    opacity: 0.5,
    'z-index': '999',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(42, 36, 65, 0.3)'
  },
  icon: {
    position: 'fixed',
    top: '50vh',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    MsTransform: 'translate(-50%,-50%)',
    'z-index': '1000',
    width: '30px',
    height: '30px'
  }
}
export default styles
