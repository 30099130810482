import * as mediaQueryHelper from 'config/mediaQueryHelper'
import * as colors from 'config/colors'
const { MOBILE_UP } = mediaQueryHelper

const styles = {
  card: {
    width: '100%',
    minHeight: params => params.height || '100%',
    background: 'rgb(250, 250, 251)',
    margin: 'auto',
    borderRadius: 3,
    border: `1px solid rgb(223, 222, 227)`,
    boxShadow: '0px 2px 4px 0px rgb(223, 222, 227)'
  },
  titleText: {
    margin: 'auto',
    display: 'flex',
    '& >div': {
      padding: [0, '0.5rem', '0.5rem']
    }
  },
  title: {
    display: 'flex'
  },
  header: {
    width: '100%',
    padding: ['0.75rem', 0, 0, 0],
    borderBottom: params => {
      if (params.showHeaderBorder) {
        return `1px solid ${colors.line2}`
      }
      return 0
    }
  },
  resizeIcon: {
    '& :hover': {
      cursor: 'pointer',
      opacity: 0.8
    },
    display: 'none',
    [MOBILE_UP()]: {
      display: 'block',
      padding: [0, '0.75rem', 0, 0]
    }
  }
}

export default styles
