import { Container, withPrivateLayout } from 'components/common'
import React, { Component } from 'react'
import withStyles from 'react-jss'
import { SettlementFilter, SettlementResult } from '../../components'
import styles from './Settlement.styles'
import container from './SettlementContainer'
import * as colors from 'config/colors'
import Modal from 'components/Modal/Modal'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button'

//TODO delete this when account settings are migrated to the new UI
class Settlement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hideResults: false,
      summaryType: '',
      selectedStores: []
    }
    this.setHideResults = this.setHideResults.bind(this)
    this.setSummaryType = this.setSummaryType.bind(this)
    this.setSelectedStores = this.setSelectedStores.bind(this)
    this.getDiplayResults = this.getDiplayResults.bind(this)
  }

  componentWillUnmount() {
    const { props } = this
    props.actions.resetSummary()
  }

  onClose = () => {
    this.props.actions.set504(false)
    this.props.actions.resetSummary()
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
  }
  setHideResults = value => {
    this.setState({ hideResults: value })
  }

  setSelectedStores = stores => {
    this.setState({ selectedStores: stores })
  }

  setSummaryType = value => {
    const { summaryType } = this.state
    if (value !== summaryType) {
      this.setState({ summaryType: value })
    }
  }

  getDiplayResults = selectedStores => {
    if (selectedStores) {
      if (selectedStores.length > 1) {
        return false
      }
    }
    return true
  }

  render() {
    const { props } = this
    const {
      classes,
      merchantSummary,
      terminalSummary,
      filter,
      isLoading,
      is504,
      routerProps,
      actions,
      merchants,
      isShowResult
    } = props
    const {
      location: { search },
      history
    } = routerProps
    const { hideResults, summaryType, selectedStores } = this.state
    const displayResults = this.getDiplayResults(selectedStores)
    return (
      <Container>
        <Modal
          override={{
            title: {
              color: colors.teal
            }
          }}
          title="Something went wrong"
          isOpen={is504}
          onClose={this.onClose}
        >
          <div className={classes['modalMain']}>
            <Text>
              Your search criteria took too long to respond, please reduce the number of stores and days or try again
              later
            </Text>
            <div className={classes['modalFooter']}>
              <Button onClick={this.onClose} variant="secondary">
                Close
              </Button>
            </div>
          </div>
        </Modal>
        <div className={classes['filter']}>
          <SettlementFilter
            setSelectedStores={this.setSelectedStores}
            selectedStores={this.state.selectedStores}
            setHideResults={this.setHideResults}
            hideResults={hideResults}
            setSummaryType={this.setSummaryType}
            summaryType={summaryType}
            {...{ search, merchants, history, isLoading, actions, stores: selectedStores }}
          />
        </div>

        <div className={classes['result']}>
          {displayResults && (
            <SettlementResult
              {...{
                merchantSummary,
                terminalSummary,
                filter,
                isLoading,
                search,
                actions,
                isShowResult
              }}
            />
          )}
        </div>
      </Container>
    )
  }
}

export default withPrivateLayout(container(withStyles(styles)(Settlement)))
