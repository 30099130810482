import React, { Component } from 'react'
import { getParamsFromUrl, isFilterValid, constructParams } from '../TransactionFilter/TransactionFilter.helper'
import styles from './TransactionResult.styles'
import withStyles from 'react-jss'
import * as colors from 'config/colors'
import Text from 'components/Text/Text'
import TransactionTable from './TransactionTable/TransactionTable'
import TransactionDetail from './TransactionDetail/TransactionDetail'
import TransctionExport from './TransactionExport/TransactionExport'
import PrintableTransactionTable from './TransactionTable/PrintableTrasactionTable'
import { formatDate } from 'utils/filters.utils'
import { SettlementDate } from 'components/common'
import apis from 'api'
import { EVENT_VIEW_TRANSACTION } from 'constants/mixpanel.constants'

const Content = ({
  filter,
  transactions,
  totalResults,
  totalPages,
  page,
  search,
  classes,
  isLoading,
  setSelectedTransaction,
  selectedTransaction,
  renderPrint,
  history
}) => {
  const { cardAcceptorIdCode, transactionTimeFrom, transactionTimeTo, name } = filter
  const { Mixpanel: mixpanel } = apis

  const onSelectTransaction = transaction => {
    mixpanel.track(EVENT_VIEW_TRANSACTION)
    setSelectedTransaction(transaction)
  }

  const deselectTransaction = () => {
    setSelectedTransaction(null)
  }

  const noResults = !isLoading && transactions.length === 0

  return (
    <>
      <div className={`${classes['title']}`}>
        <div className={`name`} data-hj-suppress>
          <Text variant="h2" color={colors.greyDark}>
            {name}
          </Text>
        </div>
        <div className={'date'}>
          {<SettlementDate {...{ from: formatDate(transactionTimeFrom), to: formatDate(transactionTimeTo) }} />}
        </div>
        <div className={'number'}>
          <Text variant="h5" color={colors.greyDark}>
            Merchant No.
          </Text>
          {cardAcceptorIdCode}
        </div>
      </div>
      <div className={classes['section']}>
        {noResults ? (
          <div className={classes['emptyMessageContainer']}>
            <Text variant="h4">No transactions to display.</Text>
          </div>
        ) : (
          <TransactionSection
            {...{
              transactions,
              totalResults,
              totalPages,
              page,
              search,
              isLoading,
              classes,
              onSelectTransaction,
              selectedTransaction,
              deselectTransaction,
              filter,
              renderPrint,
              history
            }}
          />
        )}
      </div>
    </>
  )
}

const TransactionSection = ({
  transactions,
  totalResults,
  totalPages,
  page,
  search,
  isLoading,
  classes,
  onSelectTransaction,
  selectedTransaction,
  deselectTransaction,
  filter,
  renderPrint,
  history
}) => {
  return (
    <>
      <div className={classes['tableSection']}>
        <TransactionTable
          {...{
            transactions,
            totalResults,
            totalPages,
            page,
            search,
            isLoading,
            classes,
            onSelectTransaction,
            selectedTransaction,
            history
          }}
        />
      </div>

      {selectedTransaction && (
        <div className={classes['detailsSection']}>
          <TransactionDetail {...{ selectedTransaction, deselectTransaction }} />
        </div>
      )}

      {/* For Print */}
      {renderPrint && <PrintableTransactionTable {...{ transactions, filter }} />}
    </>
  )
}

class TransactionResult extends Component {
  state = {
    renderPrint: false
  }

  componentWillReceiveProps(nextProps) {
    const { search: oldProps } = this.props
    const { search: newProps } = nextProps
    // Detect param change
    if (oldProps !== newProps) {
      this.getData(newProps)
      return false
    }
  }

  getData(newProps) {
    const { actions } = this.props
    const params = getParamsFromUrl(newProps)
    const isValid = isFilterValid(params)
    if (!isValid) {
      actions.resetTransactions()
      return
    }
    const actionParams = constructParams(params)
    actions.fetchTransactions(actionParams)
  }

  enablePrintRender = renderPrint => {
    this.setState({
      renderPrint
    })
  }

  render() {
    const { props } = this
    const {
      transactions = [],
      totalResults,
      totalPages,
      page,
      search,
      filter,
      isLoading,
      isShowResult,
      classes,
      setSelectedTransaction,
      selectedTransaction,
      history
    } = props

    if (!isShowResult) return null

    return (
      <div className={classes['wrapper']}>
        <div className={classes['header']}>
          <div className={'title'} id="transactionSummaryTop">
            <Text variant="h3" color={colors.greyDark}>
              Transaction Summary
            </Text>
          </div>
          <div className={'export'}>
            <TransctionExport
              {...{
                isLoading,
                search,
                transactions,
                enablePrintRender: this.enablePrintRender
              }}
            />
          </div>
        </div>
        <div className={classes['content']}>
          <Content
            {...{
              filter,
              transactions,
              totalResults,
              totalPages,
              page,
              search,
              classes,
              isLoading,
              setSelectedTransaction,
              selectedTransaction,
              renderPrint: this.state.renderPrint,
              history
            }}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(TransactionResult)
